import React, { useState, useEffect, ReactElement } from "react";
import ListItem from "../ListItem/ListItem";
import { ListItemProps } from "../ListItem/ListItem";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";
import Animation from "../Animations/Animations";

export interface ListViewProps {
  items: ListItemProps[];
  class?: string;
  listType?: string;
  isLoaded?: boolean;
  itemActions?: ListItemAction[];
  placeholderText?: string | ReactElement;
  onClick?: (itemId: string) => void;
}

interface ListItemAction {
  title: string;
  icon: React.ReactElement;
  onClick: (itemId: string) => void;
}

const ListView = (props: ListViewProps) => {
  // Check if there are list items provided
  const [hasItems, setHasItems] = useState(false);

  function handleItemClick(itemId: string) {
    props.onClick && props.onClick(itemId);
  }

  useEffect(() => {
    if (props.items) {
      setHasItems(true);
    } else {
      setHasItems(false);
    }
  })

  return (
    <div className={`list ${props.class ? props.class : ""}`}>

      {props.isLoaded ?
        // Render 3 items by default as placeholders
        [...Array(3)].map((_value, index) => (
          <ListItemSkeleton type={props.listType} key={index} />
        )) :
        (props.items && props.items.length) ?
          props.items.map((item: ListItemProps, index: number) => (
            <ListItem
              key={item.itemId}
              itemId={item.itemId}
              title={item.title}
              description={item.description}
              email={item.email}
              profileImage={item.profileImage}
              listType={props.listType}
              length={props.items.length}
              index={index}
              type={item.type}
              status={item.status}
              icon={item.icon}
              footer={item.footer}
              itemActions={(props.itemActions || []).map(action => ({
                itemId: item.itemId,
                ...action
              }))}
              onClick={() => handleItemClick(item.itemId)}
            />
          ))
          : <Animation show={hasItems} type="fade" itemIndex={1}>
            <h5 className="p--20 txt--grey txt--center width--full">{props.placeholderText || "No data available"}</h5>
          </Animation>
      }
    </div>
  );
}

export default ListView;
