import Ajv from "ajv";
import * as yaml from "js-yaml";
import { PartialMicroticaYaml, Runtime } from "../types/index";
import {
    microticaYamlBodyWithoutStages,
    GeneralStep,
    DockerPushStep,
    GitCloneStep,
    DeployKubernetesStep,
    DeployEnvironmentStep,
    CfnComponentStep,
    TriggerPipelineStep,
    TerraformBuildStep,
    TriggerReflectTestStep
} from "./validation";

/**
 * Validates the microtica.yaml files. Checks if the file is yaml, then checks if it has the properties we expect it to have
 *
 * @export
 * @param {string} microticaYamlContents
 * @returns {Promise<void>}
 */
export function validateMicroticaYaml(microticaYamlContents: string): string[] {
    const errors = [] as string[];
    let errors1: string[] = [];
    let errors2: string[] = [];
    let errors3: string[] = [];
    let microticaYamlParsed;
    try {
        microticaYamlParsed = parseMicroticaYaml(microticaYamlContents);
    } catch (error) {
        errors.push(error.message);
    }


    // Validating small parts makes it a lot easier to work with (limitations of the validator package)
    if (microticaYamlParsed) {
        errors1 = validateMicroticaYamlBody(JSON.parse(JSON.stringify(microticaYamlParsed)) as PartialMicroticaYaml);
        errors2 = validateMicroticaYamlProperStageUsage(JSON.parse(JSON.stringify(microticaYamlParsed)) as PartialMicroticaYaml);
        errors3 = validateMicroticaYamlSteps(JSON.parse(JSON.stringify(microticaYamlParsed)) as PartialMicroticaYaml);
    }

    errors.push(...errors1, ...errors2, ...errors3);
    errors.join(", ");
    return errors;
}


/**
 * Given an AJV errors object, get all the errors
 *
 * @param {("microtica.yaml" | "schema.json")} fileToBeValidatedName
 * @param {Ajv.ErrorObject[]} ajvErrors
 * @returns {string[]}
 */
function extractErrorMessages(fileToBeValidatedName: "microtica.yaml", ajvErrors: Ajv.ErrorObject[], stepName?: string): string[] {


    if (!ajvErrors) {
        return [];
    }

    const step = stepName ? ` on Step ${stepName}` : "";

    return ajvErrors.map(error => {

        const pathValue = error.dataPath.substr(1) === "" ? "Root level" : error.dataPath.substr(1);
        switch (error.keyword) {
            case "required":
                return `Should have required property "${(error.params as any).missingProperty}"${step}. Path: ${pathValue}`;

            case "type":
                const splitString = error.dataPath.split(".");
                const invalidProperty = splitString[splitString.length - 1];
                // Message is: image should be string. Path: steps['BuildNodeApp'].image
                return `${invalidProperty} ${error.message}${step}. Path: ${pathValue}`;

            case "additionalProperties":
                return `Invalid property "${(error.params as any).additionalProperty}"${step}. Path: ${pathValue}`;

            case "minProperties":
                return `Object does not have enough properties${step}. Path: ${pathValue}`;

            case "minItems":
                return `Array does not have enough items${step}. Path: ${pathValue}`;

            case "const":
                return `Property value is invalid${step}. Should be ${(error.params as any).allowedValue}. Path: ${pathValue}`;
            case "enum":
                return `Property value is invalid${step}. Should be ${(error.params as any).allowedValues.join(", ")}. Path: ${pathValue}`;

            default:
                throw new Error(
                    `Invalid ${fileToBeValidatedName} file. ${error.message}. Path: ${pathValue}`
                )
        }
    });
}

/**
 * Checks just if the "Stages" and "stage" in a step is used correctly. 
 * This has to be validated first else we will need two schemas for the yaml, with and without the stages. 
 * With this, we can just imagine that there are no stages while validating
 *
 * @param {PartialMicroticaYaml} fileContentsToBeValidated
 */
function validateMicroticaYamlProperStageUsage(fileContentsToBeValidated: PartialMicroticaYaml): string[] {

    const stagesErrors = [] as string[];
    const stagesInSteps = [] as string[];

    if (fileContentsToBeValidated.steps) {
        Object.keys(fileContentsToBeValidated.steps).map(stepName => {

            if (fileContentsToBeValidated.steps[stepName]) {
                if (!isNaN(Number(stepName))) {
                    stagesErrors.push(
                        `The step name ${stepName} should be a string`
                    );
                }

                if (fileContentsToBeValidated.steps[stepName].stage) {
                    stagesInSteps.push(fileContentsToBeValidated.steps[stepName].stage!);
                }

                if (fileContentsToBeValidated.stages && !fileContentsToBeValidated.steps[stepName]) {
                    stagesErrors.push(
                        `Step ${stepName} is missing the stage property. If stages are defined, all steps must have a stage`
                    );
                } else if (fileContentsToBeValidated.stages && !fileContentsToBeValidated.stages.includes(fileContentsToBeValidated.steps[stepName].stage!)) {
                    stagesErrors.push(
                        `The stage ${fileContentsToBeValidated.steps[stepName].stage} on the step ${stepName} does not exist`
                    );
                }

                if (fileContentsToBeValidated.steps[stepName].stage && typeof fileContentsToBeValidated.steps[stepName].stage !== "string") {
                    stagesErrors.push(
                        `The stage ${fileContentsToBeValidated.steps[stepName].stage} on the step ${stepName} should be a string`
                    );
                }
            }
        });
    }


    if (fileContentsToBeValidated.stages) {

        fileContentsToBeValidated.stages.map(stage => {
            if (typeof stage !== "string") {
                stagesErrors.push(
                    `Stage name ${stage} must be a string`
                );
            }
            if (!stagesInSteps.includes(stage)) {
                stagesErrors.push(
                    `The stage ${stage} is not used in any step`
                );
            }
        });
    }

    stagesErrors.join(", ");
    return stagesErrors;
}

/**
 * Validates if the body of the .yaml WITHOUT stages, is valid. 
 *
 * @param {PartialMicroticaYaml} fileContentsToBeValidated
 * @returns {void}
 */
function validateMicroticaYamlBody(fileContentsToBeValidated: PartialMicroticaYaml & { runtime?: Runtime }): string[] {

    delete fileContentsToBeValidated.stages;

    const errors = [] as string[];

    const ajv = new Ajv();
    const valid = ajv.validate(microticaYamlBodyWithoutStages, fileContentsToBeValidated);

    if (
        fileContentsToBeValidated
        && fileContentsToBeValidated.runtime
        && fileContentsToBeValidated.runtime.packages
    ) {

        const validRuntimes = ["nodejs", "java"];

        Object.keys(fileContentsToBeValidated.runtime.packages).map(key => {
            if (!validRuntimes.includes(key)) {
                errors.push(
                    `Runtime is invalid. Should be one of: ${validRuntimes.join(", ")}`
                );
            }
        });
    }


    if (!valid) {
        errors.push(...extractErrorMessages("microtica.yaml", ajv.errors!));
    }
    return errors;
}


/**
 * Validates just the steps of the microtica.yaml.
 *
 * @param {PartialMicroticaYaml} fileContentsToBeValidated
 * @returns {string[]}
 */
function validateMicroticaYamlSteps(fileContentsToBeValidated: PartialMicroticaYaml): string[] {

    const errors = [] as string[];
    if (fileContentsToBeValidated.steps) {
        Object.keys(fileContentsToBeValidated.steps).map(stepName => {
            const currentStep = fileContentsToBeValidated.steps[stepName] as any;
            if (currentStep) {
                delete currentStep.stage;


                if (
                    currentStep
                    && currentStep.runtime
                    && currentStep.runtime.packages
                ) {
                    const validRuntimes = ["nodejs", "java"];
                    Object.keys(currentStep.runtime.packages).map(key => {
                        if (!validRuntimes.includes(key)) {
                            errors.push(
                                `Runtime is invalid on the step ${stepName}. Should be one of: ${validRuntimes.join(", ")}`
                            );
                        }
                    });
                }

                switch (currentStep.type) {
                    case "docker-push":
                        errors.push(...validateMicroticaYamlStep(DockerPushStep, currentStep, stepName));
                        break;

                    case "git-clone":
                        errors.push(...validateMicroticaYamlStep(GitCloneStep, currentStep, stepName));
                        break;

                    case "deploy":
                        if (currentStep.target === "environment") {
                            errors.push(...validateMicroticaYamlStep(DeployEnvironmentStep, currentStep, stepName));
                        } else {
                            errors.push(...validateMicroticaYamlStep(DeployKubernetesStep, currentStep, stepName));
                        }
                        break;

                    case "cfn-component":
                        errors.push(...validateMicroticaYamlStep(CfnComponentStep, currentStep, stepName));
                        break;

                    case "trigger-pipeline":
                        errors.push(...validateMicroticaYamlStep(TriggerPipelineStep, currentStep, stepName));
                        break;

                    case "terraform-build":
                        errors.push(...validateMicroticaYamlStep(TerraformBuildStep, currentStep, stepName));
                        break;

                    case "trigger-reflect-test":
                        errors.push(...validateMicroticaYamlStep(TriggerReflectTestStep, currentStep, stepName));
                        break;

                    default:

                        if (currentStep.type) {
                            errors.push(`Invalid "type" value on the step ${stepName}`);
                            break;

                        } else if (currentStep.image || currentStep.commands || currentStep.artifacts) {
                            errors.push(...validateMicroticaYamlStep(GeneralStep, currentStep, stepName));
                            break;
                        }

                        errors.push(`The step ${stepName} is invalid`);
                        break;
                }
            }
        });
    }

    return errors;
}

function validateMicroticaYamlStep(schema: object, file: any, stepName: string): string[] {
    const ajv = new Ajv({ allErrors: true, verbose: true });
    ajv.validate(schema, file);
    return extractErrorMessages("microtica.yaml", ajv.errors!, stepName);
}


function parseMicroticaYaml(microticaYamlContents: string): object {
    let microticaYamlParsed;

    try {
        microticaYamlParsed = yaml.safeLoad(microticaYamlContents); // this can throw too

        if (
            !microticaYamlParsed
            || typeof microticaYamlParsed !== "object"
            || Object.keys(microticaYamlParsed).length === 0
        ) {
            throw new Error("Not a valid Yaml file. Throwing error so it can go in the 'catch' part of a try/catch. ");
        }

    } catch (error) {

        throw Error(
            "Not a valid Yaml File"
        );
    }
    return microticaYamlParsed;
}