import React from "react";

export const deployStep = {
    title: `Deploy on Kubernetes`,
    description: (
        <div>
            Deploy a service to Kubernetes.
            <br />
            <br />
            To use this step, the service should already be deployed in the Kubernetes cluster.
            <br />
            <br />
            This step will re-deploy your existing service.
        </div>
    ),
    snippet: `step-name:
    type: deploy
    target: kubernetes
    # The name of the existing Kubernetes cluster
    cluster: my-kubernetes-cluster
    # The name of the existing service to be deployed
    service: my-app
    # Docker image tag to be applied with the deployment.
    # If not specified, the latest tag will be used (optional)
    tag: v0.1.0`
}


export const dockerPushStep = {
    title: `Docker Push`,
    description: (
        <div>
            Build and push image to a remote Docker registry.
            <br />
            <br />
            Docker Push step uses Docker registries connected within Microtica Portal to obtain access credentials.
            <br />
            <br />
            If a specific registry name is not provided the default one will be used.
        </div>
    ),
    snippet: `step-name:
    type: docker-push
    title: Build and Push Docker Image
    # The name of the image that will be created and pushed in the remote Docker registry
    image_name: microtica/my-app
    # The tag identifier for the image being pushed.
    # If not provided, latest tag will be used as default (optional)
    tag: latest
    # The name identifier of the Docker registry within Microtica
    # If not provided, the default registry will be used (optional)
    registry: my-registry
    # Enable image vulnerability scanning (optional)
    scan: true
    `
}

export const gitCloneStep = {
    title: "Git Clone",
    description: (
        <div>
            Clone a Git repository into pipeline runtime environment.
            <br />
            <br />
            Git account and repository should be provided while creating a pipeline.
            The account and the repository will be automatically used in the Git Clone step while executing the pipeline.
            <br />
            <br />
            Git Clone step uses Git accounts connected within Microtica Portal to obtain temporary access credentials
        </div>
    ),
    snippet: `step-name:
    type: git-clone
    # Path to where the source code should be cloned.
    # If not provided, the source code will be cloned
    # in the current working dir of the pipeline runtime environment (optional)
    clone_dir: sub/folder/`
}

export const cfnComponentStep = {
    title: "CloudFormation Component",
    description: (
        <div>
            Build Microtica component using your existing CloudFormation templates.
            <br />
            <br />
            This built-in step will do all necessery operations to build and package your CloudFormation template so you can use it as Microtica component.
        </div>
    ),
    snippet: `step-name:
    type: cfn-component
    # Path to the CloudFormation template
    cfn_template: templates/cfn.json`
}

export const deployEnvironmentStep = {
    title: "Deploy Environment",
    description: (
        <div>
            Initiate a new deployment for a specific Environment.
            <br />
            <br />
            This will only initiate the environment deployment and complete the step. The deployment will continue asynchronously.
        </div>
    ),
    snippet: `step-name:
    type: deploy
    target: environment
    # The ID of the environment to initiate deployment for
    env_id: "<replace with your environment id>"
    # Initiate deployment only for commit/manual trigger on specified branch (optional)
    branch_filter: master
    `
}

export const triggerPipelineStep = {
    title: "Trigger Pipeline",
    description: (
        <div>
            Initiate execution of a pipeline from another pipeline.
            <br />
            <br />
            This step is useful for splitting the automation process in two or more pipelines for better management and reusability of automation.
            <br />
            <br />
            This step will initiate the pipeline execution and will not wait for pipeline to finish. Pipeline execution will continue running asynchronously.
        </div>
    ),
    snippet: `step-name:
    type: trigger-pipeline
    # The ID of an existing pipeline to be triggered
    pipeline: "<replace with your pipeline id>"
    # Initiate pipeline execution only for commit/manual trigger on specified branch
    # If not specified, pipeline will be triggered on every pipeline execution (optional)
    branch_filter: master
    # Branch or tag ref the triggered pipeline should pull the code from
    # If not specified, the code will be cloned from master branch (optional)
    ref: refs/heads/master
    `
}

export const starterYaml = `
# This is a basic spec to help you get started
stages:
    - clone
    - build

steps:
    Clone:
        type: git-clone
        stage: clone

    Build:
        image: aws/codebuild/standard:4.0
        stage: build
        commands:
            - npm install
            - npm test
        
# Visit https://microtica.com/docs/pipeline-definition for more information`

export const terraformBuildStep = {
    title: "Terraform Build",
    description: (
        <div>
            Build Microtica component using your existing Terraform templates.
            <br />
            <br />
            This built-in step will do all necessery operations to build and package your Terraform templates so you can use it as Microtica component.
            It builds the Terraform templates and creates a component schema (input/output params spec) from variables.tf and outputs.tf, if one doesn't exist.
        </div>
    ),
    snippet: `step-name:
    type: terraform-build
    # Path to where the terraform files should be found.
    # If not provided, the default path will be the pipeline's specs dir (optional)
    work_dir: sub/folder/`
}

export const triggerReflectTestStep = {
    title: "Trigger Reflect Test",
    description: (
        <div>
            Reflect is an automated web testing tool that anyone can use without writing a line of code.
            <br />
            <br />
            This will only initiate a new Reflect test run and complete the step. The test run will continue executing asynchronously.
        </div>
    ),
    snippet: `step-name:
    type: trigger-reflect-test
    api_key: "<Reflect API key>"
    tag_slug: "<Test tag>"
    # Optional perameters
    variables:
        username: johndoe@example.com
    # ...
    # You can use any other parameter as specified in https://reflect.run/docs/developer-api/documentation/#run-a-test
`
}