import React, { useState, useEffect, Fragment, ReactElement } from "react";
import { useModal } from "react-modal-hook";

import { ReactComponent as AzureLogo } from "../../static/azure-logo.svg";

import Card from "../Card/Card";
import Button from "../Button/Button";
import CardHeader from "../CardHeader/CardHeader";
import CloudAccountModal, { CloudAccountType } from "../CloudAccount/CloudAccountModal";
import DropdownMenuContainer, {
  DropdownMenuItemProps
} from "../DropdownMenuContainer/DropdownMenuContainer";
import DropdownContainer, {
  DropdownItem
} from "../DropdownContainer/DropdownContainer";

import useAzureAccountList from "../../hooks/AzureAccount";
import { FormikErrors, Field } from "formik";

export interface AzureAccountChangeEvent {
  subscriptionId?: string;
  tenantId?: string;
  subscriptionName?: string;
}

export interface AzureAccountProps {
  title: string;
  subtitle?: string | ReactElement;
  showCardBox?: boolean;
  emptyText?: string | ReactElement;
  value?: AzureAccountChangeEvent;
  errors?: FormikErrors<{
    azureSubscription: string | undefined;
    azureTenant: string | undefined;
  }>;
  optional?: boolean;
  onChange?: (event: AzureAccountChangeEvent) => void;
  setFieldValue?: {
    (
      field: "azureSubscription" | "azureTenant",
      value: any,
      shouldValidate?: boolean | undefined
    ): void;
    (field: string, value: any): void;
  };
  disabled?: boolean;
}


const AzureAccount = (props: AzureAccountProps) => {
  // Hooks
  const [title] = useState(props.title);
  const [info] = useState(props.subtitle);
  const { azureAccounts } = useAzureAccountList();
  const [subscriptionList, setSubscriptionList] = useState<DropdownItem[]>([]);
  const [subscriptionId, setSubscriptionId] = useState<DropdownItem>();

  const [showModal, hideModal] = useModal(() => (
    <CloudAccountModal onClose={hideModal} cloudAccountType={CloudAccountType.MICROSOFT_AZURE} />
  ));

  useEffect(() => {
    if (azureAccounts) {
      if (props.value) {
        const { subscriptionId, tenantId } = props.value;
        if (subscriptionId) {
          const subscriptionName = azureAccounts.find(account => account.subscriptionId === subscriptionId && account.tenantId === tenantId) ? azureAccounts.find(account => account.subscriptionId === subscriptionId && account.tenantId === tenantId)!.subscriptionName : "";
          setSubscriptionId({ id: subscriptionId, name: `${subscriptionName} (${subscriptionId})` })
        }
      }

      setSubscriptionList(azureAccounts.map(account => ({ id: account.subscriptionId!, name: `${account.subscriptionName} (${account.subscriptionId})` })));
    }

  }, [azureAccounts]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        subscriptionId: subscriptionId && subscriptionId.id,
        tenantId: subscriptionId && azureAccounts.find(account => account.subscriptionId === subscriptionId.id)!.tenantId
      });
    if (subscriptionId && props.setFieldValue) {
      props.setFieldValue("azureTenant", azureAccounts.find(account => account.subscriptionId === subscriptionId.id)!.tenantId);
    }

  }, [subscriptionId]);

  const actions: DropdownMenuItemProps[] = [
    {
      itemId: "1",
      title: "Connect Microsoft Azure Account",
      onClick: () => {
        showModal();
      }
    }
  ];

  const emptyAccounts = (
    <Card class={props.showCardBox === false ? "" : "card dark compact"}>
      <CardHeader title={title} text={info} />
      <div className="card__content">
        <div className="connect-account">
          <AzureLogo />
        </div>
        <p className="card__content__text">
          <strong>{props.emptyText || "Connect your first Microsoft Azure account"}</strong>
        </p>
        <Button
          className="btn btn--xl btn--orange"
          children="Connect Microsoft Azure Account"
          onClick={() => {
            showModal();
          }}
        />
      </div>
    </Card>
  );

  const existingAccounts = (
    <Card class={props.showCardBox === false ? "" : "card dark compact"}>
      <div className="card_header_container">
        <CardHeader title={title} text={info} />
        <DropdownMenuContainer
          actions={actions}
          type="account"
          minWidth="260px"
        />
      </div>
      <div className="page-content__dropdown-container mb--30">
        <Field
          name="azureSubscription"
          label="Microsoft Azure Subscription"
        >
          {() => (
            <div>
              <DropdownContainer
                selectedItem={subscriptionId}
                items={subscriptionList}
                label="Microsoft Azure Subscription"
                placeholder="Select a Microsoft Azure subscription"
                disabled={props.disabled}
                optional={props.optional}
                onSelectItem={item => {
                  props.setFieldValue &&
                    props.setFieldValue("azureSubscription", item.id);
                  setSubscriptionId(item);
                }}
              />
              {props.errors && props.errors.azureSubscription ? (
                <div className="page-content__error">{props.errors.azureSubscription}</div>
              ) : null}
            </div>
          )}
        </Field>
        <Field
          name="azureTenant"
          placeholder="Microsoft Azure Tenant"
          type="hidden"
          hasError={true}
          hidden={true}
        />
      </div>
    </Card >
  );

  return (
    <Fragment>{azureAccounts.length ? existingAccounts : emptyAccounts}</Fragment>
  );
};

export default AzureAccount;
