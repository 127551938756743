import { FetchGitAccounts, FetchGitRepos } from '../actions';
import { GitAccountState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';
import { DeleteGitAccount } from '../actions/accounts/git/delete-git-account';
import { ConnectGitAccount } from '../actions/accounts/git/connect-git-account';

export const initialState: GitAccountState = {
    gitAccounts: [],
    gitRepositories: [],
    isFetching: false,
    error: errorEmptyObject,
    gitAccountConnected: false
}
export default function reducer(state: GitAccountState = initialState, action: FetchGitAccounts | FetchGitRepos | DeleteGitAccount | ConnectGitAccount): GitAccountState {
    switch (action.type) {

        case constants.FETCH_GIT_ACCOUNTS_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_GIT_ACCOUNTS_SUCCESS: return { ...state, gitAccounts: action.gitAccounts, isFetching: false, error: errorEmptyObject };
        case constants.FETCH_GIT_ACCOUNTS_FAIL: return { ...state, error: action.error, isFetching: false };

        case constants.FETCH_GIT_REPOS_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_GIT_REPOS_SUCCESS: return { ...state, gitRepositories: action.gitRepositories, isFetching: false, error: errorEmptyObject };
        case constants.FETCH_GIT_REPOS_FAIL: return { ...state, error: action.error, isFetching: false };

        case constants.DELETE_GIT_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.DELETE_GIT_ACCOUNT_SUCCESS: return { ...state, isFetching: false, error: errorEmptyObject, gitAccounts: state.gitAccounts.filter(gitAccount => gitAccount.gitAccountId !== action.gitAccountId) };
        case constants.DELETE_GIT_ACCOUNT_FAIL: return { ...state, isFetching: false, error: action.error };

        case constants.CONNECT_GIT_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.CONNECT_GIT_ACCOUNT_SUCCESS: 
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                gitAccountConnected: true
            };
        case constants.CONNECT_GIT_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };
        case constants.CONNECT_GIT_ACCOUNT_CLEAR: return { ...state, error: errorEmptyObject, isFetching: false, gitAccountConnected: false };

        default: {
            return state;
        }
    }
}
