import React from "react";

export interface ToggleButtonProps {
  name?: string;
  description?: string;
  text?: boolean;
  isChecked: boolean | undefined;
  onChange: () => void;
}

const ToggleButton = (props: ToggleButtonProps) => {
  return (
    <div>
      <div className="toggle">
        {props.name ? <span className="toggle__name">{props.name}</span> : null}
        <label className="toggle__btn">
          <input
            name="isReference"
            type="checkbox"
            checked={props.isChecked || false}
            onChange={props.onChange}
            tabIndex={-1}
          />
          <span className="toggle__slider" />
          <span className="toggle__slider-bg" />
          <span className="toggle__circle" />
        </label>
        <label className="toggle__text">
          {props.text ? props.isChecked ? "On" : "Off" : null}
        </label>
      </div>
      <div>
        {props.description ? <p className="checkbox__description"> {props.description}</p> : null}
      </div>
    </div>
  )
}

export default ToggleButton;