import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { useModal } from "react-modal-hook";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { Pipeline } from "@microtica/ms-ap-sdk";


// Import components and pages
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import DropdownMenuContainer, { DropdownMenuItemProps } from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import PipelineSpec from "../../pages/Pipelines/PipelineSpec";
import BuildList from "./BuildsList";
import StageStatus from "../../components/StageStatus/StageStatus";

import { GlobalState } from "../../reducers";
import { getContinuousIntegrationAPI } from '../../api';
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as EditIcon } from "../../static/edit-icon.svg";
import { ReactComponent as ClockIcon } from "../../static/clock-icon.svg";
import { ReactComponent as ErrorIcon } from "../../static/error-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";
import { ReactComponent as StartIcon } from "../../static/start-icon.svg";

import * as actions from "../../actions";
import usePipelineMetrics from "../../hooks/PipelineMetrics";


interface PipelineDetailsProps extends RouteComponentProps<{ pipelineId: string }> {
    pipelineId: string,
}
const PipelineDetails = (props: PipelineDetailsProps) => {

    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const params = new URLSearchParams(props.location.search);
    const [selectedScreen, setSelectedScreen] = useState(params.get("tab") || "spec");
    const pipelineId = props.match.params.pipelineId;
    const [pipeline, setPipeline] = useState<Pipeline>();
    const [title, setTitle] = useState("Pipeline details");
    const { isLoaded, pipelineMetrics } = usePipelineMetrics(pipelineId);

    // Update the selected screen to display different content (AWS, Git account, Management & Notifications)
    useEffect(() => {
        const headerItems = document.querySelectorAll(".settings-tab");
        headerItems.forEach(item => item.addEventListener("click", e => {
            const screen = item.getAttribute("data-screen") as string;
            setSelectedScreen(screen);
            props.history.replace(`/pipelines/${pipelineId}/details?tab=${screen}`);
        }))
    }, []);

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data: pipeline } = await getContinuousIntegrationAPI().getPipelineById(currentProject.id, pipelineId);
                setPipeline(pipeline);
                setTitle(pipeline.name);
            } catch (error) {
                toast.error(error.response ? error.response.data.message : (error.message || "Something went wrong!"));
            }
        };
        fetch();
    }, []);


    // Handle underline
    const [underline, setUnderline] = useState({
        left: 0,
        width: 0,
        opacity: 1,
        height: "4px"
    });

    const handleUnderline = (e: any) => {
        const elements = document.querySelectorAll(".settings-tab");
        const currentIndex = parseInt(e.currentTarget.dataset.index);
        const settingsTabs = [] as any;
        let elementWidth: number;

        elements.forEach((element, index) => {
            let elementProperties = element.getBoundingClientRect();
            elementWidth = elementProperties.width;

            settingsTabs.push({ elementWidth });
        })

        let underlineLeftOfsset = 0;
        elements.forEach((element, index) => {
            if (currentIndex > index) {
                underlineLeftOfsset += settingsTabs[index].elementWidth;
            }
        })

        let left = currentIndex === 0 ? 0 : underlineLeftOfsset;
        let width = settingsTabs[currentIndex].elementWidth;

        setUnderline({
            left: left,
            width: width,
            opacity: 1,
            height: "4px"
        })
    }
    // Remove tab menu underline when not hovering over it
    const removeUnderline = () => {
        setUnderline({
            ...underline,
            opacity: 0,
            height: "0px"
        })
    }

    const [showDeleteModal, hideDeleteModal] = useModal(() => (
        <ModalDanger
            title={`Are you sure you want to delete this pipeline?`}
            description="Do you really want to delete the pipeline? This action cannot be undone."
            action="Delete"
            onCancel={hideDeleteModal}
            onConfirm={handleDeleteConfirmation}
        />
    ));

    function handleDeleteConfirmation() {
        dispatch(actions.deletePipeline(currentProject.id, pipelineId));
        hideDeleteModal();
        props.history.push("/pipelines")
    }

    async function handlePipelineTrigger(pipelineId: string, ref: string) {
        try {
            const { data: build } = await getContinuousIntegrationAPI().triggerPipeline(currentProject.id, pipelineId, { ref });
            props.history.push(`/pipelines/${pipelineId}/builds/${build.buildId}`);
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    function handlePipelineEdit() {
        props.history.push(`/pipelines/${pipelineId}/edit`);
    }

    return (
        <main className="content d-flex flex-column justify-content-start">
            <PageHeader title={title} items={[
                <DropdownMenuContainer
                    key="dropdownActions"
                    actions={[
                        {
                            itemId: "2",
                            title: "Edit",
                            icon: <EditIcon />,

                            onClick: () => handlePipelineEdit()
                        },
                        {
                            itemId: "4",
                            title: "Delete",
                            icon: <RemoveIcon />,
                            onClick: () => showDeleteModal()
                        }
                    ]}
                />
                ,
                <DropdownMenuContainer
                    key="triggerPipeline"
                    actions={[]}
                    title="Run from branch:"
                    icon={<RemoveIcon />}
                    onClick={async () => {
                        const { data: { branches } } = await getContinuousIntegrationAPI().listGitAccountRepositoryBranches(
                            currentProject.id,
                            pipeline!.gitAccountId!,
                            pipeline!.repositoryUrl!
                        );
                        return branches.map(branch => ({
                            itemId: branch,
                            title: branch,
                            onClick: () => handlePipelineTrigger(pipelineId, `refs/heads/${branch}`)
                        })) as DropdownMenuItemProps[];
                    }}
                    type="button"
                    buttonText="Trigger pipeline"
                />
            ]} />
            <div className="content__body">
                <div className="details__status">
                    <StageStatus
                        class="stages__info-wrapper-"
                        items={[
                            {
                                id: 1,
                                title: "Successful builds",
                                icon: <CheckmarkIcon />,
                                text: isLoaded ? `${pipelineMetrics!.succesfullExecutions}` : <Skeleton width="70px" />,
                            },
                            {
                                id: 2,
                                title: "Failed builds",
                                icon: <ErrorIcon />,
                                text: isLoaded ? `${pipelineMetrics!.failedExecutions}` : <Skeleton width="70px" />,
                            },
                            {
                                id: 3,
                                title: "Success rate",
                                icon: <StartIcon />,
                                text: isLoaded ? `${pipelineMetrics!.successRate.toFixed(2)} %`
                                    : <Skeleton width="70px" />,
                            },
                            {
                                id: 4,
                                title: "Pipeline build time",
                                icon: <ClockIcon />,
                                text: isLoaded ? `${pipelineMetrics!.buildTime.toFixed(2)} mins` : <Skeleton width="70px" />,
                            },

                        ]}
                    />
                </div>
                <br />
                <Card class="card card--settings dark">
                    <div className="card__header-wrapper card__header-wrapper--replicate card--underline d-flex justify-content-between align-items-center">

                        <div className="row no-gutters width--full pl--15 ">
                            <div
                                className={`col-2 settings-tab ${selectedScreen === "spec" ? 'active' : ''}`}
                                data-index="0"
                                onMouseEnter={e => handleUnderline(e)}
                                onMouseLeave={() => removeUnderline()}
                                data-screen="spec"
                            >
                                <h5 className="mb--5"><strong>Pipeline spec</strong></h5>
                                <p className="m--0">View/Edit pipeline yaml</p>
                            </div>

                            <div
                                className={`col-2 settings-tab ${selectedScreen === "builds" ? 'active' : ''}`}
                                data-index="1"
                                onMouseEnter={e => handleUnderline(e)}
                                onMouseLeave={() => removeUnderline()}
                                data-screen="builds"
                            >
                                <h5 className="mb--5"><strong>Builds</strong></h5>
                                <p className="m--0">Pipeline builds</p>
                            </div>
                            <div className="col-6"></div>
                        </div>
                        <div className="underline" style={{
                            position: "absolute",
                            bottom: '2px',
                            backgroundColor: "#007dc5",
                            height: underline.height,
                            opacity: underline.opacity,
                            left: underline.left,
                            width: underline.width,
                            transition: "0.5s ease"
                        }}></div>
                    </div>

                    {/* DISPLAY CONTENT BASED ON SELECTED TAB */}
                    {selectedScreen === "spec" ? <PipelineSpec {...props} /> :
                        selectedScreen === "builds" && pipeline ? <BuildList {...props} pipeline={pipeline} /> :
                            <p>No tab selected</p>}
                </Card>

            </div>

        </main >
    )
}

export default PipelineDetails;