import * as constants from "../../constants";
import { getProjectAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { UpdateUserPermissionsRequest } from "@microtica/ms-project-sdk";
import { toast } from "react-toastify";

export interface UpdateUserPermmissonsOnProjectStart {
    type: constants.UPDATE_USER_PERMISSIONS_START;
}
export interface UpdateUserPermmissonsOnProjectSuccess {
    type: constants.UPDATE_USER_PERMISSIONS_SUCCESS
    object: UpdateUserPermissionsRequest & { userId: string };
}
export interface UpdateUserPermmissonsOnProjectError {
    type: constants.UPDATE_USER_PERMISSIONS_FAIL;
    error: ErrorInterface;
}

export type UpdateUserPermissionsOnProject =
    | UpdateUserPermmissonsOnProjectStart
    | UpdateUserPermmissonsOnProjectSuccess
    | UpdateUserPermmissonsOnProjectError;


export function updateUserPermmissonsOnProjectStart(): UpdateUserPermissionsOnProject {
    return {
        type: constants.UPDATE_USER_PERMISSIONS_START
    };
}
export function updateUserPermmissonsOnProjectError(error: ErrorInterface): UpdateUserPermissionsOnProject {
    return {
        type: constants.UPDATE_USER_PERMISSIONS_FAIL,
        error
    };
}
export function updateUserPermmissonsOnProjectSuccess(
    object: UpdateUserPermissionsRequest & { userId: string }
): UpdateUserPermissionsOnProject {
    return {
        type: constants.UPDATE_USER_PERMISSIONS_SUCCESS,
        object
    };
}
export function updateUserPermissionsOnProject(
    projectId: string,
    userId: string,
    object: UpdateUserPermissionsRequest
): ThunkAction<Promise<void>, {}, {}, UpdateUserPermissionsOnProject> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateUserPermissionsOnProject>) => {
        dispatch(updateUserPermmissonsOnProjectStart());
        try {
            await getProjectAPI().updateUserPermissions(projectId, userId, object)
            toast.success("Successful updated user permissions");
            dispatch(updateUserPermmissonsOnProjectSuccess({ userId, ...object }))
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch(updateUserPermmissonsOnProjectError(error.response.data));
        }
    };
}