import React, { ReactElement } from "react";

// Import components
import DropdownMenuContainer from "../DropdownMenuContainer/DropdownMenuContainer";
import { ReactComponent as KubernetesLogo } from "../../static/kubernetesIcon.svg";

export interface ListItemProps {
  itemId: string;
  title: string;
  description: string;
  footer?: ReactElement;
  type?: string; // Used to mark the element as type Kubernetes to be able to differentiate from other elements.
  profileImage?: any;
  length?: any;
  index?: any;
  email?: string;
  status?: string;
  listType?: string;
  itemActions?: ListItemAction[];
  icon?: ReactElement;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface ListItemAction {
  itemId: string;
  title: string;
  icon: React.ReactElement;
  onClick: (itemId: string, title?: string) => void;
}

const ListItem = (props: ListItemProps) => {
  let icons;

  switch (true) {
    case (props.listType === "status"):
      icons = (
        <div className="list__icons">
          <span className={`circle circle--xs circle--${props.status}`}></span>
        </div>
      )
      break;

    case (props.listType === "icons" || props.listType === "icons_components" || props.listType === "management"):
      icons = (
        <div className="list__icons">
          {
            props.itemActions && props.itemActions.length ?
              <DropdownMenuContainer actions={props.itemActions} parentTitle={props.title}/> :
              null
          }

        </div>
      )
      break;

    case (props.listType === "stage"):
      icons = (
        <div className="list__icons">
          <span className={`circle circle--placeholder`}>+</span>
        </div>
      )
      break;
  }

  return (
    <div
      className={`list__item ${props.length > 1 && props.length === props.index + 1 ? 'card--last' : ''} ${props.type === 'kubernetes' ? 'kubernetes' : ''}`}
      onClick={props.onClick}
    >
      {props.listType === "management" ?
        <div className="list__management">
          <img className="circle circle--lg circle__image" src={props.profileImage} alt="profileImage" />

          <div className="list__column">
            <h6>
              {props.title && props.title !== " " ? <strong>{props.title}</strong> : "N/A"}
            </h6>
          </div>

          <div className="list__column">
            <p className="mb--0 txt--white">{props.email}</p>
          </div>

          <div className="list__column">
            <p className="mb--0 txt--white">{props.description}</p>
          </div>
          {
            props.footer ?
              <div className="list__column">
                {props.footer}
              </div> : null
          }
        </div>
        :
        <div className={`list__text ${props.listType === "icons_components" ? 'd-flex flex-row' : ''}`}>
          {props.icon ? <div>{props.icon}</div> : null}

          <>
            <div className="list__text">
              <h5 className="d-flex align-items-center">
                {props.title}
                {props.type === "kubernetes" ? <KubernetesLogo className="ml--10" height="16px" width="16px" /> : null}
              </h5>
              <p className="mb--0">{props.description}</p>
              <div>
                {
                  props.footer ?
                    <div className="mt--10">
                      {props.footer}
                    </div> : null
                }
              </div>
            </div>
          </>
        </div>
      }

      {/* Render icons or status circle */}
      {props.listType ? icons : null}
    </div>
  );
};

export default ListItem;