import * as React from 'react';

export interface PageContentProps {
  children: any;
  className?: string;
}


function PageContent(props: PageContentProps) {

  return (
    <div className={`page-content ${props.className ? props.className : null}`}>
      {props.children}
    </div>
  );
}


export default PageContent;