import * as constants from "../../constants";
import { PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import { getContinuousIntegrationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";


export interface FetchBuildsStart {
  type: constants.FETCH_BUILDS_START;
}

export interface FetchBuildsSuccess {
  type: constants.FETCH_BUILDS_SUCCESS;
  builds: PipelineBuildDetails[];
  offset?: number;
  limit: number;
}

export interface FetchBuildsFail {
  type: constants.FETCH_BUILDS_FAIL;
  error: ErrorInterface;
}

export interface ClearBuilds {
  type: constants.CLEAR_BUILDS;
}

export type FetchBuilds =
  | FetchBuildsStart
  | FetchBuildsSuccess
  | FetchBuildsFail;

export function fetchBuildsStart(): FetchBuilds {
  return {
    type: constants.FETCH_BUILDS_START
  };
}

export function fetchBuildsSuccess(
  builds: PipelineBuildDetails[] = [],
  limit: number,
  offset?: number
): FetchBuildsSuccess {
  return {
    type: constants.FETCH_BUILDS_SUCCESS,
    builds,
    offset,
    limit
  };
}

export function fetchBuildsFail(error: ErrorInterface): FetchBuildsFail {
  return {
    type: constants.FETCH_BUILDS_FAIL,
    error
  };
}

export function clearBuilds(): ClearBuilds {
  return {
    type: constants.CLEAR_BUILDS
  };
}

export function fetchBuilds(
  projectId: string,
  pipelineId: string,
  limit: number,
  offset?: number
): ThunkAction<Promise<void>, {}, {}, FetchBuilds> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchBuilds>) => {
    dispatch(fetchBuildsStart());
    try {
      const res = await getContinuousIntegrationAPI()
        .getPipelineBuilds(projectId, pipelineId, offset, limit);
      dispatch(fetchBuildsSuccess(res.data.builds, limit, offset!));
    } catch (error: any) {
      dispatch(fetchBuildsFail(error.response.data));
    }
  };
}

