import React from "react";

interface DonutChartProps {
  percentage: string | number;
  title: string;
  usage: number[];
}

const DonutChart = (props: DonutChartProps) => {
  const { usage, percentage, title} = props;

  return (
    <div className="donut">
      <h3>{title}</h3>
      <svg width="180px" height="180px" viewBox="0 0 42 42">
        <defs>
          <linearGradient id="progressGradient" gradientTransform="rotate(90)">
            <stop offset="5%"  stopColor="#007dc5" />
            <stop offset="95%" stopColor="#2ec1ff" />
          </linearGradient>
        </defs>

        <circle className="donut__hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
        <circle className="donut__ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#101b21" strokeWidth="3"></circle>

        <circle className="donut__segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="url('#progressGradient')" strokeWidth={`${percentage === 0 ? '0px' : '3px'}`} stroke-dasharray={usage} strokeDashoffset="60%" strokeLinecap="round"></circle>

        <g className="donut__text">
          <text x="50%" y="47%" className="donut__number">{percentage ? `${percentage}%` : "No data"}</text>
        </g>
      </svg>
    </div>
  )
}

export default DonutChart;