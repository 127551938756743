import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";


export default function useStageList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { stages, isFetching, error } = useSelector((state: GlobalState) => state.stage);

    // Get a list of stages from the backend
    useEffect(() => {
        dispatch(actions.fetchStages(currentProject.id));
    }, [dispatch]);

    return {
        stages,
        isFetching,
        error
    }
}
