import { errorEmptyObject, NotificationState } from '../types/index';
import * as constants from '../constants';
import { FetchUserNotifications } from '../actions/notifications/notifications-list';
import { MarkNotificationAsRead } from '../actions';
import { MarkNotificationsAsRead } from '../actions/notifications/mark-notifications-as-read';

export const initialState: NotificationState = {
    notifications: [],
    isProcessing: false,
    error: errorEmptyObject,
}

export default function reducer(state: NotificationState = initialState, action: FetchUserNotifications | MarkNotificationAsRead | MarkNotificationsAsRead): NotificationState {
    switch (action.type) {

        // Fetch user notifications
        case constants.FETCH_USER_NOTIFICATIONS_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_USER_NOTIFICATIONS_SUCCESS:
            return {
                ...state, notifications: action.noitifications, isProcessing: false, error: errorEmptyObject
            };
        case constants.FETCH_USER_NOTIFICATIONS_FAIL: return { ...state, error: action.error, };

        // Mark notification as read
        case constants.MARK_NOTIFICATION_AS_READ_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.MARK_NOTIFICATION_AS_READ_SUCCESS:
            return {
                ...state,
                notifications: [...state.notifications.map(notification => {
                    if (notification.id === action.notificationId) {
                        return { ...notification, isRead: true }
                    }
                    return notification;
                })],
                isProcessing: false,
                error: errorEmptyObject
            };
        case constants.MARK_NOTIFICATION_AS_READ_FAIL: return { ...state, error: action.error, };


        // Mark all notifications as read
        case constants.MARK_NOTIFICATIONS_AS_READ_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
            return {
                ...state,
                notifications: [...state.notifications.map(notification => {
                    return { ...notification, isRead: true }
                })],
                isProcessing: false,
                error: errorEmptyObject
            };
        case constants.FETCH_USER_NOTIFICATIONS_FAIL: return { ...state, error: action.error };

        default: {
            return state;
        }
    }
}
