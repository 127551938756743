import React, { useState, useEffect, ReactElement } from 'react';
import useOnClickOutside from "../../hooks/clickOutside";

import CustomScrollbars from '../CustomScrollbars/CustomScrollbars';
import Button from "../Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";
import { ReactComponent as LinkIcon } from "../../static/link.svg";
import { ReactComponent as LinkOffIcon } from "../../static/unlink-icon.svg";

export interface DropdownItem {
  id: string;
  name: string;
  subTitle?: string | ReactElement;
  suggestionScore?: number;
  isExternalCluster?: boolean;
  hideName?: boolean;
  plainText?: string;
}

export interface Props {
  items: DropdownItem[];
  onSelectItem: (item: DropdownItem) => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  selectedItem?: DropdownItem;
  disabled?: boolean;
  label?: string;
  info?: string;
  icon?: string;
  type?: "text" | "password";
  placeholder?: string;
  value?: string;
  tabIndex?: number;
  initialFilter?: string;
  optional?: boolean;
}

const blackArrowStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function TextOrDropdown(props: Props) {
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState(props.value || "");
  const [selected, setSelected] = useState("");
  const [connect, setConnect] = useState(props.selectedItem ? true : false);

  useEffect(() => {
    if (props.selectedItem) {
      setSelected(props.selectedItem.name)
    }
  }, [props.selectedItem])

  const clickHandler = () => {
    if (!props.disabled && connect) {
      setOpen(!open);
    }
  };

  const connectHandler = () => {
    props.onSelectItem({ id: "", name: "" });
    props.onChange && props.onChange("");

    if (!connect) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    setSelected("");
    setSearchedText("");
    setConnect(!connect)
  }

  const selectItemHandler = (item: DropdownItem) => {
    clickHandler();

    // Clear the value for the searched text
    setSearchedText("");

    // Set the value of the input as the selected item from the dropdown
    setSelected(item.name);

    props.onSelectItem(item);
  }

  useOnClickOutside(ref, () => setOpen(false));

  // Prevent going back when pressed on Enter if dropdown menu is open
  React.useEffect(() => {
    const dropdownReference: any = ref.current;

    window.addEventListener("keydown", e => {
      if (dropdownReference.classList.contains("open") && e.key === "Enter") {
        e.preventDefault();
      }
    })
  }, [])

  let classes = "dropdown";
  if (open) {
    classes = classes + ' open';
  }

  const handleSearchedText = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update searched text state
    !selected && setSearchedText(e.target.value);

    if (!connect) {
      props.onChange && props.onChange(e.target.value);
    }

    // If searched text is empty, clear the selected item
    if (e.target.value === "") {
      props.onSelectItem({ id: "", name: "" });
    }
  }

  return (
    <>
      <div className={classes} ref={ref} tabIndex={-1}>
        {
          props.label ?
            <>
              <label className="input__label">
                {props.label}
                {
                  props.info ? <span className="tooltip" data-tooltip={props.info}><InfoIcon /></span> : null
                }
              </label>
              {
                props.optional ?
                  <span style={{ float: "right", color: "#a5b8ca" }}>(optional)</span> :
                  null
              }
            </>
            : null
        }
        <div className={`dropdown__btn ${props.items.length === 0 ? 'dropdown__btn--disabled' : ''}`}
          onClick={clickHandler}
          tabIndex={-1}
        >
          <input
            className="dropdown__input input__field mb--0 input__field"
            type={props.type || "text"}
            autoComplete="new-password"
            value={selected || searchedText}
            placeholder={props.placeholder ? props.placeholder : connect ? "Select item" : "Enter text"}
            onChange={(e => { handleSearchedText(e); setOpen(connect ? true : false) })}
            onKeyPress={e => e.key === "Enter" && e.preventDefault()}
            disabled={props.disabled}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            tabIndex={props.tabIndex}
          />
          <Button
            className={`btn btn--linked btn${connect ? "--blue" : "--darker"}`}
            children={
              <Tooltip
                title={
                  connect ?
                    <h6>Remove the component reference and switch to plain value mode.</h6> :
                    <h6>Reference the value of this parameter from other component's output. Values will be automatically provided during deployment.</h6>
                }
                classes={blackArrowStyles()}
                arrow
                placement="top-end"
              >
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {
                    connect ?
                      <LinkOffIcon style={{ width: "80%", height: "80%" }} />
                      :
                      <LinkIcon style={{ width: "50%", height: "50%" }} />
                  }
                </div>
              </Tooltip>
            }
            onClick={() => connectHandler()}
          />
        </div>

        <ul className="dropdown__content" tabIndex={-1}>
          <CustomScrollbars maxHeight="300px" resetClass="reset--top">
            {/* Filter out items that dont match typed text */}
            {props.items.filter(item => (item.plainText || item.name).toString().toLowerCase().includes(
              (searchedText || props.initialFilter || "").toString().toLowerCase()
            )).map((item, index) => (
              <li
                className={`dropdown__item ${props.selectedItem && item.id === props.selectedItem.id ? "selected" : ""}`}
                key={index}
                onClick={() => selectItemHandler(item)}
              >
                {
                  !item.hideName ?
                    <span className="dropdown__maintext">
                      {item.name}
                    </span> : null
                }

                <div className="dropdown__sidetext">
                  {item.suggestionScore && item.suggestionScore > 0.65 ? <small>suggested</small> : null}
                </div>
                {/* Item subtitle (if it exists) */}
                {
                  item.subTitle ?
                    <div>
                      <small className="dropdown__subtitle">{item.subTitle}</small>
                    </div>
                    : null
                }
              </li>
            ))}
          </CustomScrollbars>
        </ul>
      </div >
      {connect && !selected && searchedText ?
        <label style={{ color: "orange", fontSize: "12px" }}>
          Must select from suggested items in select mode*
        </label>
        :
        null
      }
    </>
  );
}


export default TextOrDropdown;