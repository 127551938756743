import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../../types";
import { GetGcpAccountResponse } from "@microtica/ms-aws-sdk";

export interface FetchGcpAccountsStart {
  type: constants.FETCH_GCP_ACCOUNTS_START;
}

export interface FetchGcpAccountsSuccess {
  type: constants.FETCH_GCP_ACCOUNTS_SUCCESS;
  gcpAccounts: GetGcpAccountResponse[];
}

export interface FetchGcpAccountsFail {
  type: constants.FETCH_GCP_ACCOUNTS_FAIL;
  error: ErrorInterface;
}

export type FetchGcpAccounts =
  | FetchGcpAccountsStart
  | FetchGcpAccountsSuccess
  | FetchGcpAccountsFail;

export function fetchGcpAccountsStart(): FetchGcpAccounts {
  return {
    type: constants.FETCH_GCP_ACCOUNTS_START
  };
}

export function fetchGcpAccountsSuccess(
  gcpAccounts: GetGcpAccountResponse[] = []
): FetchGcpAccountsSuccess {
  return {
    type: constants.FETCH_GCP_ACCOUNTS_SUCCESS,
    gcpAccounts
  };
}

export function fetchGcpAccountsFail(error: ErrorInterface = errorEmptyObject): FetchGcpAccountsFail {
  return {
    type: constants.FETCH_GCP_ACCOUNTS_FAIL,
    error: error
  };
}

export function fetchGcpAccounts(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchGcpAccounts> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchGcpAccounts>) => {
    dispatch(fetchGcpAccountsStart());
    try {
      const res = await getAwsAPI().getGcpAccounts(projectId);
      dispatch(fetchGcpAccountsSuccess(res.data.gcpAccounts));
    } catch (error) {
      dispatch(fetchGcpAccountsFail(error.response.data));
    }
  };
}
