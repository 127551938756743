import React from "react";
import Table from "../../components/Table/Table";

interface TableWidgetProps {
    title: React.ReactElement | string;
    subtitle: React.ReactElement | string;
    tableHeaders: string[];
    tableRows: (string | number | JSX.Element)[][];
}

const TableWidget = (props: TableWidgetProps) => {
    return (
        <div className="widget widget-table">
            <div className="widget-header">
                <div className="widget-title">{props.title}</div>
                <div className="widget-subtitle">{props.subtitle}</div>
            </div>
            <div className="widget-content">
                <Table
                    headers={props.tableHeaders}
                    rows={props.tableRows}
                    isLoaded={true}
                />
            </div>
        </div>
    )
};
export default TableWidget;