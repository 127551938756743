import { FetchStages, FetchStageDetails } from '../actions';
import { StageState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';
import { CreateStage } from '../actions/stages/create-stage';
import { DeleteStage } from '../actions/stages/delete-stage';
import { UndeployStage } from '../actions/stages/undeploy-stage';
import { UpdateStageStatus } from '../actions/stages/update-stage';

export const initialState: StageState = {
    stages: [],
    stageDetails: {
        id: "",
        name: "",
        description: "",
        dateCreated: 0,
        dateUpdated: 0,
        lastModified: 0,
        status: "",
        awsAccountId: "",
        awsRegion: "",
        resources: [],
        infrastructureAsCodeTool: "",
        cloudProvider: ""
    },
    createdStage: "",
    isFetching: false,
    error: errorEmptyObject
}


export default function reducer(state: StageState = initialState, action: FetchStages | FetchStageDetails | CreateStage | DeleteStage | UndeployStage | UpdateStageStatus): StageState {
    switch (action.type) {
        case constants.FETCH_STAGES_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_STAGES_SUCCESS: return { ...state, stages: action.stages, isFetching: false, error: errorEmptyObject };
        case constants.FETCH_STAGES_FAIL: return { ...state, error: action.error.message ? action.error : { code: 0, message: "Something went wrong!" } };

        case constants.FETCH_STAGE_DETAILS_START: return { ...state, stageDetails: state.stageDetails, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_STAGE_DETAILS_SUCCESS: return { ...state, stageDetails: action.stageDetails, isFetching: false, error: errorEmptyObject };
        case constants.FETCH_STAGE_DETAILS_FAIL: return { ...state, error: action.error };

        case constants.CREATE_STAGE_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.CREATE_STAGE_SUCCESS: return { ...state, createdStage: action.data.id, isFetching: false, error: errorEmptyObject };
        case constants.CREATE_STAGE_FAIL: return { ...state, error: action.error };

        case constants.DELETE_STAGE_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.DELETE_STAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                stages: state.stages.filter(stage => stage.id !== action.stageId)
            };

        case constants.UPDATE_STAGE_STATUS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                stages: state.stages.map(stage => {
                    if (stage.id !== action.stageId) {
                        return stage;
                    } else {
                        return {
                            ...stage,
                            status: action.status
                        };
                    }
                })
            };
        case constants.DELETE_STAGE_FAIL: return { ...state, error: action.error };

        case constants.UNDEPLOY_STAGE_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.UNDEPLOY_STAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject
            };
        case constants.UNDEPLOY_STAGE_FAIL: return { ...state, error: action.error };

        default: {
            return state;
        }
    }
}
