
import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { AddDockerRegistryRequest } from "@microtica/ms-ap-sdk";

import { getContinuousIntegrationAPI } from "../../api";
import { GlobalState } from "../../reducers";

import { DockerRegistryModalProps, DockerRegistryObj, DockerRegistryValues } from "../../types/DockerRegistry";
import { dockerProviderList, DockerProviderType, getInitialCredentials, getDockerProvider, getRegistryPayload, modifyRetrievedCredentials, getValidationSchema } from "../../utils/docker-providers";

import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import { DockerHub, ECR, GitlabContainerRegistry } from "../../components/DockerRegistry"
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import { InputField } from "../../components/InputField/InputField";
import PageContent from "../../components/PageContent/PageContent";
import PageHeader from "../../components/PageHeader/PageHeader";
import ToggleButton from "../../components/ToggleButton/ToggleButton";


const AddDockerRegistry = (props: DockerRegistryModalProps) => {

  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);

  const [_, setIsFetching] = useState<boolean>(props.mode == "edit");
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [dockerRegistry, setDockerRegistry] = useState<DockerRegistryObj | undefined>();

  const fetch = async () => {
    try {
      const { data: registry } = await getContinuousIntegrationAPI().getDockerRegistryWithoutSensitiveCredentials(
        currentProject.id,
        props.match.params.registryName
      );

      setDockerRegistry({
        ...registry,
        credentials: modifyRetrievedCredentials(registry)
      });
    } catch (error) {
      toast.error(error.response ? error.response.data.message : (error.message || "Something went wrong!"));
    }
    setIsFetching(false);
  };

  const handleSubmit = async (values: DockerRegistryValues) => {
    try {
      const registryPayload: AddDockerRegistryRequest = getRegistryPayload(values);

      if (props.mode !== "edit") {
        await getContinuousIntegrationAPI().addDockerRegistry(currentProject.id, registryPayload);
        toast.success(`You have successfully added registry ${values.name}`);
      } else {
        await getContinuousIntegrationAPI().updateDockerRegistry(
          currentProject.id,
          values.name,
          {
            ...registryPayload,
            credentials: registryPayload.credentials.filter(k => !k.sensitive || k.value != "(sensitive)")
          });
        toast.success(`You have successfully edited registry ${values.name}`);
      }
      props.history.push("/kubernetes/docker-registries");
    }
    catch (error) {
      toast.error(error.response ? error.response.data.message : (error.message || "Something went wrong!"));
    }
  }

  useEffect(() => {
    if (props.mode === "edit") {
      fetch();
    }
    return () => { }
  }, [])

  const initialValues = {
    name: dockerRegistry?.registryName ?? "",
    is_default: dockerRegistry?.isDefault ?? false,
    docker_provider: getDockerProvider(dockerRegistry),
    ...getInitialCredentials(dockerRegistry),
  } as DockerRegistryValues;

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        titlePosition="center"
        title={props.mode === "edit" ? "Edit Docker Registry" : "Add Docker Registry"}
      />
      <div className="content__body">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async values => {
            setIsBusy(true);
            await handleSubmit(values);
            setIsBusy(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={() => yup.lazy<any>((values) => getValidationSchema(values.docker_provider.name))}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <PageContent>
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader title="General" text="General information for the registry" />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="name"
                          type="text"
                          label="Name"
                          disabled={props.mode === "edit"}
                          hasError={true}
                          placeholder="Enter registry name here"
                          component={InputField}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("name", e.target.value)}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader title="Registry" text="General information for the registry" />
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="docker_provider"
                        >
                          {() => (
                            <DropdownContainer
                              items={dockerProviderList}
                              disabled={props.mode === "edit"}
                              label="Registry Provider"
                              placeholder="Choose Registry Provider"
                              selectedItem={values.docker_provider}
                              onSelectItem={(item) => {
                                setFieldValue("docker_provider", item);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      {
                        values.docker_provider.name === DockerProviderType.DOCKER_HUB ?
                          <DockerHub setFieldValue={setFieldValue} /> :
                          values.docker_provider.name === DockerProviderType.ECR ?
                            <ECR setFieldValue={setFieldValue} awsRegion={values.aws_region} /> :
                            <GitlabContainerRegistry setFieldValue={setFieldValue} gitlabHierarchyType={values.gitlab_hierarchy_type} />
                      }
                      <div className="page-content__dropdown-container">
                        <div className="slack">
                          <h5 className="d-flex align-items-center">
                            <strong>Default registry</strong>
                          </h5>
                          <p>
                            If you mark the registry as default, when composing pipelines, if you don’t specify the registry the default registry will be used automatically
                          </p>
                          <Field
                            name="is_default"
                          >
                            {() => (
                              <ToggleButton
                                isChecked={values.is_default}
                                onChange={() => {
                                  setFieldValue("is_default", !values.is_default);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </PageContent>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  type="submit"
                  className="btn btn--md btn--lightBlue btn--create"
                  isBusy={isBusy}
                  busyText={props.mode !== "edit" ? "Creating.." : "Updating.."}
                >
                  {props.mode !== "edit" ? "Create Registry" : "Update Registry"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main >
  )
}

export default AddDockerRegistry;