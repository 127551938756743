import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../../types";
import { GetAzureAccountResponse } from "@microtica/ms-aws-sdk";

export interface FetchAzureAccountsStart {
  type: constants.FETCH_AZURE_ACCOUNTS_START;
}

export interface FetchAzureAccountsSuccess {
  type: constants.FETCH_AZURE_ACCOUNTS_SUCCESS;
  azureAccounts: GetAzureAccountResponse[];
}

export interface FetchAzureAccountsFail {
  type: constants.FETCH_AZURE_ACCOUNTS_FAIL;
  error: ErrorInterface;
}

export type FetchAzureAccounts =
  | FetchAzureAccountsStart
  | FetchAzureAccountsSuccess
  | FetchAzureAccountsFail;

export function fetchAzureAccountsStart(): FetchAzureAccounts {
  return {
    type: constants.FETCH_AZURE_ACCOUNTS_START
  };
}

export function fetchAzureAccountsSuccess(
  azureAccounts: GetAzureAccountResponse[] = []
): FetchAzureAccountsSuccess {
  return {
    type: constants.FETCH_AZURE_ACCOUNTS_SUCCESS,
    azureAccounts
  };
}

export function fetchAzureAccountsFail(error: ErrorInterface = errorEmptyObject): FetchAzureAccountsFail {
  return {
    type: constants.FETCH_AZURE_ACCOUNTS_FAIL,
    error: error
  };
}

export function fetchAzureAccounts(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchAzureAccounts> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchAzureAccounts>) => {
    dispatch(fetchAzureAccountsStart());
    try {
      const res = await getAwsAPI().getAzureAccounts(projectId);
      dispatch(fetchAzureAccountsSuccess(res.data.azureAccounts));
    } catch (error) {
      dispatch(fetchAzureAccountsFail(error.response.data));
    }
  };
}
