import * as React from "react";
import BusyIcon from "../../static/busy-indicator.svg";
interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  radius?: number;
  disabled?: boolean
  size?: "small" | "medium" | "big";
  look?: "blue" | "lightBlue" | "green";
  isBusy?: boolean;
  busyText?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button = (props: ButtonProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const {isBusy, busyText, ...buttonProps} = props;

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();
    props.onClick && props.onClick(event);

    if (buttonRef) {
      const buttonClass = buttonRef.current as HTMLButtonElement;
      buttonClass.classList.add("btn--clicked");
      buttonClass.classList.add("disabled");

      setTimeout(() => {
        buttonClass.classList.remove("btn--clicked");
        buttonClass.classList.remove("disabled");
      }, 500);
    }
  }

  return (
    <button style={
      isBusy ?
        {
          backgroundImage: `url(${BusyIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "center",
          backgroundPositionX: "8%"
        } : {}} {...buttonProps} disabled={props.disabled || isBusy} onClick={props.onClick && handleClick} ref={buttonRef} >
      { isBusy ?
        busyText
        :
        props.children}
    </button >
  )
}

export default Button;
