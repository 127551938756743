import React, { useState, ReactElement } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, RouteComponentProps } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import { ListKubernetesWithDetailsResponseKuberneteses } from '@microtica/ms-kube-sdk';

import { GlobalState } from "../../reducers";
import PageHeader from '../../components/PageHeader/PageHeader';
import CustomScrollbars from '../../components/CustomScrollbars/CustomScrollbars';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/CardHeader/CardHeader';
import DropdownMenuContainer from '../../components/DropdownMenuContainer/DropdownMenuContainer';
import CardPlaceholders from "../../components/Card/CardPlaceholders";

import useKubernetesesList from '../../hooks/Kubernetes';
import { default as KubernetesDetails } from "../../components/StageStatus/StageStatus";

// Import icons for DropdownMenuContainer
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import KubernetesDashboardScreen from "../../static/kubernetes-dashboard.png";
import ModalDanger from '../../components/ModalDanger/ModalDanger';
import { deleteKubernetes } from '../../actions';
import KubernetesModal from '../../components/KubernetesModal/KubernetesModal';
import Button from '../../components/Button/Button';
import { trackKubeConnectInit } from '../../tracking/kubernetes';


const Kubernetes = (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [selectedKube, setSelectedKube] = useState<ListKubernetesWithDetailsResponseKuberneteses>();
    const { kuberneteses: kubes, isProcessing, disabledKubernetesDashboard: disabledKubernetesDashboard } = useKubernetesesList();

    const addKube = <Button
        key="addKubernetes"
        className="btn btn--md btn--link btn--blue"
        disabled={disabledKubernetesDashboard}
        onClick={() => {
            trackKubeConnectInit();
            showAddKubernetesModal();
        }}>Connect Kubernetes</Button>

    const [showAddKubernetesModal, hideAddKubernetesModal] = useModal(() => (
        <KubernetesModal
            kubernetes={selectedKube}
            onClose={handleHideAddKubernetesModal}
        />
    ), [selectedKube]);

    const [showDeleteModal, hideDeleteModal] = useModal(() => (
        <ModalDanger
            title={`Are you sure you want to delete "${selectedKube!.name}" kubernetes cluster?`}
            description="Do you really want to delete the kubernetes cluster. This action cannot be undone."
            action="Delete"
            onCancel={hideDeleteModal}
            onConfirm={handleDeleteConfirmation}
        />
    ), [selectedKube]);

    function handleHideAddKubernetesModal() {
        setSelectedKube(undefined);
        hideAddKubernetesModal();
    }

    function handleDeleteConfirmation() {
        dispatch(deleteKubernetes(currentProject.id, selectedKube!.id));
        setSelectedKube(undefined);
        hideDeleteModal();
    }

    function handleKubernetesDelete(kube: ListKubernetesWithDetailsResponseKuberneteses) {
        setSelectedKube(kube);
        showDeleteModal();
    }

    function handleKubernetesUpdate(kube: ListKubernetesWithDetailsResponseKuberneteses) {
        setSelectedKube(kube);
        showAddKubernetesModal();
    }

    const Skeleton = () => (
        <Card class="card card--stages dark">
            <div className="skeleton skeleton__header">
                <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
                <p className="skeleton skeleton--animation skeleton--placeholder"></p>
            </div>

            <CardPlaceholders />
        </Card>
    );

    const EmptyBox = () => (
        <div className="page-centered page-centered--project" style={{ paddingTop: "5%" }}>
            <img src={KubernetesDashboardScreen} alt="kubernetes dashboard" />
            <div className="page-centered__title">Deploy and Monitor apps on Kubernetes</div>
            <p>
                Setup a complete Kubernetes infrastructure or connect your existing cluster. Configure, deploy and monitor applications from a single place.
                Fully integrated with Microtica pipelines so you can easily define your automation to build, test and deploy applications on Kubernetes.
            </p>
            {
                disabledKubernetesDashboard ?
                    <Link className="btn btn--xl" to="/settings?tab=billing">
                        Upgrade Plan
                    </Link> :
                    <Button className="btn btn--xl btn--green" onClick={() => { trackKubeConnectInit(); showAddKubernetesModal() }}>
                        Connect Kubernetes
                    </Button>
            }
        </div>
    );

    const Clusters = () => (
        <CustomScrollbars maxHeight="calc(100vh - 120px)" resetClass="reset--top">
            <Card class="card card--stages dark">
                {kubes && kubes.map(kube => {
                    const items: { id: number; title: string | ReactElement; text: string; }[] = [
                        {
                            id: 1,
                            title: "Kube Type",
                            text: kube.type.charAt(0).toUpperCase() + kube.type.slice(1)
                        }
                    ]
                    if (kube.podStatus) {
                        items.push(
                            {
                                id: 2,
                                title: "Total Nodes",
                                text: kube.nodesStatus ? kube.nodesStatus!.totalNodes.toString() : ""
                            },
                            {
                                id: 3,
                                title: "Total Pods",
                                text: kube.podStatus.totalPods.toString()
                            },
                            {
                                id: 4,
                                title: <div className={kube.podStatus.pendingPods > 0 ? "txt--yellow" : ""}>
                                    Pending Pods
                                </div>,
                                text: kube.podStatus.pendingPods.toString()
                            },
                            {
                                id: 5,
                                title: "Running Pods",
                                text: kube.podStatus.runningPods.toString()
                            },
                            {
                                id: 6,
                                title: <div className={kube.podStatus.failedPods > 0 ? "txt--red" : ""}>
                                    Failed pods
                                </div>,
                                text: kube.podStatus.failedPods.toString()
                            },
                            {
                                id: 7,
                                title: "Succeeded Pods",
                                text: kube.podStatus.succeededPods.toString()
                            }
                        )
                    } else {
                        items.push(
                            {
                                id: 2,
                                title: "",
                                text: "NOT REACHABLE"
                            },
                        )
                    }
                    return (
                        <Card
                            class="card light card-shadow pointer"
                            key={kube.id}
                            onClick={() => props.history.push(`/kubernetes/clusters/${kube.id}`)}
                        >
                            <div className="card__header-wrapper">
                                <CardHeader title={kube.name} text={
                                    <a
                                        onClick={(e) => e.stopPropagation()}
                                        href={kube.endpoint}
                                        target="_blank"
                                        rel="noreferrer">
                                        Access URL
                                    </a>
                                } />
                                <div className="d-flex align-items-center">
                                    <DropdownMenuContainer actions={[
                                        {
                                            itemId: "2",
                                            title: "Update",
                                            icon: <UpdateIcon />,
                                            disabled: false,
                                            onClick: () => handleKubernetesUpdate(kube)
                                        },
                                        {
                                            itemId: "4",
                                            title: "Delete",
                                            icon: <RemoveIcon />,
                                            disabled: false,
                                            onClick: () => handleKubernetesDelete(kube)
                                        }
                                    ]}
                                    />
                                </div>
                            </div>

                            <div className="stages">
                                <KubernetesDetails
                                    class="kube-grid"
                                    items={items}
                                />
                            </div>
                        </Card>
                    )
                })
                }
            </Card>
        </CustomScrollbars>
    );

    return (
        <main className="content d-flex flex-column justify-content-start">
            <PageHeader title="Kubernetes Clusters" items={[addKube]} />
            <div className="content__body pb--0">
                <React.Fragment>
                    {
                        !isProcessing ?
                            disabledKubernetesDashboard || kubes && !kubes.length ? <EmptyBox /> : <Clusters />
                            : <Skeleton />
                    }
                </React.Fragment>
            </div>
        </main>
    )
}

export default Kubernetes;