import React from 'react';
import { Link } from "react-router-dom";
import { StageDeploymentResponseComponent } from '@microtica/ms-elasticsearch-sdk';
import Commit from '../../components/Commit/Commit';
import { advisorResources } from "../StagesList/StagesList";

interface ResourceCommitProps {
    component: StageDeploymentResponseComponent
}

interface ResourceBranchProps {
    branch: string
}

interface ResourceVersionProps {
    isLatestVersion: boolean;
    version?: string;
    link?: string;
}

interface ResourceAdvisorProps {
    advisor: {
        all: number;
        componentEc2: number;
        componentRdc: number;
        componentElbClassic: number;
        componentElbNonClassic: number;
    },
    awsAccount: { id: string, region: string };
    stageId: string;
}

interface ResourceBodyProps {
    name: string;
    awsAccount: { id: string, region: string };
    stageId: string;
    isLatestVersion: boolean;
    version?: string;
    component?: StageDeploymentResponseComponent;
    advisor?: advisorResources;
}

function StageResourceBranch({ branch }: ResourceBranchProps) {
    return (
        <div>
            <div className="fw--bold pb--5">Branch</div>
            <div> {branch} </div>
        </div>
    );
}

function StageResourceCommit({ component }: ResourceCommitProps) {
    return (
        <div className="txt--white">
            <div className="fw--bold pb--5 commit">Commit</div>
            <Commit
                name={component.metadata.commit.name}
                message={component.metadata.commit.message}
                version={component.metadata.commit.version.substring(0, 7)}
                href={!component.version ? "" : component.version.includes("-") ?
                    `/pipelines/${component.pipelineId}/builds/${component.version}`
                    : `${component.metadata.repository}/${component.metadata.repository.startsWith("https://github.com") ? "commit" : "commits"}/${component.metadata.commit.version}`}
                target={component.version.includes("-") ? "" : "_blank"}
                userName={component.metadata.commit.user.name}
                userAvatar={component.metadata.commit.user.avatar}
                modifier="stage-details"
            />
        </div>
    );
}

function StageResourceVersion({ isLatestVersion, version, link }: ResourceVersionProps) {
    return (
        <>
            <div className="fw--bold pb--5">Configured Version</div>
            {!version ? "N/A" :
                isLatestVersion ?
                    (<div>LATEST (always deploy the latest version)</div>) :
                    (<div>
                        <a
                            className={"txt--highlight pt--1"}
                            target={version.includes("-") ? "" : "_blank"}
                            href={link}
                            onClick={e => e.stopPropagation()}
                        >
                            {version.substring(0, 7)}
                        </a> (fixed version)
                    </div>)
            }
        </>)
}

function ResourceAdvisor({ advisor, awsAccount, stageId }: ResourceAdvisorProps) {
    return advisor && advisor.all > 0 ?
        (
            <div className="row p--15 pt--5 txt--white">
                <div className="txt--white">
                    <div className="fw--bold pb--5 commit">Waste Advisor</div>
                    <div className="txt--yellow">
                        {advisor.componentEc2 > 0 &&
                            <div>{advisor.componentEc2.toString()} EC2 instance has low utilization</div>
                        }
                        {
                            advisor.componentElbClassic > 0 &&
                            <div>{advisor.componentElbClassic.toString()} classic load balancer appear to be idle.</div>
                        }
                        {
                            advisor.componentElbNonClassic > 0 &&
                            <div>{advisor.componentElbNonClassic.toString()} application, network and gateway load balancer appear to be idle.</div>
                        }
                        {
                            advisor.componentRdc > 0 &&
                            <div>{advisor.componentRdc.toString()} DB instance appear to be idle. </div>
                        }
                    </div>
                    <Link
                        style={{ pointerEvents: "all" }}
                        className="txt--highlight"
                        to={{
                            pathname: "/tools/advisor",
                            state: {
                                awsAccount: {
                                    awsAccountId: awsAccount.id,
                                    awsRegion: awsAccount.region
                                },
                                tagKey: "microtica:environment",
                                tagValue: stageId
                            }
                        }}>
                        View details
            </Link>
                </div>
            </div>
        ) : (<></>)
}

function getAdvisorForResource(name: string, advisor?: advisorResources) {
    let componentEc2 = 0;
    let componentRdc = 0;
    let componentElbClassic = 0;
    let componentElbNonClassic = 0;

    if (advisor) {
        if (advisor.ec2.status === "warning") {
            componentEc2 = advisor.ec2.statistics.filter(s => s.instanceTags && s.instanceTags.find(tag => tag.key === "microtica:resource" && tag.value === name)).length;
        }
        if (advisor.rds.status === "warning") {
            componentRdc = advisor.rds.statistics.filter(s => s.tags && s.tags.find(tag => tag.key === "microtica:resource" && tag.value === name)).length;
        }
        if (advisor.elb.classicELBStats.status === "warning") {
            componentElbClassic = advisor.elb.classicELBStats.statistics.filter(s => s.tags && s.tags.find(tag => tag.key === "microtica:resource" && tag.value === name)).length;
        }
        if (advisor.elb.nonClassicELBStats.status === "warning") {
            componentElbNonClassic = advisor.elb.nonClassicELBStats.statistics.filter(s => s.tags && s.tags.find(tag => tag.key === "microtica:resource" && tag.value === name)).length;
        }
    }
    const all = componentEc2 + componentRdc + componentElbClassic + componentElbNonClassic;
    return { all, componentEc2, componentRdc, componentElbClassic, componentElbNonClassic }
}

export function StageResourceBody({ component, version, advisor, name, awsAccount, stageId, isLatestVersion }: ResourceBodyProps) {
    const link = version && version !== "n/a" && component ?
        version.includes("-") ?
            `/pipelines/${component.pipelineId}/builds/${version}`
            : `${component.metadata.repository}/${component.metadata.repository.startsWith("https://github.com") ? "commit" : "commits"}/${component.metadata.commit.version}`
        : undefined;

    return (
        component ?
            <div>
                <div className="row p--15 pt--5 txt--white">
                    <div style={{ width: "210px" }}>
                        <StageResourceBranch branch={component.metadata.commit.name} />
                    </div>
                    <div className="col-5 p--0">
                        <StageResourceCommit component={component} />
                    </div>
                    <div className="col-4 p--0">
                        <StageResourceVersion
                            version={version}
                            isLatestVersion={isLatestVersion}
                            link={link}
                        />
                    </div>
                </div>
                <ResourceAdvisor
                    advisor={getAdvisorForResource(name, advisor)}
                    awsAccount={awsAccount}
                    stageId={stageId}
                />
                <div className="row p--15 pb--0 txt--white">
                    <div className="fw--bold pb--5">Output Parameters</div>
                </div>
            </div> : 
            <div>
                <div className="row p--15 pt--5 txt--white">
                    <div>
                        <StageResourceVersion
                            version={version}
                            isLatestVersion={isLatestVersion}
                            link={link}
                        />
                    </div>
                </div>
                <div className="row p--15 pt--20 pb--0 txt--white">
                    <div className="fw--bold pb--5">Output Parameters</div>
                </div>
            </div>
    )
}