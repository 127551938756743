export const microserviceCategories = [
    { id: "0", label: "My Services" },
    { id: "1", label: "Public Services" },
    // { id: "3", label: "My Microservices" }
];

export enum MicroserviceType {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC"
}
