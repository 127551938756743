import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { setIsLoggedIn, setCurrentProject, setCurrentUser } from "./actions";
import configureStore from "./store/store";
import interceptor from "./api/refresh-token"
import { ModalProvider } from "react-modal-hook";
import { Project } from "@microtica/ms-project-sdk";
import CustomScrollbars from "./components/CustomScrollbars/CustomScrollbars";

export const store = configureStore();
interceptor.registerRequestInterceptor(store);

const token = localStorage.getItem("idToken");
if (token) {
  store.dispatch(setIsLoggedIn());
  const project: Project = JSON.parse(localStorage.getItem("project") || "{}");
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  store.dispatch(setCurrentProject(project));
  store.dispatch(setCurrentUser(profile));
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ModalProvider>
        <div>
          <CustomScrollbars maxHeight={"100vh"} customClass="body" resetClass="reset--top">
            <App />
          </CustomScrollbars>
        </div>
      </ModalProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root") || document.createElement("div") // for testing purposes
);

// ReactDOM.render(<Hello name="Microtica" enthusiasmLevel={10} />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
