import * as constants from "../../constants";
import { getNotificationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";

export interface MarkNotificationsAsReadStart {
    type: constants.MARK_NOTIFICATIONS_AS_READ_START;
}

export interface MarkNotificationsAsReadSuccess {
    type: constants.MARK_NOTIFICATIONS_AS_READ_SUCCESS;
}

export interface MarkNotificationsAsReadFail {
    type: constants.MARK_NOTIFICATIONS_AS_READ_FAIL;
    error: ErrorInterface;
}

export type MarkNotificationsAsRead =
    | MarkNotificationsAsReadStart
    | MarkNotificationsAsReadSuccess
    | MarkNotificationsAsReadFail;


export function markNotificationsAsReadStart(): MarkNotificationsAsRead {
    return {
        type: constants.MARK_NOTIFICATIONS_AS_READ_START
    };
}

export function markNotificationsAsReadSuccess(
): MarkNotificationsAsReadSuccess {
    return {
        type: constants.MARK_NOTIFICATIONS_AS_READ_SUCCESS
    };
}

export function markNotificationsAsReadFail(error: ErrorInterface): MarkNotificationsAsReadFail {
    return {
        type: constants.MARK_NOTIFICATIONS_AS_READ_FAIL,
        error
    };
}

export function markNotificationsAsRead(
    projectId: string
): ThunkAction<Promise<void>, {}, {}, MarkNotificationsAsRead> {
    return async (dispatch: ThunkDispatch<{}, {}, MarkNotificationsAsRead>) => {
        dispatch(markNotificationsAsReadStart());
        try {
            await getNotificationAPI().markNotificationsAsRead(projectId);
            dispatch(markNotificationsAsReadSuccess());
        } catch (error) {
            dispatch(markNotificationsAsReadFail(error.response.data));
        }
    };
}
