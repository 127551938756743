export const ec2 = {
    description: ["Checks the Amazon Elastic Compute Cloud (Amazon EC2) instances that were running at any time during the last 14 days and alerts you if the daily CPU utilization was 10% or less and network I/O was 5 MB or less on 4 or more days. Although some scenarios can result in low utilization by design, you can often lower your costs by managing the number and size of your instances."],
    title: "Low Utilization EC2 Instances",
    headers: [
        "Name:left",
        "Id",
        "Type",
        "Average CPU Utilization",
        "Average Network I/O",
        "Total low utilization days",
    ]
}

export const elb = {
    description: [
        "Checks the classic Elastic Load Balancers (ELB) and alerts you if they don’t have active instances, if they don’t have at least one healthy instance or if the daily RequestCount was below 100 requests during the last 7 days.\n",
        "Checks the non-classic Elastic Load Balancers (ELB) and alerts you if their state is not active, if the daily NewConnectionCount in Application Load Balancers was below 100 connections or if the daily NewFlowCount in Network Load Balancers and Gateway Load Balancers was below 100 flows (or connections) during the last 7 days.",
    ],
    title: "Idle Load Balancers",
    headers: ["Name:left", "Type", "Reason"]
}

export const rds = {
    description:
        ["Checks the configuration of your Amazon Relational Database Service (Amazon RDS) for any DB resources that do not have status 'available' or that have 0 Database Connections during the last 7 days. If a DB resource has not had a connection for a prolonged period of time, you can delete the resource to reduce costs. If persistent storage is needed for data on the resource, you can use lower-cost options such as taking and retaining a DB snapshot. Manually created DB snapshots are retained until you delete them."],
    title: "RDS Idle DB Instances",
    headers: ["Identifier:left", "Engine", "Status", "Reason"]
}
