import React from "react";
import url from "url";
import moment from "moment";
import { Link } from "react-router-dom";
import { Pipeline } from "@microtica/ms-ap-sdk";
import Tooltip from "@material-ui/core/Tooltip";

import Badge from "../../components/Badge/Badge";
import shieldGreen from "../../static/shield-green.svg";
import shieldRed from "../../static/shield-red.svg";

export function PipelineStatusCell(props: { pipeline: Pipeline }) {
    const { pipeline } = props;
    return (
        <Link to={pipeline.latestBuild ? `/pipelines/${pipeline.id}/builds/${pipeline.latestBuild.id}` : ""} onClick={(e) => {
            pipeline.latestBuild && e.stopPropagation();
        }}>
            <Badge
                name={pipeline.latestBuild ? pipeline.latestBuild.status : "NO BUILD"}
                status={pipeline.latestBuild ? pipeline.latestBuild.status : "NO BUILD"}
            />
        </Link>
    );
}

export function PipelineCell(props: { pipeline: Pipeline }) {
    const { pipeline } = props;
    return (
        <div className="pipeline-cell">
            <Link to={`/pipelines/${pipeline.id}/details?tab=builds`}>
                <div className="pipeline-cell__title">
                    {pipeline.name}
                </div>
                <div className="pipeline-cell__subtitle">{(url.parse(pipeline.repositoryUrl).pathname || "").substring(1)}</div>
            </Link>
        </div>
    );
}

export function CommitCell(props: { pipeline: Pipeline }) {
    const { pipeline } = props;
    return (
        <>
            {
                pipeline.latestBuild ?
                    <div className="pipeline-commit-cell">
                        <div className="pipeline-commit-cell__title">
                            {pipeline.latestBuild.metadata.commit.message}
                        </div>
                        <div className="pipeline-commit-cell__subtitle">
                            <span>
                                {pipeline.latestBuild.metadata.commit.name}
                            </span>
                            {
                                !pipeline.latestBuild.metadata.commit.version.includes("-") ?
                                    <>
                                        &nbsp;(
                                        <a
                                            target="_blank"
                                            href={`${pipeline.repositoryUrl}/${pipeline.repositoryUrl.startsWith("https://bitbucket.org") ? "commits" : "commit"}/${pipeline.latestBuild.metadata.commit.version}`}
                                            onClick={e => e.stopPropagation()}
                                            rel="noreferrer">
                                            {pipeline.latestBuild.metadata.commit.version.substring(0, 7)}
                                        </a>
                                        )
                                    </> : null
                            }
                        </div>
                        <div className="pipeline-commit-cell__profile">
                            <img src={(pipeline.latestBuild as any).metadata.commit.user.avatar} alt={pipeline.latestBuild.metadata.commit.user.name} />
                            {pipeline.latestBuild.metadata.commit.user.name}
                        </div>
                    </div> : ""
            }
        </>
    )
}

export function ReportsCell(props: { pipeline: Pipeline }) {
    const { pipeline } = props;

    let findingsCount = 0;
    let scanFailed = false;
    pipeline.latestBuild && (pipeline.latestBuild.steps || []).forEach(s => {
        if (s.reports && s.reports.containerScan) {
            if (s.reports.containerScan.status === "FAILED") {
                scanFailed = true;
            }
            const { severityCounts } = s.reports.containerScan;
            Object.keys(severityCounts).forEach(severity => {
                findingsCount += severityCounts[severity];
            });
        }
    });

    return (
        <>
            {
                pipeline.latestBuild &&
                    (pipeline.latestBuild.steps || []).find(s => s.reports && s.reports.containerScan) ?
                    <Tooltip
                        interactive
                        title={
                            pipeline.latestBuild.steps
                                .filter(s => s.reports && s.reports.containerScan)
                                .map(s => {
                                    const scan = s.reports!.containerScan!;
                                    return (
                                        <React.Fragment key={s.name}>
                                            <h6>{scan.imageName}{scan.imageTag ? `:${scan.imageTag.substring(0, 7)}` : ""}</h6>
                                            {
                                                scan.status === "FAILED" ? scan.statusDescription :
                                                    !Object.keys(scan.severityCounts).length ? "No vulnerabilities found" : ""
                                            }
                                            {
                                                Object.keys(scan.severityCounts).map(severity => (
                                                    <div key={severity}>
                                                        {severity}: {scan.severityCounts[severity]}
                                                    </div>
                                                ))
                                            }
                                            <br />
                                            <br />
                                        </React.Fragment>
                                    )
                                })
                        }>
                        <div className="pipeline-report-cell">
                            <img src={findingsCount || scanFailed ? shieldRed : shieldGreen} alt="" />
                            {
                                scanFailed ?
                                    "Scan failed" :
                                    findingsCount ? `${findingsCount} security issues` : "No security issues"
                            }
                        </div>
                    </Tooltip> : ""
            }
        </>
    );
}

export function PipelineTimeCell(props: { pipeline: Pipeline }) {
    const { pipeline } = props;
    return (
        <div className="pipeline-time-cell">
            <div className="pipeline-time-cell__title">
                {
                    pipeline.latestBuild ?
                        moment.utc(moment(pipeline.latestBuild.stopDate || new Date()).diff(pipeline.latestBuild.startDate, "millisecond")).format("HH:mm:ss")
                        : ""
                }
            </div>
            <div className="pipeline-time-cell__subtitle" >
                {pipeline.latestBuild ? pipeline.latestBuild.stopDate ? moment(pipeline.latestBuild.stopDate, "x").fromNow() : "" : ""}
            </div>
        </div>
    );
}

export function PipelineExecutionCell(props: { pipeline: Pipeline }) {
    const { pipeline } = props;
    return (
        <div className="pipeline-execution-cell">
            <Link to={`/pipelines/${pipeline.id}/builds/${pipeline.latestBuild!.id}`}>
                {pipeline.latestBuild!.id}
            </Link>
        </div>
    );
}