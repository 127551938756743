import React from "react";

export interface BillingToggleButtonProps {
    name?: string;
    description?: string;
    text?: boolean;
    isChecked: boolean | undefined;
    onChange: () => void;
}

const BillingToggleButton = (props: BillingToggleButtonProps) => {
    return (
        <div className="col-12 d-flex justify-content-center">
            <div className="toggle billing">
                {props.name ? <span className="toggle__name">{props.name}</span> : null}
                <label className="toggle__text billing">
                    Monthly
                </label>
        <label className="toggle__btn billing">
                    <input
                        name="isReference"
                        type="checkbox"
                        checked={props.isChecked}
                        onChange={props.onChange}
                        tabIndex={-1}
                    />
                    <span className="toggle__slider billing" />
                    <span className="toggle__slider-bg billing" />
                    <span className="toggle__circle billing" />
                </label>
                <label className="toggle__text billing">
                    Yearly
                </label>
            </div>
            <div>
                {props.description ? <p className="checkbox__description"> {props.description}</p> : null}
            </div>
        </div>
    )
}

export default BillingToggleButton;