import React, { useEffect, useState, ChangeEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Skeleton from "react-loading-skeleton";
import uuid from "uuid";
import { toast } from "react-toastify";
import {
    AddAwsResourcesTagRequestResourceTypeEnum,
    ChangeStateAllResourcesRequestActionEnum,
    GetPricingForRDSResponseClusters,
    GetPricingForEC2WithPriceResponse,
    GetAsgFinal,
    GetPricingForRDSResponseInstances
} from "@microtica/ms-cloud-cost-optimizer-sdk";

import PageHeader from "../../components/PageHeader/PageHeader";
import { getCloudCostOptimizerAPI, getEngineAPI } from "../../api";
import { InputField } from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import Dropdown, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import AwsAccount, { AwsAccountChangeEvent } from "../../components/AwsAccount/AwsAccount";
import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import Table from "../../components/Table/Table";
import StageStatus from "../../components/StageStatus/StageStatus";
import { ReactComponent as ClockIcon } from "../../static/clock-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";

import awsRegions from "../../utils/aws-regions";
import { scheduleSchema } from "../../utils/validation";
import { calculateUtilization } from "../../utils/schedules"
import Cron from "./Cron";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { useModal } from "react-modal-hook";
import ModalPaymentLimit from "../../components/ModalPaymentLimit/ModalPaymentLimit";
import { trackScheduleCreateInit } from "../../tracking/cloud-waste";

interface CreateScheduleProps extends RouteComponentProps<{ scheduleId: string }> { }

const CreateSchedule = (props: CreateScheduleProps) => {
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);

    // Defined this initial state to avoid infinite rendering loop between CreateSchedule and Cron component
    const [initialCronExpression, setInitialCronExpression] = useState<{ startExpression: string; stopExpression: string; } | undefined>();
    const [cronExpression, setCronExpression] = useState<{ startExpression: string; stopExpression: string; } | undefined>();
    const [fromStage, setFromStage] = useState(false);
    const [name, setName] = useState("");
    const [stages, setStages] = useState<GetStagesItem[]>([]);
    const [stagesList, setStagesList] = useState<DropdownItem[]>([]);
    const [selectedStage, setSelectedStage] = useState<DropdownItem | undefined>();
    const [selectedAwsAccount, setSelectedAwsAccount] = useState<AwsAccountChangeEvent>({ awsAccountId: undefined, awsRegion: undefined });
    const [tagKey, setTagKey] = useState<string | undefined>();
    const [tagValue, setTagValue] = useState<string | undefined>();
    const [resources, setResources] = useState<((GetPricingForEC2WithPriceResponse | GetAsgFinal | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string })[]>([]);
    const [filteredResources, setFilteredResources] = useState<((GetPricingForEC2WithPriceResponse | GetAsgFinal | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { selected?: boolean, type: string })[]>([]);
    const [selectedByTag, setSelectedByTag] = useState<boolean>(false);
    const [totalUtilizedHours, setTotalUtilizedHours] = useState(0);
    const [estimatedSaving, setEstimatedSaving] = useState(0);
    const [timeZone, setTimeZone] = useState(getLocalTimeZone());
    const [sumOfAllResourcesPrice, setSumOfAllResourcesPrice] = useState(0);

    const [editMode] = useState(props.match.params.scheduleId !== undefined);
    const [isLoaded, setIsLoaded] = useState(props.match.params.scheduleId ? false : true);

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState("");

    const [StartResourcesModal, hideStartResourceModal] = useModal((status: string) => (
        <ModalDanger
            title="Are you sure you want to START all affected resources?"
            description="`You can in any time stop affected resources again."
            action="Start All"
            onCancel={hideStartResourceModal}
            onConfirm={() => handleResourcesStateChange(ChangeStateAllResourcesRequestActionEnum.Start)}
        />
    ), [combinedResourcesState]);

    const [StopResourcesModal, hideStopResourceModal] = useModal((status: string) => (
        <ModalDanger
            title="Are you sure you want to STOP all affected resources?"
            description="`You can in any time start affected resources again."
            action="Stop All"
            onCancel={hideStopResourceModal}
            onConfirm={() => handleResourcesStateChange(ChangeStateAllResourcesRequestActionEnum.Stop)}
        />
    ), [combinedResourcesState]);

    const [showPaymentModal] = useModal(() => (
        <ModalPaymentLimit
            title={paymentError}
            onCancel={() => props.history.push("/overview")}
            routeHistory={props}
        />
    ), [paymentError]);


    // checks if payment plan allows creating of schedule before filling the form
    useEffect(() => {
        trackScheduleCreateInit();
        const fetch = async function () {
            try {
                await getCloudCostOptimizerAPI().listSchedules(currentProject.id);
            } catch (error) {
                if (error.response.data.code === 402) {
                    setPaymentError(error.response.data.message);
                    showPaymentModal();
                }
            }
        }
        fetch();
    }, []);

    const getAwsRegion = () => {
        return selectedAwsAccount && selectedAwsAccount.awsRegion && selectedAwsAccount.awsRegion.indexOf("-") === -1 ? awsRegions.find(
            (r) => r.id === selectedAwsAccount.awsRegion)!.value : selectedAwsAccount.awsRegion
    }

    const fetchResources = async function () {
        if (
            selectedAwsAccount.awsAccountId &&
            selectedAwsAccount.awsRegion
        ) {
            const region = getAwsRegion()!;
            setIsLoaded(false);

            const { data: groupedResources } = await getCloudCostOptimizerAPI().getAllResourcesWithPricing(
                selectedAwsAccount.awsAccountId,
                currentProject.id,
                region,
                false
            );
            setIsLoaded(true);

            const ec2Resources = groupedResources.ec2.map(resource => { return { ...resource, type: "ec2" } })
            const asgResources = groupedResources.asg.map(resource => { return { ...resource, type: "asg" } })
            const rdsClusterResources = groupedResources.rds.clusters.map(resource => { return { ...resource, type: "rds" } })
            const rdsInstancesResources = groupedResources.rds.instances.map(resource => { return { ...resource, type: "rds" } })

            const allResources = [
                ...(ec2Resources || []),
                ...(asgResources || []),
                ...((rdsClusterResources || { clusters: [] }.clusters || [])),
                ...((rdsInstancesResources || { instances: [] }.instances || []))
            ]

            setResources(allResources);
            setFilteredResources(allResources.filter(r => {
                if (!selectedByTag) {
                    return true;
                } else {
                    return (r.tags || []).filter(t => t.key === tagKey && t.value === tagValue).length !== 0;
                }
            }));
        } else {
            setResources([]);
        }
    };

    useEffect(() => {
        fetchResources();
    }, [selectedStage, selectedAwsAccount]);

    function CalculateEstimatedSaving() {
        let TmpsumOfAllResourcesPrice = filteredResources.reduce((acc, res) => {
            if ((typeGuardIsEc2(res) || typeGuardIsRds(res)) && res.price) {
                if (editMode || selectedByTag) {
                    acc += res.price
                } else if (res.selected) {
                    acc += res.price
                }
            }
            return acc
        }, 0);

        setSumOfAllResourcesPrice(TmpsumOfAllResourcesPrice);
        setEstimatedSaving(TmpsumOfAllResourcesPrice * totalUtilizedHours);
    }
    useEffect(() => {
        CalculateEstimatedSaving();
    }, [filteredResources, totalUtilizedHours])

    useEffect(() => {
        if (!selectedByTag) {
            setFilteredResources(resources);
        } else {
            setFilteredResources(resources.filter(r => {
                if (!selectedByTag) {
                    return true;
                } else {
                    return (r.tags || []).filter(t => t.key === tagKey && t.value === tagValue).length !== 0;
                }
            }));
        }
    }, [selectedByTag, tagKey, tagValue]);

    useEffect(() => {
        const fetch = async function () {
            const { data: { stages } } = await getEngineAPI().getStages(currentProject.id);
            const stagesWithCloudAccount = stages.filter(s => s.awsAccountId);
            setStages(stagesWithCloudAccount);
        };

        if (fromStage === true && !stages.length) {
            fetch();
        } else {
            setSelectedStage(undefined);
            setSelectedAwsAccount({ awsAccountId: undefined, awsRegion: undefined });
            setTagKey(undefined);
            setTagValue(undefined);
            setResources([]);
        }
    }, [fromStage]);

    useEffect(() => {
        setStagesList(stages.map(s => ({
            id: s.id,
            name: s.name,
            subTitle: s.description
        })));
    }, [stages]);

    useEffect(() => {
        const fetch = async function () {
            setIsLoaded(false);

            const { data: schedules } = await getCloudCostOptimizerAPI().listSchedules(currentProject.id);
            const schedule = schedules.rules!.find(s => s.id === props.match.params.scheduleId);
            const awsRegion = awsRegions.find(r => r.value === schedule!.awsRegion);
            setIsLoaded(true);
            setSelectedByTag(true);
            setName(schedule!.name);
            setTagKey(schedule!.resourcesTagKey);
            setTagValue(schedule!.resourcesTagValue);
            setInitialCronExpression({
                startExpression: schedule!.startScheduleExpression,
                stopExpression: schedule!.stopScheduleExpression
            });
            setSelectedAwsAccount({
                awsAccountId: schedule!.awsAccountId,
                awsRegion: awsRegion && awsRegion.id
            });
            setTimeZone(schedule!.timezone);

            const utilization = calculateUtilization(schedule!.startScheduleExpression, schedule!.stopScheduleExpression, schedule!.timezone);
            setTotalUtilizedHours(utilization.totalUtilizedHours);
            setEstimatedSaving(sumOfAllResourcesPrice * utilization.totalUtilizedHours);

        };

        if (editMode) {
            fetch()
        }

    }, []);

    useEffect(() => {
        if (cronExpression) {
            const utilization = calculateUtilization(cronExpression.startExpression, cronExpression.stopExpression, timeZone);
            setTotalUtilizedHours(utilization.totalUtilizedHours);
            setEstimatedSaving(sumOfAllResourcesPrice * utilization.totalUtilizedHours);

        }
    }, [cronExpression]);

    async function handleSubmit() {
        setIsBusy(true);
        if (editMode) {
            await getCloudCostOptimizerAPI().updateSchedule(
                props.match.params.scheduleId,
                currentProject.id,
                {
                    name,
                    startScheduleExpression: cronExpression!.startExpression,
                    stopScheduleExpression: cronExpression!.stopExpression
                }
            )
        } else {
            const DEFAULT_TAG_KEY = "microtica:cost-optimizer";
            const DEFAULT_TAG_VALUE = uuid.v4();
            const region = getAwsRegion()!;

            if (!selectedByTag) {
                const resources = filteredResources.filter(r => r.selected);

                if (resources.length === 0) {
                    toast.error("You should have at least one resource selected to create saving schedule");
                    setIsBusy(false);
                    throw Error("No resources selected");
                }

                await Promise.all(resources.map(r => {
                    return getCloudCostOptimizerAPI().addAwsResourcesTag(
                        currentProject.id,
                        typeGuardIsRds(r) ? r.arn! : typeGuardIsEc2(r) ? r.id! : typeGuardIsAsg(r) ? r.name! : "unknown",
                        {
                            awsAccountId: selectedAwsAccount!.awsAccountId!,
                            region,
                            resourceType: r.type === "ec2" ? AddAwsResourcesTagRequestResourceTypeEnum.Ec2 :
                                r.type === "rds" ? AddAwsResourcesTagRequestResourceTypeEnum.Rds : AddAwsResourcesTagRequestResourceTypeEnum.Asg,
                            tagKey: DEFAULT_TAG_KEY,
                            tagValue: DEFAULT_TAG_VALUE
                        }
                    )
                }));
            }
            try {
                await getCloudCostOptimizerAPI().createSchedule(
                    currentProject.id,
                    {
                        name,
                        startScheduleExpression: cronExpression!.startExpression,
                        stopScheduleExpression: cronExpression!.stopExpression,
                        awsAccountId: selectedAwsAccount!.awsAccountId!,
                        region,
                        tagKey: selectedByTag ? tagKey! : DEFAULT_TAG_KEY,
                        tagValue: selectedByTag ? tagValue! : DEFAULT_TAG_VALUE,
                        timezone: timeZone
                    }
                );
            } catch (error) {
                toast.error(error.response.data.message || "Something went wrong!");
            }
        }
        setIsBusy(false);
        props.history.push("/tools/schedules");
    }

    async function handleResourcesStateChange(action: ChangeStateAllResourcesRequestActionEnum) {
        try {
            const region = getAwsRegion()!;
            await getCloudCostOptimizerAPI().changeStateAllResources(
                currentProject.id,
                {
                    scheduleId: props.match.params.scheduleId,
                    awsAccountId: selectedAwsAccount!.awsAccountId!,
                    region,
                    tagKey: tagKey!,
                    tagValue: tagValue!,
                    action: action
                }
            );
            hideStopResourceModal();
            hideStartResourceModal();
            fetchResources();
            toast.success(`Successfully initiated ${action.toUpperCase()} action on affected resources with this schedule.`);
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    function combinedResourcesState() {
        return filteredResources.filter(r => r.state === "running" || r.state === "available").length === filteredResources.length ? "running" : "stopped";
    }

    function getLocalTimeZone() {
        const zoneOffset = new Date().getTimezoneOffset();

        if (zoneOffset > 0) {
            return `UTC-${zoneOffset / 60}`;
        } else {
            return `UTC+${Math.abs(zoneOffset / 60)}`;
        }
    }

    function ResourceDescription(props: { resource: (GetPricingForEC2WithPriceResponse | GetAsgFinal | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string } }) {
        return (
            typeGuardIsAsg(props.resource) ?
                <small>
                    <b>
                        Desired: {props.resource.asgGroupCapacity!.desiredCapacity} | Min: {props.resource.asgGroupCapacity!.minSize} | Max: {props.resource.asgGroupCapacity!.maxSize}
                    </b>
                </small>
                : typeGuardIsEc2(props.resource) ?
                    <small>
                        <b>
                            Instance type: {props.resource.instanceType}
                        </b>
                    </small>
                    : typeGuardIsRds(props.resource) ?
                        <small>
                            <b>
                                Instance type: {props.resource.instanceType}
                            </b>
                        </small>
                        : null
        );
    }

    function typeGuardIsRds(resource: (GetPricingForEC2WithPriceResponse | GetAsgFinal | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string })
        : resource is (GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string } {
        return resource.type === "rds"
    }
    function typeGuardIsEc2(resource: (GetPricingForEC2WithPriceResponse | GetAsgFinal | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string })
        : resource is GetPricingForEC2WithPriceResponse & { type: string } {
        return resource.type === "ec2"
    }
    function typeGuardIsAsg(resource: (GetPricingForEC2WithPriceResponse | GetAsgFinal | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string })
        : resource is GetAsgFinal & { type: string } {
        return resource.type === "asg"
    }

    function calculateStoragePricing(res: (GetPricingForEC2WithPriceResponse | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string }): number {

        if (typeGuardIsEc2(res)) {
            return res.ebsData!.reduce((acc, ebs) => {

                acc += ebs.price || 0
                return acc
            }, 0)
        }
        return typeGuardIsRds(res) ? res.storagePrice || 0 : 0
    }

    return (
        <main className="content d-flex flex-column justify-content-start">
            <PageHeader title={editMode ? name || "Saving Schedule" : "Create Schedule"} />

            <div className="content__body schedule">
                <div className="row">
                    <div className="col-3 schedule__sidebar">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name,
                                tagKey,
                                tagValue,
                                cronExpression,
                                selectedByTag,
                                awsAccount: selectedAwsAccount.awsAccountId,
                                awsRegion: selectedAwsAccount.awsRegion,
                            }}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={handleSubmit}
                            validationSchema={scheduleSchema}
                            render={({ errors, setFieldValue, setFieldTouched }) => (
                                <Form>
                                    <div className="box__inputs">
                                        <Field
                                            name="name"
                                            placeholder="e.g. Dev Environment Schedule"
                                            type="text"
                                            hasError={true}
                                            label="Schedule Name"
                                            component={InputField}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                            value={name}
                                        />

                                        <Cron
                                            name="cronExpression"
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            cronExpression={initialCronExpression}
                                            timeZone={timeZone}
                                            errors={errors}
                                            title="CLOUD RESOURCES SHOULD BE ACTIVE ON:"
                                            onChange={setCronExpression}
                                        />
                                        {/* IF create mode */}
                                        {!editMode ?
                                            <div>
                                                <h6 className="sidebar__title">
                                                    <strong>Select Resources</strong>
                                                </h6>

                                                <Checkbox
                                                    name="Environment Resources"
                                                    checked={fromStage}
                                                    onChange={(checked) => {
                                                        setFromStage(checked);
                                                        setTagKey(undefined);
                                                        setTagValue(undefined);
                                                        setSelectedByTag(false);
                                                        setFieldValue("tagKey", undefined);
                                                        setFieldValue("tagValue", undefined);
                                                        setFieldValue("awsAccount", undefined);
                                                        setFieldValue("awsRegion", undefined);
                                                    }}
                                                    description="Apply saving schedule on resources within existing Microtica Environment."
                                                />
                                                {
                                                    fromStage ?
                                                        <Field
                                                            name="stage"
                                                            render={() => (
                                                                <div className="page-content__dropdown-container pl--0 pr--0">
                                                                    <Dropdown
                                                                        selectedItem={selectedStage}
                                                                        items={stagesList}
                                                                        placeholder="Select Environment"
                                                                        onSelectItem={item => {
                                                                            const stage = stages.find(s => s.id === item.id);
                                                                            if (stage) {
                                                                                setTagKey("microtica:environment");
                                                                                setTagValue(stage.id);
                                                                                setSelectedStage(item);
                                                                                const awsRegion = awsRegions.find(r => r.value === stage.awsRegion);
                                                                                setSelectedAwsAccount({
                                                                                    awsAccountId: stage.awsAccountId,
                                                                                    awsRegion: awsRegion && awsRegion.id
                                                                                });
                                                                                setSelectedByTag(true);

                                                                                setFieldValue("tagKey", "microtica:environment");
                                                                                setFieldValue("tagValue", stage.id);
                                                                                setFieldValue("awsAccount", stage.awsAccountId);
                                                                                setFieldValue("awsRegion", awsRegion && awsRegion.id);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        /> :
                                                        <div>
                                                            <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
                                                                <AwsAccount
                                                                    cfnLink="https://console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/quickcreate?stackName=MicroticaCrossAccountAccessCostOptimization&templateURL=https://microtica.s3.eu-central-1.amazonaws.com/assets/aws/cloudformation/MicroticaCrossAccountAccessCostOptimization.json"
                                                                    value={selectedAwsAccount}
                                                                    onChange={(event) => {
                                                                        setSelectedAwsAccount({
                                                                            awsAccountId: event.awsAccountId,
                                                                            awsRegion: event.awsRegion
                                                                        });
                                                                    }}
                                                                    showCardBox={false}
                                                                    showEcrDropdown={false}
                                                                    errors={errors}
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            </div>
                                                            <Checkbox
                                                                name="Filter by Tag"
                                                                checked={selectedByTag}
                                                                onChange={checked => {
                                                                    setFieldValue("selectedByTag", checked);
                                                                    setSelectedByTag(checked);
                                                                }}
                                                                description="Filter based on tag assigned on cloud resources."
                                                            />
                                                            <br />
                                                            {
                                                                selectedByTag ?
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <Field
                                                                                name="tagKey"
                                                                                placeholder="e.g. environment"
                                                                                type="text"
                                                                                disabled={editMode}
                                                                                hasError={true}
                                                                                label="Tag key"
                                                                                component={InputField}
                                                                                value={tagKey}
                                                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setTagKey(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <Field
                                                                                name="tagValue"
                                                                                placeholder="e.g. test"
                                                                                type="text"
                                                                                disabled={editMode}
                                                                                hasError={true}
                                                                                label="Tag value"
                                                                                component={InputField}
                                                                                value={tagValue}
                                                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setTagValue(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <Button
                                        type="submit"
                                        className={`btn btn--xl btn--${editMode ? "blue" : "green"}`}
                                        isBusy={isBusy}
                                        busyText={editMode ? "Updating.." : "Activating.."}
                                    >
                                        {editMode ? "Update Schedule" : "Activate Schedule"}
                                    </Button>
                                </Form>
                            )}
                        />

                    </div>
                    <div className="col-9 schedule__content">
                        <div className="table-wrapper">
                            <div className="details__status">
                                <StageStatus
                                    class="stages__info-wrapper"
                                    items={[
                                        {
                                            id: 1,
                                            title: "Aws Account",
                                            text: !isLoaded ? <Skeleton width="70px" /> :
                                                <div style={{ textTransform: "lowercase" }}>
                                                    {selectedAwsAccount.awsAccountId || "n/a"}
                                                </div>
                                        },
                                        {
                                            id: 2,
                                            title: "Aws Region",
                                            text: !isLoaded ? <Skeleton width="70px" /> :
                                                <div style={{ textTransform: "lowercase" }}>
                                                    {getAwsRegion() || "n/a"}
                                                </div>
                                        },
                                        {
                                            id: 3,
                                            title: "",
                                            icon: <ClockIcon />,
                                            text: !isLoaded ? <Skeleton width="70px" /> :
                                                <div style={{ textTransform: "lowercase" }}>
                                                    <h5 style={{ color: "#49bb59" }}>
                                                        {totalUtilizedHours}h
                                                    </h5>
                                                    est. saving hours /month
                                                </div>
                                        },
                                        {
                                            id: 4,
                                            title: "",
                                            icon: <CheckmarkIcon />,
                                            text: !isLoaded ? <Skeleton width="70px" /> :
                                                <div>
                                                    <h5 style={{ color: "#49bb59" }}>
                                                        ${Math.round(estimatedSaving)}
                                                    </h5>
                                                    estimated saving /month
                                                </div>
                                        }
                                    ]}
                                />
                            </div>
                            <div className="d-flex details__header details__affected overflow--auto">
                                <h5 className="pull--left">
                                    Affected Resources
                                </h5>
                                {
                                    editMode && filteredResources.length ?
                                        <div className="pull--right">
                                            <a onClick={StartResourcesModal}>
                                                Start All Resources
                                            </a>
                                            <b> / </b>
                                            <a onClick={StopResourcesModal}>
                                                Stop All Resources
                                            </a>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="d-flex details__header details__price_explanation overflow--auto">
                                * Price is calculated by instance type + storage costs
                            </div>
                            <Table
                                isLoaded={isLoaded}
                                headers={["Name:left", "Price per month *", "Type", "State", "Saving"]}
                                placeholderText="No cloud resources found"
                                rows={filteredResources.map(res => ([
                                    <div className="txt--left">
                                        {(typeGuardIsRds(res) || typeGuardIsEc2(res) || typeGuardIsAsg(res)) && !selectedByTag ?
                                            <Checkbox
                                                name={res.name || (res as GetPricingForEC2WithPriceResponse | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters).id || "n/a"}
                                                checked={res.selected}
                                                disabled={selectedByTag}
                                                onChange={(checked) => { res.selected = checked; CalculateEstimatedSaving() }}
                                                description={<ResourceDescription resource={res} />}
                                            /> : (typeGuardIsRds(res) || typeGuardIsEc2(res) || typeGuardIsAsg(res) ?
                                                <>
                                                    <div>{res.name || (res as GetPricingForEC2WithPriceResponse | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters).id || "n/a"}</div>
                                                    <ResourceDescription resource={res} />
                                                </> : <div>n/a</div>
                                            )}
                                    </div>,
                                    (typeGuardIsEc2(res) || typeGuardIsRds(res)) && res.price ? `$${(((res.price * 730) + calculateStoragePricing(res))).toFixed(2)}` : "n/a",
                                    <div style={{ width: "30px" }}>{res.type.toUpperCase()}</div>,
                                    <span className={`mt--10 align-self-start badge badge--${res.state === "running" || res.state === "available" ? "success" : "fail"}`}>
                                        {res.state === "available" ? "running" : res.state}
                                    </span>,
                                    <span className={`mt--10 align-self-start badge badge--${res.state === "running" || res.state === "available" ? "fail" : "success"}`}>
                                        {
                                            res.state !== "running" && res.state !== "available" ? "active" : "inactive"
                                        }
                                    </span>
                                ]))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
};
export default CreateSchedule;