import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { Link, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import {
  GetAgregatedHistoryResponse,
  GetAgregatedForecastResponse,
  GetReportsResponseReports,
} from "@microtica/ms-cloud-cost-optimizer-sdk";
import { toast } from "react-toastify";

import PageHeader from "../../components/PageHeader/PageHeader";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import MetricsWidget from "./MetricsWidget";
import TableWidget from "./TableWidget";
import BarChartWidget from "./BarChartWidget";
import LineChartWidget from "./LineChartWidget";
import { getCloudCostOptimizerAPI, getAwsAPI, getEngineAPI } from "../../api";
import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { Dictionary } from "../../components/ModalConfigure/ConfigurationItem";
import { calculateAggregatedUtilization } from "../../utils/schedules";
import CostExplorerScreen from "../../static/cost-explorer.png";
import Button from "../../components/Button/Button";
import BuildProcessWaiting from "../../components/BuildProcessWaiting/BuildProcessWaiting";
import CloudAccountModal, { CloudAccountType } from "../../components/CloudAccount/CloudAccountModal";

const CostExplorer = (props: RouteComponentProps) => {
  const currentProject = useSelector(
    (state: GlobalState) => state.project.currentProject
  );
  const { awsAccounts: awsAccountsState } = useSelector(
    (state: GlobalState) => state.awsAccount
  );

  const [
    aggregatedHistory,
    setAggregatedHistory,
  ] = useState<GetAgregatedHistoryResponse>();
  const [
    aggregatedForecast,
    setAggregatedForecast,
  ] = useState<GetAgregatedForecastResponse>();

  const [estimatedCost, setEstimatedCost] = useState(0);
  const [lastMonthCost, setLastMonthCost] = useState(0);
  const [estimatedMonthTrend, setEstimatedMonthTrend] = useState(0);
  const [estimatedYearTrend, setEstimatedYearTrend] = useState(0);
  const [totalYearCost, setTotalYearCost] = useState(0);
  const [montlyCostByAwsAccount, setMontlyCostByAwsAccount] = useState<
    string[][]
  >([]);
  const [montlyCostByService, setMontlyCostByService] = useState<string[][]>(
    []
  );
  const [yearCostByMonth, setYearCostByMonth] = useState<any[]>([]);
  const [estimatedSaving, setEstimatedSaving] = useState(0);
  const [activeSchedulesCount, setActiveSchedulesCount] = useState(0);
  const [dailyUtilizedHours, setDailyUtilizedHours] = useState<
    { day: string; hours: number }[]
  >([]);
  const [costByTag, setCostByTag] = useState<{ tag: string; cost: number }[]>(
    []
  );
  const [report, setReport] = useState<GetReportsResponseReports>();

  const [isLoading, setIsLoading] = useState(true);
  const [awsAccounts, setAwsAccounts] = useState<any[]>();
  const [disabledCostExplorer, setDisabledCostExplorer] = useState(false);


  const [showAwsModal, hideAwsModal] = useModal(() => (
    <CloudAccountModal
      cfnLink="https://console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/quickcreate?stackName=MicroticaCrossAccountAccessCostOptimization&templateURL=https://microtica.s3.eu-central-1.amazonaws.com/assets/aws/cloudformation/MicroticaCrossAccountAccessCostOptimization.json"
      onClose={hideAwsModal}
      cloudAccountType={CloudAccountType.AWS}
    />
  ));

  useEffect(() => {
    if (awsAccountsState.length) {
      setIsLoading(true);
      fetch();
    }
  }, [awsAccountsState]);

  useEffect(() => {
    fetch();
  }, []);

    const fetch = async function () {
        const DEFAULT_COST_ALLOCATION_TAG = "microtica:environment";

        try{
            const [{ data: stages }, { data: schedules }, { data: costHistory }, { data: costForecast }] = await Promise.all([
                getEngineAPI().getStages(currentProject.id),
                getCloudCostOptimizerAPI().listSchedules(currentProject.id),
                getCloudCostOptimizerAPI().getAggregatedHistory(currentProject.id, DEFAULT_COST_ALLOCATION_TAG),
                getCloudCostOptimizerAPI().getAggregatedForecast(currentProject.id, DEFAULT_COST_ALLOCATION_TAG).catch(e => {
                    if (e.response.data.code === "DataUnavailableException") {
                        return { data: undefined };
                    } else {
                        setIsLoading(false);
                        setDisabledCostExplorer(e.response.data.code === 402);
                        throw e;
                    }
                })
            ]);
            const { data: awsAccounts } = await getAwsAPI().getAwsAccounts(currentProject.id);

            //Reports
            const reports = await getCloudCostOptimizerAPI().listReports(
              currentProject.id
            );
            setReport(reports.data.reports[0]);
            
            if (!awsAccounts.awsAccounts.length) {
                return setIsLoading(false);
            } else {
                setAwsAccounts(awsAccounts.awsAccounts);
            }

            // Saving metrics
            const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const aggregatedUtilization = calculateAggregatedUtilization(schedules.rules.map(r => ({
                startScheduleExpression: r.startScheduleExpression,
                stopScheduleExpression: r.stopScheduleExpression,
                timeZone: r.timezone
            })));

            setActiveSchedulesCount(schedules.rules.filter(r => r.state === "ENABLED").length);
            setEstimatedSaving(costForecast? costForecast.estimatedSavingByActiveSavingSchedule: 0);
            setDailyUtilizedHours(aggregatedUtilization.dailyUtilizedHours.map((hours, index) => ({
                day: weekDays[index],
                hours
            })));


            // Cost metrics
            if (costForecast) {
                setEstimatedCost(Math.round(costForecast!.estimatedCostForTheCurrentMonth.total));
                setEstimatedMonthTrend(Math.round(
                    (costHistory!.costForTheLastMonth.total - costForecast!.estimatedCostForTheCurrentMonth.total) /
                    costHistory!.costForTheLastMonth.total * 100
                ));
                setEstimatedYearTrend(Math.round(
                    (costHistory!.costByAWSAccountForTheCurrentYear.total - costForecast!.estimatedCostByAWSAccountForTheNextYear.total) /
                    costHistory!.costByAWSAccountForTheCurrentYear.total * 100
                ));
            }

            const {
                ec2: estimatedEc2,
                rds: estimatedRds,
                allServices: estimatedAllServices
            } = costForecast ? costForecast!.estimatedCostByAWSServiceForTheCurrentMonth : { ec2: 0, rds: 0, allServices: 0 };
            const {
                ec2: currentEc2,
                rds: currentRds,
                allServices: currentAllServices
            } = costHistory!.costByAWSServiceForTheCurrentMonth;
            setMontlyCostByService([
                ["EC2", costForecast ? `$${Math.round(estimatedEc2)}` : "n/a", `$${Math.round(currentEc2)}`],
                ["RDS", costForecast ? `$${Math.round(estimatedRds)}` : "n/a", `$${Math.round(currentRds)}`],
                ["All", costForecast ? `$${Math.round(estimatedAllServices)}` : "n/a", `$${Math.round(currentAllServices)}`]
            ]);

            setLastMonthCost(Math.round(costHistory!.costForTheLastMonth.total));
            setMontlyCostByAwsAccount(awsAccounts.awsAccounts.map(account => {
                const currentCost = Math.round(costHistory!.costForTheCurrentMonth.ids[account.id].allServices);
                const estimatedCost = costForecast ? Math.round(costForecast!.estimatedCostForTheCurrentMonth.ids[account.id].allServices) : 0

                return {
                    name: account.accountName || account.id,
                    estimatedCost,
                    currentCost
                };
            }).sort((a, b) => {
                if (a.currentCost > b.currentCost) return -1;
                if (a.currentCost < b.currentCost) return 1;
                return 0;
            }).map(acc => ([
                acc.name,
                costForecast ? `$${acc.estimatedCost}` : "n/a",
                `$${acc.currentCost}`
            ])).slice(0, 3));

            const costByMonth = awsAccounts.awsAccounts.reduce((acc, account) => {
                const history = costHistory!.costByAWSAccountForTheCurrentYear.cost[account.id];
                return history.months.reduce((acc, month) => {
                    acc[month.start] = {
                        ...acc[month.start],
                        date: month.start,
                        month: moment().month(new Date(month.start).getMonth()).format('MMM'),
                        [account.accountName || account.id]: Math.round(month.cost)
                    };
                    return acc;
                }, acc as Dictionary<any>);
            }, {} as Dictionary<any>);
            setYearCostByMonth(Object.keys(costByMonth).map(key => costByMonth[key]));
            setTotalYearCost(Math.round(costHistory.costByAWSAccountForTheCurrentYear.total));

            setCostByTag(stages.stages.reduce((acc, stage) => {
                const cost = Math.round(costHistory!.costByAllocationTagForTheCurrentMonth!.resourceNames[stage.id]);
                if (cost || cost === 0) {
                    acc.push({ tag: stage.id, cost });
                }
                return acc;
            }, [] as { tag: string, cost: number }[]));

            setAggregatedHistory(costHistory);
            setAggregatedForecast(costForecast);
            setIsLoading(false);
        } catch(error){
            toast.error(error.response.data.message);
        }
  };

  function convertToCurrency(amount: number) {
    return Math.round(amount).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const AwsAccountPlaceholder = () => (
    <div
      className="page-centered page-centered--project"
      style={{ paddingTop: "10%" }}
    >
      <img src={CostExplorerScreen} alt="cost explorer screen"/>
      <h3>
        <strong>No AWS accounts connected yet</strong>
      </h3>
      <p className="txt--sm">
        Monitor the cost of all connected AWS accounts and get an overview of
        your montly and yearly cloud spendings, as well as cloud saving
        utilization. Highlight the most expensive resources in your cloud
        account. Get a cloud spending forecast to better plan your cloud budget.
        <br />
        <br />
        To enable this feature you would need to connect at least one AWS
        account.
      </p>
      <Button className="btn btn--xl btn--green" onClick={showAwsModal}>
        Connect AWS Account
      </Button>
    </div>
  );

  const SchedulePlaceholder = () => (
    <>
      <p>
        No active schedules at this moment. To start saving on cloud create new
        schedule.
      </p>
      <Link className="btn btn--md btn--blue" to="/tools/schedules/create">
        Create Schedule
      </Link>
    </>
  );

  const AllocationTagsPlaceholder = () => (
    <>
      <p>
        There is no active AWS Cost Allocation Tag named{" "}
        <i>microtica:environment</i>. To enable cost per tag, you need to first
        activate the tag from AWS Console. It could take up to 24 hours for the
        tag to become availabe in the cost explorer.
      </p>
      <a
        className="btn btn--md btn--blue"
        href="https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/custom-tags.html"
        target="_blank"
        rel="noreferrer"
      >
        Activate Custom Tag
      </a>
    </>
  );

  const EmptyBox = () => (
    <div
      className="page-centered page-centered--project"
      style={{ paddingTop: "10%" }}
    >
      <img src={CostExplorerScreen} alt="cost explorer screen"/>
      <div className="page-centered__title">Monitor cloud Cost and Savings</div>
      <p>
        Monitor the cost of all connected AWS accounts and get an overview of
        your montly and yearly cloud spendings, as well as cloud saving
        utilization. Highlight the most expensive resources in your cloud
        account. Get a cloud spending forecast to better plan your cloud budget.
      </p>
      {disabledCostExplorer ? (
        <Link className="btn btn--xl" to="/settings?tab=billing">
          Upgrade Plan
        </Link>
      ) : null}
    </div>
  );

  const Dashboard = () => (
    <CustomScrollbars maxHeight="calc(100vh - 120px)">
      <div className="row mr--0">
        <div className="col-6 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div className="row">
            <div className="col-6">
              <MetricsWidget
                title="Estimated Cost"
                badgeColor="success"
                badgeTitle="month"
                value={
                  aggregatedForecast
                    ? `$${estimatedCost}`
                    : "Insufficient Historical Data"
                }
                valueTitle={
                  aggregatedForecast ? (
                    <div>
                      vs ${lastMonthCost}
                      <br></br>last month
                    </div>
                  ) : (
                    <></>
                  )
                }
                footerTitle="Last month"
                footerValue={
                  Math.abs(estimatedMonthTrend) === Infinity ||
                    !aggregatedForecast
                    ? "trend not available"
                    : estimatedMonthTrend > 0
                      ? `↘ ${Math.abs(estimatedMonthTrend)}% less to spend`
                      : `↗ ${Math.abs(estimatedMonthTrend)}% more to spend`
                }
                footerTitleClassName={
                  estimatedMonthTrend > 0 ? "success" : "fail"
                }
                dataKey="pv"
              />
            </div>
            <div className="col-6">
              <MetricsWidget
                title="Month-to-date Spend"
                badgeColor="success"
                badgeTitle="month"
                value={
                  aggregatedHistory
                    ? `$${Math.round(
                      aggregatedHistory!.costForTheCurrentMonth.total
                    )}`
                    : ""
                }
                valueTitle={
                  <div>
                    vs $
                    {aggregatedHistory
                      ? Math.round(aggregatedHistory!.costForTheLastMonth.total)
                      : ""}
                    <br></br>last month
                  </div>
                }
                footerTitle="Last month"
                footerValue="trend not available"
                footerTitleClassName="fail"
                dataKey="pv"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TableWidget
                title="Top AWS Accounts"
                subtitle="This month breakdown by account"
                tableHeaders={["Account:left", "Est.", "Cost"]}
                tableRows={montlyCostByAwsAccount}
              />
            </div>
            <div className="col-6">
              <TableWidget
                title="Top AWS Services"
                subtitle="This month breakdown by service"
                tableHeaders={["Service Name:left", "Est.", "Cost"]}
                tableRows={montlyCostByService}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <BarChartWidget
                title="Cost per Allocation Tags"
                subtitle="This month cost by cost allocation tag per Environment"
                data={costByTag}
                xAxisDataKey="tag"
                yAxisDataKey="cost"
                size="small"
                placeholder={
                  costByTag.length === 0 ? (
                    <AllocationTagsPlaceholder />
                  ) : undefined
                }
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div className="row">
            <div className="col-12">
              <LineChartWidget
                title="How much I spend on AWS?"
                subtitle="Monthly breakdown for the past year"
                valueTitle="Estimated Cost Next Year"
                value={`${convertToCurrency(totalYearCost)}`}
                valueSubtitle={
                  Math.abs(estimatedYearTrend) === Infinity ||
                    !aggregatedForecast
                    ? "trend not available"
                    : estimatedYearTrend > 0
                      ? `↘ ${Math.abs(estimatedYearTrend)}% (${convertToCurrency(
                        aggregatedForecast!
                          .estimatedCostByAWSAccountForTheNextYear.total
                      )}) forecast for the upcoming year`
                      : `↗ ${Math.abs(estimatedYearTrend)}% (${convertToCurrency(
                        aggregatedForecast!
                          .estimatedCostByAWSAccountForTheNextYear.total
                      )}) forecast for the upcoming year`
                }
                valueSubtitleClassName={
                  estimatedYearTrend > 0 ? "success" : "fail"
                }
                data={yearCostByMonth}
                xAxisDataKey="month"
                yAxisDataKeys={(awsAccounts || []).map(
                  (acc) => acc.accountName || acc.id
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <MetricsWidget
                title="Estimated Savings"
                badgeColor="success"
                badgeTitle="month"
                value={`$${estimatedSaving}`}
                valueTitle={
                  <div>
                    Based on current
                    <br />
                    active saving schedules
                  </div>
                }
                footerTitle="Schedules"
                footerValue={`${activeSchedulesCount} active`}
                footerTitleClassName="success"
                placeholder={
                  activeSchedulesCount === 0 ? (
                    <SchedulePlaceholder />
                  ) : undefined
                }
              />
            </div>
            <div className="col-6">
              <BarChartWidget
                title="Saving Utilization"
                subtitle="Daily utilization hours"
                data={dailyUtilizedHours}
                xAxisDataKey="day"
                yAxisDataKey="hours"
                size="small"
                placeholder={
                  activeSchedulesCount === 0 ? (
                    <SchedulePlaceholder />
                  ) : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>
    </CustomScrollbars>
  );

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        title="Cost Explorer"
        items={[
          <Button
            key="submit"
            type="submit"
            className={`btn btn--md btn--create ${report ? "btn--lightBlue" : "btn--green"}`}
            isBusy={isLoading}
            busyText="Loading..."
            onClick={() =>
              props.history.push({
                pathname: report
                  ? "/tools/cost-explorer/reports/edit"
                  : "/tools/cost-explorer/reports/create",
                state: { report: report },
              })
            }
          >
            {report ? "Update Report" : "Create Report"}
          </Button>,
        ]}
      />
      <div className="content__body dashboard">
        {isLoading ? (
          <BuildProcessWaiting text="Collecting cost related data. Please wait..." />
        ) : disabledCostExplorer ? (
          <EmptyBox />
        ) : awsAccounts ? (
          <Dashboard />
        ) : (
          <AwsAccountPlaceholder />
        )}
      </div>
    </main>
  );
};
export default CostExplorer;
