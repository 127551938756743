import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";

// Import Icons
import codeartLogo from "../static/codeart-logo-big.png";
import avatar from "../static/avatar.svg";
import teacher from "../static/teacher.svg";
import scientist from "../static/scientist.svg";

// Users data to be displayed
const users = [
    {
        name: 'Marija',
        image: avatar
    },
    {
        name: 'Vlado',
        image: teacher
    },
    {
        name: 'Toni',
        image: scientist
    }
]

export default function useProjectList(initialFilter: string = "") {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(initialFilter)
    const { projects, isProcessing, error } = useSelector((state: GlobalState) => state.project);

    // Get a list of projects from the backend
    useEffect(() => {
        dispatch(actions.fetchProjects());
    }, [dispatch]);

    const filteredProjects = projects
        .filter(project =>
            project.name.toLowerCase().includes(filter.toLowerCase())
            || project.description.toLowerCase().includes(filter.toLowerCase())
        )
        .map(project => ({
            itemId: project.id,
            id: project.id,
            title: project.name,
            name: project.name,
            description: project.description,
            stages: "5", // Will be dynamically updated
            components: "10", // Will be dynamically updated,
            logo: codeartLogo, //Will be dynamically updated
            users: users //Will be dynamically updated
        }))
        .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        })

    return {
        filteredProjects,
        setFilter,
        filter,
        isProcessing,
        error
    };
}