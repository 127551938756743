import * as constants from "../../constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { getContinuousIntegrationAPI } from "../../api";

import { DockerRegistry } from "@microtica/ms-ap-sdk";


export interface FetchDockerRegistriesStart {
    type: constants.FETCH_DOCKER_REGISTRIES_START;
}

export interface FetchDockerRegistriesSuccess {
    type: constants.FETCH_DOCKER_REGISTRIES_SUCCESS;
    dockerRegistries: DockerRegistry[];
}

export interface FetchDockerRegistriesFail {
    type: constants.FETCH_DOCKER_REGISTRIES_FAIL;
    error: ErrorInterface;
}

export type FetchDockerRegistries =
    | FetchDockerRegistriesStart
    | FetchDockerRegistriesSuccess
    | FetchDockerRegistriesFail;

export function fetchDockerRegistriesStart(): FetchDockerRegistries {
    return {
        type: constants.FETCH_DOCKER_REGISTRIES_START
    };
}

export function fetchDockerRegistriesSuccess(
    dockerRegistries: DockerRegistry[] = []
): FetchDockerRegistriesSuccess {
    return {
        type: constants.FETCH_DOCKER_REGISTRIES_SUCCESS,
        dockerRegistries
    };
}

export function fetchDockerRegistriesFail(error: ErrorInterface): FetchDockerRegistriesFail {
    return {
        type: constants.FETCH_DOCKER_REGISTRIES_FAIL,
        error
    };
}

export function fetchDockerRegistries(
    projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchDockerRegistries> {
    return async (dispatch: ThunkDispatch<{}, {}, FetchDockerRegistries>) => {
        dispatch(fetchDockerRegistriesStart());
        try {
            const res = await getContinuousIntegrationAPI().listDockerRegistries(projectId);
            dispatch(fetchDockerRegistriesSuccess(res.data.dockerRegistries));
        } catch (e) {
            dispatch(fetchDockerRegistriesFail(e.response.data));
        }
    };
}

