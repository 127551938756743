import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Component, ComponentCloudProviderEnum, ComponentInfrastructureAsCodeToolEnum, ComponentTypeEnum, GetComponentsResponseAccessList } from "@microtica/ms-engine-sdk";
import * as actions from "../actions";
import { GlobalState } from "../reducers";

interface PartialComponent {
    itemId: string;
    title: string;
    description: string;
    isPublic: boolean;
    infrastructureAsCodeTool: ComponentInfrastructureAsCodeToolEnum;
    cloudProvider: ComponentCloudProviderEnum;
    type: ComponentTypeEnum;
    accessList: GetComponentsResponseAccessList[];
};

export default function useComponentList(publicOnly?: boolean, initialFilter: string = "") {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(initialFilter);
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { components, isProcessing, error } = useSelector((state: GlobalState) => state.component);
    // Get a list of components from the backend
    useEffect(() => {
        dispatch(actions.fetchComponents(currentProject.id));
    }, [dispatch]);

    const filterBy = (property: string) => {
        return property
            .toLocaleLowerCase()
            .includes(
                filter.trim().toLocaleLowerCase()
            )
    }
    
    const filterComponent = (item: PartialComponent) => {
        const cloudProvider = item.cloudProvider === ComponentCloudProviderEnum.Aws ?
            "aws amazon web services" : item.cloudProvider === ComponentCloudProviderEnum.Google ?
                "gcp google cloud platform" :
                "microsoft azure";

        return filterBy(item.title) || filterBy(item.type) || filterBy(item.description) ||
            filterBy(item.infrastructureAsCodeTool) || filterBy(cloudProvider)
    }

    const filteredItems = components
        .map(item => ({
            itemId: item.id,
            title: item.name,
            description: item.description,
            isPublic: item.isPublic,
            infrastructureAsCodeTool: item.infrastructureAsCodeTool,
            cloudProvider: item.cloudProvider,
            type: item.type,
            accessList: item.accessList
        }))
        .filter(item => Boolean(item.isPublic) === publicOnly)
        .filter(item => {
            if (filter) {
                return filterComponent(item);
            } else {
                return true;
            }
        })
        .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
        });
    return {
        filteredItems,
        filter,
        setFilter,
        isProcessing,
        error
    };
}