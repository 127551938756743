import * as constants from "../../constants";
import { getNotificationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { TimelineEvent, Type } from "@microtica/ms-notification-sdk";

export interface FetchTimelineStart {
    type: constants.FETCH_TIMELINE_START;
}

export interface FetchTimelineSuccess {
    type: constants.FETCH_TIMELINE_SUCCESS;
    events: TimelineEvent[];
}

export interface FetchTimelineFail {
    type: constants.FETCH_TIMELINE_FAIL;
    error: ErrorInterface;
}

export type FetchTimeline =
    | FetchTimelineStart
    | FetchTimelineSuccess
    | FetchTimelineFail;

export function fetchTimelineStart(): FetchTimelineStart {
    return {
        type: constants.FETCH_TIMELINE_START
    };
}

export function fetchTimelineSuccess(
    events: TimelineEvent[] = []
): FetchTimelineSuccess {
    return {
        type: constants.FETCH_TIMELINE_SUCCESS,
        events
    };
}

export function fetchTimelineFail(error: ErrorInterface): FetchTimelineFail {
    return {
        type: constants.FETCH_TIMELINE_FAIL,
        error
    };
}

export function fetchTimeline(
    projectId: string,
    type: string
): ThunkAction<Promise<void>, {}, {}, FetchTimeline> {
    return async (dispatch: ThunkDispatch<{}, {}, FetchTimeline>) => {
        dispatch(fetchTimelineStart());
        try {
            let response;
            const typeOfEvent = type === Type.Build ? Type.Build : type === Type.Deployment ? Type.Deployment : ""
            if (typeOfEvent != "") {
                response = await getNotificationAPI().getTimelineForProjectByType(projectId, typeOfEvent);
            } else {
                response = await getNotificationAPI().getTimelineForProject(projectId);
            }
            dispatch(fetchTimelineSuccess(response.data.events));
        } catch (error) {
            dispatch(fetchTimelineFail(error));
        }
    };
}

