import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getElasticSearchAPI } from '../api';
import { GlobalState } from "../reducers";

export default function usePipelineMetrics(pipelineId?: string) {

    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [pipelineMetrics, setPipelineMetrics] = useState<{ succesfullExecutions: number, failedExecutions: number, successRate: number, buildTime: number }>();
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const fetch = async () => {
            const { data: { response: pipelineMetrics } } = await getElasticSearchAPI().getPipelineMetrics(currentProject.id, pipelineId || undefined);
            setPipelineMetrics({
                succesfullExecutions: pipelineMetrics.successfulExecutions,
                failedExecutions: (pipelineMetrics.failedExecutions),
                successRate: (pipelineMetrics.successfulExecutions === 0 && pipelineMetrics.failedExecutions === 0)
                    ? 0 : (pipelineMetrics.successfulExecutions / (pipelineMetrics.successfulExecutions + pipelineMetrics.failedExecutions) * 100),
                buildTime: pipelineMetrics.buildTime / 60000
            });
            setIsLoaded(true);
        }
        fetch();
    }, [])

    return {
        isLoaded,
        pipelineMetrics
    }
}

