import React, { useState } from "react";

import InputText from "../../components/InputText/InputText";
import Button from "../../components/Button/Button";
import ToggleButton from "../../components/ToggleButton/ToggleButton";

import { ReactComponent as RemoveIcon } from "../../static/delete-icon.svg";

interface EnvironmentVariablesItem {
    id?: number;
    key: string;
    value: string;
    sensitive?: boolean;
}

interface EnvironmentVariablesProps {
    disabled?: boolean;
    onChange?: (items: EnvironmentVariablesItem[]) => void;
    initialItems?: EnvironmentVariablesItem[];
}

interface EnvironmentVariable {
    id?: number;
    key: string;
    value: string;
    sensitive?: boolean;
}


export default function EnvironmentVariables(props: EnvironmentVariablesProps) {
    const [variables, setVariables] = useState<EnvironmentVariable[]>((props.initialItems || []).map((item, index) => ({
        id: index + 1,
        key: item.key,
        value: item.value,
        sensitive: item.sensitive
    })));

    const [error, setError] = useState<string>();

    function handleChange(updatedVars: EnvironmentVariablesItem[]) {
        setVariables(updatedVars);

        if (props.onChange) {
            if (!hasEmpty(updatedVars)) {
                setError("")
            }
            // TODO validate on duplicates
            const normalizedVars: EnvironmentVariablesItem[] = updatedVars.reduce((acc, item) => {
                if (!(item.key === "" && item.value === "")) {
                    acc.push({
                        key: item.key,
                        value: item.value,
                        sensitive: item.sensitive || false
                    });
                }
                return acc;
            }, [] as { key: string; value: string; sensitive: boolean }[]);
            props.onChange(normalizedVars);  
        }
    }

    function hasEmpty(vars: EnvironmentVariable[]) {
        return vars.find(item => {
            return item.key.trim() === "" || item.value.trim() === "";
        })
    }

    return (
        <>
            {
                variables.map(item => (
                        <div className="variables" key={item.id}>
                        <div className="row">
                            <div className="col-12 col-xl-5">
                                <InputText
                                    type="text"
                                    placeholder="Enter variable name here"
                                    value={item.key}
                                    onChange={(e) => {
                                        const updatedVars = variables.map(v => {
                                            if (v.id === item.id) {
                                                return { ...v, key: e.target.value };
                                            } else {
                                                return v;
                                            }
                                        });
                                        handleChange(updatedVars);
                                    }}
                                />
                            </div>
                            <div className="col-12 col-xl-5">
                                <div className="variables__item">
                                    <React.Fragment>
                                        <InputText
                                            type={item.sensitive ? "password" : "text"}
                                            placeholder="Enter variable value here"
                                            value={item.value}
                                            onChange={(e) => {
                                                const updatedVars = variables.map(v => {
                                                    if (v.id === item.id) {
                                                        return { ...v, value: e.target.value };
                                                    } else {
                                                        return v;
                                                    }
                                                });
                                                handleChange(updatedVars);
                                            }}
                                        />
                                        <RemoveIcon onClick={() => {
                                            const updatedVars = variables.filter(v => v.id !== item.id);
                                            // Reset item IDs 
                                            updatedVars.map((v, index) => v.id = index + 1);
                                            handleChange(updatedVars);
                                        }} />
                                    </React.Fragment>

                                    <ToggleButton
                                        name="Sensitive"
                                        isChecked={item.sensitive}
                                        onChange={() => {
                                            const updatedVars = variables.map(v => {
                                                if (v.id === item.id) {
                                                    return { ...v, sensitive: !item.sensitive };
                                                } else {
                                                    return v;
                                                }
                                            });
                                            handleChange(updatedVars);
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>   
                ))
            }
            <div className="page-content__error" style={{padding: "0 0 15px 2px"}}>{error}</div>
            <Button
                className="btn btn--md btn--dark btn--create"
                disabled={props.disabled}
                onClick={() => {
                    if(hasEmpty(variables)) {
                        setError("Please enter key and value")
                        return;
                    } else {
                        setError("");
                        const vars = [...variables];
                        vars.push({ id: variables.length + 1, key: "", value: "" });
                        setVariables(vars);
                    }
                }}>
                Add New Variable
            </Button>
        </>
    );
}