import { UserState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';
import { Login, PageTitle, CurrentUser } from '../actions/user';

export const initialState: UserState = {
    profile: {
        id: "",
        email: "",
        firstName: "",
        lastName: ""
    },
    isLoading: false,
    isLoggedIn: false,
    idToken: "",
    currentPage: "Home",
    error: errorEmptyObject

}

export default function reducer(state: UserState = initialState, action: Login | PageTitle | CurrentUser): UserState {
    switch (action.type) {

        case constants.LOGIN_START: return { ...state, isLoading: true, error: errorEmptyObject };
        case constants.LOGIN_SUCCESS: return { ...state, idToken: action.data.idToken, profile: action.data.profile, isLoggedIn: true, isLoading: false, error: errorEmptyObject };
        case constants.LOGIN_FAIL: return { ...state, error: action.error };
        case constants.LOGOUT: return {
            ...state, profile: {
                id: "",
                email: "",
                firstName: "",
                lastName: ""
            },
            isLoggedIn: false
        };

        case constants.CHANGE_PAGE_TITLE: return { ...state, currentPage: action.title }
        case constants.SET_LOGGED_IN: return { ...state, isLoggedIn: true }
        case constants.CURRENT_USER: return { ...state, profile: action.user }

        default: {
            return state;
        }
    }
}
