import * as constants from "../../constants";
import { getContinuousIntegrationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";


export interface DeletePipelineStart {
    type: constants.DELETE_PIPELINE_START;
}

export interface DeletePipelineSuccess {
    type: constants.DELETE_PIPELINE_SUCCESS;
    pipelineId: string;
}

export interface DeletePipelineFail {
    type: constants.DELETE_PIPELINE_FAIL;
    error: ErrorInterface;
}

export type DeletePipeline =
    | DeletePipelineStart
    | DeletePipelineSuccess
    | DeletePipelineFail;

export function deletePipelineStart(): DeletePipeline {
    return {
        type: constants.DELETE_PIPELINE_START
    };
}

export function deletePipelineSuccess(
    pipelineId: string
): DeletePipelineSuccess {
    return {
        type: constants.DELETE_PIPELINE_SUCCESS,
        pipelineId
    };
}

export function deletePipelineFail(error: ErrorInterface): DeletePipelineFail {
    return {
        type: constants.DELETE_PIPELINE_FAIL,
        error
    };
}

export function deletePipeline(
    projectId: string,
    pipelineId: string
): ThunkAction<Promise<void>, {}, {}, DeletePipeline> {
    return async (dispatch: ThunkDispatch<{}, {}, DeletePipeline>) => {
        dispatch(deletePipelineStart());
        try {
            await getContinuousIntegrationAPI().deletePipeline(projectId, pipelineId);
            dispatch(deletePipelineSuccess(pipelineId));
        } catch (e) {
            dispatch(deletePipelineFail(e.response.data));
        }
    };
}

