import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import Button from "../../components/Button/Button";
import { getProjectAPI } from "../../api";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import Table from "../../components/Table/Table";
import BillingToggleButton from "../../components/BillingToggleButton/BillingToggleButton";
import { GlobalState } from "../../reducers";
import { InlineResponse2001, InlineResponse200PaymentPlans } from "@microtica/ms-project-sdk";
import { toast } from "react-toastify";
import moment from "moment";
import BuildProcessWaiting from "../../components/BuildProcessWaiting/BuildProcessWaiting";
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";
import { redeemCodeSchema } from "../../utils/validation";

import "../../utils/2checkout";
import { paymentLimitationsMap } from "../../utils/payment-limitations-map";
import { useModal } from "react-modal-hook";
import ModalInput from "../../components/ModalInput/ModalInput";
import { Field, Form, Formik } from "formik";
import { InputField } from "../../components/InputField/InputField";
import { trackBillingPilot } from "../../tracking/user-settings";

const tableHeaders = [
    "Date",
    "Expiration Date",
    "Type",
    "Subscription Id"
];

const Billing = () => {
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const userProfile = useSelector((state: GlobalState) => state.user.profile);
    const [subscription, setSubscription] = useState<InlineResponse2001>();
    const [developerPlan, setDeveloperPlan] = useState<InlineResponse200PaymentPlans>();
    const [isYearly, setIsYearly] = useState(true);
    const [hasPromotion, setHasPromotion] = useState(false);
    const [recurringEnabled, setRecurringEnabled] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState<any>([]);
    const [plans, setPlans] = useState<InlineResponse200PaymentPlans[]>([]);

    const [showRedeemCodeModal, hideRedeemCodeModal] = useModal(() => (
        <ModalInput
            name="Promo code redemption"
            open
            class="modal modal--account"
            onClose={() => {
                hideRedeemCodeModal();
            }}>

            <Formik
                initialValues={{ redeemCode: "" }}
                onSubmit={(values) => {
                    handleRedeemPromoCode(values.redeemCode);
                }}
                validationSchema={redeemCodeSchema}
                render={() => (
                    <>
                        <Form>
                            <br /> <br />
                            <div className="d-flex justify-content-center align-items-center flex-wrap">
                                <p className="mb--10 txt--left">
                                    Enter your Promo code below to activate 1 year of Microtica Professional Plan:
                                </p>
                                <Field
                                    name="redeemCode"
                                    type="text"
                                    hasError={true}
                                    placeholder="Promo Code"
                                    component={InputField}
                                />
                                <Button
                                    type="submit"
                                    className="btn btn--md btn--blue"
                                    children="Submit"
                                />
                            </div>
                        </Form>
                    </>
                )}
            />
        </ModalInput>
    ), []);

    useEffect(() => {
        getSubscription();
        checkPromotion();
    }, [])

    const handleRedeemPromoCode = async (reedemCode: string) => {
        try {
            await getProjectAPI().applyPromoCode(reedemCode, userProfile.email);
            await getProjectAPI().usePromoCode(currentProject.id);
            toast.success("You have successfully redeemed a promo code")
            checkPromotion();
            hideRedeemCodeModal();
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const upgradePlan = () => {
        const twoCoInlineCart = (window as any).TwoCoInlineCart;
        twoCoInlineCart.products.removeAll()
        twoCoInlineCart.products.add({
            code: developerPlan!.productCode,
            options: [{ name: "PERIOD", value: isYearly ? "yearly" : "monthly" }]
        });
        twoCoInlineCart.cart.setOrderExternalRef(currentProject.id); // order external reference
        twoCoInlineCart.cart.checkout(); // start checkout process
    }

    const getPlans = async () => {
        try {
            const { data: { paymentPlans } } = await getProjectAPI().getAllPaymentPlans();
            setPlans(
                paymentPlans!.filter(p => ["Business"].includes(p.name)).reverse()
            );

            const developerPlan = paymentPlans!.find(p => p.name.includes("Developer"));
            setDeveloperPlan(developerPlan);
        } catch (error) {
            toast.error(error.message)
        }
    }

    const cancelSubscription = async () => {
        try {
            await getProjectAPI().disableSubscriptionRecurrency(currentProject.id);
            toast.success("You have disabled subscription recurrency");
            setRecurringEnabled(false)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const getSubscription = async () => {
        try {
            const { data } = await getProjectAPI().getPaymentSubscription(currentProject.id);
            setSubscription(data);
            setRecurringEnabled(data.recurringEnabled);
            getSubscriptionHistory();
        } catch (error) {
            getPlans();
        }
    }

    const checkPromotion = async () => {
        const { data: { hasPromotion } } = await getProjectAPI().checkPromotion(currentProject.id);
        setHasPromotion(hasPromotion!);
    }

    const enableSubscripiton = async () => {
        try {
            await getProjectAPI().enableSubscriptionRecurrency(currentProject.id);
            setRecurringEnabled(true)
            toast.success("You have enabled subscription recurrency");
        } catch (error) {
            toast.error(error.message)
        }
    }

    const getSubscriptionHistory = async () => {
        try {
            const { data: { history } } = await getProjectAPI().getPaymentSubscriptionHistory(currentProject.id);
            setPaymentHistory(history.map(h => ([
                h.startDate,
                h.expirationDate,
                h.type,
                h.subscriptionId
            ])))
        } catch (error) {
            toast.error("Error while getting payment history")
        }
    }
    const freePlan = (
        <div className="billing">
            {hasPromotion ?
                <div className="row d-flex justify-content-center"
                    style={{ marginTop: "2%" }}>
                    <h4>
                        You’re currently using the AppSumo special deal ($69 for 1 year).
                    </h4>
                    <br />
                </div>
                : null}

            <div className="row d-flex justify-content-center"
                style={{ marginTop: "2%" }}>
                <BillingToggleButton
                    text={true}
                    isChecked={isYearly}
                    onChange={() => setIsYearly(!isYearly)}
                />
                <br /> <br /> <br />
            </div>
            <div className="row d-flex justify-content-center">
                <br /> <br />
                {
                    plans.length ? plans.map((plan: any, index) => (
                        <div key={index} className={`plan ${plan.name === "Business" ? "plan__popular" : ""}`}>
                            <div className="plan-content">
                                <div className="plan-features">
                                    <div className="name">{plan.name}</div>
                                    <br />
                                    <h3 > ${isYearly ? new Intl.NumberFormat("en-us").format(plan.pricing.yearly) : new Intl.NumberFormat("en-us").format(plan.pricing.monthly)}/month</h3>
                                    <br />
                                    <div className="d-flex justify-content-center">
                                        <ul className="ul">
                                            {
                                                Object.entries(plan.limitations).map(([key, value], index) =>
                                                    <li key={index} className="li" style={!(!!value) ? { display: "none" } : {}}>
                                                        &nbsp;&nbsp;
                                                        {typeof value === "boolean" ? paymentLimitationsMap(key) : `${value} ${paymentLimitationsMap(key)}`}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <br /> <br /> <br />
                                <div className="plan-actions">
                                    {
                                        plan.name === "Business" ?
                                            <a
                                                className="btn btn--xl btn--green"
                                                href="https://calendly.com/microtica/30min"
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={trackBillingPilot}
                                            >
                                                Free Pilot
                                            </a> :
                                            <a
                                                className="btn btn--xl btn--green"
                                                href="https://calendly.com/microtica/30min"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Let's Talk
                                            </a>
                                    }
                                </div>
                            </div>
                        </div>
                    )) : <BuildProcessWaiting text="Loading plan. Please wait..." />
                }
            </div >
            <br />
            <div className="row d-flex justify-content-center white">
                <div style={{ cursor: "pointer", fontSize: "1.2em", color: "white" }} onClick={showRedeemCodeModal}>Have a promo code?</div>
            </div>
            <br />
            <h6 className="row d-flex justify-content-center white">
                For plan related query, please refer<a href="https://microtica.com/pricing/" target="_blank" rel="noreferrer"> &nbsp;FAQs</a>
            </h6>
        </div >
    );

    const paidPlan = (
        <div className="notifications__preferences">

            {/* first row */}
            <div className="row">
                {
                    subscription ? <>

                        {/* left card */}
                        <div className="col-md-6 col-sm-6">
                            <Card class="card card--billing light mb--30">
                                <div className="card__header-wrapper">
                                    <CardHeader title="Your Current Plan" />
                                </div>
                                <div className="card__content">
                                    <div style={{ width: "100%", height: "100%" }}
                                        className="row d-flex align-items-center justify-content-between">
                                        <div className="col-md-5 col-sm-6">
                                            <h3>
                                                {subscription.plan.name}
                                            </h3>
                                        </div>
                                        <div className="col-md-5 col-sm-6">
                                            <div>
                                                <h4 className="txt--right">
                                                    ${subscription.plan.price}/{subscription.plan.frequency[0] + subscription.plan.frequency.substr(1)}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ width: "100%", height: "100%" }}
                                        className="row d-flex align-items-center justify-content-between">
                                        <div className="col-md-4">
                                            <Button
                                                className={`btn btn--md ${recurringEnabled ? "btn--danger" : "btn--blue"}`}
                                                children={`${recurringEnabled ? "Disable" : "Enable"} recurrency`}
                                                onClick={() => {
                                                    recurringEnabled ? cancelSubscription() : enableSubscripiton()
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <div className="col-md-12">
                                            <div className="page-content__info">
                                                <InfoIcon />
                                                <p className="m--0">
                                                    Enable recurrent payment for automated billing.
                                                    <br />
                                                    With recurrent payment disabled, you will be asked to provide payment details on each due date.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        {/* right card */}
                        <div className="col-sm-6 col-md-6">
                            <Card class="card card--billing light mb--30">
                                <div className="card__header-wrapper">
                                    <CardHeader title="Status" />
                                </div>
                                <div className="card__content">
                                    <div style={{ width: "100%", height: "100%" }}
                                        className="row d-flex align-items-center justify-content-between">
                                        <div className="col-md-5 col-sm-5">
                                            <h3>
                                                {subscription.status}
                                            </h3>
                                        </div>
                                        <div className="col-md-4 col-sm-4 offset-sm-3 offset-md-3">
                                            <h6 className="txt--right">
                                                Next Payment: <br />
                                                {moment(subscription.expirationDate).format("D MMMM YYYY")}
                                            </h6>
                                        </div>
                                    </div>
                                    <br />
                                    <div style={{ width: "100%", height: "100%" }}
                                        className="row d-flex align-items-center justify-content-between">
                                        <div className="col-md-4">
                                            <a target="_blank"
                                                className="btn btn--md btn--blue"
                                                href="https://secure.2co.com/myaccount/"
                                                rel="noreferrer">
                                                Update billing info
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <div className="col-md-12">
                                            <div className="page-content__info">
                                                <InfoIcon />
                                                <p className="m--0">
                                                    Clicking on this button will redirect you on 2Checkout.
                                                    <br />
                                                    To update your billing information, login on 2Checkout using the same email address you use for Microtica.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                    </> : null
                }

            </div>

            <br /><br />

            {/* second row */}
            <div className="row">
                <div className="col-12 col-xl-12">
                    <Card class="card light">
                        <div className="card__header-wrapper">
                            <CardHeader title="Billing History" />
                        </div>
                        <div className="table-wrapper-sticky table-wrapper-sticky_dark">
                            <CustomScrollbars maxHeight="calc(50vh - 220px)">
                                <Table
                                    isLoaded={true}
                                    headers={tableHeaders}
                                    tableModifier="billing"
                                    rows={paymentHistory!}
                                />
                            </CustomScrollbars>
                        </div>
                    </Card>
                </div>
            </div>
        </div >
    );

    return (
        <Fragment>
            {subscription ? paidPlan : freePlan}
        </Fragment>
    )
}

export default Billing;