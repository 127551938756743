import * as React from "react";
import Animation from "../../components/Animations/Animations";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";

export interface Props {
  name: string;
  open: boolean;
  onClose: React.MouseEventHandler;
  class: string;
  logo?: any;
}

function ModalInput(props: React.PropsWithChildren<Props>) {
  return (
    <React.Fragment>
      <div className="page-overlay"></div>

      <Animation show={true} type="modal" modal itemIndex={1}>
        <div className={props.class}>
          <span className="modal__close" onClick={props.onClose}>X</span>
          <CustomScrollbars maxHeight="calc(100vh - 250px)" resetClass="reset--top">
            <div className="modal--padding">
              {props.logo ? <div className="modal__logo">{props.logo}</div> : null}
              {props.name ? <h3 className="modal__title">{props.name}</h3> : null}
              {props.children}
            </div>
          </CustomScrollbars>
        </div>
      </Animation>
    </React.Fragment>
  );
}

export default ModalInput;
