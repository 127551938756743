import * as constants from "../../constants";
import { Component } from "@microtica/ms-engine-sdk";
import { getEngineAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";

export interface FetchComponentsStart {
  type: constants.FETCH_COMPONENTS_START;
}

export interface FetchComponentsSuccess {
  type: constants.FETCH_COMPONENTS_SUCCESS;
  components: Component[];
}

export interface FetchComponentsFail {
  type: constants.FETCH_COMPONENTS_FAIL;
  error: ErrorInterface;
}

export type FetchComponents =
  | FetchComponentsStart
  | FetchComponentsSuccess
  | FetchComponentsFail;

export function fetchComponentsStart(): FetchComponents {
  return {
    type: constants.FETCH_COMPONENTS_START
  };
}

export function fetchComponentsSuccess(
  components: Component[]
): FetchComponentsSuccess {
  return {
    type: constants.FETCH_COMPONENTS_SUCCESS,
    components
  };
}

export function fetchComponentsFail(error: ErrorInterface): FetchComponentsFail {
  return {
    type: constants.FETCH_COMPONENTS_FAIL,
    error
  };
}

export function fetchComponents(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchComponents> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchComponents>) => {
    // const projectId = "y2g6hjf2miurpg347953";
    dispatch(fetchComponentsStart());
    try {
      const res = await getEngineAPI().getComponents(projectId);
      dispatch(fetchComponentsSuccess(res.data.components!));
    } catch (e) {
      dispatch(fetchComponentsFail(e.response.data));
    }
  };
}
