import * as constants from "../../constants";
import { getNotificationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { UserNotificationsNotifications } from "@microtica/ms-notification-sdk";

export interface FetchUserNotificationsStart {
    type: constants.FETCH_USER_NOTIFICATIONS_START;
}

export interface FetchUserNotificationsSuccess {
    type: constants.FETCH_USER_NOTIFICATIONS_SUCCESS;
    noitifications: UserNotificationsNotifications[];
}

export interface FetchUserNotificationsFail {
    type: constants.FETCH_USER_NOTIFICATIONS_FAIL;
    error: ErrorInterface;
}

export type FetchUserNotifications =
    | FetchUserNotificationsStart
    | FetchUserNotificationsSuccess
    | FetchUserNotificationsFail;

export function fetchUserNotificationsStart(): FetchUserNotifications {
    return {
        type: constants.FETCH_USER_NOTIFICATIONS_START
    };
}

export function fetchUserNotificationsSuccess(
    noitifications: UserNotificationsNotifications[] = []
): FetchUserNotificationsSuccess {
    return {
        type: constants.FETCH_USER_NOTIFICATIONS_SUCCESS,
        noitifications
    };
}

export function fetchUserNotificationsFail(error: ErrorInterface): FetchUserNotificationsFail {
    return {
        type: constants.FETCH_USER_NOTIFICATIONS_FAIL,
        error
    };
}

export function fetchUserNotifications(): ThunkAction<Promise<void>, {}, {}, FetchUserNotifications> {
    return async (dispatch: ThunkDispatch<{}, {}, FetchUserNotifications>) => {
        dispatch(fetchUserNotificationsStart());
        try {
            const { data: { notifications } } = await getNotificationAPI().getAllUserNotifications();
            dispatch(fetchUserNotificationsSuccess(notifications));
        } catch (error) {
            dispatch(fetchUserNotificationsFail(error.response.data));
        }
    };
}

