import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useModal } from "react-modal-hook";
import moment from "moment";

import { ComponentCloudProviderEnum, ComponentInfrastructureAsCodeToolEnum, GetStageResourcesResponseResources, GetStageResponse, GetStageResponseCloudProviderEnum, GetStageResponseInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";

import * as actions from "../../actions";

import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import StageHeader from "./StageHeader";
import StageResourcesList from "./StageResourcesList";
import TabMenuContainer from "../../components/TabMenuContainer/TabMenuContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import ListView from "../../components/ListView/ListView";
import componentCategories from "../../utils/component-categories";
import useComponentList from "../../hooks/ComponentList";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";

import { GlobalState } from "../../reducers";
import { toast } from 'react-toastify';
import { getCloudCostOptimizerAPI, getEngineAPI, getElasticSearchAPI } from "../../api";

import ResourceConfigurationModal from "../../components/ModalConfigure/ResourceConfigurationModal";
import ModalInfo from "../../components/ModalInfo/ModalInfo";
import BuildProcessWaiting from "../../components/BuildProcessWaiting/BuildProcessWaiting";
import { advisorResources } from "../StagesList/StagesList";
import { StageDeploymentResponseResponse } from "@microtica/ms-elasticsearch-sdk";
import { ChangeStateAllResourcesRequestActionEnum, ListSchedulesResponseRules } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { trackCancelDeployment, trackCreatePlanInit, trackUndeployInit, trackQuickDeploy } from "../../tracking/deployment";
import { trackEnvComponentRemoved, trackEnvReplicateInit } from "../../tracking/environment";

interface StageDetailsProps extends RouteComponentProps<{ stageId: string }> {
  stageId: string;
}

const StageDetails = (props: StageDetailsProps) => {
  const currentProjectId = useSelector((state: GlobalState) => state.project.currentProject.id);
  const [errorStageDetails, setErrorStageDetails] = useState<string>("");
  const [publicOnly, setPublicOnly] = useState(false);
  let {
    filter,
    setFilter,
    filteredItems,
    isProcessing: isFetchingComponents,
    error: errorComponents
  } = useComponentList(publicOnly, "");

  const [selectedComponentId, setSelectedComponentId] = useState<string>("");

  const [selectedResource, setSelectedResource] = useState<GetStageResourcesResponseResources>();
  const [deploying, setDeploying] = useState<boolean>(false);
  const [stageId] = useState(props.match.params.stageId);
  const [stageName, setStageName] = useState("n/a");
  const [description, setDescription] = useState("Environment Details");
  const [cloudTool, setCloudTool] = useState<GetStageResponseInfrastructureAsCodeToolEnum>();
  const [cloudProvider, setCloudProvider] = useState<GetStageResponseCloudProviderEnum>();
  const [componentList, setComponentList] = useState<{
    itemId: string;
    title: string;
    description: string;
    isPublic: boolean;
    infrastructureAsCodeTool: ComponentInfrastructureAsCodeToolEnum;
  }[]>([]);
  const [status, setStatus] = useState("n/a");
  const [awsAccountId, setAwsAccountId] = useState("n/a");
  const [awsRegion, setAwsRegion] = useState("n/a");
  const [gcpProjectId, setGcpProjectId] = useState<string>();
  const [gcpRegion, setGcpRegion] = useState<string>();
  const [gcpZone, setGcpZone] = useState<string>();
  const [azureTenant, setAzureTenant] = useState<string>();
  const [azureSubscription, setAzureSubscription] = useState<string>();
  const [lastDeployed, setLastDeployed] = useState("n/a");
  const [stageResources, setStageResources] = useState<GetStageResourcesResponseResources[]>([]);
  const [deploymentHistory, setDeploymentHistory] = useState<StageDeploymentResponseResponse>();
  const [advisor, setAdvisor] = useState<advisorResources>();
  const [savingSchedule, setSavingSchedule] = useState<ListSchedulesResponseRules>();
  const [resourcesStatus, setResourcesStatus] = useState(ChangeStateAllResourcesRequestActionEnum.Start);
  const dispatch = useDispatch();

  // Check if content is loaded
  const [isLoaded, setIsLoaded] = useState(false);

  function isStatusReady(stat: string) {
    return stat === "NOT_DEPLOYED" || stat === "REVIEW_IN_PROGRESS" || status === "DELETE_COMPLETE";
  }

  function isResourceDeployed(status: string) {
    return status !== "NOT_DEPLOYED" && status !== "DELETE_COMPLETE" && status !== "CREATE_FAILED"
  }
  function getLastDeployed(stageDetails: GetStageResponse) {
    return stageDetails.lastDeployed && !isStatusReady(stageDetails.status!) ? moment(stageDetails.lastDeployed).fromNow() : "N/A";
  }

  useEffect(() => {
    if (filter || (!isFetchingComponents && cloudTool && cloudProvider)) {
      setComponentList(filteredItems.filter(component => component.infrastructureAsCodeTool === (cloudTool as unknown as ComponentInfrastructureAsCodeToolEnum) && component.cloudProvider === (cloudProvider as unknown as ComponentCloudProviderEnum)));
    }
  }, [isFetchingComponents, cloudTool, cloudProvider, filter, publicOnly]);

  const fetch = async () => {
    try {
      const [{ data: { resources } }, { data: stageDetails }] = await Promise.all([
        getEngineAPI().getStageResources(
          stageId,
          currentProjectId
        ),
        getEngineAPI().getStage(
          stageId,
          currentProjectId
        )
      ]);

      if (stageDetails.status !== "DELETE_COMPLETE") {
        try {
          const { data: { response } } = await getElasticSearchAPI().getStageDeploymentHistory(
            currentProjectId,
            stageId,
            stageDetails.lastDeploymentId
          );

          if (response.length) {
            setDeploymentHistory(response[0]);
          }
        } catch (e) { // payment limitation
          setDeploymentHistory(undefined);
        }
      } else {
        setDeploymentHistory(undefined);
      }

      setDescription(stageDetails.name);
      setStageName(stageDetails.name);
      setCloudTool(stageDetails.infrastructureAsCodeTool!);
      setCloudProvider(stageDetails.cloudProvider);
      setStatus(stageDetails.status!);
      setAwsAccountId(stageDetails.awsAccountId!);
      setAwsRegion(stageDetails.region!);
      setGcpProjectId(stageDetails.gcpProjectId);
      setGcpRegion(stageDetails.gcpRegion);
      setGcpZone(stageDetails.gcpZone);
      setAzureTenant(stageDetails.tenantId);
      setAzureSubscription(stageDetails.subscriptionId);
      setLastDeployed(getLastDeployed(stageDetails));
      setStageResources(resources);

      setIsLoaded(true);

      if (stageDetails.awsAccountId && stageDetails.region) {

        const { data: { ec2, elb, rds } } = await getCloudCostOptimizerAPI().getAllStats(
          stageDetails.awsAccountId!,
          currentProjectId,
          stageDetails.region!,
          "microtica:environment",
          stageId
        );

        setAdvisor({
          ec2,
          elb,
          rds
        })
      }
    }
    catch (error) {
      setErrorStageDetails(error.message || "Something went wrong while getting statistics!");
    }

    fetchSchedules();
  };

  const fetchSchedules = async () => {
    const { data: schedules } = await getCloudCostOptimizerAPI().listSchedules(currentProjectId);
    const stageSchedule = schedules.rules.find(schedule => schedule.resourcesTagValue === stageId && schedule.state === "ENABLED");

    setSavingSchedule(stageSchedule);
  }

  useEffect(() => {
    fetch();
  }, [])

  useEffect(() => {
    if (errorStageDetails || errorComponents.message)
      toast.error(errorStageDetails || errorComponents.message);
  }, [errorStageDetails, errorComponents])

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedResource!.name}" resource?`}
      description="Do you really want to delete the resource? This action cannot be undone."
      action="Delete"
      onCancel={hideDeleteConfirmationModal}
      onConfirm={handleDeleteResourceConfirmation}
    />
  ), [selectedResource, selectedComponentId]);

  const [showUndeployConfirmationModal, hideUndeployConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to undeploy "${selectedResource!.name}" resource?`}
      description="Do you really want to undeploy the resource? This action cannot be undone."
      action="Undeploy"
      onCancel={hideUndeployConfirmationModal}
      onConfirm={handleUndeployResourceConfirmation}
    />
  ), [selectedResource, selectedComponentId]);

  const [showCancelModal, hideCancelModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to cancel ${stageId} deployment?`}
      description="Do you really want to cancel the deployment? This action cannot be undone."
      action="Yes"
      onCancel={hideCancelModal}
      onConfirm={cancelStageDeployment}
    />
  ), []);

  const [showAwsAccountModal, hideAwsAccountModal] = useModal(() => (
    <ModalInfo
      title={`Target AWS account`}
      description="Before you can deploy an environment you should select your target AWS account where the resources will be provisioned."
      action="Select AWS account"
      onCancel={hideAwsAccountModal}
      onConfirm={() => props.history.push(`/environments/${stageId}/edit`)}
    />
  ), []);

  const [showAzureAccountModal, hideAzureAccountModal] = useModal(() => (
    <ModalInfo
      title={`Target Microsoft Azure account`}
      description="Before you can deploy an environment you should select your target Microsoft Azure account where the resources will be provisioned."
      action="Select Microsoft Azure account"
      onCancel={hideAzureAccountModal}
      onConfirm={() => props.history.push(`/environments/${stageId}/edit`)}
    />
  ), []);

  const [showGcpAccountModal, hideGcpAccountModal] = useModal(() => (
    <ModalInfo
      title={`Target Google Cloud Platform account`}
      description="Before you can deploy an environment you should select your target Google Cloud Platform account where the resources will be provisioned."
      action="Select Google Cloud Platform account"
      onCancel={hideGcpAccountModal}
      onConfirm={() => props.history.push(`/environments/${stageId}/edit`)}
    />
  ), []);

  const handleSelectComponent = (itemId: string) => {
    const item = componentList.find(component => component.itemId === itemId);
    setSelectedComponentId(item!.itemId);
    showConfigModal();
  }

  const handleAddToStage = () => {
    // Hide Configure modal
    hideConfigModal();
    if (selectedResource) {
      toast.success("Successfully updated resource configuration.");
    } else {
      toast.success("Successfully added resource in the environment.");
    }
    cleanUp();
    //Refresh the list
    fetch();
  };

  const handleOnClose = () => {
    hideConfigModal();
    cleanUp();
  }

  const handleResourceConfigure = (resourceName: string) => {
    const currentResource: GetStageResourcesResponseResources = stageResources.find(resource => resource.name === resourceName)!;
    setSelectedComponentId(currentResource.component.id);
    setSelectedResource(currentResource);
    showConfigModal();
  }

  const handleResourceDelete = (resourceName: string) => {
    const currentResource: GetStageResourcesResponseResources = stageResources!.find(resource => resource.name === resourceName)!;
    setSelectedResource(currentResource);
    showDeleteConfirmationModal();
  }

  const handleResourceUndeploy = (resourceName: string) => {
    const currentResource: GetStageResourcesResponseResources = stageResources!.find(resource => resource.name === resourceName)!;
    setSelectedResource(currentResource);
    showUndeployConfirmationModal();
  }

  async function cancelStageDeployment() {
    try {
      await getEngineAPI().cancelDeploy(stageId, currentProjectId);
      toast.warn("Deployment cancelled!");
      trackCancelDeployment(stageId);
    } catch (error) {
      toast.error(error.message);
    }
    hideCancelModal();
  }

  const handleDeleteResourceConfirmation = async () => {
    try {
      const { data: res } = await getEngineAPI().deleteResource(
        stageId,
        selectedResource!.name,
        currentProjectId
      );
      if (res.done) {
        toast.success("Deleted resource.");
        fetch();
        trackEnvComponentRemoved(stageId, stageName, selectedResource!.component.id, selectedResource!.name)
      }
    }
    catch (error) {
      setErrorStageDetails(error.response.data.message);
    }
    hideDeleteConfirmationModal();
    cleanUp();
  }

  const handleUndeployResourceConfirmation = async () => {
    try {
      const { data: res } = await getEngineAPI().undeployResource(
        stageId,
        selectedResource!.name,
        currentProjectId
      );
      const disabledInsights = await checkIfNotProperPaymentPlan();
      if (disabledInsights) {
        props.history.push({
          pathname: `/environments/${stageId}/details`,
          state: { disabledInsights: true }
        });
      }
      else {
        props.history.push({
          pathname: `/environments/${stageId}/deployment-insights`,
          state: { redirectedAfterQuickDeploy: true }
        });
      }
    }
    catch (error) {
      setErrorStageDetails(error.response.data.message);
    }
    hideUndeployConfirmationModal();
    cleanUp();
  }

  const [showConfigModal, hideConfigModal] = useModal(() => (
    <ResourceConfigurationModal
      onSuccess={handleAddToStage}
      onClose={handleOnClose}
      stageId={stageId}
      componentId={selectedComponentId}
      resource={selectedResource}
    />
  ), [selectedComponentId]);

  const cleanUp = () => {
    setSelectedResource(undefined);
    setSelectedComponentId("");
  }

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    isStatusReady(status) ?
      <ModalDanger
        title={`Are you sure you want to delete this environment?`}
        description="Do you really want to delete the environment. This action cannot be undone."
        action="Delete"
        onCancel={hideDeleteModal}
        onConfirm={handleDeleteStageConfirmation}
      /> :
      <ModalDanger
        title={`Are you sure you want to undeploy this environment?`}
        description="This action will delete all the resources from the Cloud within this environment. This action will not delete the environment itself and resources created in Microtica."
        action="Undeploy"
        onCancel={hideDeleteModal}
        onConfirm={handleUndeployStageConfirmation}
      />
  ), [status]);

  const [showWarningModal, hideWarningModal] = useModal(() => (
    <ModalDanger
      title={`Stack cannot be deployed`}
      description={`Stack is in ${status.split("_").join(" ").toLocaleLowerCase()} status, you must undeploy it first`}
      action="Undeploy"
      onCancel={hideWarningModal}
      onConfirm={handleUndeployStageConfirmation}
    />
  ), [status]);


  function handleStageDelete() {
    showDeleteModal();
  }

  function handleStageReplication() {
    trackEnvReplicateInit(stageId, stageName);
    props.history.push(`/environments/${stageId}/replicate`);
  }

  function handleStageEdit() {
    props.history.push(`/environments/${stageId}/edit`);
  }

  function handleStageHistory() {
    props.history.push(`/environments/${stageId}/deployment-insights`);
  }

  function handleStageUndeploy() {
    trackUndeployInit(stageId);
    showDeleteModal();
  }

  function handleDeleteStageConfirmation() {
    dispatch(actions.deleteStage(currentProjectId, stageId, stageName));
    hideDeleteModal();
    hideWarningModal();
    props.history.push(`/environments`);
  }

  async function handleUndeployStageConfirmation() {
    dispatch(actions.undeployStage(currentProjectId, stageId));
    hideDeleteModal();
    hideWarningModal();

    const disabledInsights = await checkIfNotProperPaymentPlan();
    if (disabledInsights) {
      props.history.push({
        pathname: `/environments/${stageId}/details`,
        state: { disabledInsights: true }
      });
    } else {
      props.history.push({
        pathname: `/environments/${stageId}/deployment-insights`,
        state: { redirectedAfterQuickDeploy: true }
      });
    }
  }

  function handleCreatePlan() {
    trackCreatePlanInit(stageId);
    if (cloudProvider === "aws" && !awsAccountId) {
      showAwsAccountModal();
    }
    else if (cloudProvider === "azurerm" && (!azureTenant || !azureSubscription)) {
      showAzureAccountModal();
    }
    else if (cloudProvider === "google" && (!gcpProjectId || !gcpRegion || !gcpZone)) {
      showGcpAccountModal();
    }
    else {
      props.history.push(`/environments/${stageId}/plan`);
    }
  }

  function canGetOutputs(): boolean {
    return (!!awsAccountId || !!gcpProjectId || !!azureSubscription) &&
      status !== "DELETE_COMPLETE" &&
      status !== "ROLLBACK_COMPLETE"
  }

  async function checkIfNotProperPaymentPlan() {
    try {
      await getElasticSearchAPI().getStageDeploymentHistory(currentProjectId, stageId);
      return false;
    } catch (error) {
      return error.response.data.code === 402;
    }
  }

  async function handleQuickDeploy() {
    if (cloudProvider === "aws" && !awsAccountId) {
      showAwsAccountModal();
    }
    else if (cloudProvider === "azurerm" && (!azureTenant || !azureSubscription)) {
      showAzureAccountModal();
    }
    else if (cloudProvider === "google" && (!gcpProjectId || !gcpRegion || !gcpZone)) {
      showGcpAccountModal();
    }
    else {
      try {
        if (status === "REVIEW_IN_PROGRESS" ||
          status === "ROLLBACK_COMPLETE") {
          showWarningModal()
        } else {
          setDeploying(true);
          await getEngineAPI().deployStage(stageId, currentProjectId);
          trackQuickDeploy(stageId);
          setDeploying(false);
          const disabledInsights = await checkIfNotProperPaymentPlan();
          if (disabledInsights) {
            props.history.push({
              pathname: `/environments/${stageId}/details`,
              state: { disabledInsights: true }
            });
          } else {
            props.history.push({
              pathname: `/environments/${stageId}/deployment-insights`,
              state: { redirectedAfterQuickDeploy: true }
            });
          }
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setDeploying(false);
      }
    }
  }

  const [startStopResourcesModal, hideStartStopResourceModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to ${resourcesStatus.toUpperCase()} all affected resources?`}
      description={`You can in any time ${resourcesStatus === "start" ? "stop" : "start"} affected resources again.`}
      action={`${resourcesStatus} All`}
      onCancel={hideStartStopResourceModal}
      onConfirm={() => handleResourcesStateChange(resourcesStatus)}
    />
  ), [savingSchedule, resourcesStatus]);

  async function handleResourcesStateChange(action: ChangeStateAllResourcesRequestActionEnum) {
    try {
      if (savingSchedule) {
        hideStartStopResourceModal();

        await getCloudCostOptimizerAPI().changeStateAllResources(
          currentProjectId,
          {
            scheduleId: savingSchedule.id,
            awsAccountId: savingSchedule.awsAccountId,
            region: savingSchedule.awsRegion,
            tagKey: savingSchedule.resourcesTagKey,
            tagValue: savingSchedule.resourcesTagValue!,
            action
          }
        );
        toast.success(`Successfully initiated ${action.toUpperCase()} action on affected resources with this schedule.`);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <main className="content d-flex flex-column justify-content-start">
      {deploying ? <BuildProcessWaiting text="Your deploy is being started. Please wait..." /> : null}
      <PageHeader
        title={description}
      />
      <div className="content__body pb--0">
        <Card class="card dark card--replicate-main">
          <StageHeader
            name={description}
            status={status}
            cloudProvider={cloudProvider!}
            infrastructureAsCodeTool={cloudTool!}
            lastDeployed={lastDeployed}
            stageId={stageId}
            disableDeploy={!stageResources.length}
            cancelDeploy={() => { showCancelModal(); trackCancelDeployment(stageId) }}
            createPlan={handleCreatePlan}
            quickDeploy={handleQuickDeploy}
            isLoaded={isLoaded}
            handleStageEdit={handleStageEdit}
            handleDeploymentInsights={handleStageHistory}
            handleStageReplication={handleStageReplication}
            handleStageUndeploy={handleStageUndeploy}
            handleStageDelete={handleStageDelete}
          />
          <div className="d-flex justify-content-between align-items-start">
            <div className="width--full">
              {isLoaded && savingSchedule &&
                <div className="pull--right mr--15 mt--5">
                  <label
                    className="txt--highlight pointer"
                    onClick={() => { setResourcesStatus(ChangeStateAllResourcesRequestActionEnum.Start); startStopResourcesModal() }}
                  >
                    Start All Resources
                  </label>
                  <b className="txt--grey"> / </b>
                  <label
                    className="txt--highlight pointer"
                    onClick={() => { setResourcesStatus(ChangeStateAllResourcesRequestActionEnum.Stop); startStopResourcesModal() }}
                  >
                    Stop All Resources
                  </label>
                </div>
              }
              <StageResourcesList
                stageId={stageId}
                resources={stageResources.map(r => ({ ...r, isDeployed: isResourceDeployed(r.status) }))}
                advisor={advisor}
                awsAccount={{ id: awsAccountId, region: awsRegion }}
                canGetOutputs={canGetOutputs()}
                deploymentHistory={deploymentHistory}
                projectId={currentProjectId}
                status={status}
                onConfigure={handleResourceConfigure}
                onDelete={handleResourceDelete}
                onUndeploy={handleResourceUndeploy}
                isLoaded={isLoaded}
              />
            </div>
            <div className="deployment-menu">
              <TabMenuContainer
                class="tab-menu--deployment"
                tabs={componentCategories}
                onClick={(tab) => setPublicOnly(tab.id === "2")}
              />
              <SearchBar
                placeholder="Search components"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />

              <CustomScrollbars maxHeight={"calc(100vh - 370px)"} customClass="deployment-menu" resetClass="reset--top">
                <div className="list__container">
                  <ListView
                    listType="stage"
                    isLoaded={isFetchingComponents}
                    items={componentList}
                    // onClick={handleAddToStage}
                    onClick={handleSelectComponent}
                  />
                </div>
              </CustomScrollbars>

            </div>
          </div>
        </Card>
      </div>
    </main>
  );
};

export default StageDetails;