import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import {
  GetIdleLoadBalancerStatsResponseClassicELBStats,
  GetIdleLoadBalancerStatsResponseNonClassicELBStats,
  GetLowUtilizationEC2StatsResponse,
  GetLowUtilizationEC2StatsResponseStatistics,
  GetIdleRelationalDBStatsResponse,
} from "@microtica/ms-cloud-cost-optimizer-sdk";

import AdvisorStatistics from "../../components/AdvisorStatistics/AdvisorStatistics";
import AwsAccount, {
  AwsAccountChangeEvent,
} from "../../components/AwsAccount/AwsAccount";
import Button from "../../components/Button/Button";
import PageHeader from "../../components/PageHeader/PageHeader";

import { ec2, elb, rds } from "./static-data";

import { getCloudCostOptimizerAPI } from "../../api";
import { GlobalState } from "../../reducers";
import AdvisorScreen from "../../static/waste-advisor.png";
import useAwsAccountList from "../../hooks/AwsAccount";
import CloudAccountModal, { CloudAccountType } from "../../components/CloudAccount/CloudAccountModal";

const Statistics = (props: RouteComponentProps<{},
  StaticContext,
  {
    awsAccount?: AwsAccountChangeEvent,
    tagKey?: string,
    tagValue?: string
  }>) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [disabledAdvisor, setDisabledAdvisor] = useState(false);
  const [selectedAwsAccount, setSelectedAwsAccount] = useState<
    AwsAccountChangeEvent
  >({ awsAccountId: undefined, awsRegion: undefined });

  const { awsAccounts, awsRegions, isFinished } = useAwsAccountList();

  const [showAwsModal, hideAwsModal] = useModal(() => (
    <CloudAccountModal
      cfnLink="https://console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/quickcreate?stackName=MicroticaCrossAccountAccessCostOptimization&templateURL=https://microtica.s3.eu-central-1.amazonaws.com/assets/aws/cloudformation/MicroticaCrossAccountAccessCostOptimization.json"
      onClose={hideAwsModal}
      cloudAccountType={CloudAccountType.AWS}
    />
  ));

  const currentProject = useSelector(
    (state: GlobalState) => state.project.currentProject
  );
  const [ec2RowsMap, setEc2RowsMap] = useState([[] as string[]]);
  const [ec2Stats, setEc2Stats] = useState(
    {} as Omit<GetLowUtilizationEC2StatsResponse, "statistics">
  );
  const [elbClassicRowsMap, setElbClassicRowsMap] = useState([[] as string[]]);
  const [elbClassicStats, setElbClassicStats] = useState(
    {} as Omit<GetIdleLoadBalancerStatsResponseClassicELBStats, "statistics">
  );
  const [elbNonClassicRowsMap, setElbNonClassicRowsMap] = useState([
    [] as string[],
  ]);
  const [elbNonClassicStats, setElbNonClassicStats] = useState(
    {} as Omit<GetIdleLoadBalancerStatsResponseNonClassicELBStats, "statistics">
  );
  const [rdsRowsMap, setRdsRowsMap] = useState([[] as string[]]);
  const [rdsStats, setRdsStats] = useState(
    {} as Omit<GetIdleRelationalDBStatsResponse, "statistics">
  );


  useEffect(() => {

    validateIfProperPaymentPlan()

    if (props.location.state && props.location.state.awsAccount) {
      const region = awsRegions.find(
        (r) => r.value === props.location.state.awsAccount!.awsRegion
      );

      setSelectedAwsAccount(
        {
          awsAccountId: props.location.state.awsAccount.awsAccountId,
          awsRegion: region && region.id
        });
    }

    handleSubmit();
  }, [])

  async function validateIfProperPaymentPlan() {
    try {
      await getCloudCostOptimizerAPI().listSchedules(currentProject.id)
    } catch (error) {
      setDisabledAdvisor(error.response.data.code === 402)
    }
  }

  const fetch = async () => {
    try {
      const { awsAccountId, awsRegion } = selectedAwsAccount;
      const projectId = currentProject.id;

      const { data: { ec2, elb, rds } } =
        props.location.state && props.location.state.awsAccount! ?
          await getCloudCostOptimizerAPI().getAllStats(
            awsAccountId ? awsAccountId : props.location.state.awsAccount!.awsAccountId!,
            projectId,
            awsRegion ? awsRegions.find((r) => r.id === awsRegion)!.value : props.location.state.awsAccount!.awsRegion!,
            props.location.state.tagKey,
            props.location.state.tagValue,
          )
          :
          await getCloudCostOptimizerAPI().getAllStats(
            awsAccountId!,
            projectId,
            awsRegions.find((r) => r.id === awsRegion)!.value
          )

      const { statistics: ec2Statistics, ...ec2Details } = ec2;
      const {
        statistics: elbClassicStatistics,
        ...elbClassicDetails
      } = elb.classicELBStats;
      const {
        statistics: elbNonClassicStatistics,
        ...elbNonClassicDetails
      } = elb.nonClassicELBStats;

      const { statistics: rdsStatistics, ...rdsDetails } = rds;

      setEc2RowsMap(
        (ec2Statistics as GetLowUtilizationEC2StatsResponseStatistics[]).map(
          (instance) => [
            instance.instanceName!,
            instance.instanceId,
            instance.instanceType,
            instance.cpuUtilizationAverage,
            instance.networkIOAverage,
            instance.totalLowUtilizationDays.toString(),
          ]
        )
      );
      setEc2Stats(ec2Details);

      setElbClassicRowsMap(
        elbClassicStatistics.map(({ name, reason }) => [
          name,
          "classic",
          reason,
        ])
      );
      setElbClassicStats(elbClassicDetails);

      setElbNonClassicRowsMap(
        elbNonClassicStatistics.map(({ name, reason, type }) => [
          name,
          type,
          reason,
        ])
      );
      setElbNonClassicStats(elbNonClassicDetails);

      setRdsRowsMap(
        rdsStatistics.map((rds) => [
          rds.instanceIdentifier! || rds.instanceClusterIdentifier!,
          rds.engine,
          rds.status,
          rds.idleReason,
        ])
      );
      setRdsStats(rdsDetails);

      setIsLoaded(true);
    } catch (e) {
      toast.error("Something went wrong!")
    }
  };

  async function handleSubmit() {
    setIsBusy(true);
    if (
      selectedAwsAccount.awsAccountId &&
      selectedAwsAccount.awsRegion
      ||
      props.location.state &&
      props.location.state.awsAccount
    ) {
      await fetch();
    }
    setIsBusy(false);
  }

  const EmptyBox = () => (
    <div
      className="page-centered page-centered--project"
      style={{ paddingTop: "10px", paddingBottom: "10px" }}
    >
      <img src={AdvisorScreen} alt="advisor" />
      <div className="page-centered__title">
        Receive statistics for your EC2, Load Balancers and RDS instances
      </div>
      <p style={{ maxWidth: "57%" }}>
        Get an overview of your low utilized EC2 instances, idle classic,
        application, gateway and network load balancers, as well as RDS idle DB
        instances. Take a look at the statistics and take the necessary actions.
      </p>
      {disabledAdvisor ? (
        <Link className="btn btn--xl" to="/settings?tab=billing">
          Upgrade Plan
        </Link>
      ) : null}
    </div>
  );

  const AwsAccountPlaceholder = () => (
    <div
      className="page-centered page-centered--project"
      style={{ paddingTop: "10px", paddingBottom: "10px" }}
    >
      <img src={AdvisorScreen} alt="advisor" />
      <h3>
        <strong>No AWS accounts connected yet</strong>
      </h3>
      <p className="txt--sm" style={{ maxWidth: "57%", marginBottom: "10px" }}>
        Get an overview of your low utilized EC2 instances, idle classic,
        application, gateway and network load balancers, as well as RDS idle DB
        instances. Take a look at the statistics and take the necessary actions.
        <br />
        <br />
        To enable this feature you would need to connect at least one AWS
        account.
      </p>
      <Button className="btn btn--xl btn--green" onClick={showAwsModal}>
        Connect AWS Account
      </Button>
    </div>
  );

  const statistics = (
    <>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            awsAccount: selectedAwsAccount.awsAccountId,
            awsRegion: selectedAwsAccount.awsRegion,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form className="statistics-form">
              <div className="aws-dropdown-fields">
                <AwsAccount
                  value={selectedAwsAccount}
                  onChange={(event) => {
                    !(!event.awsAccountId && !event.awsRegion && props.location.state && props.location.state.awsAccount) &&
                      setSelectedAwsAccount({
                        awsAccountId: event.awsAccountId,
                        awsRegion: event.awsRegion
                      });
                  }}
                  showCardBox={false}
                  showEcrDropdown={false}
                  setFieldValue={setFieldValue}
                />
              </div>
              <Button
                type="submit"
                className="btn btn--lg btn--green"
                isBusy={isBusy}
                disabled={
                  !selectedAwsAccount!.awsRegion ||
                  !selectedAwsAccount!.awsAccountId
                }
                busyText="Fetching data..."
              >
                Get statistics
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="card card--stages dark">
        <AdvisorStatistics
          isLoaded={isLoaded}
          isBusy={isBusy}
          headers={ec2.headers}
          rowsMap={ec2RowsMap}
          title={ec2.title}
          status={ec2Stats.status ? [ec2Stats.status] : []}
          description={ec2.description}
          summary={[
            `${ec2Stats.lowUtilizationInstances} of ${ec2Stats.totalInstances} EC2 instances have low average daily utilization`,
          ]}
        />
        <AdvisorStatistics
          isLoaded={isLoaded}
          isBusy={isBusy}
          headers={elb.headers}
          rowsMap={elbClassicRowsMap.concat(elbNonClassicRowsMap)}
          title={elb.title}
          status={
            elbClassicStats.status && elbNonClassicStats.status
              ? [elbClassicStats.status, elbNonClassicStats.status]
              : []
          }
          description={elb.description}
          summary={[
            `${elbClassicStats.idleLoadBalancers} of ${elbClassicStats.idleLoadBalancers} classic load balancers appear to be idle.\n`,
            `${elbNonClassicStats.idleLoadBalancers} of ${elbNonClassicStats.idleLoadBalancers} application, network and gateway load balancers appear to be idle.`,
          ]}
        />
        <AdvisorStatistics
          isLoaded={isLoaded}
          isBusy={isBusy}
          headers={rds.headers}
          rowsMap={rdsRowsMap}
          title={rds.title}
          status={rdsStats.status ? [rdsStats.status] : []}
          description={rds.description}
          summary={[
            `${rdsStats.idleRelationalDBResources} of ${rdsStats.totalRelationalDBResources} DB instances appear to be idle.`,
          ]}
        />
      </div>
    </>
  );

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader title="Waste Advisor" />
      {!isFinished ? null : awsAccounts.length ? (
        disabledAdvisor ? (
          <EmptyBox />
        ) : (
          statistics
        )
      ) : (
        <AwsAccountPlaceholder />
      )}
    </main>
  );
};

export default Statistics;
