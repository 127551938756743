import React, { useState, useEffect } from "react";
import { TableProps } from "../Table/Table";
import { DropdownMenuItemProps } from "../DropdownMenuContainer/DropdownMenuContainer";

//Components
import Card from "../Card/Card";
import DropdownMenuContainer from "../DropdownMenuContainer/DropdownMenuContainer";
import Table from "../Table/Table";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

export interface Props extends React.DOMAttributes<HTMLDivElement> {
  header: any[];
  opened: boolean;
  body?: any;
  headerDropdownActions?: DropdownMenuItemProps[];
  tableProps?: TableProps;
  status?: string;
  error?: boolean;
  errorMessage?: string;
  modifier?: string;
  getOutputs?: Function;
}

function CollapsibleCard(props: Props) {
  const [active, setActive] = useState(false);

  const clickHandler = () => {
    if (props.modifier && props.modifier.includes("stage-details") && !active) {
      props.getOutputs!();
    }
    setActive(!active);
  };

  useEffect(() => {
    setActive(props.opened);
  }, [props.opened]);

  let classes = "menu__item d-flex justify-content-between align-items-center";
  let modifier = props.modifier || "";
  let btnModifier = modifier.replace(" kubernetes", "");

  if (active) {
    classes = classes + " active";
  }

  return (
    <Card class={`card light collapse card-shadow ${modifier}`}>
      <div className={`card__header-wrapper mb--0 txt--white ${modifier.includes("stage-details") ? "ml--15 mr--15" : ""}`}>
        <div className={`collapsible-header ${modifier.includes("stage-details") ? "mt--10 mb--10" : ""}`}>
          <div
            className={`${classes} collapsible-button ${props.status!}`}
            onClick={() => clickHandler()}
          >
            <span
              className={`menu__link menu__arrow`}
            />
            {props.header[0]}
          </div>
          <div
            className="elements"
          >
            {props.header.map((element, index) =>
              index !== 0 ? (
                <React.Fragment key={index}>
                  <div
                    className={
                      (index !== 1 && index !== 3) || (!props.status && index !== 3)
                        ? "vertical"
                        : "vertical disable"
                    }
                  ></div>
                  <span className={`${index === 3 ? 'logs-btn' : `field-${index}`} ${btnModifier}`}>
                    {element}
                  </span>
                </React.Fragment>
              ) : (
                ""
              )
            )}
          </div>
        </div>
        {props.headerDropdownActions && (
          <div className="d-flex align-items-center">
            <DropdownMenuContainer actions={props.headerDropdownActions} />
          </div>
        )}
      </div>
      <div className={`collapsible-block ${active ? "active" : ""} ${modifier.includes("stage-details") ? "pl--15" : ""}`}>
        {props.error && props.errorMessage && (
          <div className="mt--15">
            <ErrorMessage message={props.errorMessage} />
          </div>
        )}
        {props.body && <div className={`mt--10 ${modifier.includes("stage-details") ? "p--15 pb--10" : ""}`}>{props.body}</div>}
        {props.tableProps && (
          <div className="table-wrapper-sticky table-wrapper-sticky_dark collapsible-table">
            <Table
              isLoaded={props.tableProps.isLoaded}
              headers={props.tableProps.headers}
              placeholderText={props.tableProps.placeholderText}
              onClick={props.tableProps.onClick}
              tableModifier={props.tableProps.tableModifier}
              rows={props.tableProps.rows}
            />
          </div>
        )}
      </div>
    </Card>
  );
}

export default CollapsibleCard;