
import {
    GetStagesItem,
    Component,
    GetStageDetailsResponse,
    GetComponentBuildsResponse,
    GetComponentResponse,
} from "@microtica/ms-engine-sdk";
import { GetMicroserviceResponse, GetMicroservicesResponseMicroservices } from "@microtica/ms-kube-sdk";
import { Pipeline, GitAccount, RepositoriesResponseRepositories, PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import { Project } from "@microtica/ms-project-sdk";
import { TimelineEvent, UserNotificationsNotifications } from "@microtica/ms-notification-sdk";
import { ListKubernetesWithDetailsResponseKuberneteses } from "@microtica/ms-kube-sdk";
import { GetAwsAccountResponse, GetAzureAccountResponse, GetGcpAccountResponse } from "@microtica/ms-aws-sdk";
import { DockerRegistry } from "@microtica/ms-ap-sdk";
import { ReactElement } from "react";

export interface MicroserviceState {
    microservices: GetMicroservicesResponseMicroservices[];
    isProcessing: boolean;
    error: ErrorInterface;
    microserviceCreated?: string;
    microservice?: GetMicroserviceResponse;
    pipeline?: Pipeline;
}
export interface ComponentState {
    components: Component[];
    componentBuilds: GetComponentBuildsResponse;
    isProcessing: boolean;
    componentCreated?: string;
    componentUpdated?: string;
    component?: GetComponentResponse;
    pipeline?: Pipeline;
    error: ErrorInterface;
}
export interface StageState {
    stages: GetStagesItem[];
    stageDetails: GetStageDetailsResponse;
    isFetching: boolean;
    createdStage: string;
    error: ErrorInterface;
}
export interface PipelineState {
    pipelines: Pipeline[];
    isFetching: boolean;
    error: ErrorInterface
}

export interface KubernetesState {
    kuberneteses: ListKubernetesWithDetailsResponseKuberneteses[];
    isProcessing: boolean;
    error: ErrorInterface
}
export interface DockerRegistryState {
    dockerRegistries: DockerRegistry[];
    isProcessing: boolean;
    error: ErrorInterface
}
export interface BuildState {
    builds: PipelineBuildDetails[];
    isFetching: boolean;
    offset?: number;
    limit: number;
    error: ErrorInterface
}
export interface UserState {
    profile: {
        id: string;
        email: string;
        firstName?: string;
        lastName?: string;
    };
    isLoggedIn: boolean;
    isLoading: boolean;
    currentPage: string;
    idToken: string;
    error: ErrorInterface;
}

export interface ProjectState {
    projects: Project[];
    events: TimelineEvent[];
    isProcessing: boolean;
    currentProject: Project;
    projectUsers: User[];
    error: ErrorInterface;
    projectCreated?: boolean;
}

export interface AWSAccountState {
    awsAccounts: GetAwsAccountResponse[];
    ecrRepositories: any[];
    isFetching: boolean;
    isFinished: boolean;
    error: ErrorInterface;
    awsAccountConnected?: boolean;
}

export interface GcpAccount {
    gcpProjectId?: string;
    microticaServiceAccount: string;
    targetServiceAccount: string;
    projectName: string;
}

export interface GCPAccountState {
    gcpAccounts: GcpAccount[];
    isFetching: boolean;
    isFinished: boolean;
    error: ErrorInterface;
    gcpAccountConnected?: boolean;
}
export interface AzureAccountState {
    azureAccounts: GetAzureAccountResponse[];
    isFetching: boolean;
    isFinished: boolean;
    error: ErrorInterface;
    azureAccountConnected?: boolean;
}

export interface GitAccountState {
    gitAccounts: GitAccount[];
    gitRepositories: RepositoriesResponseRepositories[];
    isFetching: boolean;
    gitAccountConnected?: boolean;
    error: ErrorInterface;
}

export interface NotificationState {
    notifications: UserNotificationsNotifications[];
    isProcessing: boolean;
    error: ErrorInterface;
}

export interface ErrorInterface {
    code?: number;
    message?: string | ReactElement;
}

export interface User {
    userId: string;
    firstName: string;
    lastName: string;
    email?: string;
    profileImage: string;
    permission: string;
    status: "UNCONFIRMED" | "CONFIRMED" | "ARCHIVED" | "COMPROMISED" | "UNKNOWN" | "RESET_REQUIRED" | "FORCE_CHANGE_PASSWORD"
}
export type InfrastructureAsCodeTool = "Cloudformation" | "Terraform"
export type CloudProvider = "Aws" | "Azurerm" | "Google"
export type ComponentType = "Kubernetes" | "Database" | "Storage" | "VirtualMachine" | "Lambda" | "Vpc" | "Other";
export type AwsRegionType = "UsEast2" | "UsEast1" | "UsWest1" | "UsWest2" | "ApEast1" |
    "ApSouth1" | "ApNortheast3" | "ApNortheast2" | "ApSoutheast1" |
    "ApSoutheast2" | "ApNortheast1" | "CaCentral1" | "CnNorth1" |
    "CnNorthwest1" | "EuCentral1" | "EuWest1" | "EuWest2" |
    "EuWest3" | "EuNorth1" | "MeSouth1" | "SaEast1" | "UsGovEast1" | "UsGovWest1";
export type AzureRegionType = "Centralus" | "Eastasia" | "Southeastasia" | "Eastus" | "Eastus2" | "Westus" | "Westus2" |
    "Northcentralus" | "Southcentralus" | "Westcentralus" | "Northeurope" | "Westeurope" | "Japaneast" | "Japanwest" | "Brazilsouth" | "Australiasoutheast" | "Australiaeast" | "Westindia" | "Southindia" | "Centralindia" | "Canadacentral" |
    "Canadaeast" | "Uksouth" | "Ukwest" | "Koreacentral" | "Koreasouth" | "Francecentral" | "Southafricanorth" | "Uaenorth" |
    "Australiacentral" | "Switzerlandnorth" | "Germanywestcentral" | "Norwayeast" | "Jioindiawest" | "Westus3" | "Australiacentral2";
export type GcpRegionType = "AsiaEast1" | "AsiaEast2" | "AsiaNortheast1" | "AsiaNortheast2" | "AsiaNortheast3" | "AsiaSouth1" | "AsiaSoutheast1" | "AsiaSoutheast2" | "AustraliaSoutheast1" | "EuropeCentral2" | "EuropeNorth1" | "EuropeWest1" | "EuropeWest2" | "EuropeWest3" | "EuropeWest4" | "EuropeWest6" | "NorthamericaNortheast1" | "SouthamericaEast1" | "UsCentral1" | "UsEast1" | "UsEast4" | "UsWest1" | "UsWest2" | "UsWest3" | "UsWest4"
export type GcpZoneType = "AsiaEast1A" | "AsiaEast1B" | "AsiaEast1C" | "AsiaEast2A" | "AsiaEast2B" | "AsiaEast2C" | "AsiaNortheast1A" | "AsiaNortheast1B" | "AsiaNortheast1C" | "AsiaNortheast2A" | "AsiaNortheast2B" | "AsiaNortheast2C" | "AsiaNortheast3A" | "AsiaNortheast3B" | "AsiaNortheast3C" | "AsiaSouth1A" | "AsiaSouth1B" | "AsiaSouth1C" | "AsiaSoutheast1A" | "AsiaSoutheast1B" | "AsiaSoutheast1C" | "AsiaSoutheast2A" | "AsiaSoutheast2B" | "AsiaSoutheast2C" | "AustraliaSoutheast1A" | "AustraliaSoutheast1B" | "AustraliaSoutheast1C" | "EuropeCentral2A" | "EuropeCentral2B" | "EuropeCentral2C" | "EuropeNorth1A" | "EuropeNorth1B" | "EuropeNorth1C" | "EuropeWest1B" | "EuropeWest1C" | "EuropeWest1D" | "EuropeWest2A" | "EuropeWest2B" | "EuropeWest2C" | "EuropeWest3A" | "EuropeWest3B" | "EuropeWest3C" | "EuropeWest4A" | "EuropeWest4B" | "EuropeWest4C" | "EuropeWest6A" | "EuropeWest6B" | "EuropeWest6C" | "NorthamericaNortheast1A" | "NorthamericaNortheast1B" | "NorthamericaNortheast1C" | "SouthamericaEast1A" | "SouthamericaEast1B" | "SouthamericaEast1C" | "UsCentral1A" | "UsCentral1B" | "UsCentral1C" | "UsCentral1F" | "UsEast1B" | "UsEast1C" | "UsEast1D" | "UsEast4A" | "UsEast4B" | "UsEast4C" | "UsWest1A" | "UsWest1B" | "UsWest1C" | "UsWest2A" | "UsWest2B" | "UsWest2C" | "UsWest3A" | "UsWest3B" | "UsWest3C" | "UsWest4A" | "UsWest4B" | "UsWest4C"

export const errorEmptyObject = {}

export interface Dictionary<T> {
    [key: string]: T;
}
export interface PartialMicroticaYaml {
    steps: Dictionary<Partial<{ stage?: string }>>;
    stages?: string[];
}

export interface Runtime {
    computeType: RuntimeComputeType;
    packages?: {
        [key: string]: string;
    };
}
export type RuntimeComputeType = "SMALL" | "MEDIUM" | "LARGE" | "2XLARGE";