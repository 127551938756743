import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StageDeploymentResponseResources, StageDeploymentResponseResponse } from "@microtica/ms-elasticsearch-sdk";
import { GetStageResourcesResponseResources, GetOutputsForResourceResponseOutputs } from "@microtica/ms-engine-sdk"

import { StageResourceBody } from "./StageResourceBody";
import { advisorResources } from "../StagesList/StagesList";

import { getEngineAPI } from "../../api";

import Animation from "../../components/Animations/Animations";
import Button from "../../components/Button/Button";
import CollapsibleCard from "../../components/CollapsibleCard/CollapsibleCard";
import Commit from "../../components/Commit/Commit";
import ConditionalWrapper from "../../components/ConditionalWrapper/ConditionalWrapper";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";

import { ReactComponent as RemoveIcon } from "../../static/delete-icon.svg";
import { ReactComponent as KubernetesLogo } from "../../static/kubernetesIcon.svg"
import { ReactComponent as ReviewImage } from "../../static/review-image.svg";
import { ReactComponent as SwitchIcon } from "../../static/switch-at-center.svg";
import { ReactComponent as UndeployIcon } from "../../static/undeploy-icon.svg";

import { status as mapStatus } from "../../utils/deployment-status";
import { statusColor } from "../../utils/status-color";

interface ResourceProps {
  stageId: string;
  projectId: string;
  awsAccount: { id: string, region: string };
  status: string;
  canGetOutputs: boolean;
  advisor?: advisorResources;
  onConfigure: (resourceName: string) => void;
  onDelete: (resourceName: string) => void;
  onUndeploy: (resourceName: string) => void;
}

type StageResource = GetStageResourcesResponseResources & {
  isDeployed: boolean;
  componentVersion?: string;
}
interface StageResourcesListProps extends ResourceProps {
  resources: StageResource[];
  isLoaded: boolean;
  deploymentHistory?: StageDeploymentResponseResponse;
}
interface ResourceCardProps extends ResourceProps {
  resource: StageResource;
  index: number;
  deploymentHistory?: StageDeploymentResponseResources;
}

const StageResourcesList = (props: StageResourcesListProps) => {
  return (
    <CustomScrollbars maxHeight={"100%"} customClass="review">
      <div className="review">
        {!props.resources.length ?
          <div className="d-flex flex-column justify-content-start align-items-center pt--50">
            <ReviewImage />

            <p className="txt--white txt--md txt--center m--10 pl--10 pr--10">
              <strong>Review the components on the right and choose which ones you’d like to add in the environment!</strong>
            </p>
          </div>
          : <div className="mt--10 mb--10 mr--15 ml--0 pb--10">
            <Animation type="fade" show={props.isLoaded} itemIndex={1}>
              {props.resources.map((resource, index) => (
                <ResourceCard
                  key={index}
                  resource={resource}
                  index={index}
                  deploymentHistory={props.deploymentHistory && props.deploymentHistory.resources && props.deploymentHistory.resources.find(r => r.name === resource.name)}
                  stageId={props.stageId}
                  canGetOutputs={props.canGetOutputs}
                  projectId={props.projectId}
                  advisor={props.advisor}
                  awsAccount={props.awsAccount}
                  status={props.status}
                  onConfigure={props.onConfigure}
                  onDelete={props.onDelete}
                  onUndeploy={props.onUndeploy}
                />
              ))}
            </Animation>
          </div>}
      </div>
    </CustomScrollbars>
  )
}

export default StageResourcesList;

export const ResourceCard = (props: ResourceCardProps) => {
  const [outputs, setOutputs] = useState<GetOutputsForResourceResponseOutputs[] | undefined>();

  function getCardHeader(resourceName: string, componentName: string, type: string, status: string, tool: string, resource?: StageDeploymentResponseResources) {

    const link = resource && resource.componentVersion && resource.componentVersion !== "n/a" ?
      resource.componentVersion.includes("-") ?
        `/pipelines/${resource.component.pipelineId}/builds/${resource.componentVersion}`
        : `${resource.component.metadata.repository}/${resource.component.metadata.repository.startsWith("https://github.com") ? "commit" : "commits"}/${resource.component.metadata.commit.version}`
      : "";

    return [
      <span className="first-item">
        <ConditionalWrapper
          condition={type === "kubernetes"}
          wrapper={(children: React.ReactChild[]) => <Link key={resourceName} to={`/kubernetes/clusters/${props.stageId}-${resourceName}`} className="kubernetes-link">{children}</Link>}
        >
          <div className="txt--left d-flex">
            {resourceName}
            {type === "kubernetes" ? <KubernetesLogo className="ml--10" width="20px" height="20px" /> : null}
          </div>
          <div className={`txt--left ${getStatusProp(status) === "" ? "txt--grey" : "txt--white"}`}>{componentName}</div>
        </ConditionalWrapper>
      </span>,
      <span>
        <div className="txt--grey">{mapStatus(status)}</div>
        {resource && props.resource.isDeployed  &&
          <span className="stage-details-header">
            <div className="vertical" />
            <div>
              <SwitchIcon className="mr--15 d-block" />
            </div>
            <Commit
              name={resource.component.metadata.commit.name}
              message={resource.component.metadata.commit.message}
              version={resource.component.metadata.commit.version.substring(0, 7)}
              href={link}
              target={resource.component.version.includes("-") ? "" : "_blank"}
              userName={resource.component.metadata.commit.user.name}
              userAvatar={resource.component.metadata.commit.user.avatar}
              modifier="stage-details-header"
            />
          </span>
        }
      </span>,
      <>
        <Button
          className="btn btn--sm btn--link btn--darker m--0"
          onClick={() => props.onConfigure(resourceName)}
        >
          Configure
        </Button>
        <DropdownMenuContainer actions={
          tool === "terraform" ? [
            {
              itemId: "1",
              title: "Undeploy",
              type: "separator",
              icon: <UndeployIcon />,
              disabled: !props.resource.isDeployed || status.endsWith("_IN_PROGRESS"),
              onClick: () => props.onUndeploy(resourceName),
            },
            {
              itemId: "2",
              title: "Delete",
              type: "separator",
              icon: <RemoveIcon />,
              disabled: props.resource.isDeployed || status.endsWith("_IN_PROGRESS"),
              onClick: () => props.onDelete(resourceName),
            }
          ] : [
            {
              itemId: "1",
              title: "Delete",
              type: "separator",
              icon: <RemoveIcon />,
              onClick: () => props.onDelete(resourceName),
            }
          ]} />
      </>
    ]
  }

  function isLink(testString: string) {
    return new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/).test(testString);
  }


  function getCardTableProps(outputs: GetOutputsForResourceResponseOutputs[] | undefined) {
    return {
      tableModifier: "stage-details",
      placeholderText: props.canGetOutputs ? "No outputs parameters found" : "Outputs are not available",
      headers: ["Parameter:left", "Value:left"],
      isLoaded: !!outputs,
      rows: renderOutputs()
    }
  }

  const renderOutputs = () => {
    return outputs ?
      outputs.map(({ key, value }) => {
        let displayValue;
        if (typeof value === "object") {
          displayValue = [];
          for (const objectKey of Object.keys(value)) {
            displayValue.push(
              <>
                <div>
                  {`${objectKey}: ${value[objectKey]}`}
                </div>
                <br />
              </>
            )
          }
        } else {
          if (isLink(value)) {
            displayValue = <a
              className={"txt--highlight"}
              target={"_blank"}
              href={RegExp(/^http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\//).test(value) ?
                value : `//${value}`}
              onClick={e => e.stopPropagation()}
            >
              {value}
            </a>
          } else {
            displayValue = value;
          }
        }
        return ([
          <div>{key}</div>,
          <div className="txt--left">
            {displayValue}
          </div>
        ])
      }) : [];
  }

  async function getOutputs(resourceName: string) {
    if (!outputs) {
      if (props.canGetOutputs) {
        const { data } = await getEngineAPI().getOutputsForResource(props.stageId, resourceName, props.projectId);
        setOutputs(data.outputs);
      }
      else {
        setOutputs([]);
      }
    }
  }

  function getStatusProp(resourceStatus: string) {
    const status = statusColor(resourceStatus);
    return status === "warning" ? "warning" : status === "fail" ? "fail" : ""
  }

  return (
    <CollapsibleCard
      key={props.resource.name}
      header={getCardHeader(props.resource.name, props.resource.component.name, props.resource.component.type, props.resource.status, props.resource.component.infrastructureAsCodeTool, props.deploymentHistory)}
      body={<StageResourceBody
        component={props.deploymentHistory && props.deploymentHistory.component}
        version={(props.resource as any).componentVersion}
        isLatestVersion={props.resource.component.isLatestVersion}
        advisor={props.advisor}
        name={props.resource.name}
        awsAccount={props.awsAccount}
        stageId={props.stageId}
      />}
      tableProps={getCardTableProps(outputs)}
      status={getStatusProp(props.resource.status)}
      opened={false}
      modifier={`stage-details ${props.resource.component.type === "kubernetes" ? "kubernetes" : ""}`}
      getOutputs={() => getOutputs(props.resource.name)}
    />
  )
}