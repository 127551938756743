import * as React from 'react';
import ReactAvatar, { ConfigProvider, ReactAvatarProps } from "react-avatar";

// Predefined avatar colors
const colors = [
  "#46678a",
  "#835442",
  "#5f7c4c",
  "#236E95",
  "#15B2D1"
];

const Avatar: React.StatelessComponent<ReactAvatarProps> = props => {

  return (
    // <div className={`avatar notification--show`}>
    <div className={`avatar ${props.size === "90" ? "avatar__md" : ""}`}>
      <ConfigProvider colors={colors}>
        <ReactAvatar
          round={true}
          maxInitials={2}
          fgColor="#0c0c0c"
          {...props}
        >
        </ReactAvatar>
      </ConfigProvider>
    </div >
  );
};

export default Avatar;
