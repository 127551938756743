import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useModal } from 'react-modal-hook';
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { CreateSlackIntegrationRequestTypeEnum, IntegrationType } from "@microtica/ms-notification-sdk";

// Import components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import { ReactComponent as SlackLogo } from "../../static/slack-logo.svg";
import Button from "../../components/Button/Button";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { InputField } from "../../components/InputField/InputField";

import { getNotificationAPI } from "../../api";
import { GlobalState } from '../../reducers';
import { slackSchema } from "../../utils/validation";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import { trackNotificationsSubscribed, trackNotificationsUnsubscribed, trackSlackAdded, trackSlackRemoved } from "../../tracking/user-settings";


const Notifications = () => {
  // Slack integartion input field
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const currentUser = useSelector((state: GlobalState) => state.user);
  const [integration, setIntegration] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState<{ code: number, message: string }>({ code: 0, message: "" });

  async function saveIntegration(slackUrl: string) {
    if (integration !== "") {
      toast.error("Only one integration is allowed. Remove your previous integration");
    } else {
      try {
        await getNotificationAPI().addIntegration(currentProject.id, { webhookUrl: slackUrl, type: CreateSlackIntegrationRequestTypeEnum.Slack });
        trackSlackAdded();
        toast.success(`Slack integration was successfully added in ${currentProject.name} project.`);
      } catch (error) {
        toast.error("There was an error with your integration. Check your url again.");
      }
    }
  }

  async function handleRemoveIntegration() {
    try {
      await getNotificationAPI().deleteIntegration(currentProject.id, IntegrationType.Slack);
      setIntegration("");
      hideConfirmationModal();
      trackSlackRemoved();
      toast.success(`Slack integration was successfully removed from ${currentProject.name} project.`);
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  async function handleSubscribe() {
    if (!subscribed) {
      try {
        await getNotificationAPI().addUserOnProject(currentProject.id, currentUser.profile.id);
        toast.success(`You are now subscribed to all notification in ${currentProject.name} project.`);
        trackNotificationsSubscribed();
      } catch (error) {
        toast.error(error.response.data.message);
      }
      setSubscribed(!subscribed);
    } else {
      try {
        await getNotificationAPI().deleteUserFromProject(currentProject.id, currentUser.profile.id);
        toast.success(`You are now unsubscribed from all notification in ${currentProject.name} project.`);
        trackNotificationsUnsubscribed();
      } catch (error) {
        toast.error(error.response.data.message);
      }
      setSubscribed(!subscribed);
    }
  }

  useEffect(() => {
    const fetch = async () => {
      //TODO
      // Change the return type of listIntegrationApi in order to avoid "any" type
      try {
        const [{ data: integrations }, { data: users }]: any = await Promise.all([
          getNotificationAPI().listIntegrations(currentProject.id),
          getNotificationAPI().getUsersOnProject(currentProject.id)
        ]);
        const subscribedUser = users.users.find((u: any) => u.userId == currentUser.profile.id);

        if (subscribedUser) {
          setSubscribed(true);
        }

        if (integrations.length > 0) {
          setIntegration(integrations[0].webhookUrl);
        }
      } catch (e) {
        setError(e.response.data);
      }

    };
    fetch();
  }, [])

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete the Slack integration?`}
      description=""
      action="Remove"
      onCancel={hideConfirmationModal}
      onConfirm={handleRemoveIntegration}
    />
  ), [integration]);

  return (
    <div className="notifications__preferences">
      <div className="row">
        <div className="col-12 col-xl-6">
          <Card class="card light mb--30">
            <div className="card__header-wrapper">
              <CardHeader title="Integrations" />
            </div>

            <div className="notifications__content">

              <div className="slack">
                <h5 className="d-flex align-items-center">
                  <SlackLogo style={{ width: "23px", marginRight: "8px" }} />
                  <strong>Slack integration</strong>
                </h5>
                <p>Integrating will Slack will allow you to receive notifications from Microtica directly in your Slack channel. To create Slack webhook follow
                  <a href="https://api.slack.com/messaging/webhooks" target="_blank" rel="noreferrer"> this guide.</a>
                </p>

                {error.code === 402 ?
                  <div className="page-centered page-centered--project" style={{ paddingTop: "10%", paddingBottom:"0" }}>
                    <Link className="btn btn--xl " to="/settings?tab=billing">
                      Upgrade Plan
                      </Link>
                  </div> :
                  <Formik
                    enableReinitialize={true}
                    initialValues={{ slackUrl: integration }}
                    onSubmit={(values: any) => {
                      saveIntegration(values.slackUrl)
                    }}
                    validationSchema={slackSchema}
                    render={() => (
                      <Form>
                        <Field
                          name="slackUrl"
                          type="text"
                          icon="link"
                          placeholder="Enter Slack webhook URL"
                          hasError={true}
                          component={InputField}
                        />

                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            type="submit"
                            className="btn btn--md btn--lightBlue">
                            Add
                          </Button>
                          <Button
                            onClick={showConfirmationModal}
                            disabled={integration === ""}
                            className="btn btn--md btn--danger">
                            Remove
                            </Button>
                        </div>

                      </Form>
                    )}
                  />
                }
              </div>
            </div>
          </Card>
        </div>

        <div className="col-12 col-xl-6">
          <Card class="card light">
            <div className="card__header-wrapper">
              <CardHeader title="Subscriptions" />
            </div>

            <div className="notifications__content">
              <div className="slack">
                <h5 className="d-flex align-items-center">
                  <strong>Subscribe to all events</strong>
                </h5>
                <p>Enabling this feature will allow you to receive all Microtica events as notifications in the portal sidebar.</p>

                <ToggleButton


                  text={true}
                  isChecked={subscribed}
                  onChange={handleSubscribe}
                />

              </div>
            </div>
          </Card>
        </div>

      </div>
    </div >
  )
}

export default Notifications;