export function status(status: string): string {
    if (status === "CREATE_COMPLETE" ||
        status === "UPDATE_COMPLETE") {
        return "DEPLOYED"
    }
    if (status === "CREATE_IN_PROGRESS" ||
        status === "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS" ||
        status === "UPDATE_IN_PROGRESS") {
        return "DEPLOYING"
    }
    if (status === "ROLLBACK_IN_PROGRESS" ||
        status === "UPDATE_ROLLBACK_IN_PROGRESS" ||
        status === "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS") {
        return "ROLLBACK"
    }
    if (status === "UPDATE_ROLLBACK_COMPLETE" ||
        status === "ROLLBACK_COMPLETE") {
        return "ROLLBACKED"
    }
    if (status === "DELETE_IN_PROGRESS") {
        return "TERMINATING"
    }
    if (status === "DELETE_COMPLETE") {
        return "TERMINATED"
    }
    if (status === "CREATE_FAILED" ||
        status === "ROLLBACK_FAILED" ||
        status === "UPDATE_FAILED" ||
        status === "UPDATE_ROLLBACK_FAILED" ||
        status === "CREATE_FAILED" ||
        status === "DELETE_FAILED") {
        return "FAILED"
    }

    return status.replace(/_/g, " ");
}