import React, { useState, useEffect, Fragment, ReactElement } from "react";
import { useModal } from "react-modal-hook";

import { ReactComponent as GoogleCloudLogo } from "../../static/google-cloud-logo.svg";

import Card from "../Card/Card";
import Button from "../Button/Button";
import CardHeader from "../CardHeader/CardHeader";
import CloudAccountModal, { CloudAccountType } from "../CloudAccount/CloudAccountModal";
import DropdownMenuContainer, {
  DropdownMenuItemProps
} from "../DropdownMenuContainer/DropdownMenuContainer";
import DropdownContainer, {
  DropdownItem
} from "../DropdownContainer/DropdownContainer";

import useGcpAccountList from "../../hooks/GcpAccount";
import { FormikErrors, Field } from "formik";

export interface GcpAccountChangeEvent {
  gcpProjectId?: string;
  gcpRegion?: string;
  gcpZone?: string;
  gcpProjectName?: string;
}

export interface GcpAccountProps {
  title: string;
  subtitle?: string | ReactElement;
  showCardBox?: boolean;
  emptyText?: string | ReactElement;
  value?: GcpAccountChangeEvent;
  errors?: FormikErrors<{
    gcpProject: string | undefined;
    gcpRegion: string | undefined;
    gcpZone: string | undefined;
  }>;
  optional?: boolean;
  onChange?: (event: GcpAccountChangeEvent) => void;
  setFieldValue?: {
    (
      field: "gcpProject" | "gcpRegion" | "gcpZone",
      value: any,
      shouldValidate?: boolean | undefined
    ): void;
    (field: string, value: any): void;
  };
  disabled?: boolean;
  disabledGcpZone?: boolean;
}


const GcpAccount = (props: GcpAccountProps) => {
  // Hooks
  const [title] = useState(props.title);
  const [info] = useState(props.subtitle);
  const { gcpAccounts, gcpRegions, gcpZones } = useGcpAccountList();
  const [projectList, setProjectList] = useState<DropdownItem[]>([]);
  const [gcpProjectId, setGcpProjectId] = useState<DropdownItem>();
  const [gcpRegionList, setGcpRegionList] = useState<DropdownItem[]>([]);
  const [gcpRegion, setGcpRegion] = useState<DropdownItem>();
  const [gcpZoneList, setGcpZoneList] = useState<DropdownItem[]>([]);
  const [gcpZone, setGcpZone] = useState<DropdownItem>();

  const [showModal, hideModal] = useModal(() => (
    <CloudAccountModal onClose={hideModal} cloudAccountType={CloudAccountType.GOOGLE_CLOUD_PLATFORM} />
  ));

  useEffect(() => {
    props.onChange &&
      props.onChange({
        gcpProjectId: gcpProjectId && gcpProjectId.id,
        gcpRegion: gcpRegion && gcpRegion.id,
        gcpZone: gcpZone && gcpZone.id
      });
  }, [gcpProjectId, gcpRegion, gcpZone]);

  useEffect(() => {
    if (gcpAccounts) {
      if (props.value && props.value.gcpProjectId) {
        const projectName = gcpAccounts.find(account => account.gcpProjectId === props.value!.gcpProjectId) ? gcpAccounts.find(account => account.gcpProjectId === props.value!.gcpProjectId)!.projectName : "";
        setGcpProjectId({ id: props.value.gcpProjectId!, name: `${projectName} (${props.value.gcpProjectId})` })
      }

      setProjectList(gcpAccounts.map(account => ({ id: account.gcpProjectId!, name: `${account.projectName} (${account.gcpProjectId})` })));
    }
    if (gcpRegions.length) {
      if (props.value && props.value.gcpRegion) {
        const region = gcpRegions.find(
          region => region.id === props.value!.gcpRegion
        );
        setGcpRegion(region);
      }
      setGcpRegionList(gcpRegions.map(region => ({ id: region.id, name: region.name })));
    }
    if (gcpZones.length) {
      if (props.value && props.value.gcpZone) {
        const zone = gcpZones.find(
          zone => zone.id === props.value!.gcpZone
        );
        setGcpZone(zone);
        setGcpZoneList(
          gcpZones.filter(zone => zone.id.startsWith(props.value!.gcpZone!.slice(0, -1)))
            .map(zone => ({ id: zone.id, name: zone.name }))
        );
      }
      else {
        setGcpZoneList(
          gcpZones.map(zone => ({ id: zone.id, name: zone.name }))
        );
      }
    }
  }, [gcpAccounts, gcpRegions, gcpZones]);

  const actions: DropdownMenuItemProps[] = [
    {
      itemId: "1",
      title: "Connect Google Cloud Platform Account",
      onClick: () => {
        showModal();
      }
    }
  ];

  const emptyAccounts = (
    <Card class={props.showCardBox === false ? "" : "card dark compact"}>
      <CardHeader title={title} text={info} />
      <div className="card__content">
        <div className="connect-account">
          <GoogleCloudLogo />
        </div>
        <p className="card__content__text">
          <strong>{props.emptyText || "Connect your first Google Cloud Platform account"}</strong>
        </p>
        <Button
          className="btn btn--xl btn--orange"
          children="Connect Google Cloud Platform Account"
          onClick={() => {
            showModal();
          }}
        />
      </div>
    </Card>
  );

  const existingAccounts = (
    <Card class={props.showCardBox === false ? "" : "card dark compact"}>
      <div className="card_header_container">
        <CardHeader title={title} text={info} />
        <DropdownMenuContainer
          actions={actions}
          type="account"
          minWidth="300px"
        />
      </div>
      <div className="page-content__dropdown-container mb--30">
        <Field
          name="gcpProject"
          label="Google Cloud Platform Project"
        >
          {() => (
            <div>
              <DropdownContainer
                selectedItem={gcpProjectId}
                items={projectList}
                label="Google Cloud Platform Project"
                placeholder="Select a Google Cloud Platform project"
                optional={props.optional}
                disabled={props.disabled}
                onSelectItem={item => {
                  props.setFieldValue &&
                    props.setFieldValue("gcpProject", item.id);
                  setGcpProjectId(item);
                }}
              />
              {props.errors && props.errors.gcpProject ? (
                <div className="page-content__error">{props.errors.gcpProject}</div>
              ) : null}
            </div>
          )}
        </Field>
      </div>
      <div className="page-content__dropdown-container mb--30">
        <Field
          name="gcpRegion"
          label="Google Cloud Platform Region"
        >
          {() => (
            <div>
              <DropdownContainer
                selectedItem={gcpRegion}
                items={gcpRegionList}
                label="Google Cloud Platform Region"
                placeholder="Select a Google Cloud Platform region"
                optional={props.optional}
                disabled={props.disabled}
                onSelectItem={item => {
                  props.setFieldValue && props.setFieldValue("gcpRegion", item.id);
                  setGcpRegion(item);
                  setGcpZoneList(gcpZones.filter(zone => zone.id.startsWith(item.id)));
                  props.setFieldValue && props.setFieldValue("gcpZone", "");
                  setGcpZone({ id: "", name: "" });
                }}
              />
              {props.errors && props.errors.gcpRegion ? (
                <div className="page-content__error">{props.errors.gcpRegion}</div>
              ) : null}
            </div>
          )}
        </Field>
      </div>
      <div className="page-content__dropdown-container mb--30">
        <Field
          name="gcpZone"
          label="Google Cloud Platform Zone"
        >
          {() => (
            <div>
              <DropdownContainer
                selectedItem={gcpZone}
                items={gcpZoneList}
                label="Google Cloud Platform Zone"
                placeholder="Select a Google Cloud Platform zone"
                optional={props.optional}
                disabled={props.disabled || props.disabledGcpZone}
                onSelectItem={item => {
                  props.setFieldValue && props.setFieldValue("gcpZone", item.id);
                  setGcpZone(item);
                }}
              />
              {props.errors && props.errors.gcpZone ? (
                <div className="page-content__error">{props.errors.gcpZone}</div>
              ) : null}
            </div>
          )}
        </Field>
      </div>
    </Card >
  );

  return (
    <Fragment>{gcpAccounts.length ? existingAccounts : emptyAccounts}</Fragment>
  );
};

export default GcpAccount;
