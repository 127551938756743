import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import { InputField } from "../../components/InputField/InputField";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import { ReactComponent as MicroticaLogoBig } from "../../static/microtica-big-logo.svg";
import { resetPasswordSchema } from "../../utils/validation";
import { getUserManagementAPI } from "../../api";
import * as actions from "../../actions";
import { GlobalState } from "../../reducers";
import { LoginUserResponse, LoginUserChallengeResponse } from "@microtica/ms-usermanagement-sdk";
import { toast } from "react-toastify";
import { trackUserResetPassword } from "../../tracking/authentication";

interface ResetPasswordProps extends RouteComponentProps<{
    email: string;
    code: string;
}> { }

const ResetPassword = (props: ResetPasswordProps) => {
    const dispatch = useDispatch();

    const { isLoggedIn } = useSelector((state: GlobalState) => state.user);

    useEffect(() => {
        if (isLoggedIn) {
            props.history.push("/projects");
        }
    }, [isLoggedIn]);

    async function handleSetPassword(newPassword: string) {
        try {
            await getUserManagementAPI().confirmUserForgotPassword({
                username: props.match.params.email,
                code: props.match.params.code,
                password: newPassword
            });
            trackUserResetPassword(props.match.params.email);

            await handleLogin(props.match.params.email, newPassword);
        } catch (err) {
            toast.error(err.response.data.message || "Something went wrong with resetting your password!")
        }
    }

    async function handleLogin(username: string, password: string) {
        try {
            const { data } = await getUserManagementAPI().loginUser({
                username,
                password
            }) as { data: LoginUserResponse | LoginUserChallengeResponse };
            dispatch(actions.login(data));
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    function handleGotoLogin() {
        props.history.push("/login");
    }

    return (
        <div className="container--full welcome">
            <div className="container--md container--padding d-flex flex-column align-items-center">
                <div className="mb--20 txt--center">
                    <img
                        alt="microtica Logo"
                        className="microtica-text-logo mb--20"
                        src={microticaTextLogo}
                    />
                </div>

                <div className="box box--padding-lg mb--40">
                    <h5 className="mb--35 txt--center font--roboto">
                        Set your new password below to be able to access your account
                    </h5>

                    <Formik
                        initialValues={{ username: props.match.params.email, newPassword: "" }}
                        onSubmit={(values: { newPassword: string }) => {
                            handleSetPassword(values.newPassword);
                        }}
                        validationSchema={resetPasswordSchema}
                        render={() => (
                            <Form>
                                <Field
                                    name="username"
                                    disabled={true}
                                    placeholder="Email"
                                    type="text"
                                    component={InputField}
                                    hasError={true}
                                />
                                <Field
                                    name="newPassword"
                                    placeholder="New Password"
                                    type="password"
                                    component={InputField}
                                    hasError={true}
                                />
                                <Field
                                    name="passwordConfirm"
                                    placeholder="Confirm Password"
                                    type="password"
                                    component={InputField}
                                    hasError={true}
                                />
                                <Button
                                    type="submit"
                                    className="btn btn--xl btn--blue"
                                    children="Set New Password"
                                />
                            </Form>
                        )}
                    />
                </div>
                <p className="back m--0 txt--sm txt--white font--roboto clickable"
                    onClick={handleGotoLogin}>
                    Go to Login
                </p>
                {/* <p className="txt--sm txt--white font--roboto">
                    Go to {" "}
                    <Link
                        to="/login"
                        className="txt--sm txt--highlight font--roboto">
                        Login
                    </Link>
                </p> */}
            </div>
            <MicroticaLogoBig className="microtica-big-logo" />
            <Footer />
        </div>
    );
};



export default ResetPassword;
