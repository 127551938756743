import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";
import { UpdateAwsAccountRequest } from "@microtica/ms-aws-sdk";


export interface UpdateAwsAccountStart {
    type: constants.UPDATE_AWS_ACCOUNT_START;
}
export interface UpdateAwsAccountSuccess {
    type: constants.UPDATE_AWS_ACCOUNT_SUCCESS;
    accountId: string;
    object: UpdateAwsAccountRequest;
}
export interface UpdateAwsAccountError {
    type: constants.UPDATE_AWS_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export type UpdateAwsAccount =
    | UpdateAwsAccountStart
    | UpdateAwsAccountSuccess
    | UpdateAwsAccountError;

export function updateAwsAccountStart(): UpdateAwsAccount {
    return {
        type: constants.UPDATE_AWS_ACCOUNT_START
    };
}
export function updateAwsAccountError(error: ErrorInterface): UpdateAwsAccount {
    return {
        type: constants.UPDATE_AWS_ACCOUNT_FAIL,
        error
    };
}

export function updateAwsAccountSuccess(
    accountId: string,
    object: UpdateAwsAccountRequest
): UpdateAwsAccount {
    return {
        type: constants.UPDATE_AWS_ACCOUNT_SUCCESS,
        object,
        accountId
    };
}

export function updateAwsAccount(
    projectId: string,
    accountId: string,
    object: UpdateAwsAccountRequest
): ThunkAction<Promise<void>, {}, {}, UpdateAwsAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateAwsAccount>) => {
        dispatch(updateAwsAccountStart());
        try {
            await getAwsAPI().updateAwsAccount(accountId, projectId, object);
            dispatch(updateAwsAccountSuccess(accountId, object));
        } catch (error) {
            dispatch(updateAwsAccountError(error.response.data));
        }
    };
}
