import React, { ChangeEvent } from "react";
import { Field } from "formik";
import { GitlabContainerRegistryProps } from "../../types/DockerRegistry";
import { gitlabHierarchyTypeList } from "../../utils/docker-providers";
import DropdownContainer from "../DropdownContainer/DropdownContainer";
import { InputField } from "../InputField/InputField";

const GitlabContainerRegistry = ({ setFieldValue, gitlabHierarchyType }: GitlabContainerRegistryProps) => (
    <>
        <div className="page-content__dropdown-container">
            <Field
                name="docker_username"
                placeholder="Enter Gitlab personal access token name"
                hasError={true}
                label="Docker Username"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("docker_username", e.target.value)}
            />
        </div>
        <div className="page-content__dropdown-container">
            <Field
                name="docker_password"
                placeholder="Enter Gitlab personal access token value"
                hasError={true}
                type="password"
                label="Docker Password/Token"
                autoComplete="new-password"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("docker_password", e.target.value)}
            />
        </div>
        <div className="page-content__dropdown-container mb--30">
            <Field
                name="gitlab_hierarchy_type"
            >
                {() => (
                    <DropdownContainer
                        items={gitlabHierarchyTypeList}
                        label="Gitlab Hierarchy Type"
                        placeholder="Choose if the project exists inside a group or if it is a personal project"
                        selectedItem={gitlabHierarchyType}
                        onSelectItem={item => setFieldValue("gitlab_hierarchy_type", item)}
                    />
                )}
            </Field>
        </div>
        <div className="page-content__dropdown-container">
            <Field
                name="gitlab_id"
                placeholder="Enter the Gitlab groupId or projectId according to the chosen hierarchy type"
                hasError={true}
                label="Gitlab Id"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("gitlab_id", e.target.value)}
            />
        </div>
        <div className="page-content__dropdown-container">
            <Field
                name="registry_url"
                placeholder="E.g. registry.gitlab.com; mydomain.com:5050"
                hasError={true}
                label="Registry Url"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("registry_url", e.target.value)}
            />
        </div>
    </>
);
export { GitlabContainerRegistry };