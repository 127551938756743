import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";

import { getKubeAPI } from "../../api";
import { GlobalState } from "../../reducers";

// Import components
import Button from "../../components/Button/Button";
import PageContent from "../../components/PageContent/PageContent";
import Card from "../../components/Card/Card";
import PageHeader from "../../components/PageHeader/PageHeader";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";

import { InputField } from "../../components/InputField/InputField";
import { InputAreaField } from "../../components/InputTextarea/InputAreaField";
import { microserviceSchema } from "../../utils/validation";

import useDockerRegistriesList from '../../hooks/DockerRegistries';
import CardHeader from "../../components/CardHeader/CardHeader";
import { trackServiceCreateInit } from "../../tracking/services";

interface CreateMicroserviceProps
  extends RouteComponentProps<{ microserviceName: string }> {
  mode: "edit" | undefined;
}

const PUBLIC_DOCKER = "Public Docker Image"

const CreateMicroservice = (props: CreateMicroserviceProps) => {
  // Hooks
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [microserviceName] = useState(
    props.match.params.microserviceName
  );
  const currentProject = useSelector(
    (state: GlobalState) => state.project.currentProject
  );

  const { dockerRegistriesList: registries, isProcessing, error } = useDockerRegistriesList();
  const [registryItems, setRegistryItems] = useState<DropdownItem[]>([]);
  const [repositories, setRepositories] = useState<DropdownItem[]>([]);
  const [repository, setRepository] = useState<DropdownItem>({ id: "", name: "" });
  const [registry, setRegistry] = useState<DropdownItem>({ id: "1", name: PUBLIC_DOCKER });
  const [publicImageRepository, setPublicImageRepository] = useState<string>("");
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [allowRepoSelect, setAllowRepoSelect] = useState<boolean>(false);
  const isInitialMount = useRef(true);

  async function fetchMicroservice(items: { id: string, name: string }[]) {
    try {
      const { data: microservice } = await getKubeAPI().getMicroservice(
        microserviceName,
        currentProject.id
      );
      setName(microservice.name);
      setDescription(microservice.description);
      const registryFetched = items.find((r: { id: string, name: string }) => r.name === microservice.registry);
      setRegistry(registryFetched!);
      if (registryFetched!.name !== PUBLIC_DOCKER) {
        setRepository({ id: "0", name: microservice.imageRepository });
      } else {
        setPublicImageRepository(microservice.imageRepository);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    if (!isProcessing) {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        let items = [];
        items.push({ id: "1", name: registry.name });
        registries.map((item, index) => {
          items.push({ id: index + 2, name: item.title })
        });
        setRegistryItems(items);
        if (props.mode === "edit") {
          fetchMicroservice(items);
        }
      }
    }
  }, [isProcessing]);

  useEffect(() => {
    if (props.mode !== "edit") {
      trackServiceCreateInit();
      return;
    }
  }, [])

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: { repositories } } = await getKubeAPI().getDockerRepositories(registry.name, currentProject.id);
        const items = repositories.map((item, index) => { return { id: (index + 1).toString(), name: item } })
        setRepositories(items);
        setAllowRepoSelect(true);
      } catch (error) {
        toast.error(error.response.data.message || "Can't fetch repositories for selected registry");
        setAllowRepoSelect(false);
      }
    };

    if (registry.name != PUBLIC_DOCKER) {
      fetch();
    }
  }, [registry])

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        titlePosition="center"
        title={
          props.mode === "edit" ? "Update Service" : "Create New Service"
        }
      />
      <div className="content__body">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name,
            description,
            registry,
            imageRepository: registry.name === PUBLIC_DOCKER ? publicImageRepository : repository.name
          }}
          onSubmit={async values => {
            setIsBusy(true);
            try {
              if (props.mode === "edit") {
                await getKubeAPI().updateMicroservice(
                  values.name,
                  currentProject.id,
                  {
                    description: values.description,
                    registry: values.registry.name,
                    imageRepository: values.imageRepository
                  }
                );
                props.history.push("/kubernetes/services");
              } else {
                const { data: microserviceResponse } = await getKubeAPI().createMicroservice(
                  currentProject.id,
                  {
                    name: values.name,
                    description: values.description,
                    registry: values.registry.name,
                    imageRepository: values.imageRepository
                  }
                );
                props.history.push(`/kubernetes/services/${values.name}`);
              }

            } catch (err) {
              toast.error(err.response.data.message || "Something went wrong!");
            }
            setIsBusy(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={microserviceSchema}
          render={({ errors, setFieldValue }) => (
            <Form>
              <PageContent>
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader title="General" text="General information for the service" />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="name"
                          placeholder="Enter the name of the service"
                          type="text"
                          label="Service name"
                          hasError={true}
                          disabled={props.mode === "edit"}
                          component={InputField}
                          value={name}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="description"
                          className="input__field input--textarea"
                          placeholder="Enter service description here"
                          label="Service description"
                          hasError={true}
                          rows={4}
                          component={InputAreaField}
                          value={description}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="row justify-content-center mb--10">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader title="Service image" text="General information for the service" />
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="registry"
                          render={() =>
                            <DropdownContainer
                              items={registryItems}
                              label="Docker registry"
                              placeholder="Select docker registry"
                              selectedItem={registry}
                              disabled={isProcessing}
                              onSelectItem={item => {
                                setRegistry(item);
                                setFieldValue("registry", item, true)
                              }}
                            />
                          } />
                        {errors.registry ? (
                          <div className="page-content__error">{errors.registry.id}</div>
                        ) : null}
                      </div>
                      <div className="page-content__dropdown-container">
                        {registry && registry.name !== PUBLIC_DOCKER ?
                          <>
                            <Field
                              name="imageRepository"
                              render={() =>
                                <DropdownContainer
                                  items={repositories}
                                  label="Repository"
                                  placeholder="Select docker repository"
                                  selectedItem={repository}
                                  disabled={!allowRepoSelect}
                                  onSelectItem={item => {
                                    setRepository(item);
                                    setFieldValue("imageRepository", item, true)
                                  }}
                                />
                              } />
                            {errors.imageRepository ? (
                              <div className="page-content__error">{errors.imageRepository}</div>
                            ) : null}
                          </>
                          : <Field
                            name="imageRepository"
                            placeholder="Docker image name or path (e.g. 'nginx' or 'registry.hub.docker.com/_/nginx')"
                            type="text"
                            hasError={true}
                            label="Image name"
                            component={InputField}
                            value={registry.name === PUBLIC_DOCKER ? publicImageRepository : repository.name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setPublicImageRepository(e.target.value)}
                          />
                        }
                      </div>
                    </Card>
                  </div>
                </div>
              </PageContent>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  type="submit"
                  className="btn btn--md btn--lightBlue btn--create"
                  isBusy={isBusy}
                  busyText={props.mode === "edit" ? "Updating.." : "Creating.."}
                >
                  {props.mode === "edit" ? "Update Service" : "Create Service"}
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </main>
  );
};

export default CreateMicroservice;
