import * as React from 'react';

import { ReactComponent as KubernetesLogo } from "../../static/kubernetesIcon.svg"

export interface Props {
    title: string | any;
    text?: string | React.ReactElement;
    type?: string;
}

function CardHeader(props: Props) {
    return (
        <div className="card__header">
            <h6 className={`card__title ${props.type === "pipeline" ? "d-inline-flex" : "d-flex"} align-items-center`}>
                <strong>{props.title}</strong>
                {props.type === 'kubernetes' ? <KubernetesLogo className="ml--10" width="20px" height="20px" /> : null}
            </h6>
            {props.text ? <p className={`m--0 ${props.type === "pipeline" ? "pull--right" : ""}`}>{props.text}</p> : null}
        </div>
    );
}

export default CardHeader;