import * as React from 'react';

interface TabItemProps {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>, id?: string, ) => void; 
  isActive?: boolean;
  handleUnderline: React.MouseEventHandler;
  removeUnderline: React.MouseEventHandler;
  index: number
}

const TabMenuItem: React.StatelessComponent<TabItemProps> = props => (
  <span
    onMouseEnter={props.handleUnderline} 
    onClick={props.onClick} 
    className={`tab-menu__item ${props.isActive ? 'active' : ''}`} 
    data-index={props.index}
    onMouseLeave={props.removeUnderline}>{props.label}
  </span>
);

export default TabMenuItem;