import * as constants from "../../constants";
import {
    CreateProjectRequest
} from "@microtica/ms-project-sdk";
import { getProjectAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types/index";
import { trackCreateProject } from '../../tracking/user-settings';

export interface CreateProjectStart {
    type: constants.CREATE_PROJECT_START;
}
export interface CreateProjectSuccess {
    type: constants.CREATE_PROJECT_SUCCESS;
    data: { id: string, name: string, description: string };
}
export interface CreateProjectFail {
    type: constants.CREATE_PROJECT_FAIL;
    error: ErrorInterface;
}

export type CreateProject =
    | CreateProjectStart
    | CreateProjectSuccess
    | CreateProjectFail
    
export interface ClearError {
    type: constants.CLEAR_ERROR;
    error: ErrorInterface;
}


export function createProjectStart(): CreateProjectStart {
    return {
        type: constants.CREATE_PROJECT_START
    };
}

export function createProjectFail(error: ErrorInterface): CreateProjectFail {
    return {
        type: constants.CREATE_PROJECT_FAIL,
        error
    };
}

export function createProjectSuccess(
    data: { id: string, name: string, description: string }
): CreateProjectSuccess {
    return {
        type: constants.CREATE_PROJECT_SUCCESS,
        data
    };
}

export function createProject(
    project: CreateProjectRequest
): ThunkAction<Promise<void>, {}, {}, CreateProject> {
    return async (dispatch: ThunkDispatch<{}, {}, CreateProject>, getState) => {
        dispatch(createProjectStart());
        try {
            const { id } = (await getProjectAPI().createProject(project)).data;
            const res = { ...project, id }
            trackCreateProject(project.name, id);
            dispatch(createProjectSuccess(res));
        } catch (error) {
            dispatch(createProjectFail(error.response.data));
        }
    };
}

export function clearError(): ClearError {
    return {
        type: constants.CLEAR_ERROR,
        error: errorEmptyObject
    };
}