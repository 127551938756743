import splitbee from "@splitbee/web";
import mixpanel from "mixpanel-browser";

type WindowConfig = Window & {
  woopra: {
    identify: (data: object) => void;
    track: (event: string, data?: object) => void;
    push: (callback?: Function) => void;
  };
  gist: {
    identify: (obj: { email: string }) => void;
    track: (event: string, data?: object) => void;
  };
  env: {
    SPLITBEE_TOKEN: string;
    GIST_TOKEN: string;
    MIXPANEL_TOKEN: string;
  }
};

export const windowWithType = window as unknown as WindowConfig;

splitbee.init({
  token: windowWithType.env.SPLITBEE_TOKEN,
});

const mixpanelToken = windowWithType.env.MIXPANEL_TOKEN;

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, { ignore_dnt: true });
}

export const getProject = () => {
  return JSON.parse(localStorage.getItem("project") || "{}");
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("profile") || "{}");
};

export async function trackEvent(event: string, data?: object, username?: string) {
  const project = getProject();

  trackUser();

  // Woopra
  windowWithType.woopra && windowWithType.woopra.track(event, { ...data, projectId: project.id });

  // Splitbee
  splitbee.track(event, { ...data as { [key: string]: string | number | boolean; }, projectId: project.id });

  // Mixpanel
  mixpanel.add_group('projectId', project.id);
  mixpanel.set_group('projectId', project.id);
  mixpanel.get_group("projectId", project.id).set({ "projectId": project.id });
  mixpanel.get_group("projectId", project.id).set({ "projectName": project.name });
  mixpanel.get_group("projectId", project.id).set({ "projectDescription": project.description });
  mixpanel.track(event, data);

  // Gist
  windowWithType.gist && windowWithType.gist.track(event, { ...data, projectId: project.id });
}

export const trackUser = () => {
  if (windowWithType.woopra) {
    windowWithType.woopra.identify({
      email: getUser().email,
    });
    windowWithType.woopra.push();
  }
  if (windowWithType.gist) {
    windowWithType.gist.identify({
      email: getUser().email
    });
  }

  splitbee.user.set({
    email: getUser().email
  })

  mixpanel.alias(getUser().email);
  mixpanel.identify(getUser().email);
  mixpanel.people.set({
    "$email": getUser().email,
    "$created": new Date(),
    "$last_login": new Date(),
  });
};
