import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { MicroserviceConfigurationItem, ComponentSchemaPropertiesInputs } from "@microtica/ms-engine-sdk";
import { DeployMicroserviceRequest } from "@microtica/ms-kube-sdk";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import Button from "../Button/Button";
import Animation from "../Animations/Animations";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import { Dictionary } from "./ConfigurationItem";
import ResourceConfigurationItem from "./ResourceConfigurationItem";
import { getKubeAPI } from "../../api";
import { GlobalState } from "../../reducers";
import { useSelector } from "react-redux";
import { validateSchema } from "../../utils/validation";
import InputText from "../InputText/InputText";
import moment from "moment";
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";
import CardPlaceholders from "../Card/CardPlaceholders";
import { ReactComponent as CpuIcon } from "../../static/cpu.svg";
import { ReactComponent as MemoryIcon } from "../../static/memory.svg";

interface Property {
    key: string;
    value: string;
    type: "string" | "select" | string;
    description?: string;
    required: boolean;
    enum: string[];
    sensitive: boolean;
    reference: boolean;
}
interface MicroserviceVersion extends DropdownItem {
    date?: number;
    commit?: any;
}
const DEFAULTS = {
    MEMORY_UTILIZATION: 80,
    CPU_UTILIZATION: 80,
    MIN_REPLICAS: 2,
    MAX_REPLICAS: 10,
    INITIAL_CPU: 10,        // 10m CPU       -> 0.01 CPU
    MAX_CPU: 1000,          // 1000m CPU     -> 1 CPU
    INITIAL_MEMORY: 100,    // 100 Mi
    MAX_MEMORY: 5000        // 5000 Mi
};

let microserviceConfigs: Dictionary<MicroserviceConfigurationItem> = {};

const MicroserviceConfigurationModal = (props: {
    microserviceName: string;
    namespace?: string;
    microserviceRegistry: string;
    microserviceIsPublic?: boolean;
    clusterId: string;
    onClose: () => void;
    onSuccess?: () => void;
}) => {
    const latestVersion = { id: "latest", name: "latest" };
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [errors, setErrors] = useState<Dictionary<string>>({});
    const [selectedVersion, setSelectedVersion] = useState<MicroserviceVersion>(latestVersion);
    const [properties, setProperties] = useState<Property[]>([]);
    const [resourceOutputs] = useState<Dictionary<string[]>>({});
    const [microserviceVersions, setMicroserviceVersions] = useState<MicroserviceVersion[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [schema, setSchema] = useState<ComponentSchemaPropertiesInputs>();
    const [minimumScalling, setMinimumScalling] = useState(DEFAULTS.MIN_REPLICAS);
    const [maximumScalling, setMaximumScalling] = useState(DEFAULTS.MAX_REPLICAS);
    const [scallingMemory, setScallingMemory] = useState(DEFAULTS.MEMORY_UTILIZATION);
    const [errorMinScalling, setErrorMinScalling] = useState("");
    const [errorMaxScalling, setErrorMaxScalling] = useState("");
    const [touched, setTouched] = useState<string[]>([]);
    const [isInitializing, setIsInitializing] = useState(true);
    const [isFetchingMoreData, setIsFetchingMoreData] = useState(false);
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [initialCpu, setInitialCpu] = useState(DEFAULTS.INITIAL_CPU);
    const [maximumCpu, setMaximumCpu] = useState(DEFAULTS.MAX_CPU);
    const [errorInitialCpu, setErrorInitialCpu] = useState("");
    const [errorMaxCpu, setErrorMaxCpu] = useState("");
    const [initialMemory, setInitialMemory] = useState(DEFAULTS.INITIAL_MEMORY);
    const [maximumMemory, setMaximumMemory] = useState(DEFAULTS.MAX_MEMORY);
    const [errorInitialMemory, setErrorInitialMemory] = useState("");
    const [errorMaxMemory, setErrorMaxMemory] = useState("");
    const [scallingCpu, setScallingCpu] = useState(DEFAULTS.CPU_UTILIZATION);
    const [usage, setUsage] = useState<{ averageCpu?: string, averageMemory?: string } | undefined>({ averageCpu: "n/a", averageMemory: "n/a" });
    const [namespaces, setNamespaces] = useState<{ id: string; name: string }[]>([{ id: "", name: "" }]);
    const [selectedNamespace, setSelectedNamespace] = useState<string>(props.namespace || namespaces[0]!.id);
    const [tagCursor, setTagCursor] = useState<string>();

    // Update height of deployment modal based on inner height of the window -- for custom scrollbars to work properly
    const [customScrollbarsHeight, setCustomScrollbarsHeight] = useState("750px");
    useEffect(() => {
        if (window.innerHeight <= 768) {
            setCustomScrollbarsHeight("600px");
        } else {
            setCustomScrollbarsHeight("750px");
        }
        return () => {
            microserviceConfigs = {};
        };
    }, []);

    useEffect(() => {
        // Update props with new values after selected version changes
        for (const prop of properties) {
            handlePropUpdate({
                key: prop.key,
                value: prop.value,
                reference: prop.reference,
                sensitive: prop.sensitive
            })
        }
    }, [properties]);

    useEffect(() => {
        const fetch = async () => {
            const [deployedMicroservicesResponse, allMicroserviceVersions] = await Promise.all([
                getKubeAPI().getDeployedMicroservices(props.clusterId, currentProject.id),
                fetchMicroserviceVersions()
            ]);
            const microservice = deployedMicroservicesResponse.data.deployedMicroservices.find(m => m.name === props.microserviceName);
            let versionToBeDisplayed: any;
            if (microservice) {
                // If the selected microservice has been deployed at least once
                versionToBeDisplayed = allMicroserviceVersions.find((item: MicroserviceVersion) => item.name === microservice.version)
                    || { id: microservice.version, name: microservice.version };
            } else {
                versionToBeDisplayed = allMicroserviceVersions[0] ? allMicroserviceVersions[0] : { id: "", name: "" };
            }
            handleSelectedVersion(versionToBeDisplayed);
        }
        fetchNamespaces();
        fetch();
    }, []);

    const fetchNamespaces = async () => {
        const { data: response } = await getKubeAPI().getNamespaces(props.clusterId, currentProject.id);
        // We need the namespaces in this format in order to use them as items for the DropdownContainer
        setNamespaces(response.namespaces.map((namespace: any) => ({ id: namespace.metadata.name, name: namespace.metadata.name })));
    }

    async function fetchMicroserviceVersions() {
        try {
            const { data: { versions } } = await getKubeAPI().getMicroserviceVersions(props.microserviceName, currentProject.id, String(pagination.offset), String(pagination.limit), tagCursor);
            // BE returns the versions sorted, the latest one is first in the array
            const { tags, lastElementCursor } = versions!;
            const fetchedVersions: MicroserviceVersion[] = (tags || [])
                .sort((a, b) => b.date! - a.date!)
                .sort((a) => a.name === "latest" ? -1 : 0)
                .map(version => {
                    return {
                        id: version.name!,
                        name: version.name!,
                        hideName: true,
                        plainText: version.commit ? [version.name!, version.commit.message, version.commit.name, version.commit.user!.name].join(" ") : version.name,
                        subTitle: version.commit ? <>
                            <span className="maintext">{version.commit.message}</span>
                            <div className="subtext">
                                <span>
                                    {version.commit.name} <span className="link" >({version.name!.substring(0, 7)})</span>
                                </span>
                                <span style={{ float: "right" }}>
                                    {moment(version.date).fromNow()}
                                </span>
                            </div>
                            <div className="clearfix">
                                <img src={version.commit.user!.avatar} alt="avatar" />
                                {version.commit.user!.name}
                            </div>
                        </> : <>
                            <div className="shortened-content">
                                <span>
                                    {version.name}
                                </span>
                                <span className="faded">
                                    {moment(version.date).fromNow()}
                                </span>
                            </div>
                        </>,
                        date: version.date,
                        commit: version.commit || undefined
                    }
                });
            const allMicroserviceVersions = microserviceVersions.concat(fetchedVersions);
            setMicroserviceVersions(allMicroserviceVersions);
            setIsLoading(false);
            // If the number of returned tags is less than the pagination limit (no more data to fetch)
            // set offset to -1 (prevents from making any more requests)
            fetchedVersions.length === pagination.limit ?
                setPagination({ ...pagination, offset: pagination.offset + pagination.limit }) :
                setPagination({ ...pagination, offset: -1 });

            setTagCursor(lastElementCursor);
            return allMicroserviceVersions;
        } catch (err) {
            // sometimes Service Temporarily Unavailable is thrown (when there are many retrieved tags e.g. 70+)
            if (err!.response!.data!.code === 503) {
                fetchMicroserviceVersions();
            }
            else {
                toast.error(err!.response!.data!.message);
                setIsLoading(false);
                return [];
            }
        }
    }

    function invalidResourceInputFieldExists() {
        return errorMinScalling !== "" || errorMaxScalling !== "" || errorInitialCpu !== "" || errorInitialMemory !== "" || errorMaxCpu !== "" || errorMaxMemory !== "" || selectedNamespace === "";
    }

    async function handleDeploy() {
        const validationErrors = getValidationErrors();

        if (Object.keys(validationErrors).length) {
            const errorMessage = Object.keys(validationErrors).reduce((acc, key) => {
                return acc += `${validationErrors[key]}\n`
            }, "");
            toast.error(errorMessage);
        }

        if (!isValid() || invalidResourceInputFieldExists()) {
            return;
        }

        setIsLoading(true);

        try {
            const configurations = Object.keys(microserviceConfigs)
                .filter(key => !schema || (schema.properties as { [key: string]: string })[key] !== undefined)
                .map(key => microserviceConfigs[key]);

            const microserviceRequest: DeployMicroserviceRequest = {
                deployment: {
                    image: selectedVersion.id,
                    configurations
                },
                autoScaling: {
                    memoryUtilization: scallingMemory,
                    minReplicas: minimumScalling,
                    maxReplicas: maximumScalling,
                    cpuUtilization: scallingCpu,
                    cpu: initialCpu,
                    maxCpu: maximumCpu,
                    memory: initialMemory,
                    maxMemory: maximumMemory
                }
            };
            const { data: { done } } = props.microserviceIsPublic ?
                await getKubeAPI().deployPublicMicroservice(
                    props.microserviceName,
                    selectedNamespace,
                    props.clusterId,
                    currentProject.id,
                    microserviceRequest
                ) :
                await getKubeAPI().deployMicroservice(
                    props.microserviceName,
                    props.clusterId,
                    selectedNamespace,
                    currentProject.id,
                    microserviceRequest
                );
            if (done) {
                props.onSuccess && props.onSuccess();
                props.onClose();
            }
        } catch (err) {
            if (err.response.data.kubernetesError) {
                toast.error(`${err.response.data.message}
${err.response.data.kubernetesError.message}`
                );
            } else {
                toast.error(err.response.data.message);
            }

        } finally {
            setIsLoading(false);
        }
    }

    async function handleSelectedVersion(item: MicroserviceVersion) {
        setSelectedVersion(item);
        const version = item.id;
        if (version === "") {
            setErrors({ ...errors, version: "Please select a version" });
            setIsInitializing(false);
            return;
        }

        setIsLoading(true);

        const [{ data: microservice }, deployedConfig] = await Promise.all([
            getKubeAPI().getMicroservice(props.microserviceName, currentProject.id, version),
            getDeployedConfig()
        ]);
        if (!microservice || !microservice.schema || !microservice.schema.properties) {
            setIsLoading(false);
            setIsInitializing(false);
            return;
        }
        delete errors["version"];

        // Set microservice schema
        setSchema(microservice.schema.properties.inputs);
        const { inputs } = microservice.schema.properties;
        const inputProps: Dictionary<any> = inputs.properties || {};
        const microserviceProps = Object.keys(inputProps).map(key => {
            const inputProp: { default: string; enum: string[]; description?: string; } = inputProps[key];
            const value = inputProp.default || (microserviceConfigs[key] && microserviceConfigs[key].value) || "";
            const type = inputProp.enum ? "select" : "string";
            const required = (inputs.required || []).includes(key);

            return {
                key,
                value,
                type,
                required: required,
                description: inputProp.description,
                enum: inputProp.enum || [],
                reference: false,
                ...deployedConfig[key],
                sensitive: inputProps[key].sensitive ? true : false
            };
        });
        setIsLoading(false);
        setIsInitializing(false);
        setProperties(microserviceProps);
        setScallingMemory(parseInt(deployedConfig["MIC_AS_MEMORY_UTILIZATION"] ? deployedConfig["MIC_AS_MEMORY_UTILIZATION"].value : DEFAULTS.MEMORY_UTILIZATION.toString()));
        setMinimumScalling(parseInt(deployedConfig["MIC_AS_MIN_REPLICAS"] ? deployedConfig["MIC_AS_MIN_REPLICAS"].value : DEFAULTS.MIN_REPLICAS.toString()));
        setMaximumScalling(parseInt(deployedConfig["MIC_AS_MAX_REPLICAS"] ? deployedConfig["MIC_AS_MAX_REPLICAS"].value : DEFAULTS.MAX_REPLICAS.toString()));
        setInitialCpu(parseInt(deployedConfig["MIC_AS_CPU"] ? deployedConfig["MIC_AS_CPU"].value : DEFAULTS.INITIAL_CPU.toString()));
        setMaximumCpu(parseInt(deployedConfig["MIC_AS_MAX_CPU"] ? deployedConfig["MIC_AS_MAX_CPU"].value : DEFAULTS.MAX_CPU.toString()));
        setInitialMemory(parseInt(deployedConfig["MIC_AS_MEMORY"] ? deployedConfig["MIC_AS_MEMORY"].value : DEFAULTS.INITIAL_MEMORY.toString()));
        setMaximumMemory(parseInt(deployedConfig["MIC_AS_MAX_MEMORY"] ? deployedConfig["MIC_AS_MAX_MEMORY"].value : DEFAULTS.MAX_MEMORY.toString()));
        setScallingCpu(parseInt(deployedConfig["MIC_AS_CPU_UTILIZATION"] ? deployedConfig["MIC_AS_CPU_UTILIZATION"].value : DEFAULTS.CPU_UTILIZATION.toString()));
    }

    async function getDeployedConfig() {
        try {
            const { data: deployment } = props.microserviceIsPublic ?
                await getKubeAPI().getPublicMicroserviceDeploymentStatus(
                    props.microserviceName,
                    selectedNamespace,
                    props.clusterId,
                    currentProject.id
                ) :
                await getKubeAPI().getMicroserviceDeploymentStatus(
                    props.microserviceName,
                    props.clusterId,
                    selectedNamespace,
                    currentProject.id
                );
            deployment.usage && setUsage(deployment.usage);

            return (deployment && deployment.configurations || []).reduce((acc, variable) => {
                acc[variable.key] = {
                    key: variable.key,
                    value: variable.value,
                    sensitive: false,
                    reference: false
                };
                return acc;

            }, {} as Dictionary<{ key: string; value: string; sensitive: boolean; reference: boolean; }>);
        } catch (err) {
            if (err.status === 404) {
                return {};
            }
            throw err;
        }
    }

    function handlePropUpdate(item: MicroserviceConfigurationItem) {
        if (item.value === "") {
            delete microserviceConfigs[item.key];
        } else {
            microserviceConfigs[item.key] = item;
        }
    }
    function handleMinimumScalling(minPods: number) {
        setErrorMinScalling("");
        setMinimumScalling(minPods);
        if (isNaN(minPods)) {
            setErrorMinScalling("Required field")
        }
        if (minPods > maximumScalling) {
            setErrorMinScalling('Cannot be larger than max scaled pods ')
        } else if (minPods <= maximumScalling) {
            setErrorMaxScalling("")
        }
    }
    function handleMaximumScalling(maxPods: number) {
        setErrorMaxScalling("");
        setMaximumScalling(maxPods);
        if (isNaN(maxPods)) {
            setErrorMaxScalling("Required field")
        }
        if (maxPods < minimumScalling) {
            setErrorMaxScalling('Cannot be lower than min scaled pods')
        } else if (maxPods >= minimumScalling) {
            setErrorMinScalling("");
        }
    }

    function handleInitialCpu(initialCpu: number) {
        setErrorInitialCpu("");
        setInitialCpu(initialCpu);
        if (isNaN(initialCpu)) {
            setErrorInitialCpu("Required field")
        }
        if (initialCpu > maximumCpu) {
            setErrorInitialCpu('Cannot be higher than maximum CPU')
        } else if (initialCpu < DEFAULTS.INITIAL_CPU) {
            setErrorInitialCpu(`Cannot be lower than ${DEFAULTS.INITIAL_CPU}`)
        } else if (initialCpu <= maximumCpu) {
            setErrorMaxCpu("");
        }
    }
    function handleMaximumCpu(maxCpu: number) {
        setErrorMaxCpu("");
        setMaximumCpu(maxCpu);
        if (isNaN(maxCpu)) {
            setErrorMaxCpu("Required field")
        }
        if (maxCpu < initialCpu) {
            setErrorMaxCpu('Cannot be lower than initial CPU')
        } else if (maxCpu > DEFAULTS.MAX_CPU) {
            setErrorMaxCpu(`Cannot be higher than ${DEFAULTS.MAX_CPU}`)
        } else if (maxCpu >= initialCpu) {
            setErrorInitialCpu("");
        }
    }
    function handleInitialMemory(initialMemory: number) {
        setErrorInitialMemory("");
        setInitialMemory(initialMemory);
        if (isNaN(initialMemory)) {
            setErrorInitialMemory("Required field")
        }
        if (initialMemory > maximumMemory) {
            setErrorInitialMemory('Cannot be higher than max memory')
        } else if (initialMemory < DEFAULTS.INITIAL_MEMORY) {
            setErrorInitialMemory(`Cannot be lower than ${DEFAULTS.INITIAL_MEMORY}`)
        } else if (initialMemory <= maximumMemory) {
            setErrorMaxMemory("");
        }
    }
    function handleMaximumMemory(maxMemory: number) {
        setErrorMaxMemory("");
        setMaximumMemory(maxMemory);
        if (isNaN(maxMemory)) {
            setErrorMaxMemory("Required field")
        }
        if (maxMemory < initialMemory) {
            setErrorMaxMemory('Cannot be lower than initial memory')
        } else if (maxMemory > DEFAULTS.MAX_MEMORY) {
            setErrorMaxMemory(`Cannot be higher than ${DEFAULTS.MAX_MEMORY}`)
        } else if (maxMemory >= initialMemory) {
            setErrorInitialCpu("");
        }
    }

    function onTouched(property: string) {
        if (!touched.includes(property)) {
            setTouched([...touched.concat(property)])
        }
    }

    function getValidationErrors(property?: string) {
        let validationErrors: Dictionary<string> = errors['version'] ? { version: errors['version'] } : {};

        if (schema) {
            const validationData = Object.keys(microserviceConfigs).reduce((acc, key) => {
                if ((schema.properties as { [key: string]: string })[key] && microserviceConfigs[key].value !== "") {
                    acc[key] = microserviceConfigs[key].value;
                }
                return acc;
            }, {} as Dictionary<string>);
            const validation = validateSchema(schema, validationData);
            validationErrors = {
                ...validationErrors,
                ...validation
            }
        }

        if (property) {
            //filter the errors on the touched properties
            const filtered = Object.keys(validationErrors)
                .filter(key => touched.includes(key))
                .reduce((obj: any, key) => {
                    obj[key] = validationErrors[key];
                    return obj;
                }, {});
            validationErrors = filtered;
        } else {
            //should set all to touched
            const keys = Object.keys(validationErrors);
            const newSet = new Set([...touched, ...keys]);
            setTouched(Array.from(newSet))
        }

        return validationErrors;
    }

    function isValid(property?: string) {
        const validationErrors = getValidationErrors(property);
        setErrors(validationErrors);

        return Object.keys(validationErrors).length ? false : true;
    };

    async function fetchMoreData() {
        if (!isFetchingMoreData && pagination.offset !== -1) {
            setIsFetchingMoreData(true);
            await fetchMicroserviceVersions();
            setIsFetchingMoreData(false);
        }
    }

    return (
        <>
            <div className="page-overlay" />

            <Animation show={true} type="modal" modal itemIndex={1}>
                <div className="modal modal--configure modal--relative">
                    {/* HEADER */}
                    <div className="modal__header">
                        <h3 className="modal__title">Deploy Service</h3>
                        <span>
                            <p className="modal__text m--0">
                                <a href={`/kubernetes/services/${props.microserviceName}`} target="_blank" rel="noreferrer" style={{ color: "#0075be", fontSize: "inherit", fontWeight: 600 }}>
                                    {props.microserviceName}
                                </a>
                                &nbsp;service
                            </p>
                        </span>
                    </div>

                    <div className="modal__close" onClick={props.onClose}>X</div>

                    {/* CONTENT */}
                    <>
                        <div className="modal__content">
                            <CustomScrollbars minHeight="300px" maxHeight="calc(100vh - 230px)" resetClass="reset--top">
                                {
                                    isInitializing ?
                                        <CardPlaceholders />
                                        :
                                        <>
                                            <div className="modal__content-section">
                                                <h3 className="modal__title">General</h3>
                                                <p>
                                                    Configure the image tag to be deployed. Avoid using the "latest" tag, use exact tag instead
                                                </p>
                                                <div className="row">
                                                    <div className="col-12 col-lg-6">
                                                        {/* COMPONENT VERSION */}
                                                        <div className="row gutter--xl">
                                                            <div className="col-12">
                                                                <div className="modal__info">
                                                                    <DropdownContainer
                                                                        label="Version"
                                                                        items={microserviceVersions}
                                                                        selectedItem={selectedVersion}
                                                                        onSelectItem={handleSelectedVersion}
                                                                        tabIndex={0}
                                                                        onFocus={() => onTouched("version")}
                                                                        fetchMoreData={fetchMoreData}
                                                                        loading={isFetchingMoreData}
                                                                    />
                                                                    {errors.version ? <div style={{ color: 'red', fontSize: '12px' }}>{errors.version}</div> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!props.namespace &&
                                                        <div className="col-12 col-lg-6">
                                                            {/* NAMESPACES */}
                                                            <div className="row gutter--xl">
                                                                <div className="col-12">
                                                                    <div className="modal__info">
                                                                        <DropdownContainer
                                                                            label="Namespace"
                                                                            items={namespaces}
                                                                            selectedItem={{ id: selectedNamespace, name: selectedNamespace }}
                                                                            onSelectItem={(item) => setSelectedNamespace(item.id)}
                                                                        />
                                                                        {selectedNamespace === "" ? <div style={{ color: 'red', fontSize: '12px' }}>Please select a namespace</div> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                </div>
                                                {
                                                    selectedVersion && selectedVersion.commit &&
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="content-section-infobox">
                                                                <div className="pipeline-commit-cell">
                                                                    <div className="pipeline-commit-cell__title">
                                                                        {selectedVersion.commit!.message}
                                                                    </div>
                                                                    <div className="pipeline-commit-cell__subtitle">
                                                                        <span>
                                                                            {selectedVersion.commit!.name}
                                                                        </span>
                                                                        <span>
                                                                            &nbsp;({selectedVersion!.name!.substring(0, 7)})
                                                                        </span>
                                                                        <span style={{ float: "right" }}>
                                                                            {moment(selectedVersion.date, "x").fromNow()}
                                                                        </span>
                                                                    </div>
                                                                    <div className="pipeline-commit-cell__profile">
                                                                        <img src={selectedVersion.commit!.user!.avatar} alt="avatar" />
                                                                        {selectedVersion.commit!.user!.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="modal__content-section">
                                                <h3 className="modal__title">Scaling</h3>
                                                <p>
                                                    Configure the min and max pods to be scaled when memory is above the configured threshold
                                                </p>
                                                <div className="row">

                                                    <div className="col-12 col-lg-6">
                                                        <div className="scalling">
                                                            <div className="d-flex justify-content-start align-items-center">
                                                                <div className="scalling__input">
                                                                    <InputText
                                                                        type="number"
                                                                        label="Initial Memory (Mi)"
                                                                        placeholder="Initial"
                                                                        value={initialMemory.toString()}
                                                                        min={DEFAULTS.INITIAL_MEMORY}
                                                                        max={maximumMemory}
                                                                        onChange={e => {
                                                                            handleInitialMemory(parseInt(e.target.value))
                                                                        }}
                                                                    />
                                                                    {errorInitialMemory !== "" ?
                                                                        <span className="scalling__error">{errorInitialMemory}</span>
                                                                        : null}
                                                                </div>
                                                                <div className="scalling__input">
                                                                    <InputText
                                                                        type="number"
                                                                        label="Max Memory (Mi)"
                                                                        placeholder="Maximum"
                                                                        value={maximumMemory.toString()}
                                                                        min={initialMemory}
                                                                        onChange={e => handleMaximumMemory(parseInt(e.target.value))}
                                                                    />
                                                                    {errorMaxMemory !== "" ?
                                                                        <span className="scalling__error">{errorMaxMemory}</span>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="scalling">
                                                            <div className="scalling__item ">
                                                                <div>Memory: {scallingMemory}%</div>
                                                                <div className="scalling__slider">
                                                                    <input
                                                                        type="range"
                                                                        min="0"
                                                                        max="100"
                                                                        value={scallingMemory}
                                                                        step="10"
                                                                        id="memory"
                                                                        onChange={e => setScallingMemory(parseInt(e.target.value))}
                                                                        style={{
                                                                            background: `linear-gradient(90deg, rgba(103,190,104,1) ${scallingMemory}%, rgba(22,36,44,1) ${scallingMemory}%)`
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="page-content__info">
                                                                    <InfoIcon />
                                                                    <small>
                                                                        The service will scale and create new pods if memory is above specified percentage.
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="scalling">
                                                            <div className="d-flex justify-content-start align-items-center">
                                                                <div className="scalling__input">
                                                                    <InputText
                                                                        type="number"
                                                                        label="Initial CPU (milliCPU)"
                                                                        placeholder="Initial"
                                                                        value={initialCpu.toString()}
                                                                        min={0}
                                                                        max={maximumCpu}
                                                                        onChange={e => {
                                                                            handleInitialCpu(parseInt(e.target.value))
                                                                        }}
                                                                    />
                                                                    {errorInitialCpu !== "" ?
                                                                        <span className="scalling__error">{errorInitialCpu}</span>
                                                                        : null}
                                                                </div>
                                                                <div className="scalling__input">
                                                                    <InputText
                                                                        type="number"
                                                                        label="Max CPU (milliCPU)"
                                                                        placeholder="Maximum"
                                                                        value={maximumCpu.toString()}
                                                                        min={initialCpu}
                                                                        onChange={e => handleMaximumCpu(parseInt(e.target.value))}
                                                                    />
                                                                    {errorMaxCpu !== "" ?
                                                                        <span className="scalling__error">{errorMaxCpu}</span>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="scalling">
                                                            <div className="scalling__item ">
                                                                <div>CPU: {scallingCpu}%</div>
                                                                <div className="scalling__slider">
                                                                    <input
                                                                        type="range"
                                                                        min="0"
                                                                        max="100"
                                                                        value={scallingCpu}
                                                                        step="10"
                                                                        id="scallingCpu"
                                                                        onChange={e => setScallingCpu(parseInt(e.target.value))}
                                                                        style={{
                                                                            background: `linear-gradient(90deg, rgba(103,190,104,1) ${scallingCpu}%, rgba(22,36,44,1) ${scallingCpu}%)`
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="page-content__info">
                                                                    <InfoIcon />
                                                                    <small>
                                                                        The service will scale and create new pods if CPU is above specified percentage.
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="scalling">
                                                            <div className="d-flex justify-content-start align-items-center">
                                                                <div className="scalling__input">
                                                                    <InputText
                                                                        type="number"
                                                                        label="Min Scaled Pods"
                                                                        placeholder="Minimum"
                                                                        value={minimumScalling.toString()}
                                                                        min={1}
                                                                        max={maximumScalling}
                                                                        onChange={e => {
                                                                            handleMinimumScalling(parseInt(e.target.value))
                                                                        }}
                                                                    />
                                                                    {errorMinScalling !== "" ?
                                                                        <span className="scalling__error">{errorMinScalling}</span>
                                                                        : null}
                                                                </div>
                                                                <div className="scalling__input">
                                                                    <InputText
                                                                        type="number"
                                                                        label="Max Scaled Pods"
                                                                        placeholder="Maximum"
                                                                        value={maximumScalling.toString()}
                                                                        min={minimumScalling}
                                                                        onChange={e => handleMaximumScalling(parseInt(e.target.value))}
                                                                    />
                                                                    {errorMaxScalling !== "" ?
                                                                        <span className="scalling__error">{errorMaxScalling}</span>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="scalling">
                                                            <div className="content-section-infobox scalling__info">
                                                                <h5>Current usage</h5>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <CpuIcon />
                                                                        <span>CPU <span className="value">{usage!.averageCpu}</span></span>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <MemoryIcon />
                                                                        <span>Memory <span className="value">{usage!.averageMemory}</span></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal__content-section">
                                                <h3 className="modal__title">Environment Variables</h3>
                                                <p>
                                                    Environment variables will be automatically injected in the service runtime
                                                </p>
                                                <div className="row gutter--xl">
                                                    {
                                                        properties.map(prop => (
                                                            <div className="col-12 col-xl-6" key={`${prop.key}-${selectedVersion.id}`}>
                                                                <div className="modal__info">
                                                                    <ResourceConfigurationItem
                                                                        tabIndex={0}
                                                                        prop={prop}
                                                                        resourceOuputs={resourceOutputs}
                                                                        onChange={handlePropUpdate}
                                                                        validate={isValid}
                                                                        onFocus={() => onTouched(prop.key)}
                                                                    />
                                                                    {errors[prop.key] ? <div style={{ color: 'red', fontSize: '12px' }}>{errors[prop.key]}</div> : null}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </>
                                }

                            </CustomScrollbars>
                        </div>
                    </>
                    {/* FOOTER */}
                    <div className={`modal__footer justify-content-end d-flex`}>
                        <Button className="btn btn--lg btn--lightGreen"
                            onClick={handleDeploy}
                            disabled={isInitializing || isLoading}
                        >
                            {isInitializing || isLoading ? "Loading..." : "Deploy"}
                        </Button>
                    </div>
                </div>
            </Animation >
        </>
    );
}

export default MicroserviceConfigurationModal;