import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { GlobalState } from "../../reducers";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import { ReactComponent as NewMail } from "../../static/mail-image.svg";

interface ResetPasswordProps extends RouteComponentProps<{ email: string }> { }

const ResetPasswordConfirmation = (props: ResetPasswordProps) => {
    const { isLoggedIn } = useSelector((state: GlobalState) => state.user);

    useEffect(() => {
        if (isLoggedIn) {
            props.history.push("/projects");
        }
    }, [isLoggedIn]);

    return (
        <div className="container--full welcome">
            <div className="container--md container--padding d-flex flex-column align-items-center">
                <img className="microtica-text-logo mb--30" src={microticaTextLogo} alt="microtica"/>
                <NewMail className="mb--30" />
                <h1 className="mb--15"><strong>You've Got Mail!</strong></h1>
                <p className="txt--md txt--white">We sent a recovery link to:</p>
                <a className="txt--lg txt--white">
                    <strong>{props.match.params.email}</strong>
                </a>
                <br></br>
                <Link className="btn btn--xl btn--green" to="/login">
                    Back to Login
                </Link>
            </div>
        </div>
    );
};

export default ResetPasswordConfirmation;
