import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../../types";
import { GetAwsAccountResponse } from "@microtica/ms-aws-sdk";

export interface FetchAWSAccountsStart {
  type: constants.FETCH_AWS_ACCOUNTS_START;
}

export interface FetchAWSAccountsSuccess {
  type: constants.FETCH_AWS_ACCOUNTS_SUCCESS;
  awsAccounts: GetAwsAccountResponse[];
}

export interface FetchAWSAccountsFail {
  type: constants.FETCH_AWS_ACCOUNTS_FAIL;
  error: ErrorInterface;
}

export type FetchAWSAccounts =
  | FetchAWSAccountsStart
  | FetchAWSAccountsSuccess
  | FetchAWSAccountsFail;


export function fetchAWSAccountsStart(): FetchAWSAccounts {
  return {
    type: constants.FETCH_AWS_ACCOUNTS_START
  };
}

export function fetchAWSAccountsSuccess(
  awsAccounts: GetAwsAccountResponse[] = []
): FetchAWSAccountsSuccess {
  return {
    type: constants.FETCH_AWS_ACCOUNTS_SUCCESS,
    awsAccounts
  };
}

export function fetchAWSAccountsFail(error: ErrorInterface = errorEmptyObject): FetchAWSAccountsFail {
  return {
    type: constants.FETCH_AWS_ACCOUNTS_FAIL,
    error: error
  };
}

export function fetchAWSAccounts(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchAWSAccounts> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchAWSAccounts>) => {
    dispatch(fetchAWSAccountsStart());
    try {
      const res = await getAwsAPI().getAwsAccounts(projectId);
      dispatch(fetchAWSAccountsSuccess(res.data.awsAccounts!));
    } catch (error) {
      dispatch(fetchAWSAccountsFail(error.response.data));
    }
  };
}
