import React from "react";

const TimelineItemSkeleton = () => {
  return(
    <div className="skeleton skeleton__timeline">
      <span className="skeleton skeleton--circle skeleton--circle-xs skeleton--animation skeleton--placeholder"></span>
      <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
      <p className="skeleton skeleton--animation skeleton--placeholder"></p>
    </div>
  )
}

export default TimelineItemSkeleton;