import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProjectAPI } from '../../api';
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { GlobalState } from "../../reducers";

import Skeleton from "react-loading-skeleton";
import { ReactComponent as LogsIcon } from "../../static/logs-icon.svg";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";

import { status } from "../../utils/deployment-status";

// Import icons for DropdownMenuContainer
import { ReactComponent as EditIcon } from "../../static/edit-icon.svg";
import { ReactComponent as ReplicateIcon } from "../../static/replicate-icon.svg";
import { ReactComponent as UndeployIcon } from "../../static/undeploy-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as InsightIcon } from "../../static/insight-icon.svg";
import cloudProviders from "../../utils/cloud-providers";
import cloudTools from "../../utils/infrastructure-as-code-tools";
import useEnvironmentsMetrics from "../../hooks/EnvironmentsMetrics";


interface StageHeaderProps {
  name: string;
  status: string;
  cloudProvider: string;
  infrastructureAsCodeTool: string;
  lastDeployed?: string;
  disableDeploy: boolean;
  stageId: string;
  isLoaded: boolean;
  cancelDeploy: () => void;
  createPlan: () => void;
  quickDeploy: () => void;
  handleStageEdit: () => void;
  handleDeploymentInsights: () => void;
  handleStageReplication: () => void;
  handleStageUndeploy: () => void;
  handleStageDelete: () => void;
}
const StageHeader = (props: StageHeaderProps) => {
  const { isLoaded, environmentsMetrics } = useEnvironmentsMetrics();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [buildLimitation, setBuildLimitation] = useState<number>();
  function isStatusReady(status: string) {
    return status === "NOT_DEPLOYED" || status === "REVIEW_IN_PROGRESS" || status === "DELETE_COMPLETE";
  }

  function isStatusInProgress(status: string) {
    return status !== "REVIEW_IN_PROGRESS" && status.endsWith("IN_PROGRESS");
  }

  function getName(array: { id: string, name: string, value: string }[], key: string) {
    return array.find(el => el.value === key)!.name
  }

  useEffect(() => {
    const fetch = async () => {
      const { data: { buildMinutes } } = await getProjectAPI().getProjectBuildLimitations(currentProject.id);
      setBuildLimitation(buildMinutes)
    }
    fetch();
  }, []);
  return (
    <div className="card__header-wrapper card__header-wrapper--replicate card--underline d-flex justify-content-between align-items-center pr--0">
      <div className="d-flex justify-content-between align-items-center" style={{ width: "100%", paddingLeft: "15px" }}>
        <div className="card__header__container">
          <div className="card__header__item fit-content">
            <h5><strong>Status</strong></h5>
            {/* <p className="m--0">{props.status.replace(/_/g, " ")}</p> */}
            <p className="m--0">
              {props.isLoaded ? status(props.status) : <Skeleton width="120px" />}
            </p>
          </div>

          <div className="card__header__item fit-content">
            <h5><strong>Last Deployed</strong></h5>
            <p className="m--0">{props.isLoaded ? props.lastDeployed : <Skeleton width="80px" />}</p>
          </div>

          <div className="card__header__item fit-content">
            <h5><strong>Cloud Provider</strong></h5>
            <p className="m--0">
              {props.isLoaded ? getName(cloudProviders, props.cloudProvider) : <Skeleton width="120px" />}
            </p>
          </div>

          <div className="card__header__item fit-content">
            <h5><strong>IaC Tool</strong></h5>
            <p className="m--0">
              {props.isLoaded ? getName(cloudTools, props.infrastructureAsCodeTool) : <Skeleton width="100px" />}
            </p>
          </div>

          <div className="card__header__item fit-content">
            <h5><strong>Environment ID</strong></h5>
            <p className="m--0">{props.isLoaded ? props.stageId : <Skeleton width="80px" />}</p>
          </div>

          {props.infrastructureAsCodeTool === "terraform" &&
            <div className="card__header__item fit-content">
              <div>
                <h5><strong> Deployment time </strong></h5>
                <p className="m--0"> {props.isLoaded && isLoaded ? `${environmentsMetrics!.deploymentTime!} / ${buildLimitation!} mins` : <Skeleton width="80px" />} </p>
              </div>
            </div>
          }
        </div>

        <div className="d-flex align-items-center">
          {
            isStatusInProgress(props.status) ?
              <Button
                className="btn btn--sm btn--danger"
                children="Cancel Deployment"
                onClick={props.cancelDeploy}
              /> :
              <>
                <Button
                  className={`btn btn--sm btn--blue ${props.disableDeploy ? "disabled" : ""}`}
                  style={{ marginRight: "12px" }}
                  children="Quick Deploy"
                  disabled={props.disableDeploy}
                  onClick={props.quickDeploy}
                /> &nbsp;
                <Button
                  className={`btn btn--sm btn--dark ${props.disableDeploy ? "disabled" : ""}`}
                  children="Create Plan"
                  disabled={props.disableDeploy}
                  onClick={props.createPlan}
                />
              </>
          }

          <Link to={`/environments/${props.stageId}/details`} className="btn btn--sm btn--green btn--tooltip" data-tooltip="View Logs">
            <LogsIcon width="20" />
          </Link>

          <DropdownMenuContainer key={`dropdown-status-${props.status}`} actions={[
            {
              itemId: "0",
              title: "Deployment Insights",
              icon: <InsightIcon />,
              onClick: () => props.handleDeploymentInsights()
            },
            {
              itemId: "1",
              title: "Edit",
              type: "separator",
              icon: <EditIcon />,
              onClick: () => props.handleStageEdit()
            },
            {
              itemId: "2",
              title: "Replicate",
              icon: <ReplicateIcon />,
              disabled: isStatusInProgress(props.status),
              onClick: () => props.handleStageReplication()
            },
            {
              itemId: "3",
              title: "Undeploy",
              icon: <UndeployIcon />,
              disabled: isStatusReady(props.status) || isStatusInProgress(props.status),
              onClick: () => props.handleStageUndeploy()
            },
            {
              itemId: "4",
              title: "Delete",
              type: "separator",
              icon: <RemoveIcon />,
              disabled: !isStatusReady(props.status),
              onClick: () => props.handleStageDelete()
            }
          ]}
          />

        </div>
      </div>
    </div >
  )
}
export default StageHeader;