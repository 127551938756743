import { trackEvent } from "./index";

export const trackKubeConnectInit = () => {
    console.log("kube_connect_init")
    trackEvent("kube_connect_init", {
    });
};

export const trackRegistryCreateInit = () => {
    console.log("trackRegistryCreateInit")
    trackEvent("registry_create_init", {
    });
};