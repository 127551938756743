import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface MenuItemProps {
  id: string;
  title: string;
  href: string;
}

const MenuItem: React.StatelessComponent<MenuItemProps> = p => {
  const [linkActive, setLinkActive] = React.useState(false);
  React.useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes(p.id)) {
      setLinkActive(true);
    } else {
      setLinkActive(false);
    }
  })

  return (
    <li className="submenu__item" >
      <NavLink className={`submenu__link ${linkActive ? 'submenu__link--active' : ''}`} to={p.href} exact>{p.title}</NavLink>
    </li>
  );
};


export default MenuItem;