import React, { useState, ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";
import { useModal } from "react-modal-hook";

import PageHeader from "../../components/PageHeader/PageHeader";
import PageContent from "../../components/PageContent/PageContent";
import GitSource, { GitSourceChangeEvent } from "../../components/GitAccount/GitSource";
import { getContinuousIntegrationAPI } from "../../api";
import EnvironmentVariables from "../../components/EnvironmentVariables/EnvironmentVariables";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import Button from "../../components/Button/Button";
import { createPipelineSchema } from "../../utils/validation";
import { GlobalState } from "../../reducers";
import { InputField } from "../../components/InputField/InputField";
import { toast } from "react-toastify";
import Checkbox from "../../components/Checkbox/Checkbox";
import ModalPaymentLimit from "../../components/ModalPaymentLimit/ModalPaymentLimit";
import { trackPipelineCreateInit } from "../../tracking/pipeline";


interface CreatePipelineProps extends RouteComponentProps<{
    pipelineId: string
}> {

}

const CreatePipeline = (props: CreatePipelineProps) => {
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [name, setName] = useState("");
    const [branch, setBranch] = useState("master");
    const [modifiedFiles, setModifiedFiles] = useState("");
    const [automatedTrigger, setAutomatedTrigger] = useState(true);
    const [selectedGitSource, setSelectedGitSource] = useState<GitSourceChangeEvent>();
    const [variables, setVariables] = useState<{ key: string; value: string; sensitive?: boolean }[]>([]);
    const [pipelineId] = useState(props.match.params.pipelineId);
    const [pipelineLoaded, setPipelineLoaded] = useState("");
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState("");

    const [showPaymentModal] = useModal(() => (
        <ModalPaymentLimit
          title={paymentError}
          onCancel={() => props.history.push("/overview")}
          routeHistory={props}
        />
      ), [paymentError]);

    useEffect(() => {
        trackPipelineCreateInit();
        const fetch = async () => {
            const { data: pipeline } = await getContinuousIntegrationAPI().getPipelineById(currentProject.id, pipelineId);
            setName(pipeline.name);
            setBranch(pipeline.branchFilter);
            setModifiedFiles(pipeline.modifiedFiles || "");
            setAutomatedTrigger(pipeline.automatedTrigger);
            setSelectedGitSource({
                gitAccountId: pipeline.gitAccountId,
                gitRepositoryUrl: pipeline.repositoryUrl,
                workdir: pipeline.workDir,
            });
            setVariables(pipeline.environmentVariables || []);
            setPipelineLoaded(pipeline.id);
        }
        if (pipelineId) {
            fetch();
        }
    }, []);

    // checks if payment plan allows creating of schedule before filling the form
    useEffect(() => {
        const fetch = async function () {
            try {
                await getContinuousIntegrationAPI().listPipelines(currentProject.id);
            } catch (error) {
                if (error.response.data.code === 402) {
                    setPaymentError(error.response.data.message);
                    showPaymentModal();
                }
            }
        }
        fetch();
    }, [])

    return (
        <main className="content d-flex flex-column justify-content-start" >
            <PageHeader
                titlePosition="center"
                title={pipelineId ? "Edit Pipeline" : "Create New Pipeline"}
            />
            <div className="content__body">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name,
                        branch,
                        modifiedFiles,
                        workdir: selectedGitSource ? selectedGitSource.workdir : "/",
                        gitAccount: selectedGitSource ? selectedGitSource.gitAccountId : "",
                        gitRepo: selectedGitSource ? selectedGitSource.gitRepositoryUrl : "",
                        automatedTrigger
                    }}
                    onSubmit={async (values) => {
                        setIsBusy(true);
                        try {
                            if (pipelineId) {
                                await getContinuousIntegrationAPI().updatePipeline(currentProject.id, pipelineId, {
                                    gitAccountId: values.gitAccount!,
                                    repositoryUrl: values.gitRepo!,
                                    branchFilter: values.branch!,
                                    automatedTrigger: values.automatedTrigger,
                                    environmentVariables: variables,
                                    workDir: values.workdir,
                                    modifiedFiles: values.modifiedFiles
                                });
                                props.history.push(`/pipelines/${pipelineId}/details`);
                            } else {
                                const { data: { pipelineId } } = await getContinuousIntegrationAPI().createPipeline(currentProject.id, {
                                    name: values.name,
                                    gitAccountId: values.gitAccount!,
                                    repositoryUrl: values.gitRepo!,
                                    branchFilter: values.branch!,
                                    automatedTrigger: values.automatedTrigger,
                                    environmentVariables: variables,
                                    workDir: values.workdir,
                                    modifiedFiles: values.modifiedFiles
                                });
                                props.history.push(`/pipelines/${pipelineId}/details`);
                            }
                        } catch (error) {
                            toast.error(error.response.data.message);
                        }
                        setIsBusy(false);
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={createPipelineSchema}
                    render={({ errors, setFieldValue }) => (
                        <Form>
                            <PageContent key={pipelineLoaded}>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-xl-6 mb--10">
                                        <Card class="card dark compact">
                                            <div className="card_header_container">
                                                <CardHeader title="General" text="General information for the pipeline" />
                                            </div>
                                            <div className="page-content__dropdown-container">
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    label="Pipeline name"
                                                    placeholder="Enter pipeline name here"
                                                    component={InputField}
                                                    hasError={true}
                                                    disabled={pipelineId}
                                                    value={name}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb--10">
                                    <GitSource
                                        value={selectedGitSource}
                                        onChange={setSelectedGitSource}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-xl-6 mb--10">
                                        <Card class="card dark compact">
                                            <div className="card_header_container">
                                                <CardHeader
                                                    title="Trigger filters"
                                                    text="Trigger this pipeline only if configured filters are matched"
                                                />
                                            </div>
                                            <div className="page-content__dropdown-container mb--30">
                                                <Checkbox
                                                    key="automatedTrigger"
                                                    name="Trigger on Git push"
                                                    description="The pipeline will be automatically triggered on every push on specified branch, tag and modified files pattern"
                                                    checked={automatedTrigger}
                                                    onChange={checked => setAutomatedTrigger(checked)}
                                                />
                                            </div>
                                            <div className="page-content__dropdown-container">
                                                <Field
                                                    name="branch"
                                                    key="branch"
                                                    placeholder="Branch name"
                                                    type="text"
                                                    label="Branch or Tag (plain or regex)"
                                                    info="Trigger this pipeline for branches or tags that match the regex. E.g. master, feature/.*, v0.*"
                                                    hasError={true}
                                                    component={InputField}
                                                    value={branch}
                                                    disabled={!automatedTrigger}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setBranch(e.target.value)}
                                                />
                                                <Field
                                                    key="modifiedFiles"
                                                    name="modifiedFiles"
                                                    type="text"
                                                    label="Modified files (Glob pattern)"
                                                    info="Trigger pipeline only if certain files change. You can define multiple patters separated by comma. E.g. path/**/dir, path/*.json. Leave empty or / to trigger on any file change"
                                                    placeholder="Leave empty or / to trigger on any file change"
                                                    component={InputField}
                                                    hasError={true}
                                                    disabled={!automatedTrigger}
                                                    value={modifiedFiles}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setModifiedFiles(e.target.value)}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-xl-6 mb--10">
                                        <Card class="card dark compact">
                                            <div className="card_header_container">
                                                <CardHeader
                                                    title="Environment variables"
                                                    text="Variables can be used in the pipeline spec using {{variable-name}} syntax"
                                                />
                                            </div>
                                            <div className="page-content__dropdown-container">
                                                <EnvironmentVariables
                                                    initialItems={variables}
                                                    onChange={items => setVariables(items)}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </PageContent>
                            <div className="d-flex justify-content-center align-items-center">
                                <Button
                                    type="submit"
                                    className="btn btn--md btn--lightBlue btn--create"
                                    isBusy={isBusy}
                                    busyText={pipelineId ? "Updating..." : "Creating..."}
                                >
                                    {pipelineId ? "Update Pipeline" : "Create Pipeline"}
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </div>
        </main>
    );
}

export default CreatePipeline;