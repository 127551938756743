import React, { useState, useEffect, createRef } from "react";
import { useSelector } from 'react-redux';
import { RouteComponentProps } from "react-router";
import moment from "moment";
import ClipboardJS from "clipboard";
import { toast } from "react-toastify";
import { GetKubernetesStatusResponseMicroservicesStatus } from "@microtica/ms-kube-sdk";
import Skeleton from "react-loading-skeleton";
import { ServiceDeploymentResponseResponse } from "@microtica/ms-elasticsearch-sdk";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import TabMenuContainer from "../../components/TabMenuContainer/TabMenuContainer";
import { GlobalState } from "../../reducers";
import { getElasticSearchAPI, getKubeAPI } from "../../api";
import Checkbox from "../../components/Checkbox/Checkbox";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import { useModal } from "react-modal-hook";
import StageStatus from "../../components/StageStatus/StageStatus";
import Badge from "../../components/Badge/Badge";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import Animation from "../../components/Animations/Animations";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import CollapsibleCard from "../../components/CollapsibleCard/CollapsibleCard";
import Commit from "../../components/Commit/Commit";

import { PreviousToNext } from "../DeploymentHistory/DeploymentHistoryCardBody";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { statusColor } from "../../utils/status-color";


interface MicroserviceLogsAndEventsProps extends RouteComponentProps<{
    clusterId: string;
    namespace: string;
    microserviceName: string;
}> { }

const MicroserviceLogsAndEvents = (props: MicroserviceLogsAndEventsProps) => {
    // Hooks
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);

    // const [searchedValue, setSearchedValue] = useState("");
    const [pod, setPod] = useState<DropdownItem>();
    const [podsList, setPodList] = useState<DropdownItem[]>([]);
    const [logs, setLogs] = useState("");
    const [spec, setSpec] = useState("");
    const [status, setStatus] = useState<GetKubernetesStatusResponseMicroservicesStatus>();
    const [serviceInsights, setServiceInsights] = useState<ServiceDeploymentResponseResponse[]>();
    const [autoRefresh, setAutoRefresh] = useState(true);
    const { clusterId, namespace, microserviceName } = props.match.params;
    const [selectedTab, setSelectedTab] = useState("1");
    const [events, setEvents] = useState<any>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [imageClipboardText, setImageClipboardText] = useState("");
    const [versionClipboardText, setVersionClipboardText] = useState("");
    const latestLog = createRef<HTMLDivElement>();
    const [instantScroll, setInstantScroll] = useState(true);

    const [showSpecModal, hideSpecModal] = useModal(() => (
        <Animation show={true} type="modal" modal itemIndex={1}>
            <div className="modal modal--configure"
            // onClose={hideSpecModal}
            >
                <div className="modal__header mb--40">
                    <h3 className="modal__title">Kubernetes specification</h3>
                    JSON specification for the microservice deployed in Kubernetes
                </div>
                <div className="modal__close" onClick={hideSpecModal}>X</div>
                <CustomScrollbars minHeight="300px" maxHeight="calc(100vh - 230px)" resetClass="reset--top">
                    {/* CONTENT */}
                    <div className="modal__content">
                        <div className="details__container">
                            <code>{spec}</code>
                        </div>
                    </div>
                </CustomScrollbars>
            </div>
        </Animation>
    ), [spec]);

     // Image clipboard
     const imageClipboard = new ClipboardJS('.image-clipboard');
     imageClipboard.on('success', (e) => {
         setImageClipboardText("Copied to clipboard!");
         e.clearSelection();
     });

     // Version clipboard
     const versionClipboard = new ClipboardJS('.version-clipboard');
     versionClipboard.on('success', (e) => {
         setVersionClipboardText("Copied to clipboard!");
         e.clearSelection();
     });

     const fetchStatus = async () => {
        try {
            const [{ data: response }] = await Promise.all([ 
                getKubeAPI().getKubernetesStatus(clusterId, currentProject.id)
            ])
            const msStatus = response.microservicesStatus.find(service => service.namespace === namespace && service.name === microserviceName);
            const podItems: DropdownItem[] = (msStatus!.pods || []).map(pod => ({
                id: pod.metadata.name,
                name: pod.metadata.name,
                subTitle: pod.status.phase
            }));

            setStatus(msStatus);
            setPodList(podItems);
            setPod(podItems[0]);
            setImageClipboardText(`Click to copy to clipboard: ${msStatus!.imageRepository}`);
            setVersionClipboardText(`Click to copy to clipboard: ${msStatus!.version}`);
            setIsLoaded(true);
        } catch (err) {
            setIsLoaded(true);
            toast.error(err.response.data.message);
        }
    };

    const fetchPodLogs = async () => {
        try {
            const [{ data }] = await Promise.all([ 
                getKubeAPI().getMicroservicePodLogs(
                    microserviceName,
                    namespace,
                    clusterId,
                    pod!.name,
                    currentProject.id,
                )
            ])
            setLogs("");
            setLogs(data.logs);

        } catch (err) {
            toast.error(err.data);
        }
    };

    const fetchDeployments = async () => {
        try {
            const { data } = await getElasticSearchAPI().getServiceDeploymentHistory(
                currentProject.id,
                microserviceName,
                clusterId
            )
            setServiceInsights(data.response as ServiceDeploymentResponseResponse[]);

        } catch (err) {
            toast.error(err.response.data.message);
        }
    };

    const fetchPodEvents = async () => {
        try {
            const [{ data }] = await Promise.all([
                getKubeAPI().getMicroservicePodEvents(
                    microserviceName,
                    namespace,
                    clusterId,
                    pod!.name,
                    currentProject.id,
                )
            ])

            setEvents(data.events.map((event) => ([
                event.message,
                event.type,
                event.count,
                moment(event.firstSeen).format("MMM, DD"),
                moment(event.lastSeen).format("MMM, DD"),
            ])));
            setIsLoaded(true);
        }
        catch (err) {
            toast.error(err.data);
        }
    }

    useEffect(() => {
        fetchStatus();
        fetchDeployments();
    }, [])

    useEffect(() => {
        if (latestLog.current !== null) {
            if (instantScroll) {
                latestLog.current.scrollIntoView({ behavior: "auto" })
                setInstantScroll(false);
            } else {
                latestLog.current.scrollIntoView({ behavior: "smooth" })
            }
        }
    }, [logs])

    useEffect(() => {
        if (autoRefresh && selectedTab === "1") {
            const timer = setInterval(() => {
                fetchPodLogs();
            }, 5000);

            fetchPodLogs();
            return () => clearInterval(timer);
        }
        if (selectedTab === "2") {
            fetchPodEvents();
        }
    }, [pod, autoRefresh, selectedTab]);

    function handleTabClick(id: string) {
        setSelectedTab(id);
    }

    async function handleSpecClick() {
        showSpecModal();

        const [{ data: response }] = await Promise.all([
            getKubeAPI().getMicroserviceDeploymentStatus(
                microserviceName,
                clusterId,
                namespace,
                currentProject.id
            )
        ])
        setSpec(JSON.stringify(response));
    }

    function cardHeader(data: any) {
        return [
            <span className="first-item">
                <div>
                    <b>{data.status}</b>
                </div>
                <div>{moment(data.timestamp).fromNow()}</div>
            </span>,
            <div id={data.initiator.id}>{data.initiator.email}</div>,
            <PreviousToNext
                previous={{ 
                    name: data.previousVersion || "n/a",
                    link: data.previousVersion && data.commit ?
                        data.commit.version.includes("-") && data.commit.pipelineId ?
                        `/pipelines/${data.commit.pipelineId}/builds/${data.previousVersion === "latest" ? data.commit.version : data.previousVersion}`
                        : `https://bitbucket.org/${data.commit.repository}/commits/${data.commit.version}`
                        : undefined }}
                next={{ 
                    name: data.version || "n/a",
                    link: data.version && data.commit ? data.commit.version.includes("-") && data.commit.pipelineId ?
                        `/pipelines/${data.commit.pipelineId}/builds/${data.version === "latest" ? data.commit.version : data.version}`
                        : `https://bitbucket.org/${data.commit.repository}/commits/${data.commit.version}`
                        : undefined }}
            />
        ]
    }

    function cardBody(data: ServiceDeploymentResponseResponse) {
        const { error } = data as any;
        const pipelineId = (data.commit as any)?.pipelineId;

        return (
            <div className="pt--5 pr--25 pl--10">
                <ErrorMessage message={error} />
                <div className="row txt--white overflow-auto">
                    {data.commit &&
                        <div className="col col-sm-12 col-md-auto col-lg-auto p--20 pr--40">
                            <h5 className="fw--semibold pb--10">Commit</h5>
                            <Commit
                                name={data.commit.name}
                                message={data.commit.message}
                                version={data.commit.version.substring(0, 7)}
                                href={data.commit.version.includes("-") && pipelineId ?
                                    `/pipelines/${pipelineId}/builds/${data.commit.version}`
                                    : `https://bitbucket.org/${data.commit.repository}/commits/${data.commit.version}`}
                                target={data.commit.version.includes("-") && pipelineId ? "" : "_blank"}
                                userName={data.commit.user.name}
                                userAvatar={data.commit.user.avatar}
                            />
                        </div>
                    }
                    <div className="col col-sm-12 col-md-auto col-lg-auto p--20 pr--40">
                        <h5 className="fw--semibold pb--10">Description</h5>
                        <div>
                            {data.description}
                        </div>
                    </div>
                    <div className="col col-sm-12 col-md-auto col-lg-auto p--20 pr--40">
                        <h5 className="fw--semibold pb--10">Image Repository</h5>
                        <div>
                            {data.imageRepository}
                        </div>
                    </div>
                    <div className="col col-sm-12 col-md-auto col-lg-auto p--20 pr--40">
                        <h5 className="fw--semibold pb--10">Kubernetes Cluster</h5>
                        <div >
                            <a 
                              className="txt--highlight" 
                              style={{ fontSize: "inherit" }}
                              href={`http://localhost:3000/kubernetes/clusters/${data.kuberentesId ? data.kuberentesId : data.kubernetesName}`}
                            >
                                {data.kuberentesId ? data.kuberentesId : data.kubernetesName}
                            </a>
                        </div>
                    </div>
                    <div className="col col-sm-12 col-md-auto col-lg-auto p--20 pr--40">
                        <h5 className="fw--semibold pb--10">Registry</h5>
                        <div>
                            {data.registry}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <main className="content d-flex flex-column justify-content-start">
            <PageHeader
                title={microserviceName}
                items={[
                    <Button
                     className="btn btn--md btn--link btn--blue m--0"
                     onClick={handleSpecClick}
                     key="Button"
                    >
                        Kubernetes Spec
                    </Button>
                ]}
            />
            <div className="content__body">
                <div className="details__status">
                    <StageStatus
                        class="stages__info-wrapper--details"
                        items={[
                            {
                                id: 1,
                                title: "Name",
                                text: isLoaded ? status!.name : <Skeleton width="70px" />,
                            },
                            {
                                id: 2,
                                title: "Image",
                                text: isLoaded ? <span className="tooltip tooltip--build-details" data-tooltip={imageClipboardText}>
                                    <span
                                        className="build image-clipboard"
                                        data-clipboard-text={status!.imageRepository}
                                    >
                                        {status!.imageRepository.substring(0, 6)}
                                    </span>
                                </span> : <Skeleton width="70px" />,
                            },
                            {
                                id: 3,
                                title: "Version",
                                text: isLoaded ? <span className="tooltip tooltip--build-details" data-tooltip={versionClipboardText}>
                                    <span
                                        className="build version-clipboard"
                                        data-clipboard-text={status!.version}
                                    >
                                        {status!.version.substring(0, 6)}
                                    </span>
                                </span> : <Skeleton width="70px" />,
                            },
                            {
                                id: 4,
                                title: "Last Deployed",
                                text: isLoaded ? moment(status!.lastDeployed).fromNow() : <Skeleton width="70px" />,
                            },
                            {
                                id: 5,
                                title: "Status",
                                text: isLoaded ? <Badge name={status!.isHealthy ? "HEALTHY" : "FAILED"} status={status!.isHealthy ? "SUCCESS" : "FAILED"} /> : <Skeleton width="70px" />,
                            },
                        ]}
                    />
                </div>
                <Card class="card card--vertical-full dark">
                    <div className="card__header-wrapper card__header-wrapper--lists">
                        <TabMenuContainer
                            class="tab-menu--big"
                            tabs={[
                                { id: "1", label: "Logs" },
                                { id: "2", label: "Events" },
                                { id: "3", label: "Insights" }
                            ]}
                            onClick={(e) => handleTabClick(e.id)}
                        />
                        <div className="d-flex align-items-center justify-content-end" style={{ width: "100%" }}>
                            <h5 className="pr--20">Pods</h5>
                            <DropdownContainer
                                selectedItem={pod}
                                items={podsList}
                                onSelectItem={setPod}
                            />
                            {selectedTab === "1" ?
                                <div className="ml--50">
                                    <Checkbox
                                        name="Auto-refresh (every 5s)"
                                        checked={autoRefresh}
                                        onChange={checked => setAutoRefresh(checked)}
                                    />
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    {selectedTab === "1" ?
                        <div>
                            <br />
                            <div className="details__container details__container--full">
                                <code className="details__code-block details__code-block--lg">
                                    {
                                        logs === "" ? <p>Preparing logs...</p> :
                                            <pre style={{ color: "white" }}>
                                                {logs}

                                                <div ref={latestLog} />
                                            </pre>
                                    }
                                </code>
                            </div>
                        </div>
                        : selectedTab === "2" ?
                            <div className="ptb--10">
                                <Table
                                    rows={events}
                                    headers={["Message", "Type", "Count", "First seen", "Last seen"]}
                                    isLoaded={isLoaded}
                                    tableModifier="pods-table"
                                />
                            </div>
                        :
                        <CustomScrollbars maxHeight="calc(100vh - 330px)" resetClass="reset--top">
                            {serviceInsights && serviceInsights.map((service, index) => 
                                <div className="p--10 service-insights-card" key={index}>
                                <CollapsibleCard
                                    key={service.id + index}
                                    header={cardHeader(service)}
                                    body={cardBody(service)}
                                    status={
                                        statusColor(service.status) === "success" && index === 0 ?
                                            "success" :
                                        statusColor(service.status) === "warning" ?
                                            "warning" :
                                        statusColor(service.status) === "fail" ?
                                            "fail" : ""
                                    }
                                    opened={index === 0 ? true : window.location.href.split('#')[1] === service.id}
                                />
                                </div>
                            )}
                        </CustomScrollbars>
                    }
                </Card>
            </div>
        </main >
    )
}

export default MicroserviceLogsAndEvents;