import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface StageStatusItem {
    id: number;
    title: string | React.ReactElement;
    text: string | React.ReactElement;
    status?: string;
    configurable?: boolean;
    icon?: any;
    onClick?: React.MouseEventHandler;
    button?: JSX.Element;
    class?: string;
}

export interface StageStatusContainerProps {
    items: StageStatusItem[];
    class?: string;
}

function StageStatusContainer(props: StageStatusContainerProps & RouteComponentProps) {

    return (
        <div className={`stages__info-wrapper ${props.class ? props.class : ''}`} onClick={(e) => { e.stopPropagation(); }}>
            {props.items.map(item => (
                <div
                    key={item.id}
                    className={`stages__info-container ${item.class || ""}`}
                >
                    {item.icon ?
                        <div className={`circle circle--lg circle--${item.status ? item.status : 'placeholder'}`}>{item.icon}</div> : null
                    }

                    <div className="stages__info">
                        <h6>{item.title}</h6>
                        {item.configurable ?
                            <div className="stages__info stages__info--configure" onClick={item.onClick}>{item.text}</div>
                            :
                            <div className={`stages__info stages__info--description ${item.status ? 'txt--white' : ""}`}>{item.text}</div>
                        }
                    </div>
                    {item.button ? <div className="stages__info stages__info-button">{item.button}</div> : null}
                </div>
            ))}
        </div>
    )
}

export default withRouter(StageStatusContainer);