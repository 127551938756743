import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";

export default function useAzureAccountList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { azureAccounts, isFetching, error, isFinished } = useSelector((state: GlobalState) => state.azureAccount);

    // Get a list of Azure accounts from the backend
    useEffect(() => {
        dispatch(actions.fetchAzureAccounts(currentProject.id));
    }, [dispatch]);

    return {
        azureAccounts,
        isFetching,
        isFinished,
        error
    };
}