import * as React from "react";


export interface PageHeaderProps {
  title: string | any;
  titlePosition?: "left" | "center";
  items?: any[];
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <header className={`content__header ${props.titlePosition === "center" ? "content__header--center" : ""} `}>
      <h3>{props.title}</h3>

      {props.items ? <div className="content__header__buttons">
        {props.items}
      </div> : null}
    </header>
  );
};

export default PageHeader;
