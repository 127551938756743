import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../reducers";
import { fetchKuberneteses } from "../actions/kubernetes/kuberneteses-list";

export default function useKubernetesesList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { kuberneteses, isProcessing, error } = useSelector((state: GlobalState) => state.kubernetes);

    useEffect(() => {
        dispatch(fetchKuberneteses(currentProject.id));
    }, [dispatch]);

    return {
        kuberneteses,
        isProcessing,
        error,
        disabledKubernetesDashboard: error.code === 402
    }
}
