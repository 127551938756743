import * as constants from "../../constants";
import { getEngineAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types";

export interface DeleteComponentStart {
    type: constants.DELETE_COMPONENT_START;
    componentId: string;
}
export interface DeleteComponentSuccess {
    type: constants.DELETE_COMPONENT_SUCCESS;
    componentId: string;
}
export interface DeleteComponentFail {
    type: constants.DELETE_COMPONENT_FAIL;
    error: ErrorInterface;
}

export type DeleteComponent =
    | DeleteComponentStart
    | DeleteComponentSuccess
    | DeleteComponentFail

export function deleteComponentStart(componentId: string): DeleteComponentStart {
    return {
        type: constants.DELETE_COMPONENT_START,
        componentId
    };
}

export function deleteComponentFail(error: ErrorInterface = errorEmptyObject): DeleteComponentFail {
    return {
        type: constants.DELETE_COMPONENT_FAIL,
        error
    };
}

export function deleteComponentSuccess(
    componentId: string,
): DeleteComponentSuccess {
    return {
        type: constants.DELETE_COMPONENT_SUCCESS,
        componentId
    };
}

export function deleteComponent(
    projectId: string,
    componentId: string
): ThunkAction<Promise<void>, {}, {}, DeleteComponent> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteComponent>) => {
        dispatch(deleteComponentStart(componentId));
        try {
            await getEngineAPI().deleteComponent(componentId, projectId);
            dispatch(deleteComponentSuccess(componentId));
        } catch (error) {
            dispatch(deleteComponentFail(error.response.data));
        }
    };
}
