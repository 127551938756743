import { FetchProjects, CurrentProjectSelected, CreateProject, ClearError, FetchTimeline, RemoveUserFromProject, FetchProjectUsers, UpdateUserPermissionsOnProject, DeleteProject } from '../actions';
import { ProjectState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';
import { UpdateProject } from '../actions/projects/update-project';

export const initialState: ProjectState = {
    projects: [],
    events: [],
    projectUsers: [],
    isProcessing: false,
    currentProject: { id: "", name: "Project", description: "Project" },
    projectCreated: false,
    error: errorEmptyObject
}
export default function reducer(state: ProjectState = initialState, action:
    FetchProjects | CurrentProjectSelected | CreateProject | UpdateProject |
    ClearError | FetchTimeline | RemoveUserFromProject | FetchProjectUsers |
    UpdateUserPermissionsOnProject | DeleteProject): ProjectState {
    switch (action.type) {
        case constants.FETCH_PROJECTS_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.projects.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                }),
                isProcessing: false,
                error: errorEmptyObject
            };
        case constants.FETCH_PROJECTS_FAIL: return { ...state, error: action.error };
        case constants.CURRENT_PROJECT_SELECTED: return { ...state, projectCreated: false, currentProject: action.project };

        case constants.CREATE_PROJECT_START: return { ...state, projectCreated: false, isProcessing: true, error: errorEmptyObject };
        case constants.CREATE_PROJECT_SUCCESS: return { ...state, isProcessing: false, projects: [...state.projects, action.data], projectCreated: true, error: errorEmptyObject };
        case constants.CREATE_PROJECT_FAIL: return { ...state, isProcessing: false, error: action.error, projectCreated: false };
        case constants.CLEAR_ERROR: return { ...state, isProcessing: false, error: action.error, projectCreated: false };

        case constants.UPDATE_PROJECT_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.UPDATE_PROJECT_SUCCESS:
            const updatedProjects = state.projects.map(project => {
                if (project.id === action.projectId) {
                    return {
                        ...project,
                        name: action.object.name ? action.object.name : project.name,
                        description: action.object.description ? action.object.description : project.description
                    }
                }
                return project;
            });

            return {
                ...state,
                projects: updatedProjects,
                isProcessing: false,
                error: errorEmptyObject
            };
        case constants.UPDATE_PROJECT_FAIL: return { ...state, isProcessing: false, error: action.error };

        case constants.DELETE_PROJECT_START: return { ...state, error: errorEmptyObject };
        case constants.DELETE_PROJECT_SUCCESS: return {
            ...state,
            projects: state.projects.filter(p => p.id !== action.projectId),
            error: errorEmptyObject
        };
        case constants.DELETE_PROJECT_FAIL: return { ...state, error: action.error };


        case constants.FETCH_TIMELINE_START: return { ...state, isProcessing: true, projectCreated: false, error: errorEmptyObject };
        case constants.FETCH_TIMELINE_SUCCESS: return { ...state, events: action.events, isProcessing: false, projectCreated: false, error: errorEmptyObject };
        case constants.FETCH_TIMELINE_FAIL: return { ...state, isProcessing: false, error: action.error, projectCreated: false };
        case constants.CLEAR_ERROR: return { ...state, isProcessing: false, projectCreated: false, error: errorEmptyObject }

        case constants.FETCH_PROJECT_USERS_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_PROJECT_USERS_SUCCESS:
            return {
                ...state, projectUsers: action.users, isProcessing: false
            }
        case constants.FETCH_PROJECT_USERS_FAIL: return { ...state, isProcessing: false, error: errorEmptyObject };

        case constants.REMOVE_USER_FROM_PROJECT_START: return { ...state, isProcessing: true }
        case constants.REMOVE_USER_FROM_PROJECT_SUCCESS: return { ...state, isProcessing: false, projectUsers: state.projectUsers.filter(user => user.userId !== action.userId) }
        case constants.REMOVE_USER_FROM_PROJECT_FAIL: return { ...state, isProcessing: false, error: action.error }

        case constants.UPDATE_USER_PERMISSIONS_START: return { ...state, isProcessing: true }
        case constants.UPDATE_USER_PERMISSIONS_SUCCESS: return {
            ...state,
            isProcessing: false,
            projectUsers:
                state.projectUsers.map(user => {
                    if (user.userId === action.object.userId) {
                        return {
                            ...user,
                            permission: action.object.permission
                        }
                    }
                    return user;
                })
        }
        case constants.UPDATE_USER_PERMISSIONS_FAIL: return { ...state, isProcessing: false, error: action.error }

        default: {
            return state;
        }
    }
}
