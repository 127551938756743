import React, { useState } from "react";

// Import components
import Card from "../../components/Card/Card";
import SearchBar from "../../components/SearchBar/SearchBar";
import Button from "../../components/Button/Button";

// Import icons
import { ReactComponent as DeployIcon } from "../../static/deploy-icon.svg";
import { ReactComponent as ArrowIcon } from "../../static/cluster-arrow-icon.svg";
import useMicroserviceList from "../../hooks/MicroserviceList";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import Skeleton from "react-loading-skeleton";

import Animation from "../Animations/Animations";
import useOnClickOutside from "../../hooks/clickOutside";
import TabMenuContainer, { Tab } from "../TabMenuContainer/TabMenuContainer";
import { microserviceCategories, MicroserviceType } from "../../utils/microservice-categories";

export interface ClusterButtonProps {
  sidemenuClosed: boolean;
  handleSidemenu: React.MouseEventHandler;
  onClick: (microservice: {
    itemId: string;
    title: string;
    description: string;
    registry: string;
    isPublic?: boolean;
  }) => void;
  setSidemenuClosed: Function;
}

const ClustersSidemenu = (props: ClusterButtonProps) => {
  const { filter, setFilter, filteredItems, isProcessing } = useMicroserviceList();
  const [displayedServiceType, setDisplayedServiceType] = useState<MicroserviceType>(MicroserviceType.PRIVATE);

  const ref = React.useRef(null);

  useOnClickOutside(ref,() => props.setSidemenuClosed(true));

  const handleTabChange = (selectedTab: Tab) => {
    switch (selectedTab.id) {
      case "0":
      default:
        setDisplayedServiceType(MicroserviceType.PRIVATE);
        break;
      case "1":
        setDisplayedServiceType(MicroserviceType.PUBLIC);
        break;
    }
  }

  return (
    <React.Fragment>
      {(!props.sidemenuClosed) ? <div className="page-overlay"></div> : null}

      <div className="cluster" ref={ref}>
        <div className={`cluster__sidemenu ${(!props.sidemenuClosed) ? 'cluster__sidemenu--open' : null}`}>
          <div className="cluster__side-btn" onClick={props.handleSidemenu}>
            <p className="m--0 txt--white txt--nm"><strong>Add Services</strong></p>
            <span className="circle circle--md circle--white"><ArrowIcon /></span>
          </div>

          {/* Cluster sidemenu header */}
          <div className="cluster__header">
            <TabMenuContainer
              class="tab-menu--deployment"
              tabs={microserviceCategories}
              onClick={handleTabChange}
            />
          </div>

          <CustomScrollbars maxHeight={"calc(100vh - 50px)"} customClass="cluster">
            <div className="cluster__container">
              <SearchBar
                placeholder="Search services..."
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
              />
              {filteredItems && filteredItems.length ?
                filteredItems
                  .filter(microservice => !!microservice.isPublic === (displayedServiceType === MicroserviceType.PUBLIC))
                  .map((microservice, index) => {
                    return (
                      <Animation key={microservice.itemId} show={!isProcessing && !props.sidemenuClosed} type="fade" itemIndex={index}>
                        <Card class="card light" key={index}>
                          <h6>
                            <strong>{isProcessing ? <Skeleton width="80px" /> : microservice.title}</strong>
                          </h6>

                          <p className="m--0">{isProcessing ? <Skeleton width="120px" /> : microservice.description}</p>

                          {isProcessing ?
                            <Skeleton width="70px" height="20px" />
                            :
                            null}

                          <Button className="btn btn--xs btn--blue" onClick={() => props.onClick(microservice)}><DeployIcon /> Deploy</Button>

                        </Card>
                      </Animation>)
                  })
                : <h6 className="txt--center txt--grey p--15">No services available.</h6>}
            </div>

          </CustomScrollbars>

        </div>
      </div>
    </React.Fragment>
  )
}

export default ClustersSidemenu;