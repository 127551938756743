import React, { useState, useEffect, ReactElement } from 'react';
import useOnClickOutside from "../../hooks/clickOutside";
import CustomScrollbars from '../CustomScrollbars/CustomScrollbars';
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";

export interface DropdownItem {
  id: string;
  name: string;
  subTitle?: string | ReactElement;
  suggestionScore?: number;
  isExternalCluster?: boolean;
  hideName?: boolean;
  plainText?: string;
  value?: string;
}

export interface Props {
  items: DropdownItem[];
  onSelectItem: (item: DropdownItem) => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  selectedItem?: DropdownItem;
  disabled?: boolean;
  label?: string;
  info?: string;
  icon?: string;
  type?: "text" | "password";
  placeholder?: string;
  value?: string;
  tabIndex?: number;
  initialFilter?: string;
  optional?: boolean;
  fetchMoreData?: Function;
  maxWidth?: string;
  loading?: boolean;
}

function Dropdown(props: Props) {
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState(props.value || "");
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (props.selectedItem) {
      setSelected(props.selectedItem.name)
    }
  }, [props.selectedItem])


  const clickHandler = () => {
    if (!props.disabled) {
      setOpen(!open);
    }
  };

  const selectItemHandler = (item: DropdownItem) => {
    clickHandler();

    // Clear the value for the searched text
    setSearchedText("");

    // Set the value of the input as the selected item from the dropdown
    setSelected(item.name);

    props.onSelectItem(item);
  }

  useOnClickOutside(ref, () => setOpen(false));

  // Prevent going back when pressed on Enter if dropdown menu is open
  React.useEffect(() => {
    const dropdownReference: any = ref.current;

    window.addEventListener("keydown", e => {
      if (dropdownReference.classList.contains("open") && e.key === "Enter") {
        e.preventDefault();
      }
    })
  }, [])

  let classes = "dropdown";
  if (open) {
    classes = classes + ' open';
  }

  const handleSearchedText = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update searched text state
    setSearchedText(e.target.value);
    setSelected("");

    props.onChange && props.onChange(e.target.value);

    // If searched text is empty, clear the selected item
    if (e.target.value === "") {
      props.onSelectItem({ id: "", name: "" });
    }
  }

  return (
    <div className={classes} ref={ref} tabIndex={-1} style={props.maxWidth ? { maxWidth: props.maxWidth } : {}}>
      {
        props.label ?
          <>
            <label className="input__label">
              {props.label}
              {
                props.info ? <span className="tooltip" data-tooltip={props.info}><InfoIcon /></span> : null
              }
            </label>
            {
              props.optional ?
                <span style={{ float: "right", color: "#a5b8ca" }}>(optional)</span> :
                null
            }
          </>
          : null
      }
      <div className={`dropdown__btn ${props.items.length === 0 || props.disabled ? 'dropdown__btn--disabled' : ''}`}
        onClick={clickHandler}
        tabIndex={-1}
      >
        <input
          className={`dropdown__input input__field mb--0 input__field--${props.icon}`}
          type={props.type || "text"}
          autoComplete="new-password"
          value={searchedText || selected}
          placeholder={props.placeholder || "Search..."}
          onChange={(e => { handleSearchedText(e); setOpen(true) })}
          onKeyPress={e => e.key === "Enter" && e.preventDefault()}
          disabled={props.disabled}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          tabIndex={props.tabIndex}
        />
        <span className="dropdown__arrow"></span>
      </div>

      <ul className="dropdown__content" tabIndex={-1}>
        <CustomScrollbars maxHeight="300px" resetClass="reset--top" fetchMoreData={props.fetchMoreData}>
          {/* Filter out items that dont match typed text */}
          {props.items.filter(item => (item.plainText || item.name).toString().toLowerCase().includes(
            (searchedText || props.initialFilter || "").toLowerCase()
          )).map((item, index) => (
            <li
              className={`dropdown__item ${props.selectedItem && item.id === props.selectedItem.id ? "selected" : ""}`}
              key={index}
              onClick={() => selectItemHandler(item)}
            >
              {
                !item.hideName ?
                  <span className="dropdown__maintext">
                    {item.name}
                  </span> : null
              }

              <div className="dropdown__sidetext">
                {item.suggestionScore && item.suggestionScore > 0.65 ? <small>suggested</small> : null}
              </div>
              {/* Item subtitle (if it exists) */}
              {
                item.subTitle ?
                  <div>
                    <small className="dropdown__subtitle">{item.subTitle}</small>
                  </div>
                  : null
              }
            </li>
          ))}
          {props.loading &&
            <li className="skeleton skeleton__header ml--10 mr--10 flex align-items-center justify-content-between">
              <div>
                <p className="skeleton--animation"></p>
                <h6 className="skeleton--animation"></h6>
              </div>
              <h6 className="ml--50 skeleton--animation"></h6>
            </li>
          }
        </CustomScrollbars>
      </ul>

    </div >
  );
}



export default Dropdown;