import * as constants from "../../constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types";
import { getKubeAPI } from "../../api";
import { UpdateKubernetesRequest } from "@microtica/ms-kube-sdk";

export interface UpdateKubernetesStart {
    type: constants.UPDATE_KUBERNETES_START;
}
export interface UpdateKubernetesSuccess {
    type: constants.UPDATE_KUBERNETES_SUCCESS;
    kubernetesId: string;
    object: UpdateKubernetesRequest
}
export interface UpdateKubernetesFail {
    type: constants.UPDATE_KUBERNETES_FAIL;
    error: ErrorInterface;
}

export type UpdateKubernetes =
    | UpdateKubernetesStart
    | UpdateKubernetesSuccess
    | UpdateKubernetesFail


export function updateKubernetesStart(): UpdateKubernetesStart {
    return {
        type: constants.UPDATE_KUBERNETES_START
    };
}

export function updateKubernetesFail(error: ErrorInterface = errorEmptyObject): UpdateKubernetesFail {
    return {
        type: constants.UPDATE_KUBERNETES_FAIL,
        error
    };
}

export function updateKubernetesSuccess(
    kubernetesId: string,
    object: UpdateKubernetesRequest
): UpdateKubernetesSuccess {
    return {
        type: constants.UPDATE_KUBERNETES_SUCCESS,
        kubernetesId,
        object
    };
}

export function updateKubernetes(
    kubernetesId: string,
    projectId: string,
    object: UpdateKubernetesRequest
): ThunkAction<Promise<void>, {}, {}, UpdateKubernetes> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateKubernetes>) => {
        dispatch(updateKubernetesStart());
        try {
            await getKubeAPI().updateKubernetes(kubernetesId, projectId, object)
            dispatch(updateKubernetesSuccess(kubernetesId, object));
        } catch (error) {
            dispatch(updateKubernetesFail(error.response.data));
        }
    };
}

