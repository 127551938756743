import React from "react";

const DeploymentPlanPlaceholders = () => {
  return (
    <React.Fragment>
      {[...Array(2)].map((_value, index) => (
        <div className="skeleton skeleton__card skeleton__card--deployment" key={index}>
          <div className="skeleton skeleton__content">
            <div className="skeleton skeleton__circle skeleton--animation skeleton--placeholder"></div>
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>
          </div>

          <div className="skeleton skeleton__content">
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>
          </div>

          <div className="skeleton skeleton__content">
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>
          </div>
  
        </div>
      ))}
    </React.Fragment>
  )
}

export default DeploymentPlanPlaceholders;