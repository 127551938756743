const gcpZones =
    [
        {
            "id": "AsiaEast1A",
            "name": "asia-east1-a"
        },
        {
            "id": "AsiaEast1B",
            "name": "asia-east1-b"
        },
        {
            "id": "AsiaEast1C",
            "name": "asia-east1-c"
        },
        {
            "id": "AsiaEast2A",
            "name": "asia-east2-a"
        },
        {
            "id": "AsiaEast2B",
            "name": "asia-east2-b"
        },
        {
            "id": "AsiaEast2C",
            "name": "asia-east2-c"
        },
        {
            "id": "AsiaNortheast1A",
            "name": "asia-northeast1-a"
        },
        {
            "id": "AsiaNortheast1B",
            "name": "asia-northeast1-b"
        },
        {
            "id": "AsiaNortheast1C",
            "name": "asia-northeast1-c"
        },
        {
            "id": "AsiaNortheast2A",
            "name": "asia-northeast2-a"
        },
        {
            "id": "AsiaNortheast2B",
            "name": "asia-northeast2-b"
        },
        {
            "id": "AsiaNortheast2C",
            "name": "asia-northeast2-c"
        },
        {
            "id": "AsiaNortheast3A",
            "name": "asia-northeast3-a"
        },
        {
            "id": "AsiaNortheast3B",
            "name": "asia-northeast3-b"
        },
        {
            "id": "AsiaNortheast3C",
            "name": "asia-northeast3-c"
        },
        {
            "id": "AsiaSouth1A",
            "name": "asia-south1-a"
        },
        {
            "id": "AsiaSouth1B",
            "name": "asia-south1-b"
        },
        {
            "id": "AsiaSouth1C",
            "name": "asia-south1-c"
        },
        {
            "id": "AsiaSoutheast1A",
            "name": "asia-southeast1-a"
        },
        {
            "id": "AsiaSoutheast1B",
            "name": "asia-southeast1-b"
        },
        {
            "id": "AsiaSoutheast1C",
            "name": "asia-southeast1-c"
        },
        {
            "id": "AsiaSoutheast2A",
            "name": "asia-southeast2-a"
        },
        {
            "id": "AsiaSoutheast2B",
            "name": "asia-southeast2-b"
        },
        {
            "id": "AsiaSoutheast2C",
            "name": "asia-southeast2-c"
        },
        {
            "id": "AustraliaSoutheast1A",
            "name": "australia-southeast1-a"
        },
        {
            "id": "AustraliaSoutheast1B",
            "name": "australia-southeast1-b"
        },
        {
            "id": "AustraliaSoutheast1C",
            "name": "australia-southeast1-c"
        },
        {
            "id": "EuropeCentral2A",
            "name": "europe-central2-a"
        },
        {
            "id": "EuropeCentral2B",
            "name": "europe-central2-b"
        },
        {
            "id": "EuropeCentral2C",
            "name": "europe-central2-c"
        },
        {
            "id": "EuropeNorth1A",
            "name": "europe-north1-a"
        },
        {
            "id": "EuropeNorth1B",
            "name": "europe-north1-b"
        },
        {
            "id": "EuropeNorth1C",
            "name": "europe-north1-c"
        },
        {
            "id": "EuropeWest1B",
            "name": "europe-west1-b"
        },
        {
            "id": "EuropeWest1C",
            "name": "europe-west1-c"
        },
        {
            "id": "EuropeWest1D",
            "name": "europe-west1-d"
        },
        {
            "id": "EuropeWest2A",
            "name": "europe-west2-a"
        },
        {
            "id": "EuropeWest2B",
            "name": "europe-west2-b"
        },
        {
            "id": "EuropeWest2C",
            "name": "europe-west2-c"
        },
        {
            "id": "EuropeWest3A",
            "name": "europe-west3-a"
        },
        {
            "id": "EuropeWest3B",
            "name": "europe-west3-b"
        },
        {
            "id": "EuropeWest3C",
            "name": "europe-west3-c"
        },
        {
            "id": "EuropeWest4A",
            "name": "europe-west4-a"
        },
        {
            "id": "EuropeWest4B",
            "name": "europe-west4-b"
        },
        {
            "id": "EuropeWest4C",
            "name": "europe-west4-c"
        },
        {
            "id": "EuropeWest6A",
            "name": "europe-west6-a"
        },
        {
            "id": "EuropeWest6B",
            "name": "europe-west6-b"
        },
        {
            "id": "EuropeWest6C",
            "name": "europe-west6-c"
        },
        {
            "id": "NorthamericaNortheast1A",
            "name": "northamerica-northeast1-a"
        },
        {
            "id": "NorthamericaNortheast1B",
            "name": "northamerica-northeast1-b"
        },
        {
            "id": "NorthamericaNortheast1C",
            "name": "northamerica-northeast1-c"
        },
        {
            "id": "SouthamericaEast1A",
            "name": "southamerica-east1-a"
        },
        {
            "id": "SouthamericaEast1B",
            "name": "southamerica-east1-b"
        },
        {
            "id": "SouthamericaEast1C",
            "name": "southamerica-east1-c"
        },
        {
            "id": "UsCentral1A",
            "name": "us-central1-a"
        },
        {
            "id": "UsCentral1B",
            "name": "us-central1-b"
        },
        {
            "id": "UsCentral1C",
            "name": "us-central1-c"
        },
        {
            "id": "UsCentral1F",
            "name": "us-central1-f"
        },
        {
            "id": "UsEast1B",
            "name": "us-east1-b"
        },
        {
            "id": "UsEast1C",
            "name": "us-east1-c"
        },
        {
            "id": "UsEast1D",
            "name": "us-east1-d"
        },
        {
            "id": "UsEast4A",
            "name": "us-east4-a"
        },
        {
            "id": "UsEast4B",
            "name": "us-east4-b"
        },
        {
            "id": "UsEast4C",
            "name": "us-east4-c"
        },
        {
            "id": "UsWest1A",
            "name": "us-west1-a"
        },
        {
            "id": "UsWest1B",
            "name": "us-west1-b"
        },
        {
            "id": "UsWest1C",
            "name": "us-west1-c"
        },
        {
            "id": "UsWest2A",
            "name": "us-west2-a"
        },
        {
            "id": "UsWest2B",
            "name": "us-west2-b"
        },
        {
            "id": "UsWest2C",
            "name": "us-west2-c"
        },
        {
            "id": "UsWest3A",
            "name": "us-west3-a"
        },
        {
            "id": "UsWest3B",
            "name": "us-west3-b"
        },
        {
            "id": "UsWest3C",
            "name": "us-west3-c"
        },
        {
            "id": "UsWest4A",
            "name": "us-west4-a"
        },
        {
            "id": "UsWest4B",
            "name": "us-west4-b"
        },
        {
            "id": "UsWest4C",
            "name": "us-west4-c"
        }
    ]




export default gcpZones;