import * as constants from "../../../constants";
import { GitAccount } from "@microtica/ms-ap-sdk";
import { getContinuousIntegrationAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../../types";

export interface FetchGitAccountsStart {
  type: constants.FETCH_GIT_ACCOUNTS_START;
}

export interface FetchGitAccountsSuccess {
  type: constants.FETCH_GIT_ACCOUNTS_SUCCESS;
  gitAccounts: GitAccount[];
}

export interface FetchGitAccountsFail {
  type: constants.FETCH_GIT_ACCOUNTS_FAIL;
  error: ErrorInterface;
}

export type FetchGitAccounts =
  | FetchGitAccountsStart
  | FetchGitAccountsSuccess
  | FetchGitAccountsFail;


export function fetchGitAccountsStart(): FetchGitAccounts {
  return {
    type: constants.FETCH_GIT_ACCOUNTS_START
  };
}

export function fetchGitAccountsSuccess(
  gitAccounts: GitAccount[] = []
): FetchGitAccountsSuccess {
  return {
    type: constants.FETCH_GIT_ACCOUNTS_SUCCESS,
    gitAccounts
  };
}

export function fetchGitAccountsFail(error: ErrorInterface = errorEmptyObject): FetchGitAccountsFail {
  return {
    type: constants.FETCH_GIT_ACCOUNTS_FAIL,
    error: error
  };
}

export function fetchGitAccounts(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchGitAccounts> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchGitAccounts>) => {
    dispatch(fetchGitAccountsStart());
    try {
      const res = await getContinuousIntegrationAPI().listGitAccounts(projectId);
      dispatch(fetchGitAccountsSuccess(res.data.gitAccounts));
    } catch (error) {
      dispatch(fetchGitAccountsFail(error.response.data));
    }
  };
}
