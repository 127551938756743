import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

interface CustomScrollbarsProps {
  children: React.ReactNode;
  maxHeight: string
  minHeight?: string;
  customClass?: string;
  resetClass?: string;
  fetchMoreData?: Function;
}
let lastScrollPosition = 0;

const CustomScrollbars = (props: CustomScrollbarsProps) => {
  let baseClass = 'track-vertical';

  // Merge base class with custom class if passed as prop
  if (props.customClass || props.resetClass) baseClass = `
    ${baseClass} 
    ${props.customClass ? `${baseClass}--${props.customClass}` : ''}
    ${props.resetClass ? props.resetClass : ''}`;

  const handleAboutToReachBottom = () => {
    if(props.fetchMoreData){
      props.fetchMoreData();
    }
  }

  const handleScrollUpdate = (values: any) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 50; // 50px of the bottom
    const percentageOfContentScrolled = ((scrollTop + pad) / (scrollHeight - clientHeight));
    // percentageOfContentScrolled will be greater than 1 if we are about to reach the bottom
    if (percentageOfContentScrolled > 1 && scrollTop > lastScrollPosition) {
      handleAboutToReachBottom();
    }
    lastScrollPosition = scrollTop;
  }

  return (
    <Scrollbars
      renderTrackVertical={props => <div {...props} className={baseClass} />}
      renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
      renderTrackHorizontal={props => <div {...props} style={{display: "none"}} />}
      renderThumbHorizontal={props => <div {...props} style={{display: "none"}} />}
      autoHide
      autoHeight
      onUpdate={handleScrollUpdate}
      autoHeightMin={props.minHeight}
      autoHeightMax={props.maxHeight}
      className={props.customClass === "menubar"? "sideMenu" : ""}
    >
      {props.children}
    </Scrollbars>
  )
}

export default CustomScrollbars;