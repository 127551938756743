import React, { useState, useEffect } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from "react-redux";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import moment from "moment";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

// Import components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import Button from "../../components/Button/Button";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import StageStatus from "../../components/StageStatus/StageStatus";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";

// Import icons for DropdownMenuContainer
import { ReactComponent as EditIcon } from "../../static/edit-icon.svg";
import { ReactComponent as ReplicateIcon } from "../../static/replicate-icon.svg";
import { ReactComponent as UndeployIcon } from "../../static/undeploy-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";

// Import icons in the circles
import { ReactComponent as InProgressIcon } from "../../static/inprogress-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";
import { ReactComponent as ClockIcon } from "../../static/clock-icon.svg";
import { ReactComponent as ErrorIcon } from "../../static/error-icon.svg";
import { ReactComponent as DollarIcon } from "../../static/dollar-icon.svg";
import { ReactComponent as InsightIcon } from "../../static/insight-icon.svg";
import { ReactComponent as CloudformationLogo } from "../../static/cloudformation.svg";
import { ReactComponent as TerraformLogo } from "../../static/terraform.svg";
import { ReactComponent as AWSLogo } from "../../static/aws-logo.svg";
import { ReactComponent as AzureLogo } from "../../static/azure-logo.svg";
import { ReactComponent as GCPLogo } from "../../static/google-cloud-logo.svg";

// Other icons
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from '@material-ui/icons/Check';

import useStageList from "../../hooks/StageList";

import * as actions from "../../actions";
import { GlobalState } from "../../reducers";
import StagesListPlaceholders from "./StagesListPlaceholders";
import { getEngineAPI, getCloudCostOptimizerAPI } from "../../api";
import { status } from "../../utils/deployment-status";

import EnvironmentScreen from "../../static/environment-details.png";

import {
  GetIdleLoadBalancerStatsResponse,
  GetIdleLoadBalancerStatsResponseClassicELBStatsStatusEnum,
  GetIdleRelationalDBStatsResponse,
  GetLowUtilizationEC2StatsResponse,
  GetLowUtilizationEC2StatsResponseStatusEnum,
  GetIdleLoadBalancerStatsResponseNonClassicELBStatsStatusEnum,
  GetIdleRelationalDBStatsResponseStatusEnum
} from "@microtica/ms-cloud-cost-optimizer-sdk";
import { trackEnvReplicateInit } from "../../tracking/environment";

export interface advisorResources {
  ec2: GetLowUtilizationEC2StatsResponse,
  elb: GetIdleLoadBalancerStatsResponse,
  rds: GetIdleRelationalDBStatsResponse
}

const StagesList = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const { stages, error } = useStageList();
  const [selectedStage, setSelectedStage] = useState<GetStagesItem>();
  const [schedulesMap, setSchedulesMap] = useState(new Map<string, string>());
  const [advisor, setAdvisor] = useState(new Map<string, advisorResources>())
  const [cloudToolsPaymentRequired, setCloudToolsPaymentRequired] = useState(false);

  // Check if content is loaded
  const [isLoaded, setIsLoaded] = useState(false);
  const isProcessing = useSelector((state: GlobalState) => state.stage.isFetching);

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    isStatusReady(selectedStage!.status) ?
      <ModalDanger
        title={`Are you sure you want to delete "${selectedStage!.name}" environment?`}
        description="Do you really want to delete the environment. This action cannot be undone."
        action="Delete"
        onCancel={hideDeleteModal}
        onConfirm={handleDeleteConfirmation}
      /> :
      <ModalDanger
        title={`Are you sure you want to undeploy "${selectedStage!.name}" environment?`}
        description="This action will delete all the resources from the Cloud within this environment. This action will not delete the environment itself and resources created in Microtica."
        action="Undeploy"
        onCancel={hideDeleteModal}
        onConfirm={handleUndeployConfirmation}
      />
  ), [selectedStage]);

  useEffect(() => {
    const fetch = async function () {
      try {
        const { data: schedules } = await getCloudCostOptimizerAPI().listSchedules(currentProject.id);

        setSchedulesMap(
          new Map(schedules.rules.map(
            schedule => schedule.state === "ENABLED" ?
              [schedule.resourcesTagValue, schedule.resourcesTagKey] : ["", ""]
          ))
        );

        const advisorStats = await Promise.all(stages.map(async (stage) => {
          if (stage.awsAccountId && stage.awsRegion) {

            const { data: { ec2, elb, rds } } = await getCloudCostOptimizerAPI().getAllStats(
              stage.awsAccountId!,
              currentProject.id,
              stage.awsRegion!,
              "microtica:environment",
              stage.id
            );

            return {
              stageId: stage.id,
              ec2,
              elb,
              rds
            };
          }
        }
        ));

        const advisorMap = new Map<string, advisorResources>();

        advisorStats.map(s => s && advisorMap.set(s.stageId, { ec2: s.ec2, rds: s.rds, elb: s.elb }));

        setAdvisor(advisorMap);
        setIsLoaded(true);
      } catch (e) {
        toast.error(e.response ? e.response.data.message : (e.message || "Something went wrong!"));
        setCloudToolsPaymentRequired(e.response && e.response.data.code === 402);
        setIsLoaded(true);
      }
    }

    if (stages && stages.length) {
      fetch();
    }
  }, [stages.length === 0])

  useEffect(() => {
    if (error.message && error.message !== "")
      toast.error(error.message);
  }, [error])

  function checkAdvisor(id: string) {
    if (advisor.get(id)!) {
      if (advisor.get(id)!.ec2.status === GetLowUtilizationEC2StatsResponseStatusEnum.Warning ||
        advisor.get(id)!.elb.classicELBStats.status === GetIdleLoadBalancerStatsResponseClassicELBStatsStatusEnum.Warning ||
        advisor.get(id)!.elb.nonClassicELBStats.status === GetIdleLoadBalancerStatsResponseNonClassicELBStatsStatusEnum.Warning ||
        advisor.get(id)!.rds.status === GetIdleRelationalDBStatsResponseStatusEnum.Warning) {

        return "warning";

      } else if (advisor.get(id)!.ec2.status === GetLowUtilizationEC2StatsResponseStatusEnum.Ok ||
        advisor.get(id)!.elb.classicELBStats.status === GetIdleLoadBalancerStatsResponseClassicELBStatsStatusEnum.Ok ||
        advisor.get(id)!.elb.nonClassicELBStats.status === GetIdleLoadBalancerStatsResponseNonClassicELBStatsStatusEnum.Ok ||
        advisor.get(id)!.rds.status === GetIdleRelationalDBStatsResponseStatusEnum.Ok) {

        return "ok";

      } else return "noExisting";
    } else return "noExisting";
  }

  const checkPaymentLimitationAndHandleEnvironmentCreate = async () => {
    try {
      await getEngineAPI().createStage(currentProject.id);
    } catch (error) {
      props.history.push(`/environments/create`);
    }
  }

  function handleDeleteConfirmation() {
    dispatch(actions.deleteStage(currentProject.id, selectedStage!.id, selectedStage!.name));
    hideDeleteModal();
  }

  function handleUndeployConfirmation() {
    dispatch(actions.undeployStage(currentProject.id, selectedStage!.id));
    hideDeleteModal();
  }

  function selectStage(itemId: string) {
    const selectedStage = stages.find(s => s.id === itemId);
    setSelectedStage(selectedStage);
  }

  function handleStageDelete(itemId: string) {
    selectStage(itemId);
    showDeleteModal();
  }

  function handleStageReplication(stage: GetStagesItem) {
    trackEnvReplicateInit(stage.id, stage.name);
    props.history.push(`/environments/${stage.id}/replicate`);
  }

  function handleStageEdit(itemId: string) {
    props.history.push(`/environments/${itemId}/edit`);
  }

  function handleStageUndeploy(itemId: string) {
    selectStage(itemId);
    showDeleteModal();
  }

  function lastDeployed(stage: GetStagesItem) {
    return stage.lastDeployed && !isStatusReady(stage.status) ? moment(stage.lastDeployed).fromNow() : "N/A";
  }

  function isStatusReady(status: string) {
    return status === "NOT_DEPLOYED" || status === "REVIEW_IN_PROGRESS" || status === "DELETE_COMPLETE";
  }

  function isStatusInProgress(status: string) {
    return status !== "REVIEW_IN_PROGRESS" && status.endsWith("IN_PROGRESS");
  }

  function isStatusFailed(status: string) {
    return status.endsWith("FAILED");
  }

  function isStatusCompleted(status: string) {
    return status.endsWith("COMPLETE") && status !== "DELETE_COMPLETE";
  }

  function getAdvisorSummary(resources: advisorResources) {
    return (
      <>
        {resources.ec2.status === GetLowUtilizationEC2StatsResponseStatusEnum.Warning &&
          <div>{resources.ec2.lowUtilizationInstances} of {resources.ec2.totalInstances} EC2 instances have low average daily utilization</div>
        }
        {
          resources.elb.classicELBStats.status === GetIdleLoadBalancerStatsResponseClassicELBStatsStatusEnum.Warning &&
          <div>{resources.elb.classicELBStats.idleLoadBalancers} of {resources.elb.classicELBStats.idleLoadBalancers} classic load balancers appear to be idle.</div>
        }
        {
          resources.elb.nonClassicELBStats.status === GetIdleLoadBalancerStatsResponseNonClassicELBStatsStatusEnum.Warning &&
          <div>{resources.elb.nonClassicELBStats.idleLoadBalancers} of {resources.elb.nonClassicELBStats.idleLoadBalancers} application, network and gateway load balancers appear to be idle.</div>
        }
        {
          resources.rds.status === GetIdleRelationalDBStatsResponseStatusEnum.Warning &&
          <div>{resources.rds.idleRelationalDBResources} of {resources.rds.totalRelationalDBResources} DB instances appear to be idle. </div>
        }
      </>);
  }

  function mapAdvisor(id: string, awsAccountId?: string, awsRegion?: string) {
    if (isLoaded) {
      const advisorLink = (
        <Link
          className="txt--highlight"
          onClick={(event) => event.stopPropagation()}
          to={{
            pathname: "/tools/advisor",
            state: {
              awsAccount: {
                awsAccountId: awsAccountId && awsAccountId,
                awsRegion: awsRegion && awsRegion
              },
              tagKey: "microtica:environment",
              tagValue: id
            }
          }}>
          View details
        </Link>
      );

      if (checkAdvisor(id) === "warning") {
        return {
          id: 6,
          title: "Waste Advisor",
          icon: <WarningIcon className="txt--yellow status-icon" viewBox="0 1 24 24" />,
          text: <>{getAdvisorSummary(advisor.get(id)!)}{advisorLink}</>
        }
      }
      else {
        return {
          id: 6,
          title: "Waste Advisor",
          icon: <CheckIcon className="txt--green" />,
          text: <><div>All resources are utilized</div>{advisorLink}</>
        }
      }
    }
    else {
      return {
        id: 6,
        title: "",
        text:
          <div className="row">
            <div className="col">
              <Skeleton width="30px" height="30px" circle />
            </div>
            <div className="col">
              <Skeleton width="80px" />
              <Skeleton width="80" />
            </div>
          </div>
      }
    }
  }

  function handleCloudToolPaymentRequired(stage: GetStagesItem) {

    const emptyItem = (id: number) => ({
      id,
      title: "",
      text: ""
    });
    const advisorLink = (
      <Link
        className="txt--highlight"
        onClick={(event) => event.stopPropagation()}
        to={{
          pathname: "/tools/advisor",
        }}>
        View details
      </Link>
    );

    if (stage.awsAccountId && stage.awsRegion) {
      if (cloudToolsPaymentRequired) {
        return [
          {
            id: 5,
            title: "Cloud Waste Manager",
            icon: <DollarIcon />,
            text: <div><div>Understand and save on your AWS spendings</div>{advisorLink}</div>,
            class: "width--double"
          },
          emptyItem(6)
        ]
      }
      else {
        const schedule = schedulesMap.get(stage.id);
        return [
          {
            id: 5,
            title: "Saving Schedule",
            icon: <DollarIcon />,
            text: schedule ? "ACTIVE" : "NOT ACTIVE",
            status: schedule ? "success" : ""
          },
          mapAdvisor(stage.id, stage.awsAccountId, stage.awsRegion)
        ]
      }
    }
    else {
      return [emptyItem(5), emptyItem(6)]
    }
  }

  function mapStateToIcon(state: string) {
    if (isStatusInProgress(state)) {
      return {
        id: 3,
        title: "Status",
        icon: <InProgressIcon />,
        text: status(state),
        status: "warning"
      };
    } else if (isStatusCompleted(state)) {
      return {
        id: 3,
        title: "Status",
        icon: <CheckmarkIcon />,
        text: status(state),
        status: "success"
      };
    } else if (isStatusFailed(state)) {
      return {
        id: 3,
        title: "Status",
        icon: <ErrorIcon width="11" height="11" />,
        text: status(state),
        status: "fail"
      };
    } else {
      return {
        id: 3,
        title: "Status",
        icon: <CheckmarkIcon />,
        text: status(state),
        status: ""
      };
    }
  }

  function mapCloudProviderToIcon(cloudProvider: string) {
    if (cloudProvider === "aws") {
      return {
        id: 1,
        title: "Cloud Provider",
        icon: <AWSLogo width="23px" height="23px" />,
        text: "Amazon Web Services"
      };
    }
    else if (cloudProvider === "azurerm") {
      return {
        id: 1,
        title: "Cloud Provider",
        icon: <AzureLogo width="23px" height="23px" />,
        text: "Microsoft Azure"
      }
    }
    else {
      return {
        id: 1,
        title: "Cloud Provider",
        icon: <GCPLogo width="23px" height="23px" />,
        text: "Google Cloud Platform"
      }
    }
  }

  function mapIaCToolToIcon(tool: string) {
    if (tool === "terraform") {
      return {
        id: 2,
        title: "IaC Tool",
        icon: <TerraformLogo width="23px" height="23px" className="pt--1" />,
        text: "Terraform"
      }
    }
    else {
      return {
        id: 2,
        title: "IaC Tool",
        icon: <CloudformationLogo width="23px" height="23px" />,
        text: "Cloudformation"
      }
    }
  }
  // Header Item(s)
  const createStageButton = <Button key="createStage" className="btn btn--md btn--link btn--blue m--0" onClick={checkPaymentLimitationAndHandleEnvironmentCreate}>Create Environment</Button>

  const EmptyBox = () => (
    <div className="page-centered page-centered--project" >
      <img src={EnvironmentScreen} alt="environment" />
      <div className="page-centered__title">Infrastucture Builder</div>
      <p className="txt--sm">
        Build and deploy custom cloud infrastructure with ready-to-use components. If that's not enough, you can always develop your custom ones using CloudFormation. It's easy as drag-and-drop infrastructure elements.
        <br />
        You can also create environment blueprints and replicate them with just a few clicks.
        <br />
        <br />
        {/* Environment is a place where components are combined and deployed in the Cloud, such as development and production.
        Combining multiple components will allow you to create custom cloud infrastructure for your applications.&nbsp; */}
        <a href="https://microtica.com/docs/build-your-own-cloud-infrastructure"
          target="_blank"
          rel="noreferrer">
          Read more about Infrastructure Builder
        </a>
      </p>
      <Link className="btn btn--xl btn--green" to="environments/create">Create Environment</Link>
    </div>
  );

  const showStages = (
    <CustomScrollbars maxHeight="calc(100vh - 120px)" resetClass="reset--top">
      <Card class="card card--stages dark">
        {stages.map(stage => {
          return (
            <Card
              class="card light card-shadow pointer"
              key={stage.id}
              onClick={() => props.history.push("/environments/" + stage.id, { advisor: advisor.get(stage.id) })}
            >
              <div className="card__header-wrapper">
                <CardHeader title={stage.name} text={stage.description} />
                <div className="d-flex align-items-center">
                  <Link
                    onClick={(event) => event.stopPropagation()}
                    className="btn btn--sm btn--deepdark"
                    to={`/environments/${stage.id}/deployment-insights`}
                  >
                    <InsightIcon />
                    &nbsp;&nbsp;Insights
                  </Link>
                  <DropdownMenuContainer actions={[
                    {
                      itemId: "1",
                      title: "Edit",
                      icon: <EditIcon />,
                      onClick: () => handleStageEdit(stage.id)
                    },
                    {
                      itemId: "2",
                      title: "Replicate",
                      icon: <ReplicateIcon />,
                      disabled: isStatusInProgress(stage.status),
                      onClick: () => handleStageReplication(stage)
                    },
                    {
                      itemId: "3",
                      title: "Undeploy",
                      icon: <UndeployIcon />,
                      disabled: isStatusReady(stage.status) || isStatusInProgress(stage.status),
                      onClick: () => handleStageUndeploy(stage.id)
                    },
                    {
                      itemId: "4",
                      title: "Delete",
                      type: "separator",
                      icon: <RemoveIcon />,
                      disabled: !isStatusReady(stage.status),
                      onClick: () => handleStageDelete(stage.id)
                    }
                  ]}
                  />
                </div>
              </div>

              <div className="stages">
                <StageStatus
                  class="stages__info-wrapper--list"
                  items={[
                    mapCloudProviderToIcon(stage.cloudProvider),
                    mapIaCToolToIcon(stage.infrastructureAsCodeTool),
                    mapStateToIcon(stage.status),
                    {
                      id: 4,
                      title: "Last Deployed",
                      icon: <ClockIcon />,
                      text: lastDeployed(stage)
                    },
                    ...handleCloudToolPaymentRequired(stage)
                  ].map(item => ({
                    ...item,
                    link: { pathname: "/environments/" + stage.id, state: {} },
                  }))}
                />
              </div>
            </Card>
          )
        })
        }
      </Card>
    </CustomScrollbars>
  );

  const Placeholders = () => (
    <Card class="card card--stages dark">
      <StagesListPlaceholders />
    </Card>
  );

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader title="Environments" items={[createStageButton]} />
      <div className="content__body">
        <React.Fragment>
          {!isProcessing ?
            stages && stages.length >= 1 ? showStages : <EmptyBox />
            : <Placeholders />
          }
        </React.Fragment>
      </div>
    </main>
  );
};

export default StagesList;