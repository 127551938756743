import * as React from 'react';

export interface RadioButtonProps {
  label: string;
  subLabel?: string;
  value: string;
  checked?: true | false;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.StatelessComponent<RadioButtonProps> = p => {

  return (
    <label className="radiobutton">
      <span className="radiobutton__text">{p.label}</span>
      {
        p.subLabel ?
          <span className="radiobutton__description" style={{ display: "block", paddingTop: "5px", fontSize: "small" }}>
            {p.subLabel}
          </span> :
          null
      }
      <input
        className="radiobutton__input"
        onChange={p.onChange}
        type="radio"
        name={p.label}
        value={p.value}
        checked={p.checked}
      />
      <span className="radiobutton__mark"></span>
    </label>
  )
};

export default RadioButton;