import * as constants from "../../constants";
import { getEngineAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { updateStageStatusSuccess, UpdateStageStatus } from "./update-stage";

export interface UndeployStageStart {
    type: constants.UNDEPLOY_STAGE_START;
}

export interface UndeployStageSuccess {
    type: constants.UNDEPLOY_STAGE_SUCCESS;
    stageId: string;
}

export interface UndeployStageFail {
    type: constants.UNDEPLOY_STAGE_FAIL;
    error: ErrorInterface;
}

export type UndeployStage =
    | UndeployStageStart
    | UndeployStageSuccess
    | UndeployStageFail;

export function undeployStageStart(): UndeployStage {
    return {
        type: constants.UNDEPLOY_STAGE_START
    };
}

export function undeployStageSuccess(
    stageId: string
): UndeployStageSuccess {
    return {
        type: constants.UNDEPLOY_STAGE_SUCCESS,
        stageId
    };
}

export function undeployStageFail(error: ErrorInterface): UndeployStageFail {
    return {
        type: constants.UNDEPLOY_STAGE_FAIL,
        error
    };
}

export function undeployStage(
    projectId: string,
    stageId: string
): ThunkAction<Promise<void>, {}, {}, UndeployStage> {
    return async (dispatch: ThunkDispatch<{}, {}, UndeployStage | UpdateStageStatus>) => {
        dispatch(undeployStageStart());
        try {
            const { data: { status } } = await getEngineAPI().undeployStage(stageId, projectId);
            dispatch(updateStageStatusSuccess(stageId, status));
        } catch (e) {
            dispatch(undeployStageFail(e.response.data));
        }
    };
}

