import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import StageStatus from "../../components/StageStatus/StageStatus";
import SearchBar from "../../components/SearchBar/SearchBar";
import Button from "../../components/Button/Button";

import { ReactComponent as InProgressIcon } from "../../static/inprogress-icon.svg";
import { ReactComponent as ErrorIcon } from "../../static/error-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";
import { ReactComponent as ExportIcon } from "../../static/export-icon.svg";

import { getEngineAPI, socketIO } from "../../api";
import { RouteComponentProps, StaticContext } from "react-router";

import { GlobalState } from "../../reducers";
import moment from "moment";
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";

import { toast } from 'react-toastify';
import { useModal } from "react-modal-hook";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import isStatusInProgress from "../../utils/isInProgress";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import Skeleton from "react-loading-skeleton";
import { ComponentInfrastructureAsCodeToolEnum, GetStageDeploymentEventsResponseEvents } from "@microtica/ms-engine-sdk";
import { Socket } from "socket.io-client";
import { trackCancelDeployment } from "../../tracking/deployment";

interface DeploymentDetailsProps extends RouteComponentProps<{ stageId: string }, StaticContext, {
  disabledInsights?: boolean;
}> {
  stageId: string;
}

const headers = [
  "Event time",
  "Resource",
  "Resource type",
  "Status",
  "Status reason"
];

const DeploymentDetails = (props: DeploymentDetailsProps) => {
  // Searchbar
  const stageId = props.match.params.stageId;
  const disabledInsights = props.location.state ? props.location.state.disabledInsights : false;
  const projectId = useSelector((stage: GlobalState) => stage.project.currentProject.id);
  const [cfnLogs, setCfnLogs] = useState<string[][]>([]);
  const [tfLogs, setTfLogs] = useState<string>("");
  const [events, setEvents] = useState<GetStageDeploymentEventsResponseEvents[]>([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [stageStatus, setStageStatus] = useState("n/a");
  const [lastDeployed, setLastDeployed] = useState("n/a");
  const [stageName, setStageName] = useState("n/a");
  const [deploymentId, setDeploymentId] = useState<string>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [socket, setSocket] = useState<Socket>();
  const [infrastructureAsCodeTool, setInfrastructureAsCodeTool] = useState("n/a");

  useEffect(() => {
    if (disabledInsights) {
      toast.info("Upgrade your plan to see more details for your deployments in the deployment insights page");
    }
  }, []);


  useEffect(() => {
    let tempLogs: string = "";
    if (socket !== undefined) {
      socket.on("logs", (cloudLogs: string) => {
        tempLogs += cloudLogs;
        setTfLogs(tempLogs)
      });
    }
  }, [socket])

  useEffect(() => {
    const timer = setInterval(() => {

      if (stageStatus.includes("FAILED") || stageStatus.endsWith("COMPLETE")) {
        clearInterval(timer);
      } else {
        fetchStageDetails();
        fetchEvents();
      }

    }, 5000);

    fetchStageDetails();
    fetchEvents();

    return () => { clearInterval(timer); };
  }, [stageStatus, stageId]);

  async function fetchStageDetails() {
    const { data: stage } = await getEngineAPI().getStageDetails(stageId, projectId);
    setInfrastructureAsCodeTool(stage.infrastructureAsCodeTool)
    setDeploymentId(stage.currentDeploymentId)
    // fetchEvents(stage.infrastructureAsCodeTool, stage.currentDeploymentId!)
    setStageStatus(stage.status);
    setLastDeployed(moment(stage.lastDeployed, "x").fromNow());
    setStageName(stage.name);
    setIsLoaded(true);
  }

  useEffect(() => {
    async function connectSocket() {
      if (deploymentId && infrastructureAsCodeTool === ComponentInfrastructureAsCodeToolEnum.Terraform) {
        await connectSocketIO(deploymentId);
      }
    }
    connectSocket();
  }, [deploymentId])

  useEffect(() => {
    const eventsList = events.map(event => ([
      event.timestamp.toString(),
      event.logicalResourceId,
      event.resourceType,
      event.resourceStatus.replace(/_/g, " "),
      event.statusReason ? event.statusReason : " "
    ]))

    const otherLogs = cfnLogs ? cfnLogs.filter(log => !eventsList.find(event =>
      JSON.stringify(log) === JSON.stringify(event)
    ))
      : [];

    const filteredLogs = eventsList
      .concat(otherLogs)
      .sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
      .filter(item => {
        if (searchedValue) {
          return item[3].toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase())
        }
        return true
      });

    setCfnLogs(filteredLogs);
  }, [searchedValue, events])

  async function fetchEvents() {
    if (infrastructureAsCodeTool === ComponentInfrastructureAsCodeToolEnum.Cloudformation) {
      const { events } = (await getEngineAPI().getStageDeploymentEvents(stageId, projectId)).data;
      setEvents(events);
    }
  }

  async function connectSocketIO(deploymentId: string) {
    try {
      const socket = await socketIO(
        {
          projectId: projectId,
          stageId,
          deploymentId,
          typeOfLogs: "terraform"
        }
      );
      setSocket(socket);
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function cancelStageDeployment() {
    try {
      await getEngineAPI().cancelDeploy(stageId, projectId);
      toast.warn("Deployment cancelled!");
    } catch (error) {
      toast.error(error.message);
    }
    hideCancelModal();
  }

  async function handleContinueRollback() {
    try {
      await getEngineAPI().continueRollback(stageId, projectId);
      fetchStageDetails();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const [showCancelModal, hideCancelModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to cancel deployment?`}
      description="Do you really want to cancel the deployment? This action cannot be undone."
      action="Yes"
      onCancel={hideCancelModal}
      onConfirm={cancelStageDeployment}
    />
  ), []);

  function mapStatusToIcon(status: string) {
    if (status.includes("PROGRESS")) {
      return {
        icon: <InProgressIcon />,
        status: "warning"
      }
    } else if (status.includes("COMPLETE")) {
      return {
        icon: <CheckmarkIcon />,
        status: "success"
      };
    } else if (status.includes("FAILED")) {
      return {
        icon: <ErrorIcon />,
        status: "fail"
      }
    } else {
      return {
        icon: <CheckmarkIcon />
      }
    }
  }

  // Page header button
  const viewStageButton =
    <Link to={`/environments/${stageId}`} key="viewStage">
      <Button className="btn btn--md btn--lightGreen" key="viewStage">
        View Environment
      </Button>
    </Link>

  // Cancel header button
  const cancelDeployButton =
    <Button onClick={() => { showCancelModal(); trackCancelDeployment(stageId) }} className="btn btn--cancel-deploy btn--danger" key="cancelDeploy">
      Cancel
    </Button>

  // Continue rollback header button
  const continueRollbackButton =
    <Button onClick={handleContinueRollback} className="btn btn--sm btn--danger" key="cancelDeploy">
      Continue Rollback
    </Button>

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        title={stageName}
        items={[viewStageButton]}
      />

      <div className="content__body ptb--20">
        <div className="details__status details__status--deployment">
          <StageStatus
            items={[
              {
                id: 1,
                title: "Status",
                text: isLoaded ? stageStatus.replace(/_/g, " ") : <Skeleton width="50px" />,
                ...mapStatusToIcon(stageStatus),
                button: stageStatus && isStatusInProgress(stageStatus) ?
                  cancelDeployButton : stageStatus === "UPDATE_ROLLBACK_FAILED" ? continueRollbackButton : undefined
              },
              {
                id: 2,
                title: "Last Deployed",
                icon: <ExportIcon />,
                text: isLoaded ? lastDeployed : <Skeleton width="50px" />,
              },
              // {
              //   id: 3,
              //   title: "Duration",
              //   icon: <ClockIcon />,
              //   text: "N/A",
              // },
            ]}
          />
        </div>
        <Card class="card card--build card--pipelines dark">
          <div className="d-flex justify-content-between align-items-center">
            <div className="searchbar-wrapper">
              <SearchBar
                placeholder="Search by status..."
                modifierClass="pr--0"
                value={searchedValue}
                onChange={e => setSearchedValue(e.target.value)}
              />
            </div>

          </div>
          <div className="table-wrapper-sticky">
            <CustomScrollbars maxHeight="65vh">
              {
                isLoaded && infrastructureAsCodeTool === "cloudformation" ? cfnLogs ?
                  <Table
                    headers={headers}
                    rows={cfnLogs.map(log => ([
                      isNaN(parseInt(log[0])) ? log[0] : moment(parseInt(log[0])).format("LLL"),
                      log[1],
                      log[2],
                      log[3],
                      log[4]
                    ]))}
                    isLoaded={isLoaded}
                  />
                  :
                  <Table
                    headers={headers}
                    rows={[]}
                    isLoaded={isLoaded}
                  /> : <code className="details__code-block details__code-block--lg">
                  {
                    isLoaded && tfLogs == "" ? <p>Preparing logs...</p> :
                      <pre style={{ color: "white" }}>
                        {tfLogs}
                      </pre>
                  }
                </code>
              }
            </CustomScrollbars>
          </div>
        </Card>
      </div>
    </main>
  )
}

export default DeploymentDetails;