import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import { RouteComponentProps } from "react-router-dom";

import { GlobalState } from "../../reducers";
import Button from "../../components/Button/Button";

import PageHeader from "../../components/PageHeader/PageHeader";
import PageContent from "../../components/PageContent/PageContent";
import Card from "../../components/Card/Card";
import { getEngineAPI } from "../../api";
import { InputField } from "../../components/InputField/InputField";
import { InputAreaField } from "../../components/InputTextarea/InputAreaField";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import { UpdateComponentRequestTypeEnum, CreateComponentRequestTypeEnum, CreateComponentRequestInfrastructureAsCodeToolEnum, UpdateComponentRequestInfrastructureAsCodeToolEnum, UpdateComponentRequestCloudProviderEnum, CreateComponentRequestCloudProviderEnum } from "@microtica/ms-engine-sdk";

// Import types and utils
import { CloudProvider, ComponentType, InfrastructureAsCodeTool } from "../../types";
import { componentSchema } from "../../utils/validation";
import componentTypes from "../../utils/component-types";
import cloudProviders from "../../utils/cloud-providers";
import cloudTools from "../../utils/infrastructure-as-code-tools";
import SelectPipeline from "../../components/Pipeline/SelectPipeline";
import CardHeader from "../../components/CardHeader/CardHeader";

interface CreateComponentProps
  extends RouteComponentProps<{ componentId: string }> {
  mode: "edit" | undefined;
}

const CreateComponent = (props: CreateComponentProps) => {
  // Hooks
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState<DropdownItem>();
  const [cloudTool, setCloudTool] = useState<DropdownItem>();
  const [cloudProvider, setCloudProvider] = useState<DropdownItem>();
  const currentProject = useSelector(
    (state: GlobalState) => state.project.currentProject
  );
  const [selectedPipeline, setSelectedPipeline] = useState<{ pipelineId: string, artifactStep: string }>();
  const [componentId] = useState(props.match.params.componentId);
  const [_, setCreateComponentTriggered] = useState(false);

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const awsProvider = cloudProviders.find(provider => provider.id === "Aws");

  useEffect(() => {
    if (props.mode === "edit") {
      const fetch = async () => {
        try {
          const { data: component } = await getEngineAPI().getComponent(componentId, currentProject.id);

          setName(component.name);
          setType(componentTypes.find(t => t.id === component.type));
          setCloudProvider(cloudProviders.find(provider => provider.value === component.cloudProvider)!);
          setCloudTool(cloudTools.find(tool => tool.value === component.infrastructureAsCodeTool)!);
          setDescription(component.description);
          setSelectedPipeline({
            pipelineId: component.pipelineId,
            artifactStep: component.artifactStep
          });

        } catch (error) {
          toast.error(error.response ? error.response.data.message : (error.message || "Something went wrong!"));
        }
      };
      fetch();
    }
  }, []);

  function resetCloudConfigs(cloudProvider: DropdownItem, setFieldValue: Function) {
    if (cloudProvider !== awsProvider) {
      const terrafomTool = cloudTools.find(tool => tool.id === "Terraform")!;
      setFieldValue("cloudTool", terrafomTool.id);
      setCloudTool(terrafomTool);
    }
  }

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        titlePosition="center"
        title={props.mode === "edit" ? "Update Component" : "Create New Component"}
      />
      <div className="content__body">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name,
            description,
            type: type ? type.id : "",
            pipelineId: selectedPipeline ? selectedPipeline.pipelineId : "",
            artifactStep: selectedPipeline ? selectedPipeline.artifactStep : "",
            cloudTool: cloudTool ? cloudTool.id : "",
            cloudProvider: cloudProvider ? cloudProvider.id : "",
          }}
          onSubmit={async values => {
            try {
              setCreateComponentTriggered(true);
              setIsBusy(true);
              if (componentId) {
                await getEngineAPI().updateComponent(componentId, currentProject.id, {
                  name: values.name,
                  description: values.description,
                  type: UpdateComponentRequestTypeEnum[type!.name as ComponentType],
                  artifactStep: selectedPipeline!.artifactStep,
                  pipelineId: selectedPipeline!.pipelineId,
                  infrastructureAsCodeTool: UpdateComponentRequestInfrastructureAsCodeToolEnum[values.cloudTool as InfrastructureAsCodeTool],
                  cloudProvider: UpdateComponentRequestCloudProviderEnum[values.cloudProvider as CloudProvider]
                });
                props.history.push(`/components`);
              } else {
                const { data: componentResponse } = await getEngineAPI().createComponent(
                  currentProject.id,
                  {
                    name: values.name,
                    description: values.description,
                    type: CreateComponentRequestTypeEnum[type!.name as ComponentType],
                    infrastructureAsCodeTool: CreateComponentRequestInfrastructureAsCodeToolEnum[values.cloudTool as InfrastructureAsCodeTool],
                    cloudProvider: CreateComponentRequestCloudProviderEnum[values.cloudProvider as CloudProvider],
                    pipelineId: selectedPipeline!.pipelineId,
                    artifactStep: selectedPipeline!.artifactStep
                  }
                );
                props.history.push(`/components/${componentResponse.componentId}`);
              }
            } catch (e) {
              toast.error(e.response.data.message);
              setCreateComponentTriggered(false);
            }
            setIsBusy(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={componentSchema}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <PageContent>
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader title="General" text="General information for the component" />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="name"
                          placeholder="Enter component name here"
                          label="Component name"
                          hasError={true}
                          disabled={componentId}
                          component={InputField}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("name", e.target.value);
                            setName(e.target.value)
                          }}
                        />
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="type"
                        >
                          {() => (
                            <>
                              <DropdownContainer
                                items={componentTypes}
                                label="Component type"
                                placeholder="Select component type"
                                info="A way to label components by type. The label will be displayed on different places in the portal. If you don't know what to select, choose Other"
                                selectedItem={type}
                                onSelectItem={item => {
                                  setType(item);
                                  setFieldValue("type", item.id);
                                }}
                              />
                              {
                                errors.type ?
                                  <div className="page-content__error">{errors.type}</div>
                                  : null
                              }
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="description"
                          className="input__field input--textarea"
                          placeholder="Enter component description here"
                          label="Component description"
                          hasError={true}
                          rows={4}
                          component={InputAreaField}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("description", e.target.value);
                            setDescription(e.target.value)
                          }}
                        />
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="cloudProvider"
                          label="Cloud provider"
                        >
                          {() => (
                            <div>
                              <DropdownContainer
                                items={cloudProviders}
                                label="Cloud provider"
                                placeholder="Select cloud provider"
                                selectedItem={cloudProvider}
                                onSelectItem={(item) => {
                                  setFieldValue("cloudProvider", item.id);
                                  setCloudProvider(item);
                                  resetCloudConfigs(item, setFieldValue);
                                }}
                              />
                              {
                                errors.cloudProvider ?
                                  <div className="page-content__error">{errors.cloudProvider}</div>
                                  : null
                              }
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="cloudTool"
                          label="Infrastructure as Code tool"
                        >
                          {() => (
                            <div>
                              <DropdownContainer
                                items={cloudTools}
                                label="Infrastructure as Code tool"
                                placeholder="Select Infrastructure as Code tool"
                                selectedItem={cloudTool}
                                onSelectItem={(item) => {
                                  setFieldValue("cloudTool", item.id);
                                  setCloudTool(item);
                                }}
                                disabled={cloudProvider && cloudProvider !== awsProvider}
                              />
                              {
                                errors.cloudTool ?
                                  <div className="page-content__error">{errors.cloudTool}</div>
                                  : null
                              }
                            </div>
                          )}
                        </Field>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="row justify-content-center mb--10">
                  <SelectPipeline
                    value={selectedPipeline}
                    onChange={setSelectedPipeline}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </PageContent>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  type="submit"
                  className="btn btn--md btn--lightBlue btn--create"
                  isBusy={isBusy}
                  busyText={componentId ? "Updating.." : "Creating.."}
                >
                  {componentId ? "Update Component" : "Create Component"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main >
  );
};

export default CreateComponent;
