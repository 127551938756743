import React, { useState } from "react";
import { FieldProps } from "formik";
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";

interface InputProps {
  type?: "password" | "text";
  icon?: string;
  info?: string;
  hasError?: boolean;
  label?: string;
  disabled?: boolean;
  dataPlaceholder?: string;
}

export const InputField: React.SFC<FieldProps<any> & InputProps> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const [showToggle] = useState(props.type === "password");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const errorMsg = touched[field.name] && errors[field.name];
  const { hasError, ...otherProps } = props;

  function togglePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <div className={`input ${hasError ? 'input--login' : ''}`}>
      {
        props.label ?
          <label className="input__label">
            {props.label}
            {
              props.info ? <span className="tooltip" data-tooltip={props.info}><InfoIcon /></span> : null
            }
          </label> : null
      }
      <input
        {...field}
        {...otherProps}
        type={passwordVisible ? "text" : props.type}
        className={`input__field input__field--${props.icon} ${errorMsg ? 'input__field--error' : ''} ${props.disabled ? 'input__field--disabled' : ''}`}
        style={{
          border: errorMsg ? "1px solid #f84737" : "",
          paddingRight: showToggle ? "65px" : ""
        }}
        onKeyPress={e => e.key === "Enter" && e.preventDefault()}
      />
      {
        showToggle ?
          <div className={`input__toggle ${props.label ? "input__toggle__label" : ""}`}
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? "Hide" : "Show"}
          </div> :
          null
      }

      {props.dataPlaceholder ? <span className="animated-placeholder">{props.dataPlaceholder}</span> : null}

      {/* {errorMsg ? <ExclamationIcon /> : null} */}
      {errorMsg ? <span className="input__field--error-msg">{errorMsg}</span> : null}
    </div>
  );
};
