import React, { useState, useEffect } from "react";
import {
  GetKubernetesStatusResponse,
  GetKubernetesStatusResponseMicroservicesStatus,
  ListKubernetesWithDetailsResponsePodStatus
} from "@microtica/ms-kube-sdk";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import ClustersSidemenu from "../../components/ClustersSidemenu/ClustersSidemenu";
import MicroserviceConfigurationModal from "../../components/ModalConfigure/MicroserviceConfigurationModal";
import PolarChart from "../../components/PieChart/PieChart";
import DonutChart from "../../components/DonutChart/DonutChart";
import Commit from "../../components/Commit/Commit";

// Import dropdown icons
import { ReactComponent as DeployIcon } from "../../static/deploy-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as KubernetesLogo } from "../../static/kubernetesIcon.svg";
import { ReactComponent as UploadIcon } from "../../static/upload-icon.svg";
import { ReactComponent as DeploymentBackground } from "../../static/deployment-bg.svg";
import { ReactComponent as DeploymentPerson } from "../../static/deployment-person.svg";
import { useModal } from "react-modal-hook";
import { toast } from "react-toastify";
import { getKubeAPI, getElasticSearchAPI } from "../../api";
import { RouteComponentProps } from "react-router";
import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";

import moment from "moment";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import Skeleton from "react-loading-skeleton";
import Animation from "../../components/Animations/Animations";
import Badge from "../../components/Badge/Badge";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ErrorInterface } from "../../types";
import { ServiceDeploymentResponseCommit } from "@microtica/ms-elasticsearch-sdk";
import TabMenuContainer, { Tab } from "../../components/TabMenuContainer/TabMenuContainer";

//Table headers
const tableHeaders = [
  "Name:left",
  "Pods",
  "Image Tag",
  "Last Deployed",
  "Status",
  "Commit:left",
  ""
];

interface StageClusterProps extends RouteComponentProps<{ clusterId: string }> {
}
const StageCluster = (props: StageClusterProps) => {
  const currentProjectId = useSelector((state: GlobalState) => state.project.currentProject.id);
  const [deploying] = useState(false);
  const [sidemenuClosed, setSidemenuClosed] = useState(true);
  const [kubernetes, setKubernetes] = useState<GetKubernetesStatusResponse>();
  const [accessUrl, setAccessUrl] = useState("");
  const [microserviceName, setMicroserviceName] = useState("");
  const [namespace, setNamespace] = useState<string | undefined>(undefined);
  const [tableRows, setTableRows] = useState<(string | number | JSX.Element)[][]>([]);
  const [commitInfoMap, setCommitInfoMap] = useState(new Map<string, ServiceDeploymentResponseCommit & { pipelineId: string }>());

  const [podsData, setPodsData] = useState<ListKubernetesWithDetailsResponsePodStatus>();
  const [nodesData, setNodesData] = useState<any>(null);
  const [microserviceRegistry, setMicroserviceRegistry] = useState("");
  const [microserviceIsPublic, setMicroserviceIsPublic] = useState<boolean>(false);
  const [error, setError] = useState<ErrorInterface>()
  const { clusterId } = props.match.params;

  // Set content as loaded
  const [isLoaded, setIsLoaded] = useState(false);
  const [rerenderTableRows, setRerenderTableRows] = useState(false);

  const [tabs, setTabs] = useState<any>([{ id: "", label: "" }]);
  const [selectedTab, setSelectedTab] = useState<Tab>({ id: "", label: "" });

  const fetchCommitInformations = async () => {
    let commitMap = new Map<string, ServiceDeploymentResponseCommit & { pipelineId: string }>();
    try {
      const { data: { response } } = await getElasticSearchAPI().getDeployedMicroservices(
        currentProjectId,
        clusterId
      )
      response.map(r => commitMap.set(r.id, r.commit as ServiceDeploymentResponseCommit & { pipelineId: string }));
      setCommitInfoMap(commitMap);

    } catch (err) {
      toast.error(err.response.data.message)
    }
  }

  const fetchKubernetes = async () => {
    try {
      const [{ data: kubeResponse }] = await Promise.all([
        getKubeAPI().getKubernetesStatus(
          clusterId,
          currentProjectId
        )
      ]);

      fetchCommitInformations();
      setKubernetes(kubeResponse);
      setPodsData(kubeResponse.podStatus);
      setAccessUrl(kubeResponse.endpoint);
      setNodesData(kubeResponse.nodesStatus);

      outerRadiusUpdate(); // Prevent pie charts outer radius from reseting
    } catch (err) {
      setError(err);
      toast.error(err.response.data.message)
    }
    // Mark content as loaded
    setIsLoaded(true);
  }

  useEffect(() => {
    fetchKubernetes();
  }, []);

  useEffect(() => {
    if (kubernetes) {
      // Set only the namespaces that currently have services deployed in them as tab options
      const distinctNamespacesInUse = kubernetes.microservicesStatus
        .map(service => service.namespace)
        .filter((value, index, self) => self.indexOf(value) === index);
      setTabs(distinctNamespacesInUse.map(namespaceName => ({ id: namespaceName, label: namespaceName })));
    }
  }, [kubernetes]);

  useEffect(() => {
    // Select the first item of the tabs (namespaces) once they are available & if the tab is not set yet ()
    if (tabs && tabs.length && selectedTab.id === "") {
      setSelectedTab(tabs[0]);
    }
  }, [tabs]);

  useEffect(() => {
    generateTableRows();
  }, [kubernetes, commitInfoMap])

  useEffect(() => {
    // Reset the table rows to empty array when tab (namespace) changes and Trigger re-rendering
    // This is done because the DropdownContainer somehow uses the row values from the items in the first tab
    // when setTableRows() is called directly with new values (on tab change)
    setTableRows([]);
    setRerenderTableRows(!rerenderTableRows);
  }, [selectedTab]);

  useEffect(() => {
    // Re-rendering for table rows
    generateTableRows();
  }, [rerenderTableRows]);

  useEffect(() => {
    outerRadiusUpdate(); // Prevent pie charts outer radius from reseting
  }, [podsData]);

  useEffect(() => {
    if (nodesData) {
      setCpuUsage([roundNumber(nodesData.utilization.averageCpuUtilization * 100), roundNumber(100 - nodesData.utilization.averageCpuUtilization * 100)]);
      setMemoryUsage([roundNumber(nodesData.utilization.averageMemoryUtilization * 100), roundNumber(100 - nodesData.utilization.averageMemoryUtilization * 100)]);
    }
  }, [nodesData]);

  const getMicroserviceStatus = (microservice: GetKubernetesStatusResponseMicroservicesStatus): string => {
    return microservice.isHealthy ?
      "ACTIVE" :
      microservice.isPending ?
        "PENDING" :
        "FAILED";
  }

  const [showDeployModal, hideDeployModal] = useModal(() => (
    <MicroserviceConfigurationModal
      microserviceName={microserviceName}
      namespace={namespace}
      microserviceRegistry={microserviceRegistry}
      microserviceIsPublic={microserviceIsPublic}
      clusterId={clusterId}
      onClose={() => {
        hideDeployModal();
        outerRadiusUpdate();
      }}
      onSuccess={() => {
        hideDeployModal();
        handleSidemenu();
        fetchKubernetes();
        toast.success(`Service ${microserviceName} has been deployed in ${clusterId} cluster`);
      }}
    />
  ), [microserviceName, namespace, props]);

  const [showUndeployModal, hideUndeployModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to undeploy "${microserviceName}" service?`}
      description="This action will delete the resource from the Cloud within this Kubernetes cluster."
      action="Undeploy"
      onCancel={hideUndeployModal}
      onConfirm={handleDeleteConfirmation}
    />
  ), [microserviceName, namespace, handleDeleteConfirmation]);

  const generateTableRows = () => {
    if (kubernetes && kubernetes.podStatus) {
      const rows = kubernetes.microservicesStatus
        .filter((ms: GetKubernetesStatusResponseMicroservicesStatus) => ms.namespace === selectedTab.id)
        .map((ms: GetKubernetesStatusResponseMicroservicesStatus) => {
          return [
            <Link to={`${clusterId}/namespaces/${ms.namespace}/${ms.name}/details`} style={{ color: "rgb(74, 144, 226)" }}>{ms.name}</Link>,
            <div
              style={{ minWidth: "35px" }}
              className={
                `${ms.podStatus.failedPods > 0 ? "txt--red" : ms.podStatus.pendingPods > 0 ? "txt--yellow" : ""}`
              }>
              {ms.podStatus.runningPods}/{ms.podStatus.totalPods}
            </div>,
            <span>
              {ms.version}
              <br />
              <small>{ms.imageRepository}</small>
            </span>,
            ms.lastDeployed ? moment(ms.lastDeployed).fromNow() : "n/a",
            <Badge name={getMicroserviceStatus(ms)} status={getMicroserviceStatus(ms)} />,
            isLoaded ?
              commitInfoMap.get(ms.id) ?
                <div className="pull--left">
                  <Commit
                    name={commitInfoMap.get(ms.id)!.name}
                    version={commitInfoMap.get(ms.id)!.version}
                    message={commitInfoMap.get(ms.id)!.message}
                    href={commitInfoMap.get(ms.id)!.version.includes("-") && commitInfoMap.get(ms.id)!.pipelineId ?
                      `/pipelines/${commitInfoMap.get(ms.id)!.pipelineId}/builds/${commitInfoMap.get(ms.id)!.version}`
                      : `https://bitbucket.org/${commitInfoMap.get(ms.id)!.repository}/commits/${commitInfoMap.get(ms.id)!.version}`}
                    target={commitInfoMap.get(ms.id)!.version.includes("-") && commitInfoMap.get(ms.id)!.pipelineId ? "" : "_blank"}
                    userName={commitInfoMap.get(ms.id)!.user.name}
                    userAvatar={commitInfoMap.get(ms.id)!.user.avatar}
                  />
                </div> :
                <div className="pull--left">
                  <div>No deployment information available</div>
                  {error && error.code === 402 ?
                    <Link
                      to="/settings?tab=billing"
                      className="txt--highlight pull--right"
                    >
                      Upgrade  plan
                    </Link> : <></>}
                </div>
              :
              <div className="pull--left">
                <div className="mb--5"><Skeleton width="150px" /></div>
                <div><Skeleton width="150px" /></div>
              </div>,
            <DropdownMenuContainer
              actions={[
                { itemId: ms.name, title: "Deploy", icon: <DeployIcon />, onClick: () => handleDeploy(ms.name, ms.registry, !!ms.isPublic, ms.namespace) },
                { itemId: ms.name, title: "Undeploy", icon: <RemoveIcon />, onClick: () => handleRemove(ms.name, ms.namespace, !!ms.isPublic) }
              ]}
            />
          ];
        });
      setTableRows(rows);
    }
  }

  async function handleDeleteConfirmation() {
    try {
      const { data: response } = microserviceIsPublic ?
        await getKubeAPI().undeployPublicMicroservice(
          microserviceName,
          clusterId,
          namespace!,     // Will always have a non-empty value because this action is only allowed from the table view (namespace always has a value there)
          currentProjectId) :
        await getKubeAPI().undeployMicroservice(
          microserviceName,
          namespace!,     // Will always have a non-empty value because this action is only allowed from the table view (namespace always has a value there)
          clusterId,
          currentProjectId);
      if (response.done) {
        toast.success(`Successfully undeployed ${microserviceName} service`);
        outerRadiusUpdate();
        // trackUndeployMicroservice(true, stageId, clusterId, microserviceName)
      }
    } catch (err) {
      setError(err)
      toast.error(err.response.data.message);
      // trackUndeployMicroservice(false, stageId, clusterId, microserviceName);
    }
    fetchKubernetes();
    hideUndeployModal();
  }

  // Pie charts values
  const [deploymentPieValues, setDeploymentPieValues] = useState([0, 0]);
  const [podsPieValues, setPodsPieValues] = useState([0, 0, 0]);

  // Handle deployment when clicked "Deploy" button on the modal
  let [deploymentProgress] = useState(0);

  const handleSidemenu = () => {
    if (sidemenuClosed) {
      // If the sidemenu is closed - open it
      setSidemenuClosed(false);
      document.body.style.overflow = "hidden";
    } else {
      setSidemenuClosed(true);
      document.body.style.overflow = "visible";
    }

    outerRadiusUpdate();
  }

  // Prevent pie chart outer radius from reseting when Deployment sidemenu is open/closed
  const outerRadiusUpdate = () => {
    // Re-set the pies state with the same values

    if (podsData) {
      const running = Math.round((podsData.runningPods + podsData.succeededPods) / podsData.totalPods * 100);
      const failed = Math.floor(podsData.failedPods / podsData.totalPods * 100);
      const pending = Math.floor(podsData.pendingPods / podsData.totalPods * 100);

      let totalDeployments = 0;
      let runningDeployments = 0;
      let failedDeployments = 0;

      for (let [, value] of Object.entries(kubernetes!.microservicesStatus)) {
        totalDeployments++;
        if (!value.isHealthy) {
          failedDeployments++
        } else {
          runningDeployments++;
        }
      }

      runningDeployments = Math.round(runningDeployments / totalDeployments * 100);
      failedDeployments = Math.round(failedDeployments / totalDeployments * 100);

      setDeploymentPieValues([runningDeployments, failedDeployments, 0]);
      setPodsPieValues([running, failed, pending]);
    }
  }

  const handleDeploy = (microserviceName: string, microserviceRegistry: string, isPublic: boolean, namespace?: string) => {
    setNamespace(namespace);
    setMicroserviceName(microserviceName);
    setMicroserviceRegistry(microserviceRegistry);
    setMicroserviceIsPublic(isPublic);
    // trackDeployMicroserviceTrigger(stageId, clusterId, microservice);
    showDeployModal();
    outerRadiusUpdate();
  }

  // Donut chart progress (CPU, Memory)
  /*
    NOTE: cpuUsage and memoryUsage values are in opposite direction, 
          as value A is increasing, value B should be decreasing for the ammount of value A and vice-versa.
  */
  const [cpuUsage, setCpuUsage] = useState([25.5, 74.5])       // Test values - will be dynamic
  const [memoryUsage, setMemoryUsage] = useState([80, 20])     // Test values - will be dynamic

  // Update CPU Usage
  const updateCpuUsage = () => {
    if (cpuUsage[0] >= 95) {
      setCpuUsage([100, 0]);
    } else {
      setCpuUsage([cpuUsage[0] + 5, 100 - cpuUsage[0]])
    }
  }

  const roundNumber = (num: number) => {
    return Math.round(num * 100) / 100;
  }

  // Update Memory Usage
  const updateMemoryUsage = () => {
    if (memoryUsage[0] >= 95) {
      setMemoryUsage([memoryUsage[0] - 5, 0 + memoryUsage[0]])
    } else {
      setMemoryUsage([memoryUsage[0] + 5, 100 - memoryUsage[0]])
    }
  }

  const handleRemove = (microservice: string, namespace: string, isPublic?: boolean) => {
    setNamespace(namespace)
    setMicroserviceName(microservice);
    setMicroserviceIsPublic(!!isPublic);
    showUndeployModal();
  }

  const noKubernetesDashboard = (
    <div className="page-centered page-centered--project" style={{ paddingTop: "10%" }}>
      <KubernetesLogo width="20%" height="20%" />
      <br />
      <p className="txt--lg" style={{ fontSize: "20px" }}>
        Your 30 days trial for Microtica Kubernetes Dashboard has just expired.
        Upgrade to Professional Plan so you can continue use all the benefits.
        Remember that your Kubernetes cluster will still continue to run normally and you can still deploy your services through Service details -&gt; Add to Cluster
        <br></br>
      </p>
      <Link className="btn btn--xl " to="/settings?tab=billing">
        <Button className="btn btn--xl ">

          Upgrade Now!
        </Button>
      </Link>
    </div>
  );

  return (
    <main className="content d-flex flex-column justify-content-start pr--55">
      <PageHeader
        title={isLoaded ? kubernetes ? kubernetes.name : "Cluster name" : <Skeleton width="70px" height="30px" />}
        items={[<KubernetesLogo key="kubeLogo" />]}
      />

      <div className="content__body ptb--30">

        {
          error && error.code === 402 ? noKubernetesDashboard : <Card class="card card--clusters-container dark pl--20 pr--20">

            <div className="card__header-wrapper card__header-wrapper--clusters card--underline">
              <div className="row width--full">
                {/* Total Nodes */}
                <div className="col-12 col-md-6 col-lg-2">
                  <h5><strong>Total Nodes</strong></h5>
                  <p className="m--0">{isLoaded ? nodesData ? nodesData.totalNodes : "n/a" : <Skeleton width="70px" />}</p>
                </div>

                {/* Running Pods */}
                <div className="col-12 col-md-6 col-lg-2">
                  <h5><strong>Running Pods</strong></h5>
                  <p className="m--0">{isLoaded ? podsData ? podsData.runningPods : "n/a" : <Skeleton width="70px" />}</p>
                </div>

                {/* Pending Pods */}
                <div className="col-12 col-md-6 col-lg-2">
                  <h5 className={podsData && podsData.pendingPods > 0 ? "txt--yellow" : ""}><strong>Pending Pods</strong></h5>
                  <p className="m--0">{isLoaded ? podsData ? podsData.pendingPods : "n/a" : <Skeleton width="70px" />}</p>
                </div>

                {/* Failed Pods */}
                <div className="col-12 col-md-6 col-lg-2">
                  <h5 className={podsData && podsData.failedPods > 0 ? "txt--red" : ""}><strong>Failed Pods</strong></h5>
                  <p className="m--0">{isLoaded ? podsData ? podsData.failedPods : "n/a" : <Skeleton width="70px" />}</p>
                </div>

                {/* Kubernetes API URL */}
                <div className="col-12 col-md-6 col-lg-3">
                  <h5><strong>Kubernetes API URL</strong></h5>
                  <p className="m--0">
                    {isLoaded ? accessUrl ? <a href={accessUrl} target="_blank" rel="noreferrer">{accessUrl}</a> : "n/a" : <Skeleton width="70px" />}
                  </p>
                </div>

              </div>
            </div>

            {/* Pie charts - will be changed with charts*/}
            {isLoaded ?
              <div className="d-flex justify-content-around align-items-center">
                {podsData && podsData.totalPods ?
                  <React.Fragment>
                    <DonutChart title="CPU" percentage={cpuUsage[0]} usage={cpuUsage} />
                    <DonutChart title="Memory" percentage={memoryUsage[0]} usage={memoryUsage} />

                    <PolarChart
                      title="Deployments"
                      metaNumber={0}
                      pieValues={deploymentPieValues}
                    />
                    <PolarChart
                      title="Pods"
                      metaNumber={1}
                      pieValues={podsPieValues}
                    />
                  </React.Fragment>
                  :
                  <Animation show={true} type="fade" itemIndex={1}>
                    <h5 className="p--20 txt--grey txt--center width--full">No data available.</h5>
                  </Animation>
                }
              </div>
              : null}

            {/* Table */}
            <Card class="card card--clusters light">
              <h5 className="pb--10">
                <KubernetesLogo width="20px" height="20px" />
                <span className="pl--10" style={{ verticalAlign: "text-bottom" }}>
                  <strong>Namespaces</strong>
                </span>
              </h5>
              <div className="card__header-wrapper card__header-wrapper--lists">
                <TabMenuContainer
                  key={selectedTab.id}
                  class="tab-menu--namespaces"
                  tabs={tabs}
                  value={selectedTab}
                  onClick={setSelectedTab}
                />
              </div>
              <Table
                headers={tableHeaders}
                rows={tableRows}
                isLoaded={isLoaded}
              />
            </Card>
          </Card>
        }
      </div>

      {/* Microservices clusters sidemenu */}
      <ClustersSidemenu
        onClick={(item) => handleDeploy(item.title, item.registry, !!item.isPublic, kubernetes!.associatedNamespace || undefined)}
        sidemenuClosed={sidemenuClosed}
        handleSidemenu={handleSidemenu}
        setSidemenuClosed={setSidemenuClosed}
      />

      {/* Deploying Modal */}
      {
        deploying ?
          <React.Fragment>
            <div className="page-overlay"></div>

            <div className="modal modal--deploy">
              <h3><strong>Deploying</strong></h3>
              <p>Sed do eiusmod tempor incididunt Labore et dolore magna aliqua</p>

              <div className="p--relative">
                <div className="deployment__bar">
                  <UploadIcon />
                  <div className="deployment__progress" style={{ width: `${deploymentProgress}%` }}></div>
                </div>
                <DeploymentBackground className="modal--deploy-image modal--deploy-bg" />
                <DeploymentPerson className="modal--deploy-image modal--deploy-person" />
              </div>
            </div>
          </React.Fragment>
          : null
      }
    </main >
  )
}

export default StageCluster;