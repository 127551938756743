import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import * as actions from "../../actions";
import { Project } from "@microtica/ms-project-sdk";

// Import components
import ProjectBox from "../../components/ProjectBox/ProjectBox";
import PageHeader from "../../components/PageHeader/PageHeader";
import SearchBar from "../../components/SearchBar/SearchBar";

import { useModal } from "react-modal-hook";
import CreateProjectModal from "./CreateProjectModal";
import useProjectList from "../../hooks/ProjectList";
import Button from "../../components/Button/Button";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { deleteProject } from "../../actions";
import ProjectBoxSkeleton from "../../components/ProjectBox/ProjectBoxSkeleton";
import { GlobalState } from "../../reducers";

export interface ProjectsProps extends RouteComponentProps { }

interface ProjectListItem {
  itemId: string;
  title: string;
  description: string;
  stages: string;
  components: string;
  logo: string;
  users: {
    name: string;
    image: string;
  }[];
}

const Projects = (props: ProjectsProps) => {
  const dispatch = useDispatch();
  const { filteredProjects, filter, setFilter, isProcessing, error } = useProjectList();
  const [selectedProject, setSelectedProject] = useState<Project>()

  const { projectCreated, projects } = useSelector((state: GlobalState) => state.project);

  useEffect(() => {

    if (projectCreated) {
      handleProjectSelect({
        itemId: projects[projects.length - 1].id,
        title: projects[projects.length - 1].name,
        description: projects[projects.length - 1].description,
      })
    }
  }, [projectCreated])


  const [showModal, hideModal] = useModal(() => (
    <CreateProjectModal
      onClose={hideModal}
      project={selectedProject!}
    />
  ), [selectedProject]);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedProject!.name}" project?`}
      description="Do you really want to delete the project? This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleProjectRemove}
    />
  ), [selectedProject]);


  const handleProjectSelect = (item: Partial<ProjectListItem>) => {
    dispatch(actions.setCurrentProject({
      id: item!.itemId!,
      name: item!.title!,
      description: item!.description!
    }));
    props.history.push({ pathname: "/overview" });
  };

  const handleProjectEdit = (itemId: string) => {
    const selectedProject = filteredProjects.find(p => p.itemId === itemId);
    setSelectedProject(selectedProject);
    showModal();
  }

  const handleProjectRemove = async () => {
    dispatch(deleteProject(selectedProject!.id, selectedProject!.name));
    hideConfirmationModal();
  }

  // Header item(s)
  const createNewProjectButton = <Button key="createProject" className="btn btn--md btn--link btn--blue m--0" onClick={() => {
    setSelectedProject(undefined);
    showModal();
  }}>Create new project</Button>

  const EmptyBox = () => (
    <div className="page-centered page-centered--project" >
      {/* <img src={EnvironmentScreen} /> */}
      <br />
      <h3>Welcome to Microtica!</h3>
      <br />

      <Button className="btn btn--xl btn--green" onClick={() => {
        setSelectedProject(undefined);
        showModal()
      }}>Start Building!</Button>
      <br />
      <br />


      <div className="feature-box">
        <h3>Infrastructure Builder</h3>
        <p className="feature-box__subtitle">
          Build and deploy custom cloud infrastructure with ready-to-use components.
          <br />
          You can also deploy your custom infrastructure using CloudFormation. It's easy as drag-and-drop infrastructure elements.
          <br />
          Create blueprints and replicate environments with just a few clicks.
          <br />
          <a href="https://microtica.com/docs/build-your-own-cloud-infrastructure"
            target="_blank"
            rel="noreferrer">
            Read more about Infrastructure Builder
          </a>
        </p>
      </div>
      <div className="feature-box">
        <h3>Pipeline Manager</h3>
        <p className="feature-box__subtitle">
          Automate the process of building, testing and deploying your infrastructure and apps from source code to production.
          <br />
          <a href="https://microtica.com/docs/pipeline-introduction/"
            target="_blank"
            rel="noreferrer">
            Read more about Pipelines
          </a>
        </p>
      </div>
      <div className="feature-box">
        <h3>Kubernetes Dashboard</h3>
        <p className="feature-box__subtitle">
          Setup a complete Kubernetes infrastructure or connect your existing cluster. Configure, deploy and monitor applications from a single place. Fully integrated with Microtica pipelines so you can easily define your automation to build, test and deploy applications on Kubernetes.
          <br />
          <a href="https://microtica.com/docs/deploy-to-kubernetes/"
            target="_blank"
            rel="noreferrer">
            Read more
          </a>
        </p>
      </div>
      <div className="feature-box">
        <h3>Cloud Cost Optimization</h3>
        <p className="feature-box__subtitle">
          Reduce cloud costs for non-production environments. Define custom-scheduled sleep cycles. Once saving mode is activated, Microtica goes in autopilot mode and takes care of the rest. If you need the cloud in a certain moment, you can bring it back manually right away
          <br />
          <a href="https://microtica.com/aws-cost/"
            target="_blank"
            rel="noreferrer">
            Read more about Cost Optimizer
          </a>
        </p>
      </div>
      <br />

      {/* <Button className="btn btn--xl btn--green" onClick={() => showModal()}>Start Building!</Button> */}
    </div >
  );

  return (
    <main className="content content--projects d-flex flex-column justify-content-start">
      {projects.length === 0 ?
        null
        :
        <PageHeader
          title="Projects"
          items={[createNewProjectButton]}
        />
      }

      {projects.length !== 0 ?
        <div className="card card--small dark">
          <div className="searchbar-wrapper">
            <SearchBar
              placeholder="Search project"
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              modifierClass="searchbar_padding"
            />
          </div>
        </div> : null
      }


      <div className="content__body">
        {isProcessing ? <ProjectBoxSkeleton /> :
          projects.length === 0 ?
            <EmptyBox />
            :
            filteredProjects.length === 0 ?
              <h2 className="page-centered--project p--20 txt--grey txt--center width--full" style={{ marginTop: "35px" }}>No projects found with that name or description</h2> :
              <div className="row row--projects">
                <div className="col-12">
                  <div className="projects__header">
                    <p className="m--0">
                      <strong>Total Projects: {filteredProjects.length}</strong>
                    </p>
                  </div>
                </div>

                {
                  filteredProjects.map((project: any, index: number) => (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb--30"
                      key={project.itemId}>
                      <ProjectBox
                        {...project}
                        onEditItem={() => handleProjectEdit(project.itemId)}
                        onRemoveItem={() => { setSelectedProject(filteredProjects.find(p => p.id === project.itemId)); showConfirmationModal() }}
                        onSelectItem={() => handleProjectSelect(project)} />
                    </div>
                  ))}
              </div>
        }
      </div>

    </main>
  );
};

export default Projects;
