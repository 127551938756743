
import React, { useState, useEffect } from "react";
import ModalInput from "../../components/ModalInput/ModalInput";
import Button from "../Button/Button";
import { AddAwsAccountRequest, AddAzureAccountRequest, AddGcpAccountRequest, UpdateAwsAccountRequest, UpdateAzureAccountRequest, UpdateGcpAccountRequest } from "@microtica/ms-aws-sdk";
import { useDispatch, useSelector } from "react-redux";
import { connectAwsAccount, connectAwsAccountClear } from "../../actions/accounts/aws/connect-aws-account";
import { GlobalState } from "../../reducers";
import { ReactComponent as AWSLogo } from "../../static/aws-logo.svg";
import { ReactComponent as GCPLogo } from "../../static/google-cloud-logo.svg";
import { ReactComponent as AzureLogo } from "../../static/azure-logo.svg";
import { connectAzureAccount, connectAzureAccountClear, updateAwsAccount, updateAzureAccount } from "../../actions";
import { Formik, Form, Field } from "formik";
import { createAwsSchema, createAzureSchema, createGcpSchema } from "../../utils/validation";
import { InputField } from "../InputField/InputField";
import { toast } from "react-toastify";
import DropdownContainer from "../DropdownContainer/DropdownContainer";
import { connectGcpAccount, connectGcpAccountClear } from "../../actions/accounts/gcp/connect-gcp-account";
import { updateGcpAccount } from "../../actions/accounts/gcp/update-gcp-account";
import { trackSpecCloudAccountInit } from "../../tracking/user-settings";

export interface CloudAccountModalProps {
    awsAccount?: UpdateAwsAccountRequest;
    gcpAccount?: UpdateGcpAccountRequest & { gcpProjectId?: string };
    azureAccount?: UpdateAzureAccountRequest;
    cfnLink?: string;
    cloudAccountType?: CloudAccountType,
    onClose: () => void;
}

export enum CloudAccountType {
    AWS = "AWS",
    GOOGLE_CLOUD_PLATFORM = "Google Cloud Platform",
    MICROSOFT_AZURE = "Microsoft Azure"
}

const cloudAccountTypes = [
    {
        id: "0",
        name: CloudAccountType.AWS,
        logo: <AWSLogo />,
        type: "aws"
    },
    {
        id: "1",
        name: CloudAccountType.GOOGLE_CLOUD_PLATFORM,
        logo: <GCPLogo />,
        type: "gcp"
    },
    {
        id: "2",
        name: CloudAccountType.MICROSOFT_AZURE,
        logo: <AzureLogo />,
        type: "azure"
    }
];
export default function CloudAccountModal(props: CloudAccountModalProps) {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { awsAccounts, awsAccountConnected, error: errorAws } = useSelector((state: GlobalState) => state.awsAccount);
    const { gcpAccounts, gcpAccountConnected, error: errorGcp } = useSelector((state: GlobalState) => state.gcpAccount);
    const { azureAccounts, azureAccountConnected, error: errorAzure } = useSelector((state: GlobalState) => state.azureAccount);
    const [cancelModalFlag, setCancelModalFlag] = useState(false);
    const [cfnLink] = useState(props.cfnLink || "https://console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/quickcreate?stackName=MicroticaCrossAccountAccess&templateURL=https://microtica.s3.eu-central-1.amazonaws.com/assets/aws/cloudformation/MicroticaCrossAccountAccess.json")
    const externalIdValue: string = "externalIdValue"                   // Placeholder value for AWS externalId
    const clientSecretPlaceholder: string = "clientSecretPlaceholder"   // Placeholder value for Azure clientSecret
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const [cloudAccountType, setCloudAccountType] = useState(props.cloudAccountType ? cloudAccountTypes.find(type => type.name === props.cloudAccountType)! : cloudAccountTypes[0]);


    useEffect(() => {
        trackSpecCloudAccountInit(cloudAccountType.type)
    }, [cloudAccountType])

    const connectCloudAccount = (values: any) => {
        setIsBusy(true);
        if (cloudAccountType.name === CloudAccountType.AWS) {
            // Handle AWS
            const { awsRoleArn, awsExternalId, awsAccountName } = values;
            if (props.awsAccount) {
                const updateAccount: UpdateAwsAccountRequest = {
                    id: awsRoleArn.split(":")[4],
                    iamRole: awsRoleArn,
                    externalId: awsExternalId.includes(externalIdValue) ? undefined : awsExternalId,
                    accountName: awsAccountName
                }
                dispatch(updateAwsAccount(currentProject.id, props.awsAccount.id!, updateAccount))
            } else {
                const addAccount: AddAwsAccountRequest = {
                    id: awsRoleArn.split(":")[4],
                    iamRole: awsRoleArn,
                    externalId: awsExternalId,
                    accountName: awsAccountName
                }
                dispatch(connectAwsAccount(currentProject.id, addAccount));
            }
        } else if (cloudAccountType.name === CloudAccountType.GOOGLE_CLOUD_PLATFORM) {
            // Handle GoogleCloud
            const { targetServiceAccount, projectName } = values;
            if (props.gcpAccount) {
                const updateAccount: UpdateGcpAccountRequest = {
                    targetServiceAccount,
                    projectName
                }
                dispatch(updateGcpAccount(props.gcpAccount.gcpProjectId!, currentProject.id, updateAccount))
            } else {
                const addAccount: AddGcpAccountRequest = {
                    targetServiceAccount,
                    projectName
                }
                dispatch(connectGcpAccount(currentProject.id, addAccount));
            }
        } else if (cloudAccountType.name === CloudAccountType.MICROSOFT_AZURE) {
            // Handle Azure
            const { tenantId, subscriptionId, applicationId, subscriptionName, clientSecret } = values;
            if (props.azureAccount) {
                const updateAccount: UpdateAzureAccountRequest = {
                    tenantId,
                    subscriptionId,
                    applicationId,
                    subscriptionName,
                    clientSecret: clientSecret.includes(clientSecretPlaceholder) ? undefined : clientSecret
                }
                dispatch(updateAzureAccount(props.azureAccount.tenantId, props.azureAccount.subscriptionId, currentProject.id, updateAccount))
            } else {
                const addAccount: AddAzureAccountRequest = {
                    tenantId,
                    subscriptionId,
                    applicationId,
                    subscriptionName,
                    clientSecret
                }
                dispatch(connectAzureAccount(currentProject.id, addAccount));
            }
        }
        setCancelModalFlag(true);
    };

    useEffect(() => {
        if (errorAws.message && cancelModalFlag) {
            setIsBusy(false);
            const defaultErrorMessage = "We cannot establish a connection with the AWS provider.\nPlease check if your IAM role ARN and external ID are correct and try again.";
            toast.error(errorAws.message || defaultErrorMessage);
        }
    }, [errorAws])

    useEffect(() => {
        if (errorGcp.message && cancelModalFlag) {
            setIsBusy(false);
            const defaultErrorMessage = "We cannot establish a connection with the Google Cloud Platform provider.\nPlease check if your input is correct and try again.";
            toast.error(errorGcp.message || defaultErrorMessage);
        }
    }, [errorGcp])

    useEffect(() => {
        if (errorAzure.message && cancelModalFlag) {
            setIsBusy(false);
            const defaultErrorMessage = "We cannot establish a connection with the Microsoft Azure provider.\nPlease check if your input is correct and try again.";
            toast.error(errorAzure.message || defaultErrorMessage);
        }
    }, [errorAzure])

    useEffect(() => {
        if (awsAccountConnected) {
            toast.success("You have successfully connected your AWS account.");
            dispatch(connectAwsAccountClear());
        }
    }, [awsAccountConnected])

    useEffect(() => {
        if (gcpAccountConnected) {
            toast.success("You have successfully connected your Google Cloud Platform account.");
            dispatch(connectGcpAccountClear());
        }
    }, [gcpAccountConnected])

    useEffect(() => {
        if (azureAccountConnected) {
            toast.success("You have successfully connected your Microsoft Azure account.");
            dispatch(connectAzureAccountClear());
        }
    }, [azureAccountConnected])

    useEffect(() => {
        if (cancelModalFlag) {
            props.onClose && props.onClose();
        }
    }, [awsAccounts, gcpAccounts, azureAccounts])

    useEffect(() => {
        // If cloud account is provided (EDIT), show proper form
        // eg. If gcpAccount is provided -> Display GCP Input Form (with the account's values in the form)
        // TODO: Find a better way to set account type. ex. ENUM
        props.awsAccount && setCloudAccountType(cloudAccountTypes[0]);
        props.gcpAccount && setCloudAccountType(cloudAccountTypes[1]);
        props.azureAccount && setCloudAccountType(cloudAccountTypes[2]);
    }, [props.awsAccount, props.gcpAccount, props.azureAccount])

    const handleCloudAccountTypeChange = (item: any) => {
        setCloudAccountType(item);
    }

    const renderAwsForm = () => {
        return <>
            {cloudAccountType.name === CloudAccountType.AWS && <>
                {
                    !props.awsAccount ?
                        <p className="modal__text txt--white txt--center">
                            1. Login into your AWS account
                            <br />
                            2. <a href={cfnLink} target="_blank" rel="noreferrer">Create new IAM Role</a>
                            <br />
                            3. Wait for CloudFormation to finish
                            <br />
                            4. Copy the value of <i>MicroticaRoleArn</i> CloudFormation output parameter in Role ARN
                        </p> : <br />
                }
                <Field
                    name="awsAccountName"
                    placeholder="e.g. Dev Account"
                    type="text"
                    hasError={true}
                    label="Account Name"
                    component={InputField}
                />
                <Field
                    name="awsRoleArn"
                    placeholder="e.g. arn:aws:iam::123456789087:role/MicroticaCrossAccountRole"
                    type="text"
                    hasError={true}
                    label="Role ARN"
                    component={InputField}
                />
                <Field
                    name="awsExternalId"
                    placeholder="e.g. my_secret"
                    hasError={true}
                    type="password"     // TODO: Do we set this as 'password'
                    label="External ID"
                    autoComplete="new-password"
                    component={InputField}
                />
            </>
            }
        </>
    }

    const renderGcpForm = () => {
        return <>
            {cloudAccountType.name === CloudAccountType.GOOGLE_CLOUD_PLATFORM && <>
                <br />
                <Field
                    name="projectName"
                    placeholder="e.g. MyProject"
                    type="text"
                    hasError={true}
                    label="Account Name"
                    component={InputField}
                />
                <Field
                    name="targetServiceAccount"
                    placeholder="e.g. client-service-acc@thematic-mapper-274117.iam.gserviceaccount.com"
                    type="text"
                    hasError={true}
                    label="Service Account"
                    component={InputField}
                />
            </>
            }
        </>
    }

    const renderAzureForm = () => {
        return <>
            {cloudAccountType.name === CloudAccountType.MICROSOFT_AZURE && <>
                <br />
                <Field
                    name="subscriptionName"
                    placeholder="e.g. DevSubscription"
                    type="text"
                    hasError={true}
                    label="Account Name"
                    component={InputField}
                />
                <Field
                    name="tenantId"
                    placeholder="e.g. b1761c3a-fe72-463b-af5c-1d32084e8933"
                    type="text"
                    hasError={true}
                    label="Microsoft Azure Tenant ID"
                    component={InputField}
                    disabled={props.azureAccount}
                />
                <Field
                    name="subscriptionId"
                    placeholder="e.g. b1761c3a-fe72-463b-af5c-1d32084e8933"
                    type="text"
                    hasError={true}
                    label="Microsoft Azure Subscription ID"
                    component={InputField}
                    disabled={props.azureAccount}
                />
                <Field
                    name="applicationId"
                    placeholder="e.g. b1761c3a-fe72-463b-af5c-1d32084e8933"
                    type="text"
                    hasError={true}
                    label="Microsoft Azure Application ID"
                    component={InputField}
                />
                <Field
                    name="clientSecret"
                    placeholder="e.g. Ahb-Va.MOpDe1~XJg~Y~vS989QWFnbfvPw"
                    type="password"
                    autoComplete="new-password"
                    hasError={true}
                    label="Microsoft Azure Client Secret"
                    component={InputField}
                />
            </>
            }
        </>
    }

    const renderInitialValues = () => {
        if (props.awsAccount) {
            // Render default values for AWS account
            return {
                awsRoleArn: props.awsAccount.iamRole,
                awsExternalId: externalIdValue,
                awsAccountName: props.awsAccount.accountName
            }
        } else if (props.gcpAccount) {
            // Render default values for GCP account
            return {
                targetServiceAccount: props.gcpAccount.targetServiceAccount,
                projectName: props.gcpAccount.projectName
            }
        } else if (props.azureAccount) {
            // Render default values for Azure account
            return {
                tenantId: props.azureAccount.tenantId,
                subscriptionId: props.azureAccount.subscriptionId,
                applicationId: props.azureAccount.applicationId,
                subscriptionName: props.azureAccount.subscriptionName,
                clientSecret: clientSecretPlaceholder
            }
        }
        // Values must exist in order for the form to be a controlled component
        return {
            awsRoleArn: "",
            awsExternalId: "",
            awsAccountName: "",

            gcpProjectId: "",
            targetServiceAccount: "",
            projectName: "",

            tenantId: "",
            subscriptionId: "",
            applicationId: "",
            subscriptionName: "",
            clientSecret: ""
        };
    }

    const renderValidationSchema = () => {
        switch (cloudAccountType.name) {
            case CloudAccountType.AWS:
                return createAwsSchema;
            case CloudAccountType.GOOGLE_CLOUD_PLATFORM:
                return createGcpSchema;
            case CloudAccountType.MICROSOFT_AZURE:
                return createAzureSchema;
            default:
                return createAwsSchema;
        }
    }

    const renderSubmitButtonText = () => {
        return `${props.awsAccount || props.gcpAccount || props.azureAccount ? "Update" : "Connect"} ${cloudAccountType.name} Account`
    }

    const renderCloudAccountTypeSelect = () => {
        return <>
            { // If action is EDIT or there is a provided cloudAccountType -> Do not display account type dropdown
                !props.awsAccount && !props.gcpAccount && !props.azureAccount && !props.cloudAccountType &&
                <>
                    <DropdownContainer
                        items={cloudAccountTypes}
                        label="Account type"
                        placeholder="Select account"
                        selectedItem={cloudAccountType}
                        onSelectItem={item => handleCloudAccountTypeChange(item)}
                        maxWidth="245px"
                    />
                    <br />
                </>
            }
        </>
    }

    return (
        <ModalInput
            class="modal modal--account"
            name={renderSubmitButtonText()}
            open
            logo={cloudAccountType.logo}
            onClose={props.onClose} >
            <Formik
                initialValues={renderInitialValues()}
                validationSchema={renderValidationSchema()}
                onSubmit={values => connectCloudAccount(values)}
            >
                {() => (
                    <Form>
                        <div className="box__inputs">
                            {renderCloudAccountTypeSelect()}
                            <br />
                            {renderAwsForm()}
                            {renderGcpForm()}
                            {renderAzureForm()}
                        </div>
                        <Button
                            className="btn btn--xl btn--orange"
                            type="submit"
                            isBusy={isBusy}
                            busyText={props.awsAccount || props.gcpAccount || props.azureAccount ? "Updating.." : "Connecting.."}
                        >
                            {renderSubmitButtonText()}
                        </Button>
                    </Form>
                )}
            </Formik>

        </ModalInput >
    )
}