import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useModal } from "react-modal-hook";
import { Bar, BarChart } from "recharts";
import { toast } from "react-toastify";

import { GlobalState } from "../../reducers";
import { getCloudCostOptimizerAPI } from "../../api";
import {
    ListSchedulesResponseRules,
    UpdateScheduleStateRequestNewStateEnum,
    ChangeStateAllResourcesRequestActionEnum
} from "@microtica/ms-cloud-cost-optimizer-sdk";

import PageHeader from "../../components/PageHeader/PageHeader";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import StageStatus from "../../components/StageStatus/StageStatus";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import CardPlaceholders from "../../components/Card/CardPlaceholders";
import { calculateUtilization } from "../../utils/schedules";
import Button from '../../components/Button/Button';

import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as PlayIcon } from "../../static/play-icon.svg";
import ScheduleScreen from "../../static/saving-schedule.png";

interface Schedule extends ListSchedulesResponseRules {
    totalUtilizedHours?: number;
    estimatedSaving?: number;
    estimatedSavingPersentage?: number;
    dailyUtilizedHours?: number[];
}

const Schedules = () => {
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [schedules, setSchedules] = useState<Schedule[]>();
    const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();
    const [isLoading, setIsLoading] = useState(true);
    const [disabledSchedules, setDisabledSchedules] = useState(false);

    const [showDeleteModal, hideDeleteModal] = useModal(() => (
        <ModalDanger
            title={`Are you sure you want to delete "${selectedSchedule!.name}" schedule?`}
            description="Do you really want to delete the schedule. This action cannot be undone."
            action="Delete"
            onCancel={hideDeleteModal}
            onConfirm={handleDeleteConfirmation}
        />
    ), [selectedSchedule]);

    const [showStartResourcesModal, hideStartResourcesModal] = useModal(() => (
        <ModalDanger
            title={`You disabled your schedule "${selectedSchedule!.name}".`}
            description="The resources affected with this schedule might be shut down. Do you what to start them so they are up and running?"
            action="Start resources"
            onCancel={hideStartResourcesModal}
            onConfirm={handleStartResourcesConfiguration}
        />
    ), [selectedSchedule]);

    useEffect(() => {
        initSchedules();
    }, []);

    async function initSchedules() {
        try {
            const { data: schedules } = await getCloudCostOptimizerAPI().listSchedules(currentProject.id);
            setSchedules(schedules.rules.map(schedule => ({
                ...schedule,
                ...calculateUtilization(schedule.startScheduleExpression, schedule.stopScheduleExpression, schedule.timezone)
            })));
        } catch (e) {
            setDisabledSchedules(e.response.data.code === 402);
        }

        setIsLoading(false);
    }

    async function handleDeleteConfirmation() {
        if (selectedSchedule) {
            await getCloudCostOptimizerAPI().deleteSchedule(selectedSchedule.id, currentProject.id, selectedSchedule.awsAccountId);
            hideDeleteModal();
            await initSchedules();
        }
    }

    async function handleScheduleStateChange(schedule: Schedule) {
        await getCloudCostOptimizerAPI().updateScheduleState(
            schedule.id,
            currentProject.id,
            {
                newState: schedule.state === "ENABLED" ?
                    UpdateScheduleStateRequestNewStateEnum.DISABLED :
                    UpdateScheduleStateRequestNewStateEnum.ENABLED
            }
        );
        await initSchedules();
        toast.success(`Successfully ${schedule.state === "ENABLED" ? "DISABLED" : "ENABLED"} schedule.`);
    }

    async function handleStartResourcesConfiguration() {
        await getCloudCostOptimizerAPI().changeStateAllResources(
            currentProject.id,
            {
                scheduleId: selectedSchedule!.id,
                awsAccountId: selectedSchedule!.awsAccountId,
                region: selectedSchedule!.awsRegion,
                tagKey: selectedSchedule!.resourcesTagKey,
                tagValue: selectedSchedule!.resourcesTagValue,
                action: ChangeStateAllResourcesRequestActionEnum.Start
            }
        );
        hideStartResourcesModal();
        toast.success("Successfully initiated START action on affected resources with this schedule.");
    }

    const EmptyBox = () => (
        <div className="page-centered page-centered--project" style={{ paddingTop: "5%" }}>
            <img src={ScheduleScreen} alt="schedule screen"/>
            <div className="page-centered__title">Custom sleep schedules</div>
            <p>
                Reduce cloud costs for non-production environments.
                Define custom-scheduled sleep cycles. Once saving mode is activated, Microtica goes in autopilot mode and takes care of the rest. If you need the cloud in a certain moment, you can bring it back manually right away.
            </p>
            {
                disabledSchedules ?
                    <Link className="btn btn--xl" to="/settings?tab=billing">
                        Upgrade Plan
                    </Link> : <Link className="btn btn--xl btn--green" to="/tools/schedules/create">Create Schedule</Link>
            }
        </div>
    );

    const showPlaceholders = (
        <Card class="card card--stages dark">
            <div className="skeleton skeleton__header">
                <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
                <p className="skeleton skeleton--animation skeleton--placeholder"></p>
            </div>

            <CardPlaceholders />
        </Card>
    );

    const showSchedules = (
        <CustomScrollbars maxHeight="calc(100vh - 120px)" resetClass="reset--top">
            <Card class="card card--stages dark">
                {(schedules || []).map(schedule => {
                    return (
                        <Card class="card light card-shadow" key={schedule.id}>
                            <Link to={`/tools/schedules/${schedule.id}/edit`}>
                                <div className="card__header-wrapper">
                                    <CardHeader title={schedule.name} text={
                                        <span className={`mt--10 align-self-start badge badge--${schedule.state === "ENABLED" ? "success" : "nobuild"}`}>
                                            {schedule.state === "ENABLED" ? "ACTIVE" : "NOT ACTIVE"}
                                        </span>
                                    } />
                                    <div className="d-flex align-items-center" key={`${schedule.id}-${schedule.state}`}>
                                        <DropdownMenuContainer actions={[
                                            {
                                                itemId: "1",
                                                title: schedule.state === "ENABLED" ? "Deactivate" : "Activate",
                                                icon: <PlayIcon />,
                                                onClick: () => {
                                                    setSelectedSchedule(schedule);
                                                    handleScheduleStateChange(schedule);
                                                }
                                            },
                                            {
                                                itemId: "2",
                                                title: "Delete",
                                                icon: <RemoveIcon />,
                                                type:"separator",
                                                onClick: () => {
                                                    setSelectedSchedule(schedule);
                                                    showDeleteModal();
                                                }
                                            }
                                        ]}
                                        />
                                    </div>
                                </div>

                                <div className="stages">
                                    <StageStatus
                                        class="stages__info-wrapper"
                                        items={[
                                            {
                                                id: 1,
                                                title: "Aws Account",
                                                text: schedule.awsAccountId
                                            },
                                            {
                                                id: 2,
                                                title: "Aws Region",
                                                text: schedule.awsRegion
                                            },
                                            {
                                                id: 3,
                                                title: "Estimated Saving",
                                                text: `${schedule.estimatedSavingPersentage}%`
                                            },
                                            {
                                                id: 4,
                                                title: "",
                                                text: <BarChart width={150} height={50} data={(schedule.dailyUtilizedHours || []).map(hours => ({ hours }))}>
                                                    <Bar dataKey="hours" fill="#49bb59" opacity="0.5" />
                                                </BarChart>
                                            },
                                            {
                                                id: 5,
                                                title: "Est. Saving Hours",
                                                text: `${schedule.totalUtilizedHours} hours/month`
                                            }
                                        ]}
                                    />
                                </div>
                            </Link>
                        </Card>
                    )
                })
                }
            </Card>
        </CustomScrollbars>
    );

    return (
        <main className="content d-flex flex-column justify-content-start">
            <PageHeader title="Saving Schedules" items={[
                <Link key="createSchedule"
                    className="btn btn--md btn--link btn--blue m--0"
                    to="/tools/schedules/create">
                    <Button
                        className="btn btn--md btn--link btn--blue m--0"
                        disabled={disabledSchedules}
                    >Create Schedule
                    </Button>

                </Link>
            ]} />

            <div className="content__body">
                {
                    !isLoading ?
                        disabledSchedules || (schedules && schedules.length === 0) ? <EmptyBox /> : showSchedules
                        : showPlaceholders
                }
            </div>
        </main>
    )
};
export default Schedules;