
export interface EnvironmentTemplate {
    [key: string]: {
        inputs?: {
            type: "object",
            properties: {
                [key: string]: {
                    type: "string";
                    description?: string;
                    default?: string;
                    enum?: string[];
                }
            },
            required?: string[];
        },
        components: {
            [key: string]: {
                type: string;
                properties: EnvironmentTemplateComponentProperty[]
            }
        }
    }
}

export interface EnvironmentTemplateComponentProperty {
    key: string;
    value?: string;
    reference?: string;
}

export default {
    "strapi": {
        "inputs": {
            "type": "object",
            "properties": {
                "DatabaseClient": {
                    "type": "string",
                    "default": "sqlite",
                    "enum": [
                        "sqlite",
                        "mysql"
                    ]
                },
                "ImageUrl": {
                    "type": "string",
                    "default": "<YOUR_AWS_ACCOUNT_ID>.dkr.ecr.<YOUR_REGION>.amazonaws.com/microtica/strapi-app",
                    "description": "URL of the Amazon ECR image"
                },
                "DesiredReplicas": {
                    "type": "string",
                    "default": "1"
                },
                "Memory": {
                    "type": "string",
                    "default": "512",
                    "enum": [
                        "512",
                        "1024"
                    ]
                },
                "CPU": {
                    "type": "string",
                    "default": "256",
                    "enum": [
                        "256",
                        "512",
                        "1024"
                    ]
                },
                "Domain": {
                    "type": "string",
                    "description": "Custom domain name"
                },
                "CertificateArn": {
                    "type": "string",
                    "description": "Certificate ARN for the custom domain"
                }
            },
            "required": [
                "Memory",
                "CPU",
                "DatabaseClient",
                "ImageUrl",
                "DesiredReplicas"
            ]
        },
        "components": {
            "VPC": {
                "type": "microtica-aws-vpc:v0.6.0-t3-instance-class",
                "properties": [
                    {
                        "key": "natInstanceType",
                        "value": "t3.micro"
                    }
                ]
            },
            "StrapiInfra": {
                "type": "microtica-aws-strapi-serverless:v4.0.0",
                "properties": [
                    {
                        "key": "VpcId",
                        "reference": "VPC.vpcId"
                    },
                    {
                        "key": "PrivateSubnet1Id",
                        "reference": "VPC.privateSubnet1"
                    },
                    {
                        "key": "PrivateSubnet2Id",
                        "reference": "VPC.privateSubnet2"
                    },
                    {
                        "key": "CPU",
                        "value": "256"
                    },
                    {
                        "key": "Memory",
                        "value": "512"
                    },
                    {
                        "key": "DesiredReplicas",
                        "value": "1"
                    },
                    {
                        "key": "DatabaseClient",
                        "value": "sqlite"
                    }
                ]
            },
            "AppRunner": {
                "type": "microtica-aws-fargate:v0.3.2-redirect-http-https",
                "properties": [
                    {
                        "key": "VpcId",
                        "reference": "VPC.vpcId"
                    },
                    {
                        "key": "PrivateSubnet1Id",
                        "reference": "VPC.privateSubnet1"
                    },
                    {
                        "key": "PrivateSubnet2Id",
                        "reference": "VPC.privateSubnet2"
                    },
                    {
                        "key": "PublicSubnet1Id",
                        "reference": "VPC.publicSubnet1"
                    },
                    {
                        "key": "PublicSubnet2Id",
                        "reference": "VPC.publicSubnet2"
                    },
                    {
                        "key": "CustomTaskDefinitionArn",
                        "reference": "StrapiInfra.TaskDefinitionArn"
                    },
                    {
                        "key": "ContainerPort",
                        "reference": "StrapiInfra.ContainerPort"
                    },
                    {
                        "key": "DesiredReplicas",
                        "reference": "StrapiInfra.DesiredReplicas"
                    }
                ]
            }
        }
    },
    "kubernetes": {
        "inputs": {
            "type": "object",
            "properties": {
                "Domain": {
                    "type": "string",
                    "description": "Custom domain name"
                },
                "CertificateArn": {
                    "type": "string",
                    "description": "Certificate ARN for the custom domain"
                }
            },
            "required": [
            ]
        },
        "components": {
            "VPC": {
                "type": "microtica-aws-vpc:v0.6.0-t3-instance-class",
                "properties": [
                    {
                        "key": "natInstanceType",
                        "value": "t3.micro"
                    }
                ]
            },
            "KubernetesCluster": {
                "type": "microtica-aws-eks:v0.1.17-optional-ssh-key",
                "properties": [
                    {
                        "key": "keyName",
                        "reference": "VPC.keyName"
                    },
                    {
                        "key": "vpcId",
                        "reference": "VPC.vpcId"
                    },
                    {
                        "key": "privateSubnet1Id",
                        "reference": "VPC.privateSubnet1"
                    },
                    {
                        "key": "privateSubnet2Id",
                        "reference": "VPC.privateSubnet2"
                    },
                    {
                        "key": "publicSubnet1Id",
                        "reference": "VPC.publicSubnet1"
                    },
                    {
                        "key": "publicSubnet2Id",
                        "reference": "VPC.publicSubnet2"
                    }
                ]
            }
        }
    }
} as EnvironmentTemplate;