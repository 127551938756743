import * as React from 'react';
import Button from "../../components/Button/Button";
import useOnClickOutside from '../../hooks/clickOutside';

export interface DropdownMenuContainerProps {
  title?: string;
  parentTitle?: string;
  actions: DropdownMenuItemProps[];
  type?: string;
  buttonText?: string;
  icon?: React.ReactElement;
  onClick?: () => Promise<DropdownMenuItemProps[]>;
  minWidth?: string;
}

export interface DropdownMenuItemProps {
  itemId: string;
  title: string;
  type?: "item" | "separator";
  icon?: React.ReactElement;
  disabled?: boolean;
  emphasized?: boolean;
  onClick?: (itemId: string, title?: string) => void;
}

function DropdownMenuContainer(props: DropdownMenuContainerProps) {
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [actions, setActions] = React.useState(props.actions);
  const [isBusy, setIsBusy] = React.useState(false);

  async function handleClick(event: any) {
    event.preventDefault();
    event.stopPropagation();

    if (props.onClick && !open) {
      setIsBusy(true);
      const actions = await props.onClick();
      setActions(actions);
      setIsBusy(false);
    }

    setOpen(!open);
  }

  function handleItemClick(event: React.MouseEvent, item: DropdownMenuItemProps) {
    event.preventDefault();
    event.stopPropagation();

    if (item.disabled) return;

    setOpen(!open);

    item.onClick && item.onClick(item.itemId, props.parentTitle);
  }

  useOnClickOutside(ref, () => setOpen(false));

  let classes = "dropdown__menu";
  if (open) {
    classes = classes + ' open';
  }

  return (
    <div className={classes} ref={ref}>
      <div>
        {/* Pipeline details -> "Trigger pipeline" button */}
        {props.type && props.type === "button" ?
          <Button
            className={`btn btn btn--md  btn--blue ${isBusy ? "dropdown__menu__btn--busy" : ""}`}
            style={{ minWidth: "130px" }}
            onClick={handleClick}
          >
            {!isBusy ? props.buttonText : ""}
          </Button>
          : <div
            className={`dropdown__btn dropdown__menu__btn dropdown__menu__btn--${props.type} ${isBusy ? "dropdown__menu__btn--busy" : ""}`}
            onClick={handleClick}
          >
          </div>
        }
      </div>
      <ul className="dropdown__content dropdown__content--light" style={props.minWidth ? { minWidth: props.minWidth } : {}}>
        {
          props.title ?
            <div className="dropdown__title">{props.title}</div>
            : null
        }
        <div className="items-container">
        {actions.map((item: DropdownMenuItemProps, index) => (
          <li
            key={index}
            onClick={(event) => handleItemClick(event, item)}
            className={`dropdown__item dropdown__item--${item.type} ${item.disabled ? "disabled" : ""} ${item.emphasized ? 'dropdown__item--emphasized' : ''}`}
          >
            <span className="dropdown__icon">{item.icon}</span>
            {item.title}
          </li>
        ))}
        </div>
      </ul>
    </div >
  );
}

export default DropdownMenuContainer;