import React, { ReactElement } from "react";
import DropdownMenuContainer from "../DropdownMenuContainer/DropdownMenuContainer";

export interface CardItemProps {
  itemId: string;
  title: string;
  description: string;
  footer?: ReactElement;
  type?: string;
  itemActions?: CardItemAction[];
  icon?: ReactElement
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface CardItemAction {
  itemId: string;
  title: string;
  icon: React.ReactElement;
  onClick: (itemId: string) => void;
}

const CardItem = (props: CardItemProps) => {

  return (
    <div
      className="col-4 pl--10 pr--10"
      onClick={props.onClick}
    >
      <div className="card-item">
        <div className="d-flex justify-content-between" style={{ height: "55px" }}>
          <div className="d-flex">
            {props.icon}
            <div className="d-flex flex-column justify-content-around ml--10">
              <div className="list__column">
                <h6 className="card-text">
                  <strong>{props.title}</strong>
                </h6>
              </div>

              <div className="list__column">
                <p className="mb--0 txt--capitalize card-text">{props.type}</p>
              </div>
            </div>
          </div>
          <div className="list__icons">
            {
              props.itemActions && props.itemActions.length ?
                <DropdownMenuContainer actions={props.itemActions} /> :
                null
            }
          </div>
        </div>
        <div className="mt--25 mb--25" style={{ height: "80px" }}>
          <p className="mb--0 card-text">{props.description.length > 200 ? props.description.substr(0, props.description.lastIndexOf(" ", 200)) + "..." : props.description}</p>
        </div>
        {props.footer}
      </div >
    </div>
  );
};

export default CardItem;