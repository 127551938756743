import { FetchBuilds, ClearBuilds } from '../actions';
import { BuildState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';

export const initialState: BuildState = {
    builds: [],
    offset: 0,
    limit: 10,
    isFetching: false,
    error: errorEmptyObject
}

export default function reducer(state: BuildState = initialState, action: FetchBuilds | ClearBuilds): BuildState {
    switch (action.type) {
        case constants.FETCH_BUILDS_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_BUILDS_SUCCESS: return {
            ...state,
            builds: state.builds.concat(action.builds),
            offset: action.builds.length >= action.limit ? action.offset! + action.limit : undefined,
            isFetching: false,
            error: errorEmptyObject
        };
        case constants.FETCH_BUILDS_FAIL: return { ...state, error: action.error };
        case constants.CLEAR_BUILDS: return { ...state, builds: [], offset: 0, limit: 10, isFetching: false, error: errorEmptyObject };
        default: {
            return state;
        }
    }
}
