import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetMicroservicesResponseMicroservices } from "@microtica/ms-kube-sdk";

import { GlobalState } from "../../reducers";

// import components
import Card from "../../components/Card/Card";
import TabMenuContainer, { Tab } from "../../components/TabMenuContainer/TabMenuContainer";
import ListView from "../../components/ListView/ListView";
import PageHeader from "../../components/PageHeader/PageHeader";
import SearchBar from "../../components/SearchBar/SearchBar";
import ModalDanger from "../../components/ModalDanger/ModalDanger";

import useMicroserviceList from "../../hooks/MicroserviceList";
import { microserviceCategories, MicroserviceType } from "../../utils/microservice-categories";
import * as actions from "../../actions";

import { ReactComponent as RemoveIcon } from "../../static/delete-icon.svg";
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import Button from "../../components/Button/Button";
import { getKubeAPI } from "../../api";
import ComponentScreen from "../../static/component-details.png";

const MicroservicesList = (props: RouteComponentProps) => {
  // Hooks
  const dispatch = useDispatch();
  const { filter, setFilter, filteredItems, isProcessing, error } = useMicroserviceList();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const { microservices } = useSelector((state: GlobalState) => state.microservice);
  const [selectedMicroservice, setSelectedMicroservice] = useState<GetMicroservicesResponseMicroservices>();
  const [displayedServiceType, setDisplayedServiceType] = useState<MicroserviceType>(MicroserviceType.PRIVATE);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedMicroservice!.name}" service?`}
      description="Do you really want to delete the service. This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleDeleteConfirmation}
    />
  ), [selectedMicroservice]);


  const handleTabChange = (selectedTab: Tab) => {
    switch (selectedTab.id) {
      case "0":
      default:
        setDisplayedServiceType(MicroserviceType.PRIVATE);
        break;
      case "1":
        setDisplayedServiceType(MicroserviceType.PUBLIC);
        break;
    }
  }

  useEffect(() => {
    if (error.code && !error.message) {
      toast.configure({
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      toast.info(`Something went wrong! Error: ${error.code}`)
    }
    if (error.message && error.message !== "") toast.error(error.message)
  }, [error])


  const privateServiceItemActions = [{
    title: "Edit",
    icon: <UpdateIcon />,
    onClick: handleMicroserviceUpdate
  }, {
    title: "Delete",
    type: "separator",
    icon: <RemoveIcon />,
    onClick: handleMicroserviceRemove
  }];
  const publicServiceItemActions: any[] = [];

  function handleDeleteConfirmation() {
    dispatch(actions.deleteMicroservice(selectedMicroservice ? selectedMicroservice.name : "", currentProject.id, selectedMicroservice ? selectedMicroservice.id : ""));
    hideConfirmationModal();
  }

  function handleMicroserviceUpdate(microserviceName: string) {
    props.history.push(`/kubernetes/services/${microserviceName}/edit`);
  }

  function handleMicroserviceRemove(microserviceName: string) {
    const selectedMicroservice = microservices.find(m => m.name === microserviceName);
    setSelectedMicroservice(selectedMicroservice);
    showConfirmationModal();
  }

  function handleMicroserviceDetails(microserviceName: string) {
    props.history.push(`/kubernetes/services/${microserviceName}`)
  }
  const handleMicroserviceCreate = async () => {
    try {
      await getKubeAPI().createMicroservice(currentProject.id);
    } catch (error) {
      props.history.push(`services/create`);
    }
  }

  // Header item(s)
  const createMicroserviceButton = <Button key="createMicroservice" className="btn btn--md btn--link btn--blue" onClick={handleMicroserviceCreate}>Create Service</Button>

  const EmptyBox = () => (
    <div className="page-centered page-centered--project">
      <img src={ComponentScreen} alt="component"/>
      <div className="page-centered__title">Kubernetes Services</div>
      <p className="txt--sm">
        Delivery lifecycle and observability for applications that run on Kubernetes.
        <br />
        <br />
        <a href="https://microtica.com/docs/deploy-to-kubernetes/"
          target="_blank"
          rel="noreferrer">
          Read how to deploy to Kubernetes
        </a>
      </p>
      <Button className="btn btn--lg btn--link btn--green" onClick={handleMicroserviceCreate}>Create Service</Button>
    </div >
  );

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader title="Kubernetes Services" items={[createMicroserviceButton]} />
      <div className="content__body pb--0">
        <Card class="card card--vertical dark">
          <div className="card__header-wrapper card__header-wrapper--lists">
            <TabMenuContainer
              class="tab-menu--big"
              tabs={microserviceCategories}
              onClick={handleTabChange}
            />
          </div>
          {
            !isProcessing && !microservices.length ?
              <EmptyBox /> :
              <>
                <div className="searchbar-wrapper">
                  <SearchBar
                    placeholder="Search services"
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                </div>
                <CustomScrollbars maxHeight={"100%"} resetClass="reset--top">
                  <ListView
                    isLoaded={isProcessing}
                    class="list--height"
                    listType="icons"
                    items={(displayedServiceType === MicroserviceType.PUBLIC) ? filteredItems.filter(item => item.isPublic) : filteredItems.filter(item => !item.isPublic)}
                    itemActions={(displayedServiceType === MicroserviceType.PUBLIC) ? publicServiceItemActions : privateServiceItemActions}
                    onClick={handleMicroserviceDetails}
                  />
                </CustomScrollbars>
              </>
          }
        </Card>
      </div>

    </main>
  )
};

export default MicroservicesList;
