import { AddDockerRegistryRequest, DockerCredentials, DockerCredentialsKeyEnum, DockerRegistry, DockerRegistryType } from "@microtica/ms-ap-sdk";
import { DockerRegistryObj, DockerRegistryValues, DropdownObject } from "../types/DockerRegistry";
import awsRegions from "./aws-regions";
import { addDockerHubRegistrySchema, addEcrRegistrySchema, addGitlabContainerRegistrySchema } from "./validation"
import { DropdownItem } from "../components/DropdownContainer/DropdownContainer";

export enum DockerProviderType {
    DOCKER_HUB = "Docker Hub",
    ECR = "Amazon ECR",
    GITLAB_CONTAINER_REGISTRY = "Gitlab Container Registry"
}

export enum GitlabHierarchy {
    GROUPS = "groups",
    PROJECTS = "projects"
}

const gitlabHierarchyType = {
    [GitlabHierarchy.GROUPS]:
        { id: "1", name: "groups" },
    [GitlabHierarchy.PROJECTS]:
        { id: "2", name: "projects" }
};
export const gitlabHierarchyTypeList: DropdownItem[] = Object.values(gitlabHierarchyType)

const dockerProvider = {
    [DockerRegistryType.Dockerhub]:
        { id: "1", name: DockerProviderType.DOCKER_HUB },
    [DockerRegistryType.Ecr]:
        { id: "2", name: DockerProviderType.ECR },
    [DockerRegistryType.GitlabContainerRegistry]:
        { id: "3", name: DockerProviderType.GITLAB_CONTAINER_REGISTRY }
}
export const dockerProviderList: DropdownItem[] = Object.values(dockerProvider)

export const getDockerProvider = (dockerRegistry?: DockerRegistryObj): DropdownItem => {
    if (dockerRegistry) {
        if (dockerRegistry.type === DockerRegistryType.GitlabContainerRegistry)
            return dockerProvider[DockerRegistryType.GitlabContainerRegistry];
        if (dockerRegistry?.type === DockerRegistryType.Ecr)
            return dockerProvider[DockerRegistryType.Ecr];
    }
    return dockerProvider[DockerRegistryType.Ecr]
}

const getGitlabHierarchyType = (dockerRegistry?: DockerRegistryObj): DropdownItem => (
    dockerRegistry?.credentials?.find(cred => cred.key === DockerCredentialsKeyEnum.GitlabHierarchyType)?.value === GitlabHierarchy.PROJECTS ? gitlabHierarchyType[GitlabHierarchy.PROJECTS] : gitlabHierarchyType[GitlabHierarchy.GROUPS]
)

const getCredentialValue = (key: DockerCredentialsKeyEnum, dockerRegistry?: DockerRegistryObj) => (
    dockerRegistry?.credentials?.find(cred => cred.key === key)?.value ?? ""
) as string;

export const getInitialCredentials = (dockerRegistry?: DockerRegistryObj): Omit<DockerRegistryValues, "name" | "is_default" | "docker_provider"> => ({
    docker_username: getCredentialValue(DockerCredentialsKeyEnum.DockerUsername, dockerRegistry),
    docker_password: getCredentialValue(DockerCredentialsKeyEnum.DockerPassword, dockerRegistry),
    aws_account_id: getCredentialValue(DockerCredentialsKeyEnum.AwsAccountId, dockerRegistry),
    aws_access_key_id: getCredentialValue(DockerCredentialsKeyEnum.AwsAccessKeyId, dockerRegistry),
    aws_secret_access_key: getCredentialValue(DockerCredentialsKeyEnum.AwsSecretAccessKey, dockerRegistry),
    aws_region: (dockerRegistry?.credentials?.find(cred => cred.key === DockerCredentialsKeyEnum.AwsRegion)?.value ?? { id: awsRegions[0].value, name: awsRegions[0].name }) as DropdownItem,
    gitlab_hierarchy_type: getGitlabHierarchyType(dockerRegistry),
    gitlab_id: getCredentialValue(DockerCredentialsKeyEnum.GitlabId, dockerRegistry),
    registry_url: getCredentialValue(DockerCredentialsKeyEnum.RegistryUrl, dockerRegistry)
});

export const modifyRetrievedCredentials = (dockerRegistry: DockerRegistry): DropdownObject[] => (
    dockerRegistry.credentials.map(cred => {
        if (cred.key === DockerCredentialsKeyEnum.AwsRegion) {
            const region = awsRegions.find(
                region => region.value === cred.value
            );
            return { key: cred.key, value: { id: region!.value, name: region!.name } }
        }
        return { key: cred.key, value: cred.value };
    }).concat(dockerRegistry.type === DockerRegistryType.Ecr ?
        [
            { key: DockerCredentialsKeyEnum.AwsAccessKeyId, value: "(sensitive)" },
            { key: DockerCredentialsKeyEnum.AwsSecretAccessKey, value: "(sensitive)" }
        ] : [
            { key: DockerCredentialsKeyEnum.DockerPassword, value: "(sensitive)" }
        ])
)

const getPayloadCredentials = (values: DockerRegistryValues): DockerCredentials[] => {
    switch (values.docker_provider.name) {
        case DockerProviderType.DOCKER_HUB:
            return [
                {
                    key: DockerCredentialsKeyEnum.DockerUsername,
                    value: values.docker_username!.trim(),
                },
                {
                    key: DockerCredentialsKeyEnum.DockerPassword,
                    value: values.docker_password!.trim(),
                    sensitive: true
                }
            ];
        case DockerProviderType.ECR:
            return [
                {
                    key: DockerCredentialsKeyEnum.AwsAccountId,
                    value: values.aws_account_id!.trim(),
                    sensitive: false
                },
                {
                    key: DockerCredentialsKeyEnum.AwsRegion,
                    value: values.aws_region.id!,
                    sensitive: false
                },
                {
                    key: DockerCredentialsKeyEnum.AwsSecretAccessKey,
                    value: values.aws_secret_access_key!.trim(),
                    sensitive: true
                },
                {
                    key: DockerCredentialsKeyEnum.AwsAccessKeyId,
                    value: values.aws_access_key_id!.trim(),
                    sensitive: true
                }
            ];
        case DockerProviderType.GITLAB_CONTAINER_REGISTRY:
            return [
                {
                    key: DockerCredentialsKeyEnum.DockerUsername,
                    value: values.docker_username!.trim(),
                },
                {
                    key: DockerCredentialsKeyEnum.DockerPassword,
                    value: values.docker_password!.trim(),
                    sensitive: true
                },
                {
                    key: DockerCredentialsKeyEnum.GitlabHierarchyType,
                    value: values.gitlab_hierarchy_type.name!,
                },
                {
                    key: DockerCredentialsKeyEnum.GitlabId,
                    value: values.gitlab_id!.trim(),
                },
                {
                    key: DockerCredentialsKeyEnum.RegistryUrl,
                    value: values.registry_url!.trim(),
                }
            ];
        default:
            throw new Error("Not supported docker provider type");
    }
}

export const getRegistryPayload = (values: DockerRegistryValues): AddDockerRegistryRequest => {
    let registryPayload = {
        registryName: values.name,
        isDefault: values.is_default,
        credentials: getPayloadCredentials(values)
    };
    switch (values.docker_provider.name) {
        case DockerProviderType.DOCKER_HUB:
            return {
                ...registryPayload,
                type: DockerRegistryType.Dockerhub,
            };
        case DockerProviderType.ECR:
            return {
                ...registryPayload,
                type: DockerRegistryType.Ecr,
            };
        case DockerProviderType.GITLAB_CONTAINER_REGISTRY:
            return {
                ...registryPayload,
                type: DockerRegistryType.GitlabContainerRegistry,
            };
        default:
            throw new Error("Not supported docker provider type");
    }
}

export const getValidationSchema = (dockerProviderName: DockerProviderType) => (dockerProviderName === DockerProviderType.DOCKER_HUB ? addDockerHubRegistrySchema : dockerProviderName === DockerProviderType.ECR ? addEcrRegistrySchema : addGitlabContainerRegistrySchema)