import * as React from 'react';
import InputText from '../InputText/InputText';
import { ReactComponent as SearchIcon } from "../../static/search-icon.svg";

export interface SearchProps {
  placeholder: string;
  value: string;
  modifierClass?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchBar: React.StatelessComponent<SearchProps> = p => (
  <div className={`searchbar__container ${p.modifierClass ? p.modifierClass : ''}`}>
    <div className="searchbar__icon">
      <SearchIcon />
    </div>
    <InputText
      type='text'
      name='searchText'
      placeholder={p.placeholder}
      value={p.value || ""}
      onChange={p.onChange} />
  </div>
);

export default SearchBar;