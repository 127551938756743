export function renderMenu(route: string): boolean {
    switch (route) {
        case '/projects':
        case '/account':
        case '/page-not-found':
            return false;

        default:
            return true;
    }
}