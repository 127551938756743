import React, { useState, useEffect, ReactElement } from "react";
import { useSelector } from 'react-redux';
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import StageStatus from "../../components/StageStatus/StageStatus";

import { RouteComponentProps } from "react-router";
import { GlobalState } from "../../reducers";
import { getContinuousIntegrationAPI, socketIO, disconnectSocketIO } from "../../api";

import { ReactComponent as InProgressIcon } from "../../static/inprogress-icon.svg";
import { ReactComponent as BranchIcon } from "../../static/branch-icon.svg";
import { ReactComponent as BuildIcon } from "../../static/build-icon.svg";
import { ReactComponent as ErrorIcon } from "../../static/error-icon.svg";
import { ReactComponent as ClockIcon } from "../../static/clock-icon.svg";
import { ReactComponent as StartIcon } from "../../static/start-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Skeleton from "react-loading-skeleton";
import ClipboardJS from "clipboard";

import { PipelineBuildDetailsSteps, PipelineBuildDetailsStages } from "@microtica/ms-ap-sdk";
import CardHeader from "../../components/CardHeader/CardHeader";
import PipelineCollapse from "../../components/PipelineCollapse/PipelineCollapse";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

import shieldRed from "../../static/shield-red.svg";
import shieldGreen from "../../static/shield-green.svg";
import { Socket } from "socket.io-client";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";

interface BuildDetailsProps extends RouteComponentProps<{
  pipelineId: string;
  buildId: string;
}> { }

const BuildDetails = (props: BuildDetailsProps) => {
  // Hooks
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [status, setStatus] = useState("SCHEDULING");
  const [branchFilter, setBranchFilter] = useState("n/a");
  const [steps, setSteps] = useState<PipelineBuildDetailsSteps[]>();
  const [stages, setStages] = useState<PipelineBuildDetailsStages[]>();
  const [entityName, setEntityName] = useState("n/a");
  const [buildNumber, setBuildNumber] = useState("n/a");
  const [startTime, setStartTime] = useState("n/a");
  const [duration, setDuration] = useState("n/a");
  const [errorMessage, setErrorMessage] = useState("");
  const [logs, setLogs] = useState("");

  const [currentStep, setCurrentStep] = useState<PipelineBuildDetailsSteps>();
  const [collapseClosed, setCollapseClosed] = useState(false);

  const { pipelineId, buildId } = props.match.params;
  const [socket, setSocket] = useState<Socket>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBusyStopButton, setIsBusyStopButton] = useState(false);
  const [clipboardText, setClipboardText] = useState<string | ReactElement>("");
  const clipboard = new ClipboardJS("#build-number");

  clipboard.on('success', (e) => {
    setClipboardText(<h6>Copied!</h6>);
    e.clearSelection();
  });

  useEffect(() => {
    let tempLogs: string = "";
    if (socket !== undefined) {
      socket.on("logs", (cloudLogs: string) => {
        tempLogs = tempLogs.concat(cloudLogs);
        setLogs(tempLogs);
      });
    }
  }, [socket])


  useEffect(() => {
    if (currentStep) {
      connectSocketIO();
    }
  }, [currentStep]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (status === "RUNNING" ||
        status === "SCHEDULING" ||
        status === "QUEUED") {
        fetchBuildDetails();
      } else {
        clearInterval(timer);
      }
    }, 5000);

    fetchBuildDetails();
    return () => clearInterval(timer);
  }, [status, pipelineId, buildId]);

  async function connectSocketIO() {
    const socket = await socketIO(
      {
        projectId: currentProject.id,
        pipelineId,
        buildId,
        stepName: currentStep!.name,
        typeOfLogs: "pipeline",
        codebuildLogs: true
      }
    );
    setSocket(socket);
  }

  async function fetchBuildDetails() {

    const { data: build } = await getContinuousIntegrationAPI().getPipelineBuild(currentProject.id, pipelineId, buildId);
    const duration = moment.duration(moment(build.stopDate || new Date()).diff(moment(build.startDate!)));

    setStatus(build.status);
    setEntityName(build.name)
    setBranchFilter((build.metadata as {
      commit: { name: string }
    }).commit.name);
    setErrorMessage(build.errorMessage!);
    setBuildNumber(build.id);
    setClipboardText(
      <>
        <h6>Click to copy</h6>
        {buildNumber}
      </>
    );
    setStartTime(moment(build.startDate).fromNow());
    setDuration(moment.utc(duration.as("milliseconds")).format("m[min] s[sec]"));
    setSteps(build.steps);
    setStages(build.stages);

    // Mark content as loaded after build details are fetched
    setIsLoaded(true);
  }

  const mapStatusToIcon = (status: string) => {
    if (status === "RUNNING" ||
      status === "SCHEDULING"
    ) {
      return {
        icon: <InProgressIcon />,
        status: "warning"
      }
    } else if (status === "SUCCEEDED") {
      return {
        icon: <CheckmarkIcon />,
        status: "success"
      };
    } else if (status === "FAILED") {
      return {
        icon: <ErrorIcon />,
        status: "fail"
      }
    } else if (status === "ABORTED") {
      return {
        icon: <NotInterestedIcon fontSize="small" />,
        status: "aborted"
      }
    } else if (status === "QUEUED") {
      return {
        icon: <InProgressIcon />,
        status: "queued"
      }
    } else {
      return {
        icon: <FiberManualRecordIcon className="txt--grey" />,
        status: "nobuild"
      }
    }
  }

  const openCollapse = (step?: any) => {
    setLogs("")
    setCollapseClosed(true);
    setCurrentStep(step);
    document.body.style.overflow = "visible";
  }
  const closeCollapse = () => {
    setLogs("")
    disconnectSocketIO();
    setCollapseClosed(false);
    setCurrentStep(undefined);

    document.body.style.overflow = "hidden";
  }

  const stopExecution = async () => {
    try {
      await getContinuousIntegrationAPI().stopPipelineExecution(currentProject.id, pipelineId, buildId);
      setIsBusyStopButton(true);
      toast.success("Successfully stopped pipeline execution")
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const Step = (props: { step: PipelineBuildDetailsSteps }) => {
    let scanFailed = false;
    let scanFailedDescription = "";
    let findingsCount = 0;
    const scan = props.step.reports && props.step.reports.containerScan;

    if (props.step.reports && props.step.reports.containerScan) {
      const { severityCounts, status, statusDescription } = props.step.reports.containerScan;

      if (status === "FAILED") {
        scanFailed = true;
        scanFailedDescription = statusDescription;
      }

      Object.keys(severityCounts).forEach(severity => {
        findingsCount += severityCounts[severity];
      });
    }

    return (
      <Card class="card--pipeline card--stages">
        <Card key={props.step.name}
          class={`card pipeline ${currentStep ? props.step.name === currentStep.name ? `card-${props.step.status.toLocaleLowerCase()}` : "" : ""} ${props.step.status.toLocaleLowerCase()}-status`}>
          <div className="pointer" onClick={() => openCollapse(props.step)} >
            <CardHeader
              type="pipeline"
              title={
                <div className="d-flex">
                  <span className={`circle mr--10 circle--step circle--${mapStatusToIcon(props.step.status).status}`}>
                    {mapStatusToIcon(props.step.status).icon}
                  </span>
                  <span className="align-self-center">
                    {props.step.name}
                  </span>
                </div>}
              text={moment.utc(moment.duration(moment(props.step.stopDate || new Date())
                .diff(moment(props.step.startDate!)))
                .as("milliseconds"))
                .format("m[min] s[sec]")}
            />
            {
              scan ?
                <Tooltip
                  title={
                    <>
                      <h6>{scan.imageName}{scan.imageTag ? `:${scan.imageTag.substring(0, 7)}` : ""}</h6>
                      {
                        scan.status === "FAILED" ? scan.statusDescription :
                          !Object.keys(scan.severityCounts).length ? "No vulnerabilities found" : ""
                      }
                      {
                        Object.keys(scan.severityCounts).map(severity => (
                          <div>
                            {severity}: {scan.severityCounts[severity]}
                          </div>
                        ))
                      }
                      <br />
                      <br />
                    </>
                  }
                >
                  <div className="step-reports">
                    <img src={findingsCount || scanFailed ? shieldRed : shieldGreen} alt="step report" />
                    {
                      scanFailed ?
                        scanFailedDescription :
                        findingsCount ? `${findingsCount} security issues` : "No security issues"
                    }
                  </div>
                </Tooltip> : null
            }
          </div>
        </Card>
      </Card>
    )
  };

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        title={entityName}
        items={status === "RUNNING" ? [<Button
          className="btn btn--sm btn--danger"
          children="Stop Execution"
          disabled={isBusyStopButton}
          onClick={stopExecution}
        />] : []}
      />
      <div className="content__body">
        <div className="details__status">
          <StageStatus
            class="stages__info-wrapper--details"
            items={[
              {
                id: 1,
                title: "Status",
                text: isLoaded ? status : <Skeleton width="70px" />,
                ...mapStatusToIcon(status)
              },
              {
                id: 2,
                title: "Branch",
                icon: <BranchIcon />,
                text: isLoaded ? branchFilter : <Skeleton width="70px" />,
              },
              {
                id: 3,
                title: "Build Number",
                icon: <BuildIcon />,
                text: isLoaded ?
                  <Tooltip
                    color="white"
                    interactive
                    title={clipboardText}>
                    <span
                      id="build-number"
                      style={{ cursor: "pointer", color: "#4a90e2" }}
                      data-clipboard-text={buildNumber}
                      onPointerOut={() => setClipboardText(<>
                        <h6>Click to copy</h6>
                        {buildNumber}
                      </>
                      )}
                    >
                      {buildNumber.substring(0, 10)}
                    </span>
                  </Tooltip> : <Skeleton width="70px" />,
              },
              {
                id: 4,
                title: "Start Time",
                icon: <StartIcon />,
                text: isLoaded ? startTime : <Skeleton width="70px" />,
              },
              {
                id: 5,
                title: "Duration",
                icon: <ClockIcon />,
                text: isLoaded ? duration : <Skeleton width="70px" />,
              },
            ]}
          />
        </div>

        <ErrorMessage message={errorMessage} />
        {/* stages and steps */}
        <br />
        <br />
        <div className="row flex-row flex-nowrap"
          style={{ overflowX: "auto" }}>
          {
            stages && !stages.length ?
              (steps || []).map((step, index) =>
                <div className="col-3" key={step.name + index}>
                  <div className="list__item" style={{ cursor: "auto" }}>
                    <div className="list__text" style={{ textTransform: "uppercase" }}>
                      <h5 className="d-flex align-items-center">
                        {step.name}
                      </h5>
                    </div>
                  </div>
                  <div>
                    <br />
                    <Step step={step} />
                  </div>
                </div>
              ) :
              // stages
              stages && stages.map((stage, index) =>
              (
                <div className="col-3" key={stage.name + index}>
                  <div className="list__item" style={{ cursor: "auto" }}>
                    <div className="list__text" style={{ textTransform: "uppercase" }}>
                      <h5 className="d-flex align-items-center">
                        {stage.name}
                      </h5>
                    </div>
                  </div>
                  <div>
                    <br />
                    {
                      // steps
                      steps && steps.filter(s => s.stage === stage.name).map((step, index) =>
                        <Step step={step} key={step.name + index} />
                      )
                    }
                  </div>
                </div>
              ))
          }
        </div>
      </div>
      <PipelineCollapse
        step={currentStep}
        collapseClosed={collapseClosed}
        handleCloseCollapse={closeCollapse}
        logs={logs}
      />

    </main>
  )
}

export default BuildDetails;
