import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    MicroserviceConfigurationItem,
    GetStageDetailsResponseResources,
    ComponentSchemaPropertiesInputs,
    GetStageResourcesResponseResources,
    GetComponentBuildsResponseBuilds,
    GetComponentResponse,
} from "@microtica/ms-engine-sdk";
import { PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import Skeleton from "react-loading-skeleton";

import { getEngineAPI } from "../../api";
import { GlobalState } from "../../reducers";
import { Dictionary } from "./ConfigurationItem";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import { resourceName as resourceNameValidation, validateSchema } from "../../utils/validation";

import ResourceConfigurationItem from "./ResourceConfigurationItem";
import Animation from "../Animations/Animations";
import CardPlaceholders from "../Card/CardPlaceholders";
import InfoMessage from "../InfoMessage/InfoMessage";
import { trackEnvComponentAdded, trackEnvComponentConfigured } from "../../tracking/environment";

export interface ResourceConfigurationModalProps {
    onSuccess?: () => void;
    onClose: () => void;
    stageId: string;
    componentId: string;
    resource?: GetStageResourcesResponseResources;
}
interface Property {
    key: string;
    value: string;
    type: "string" | "select" | string;
    description?: string;
    required: boolean;
    enum: string[];
    sensitive: boolean;
    reference: boolean;
}

let resourceConfigs: Dictionary<MicroserviceConfigurationItem> = {};

const ResourceConfigurationModal = (props: ResourceConfigurationModalProps) => {
    const { resource } = props;
    const latestVersion = { id: "latest", name: "LATEST", subTitle: "Always take the latest version on deployment" };
    const [errors, setErrors] = useState<Dictionary<string>>({});
    const [touched, setTouched] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [properties, setProperties] = useState<Property[]>([]);
    const [subtitle, setSubtitle] = useState<React.ReactElement>();
    const [resourceName, setResourceName] = useState(resource ? resource.name : "");
    const [selectedVersionItem, setSelectedVersionItem] = useState<DropdownItem>({ id: "", name: "" });
    const [resourceComponent, setResourceComponent] = useState<GetComponentResponse>();
    const [componentVersions, setComponentVersions] = useState<GetComponentBuildsResponseBuilds[]>([]);
    const [selectedVersion, setSelectedVersion] = useState<GetComponentBuildsResponseBuilds>();
    const [componentVersionItems, setComponentVersionItems] = useState<DropdownItem[]>([]);
    const [resourceOutputs, setResourceOutputs] = useState<Dictionary<string[]>>({});
    const [customScrollbarsHeight, setCustomScrollbarsHeight] = useState("750px");
    const [modalActionName] = useState(resource ? "Update" : "Create");
    const [headerTitle] = useState(resource ? "Configure" : "Create");
    const [schema, setSchema] = useState<ComponentSchemaPropertiesInputs>();
    const selectedResource = useRef({} as GetStageDetailsResponseResources);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [infrastructureAsCodeTool, setInfrastructureAsCodeTool] = useState<string>("");   // 'cloudformation' || 'terraform'
    const [stageName, setStageName] = useState<string>("");

    useEffect(() => {
        if (window.innerHeight <= 768) {
            setCustomScrollbarsHeight("600px");
        } else {
            setCustomScrollbarsHeight("750px");
        }
    }, []);

    useEffect(() => {
        const fetch = async () => {
            try {
                const [{ data: stage }, { data: versions }, { data: component }] = await Promise.all([
                    getEngineAPI().getStageDetails(props.stageId, currentProject.id),
                    getEngineAPI().getComponentBuilds(props.componentId, currentProject.id),
                    getEngineAPI().getComponent(props.componentId, currentProject.id)
                ]);
                setInfrastructureAsCodeTool(component!.infrastructureAsCodeTool);
                setStageName(stage.name);
                if (resource) {
                    selectedResource.current = (
                        stage.resources.filter(r => r.name === resource.name && r.component.id === resource.component.id)
                    )[0];
                }

                const outputs = stage.resources.reduce((acc, resource) => {
                    // Skip the resource being configured to avoid circular dependency
                    if (resource.name === (props.resource && props.resource.name)) {
                        return acc;
                    }
                    const outputs = resource.component.schema.properties.outputs;
                    acc[resource.name] = Object.keys(outputs.properties || {});
                    return acc;
                }, {} as Dictionary<string[]>);

                // Map component versions. List only succeeded executions.
                const componentVersions = (versions.builds as PipelineBuildDetails[] || []).sort((a, b) => {
                    if ((new Date(a.stopDate!) as any) > (new Date(b.stopDate!) as any)) return -1;
                    if ((new Date(a.stopDate!) as any) < (new Date(b.stopDate!) as any)) return 1;
                    return 0;
                }).reduce((acc, build) => {
                    if (build.status === "SUCCEEDED") {
                        acc.push({
                            id: build.id,
                            name: build.id,
                            hideName: true,
                            plainText: [build.id, build.metadata.commit.message, build.metadata.commit.name, build.metadata.commit.user.name].join(" "),
                            subTitle: <>
                                <span className="maintext">{build.metadata.commit.message}</span>
                                <div className="subtext">
                                    <span>
                                        {build.metadata.commit.name} <span className="link" >({build.id.substring(0, 7)})</span>
                                    </span>
                                    <span style={{ float: "right" }}>
                                        {moment(build.stopDate).fromNow()}
                                    </span>
                                </div>
                                <div className="clearfix">
                                    <img src={build.metadata.commit.user.avatar} alt={build.metadata.commit.user.name} />
                                    {build.metadata.commit.user.name}
                                </div>
                            </>
                        });
                    }
                    return acc;
                }, [latestVersion] as DropdownItem[]);

                const currentVersion = props.resource ? selectedResource.current.component.isLatestVersion ?
                    latestVersion : {
                        id: selectedResource.current.component.version,
                        name: selectedResource.current.component.version
                    } :
                    latestVersion;

                setResourceComponent(component);
                setComponentVersions(versions.builds || []);
                setResourceOutputs(outputs);
                setComponentVersionItems(componentVersions);
                handleSelectedVersion(currentVersion);
            } catch (err) {
                toast.error(err.response.data.message);
            } finally {
                // setIsLoading(false);
            }
        };
        fetch();
        setSelectedVersionItem(latestVersion);
    }, []);

    useEffect(() => {
        if (componentVersions.length) {
            setSelectedVersion(
                selectedVersionItem.id === "latest" ?
                    componentVersions[0] :
                    componentVersions.find(v => v.id === selectedVersionItem.id)
            );
        }
    }, [componentVersions, selectedVersionItem]);

    function getResourceConfig(key: string) {
        const { resource } = props;
        if (!resource) { return {}; }

        const config = selectedResource.current.configurations.find(config => config.key === key);
        if (!config) { return {}; }

        return {
            key,
            value: config.value,
            sensitive: config.sensitive || false,
            reference: config.reference || false
        };
    }

    async function handleSelectedVersion(item: DropdownItem) {
        const version = item.id;
        if (version === "") {
            setErrors({
                ...errors,
                version: "Please select a version"
            });
            return;
        }
        const { data: component } = await getEngineAPI().getComponent(props.componentId, currentProject.id, version);
        setSubtitle(<span>
            <p className="modal__text m--0">
                An instance of&nbsp;
                <a href={`/components/${props.componentId}`} target="_blank" style={{ color: "#0075be", fontSize: "inherit", fontWeight: 600 }} rel="noreferrer">
                    {component.name}
                </a>
                &nbsp;component
            </p>
        </span>);

        if (!component || !component.schema || !component.schema.properties) {
            setIsLoading(false);
            return;
        }

        delete errors["version"];

        setSchema(component.schema.properties.inputs);

        const { inputs } = component.schema.properties;
        const inputProps: Dictionary<any> = inputs.properties || {};
        const componentProps = Object.keys(inputProps).map(key => {
            const inputProp: { default: string; enum: string[]; description?: string; sensitive?: boolean } = inputProps[key];
            const value = inputProp.default || "";
            const type = inputProp.enum ? "select" : "string";
            const required = (inputs.required || []).includes(key);
            const sensitive = inputProp.sensitive || false;

            const resourceConfig = getResourceConfig(key);

            return {
                key,
                value,
                type,
                required: required,
                description: inputProp.description,
                enum: inputProp.enum || [],
                sensitive,
                reference: false,
                ...resourceConfig
            };
        });
        setProperties(componentProps);
        setSelectedVersionItem(item);

        if (componentVersions.length) {
            setSelectedVersion(
                item.id === "latest" ?
                    componentVersions[0] :
                    componentVersions.find(v => v.id === item.id)
            );
        }
        setIsLoading(false);
    }

    function handleClose() {
        resourceConfigs = {}; // TODO: fix this
        props.onClose();
    }

    function handlePropUpdate(item: MicroserviceConfigurationItem) {
        // We want to save the prop value even if it is an empty string (terraform only)
        if (item.value === "" && infrastructureAsCodeTool !== "terraform") {
            delete resourceConfigs[item.key];
        } else {
            resourceConfigs[item.key] = item;
        }
    }

    async function handleAddToStage() {
        const validationErrors = getValidationErrors();

        if (Object.keys(validationErrors).length) {
            const errorMessage = Object.keys(validationErrors).reduce((acc, key) => {
                return acc += `${validationErrors[key]}\n`
            }, "");
            toast.error(errorMessage);
        }

        if (!isValid()) {
            return;
        }

        const { componentId, stageId } = props;
        const componentVersion = selectedVersionItem!.id;
        // Cleanup properties that do not exist in the current schema
        const configurations = Object.keys(resourceConfigs).reduce((obj, key) => {
            if (schema && schema.properties && (schema as any).properties[key]) {
                obj.push(resourceConfigs[key]);
            }
            return obj;
        }, [] as MicroserviceConfigurationItem[]);

        setIsBusy(true);
        try {
            if (resource) {
                await getEngineAPI().updateResource(stageId, resourceName, currentProject.id, {
                    componentVersion,
                    configurations
                });
                trackEnvComponentConfigured(stageId, stageName, componentId, componentVersion, resourceName)
            } else {
                await getEngineAPI().createResource(stageId, currentProject.id, {
                    name: resourceName,
                    componentId,
                    componentVersion,
                    configurations
                });
                trackEnvComponentAdded(stageId, stageName, componentId, componentVersion, resourceName);
            }
            handleClose();
            props.onSuccess && props.onSuccess();
        } catch (err) {
            toast.error(err.response.data.message);
        }
        setIsBusy(false);
    }

    function onTouched(property: string) {
        if (!touched.includes(property)) {
            setTouched([...touched.concat(property)])
        }
    }

    function getValidationErrors(property?: string) {
        let validationErrors: Dictionary<string> = errors['version'] ? { version: errors['version'] } : {};

        try {
            resourceNameValidation.validateSync(resourceName);
        } catch (err) {
            validationErrors["resourceName"] = err.message;
        }

        if (schema) {
            let validationData: Dictionary<string | undefined> = {};
            Object.keys(resourceConfigs).map(key => {
                if (resourceConfigs[key].value !== "" && key in (schema.properties || {})) {
                    validationData[key] = resourceConfigs[key].value;
                }
            })
            const validation = validateSchema(schema, validationData);
            validationErrors = {
                ...validationErrors,
                ...validation
            }
        }

        if (property) {
            //filter the errors on the touched properties
            const filtered = Object.keys(validationErrors)
                .filter(key => touched.includes(key))
                .reduce((obj: any, key) => {
                    obj[key] = validationErrors[key];
                    return obj;
                }, {});
            validationErrors = filtered;
        } else {
            //should set all to touched
            const keys = Object.keys(validationErrors);
            const newSet = new Set([...touched, ...keys]);
            setTouched(Array.from(newSet))
        }

        return validationErrors;
    }

    function isValid(property?: string) {
        const validationErrors = getValidationErrors(property);
        setErrors(validationErrors);

        return Object.keys(validationErrors).length ? false : true;
    };

    const NoVersionsPlaceholder = () => {
        return (
            <InfoMessage message={
                <>
                    This component does not have any available versions yet.
                    <br />
                    To use this component, you need to have at least one successful build for the <a href={resourceComponent ? `/pipelines/${resourceComponent.pipelineId}/details` : ""} target="_blank" >pipeline associated with this component.</a>
                </>
            } />
        )
    }

    return (
        <>
            <div className="page-overlay" />

            <Animation show={true} type="modal" modal itemIndex={1}>
                <div className="modal modal--configure modal--relative">
                    {/* HEADER */}
                    <div className="modal__header">
                        <h3 className="modal__title">{headerTitle} resource</h3>
                        {
                            isLoading ? <Skeleton width="120px" /> : subtitle
                        }
                    </div>

                    <div className="modal__close" onClick={handleClose}>X</div>

                    {/* CONTENT */}
                    <div className="modal__content">
                        <CustomScrollbars minHeight="100px" maxHeight="calc(100vh - 230px)" resetClass="reset--top">
                            {
                                isLoading ?
                                    <CardPlaceholders />
                                    :
                                    <>
                                        <div className="modal__content-section">
                                            <h3 className="modal__title">General</h3>
                                            <p>
                                                Configure component name and version to be deployed
                                                <br />
                                                Set version to LATEST if you want to get the latest changes on every deployment
                                            </p>
                                            {/* RESOURCE NAME and VERSION */}
                                            <div className="row gutter--xl">
                                                <div className="col-12 col-xl-6">
                                                    <div className="modal__info">
                                                        <InputText
                                                            type="text"
                                                            placeholder="Enter resource name"
                                                            info="Resource is an instance of a component. This is the name that will identify the component instance."
                                                            label="Resource name"
                                                            value={resourceName}
                                                            disabled={resource ? true : false}
                                                            onChange={event => setResourceName(event.target.value)}
                                                            onBlur={() => isValid("resourceName")}
                                                            onFocus={() => onTouched("resourceName")}
                                                            tabIndex={0}
                                                        />
                                                        {errors.resourceName ? <div style={{ color: 'red', fontSize: '12px' }}>{errors.resourceName}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <div className="modal__info">
                                                        <DropdownContainer
                                                            items={componentVersionItems}
                                                            key={selectedVersionItem.id}
                                                            label="Version"
                                                            info="Component version. Set latest if you want to get the latest changes on every deployment."
                                                            selectedItem={selectedVersionItem}
                                                            onSelectItem={handleSelectedVersion}
                                                            onFocus={() => onTouched("version")}
                                                            tabIndex={0}
                                                        />
                                                        {errors.version ? <div style={{ color: 'red', fontSize: '12px' }}>{errors.version}</div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                selectedVersion && selectedVersion.metadata ?
                                                    <div className="content-section-infobox">
                                                        <div className="pipeline-commit-cell">
                                                            <div className="pipeline-commit-cell__title">
                                                                {selectedVersion.metadata.commit.message}
                                                            </div>
                                                            <div className="pipeline-commit-cell__subtitle">
                                                                <span>
                                                                    {selectedVersion.metadata.commit.name}
                                                                </span>
                                                                {
                                                                    !selectedVersion.metadata.commit.version.includes("-") ?
                                                                        <>
                                                                            &nbsp;(
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                href={
                                                                                    `${selectedVersion.metadata.repository}/${selectedVersion.metadata.repository.startsWith("https://github.com") ? "commit" : "commits"}/${selectedVersion.metadata.commit.version}`
                                                                                }
                                                                                onClick={e => e.stopPropagation()}>
                                                                                {selectedVersion.metadata.commit.version.substring(0, 7)}
                                                                            </a>
                                                                            )
                                                                        </> :
                                                                        <>
                                                                            &nbsp;(
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                href={
                                                                                    `/pipelines/${selectedVersion.pipelineId}/builds/${selectedVersion.id}`
                                                                                }
                                                                                onClick={e => e.stopPropagation()}>
                                                                                {selectedVersion.id.substring(0, 7)}
                                                                            </a>
                                                                            )
                                                                        </>
                                                                }
                                                                <span style={{ float: "right" }}>
                                                                    {moment(selectedVersion.stopDate, "x").fromNow()}
                                                                </span>
                                                            </div>
                                                            <div className="pipeline-commit-cell__profile">
                                                                <img src={selectedVersion.metadata.commit.user.avatar} alt={selectedVersion.metadata.commit.user.name} />
                                                                {selectedVersion.metadata.commit.user.name}
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </div>
                                        {
                                            !componentVersions.length ?
                                                <NoVersionsPlaceholder />
                                                :

                                                <div className="modal__content-section">
                                                    <h3 className="modal__title">Configuration</h3>
                                                    <p>
                                                        Enter plain values or reference props from other components
                                                        <br />
                                                        By referencing other components, Microtica will automatically provide the values during deployment
                                                    </p>
                                                    <div className="row gutter--xl">
                                                        {
                                                            properties.map((prop) => (
                                                                <div className="col-12 col-xl-6" key={`${prop.key}-${selectedVersionItem.id}`}>
                                                                    <div className="modal__info">
                                                                        <ResourceConfigurationItem
                                                                            tabIndex={0}
                                                                            prop={prop}
                                                                            resourceOuputs={resourceOutputs}
                                                                            onChange={handlePropUpdate}
                                                                            validate={isValid}
                                                                            onFocus={() => onTouched(prop.key)}
                                                                        />
                                                                        {errors[prop.key] ? <div style={{ color: 'red', fontSize: '12px' }}>{errors[prop.key]}</div> : null}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                        }
                                    </>
                            }
                        </CustomScrollbars>
                        <div className="modal__footer d-flex justify-content-end">
                            <Button
                                className="btn btn--lg btn--green"
                                disabled={isLoading || !componentVersions.length}
                                isBusy={isBusy}
                                busyText={resource ? "Update" : "Create"}
                                onClick={!isLoading ? handleAddToStage : undefined}>
                                {modalActionName}
                            </Button>
                        </div>
                    </div>

                    {/* FOOTER */}

                </div>
            </Animation>
        </>
    );
};

export default ResourceConfigurationModal;