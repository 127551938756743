import React, { useState, useEffect } from "react";
import { useModal } from 'react-modal-hook';
import ListView from "../../components/ListView/ListView";
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ListItemProps } from "../../components/ListItem/ListItem";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../reducers";
import { deleteGcpAccount } from "../../actions";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";
import useGcpAccountList from "../../hooks/GcpAccount";
import CloudAccountModal from "../../components/CloudAccount/CloudAccountModal";

const GcpAccounts = (props: { searchText?: string }) => {
  const { gcpAccounts } = useGcpAccountList();
  const [gcpAccountList, setGcpAccountList] = useState<ListItemProps[]>([]);
  const [gcpAccountId, setGcpAccountId] = useState("");
  const [selectedGcpProject, setSelectedGcpProject] = useState({ id: "", name: "" })
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const dispatch = useDispatch();
  const [filterGcpAccounts, setFilterGcpAccounts] = useState("");

  // Check if request to server is still processing - to be able to display real data or skeleton placeholder
  const isProcessing = useSelector((state: GlobalState) => state.project.isProcessing);

  const [showGcpModal, hideGcpModal] = useModal(() => (<CloudAccountModal
    gcpAccount={gcpAccounts.find(acc => acc.gcpProjectId === gcpAccountId)}
    onClose={handleHideGcpModal}
  />), [gcpAccounts, gcpAccountId]);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedGcpProject.name}" account?`}
      description="Do you really want to delete this Google Cloud Platform account? This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleDeleteGcpAccountConfirmation}
    />), [selectedGcpProject]);

  function handleHideGcpModal() {
    setGcpAccountId("");
    hideGcpModal();
  }

  function handleDeleteGcpAccount(gcpProjectId: string, name?: string ) {
    setSelectedGcpProject({id: gcpProjectId, name: name || gcpProjectId})
    showConfirmationModal();
  }

  function handleUpdateGcpAccount(gcpProjectId: string){
    setGcpAccountId(gcpProjectId)
    showGcpModal();
  }

  async function handleDeleteGcpAccountConfirmation() {
    dispatch(deleteGcpAccount(currentProject.id, selectedGcpProject.id));
    setSelectedGcpProject({id: "", name: ""});
    hideConfirmationModal();
  }

  useEffect(() => {
    setGcpAccountList(gcpAccounts.map((acc: any) => ({
      itemId: acc.gcpProjectId,
      title: acc.projectName || acc.gcpProjectId,
      description: acc.targetServiceAccount
    }))
      .filter(item => {
        if (filterGcpAccounts !== "") {
          return item.title
            .toLocaleLowerCase()
            .includes(
              filterGcpAccounts.toLocaleLowerCase()
            );
        } else {
          return true;
        }
      }));
  }, [gcpAccounts, filterGcpAccounts]);

  useEffect(() => {
    setFilterGcpAccounts(props.searchText!);
  }, [props.searchText]);

  // Dropdown menu action items
  const itemActions = [
    {
      title: "Edit",
      icon: <UpdateIcon />,
      onClick: handleUpdateGcpAccount
    },
    {
      title: "Remove",
      icon: <RemoveIcon />,
      onClick: handleDeleteGcpAccount
    }
  ]

  return (
    <React.Fragment>
      {!isProcessing ?
        gcpAccountList && gcpAccountList.length > 0 &&
        <>
          <h5 className="pl--10">Google Cloud Platform Accounts</h5>
          <ListView
            class="list--settings pr--20"
            listType="icons"
            itemActions={itemActions}
            items={gcpAccountList}
          />
        </>
        : [...Array(3)].map((_value, index) => (<ListItemSkeleton key={index} />))
      }
    </React.Fragment>
  )
}

export default GcpAccounts;