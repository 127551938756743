import * as constants from "../../constants";
import { Project } from "@microtica/ms-project-sdk";
import { getProjectAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";

export interface FetchProjectsStart {
  type: constants.FETCH_PROJECTS_START;
}

export interface FetchProjectsSuccess {
  type: constants.FETCH_PROJECTS_SUCCESS;
  projects: Project[];
}

export interface FetchProjectsFail {
  type: constants.FETCH_PROJECTS_FAIL;
  error: ErrorInterface;
}

export type FetchProjects =
  | FetchProjectsStart
  | FetchProjectsSuccess
  | FetchProjectsFail;


export function fetchProjectsStart(): FetchProjects {
  return {
    type: constants.FETCH_PROJECTS_START
  };
}

export function fetchProjectsSuccess(
  projects: Project[] = []
): FetchProjectsSuccess {
  return {
    type: constants.FETCH_PROJECTS_SUCCESS,
    projects
  };
}

export function fetchProjectsFail(error: ErrorInterface): FetchProjectsFail {
  return {
    type: constants.FETCH_PROJECTS_FAIL,
    error
  };
}

export function fetchProjects(): ThunkAction<Promise<void>, {}, {}, FetchProjects> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchProjects>) => {
    dispatch(fetchProjectsStart());
    try {
      const res = await getProjectAPI().listProjects();
      dispatch(fetchProjectsSuccess(res.data.projects));
    } catch (e) {
      dispatch(fetchProjectsFail(e.response.data));
    }
  };
}
