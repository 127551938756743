import React, { ReactElement } from "react";
import Animation from "../../components/Animations/Animations";

export interface TableProps {
  headers: string[]
  rows: any[]
  onClick?: (itemId: string) => void;
  isLoaded: boolean;
  tableModifier?: string;
  placeholderText?: string | ReactElement;
  changeBackgroundColor?: boolean;
}

const Table = (props: TableProps) => {

  function handleItemClick(itemId: string) {
    props.onClick && props.onClick(itemId);
  }

  // Create table headers
  const createTableHeaders = () => {
    let tableHeaders = [];
    for (let i = 0; i < props.headers.length; i++) {
      const [content, align] = props.headers[i].split(":");
      tableHeaders.push(
        <th key={i} className={`txt--${align}`}>
          <span>{content}</span>
        </th>
      )
    }
    return (<tr>{tableHeaders}</tr>);
  }

  const createTable = () => {
    let table = [];

    // We don't want to show the id of the item in the table, but we have it in the array if we want to fetch details for it when clicked.
    let loopStart = props.onClick ? 1 : 0;
    // Outer loop to create parent
    for (let i = 0; i < props.rows.length; i++) {
      let children: any = []
      //Inner loop to create children
      for (let j = loopStart; j < props.rows[i].length; j++) {
        let rowItems = props.rows[i];
        children.push(
          <td key={j}>
            {rowItems[j]}
          </td>
        )
      }
      //Create the parent and add the children
      table.push(<tr style={props.onClick ? { cursor: "pointer" } : {}} onClick={() => handleItemClick(props.rows[i][0])} key={i}>{children}</tr>)
    }
    return table
  }

  // Table body content placeholders while loading
  const createPlaceholders = () => {
    let placeholders = [];

    for (let i = 0; i < 3; i++) {
      let placeholderChildren = [];

      for (let j = 0; j < props.headers.length; j++) {
        placeholderChildren.push(
          <td key={j}>
            <span className="skeleton--animation"></span>
          </td>
        )
      }

      placeholders.push(<tr key={i}>{placeholderChildren}</tr>)
    }

    return placeholders;
  }

  const backgroundColor = props.changeBackgroundColor ? { backgroundColor: "#1f2e38" } : {}
  
  return (
    <React.Fragment>
      <table className={`table ${props.tableModifier ? props.tableModifier : ''}`}>
        <thead style={backgroundColor}> 
          {createTableHeaders()}
        </thead>
        {props.isLoaded ?
          <tbody>
            {createTable()}
          </tbody>
          :
          <tbody className="skeleton skeleton__table">
            {createPlaceholders()}
          </tbody>
        }
      </table>

      {
        props.isLoaded && props.rows.length < 1 ?
          <Animation show={true} type="fade" itemIndex={1}>
            <h5 className="p--20 txt--grey txt--center width--full">{props.placeholderText || "No data available"}</h5>
          </Animation>
          : null
      }
    </React.Fragment >
  );
};

export default Table;
