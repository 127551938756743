import { combineReducers, AnyAction } from 'redux';
import user from "./user";
import microservice from "./microservice";
import component from "./component";
import pipeline from "./pipeline";
import stage from "./stage";
import project from "./project";
import awsAccount from "./aws-account";
import gcpAccount from "./gcp-account";
import azureAccount from "./azure-account";
import gitAccount from './git-account';
import notification from "./notification";
import build from "./build";
import kubernetes from "./kubernetes";
import dockerRegistry from "./docker-registry";

import { UserState, ComponentState, MicroserviceState, PipelineState, StageState, ProjectState, AWSAccountState, GitAccountState, NotificationState, BuildState, KubernetesState, DockerRegistryState, GCPAccountState, AzureAccountState } from '../types';

import * as constants from "../constants";

const appReducer = combineReducers({
  user,
  microservice,
  component,
  pipeline,
  stage,
  project,
  awsAccount,
  gcpAccount,
  azureAccount,
  gitAccount,
  notification,
  build,
  kubernetes,
  dockerRegistry,
});

export default (state: any, action: AnyAction) => {
  if (action.type === constants.CURRENT_PROJECT_SELECTED) {
    // Clean up the whole Redux state except for the user and project state
    state = {
      user: state.user,
      project: state.project
    };
  }

  return appReducer(state, action);
}

export interface GlobalState {
  user: UserState;
  component: ComponentState;
  microservice: MicroserviceState;
  pipeline: PipelineState;
  stage: StageState;
  project: ProjectState
  awsAccount: AWSAccountState,
  gcpAccount: GCPAccountState,
  azureAccount: AzureAccountState,
  gitAccount: GitAccountState,
  notification: NotificationState,
  build: BuildState,
  kubernetes: KubernetesState,
  dockerRegistry: DockerRegistryState
}
