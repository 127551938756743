import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";
import { UpdateAzureAccountRequest } from "@microtica/ms-aws-sdk";

export interface UpdateAzureAccountStart {
    type: constants.UPDATE_AZURE_ACCOUNT_START;
}
export interface UpdateAzureAccountSuccess {
    type: constants.UPDATE_AZURE_ACCOUNT_SUCCESS;
    tenantId: string;
    subscriptionId: string;
    object: UpdateAzureAccountRequest;
}
export interface UpdateAzureAccountError {
    type: constants.UPDATE_AZURE_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export type UpdateAzureAccount =
    | UpdateAzureAccountStart
    | UpdateAzureAccountSuccess
    | UpdateAzureAccountError;

export function updateAzureAccountStart(): UpdateAzureAccount {
    return {
        type: constants.UPDATE_AZURE_ACCOUNT_START
    };
}
export function updateAzureAccountError(error: ErrorInterface): UpdateAzureAccount {
    return {
        type: constants.UPDATE_AZURE_ACCOUNT_FAIL,
        error
    };
}
export function updateAzureAccountSuccess(
    tenantId: string,
    subscriptionId: string,
    object: UpdateAzureAccountRequest
): UpdateAzureAccount {
    return {
        type: constants.UPDATE_AZURE_ACCOUNT_SUCCESS,
        tenantId,
        subscriptionId,
        object
    };
}

export function updateAzureAccount(
    tenantId: string,
    subscriptionId: string,
    projectId: string,
    object: UpdateAzureAccountRequest
): ThunkAction<Promise<void>, {}, {}, UpdateAzureAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateAzureAccount>) => {
        dispatch(updateAzureAccountStart());
        try {
            await getAwsAPI().updateAzureAccount(tenantId, subscriptionId, projectId, object);
            dispatch(updateAzureAccountSuccess(tenantId, subscriptionId, object));
        } catch (error) {
            dispatch(updateAzureAccountError(error.response.data));
        }
    };
}
