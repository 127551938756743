import { FetchAWSAccounts, ConnectAwsAccount,UpdateAwsAccount, DeleteAwsAccount } from '../actions';
import { AWSAccountState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';

const externalIdValue: string = "externalIdValue"
export const initialState: AWSAccountState = {
    awsAccounts: [],
    ecrRepositories: [],
    isFetching: false,
    isFinished: false,
    error: errorEmptyObject,
    awsAccountConnected: false
}
export default function reducer(state: AWSAccountState = initialState, action: FetchAWSAccounts  | ConnectAwsAccount | DeleteAwsAccount | UpdateAwsAccount): AWSAccountState {
    switch (action.type) {

        case constants.FETCH_AWS_ACCOUNTS_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_AWS_ACCOUNTS_SUCCESS: return { ...state, awsAccounts: action.awsAccounts, isFetching: false, isFinished: true, error: errorEmptyObject };
        case constants.FETCH_AWS_ACCOUNTS_FAIL: return { ...state, error: action.error, isFetching: false, isFinished: true };

        case constants.CONNECT_AWS_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject, awsAccountConnected: false };
        case constants.CONNECT_AWS_ACCOUNT_SUCCESS: {
            return {
                ...state,
                awsAccounts: [
                    ...state.awsAccounts,
                    action.awsAccount
                ],
                isFetching: false,
                error: errorEmptyObject,
                awsAccountConnected: true
            };
        }
        case constants.CONNECT_AWS_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false, awsAccountConnected: false };
        case constants.CONNECT_AWS_ACCOUNT_CLEAR: return { ...state, error: errorEmptyObject, isFetching: false, awsAccountConnected: false };


        case constants.DELETE_AWS_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.DELETE_AWS_ACCOUNT_SUCCESS: return {
            ...state,
            awsAccounts: state.awsAccounts.filter(acc => acc.id !== action.accountId),
            isFetching: false,
            error: errorEmptyObject
        };
        case constants.DELETE_AWS_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };

        case constants.UPDATE_AWS_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.UPDATE_AWS_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                awsAccounts: state.awsAccounts.map(acc => {
                    if (acc.id !== action.accountId) {
                        return acc;
                    } else {
                        return {
                            ...acc,
                            externalId: externalIdValue,
                            iamRole: action.object.iamRole ? action.object.iamRole : acc.iamRole,
                            accountName: action.object.accountName ? action.object.accountName : acc.accountName
                        };
                    }
                })
            };
        case constants.UPDATE_AWS_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };


        default: {
            return state;
        }
    }
}
