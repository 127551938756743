import { io } from "socket.io-client";

import { MsEngineApi } from '@microtica/ms-engine-sdk';
import { MsApApi } from '@microtica/ms-ap-sdk';
import { MsUserApi } from "@microtica/ms-usermanagement-sdk";
import { MsProjectApi } from "@microtica/ms-project-sdk";
import { MsNotificationApi } from "@microtica/ms-notification-sdk";
import { ComponentInstanceSchedulerApi } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { MsKubeApi } from "@microtica/ms-kube-sdk";
import { MsAwsApi } from "@microtica/ms-aws-sdk";
import { MsElasticsearchApi } from "@microtica/ms-elasticsearch-sdk";

type WindowConfig = Window & { env: { BACKEND_ENDPOINT: string } }

export function getBaseUrl(): string {
    if ((window as unknown as WindowConfig).env) {
        return (window as unknown as WindowConfig).env.BACKEND_ENDPOINT;
    } else {
        return "http://localhost:80"
    }
};

export function getIdToken(): string | undefined {
    if (process.env.NODE_ENV === "test") {
        return "testing_access_token";
    }
    return localStorage.getItem("idToken") || undefined;
}

export function getEngineAPI() {
    return new MsEngineApi({
        basePath: `${getBaseUrl()}/ms-engine`,
        accessToken: getIdToken()
    });
}

export function getContinuousIntegrationAPI() {
    return new MsApApi({
        basePath: `${getBaseUrl()}/ms-ap`,
        accessToken: getIdToken()
    });
}

export function getNotificationAPI() {
    return new MsNotificationApi({
        basePath: `${getBaseUrl()}/ms-notification`,
        accessToken: getIdToken()
    });
}

export function getUserManagementAPI() {
    return new MsUserApi({
        basePath: `${getBaseUrl()}/ms-usermanagement`,
        accessToken: getIdToken()
    });
}

export function getProjectAPI() {
    return new MsProjectApi({
        basePath: `${getBaseUrl()}/ms-project`,
        accessToken: getIdToken()
    });
}

export function getCloudCostOptimizerAPI() {
    return new ComponentInstanceSchedulerApi({
        basePath: `${getBaseUrl()}/ms-cloud-cost-optimizer`,
        accessToken: getIdToken()
    });
}

export function getAwsAPI() {
    return new MsAwsApi({
        basePath: `${getBaseUrl()}/ms-aws`,
        accessToken: getIdToken()
    });
}

export function getKubeAPI() {
    return new MsKubeApi({
        basePath: `${getBaseUrl()}/ms-kube`,
        accessToken: getIdToken()
    });
}

export function getElasticSearchAPI() {
    return new MsElasticsearchApi({
        basePath: `${getBaseUrl()}/ms-elasticsearch`,
        accessToken: getIdToken()
    });
}

export function socketIO(queryParams: any) {
    return io(
        getBaseUrl(), {
        path: "/ms-realtime/websockets/",
        withCredentials: true,
        reconnection: false,
        transports: ['polling', 'websocket'],
        query: {
            ...queryParams,
            Authorization: `Bearer ${getIdToken()}`
        }
    });
}

export function disconnectSocketIO() {
    return io().disconnect();
}