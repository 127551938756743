import * as constants from "../../constants";
import { getProjectAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { toast } from "react-toastify";
import { trackUserRemoved } from "../../tracking/user-settings";

export interface RemoveUserFromProjectStart {
    type: constants.REMOVE_USER_FROM_PROJECT_START;
}
export interface RemoveUserFromProjectSuccess {
    type: constants.REMOVE_USER_FROM_PROJECT_SUCCESS
    userId: string;
}
export interface RemoveUserFromProjectError {
    type: constants.REMOVE_USER_FROM_PROJECT_FAIL;
    error: ErrorInterface;
}

export type RemoveUserFromProject =
    | RemoveUserFromProjectStart
    | RemoveUserFromProjectSuccess
    | RemoveUserFromProjectError;


export function removeUserFromProjectStart(): RemoveUserFromProject {
    return {
        type: constants.REMOVE_USER_FROM_PROJECT_START
    };
}
export function removeUserFromProjectError(error: ErrorInterface): RemoveUserFromProject {
    return {
        type: constants.REMOVE_USER_FROM_PROJECT_FAIL,
        error
    };
}
export function removeUserFromProjectSuccess(
    userId: string
): RemoveUserFromProject {
    return {
        type: constants.REMOVE_USER_FROM_PROJECT_SUCCESS,
        userId
    };
}
export function removeUserFromProject(
    projectId: string,
    userId: string,
    userEmail: string
): ThunkAction<Promise<void>, {}, {}, RemoveUserFromProject> {
    return async (dispatch: ThunkDispatch<{}, {}, RemoveUserFromProject>) => {
        dispatch(removeUserFromProjectStart());
        try {
            await getProjectAPI().removeUser(projectId, userId, window.location.origin);
            toast.success("Successful removed user from project");
            trackUserRemoved(userEmail);
            dispatch(removeUserFromProjectSuccess(userId));
        } catch (error) {
            dispatch(removeUserFromProjectError(error.response.data));
            toast.error(error.response.data.message)
        }
    };
}

