import React, { useState } from "react";
import TabMenuItem from '../TabMenuItem/TabMenuItem';

export interface Tab {
    id: string,
    label: string
}

export interface TabMenuProps {
    tabs: Tab[];
    value?: Tab;
    class?: string;
    onClick?: (tab: Tab) => void;
}


const TabMenuContainer = (props: TabMenuProps) => {
    const [activeTab, setActiveTab] = useState(props.value || props.tabs[0]);
    function handleOnClick(id: string) {

        const activeTab = props.tabs.find(tab => {
            return tab.id === id;
        });
        setActiveTab(activeTab!);
        props.onClick && props.onClick(activeTab!)
    }

    // Handle tab item underline animation
    const [underline, setUnderline] = useState({
        left: 0,
        width: 0,
        opacity: 1,
        height: "3px"
    })

    // Hide tab item underline if user is not hovering on tab item
    const removeUnderline = () => {
        setUnderline({
            ...underline,
            opacity: 0,
            height: "0px"
        })
    }

    const handleUnderline = (e: any) => {
        const elements = document.querySelectorAll(".tab-menu__item");
        const tabItemsArray = [] as any;
        let elementWidth: number;
        let currentIndex = parseInt(e.target.dataset.index);

        // Go trough each item, copy object properties and add element's width
        elements.forEach((element, index) => {
            const elementProperties = element.getBoundingClientRect()
            elementWidth = elementProperties.width;

            tabItemsArray.push({
                ...props.tabs[index],
                elementWidth
            })
        })

        // Cycle trough each element and add the element's width to the underline offset value
        let underlineOffsetPosition = 0;
        elements.forEach((item, index) => {
            if (index < currentIndex) {
                underlineOffsetPosition += tabItemsArray[index].elementWidth
            }
        })

        // Underline values
        let left = currentIndex === 0 ? 0 : underlineOffsetPosition;
        let width = tabItemsArray[currentIndex].elementWidth;

        setUnderline({
            left: left,
            width: width,
            opacity: 1,
            height: "4px"
        });
    }

    return (
        <div className={`tab-menu ${props.class ? props.class : ""}`}>
            {props.tabs.map((tab, index) => (
                <TabMenuItem
                    key={tab.id}
                    label={tab.label}
                    onClick={() => handleOnClick(tab.id)}
                    isActive={activeTab.id === tab.id}
                    handleUnderline={handleUnderline}
                    removeUnderline={removeUnderline}
                    index={index}
                />
            ))}
            {props.class === "tab-menu--big" || props.class === "tab-menu--deployment" || props.class === "tab-menu--namespaces" ?
                <div className="underline" style={{
                    position: "absolute",
                    bottom: "3px",
                    backgroundColor: "#007dc5",
                    height: underline.height,
                    opacity: underline.opacity,
                    left: underline.left,
                    width: underline.width,
                    transition: "0.5s ease"
                }}>
                </div>
                : null}
        </div>
    )
}


export default TabMenuContainer;