import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import HelpIcon from "@material-ui/icons/Help";
import Table from "../../components/Table/Table";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";

interface AdvisorStatisticsProps {
  isLoaded: boolean;
  isBusy: boolean;
  title: string;
  status: string[];
  description: string[];
  summary: string[];
  headers: string[];
  rowsMap: any[][];
}

function mapStatusToIcon(isBusy: boolean, status: string[]) {
  return isBusy ? ( // show placeholder
    <div className="skeleton skeleton__circle skeleton--animation skeleton--placeholder icon-placeholder"></div>
  ) : !status.length ? (
    <HelpIcon fontSize="large" className="status-icon status-unknown" />
  ) : status.includes("warning") ? (
    <WarningIcon className="txt--yellow status-icon" fontSize="large" />
  ) : (
    <CheckmarkIcon className="circle circle--lg circle--success status-icon status-ok" />
  );
}

function getSummary(
  isBusy: boolean,
  isLoaded: boolean,
  status: string,
  summary: string
) {
  return isBusy ? ( // show placeholder
    <p className="skeleton skeleton--animation skeleton--placeholder summary-placeholder"></p>
  ) : (
    isLoaded && (
      <div id="stats_content">
        <p
          className={`summary ${
            status === "warning" ? "txt--yellow" : "txt--green"
          }`}
        >
          {summary}
        </p>
      </div>
    )
  );
}

const AdvisorStatistics = (props: AdvisorStatisticsProps) => {
  return (
    <div className="content__body card light card-shadow statistics-content">
      <div className="details__item">
        <div className="general-info">
          <div className="details__title">
            {mapStatusToIcon(props.isBusy, props.status)}
            <h5>
              <strong>{props.title}</strong>
            </h5>
          </div>
          <div className="details__subtitle">
            {props.description.map((description, index) => (
              <span key={index}>
                {!props.isLoaded && index !== 0 && <br />}
                <strong>{description}</strong>
                {getSummary(
                  props.isBusy,
                  props.isLoaded,
                  props.status[index],
                  props.summary[index]
                )}
               </span>
            ))}
          </div>
        </div>
        {props.isBusy ? (
          <Table
            headers={props.headers}
            rows={props.rowsMap}
            isLoaded={false}
            changeBackgroundColor={true}
          />
        ) : (
          props.isLoaded && (
            <Table
              headers={props.headers}
              rows={props.rowsMap}
              isLoaded={true}
              changeBackgroundColor={true}
            />
          )
        )}
      </div>
    </div>
  );
};

export default AdvisorStatistics;
