import * as constants from "../../../constants";
import {
    AddAwsAccountRequest, GetAwsAccountResponse
} from "@microtica/ms-aws-sdk";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";

// Create microservice
export interface ConnectAwsAccountStart {
    type: constants.CONNECT_AWS_ACCOUNT_START;
}
export interface ConnectAwsAccountSuccess {
    type: constants.CONNECT_AWS_ACCOUNT_SUCCESS;
    awsAccount: GetAwsAccountResponse;
}
export interface ConnectAwsAccountError {
    type: constants.CONNECT_AWS_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export interface ConnectAwsAccountClear {
    type: constants.CONNECT_AWS_ACCOUNT_CLEAR;
}


export type ConnectAwsAccount =
    | ConnectAwsAccountStart
    | ConnectAwsAccountSuccess
    | ConnectAwsAccountError
    | ConnectAwsAccountClear

export function connectAwsAccountStart(): ConnectAwsAccount {
    return {
        type: constants.CONNECT_AWS_ACCOUNT_START
    };
}
export function connectAwsAccountError(error: ErrorInterface): ConnectAwsAccount {
    return {
        type: constants.CONNECT_AWS_ACCOUNT_FAIL,
        error
    };
}

export function connectAwsAccountClear(): ConnectAwsAccount {
    return {
        type: constants.CONNECT_AWS_ACCOUNT_CLEAR
    };
}

export function connectAwsAccountSuccess(
    awsAccount: GetAwsAccountResponse
): ConnectAwsAccount {
    return {
        type: constants.CONNECT_AWS_ACCOUNT_SUCCESS,
        awsAccount
    };
}

export function connectAwsAccount(
    projectId: string,
    object: AddAwsAccountRequest
): ThunkAction<Promise<void>, {}, {}, ConnectAwsAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, ConnectAwsAccount>) => {
        dispatch(connectAwsAccountStart());
        try {
            await getAwsAPI().addAwsAccount(projectId, object);
            dispatch(connectAwsAccountSuccess({
                ...object,
                projectId
            }));
        } catch (error) {
            dispatch(connectAwsAccountError(error.response.data));
        }
    };
}