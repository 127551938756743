import { errorEmptyObject, DockerRegistryState } from '../types/index';
import * as constants from '../constants';
import { FetchDockerRegistries } from '../actions';
import { DeleteDockerRegistry } from '../actions/docker-registries/delete-docker-registry';


export const initialState: DockerRegistryState = {
    dockerRegistries: [],
    isProcessing: false,
    error: errorEmptyObject
}
export default function reducer(state: DockerRegistryState = initialState, action: FetchDockerRegistries | DeleteDockerRegistry): DockerRegistryState {
    switch (action.type) {

        // Fetch Docker Registries
        case constants.FETCH_DOCKER_REGISTRIES_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_DOCKER_REGISTRIES_SUCCESS: return { ...state, dockerRegistries: action.dockerRegistries, isProcessing: false, error: errorEmptyObject };
        case constants.FETCH_DOCKER_REGISTRIES_FAIL: return { ...state, error: action.error, isProcessing: false };

        // Delete Docker registry
        case constants.DELETE_DOCKER_REGISTRY_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.DELETE_DOCKER_REGISTRY_SUCCESS: return { ...state, isProcessing: false, error: errorEmptyObject, dockerRegistries: state.dockerRegistries.filter(registry => registry.registryName !== action.registryName) };
        case constants.DELETE_DOCKER_REGISTRY_FAIL: return { ...state, isProcessing: false, error: action.error };

        default: {
            return state
        }
    }
}
