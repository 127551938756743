import React, { useState, useEffect } from "react";
import { useModal } from 'react-modal-hook';
import ListView from "../../components/ListView/ListView";

// Import icons
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import useAwsAccountList from "../../hooks/AwsAccount";
import { ListItemProps } from "../../components/ListItem/ListItem";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../reducers";
import { deleteAwsAccount } from "../../actions";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";
import CloudAccountModal from "../../components/CloudAccount/CloudAccountModal";

const AwsAccounts = (props: { searchText?: string }) => {
  const { awsAccounts } = useAwsAccountList();
  const [awsAccountList, setAwsAccountList] = useState<ListItemProps[]>([]);
  const [awsAccountId, setAwsAccountId] = useState("");
  const [selectedAWSAccount, setSelectedAwsAccount] = useState({ id: "", name: "" })
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const dispatch = useDispatch();
  const [filterAwsAccounts, setFilterAwsAccounts] = useState("");

  // Check if request to server is still processing - to be able to display real data or skeleton placeholder
  const isProcessing = useSelector((state: GlobalState) => state.project.isProcessing);

  const [showAwsModal, hideAwsModal] = useModal(() => (<CloudAccountModal
    awsAccount={awsAccounts.find(acc => acc.id === awsAccountId)}
    onClose={handleHideAwsModal}
  />), [awsAccounts, awsAccountId]);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedAWSAccount.name}" account?`}
      description="Do you really want to delete the aws account. This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleDeleteAwsAccountConfirmation}
    />), [selectedAWSAccount]);

  function handleHideAwsModal() {
    setAwsAccountId("");
    hideAwsModal();
  }
  function handleDeleteAwsAccount(accountId: string, name?: string) {
    setSelectedAwsAccount({id: accountId, name: name || accountId })
    showConfirmationModal();
  }

  function handleUpdateAwsAccount(accountId: string) {
    setAwsAccountId(accountId)
    showAwsModal();
  }

  async function handleDeleteAwsAccountConfirmation() {
    dispatch(deleteAwsAccount(currentProject.id, selectedAWSAccount.id));
    setSelectedAwsAccount({id: "", name: ""})
    hideConfirmationModal();
  }

  useEffect(() => {
    setAwsAccountList(awsAccounts.map(acc => ({
      itemId: acc.id,
      title: acc.accountName || acc.id,
      description: acc.iamRole
    }))
      .filter(item => {
        if (filterAwsAccounts !== "") {
          return item.title
            .toLocaleLowerCase()
            .includes(
              filterAwsAccounts.toLocaleLowerCase()
            );
        } else {
          return true;
        }
      }));
  }, [awsAccounts, filterAwsAccounts]);

  useEffect(() => {
    setFilterAwsAccounts(props.searchText!);
  }, [props.searchText]);

  // Dropdown menu action items
  const itemActions = [
    {
      title: "Edit",
      icon: <UpdateIcon />,
      onClick: handleUpdateAwsAccount
    },
    {
      title: "Remove",
      icon: <RemoveIcon />,
      onClick: handleDeleteAwsAccount
    }
  ]

  return (
    <React.Fragment>
      {!isProcessing ?
        awsAccountList && awsAccountList.length > 0 &&
        <>
          <h5 className="pl--10">AWS Accounts</h5>
          <ListView
            class="list--settings pr--20"
            listType="icons"
            itemActions={itemActions}
            items={awsAccountList}
          />
        </>
        : [...Array(3)].map((_value, index) => (<ListItemSkeleton key={index} />))
      }
    </React.Fragment>
  )
}

export default AwsAccounts;