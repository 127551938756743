import * as constants from "../../constants";
import { getKubeAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { CreateKubernetesRequest } from "@microtica/ms-kube-sdk";

// Add kubernetes
export interface AddKubernetesStart {
    type: constants.ADD_KUBERNETES_START;
}
export interface AddKubernetesSuccess {
    type: constants.ADD_KUBERNETES_SUCCESS;
    data: CreateKubernetesRequest & {
        podStatus: {
            pendingPods: number;
            runningPods: number;
            succeededPods: number;
            failedPods: number;
            totalPods: number;
        }
    };
    projectId: string;
}
export interface AddKubernetesError {
    type: constants.ADD_KUBERNETES_FAIL;
    error: ErrorInterface;
}

export type AddKubernetes =
    | AddKubernetesStart
    | AddKubernetesSuccess
    | AddKubernetesError

export function addKubernetesStart(): AddKubernetesStart {
    return {
        type: constants.ADD_KUBERNETES_START
    };
}
export function addKubernetesError(error: ErrorInterface): AddKubernetesError {
    return {
        type: constants.ADD_KUBERNETES_FAIL,
        error
    };
}

export function addKubernetesSuccess(
    data: CreateKubernetesRequest & {
        podStatus: {
            pendingPods: number;
            runningPods: number;
            succeededPods: number;
            failedPods: number;
            totalPods: number;
        }
    },
    projectId: string
): AddKubernetesSuccess {
    return {
        type: constants.ADD_KUBERNETES_SUCCESS,
        data,
        projectId
    };
}

export function addKubernetes(
    projectId: string,
    data: CreateKubernetesRequest
): ThunkAction<Promise<void>, {}, {}, AddKubernetes> {
    return async (dispatch: ThunkDispatch<{}, {}, AddKubernetes>) => {
        dispatch(addKubernetesStart());
        try {
            await getKubeAPI().createKubernetes(projectId, data);
            // the created kubernetes will have the same value for id and name
            // that's why data.name is passed as clusterId here
            const { data: kubeStatus } = await getKubeAPI().getKubernetesStatus(data.name, projectId);
            dispatch(addKubernetesSuccess({ ...data, podStatus: kubeStatus.podStatus }, projectId));
        } catch (error) {
            dispatch(addKubernetesError(error.response.data));
        }
    };
}