import React from "react";
import notificationIcon from "../../static/023-notification.svg";

export interface Props {
  show: boolean;
  numberOfNotifications: number;
  onClick: React.MouseEventHandler;
}

function NotificationBadge(props: Props) {
  return (
    <div className="notification-wrapper">
      <div className={`notification ${props.show ? "notification--show" : ""} clickable`} data-notification={props.numberOfNotifications} onClick={props.onClick}>
        <img className="notification__icon" src={notificationIcon} alt="notification"/>
      </div>
    </div >
  );
}

export default NotificationBadge;
