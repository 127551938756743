import { errorEmptyObject, AzureAccountState } from '../types/index';
import * as constants from '../constants';
import { FetchAzureAccounts, ConnectAzureAccount, DeleteAzureAccount, UpdateAzureAccount } from '../actions';

export const initialState: AzureAccountState = {
    azureAccounts: [],
    isFetching: false,
    isFinished: false,
    error: errorEmptyObject,
    azureAccountConnected: false
}

export default function reducer(state: AzureAccountState = initialState, action: FetchAzureAccounts  | ConnectAzureAccount | DeleteAzureAccount | UpdateAzureAccount): AzureAccountState {
    switch (action.type) {
        case constants.FETCH_AZURE_ACCOUNTS_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_AZURE_ACCOUNTS_SUCCESS: return { ...state, azureAccounts: action.azureAccounts, isFetching: false, isFinished: true, error: errorEmptyObject };
        case constants.FETCH_AZURE_ACCOUNTS_FAIL: return { ...state, isFetching: false, isFinished: true, error: action.error };

        case constants.CONNECT_AZURE_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject, azureAccountConnected: false };
        case constants.CONNECT_AZURE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                azureAccounts: [
                    ...state.azureAccounts,
                    action.azureAccount
                ],
                isFetching: false,
                error: errorEmptyObject,
                azureAccountConnected: true
            };
        }
        case constants.CONNECT_AZURE_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false, azureAccountConnected: false };
        case constants.CONNECT_AZURE_ACCOUNT_CLEAR: return { ...state, error: errorEmptyObject, isFetching: false, azureAccountConnected: false };

        case constants.UPDATE_AZURE_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.UPDATE_AZURE_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };
        case constants.UPDATE_AZURE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                azureAccounts: state.azureAccounts.map(acc => {
                    if (acc.tenantId !== action.tenantId && acc.subscriptionId !== action.subscriptionId) {
                        return acc;
                    } else {
                        return {
                            ...acc,
                            applicationId: action.object.applicationId || acc.applicationId,
                            subscriptionName: action.object.subscriptionName || acc.subscriptionName,
                            clientSecret: action.object.clientSecret || acc.clientSecret
                        };
                    }
                })
            };

        case constants.DELETE_AZURE_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.DELETE_AZURE_ACCOUNT_SUCCESS: return {
            ...state,
            azureAccounts: state.azureAccounts.filter(acc => (acc.tenantId !== action.tenantId) || (acc.subscriptionId !== action.subscriptionId)),
            isFetching: false,
            error: errorEmptyObject
        };
        case constants.DELETE_AZURE_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };

        default: {
            return state;
        }
    }
}
