import React, { useState, useEffect, useRef } from "react";
// Import icons
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import Animation from "../Animations/Animations";
import { PipelineBuildDetailsSteps } from "@microtica/ms-ap-sdk";
import { ReactComponent as Expand} from "../../static/expand.svg";
import {ReactComponent as Collapse} from "../../static/collapse.svg";

export interface PipelineCollapseProps {
    collapseClosed?: boolean;
    step?: PipelineBuildDetailsSteps
    logs?: string;
    handleCloseCollapse(): void;
}


//Table headers
const headers = [
    "Artifacts",
    ""
];

const tabs = [
    { id: "1", label: "Logs" },
    { id: "2", label: "Artifacts" }
]

const PipelineCollapse = (props: PipelineCollapseProps) => {
    const [width, setWidth] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<{ id: string; label: string }>();
    const [resizeStyle, setResizeStyle] = useState("");
    const ref = useRef<any>();
    useEffect(() => {
        if (props.collapseClosed) {
            setActiveTab(tabs[0])
        } else {
            setActiveTab(undefined)
        }
    }, [props.collapseClosed])

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth)
        }
    }, [ref.current]);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(ref.current.offsetWidth))
        return window.removeEventListener('resize', () => setWidth(ref.current.offsetWidth));

    }, []);

    // Create table headers
    const createTableHeaders = () => {
        let tableHeaders = [];
        for (let i = 0; i < headers.length; i++) {
            tableHeaders.push(
                <th key={i} className="txt--left">
                    <span>{headers[i]}</span>
                </th>
            )
        }
        return (<tr>{tableHeaders}</tr>);
    }

    const createTable = () => {
        let table = [];
        let files: any = []

        if (props.step && props.step.artifacts) {
            Object.entries((props.step.artifacts as { files: object }).files).map((key) => {
                files.push([
                    key[0],
                    key[1].files,
                    <a style={{ color: "#4a90e2" }}
                        href={key[1].location}
                        target="_blank"
                        rel="noreferrer">Download</a>
                ])
            })

            Object.entries((props.step.artifacts as { reports: object }).reports).map((key) => {
                files.push([
                    `${key[0]} (report)`,
                    key[1].files,
                    <a style={{ color: "#4a90e2" }}
                        href={key[1].location}
                        target="_blank"
                        rel="noreferrer">Download</a>
                ])
            })

            if ((props.step.artifacts as { json: object }).json) {
                files.push([
                    "json",
                    (props.step.artifacts as { json: { files: object } }).json.files,
                    <a style={{ color: "#4a90e2" }}
                        href={(props.step.artifacts as any).json.location}
                        target="_blank"
                        rel="noreferrer">Download</a>
                ])
            }
        }

        // We don't want to show the id of the item in the table, but we have it in the array if we want to fetch details for it when clicked.
        // let loopStart = props.onClick ? 1 : 0;
        // Outer loop to create parent
        for (let i = 0; i < files.length; i++) {
            let children: any = []
            //Inner loop to create children
            for (let j = 0; j < files[i].length; j++) {
                let rowItems = files[i];
                children.push(
                    <td key={j}>
                        <div>{rowItems[j]}</div>
                        <small>{rowItems[++j]}</small>
                    </td>
                )
            }
            //Create the parent and add the children
            table.push(<tr style={{ cursor: "pointer" }} key={i}>{children}</tr>)
        }
        return table
    }

    function handleOnClick(id: string) {
        if (props.step) {
            const activeTab = tabs.find(tab => {
                return tab.id === id;
            });
            setActiveTab(activeTab);
        }
    }

    const resizeBuildLogsDialog = () => {
        if (!resizeStyle) {
            setResizeStyle("resize");
        } else {
            setResizeStyle("");
        }
    };

    return (
        <React.Fragment>
            {/* content */}
            <div className="collapse">
                <div style={{ width: width }} className={`collapse__sidemenu ${resizeStyle} ${(props.collapseClosed) ? 'collapse__sidemenu--open open-sidemenu' : null}`}>
                    {/* collapse sidemenu header */}
                    <div className="collapse__header" >
                        <h5><strong>
                            {props.step !== undefined ? props.step.name : ""}
                        </strong></h5>
                        <div>
                            <span title="resize" className="resize_button" onClick={() => resizeBuildLogsDialog()}> 
                             { resizeStyle == "" ? <Expand /> : <Collapse /> } </span>
                        </div>
                            <span className="close" onClick={() => props.handleCloseCollapse()}> X </span>
            
                    </div>
                    <div className="collapse__container">
                        {
                            activeTab && props.step ? activeTab.id === "1" ?
                                <div className="details__container">
                                    <CustomScrollbars maxHeight={"calc(100vh - 10px)"} customClass="collapse">
                                        <code className="details__code-block details__code-block--lg">
                                            {
                                                props.logs == "" ? <p>Preparing logs...</p> :
                                                    <pre style={{ color: "white" }}>
                                                        {props.logs}
                                                    </pre>
                                            }
                                        </code>
                                    </CustomScrollbars>
                                </div> : <div>
                                    {
                                        props.step.artifacts ? <div className="col-5">
                                            <table className="table">
                                                {createTableHeaders()}
                                                <tbody>
                                                    {createTable()}
                                                </tbody>
                                            </table>
                                        </div> : <Animation show={true} type="fade" itemIndex={1}>
                                        <h5 className="p--20 txt--grey txt--center width--full">This step does not have any artifacts.</h5>
                                        </Animation>
                                    }
                                </div> : null
                        }
                    </div>
                </div>

                {/* tabs */}
                <div ref={ref} className="tab-collapse tab-collapse--deployment">
                    {tabs.map((tab, index) => (
                        <span
                            key={tab.id + index}
                            onClick={() => handleOnClick(tab.id)}
                            className={`tab-collapse__item ${((activeTab && activeTab.id) === tab.id) ? 'active' : ''}`}
                            data-index={index}
                        >{tab.label}
                        </span>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default PipelineCollapse;