export function paymentLimitationsMap(entity: string) {
    switch (entity) {
        case "environmentsAllowed":
            return "environments";
        case "buildMinutes":
            return "build minutes";
        case "pipelinesManager":
            return "Pipeline manager";
        case "kubernetesDashboard":
            return "Kubernetes Dashboard";
        case "slackIntegration":
            return "Slack integration";
        case "cloudCostOptimizer":
            return "Cloud cost optimizer";
        case "environmentReplication":
            return "Environment replication";
        case "biggerRuntimeEnvironments":
            return "Medium and large sized pipeline runtimes";
        case "fullPackComponents":
            return "Full pack of infrastructure components";
        case "deploymentInsights":
            return "Deployment insights"    
        default:
            return "";
    }
}
