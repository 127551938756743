import React, { useState, useEffect, ReactElement } from 'react';

export interface CheckboxProps {
  name: string;
  description?: string | ReactElement;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean
}

function Checkbox(props: CheckboxProps) {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  function handleCheckboxClick() {
    const currentState = !checked;
    setChecked(currentState);
    props.onChange && props.onChange(currentState);
  }

  return (
    <div className="checkbox" style={props.disabled ? { pointerEvents: "none" } : {}} onClick={handleCheckboxClick}>
      <input disabled={props.disabled} type="checkbox" className="checkbox__input" checked={checked} readOnly/>
      <label className="checkbox__label"></label>

      <div className="checkbox__content">
        <h5 className="checkbox__title">{props.name}</h5>
        {props.description ? <p className="checkbox__description"> {props.description}</p> : null}
      </div>
    </div>
  );
}

export default Checkbox;