import * as constants from "../../constants";
import { getNotificationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";

export interface MarkNotificationAsReadStart {
    type: constants.MARK_NOTIFICATION_AS_READ_START;
}

export interface MarkNotificationAsReadSuccess {
    type: constants.MARK_NOTIFICATION_AS_READ_SUCCESS;
    notificationId: string;
}

export interface MarkNotificationAsReadFail {
    type: constants.MARK_NOTIFICATION_AS_READ_FAIL;
    error: ErrorInterface;
}

export type MarkNotificationAsRead =
    | MarkNotificationAsReadStart
    | MarkNotificationAsReadSuccess
    | MarkNotificationAsReadFail;


export function markNotificationAsReadStart(): MarkNotificationAsRead {
    return {
        type: constants.MARK_NOTIFICATION_AS_READ_START
    };
}

export function markNotificationAsReadSuccess(
    notificationId: string
): MarkNotificationAsReadSuccess {
    return {
        type: constants.MARK_NOTIFICATION_AS_READ_SUCCESS,
        notificationId
    };
}

export function markNotificationAsReadFail(error: ErrorInterface): MarkNotificationAsReadFail {
    return {
        type: constants.MARK_NOTIFICATION_AS_READ_FAIL,
        error
    };
}

export function markNotificationAsRead(
    projectId: string,
    notificationId: string
): ThunkAction<Promise<void>, {}, {}, MarkNotificationAsRead> {
    return async (dispatch: ThunkDispatch<{}, {}, MarkNotificationAsRead>) => {
        dispatch(markNotificationAsReadStart());
        try {
            await getNotificationAPI().markNotificationAsRead(notificationId, projectId);
            dispatch(markNotificationAsReadSuccess(notificationId));
        } catch (error) {
            dispatch(markNotificationAsReadFail(error.response.data));
        }
    };
}
