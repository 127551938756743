import React from "react";

const CardPlaceholders = () => {
  return (
    <React.Fragment>
      {[...Array(2)].map((_value, index) => (
        <div className="skeleton skeleton__card" key={index}>
          <div className="skeleton skeleton__header d-flex">
            <div className="mt--0 pl--20">
              <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
              <p className="skeleton skeleton--animation skeleton--placeholder"></p>
            </div>
            <div className="skeleton skeleton__button skeleton--animation skeleton--placeholder"></div>
          </div>

          <div className="skeleton skeleton__content mb--10 ml--10">
            <div className="skeleton skeleton__circle skeleton--animation skeleton--placeholder"></div>
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>
          </div>
        </div>
      ))
      }
    </React.Fragment >
  )
}

export default CardPlaceholders;