import * as constants from "../../constants";
import { getEngineAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { trackEnvRemoveFailed, trackEnvRemoveSucceeded } from "../../tracking/environment";

export interface DeleteStageStart {
    type: constants.DELETE_STAGE_START;
}

export interface DeleteStageSuccess {
    type: constants.DELETE_STAGE_SUCCESS;
    stageId: string;
}

export interface DeleteStageFail {
    type: constants.DELETE_STAGE_FAIL
    error: ErrorInterface;
}

export type DeleteStage =
    | DeleteStageStart
    | DeleteStageSuccess
    | DeleteStageFail;

export function deleteStageStart(): DeleteStage {
    return {
        type: constants.DELETE_STAGE_START
    };
}

export function deleteStageSuccess(
    stageId: string
): DeleteStageSuccess {
    return {
        type: constants.DELETE_STAGE_SUCCESS,
        stageId
    };
}

export function deleteStageFail(error: ErrorInterface): DeleteStageFail {
    return {
        type: constants.DELETE_STAGE_FAIL,
        error
    };
}

export function deleteStage(
    projectId: string,
    stageId: string,
    stageName: string
): ThunkAction<Promise<void>, {}, {}, DeleteStage> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteStage>) => {
        dispatch(deleteStageStart());
        try {
            await getEngineAPI().deleteStage(stageId, projectId);
            trackEnvRemoveSucceeded(stageId, stageName);
            dispatch(deleteStageSuccess(stageId));
        } catch (e) {
            dispatch(deleteStageFail(e.response.data));
            trackEnvRemoveFailed(stageId, stageName, JSON.stringify(e))
        }
    };
}

