import * as constants from "../../constants";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import { getEngineAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";


export interface FetchStagesStart {
  type: constants.FETCH_STAGES_START;
}

export interface FetchStagesSuccess {
  type: constants.FETCH_STAGES_SUCCESS;
  stages: GetStagesItem[];
}

export interface FetchStagesFail {
  type: constants.FETCH_STAGES_FAIL;
  error: ErrorInterface;
}

export type FetchStages =
  | FetchStagesStart
  | FetchStagesSuccess
  | FetchStagesFail;



export function fetchStagesStart(): FetchStages {
  return {
    type: constants.FETCH_STAGES_START
  };
}

export function fetchStagesSuccess(
  stages: GetStagesItem[] = []
): FetchStagesSuccess {
  return {
    type: constants.FETCH_STAGES_SUCCESS,
    stages
  };
}

export function fetchStagesFail(error: ErrorInterface): FetchStagesFail {
  console.log(error)
  return {
    type: constants.FETCH_STAGES_FAIL,
    error
  };
}

export function fetchStages(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchStages> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchStages>) => {
    dispatch(fetchStagesStart());
    try {
      const res = await getEngineAPI().getStages(projectId);
      dispatch(fetchStagesSuccess(res.data.stages));
    } catch (e) {
      dispatch(fetchStagesFail(e.response.data));
    }
  };
}

