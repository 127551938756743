import React, { ReactElement } from "react";
import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    ResponsiveContainer,
    Cell
} from "recharts";

interface BarChartWidgetProps {
    title: React.ReactElement | string;
    subtitle: React.ReactElement | string;
    value?: React.ReactElement | string;
    valueTitle?: React.ReactElement | string;
    valueSubtitle?: React.ReactElement | string;
    valueSubtitleClassName?: "success" | "fail";
    data: object[];
    xAxisDataKey: string;
    yAxisDataKey: string;
    size?: "large" | "small";
    placeholder?: ReactElement;
}

const BarChartWidget = (props: BarChartWidgetProps) => {
    const height = props.size == "small" ? 139 : 272;
    const placeholder = (
        <div className="widget widget-chart">
            <div className="widget-header">
                <div className="widget-title">{props.title}</div>
                <div className="widget-subtitle">{props.subtitle}</div>
            </div>
            <div className="placeholder">
                {props.placeholder}
            </div>
        </div>
    );
    return (
        props.placeholder ? placeholder :
            <div className="widget widget-chart">
                <div className="widget-header">
                    <div className="widget-title">{props.title}</div>
                    <div className="widget-subtitle">{props.subtitle}</div>
                </div>
                <div className="widget-content txt--center">
                    {
                        props.value ?
                            <div className="content-header">
                                <div className="header-title">{props.valueTitle}</div>
                                <h2 className="header-value">{props.value}</h2>
                                <div className={`header-subtitle subtitle--${props.valueSubtitleClassName}`}>
                                    {props.valueSubtitle}
                                </div>
                            </div>
                            : null
                    }
                    <div style={{ width: '100%', height: height }}>
                        <ResponsiveContainer>
                            <BarChart width={600} height={height} data={props.data}
                                margin={{ top: 5, right: 30, left: -10, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey={props.xAxisDataKey} />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey={props.yAxisDataKey}>
                                    {
                                        props.data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={(entry as any).barColor || "#2b7dc5"} />
                                        ))
                                    }
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
    )
};
export default BarChartWidget;