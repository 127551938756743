import React, { useState, useEffect, ChangeEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { UpdateStageRequestAwsRegionEnum, CreateStageRequestAwsRegionEnum, CreateStageRequestInfrastructureAsCodeToolEnum, CreateStageRequestCloudProviderEnum, UpdateStageRequestInfrastructureAsCodeToolEnum, UpdateStageRequestCloudProviderEnum, CreateStageRequestGcpRegionEnum, UpdateStageRequestGcpRegionEnum, CreateStageRequestGcpZoneEnum, UpdateStageRequestGcpZoneEnum } from "@microtica/ms-engine-sdk";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useModal } from "react-modal-hook";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContent from "../../components/PageContent/PageContent";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Checkbox from "../../components/Checkbox/Checkbox"

// Import icons in for stage status
import AwsAccount, { AwsAccountChangeEvent } from "../../components/AwsAccount/AwsAccount";
import { GlobalState } from "../../reducers";
import { getCloudCostOptimizerAPI, getEngineAPI } from "../../api";
import { InputField } from "../../components/InputField/InputField";
import { InputAreaField } from "../../components/InputTextarea/InputAreaField";

//import Types and utils
import { createStageSchema, updateStageSchema, createStageWithSavingScheduleSchema, updateStageSchemaWithSavingScheduleSchema } from "../../utils/validation";
import { AwsRegionType, CloudProvider, GcpRegionType, GcpZoneType, InfrastructureAsCodeTool } from "../../types";

import CardHeader from "../../components/CardHeader/CardHeader";
import awsRegions from "../../utils/aws-regions";
import Cron from "../CostOptimization/Cron"
import ModalPaymentLimit from "../../components/ModalPaymentLimit/ModalPaymentLimit";

import StrapiLogo from "../../static/strapi-logo.svg";
import KubernetesLogo from "../../static/kubernetesIcon.svg";

import environmentTemplates, { EnvironmentTemplateComponentProperty } from "../../utils/environment-templates";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import cloudTools from "../../utils/infrastructure-as-code-tools";
import cloudProviders from "../../utils/cloud-providers";
import AzureAccount, { AzureAccountChangeEvent } from "../../components/AzureAccount/AzureAccount";
import GcpAccount, { GcpAccountChangeEvent } from "../../components/GcpAccount/GcpAccount";
import gcpRegions from "../../utils/gcp-regions";
import gcpZones from "../../utils/gcp-zones";
import { trackEnvCreateFailed, trackEnvCreateInit, trackEnvCreateSucceeded, trackEnvReplicateFailed, trackEnvReplicateSucceeded } from "../../tracking/environment";

interface CreateStageProps extends RouteComponentProps<{ stageId: string }> { }

const CreateStage = (props: CreateStageProps) => {
  // Hooks
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [isReplicate] = useState(props.match.path.split("/").reverse()[0] === "replicate");
  const [isUpdate] = useState(props.match.path.split("/").reverse()[0] === "edit");
  const [stageId] = useState(props.match.params.stageId);
  const [stageName] = useState(props.match.params.stageId && props.match.params.stageId.split("-")[0]);
  const [name, setName] = useState(isReplicate ? `${stageName}Replica` : "");
  const [description, setDescription] = useState(isReplicate ? `Replica of ${stageName} environment` : "");
  const [cloudTool, setCloudTool] = useState<DropdownItem>();
  const [cloudProvider, setCloudProvider] = useState<DropdownItem>();
  const [selectedAwsAccount, setSelectedAwsAccount] = useState<AwsAccountChangeEvent>({ awsAccountId: undefined, awsRegion: undefined });
  const [selectedAzureAccount, setSelectedAzureAccount] = useState<AzureAccountChangeEvent>();
  const [selectedGcpAccount, setSelectedGcpAccount] = useState<GcpAccountChangeEvent>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [disableCloudAccountUpdate, setDisableCloudAccountUpdate] = useState(false);
  const [savingSchedule, setSavingSchedule] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [cronExpression, setCronExpression] = useState<{ startExpression: string; stopExpression: string; } | undefined>();
  const [timeZone] = useState(getLocalTimeZone());
  const [costOptimizer, setCostOptimizer] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [initialCronExpression] = useState<{ startExpression: string; stopExpression: string; } | undefined>();
  const [pageTitle] = useState(isUpdate ? "Update Environment" :
    isReplicate ?
      `Replicate Environment: ${stageName}` :
      "Create Environment"
  );

  const params = new URLSearchParams(props.location.search);
  const template = params.get("template") as ("strapi" | "kubernetes" | undefined);

  const [selectedTemplate, setSelectedTemplate] = useState<"strapi" | "kubernetes" | undefined>(template);

  const awsProvider = cloudProviders.find(provider => provider.id === "Aws");
  const cfnTool = cloudTools.find(tool => tool.id === "Cloudformation");

  const [showPaymentModal, hidePaymentModal] = useModal(() => (
    <ModalPaymentLimit
      title={paymentError}
      onCancel={hidePaymentModal}
      routeHistory={props}
    />
  ), [paymentError]);


  function getLocalTimeZone() {
    const zoneOffset = new Date().getTimezoneOffset();

    if (zoneOffset > 0) {
      return `UTC-${zoneOffset / 60}`;
    } else {
      return `UTC+${Math.abs(zoneOffset / 60)}`;
    }
  }

  async function handleSubmit(values: { name: string, description: string, cloudProvider: string, cloudTool: string, awsAccount?: string, awsRegion?: string, scheduleName?: string, azureSubscription?: string, azureTenant?: string, gcpProject?: string, gcpRegion?: string, gcpZone?: string }) {
    setIsBusy(true);
    const DEFAULT_TAG_KEY = "microtica:cost-optimizer";
    try {
      if (selectedTemplate) {
        await createFromTemplate(selectedTemplate, values.name, values.description);
      } else if (isUpdate) {
        await getEngineAPI().updateStage(
          stageId,
          currentProject.id,
          {
            name: values.name,
            description: values.description,
            awsAccountId: values.awsAccount!,
            awsRegion: UpdateStageRequestAwsRegionEnum[values.awsRegion as AwsRegionType],
            infrastructureAsCodeTool: UpdateStageRequestInfrastructureAsCodeToolEnum[values.cloudTool as InfrastructureAsCodeTool],
            cloudProvider: UpdateStageRequestCloudProviderEnum[values.cloudProvider as CloudProvider],
            tenantId: values.azureTenant,
            subscriptionId: values.azureSubscription,
            gcpProjectId: values.gcpProject,
            gcpRegion: UpdateStageRequestGcpRegionEnum[values.gcpRegion as GcpRegionType],
            gcpZone: UpdateStageRequestGcpZoneEnum[values.gcpZone as GcpZoneType],
          }
        );
        props.history.push(`/environments/${stageId}`);
      } else {
        const { data: stageResponse } = await getEngineAPI().createStage(
          currentProject.id,
          {
            name: values.name,
            description: values.description,
            copyFrom: stageId,
            awsAccountId: savingSchedule ? values.awsAccount : undefined,
            awsRegion: savingSchedule ? CreateStageRequestAwsRegionEnum[values.awsRegion as AwsRegionType] : undefined,
            infrastructureAsCodeTool: CreateStageRequestInfrastructureAsCodeToolEnum[values.cloudTool as InfrastructureAsCodeTool],
            cloudProvider: CreateStageRequestCloudProviderEnum[values.cloudProvider as CloudProvider],
            tenantId: values.azureTenant,
            subscriptionId: values.azureSubscription,
            gcpProjectId: values.gcpProject,
            gcpRegion: CreateStageRequestGcpRegionEnum[values.gcpRegion as GcpRegionType],
            gcpZone: CreateStageRequestGcpZoneEnum[values.gcpZone as GcpZoneType]
          }
        );

        isReplicate ? trackEnvReplicateSucceeded(
          stageResponse.id,
          values.name,
          props.match.params.stageId,
          stageName,
          values.cloudProvider,
          values.cloudTool,
          savingSchedule
        ) :
          trackEnvCreateSucceeded(stageResponse.id,
            values.name,
            values.cloudProvider,
            values.cloudTool,
            savingSchedule
          );
        props.history.push(`/environments/${stageResponse.id}`);
      }

      if (savingSchedule) {
        const { data: { stages } } = await getEngineAPI().getStages(currentProject.id);
        const stage = stages.filter(s => s.name === values.name);

        await getCloudCostOptimizerAPI().createSchedule(
          currentProject.id,
          {
            name: values.scheduleName!,
            startScheduleExpression: cronExpression!.startExpression,
            stopScheduleExpression: cronExpression!.stopExpression,
            awsAccountId: values.awsAccount!,
            region: UpdateStageRequestAwsRegionEnum[values.awsRegion as AwsRegionType],
            tagKey: DEFAULT_TAG_KEY,
            tagValue: stage[0].id,
            timezone: timeZone
          }
        );
      }
    } catch (err) {
      isReplicate ? trackEnvReplicateFailed(props.match.params.stageId,
        stageName,
        values.name,
        values.cloudProvider,
        values.cloudTool,
        savingSchedule,
        JSON.stringify(err)) : trackEnvCreateFailed(values.name,
          values.cloudProvider,
          values.cloudTool,
          savingSchedule,
          JSON.stringify(err),
          selectedTemplate
        );
      if (err.response.data.code === 402) {
        setPaymentError(err.response.data.message);
        showPaymentModal();
      } else {
        toast.error(err.response.data.message || "Something went wrong!");
      }

    }
    setIsBusy(false);
  }

  useEffect(() => {
    trackEnvCreateInit();

    async function fetch() {
      const { data: stage } = await getEngineAPI().getStage(props.match.params.stageId, currentProject.id);

      setCloudProvider(cloudProviders.find(provider => provider.value === stage.cloudProvider)!);
      setCloudTool(cloudTools.find(tool => tool.value === stage.infrastructureAsCodeTool)!)
      if (isUpdate) {
        const awsRegion = awsRegions.find(r => r.value === stage.region);
        const gcpRegion = gcpRegions.find(r => r.value === stage.gcpRegion);
        const gcpZone = gcpZones.find(r => r.name === stage.gcpZone);

        setName(stage.name);
        setDescription(stage.description);
        setSelectedAwsAccount({
          awsAccountId: stage.awsAccountId || undefined,
          awsRegion: awsRegion && awsRegion.id
        });
        setSelectedAzureAccount({
          subscriptionId: stage.subscriptionId || undefined,
          tenantId: stage.tenantId || undefined
        })
        setSelectedGcpAccount({
          gcpProjectId: stage.gcpProjectId || undefined,
          gcpRegion: gcpRegion && gcpRegion.id,
          gcpZone: gcpZone && gcpZone.id,
        })
        setDisableCloudAccountUpdate(
          ["NOT_DEPLOYED", "DELETE_COMPLETE"].indexOf(stage.status!) === -1
        );
      }
    }

    if (isUpdate || isReplicate) {
      fetch();
    }
  }, []);

  useEffect(() => {
    async function fetch() {
      let errorStatus;
      try {
        await getCloudCostOptimizerAPI().listSchedules(currentProject.id)
      } catch (error) {
        errorStatus = error.status;
      }
      if (errorStatus !== 402) {
        setCostOptimizer(true)
      }
    }
    fetch();

  }, []);

  async function createFromTemplate(templateId: string, envName: string, envDescription: string) {
    const engine = getEngineAPI();

    const { data: { id: envId } } = await engine.createStage(
      currentProject.id,
      {
        name: envName,
        description: envDescription,
        infrastructureAsCodeTool: CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation,
        cloudProvider: CreateStageRequestCloudProviderEnum.Aws
      }
    );

    try {
      let template = environmentTemplates[templateId];

      for (const key in template.components) {
        const component = template.components[key];
        const [componentId, componentVersion] = component.type.split(":");

        await engine.createResource(
          envId,
          currentProject.id,
          {
            name: key,
            componentId,
            componentVersion,
            configurations: component.properties.map((param: EnvironmentTemplateComponentProperty) => ({
              key: param.key,
              value: param.reference || param.value || "",
              reference: param.reference ? true : false
            }))
          }
        );
      }
      props.history.push(`/environments/${envId}`);
    } catch (error) {
      console.log("ERROR", error);
      await engine.deleteStage(envId, currentProject.id);
      toast.error(`Creating environment from template failed.\n${error.response.data.message}`);
    }
  }

  function resetAzureConfig(setFieldValue: Function) {
    setSelectedAzureAccount({
      subscriptionId: undefined,
      tenantId: undefined
    })
    setFieldValue("azureSubscription", undefined);
    setFieldValue("azureTenant", undefined);
  }

  function resetGcpConfig(setFieldValue: Function) {
    setSelectedGcpAccount({
      gcpProjectId: undefined,
      gcpRegion: undefined,
      gcpZone: undefined
    })
    setFieldValue("gcpProject", undefined);
    setFieldValue("gcpRegion", undefined);
    setFieldValue("gcpZone", undefined);
  }

  function resetAwsConfig(setFieldValue: Function) {
    setSelectedAwsAccount({
      awsRegion: undefined,
      awsAccountId: undefined,
    });
    setFieldValue("awsRegion", undefined);
    setFieldValue("awsAccount", undefined);
  }
  function resetCloudConfigs(cloudProvider: DropdownItem, setFieldValue: Function) {
    if (cloudProvider !== awsProvider) {
      const terrafomTool = cloudTools.find(tool => tool.id === "Terraform")!;
      setFieldValue("cloudTool", terrafomTool.id);
      setFieldValue("cronExpression", undefined);
      setCloudTool(terrafomTool);
      setSelectedTemplate(undefined);
      setSavingSchedule(false);
      setCronExpression(undefined);
    }
    if (cloudProvider.id === "Aws") {
      resetAzureConfig(setFieldValue);
      resetGcpConfig(setFieldValue);
    }
    else if (cloudProvider.id === "Google") {
      resetAwsConfig(setFieldValue);
      resetAzureConfig(setFieldValue);
    }
    else if (cloudProvider.id === "Azurerm") {
      resetAwsConfig(setFieldValue);
      resetGcpConfig(setFieldValue);
    }
  }
  function handleSavingScheduleChange(savingScheduleEnabled: boolean, setFieldValue: Function) {
    setSavingSchedule(savingScheduleEnabled);
    if (!savingScheduleEnabled) {
      setFieldValue("scheduleName", "");
      setScheduleName("");
      resetAwsConfig(setFieldValue);
      setCronExpression(undefined);
    }
  }

  const templateItems = [
    {
      id: "strapi",
      logo: StrapiLogo,
      title: "Strapi",
      description:
        <>
          Strapi is an open source Headless CMS built with NodeJS. Using Strapi you can design and publish your content consumed by your apps via API.
        </>,
      onClick: () => { setSelectedTemplate(selectedTemplate === "strapi" ? undefined : "strapi") }
    },
    {
      id: "kubernetes",
      logo: KubernetesLogo,
      title: "Kubernetes",
      description:
        <>
          Kubernetes is a production-grade container orchestration platform. With Kubernetes you can run your containerized services at scale.
        </>,
      onClick: () => { setSelectedTemplate(selectedTemplate === "kubernetes" ? undefined : "kubernetes") }
    }
  ];

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        titlePosition="center"
        title={pageTitle}
      />

      <div className="content__body">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name,
            description,
            cloudProvider: cloudProvider ? cloudProvider.id : "",
            cloudTool: cloudTool ? cloudTool.id : "",
            scheduleName,
            awsAccount: selectedAwsAccount ? selectedAwsAccount.awsAccountId : undefined,
            awsRegion: selectedAwsAccount ? selectedAwsAccount.awsRegion : undefined,
            azureSubscription: selectedAzureAccount ? selectedAzureAccount.subscriptionId : undefined,
            azureTenant: selectedAzureAccount ? selectedAzureAccount.tenantId : undefined,
            gcpProject: selectedGcpAccount ? selectedGcpAccount.gcpProjectId : undefined,
            gcpRegion: selectedGcpAccount ? selectedGcpAccount.gcpRegion : undefined,
            gcpZone: selectedGcpAccount ? selectedGcpAccount.gcpZone : undefined,
          }}
          onSubmit={async values => { handleSubmit(values) }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={isUpdate ? savingSchedule ? updateStageSchemaWithSavingScheduleSchema : updateStageSchema : savingSchedule ? createStageWithSavingScheduleSchema : createStageSchema}
        >
          {({ errors, setFieldValue, setFieldTouched, values }) => (
            <Form>
              <PageContent>
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader title="General" text="General information for the environment" />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="name"
                          type="text"
                          label="Environment name"
                          placeholder="Enter environment name here"
                          hasError={true}
                          component={InputField}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("name", e.target.value);
                            setName(e.target.value)
                          }}
                        />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="description"
                          className="input__field input--textarea"
                          placeholder="Enter environment description here"
                          label="Environment Description"
                          hasError={true}
                          rows={4}
                          component={InputAreaField}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("description", e.target.value);
                            setDescription(e.target.value)
                          }}
                        />
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="cloudProvider"
                          label="Cloud provider"
                        >
                          {() => (
                            <div>
                              <DropdownContainer
                                items={cloudProviders}
                                label="Cloud provider"
                                placeholder="Select cloud provider"
                                selectedItem={cloudProvider}
                                onSelectItem={(item) => {
                                  setFieldValue("cloudProvider", item.id);
                                  setCloudProvider(item);
                                  resetCloudConfigs(item, setFieldValue);
                                }}
                                disabled={disableCloudAccountUpdate || isReplicate}
                              />
                              {
                                errors.cloudProvider ?
                                  <div className="page-content__error">{errors.cloudProvider}</div>
                                  : null
                              }
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="page-content__dropdown-container mb--30">
                        <Field
                          name="cloudTool"
                          label="Infrastructure as Code tool"
                        >
                          {() => (
                            <div>
                              <DropdownContainer
                                items={cloudTools}
                                label="Infrastructure as Code tool"
                                placeholder="Select Infrastructure as Code tool"
                                selectedItem={cloudTool}
                                onSelectItem={(item) => {
                                  setFieldValue("cloudTool", item.id);
                                  setCloudTool(item);
                                  if (item !== cfnTool) {
                                    setSelectedTemplate(undefined);
                                  }
                                }}
                                disabled={(cloudProvider && cloudProvider !== awsProvider) || disableCloudAccountUpdate || isReplicate}
                              />
                              {
                                errors.cloudTool ?
                                  <div className="page-content__error">{errors.cloudTool}</div>
                                  : null
                              }
                            </div>
                          )}
                        </Field>
                      </div>
                    </Card>
                  </div>
                </div>
                {/* Environment from template */}
                <>
                  {
                    (isUpdate || cloudTool !== cfnTool) || isReplicate ? null :
                      <div className="row justify-content-center mb--10">
                        <div className="col-12 col-xl-6">
                          <Card class="card dark compact">
                            <div className="card_header_container">
                              <CardHeader title="Create from Template" text="Create infrastructure from predefined environment components and configurations" />
                            </div>
                            <div className="row list-box mt--15">
                              {
                                templateItems.map(template => (
                                  <div className="col-6">
                                    <div className={`list-box-item list-box-item${selectedTemplate === template.id ? "__selected" : ""}`} onClick={template.onClick}>
                                      <img src={template.logo} />
                                      <div>
                                        <b className="list-box__title">
                                          {template.title}
                                        </b>
                                        <div className="list-box__description">
                                          {template.description}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </Card>
                        </div>
                      </div>
                  }
                </>

                {/* Saving schedule */}
                {costOptimizer && !isUpdate && cloudProvider && cloudProvider.id === "Aws" ?
                  <>
                    <div className="row justify-content-center mb--10">
                      <div className="col-12 col-xl-6">
                        <Card class="card dark compact">
                          <div className="card_header_container">
                            <CardHeader title="Cost Optimization" text="Enable cost saving for this environment" />
                          </div>
                          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                            <Checkbox
                              key="savingSchedule"
                              name="Enable saving"
                              checked={savingSchedule}
                              onChange={checked => handleSavingScheduleChange(checked, setFieldValue)}
                              description="Saving schedules will put your environment resources on sleep"
                            />
                          </div>
                          {
                            savingSchedule ?
                              <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                                <Field
                                  name="scheduleName"
                                  placeholder="e.g. Dev Environment Schedule"
                                  type="text"
                                  hasError={true}
                                  label="Schedule Name"
                                  component={InputField}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("scheduleName", e.target.value);
                                    setScheduleName(e.target.value);
                                  }}
                                />
                                <div className="mb--30">
                                  <Cron
                                    name="cronExpression"
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    cronExpression={initialCronExpression}
                                    timeZone={timeZone}
                                    errors={errors}
                                    title="ENVIRONMENT SHOULD BE ACTIVE ON:"
                                    onChange={setCronExpression}
                                  />
                                </div>
                              </div> : <div />
                          }
                        </Card>
                      </div>
                    </div>
                  </> : null
                }

                {/* AWS account */}
                {
                  cloudProvider && cloudProvider.id === "Aws" && (isUpdate || savingSchedule) ?
                    <div className="row justify-content-center mb--10">
                      <div className="col-12 col-xl-6">
                        <AwsAccount
                          title="AWS account"
                          subtitle="Components added in this environment will be provisioned on your own AWS account."
                          value={selectedAwsAccount}
                          onChange={(event) => {
                            setSelectedAwsAccount({
                              awsAccountId: event.awsAccountId,
                              awsRegion: event.awsRegion
                            })
                          }}
                          showEcrDropdown={false}
                          errors={errors}
                          disabled={disableCloudAccountUpdate}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div> : <div />
                }
                {/* Azure account */}
                {
                  cloudProvider && cloudProvider.id === "Azurerm" ?
                    <div className="row justify-content-center mb--10">
                      <div className="col-12 col-xl-6">
                        <AzureAccount
                          title="Microsoft Azure account"
                          subtitle="Components added in this environment will be provisioned on your own Microsoft Azure account."
                          value={selectedAzureAccount}
                          onChange={(event) => {
                            setSelectedAzureAccount({
                              subscriptionId: event.subscriptionId,
                              tenantId: event.tenantId
                            })
                          }}
                          optional={!isUpdate}
                          errors={errors}
                          disabled={disableCloudAccountUpdate}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div> : <div />
                }
                {/* GCP account */}
                {
                  cloudProvider && cloudProvider.id === "Google" ?
                    <div className="row justify-content-center mb--10">
                      <div className="col-12 col-xl-6">
                        <GcpAccount
                          title="Google Cloud Platform account"
                          subtitle="Components added in this environment will be provisioned on your own Google Cloud Platform account."
                          value={selectedGcpAccount}
                          onChange={(event) => {
                            setSelectedGcpAccount({
                              gcpProjectId: event.gcpProjectId,
                              gcpRegion: event.gcpRegion,
                              gcpZone: event.gcpZone
                            })
                          }}
                          optional={!isUpdate}
                          errors={errors}
                          disabled={disableCloudAccountUpdate}
                          disabledGcpZone={!values.gcpRegion}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div> : <div />
                }
              </PageContent>
              <div className="d-flex justify-content-space-evenly ">
                <Button
                  type="submit"
                  className="btn btn--md btn--lightBlue btn--create"
                  isBusy={isBusy}
                  busyText="Creating..."
                >
                  {
                    isUpdate ?
                      "Update Environment" : selectedTemplate ? "Create from Template" : "Create Environment"
                  }
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

    </main>
  );
};

export default CreateStage;
