import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";

// Create microservice
export interface DeleteAwsAccountStart {
    type: constants.DELETE_AWS_ACCOUNT_START;
}
export interface DeleteAwsAccountSuccess {
    type: constants.DELETE_AWS_ACCOUNT_SUCCESS;
    accountId: string;
}
export interface DeleteAwsAccountError {
    type: constants.DELETE_AWS_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export type DeleteAwsAccount =
    | DeleteAwsAccountStart
    | DeleteAwsAccountSuccess
    | DeleteAwsAccountError

export function deleteAwsAccountStart(): DeleteAwsAccount {
    return {
        type: constants.DELETE_AWS_ACCOUNT_START
    };
}
export function deleteAwsAccountError(error: ErrorInterface): DeleteAwsAccount {
    return {
        type: constants.DELETE_AWS_ACCOUNT_FAIL,
        error
    };
}

export function deleteAwsAccountSuccess(
    accountId: string
): DeleteAwsAccount {
    return {
        type: constants.DELETE_AWS_ACCOUNT_SUCCESS,
        accountId
    };
}

export function deleteAwsAccount(
    projectId: string,
    accountId: string
): ThunkAction<Promise<void>, {}, {}, DeleteAwsAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteAwsAccount>) => {
        dispatch(deleteAwsAccountStart());
        try {
            await getAwsAPI().deleteAwsAccount(accountId, projectId);
            dispatch(deleteAwsAccountSuccess(accountId));
        } catch (error) {
            dispatch(deleteAwsAccountError(error.response.data));
        }
    };
}
