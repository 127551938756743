import * as React from 'react';
import Skeleton from "react-loading-skeleton";


export interface Props {
  name: string;
  percentage: string;
  isLoaded: boolean;
}


function ProgressBar(props: Props) {

  return (
    <div className="progressbar">
      <div className="d-flex justify-content-between align-items-center">
        <p className="progressbar__text txt--white"><strong>{props.name}</strong></p>
        {props.isLoaded ? <Skeleton width="30px" height="8px" /> : <p className="progressbar__text">{props.percentage}</p> }
      </div>

      {props.isLoaded ? 
        <Skeleton width="100%" height="8px" />
      :
        <div className="progressbar__bar">
          <span style={{width: props.percentage}}></span>
        </div>
      }
    </div>
  );
}


export default ProgressBar;