import React from "react";
import ConditionalWrapper from "../../components/ConditionalWrapper/ConditionalWrapper";

export interface Props {
  message: string;
  name: string;
  version: string;
  href: string;
  target: string;
  userAvatar: string;
  userName: string;
  modifier?: string;
  type?: string;
}

function Commit(props: Props) {
  return (
    <div className="commit">
      <div className={`message ${props.modifier === "stage-details-header" ? "stage-details-header" : ""}`}>{props.message}</div>
      <div>
        <ConditionalWrapper
          condition={props.modifier === "stage-details"}
          wrapper={(children: React.ReactChild[]) => <div className="pb--5">{children}</div>}
        >
          <label>{props.name} (</label>
          <a
            href={props.href}
            onClick={(e) => e.stopPropagation()}
            target={props.target}
          >
            {
              (!props.type || props.type === "branch")
                ? props.version.substring(0, 7)
                : props.version
            }
          </a>
          <label>)</label>
          &nbsp;&nbsp;
        </ConditionalWrapper>
        <span className="d-inline-block">
          <img src={props.userAvatar} alt={props.name} />
          {props.userName}
        </span>
      </div>
    </div>
  );
}

export default Commit;
