//Components
export const FETCH_COMPONENTS_START = 'FETCH_COMPONENTS_START';
export type FETCH_COMPONENTS_START = typeof FETCH_COMPONENTS_START;
export const FETCH_COMPONENTS_SUCCESS = 'FETCH_COMPONENTS_SUCCESS';
export type FETCH_COMPONENTS_SUCCESS = typeof FETCH_COMPONENTS_SUCCESS;
export const FETCH_COMPONENTS_FAIL = 'FETCH_COMPONENTS_FAIL';
export type FETCH_COMPONENTS_FAIL = typeof FETCH_COMPONENTS_FAIL;

//Component builds
export const FETCH_COMPONENT_BUILDS_START = 'FETCH_COMPONENT_BUILDS_START';
export type FETCH_COMPONENT_BUILDS_START = typeof FETCH_COMPONENT_BUILDS_START;
export const FETCH_COMPONENT_BUILDS_SUCCESS = 'FETCH_COMPONENT_BUILDS_SUCCESS';
export type FETCH_COMPONENT_BUILDS_SUCCESS = typeof FETCH_COMPONENT_BUILDS_SUCCESS;
export const FETCH_COMPONENT_BUILDS_FAIL = 'FETCH_COMPONENT_BUILDS_FAIL';
export type FETCH_COMPONENT_BUILDS_FAIL = typeof FETCH_COMPONENT_BUILDS_FAIL;

//Create component
export const CREATE_COMPONENT_START = 'CREATE_COMPONENT_START';
export type CREATE_COMPONENT_START = typeof CREATE_COMPONENT_START;
export const CREATE_COMPONENT_SUCCESS = 'CREATE_COMPONENT_SUCCESS';
export type CREATE_COMPONENT_SUCCESS = typeof CREATE_COMPONENT_SUCCESS;
export const CREATE_COMPONENT_FAIL = 'CREATE_COMPONENT_FAIL';
export type CREATE_COMPONENT_FAIL = typeof CREATE_COMPONENT_FAIL;

//Update component
export const UPDATE_COMPONENT_START = 'UPDATE_COMPONENT_START';
export type UPDATE_COMPONENT_START = typeof UPDATE_COMPONENT_START;
export const UPDATE_COMPONENT_SUCCESS = 'UPDATE_COMPONENT_SUCCESS';
export type UPDATE_COMPONENT_SUCCESS = typeof UPDATE_COMPONENT_SUCCESS;
export const UPDATE_COMPONENT_FAIL = 'UPDATE_COMPONENT_FAIL';
export type UPDATE_COMPONENT_FAIL = typeof UPDATE_COMPONENT_FAIL;

//Delete component
export const DELETE_COMPONENT_START = 'DELETE_COMPONENT_START';
export type DELETE_COMPONENT_START = typeof DELETE_COMPONENT_START;
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS';
export type DELETE_COMPONENT_SUCCESS = typeof DELETE_COMPONENT_SUCCESS;
export const DELETE_COMPONENT_FAIL = 'DELETE_COMPONENT_FAIL';
export type DELETE_COMPONENT_FAIL = typeof DELETE_COMPONENT_FAIL;

//Component details
export const FETCH_COMPONENT_DETAILS_START = 'FETCH_COMPONENT_DETAILS_START';
export type FETCH_COMPONENT_DETAILS_START = typeof FETCH_COMPONENT_DETAILS_START;
export const FETCH_COMPONENT_DETAILS_SUCCESS = 'FETCH_COMPONENT_DETAILS_SUCCESS';
export type FETCH_COMPONENT_DETAILS_SUCCESS = typeof FETCH_COMPONENT_DETAILS_SUCCESS;
export const FETCH_COMPONENT_DETAILS_FAIL = 'FETCH_COMPONENT_DETAILS_FAIL';
export type FETCH_COMPONENT_DETAILS_FAIL = typeof FETCH_COMPONENT_DETAILS_FAIL;

// Microservices
export const FETCH_MICROSERVICES_START = 'FETCH_MICROSERVICES_START';
export type FETCH_MICROSERVICES_START = typeof FETCH_MICROSERVICES_START;
export const FETCH_MICROSERVICES_SUCCESS = 'FETCH_MICROSERVICES_SUCCESS';
export type FETCH_MICROSERVICES_SUCCESS = typeof FETCH_MICROSERVICES_SUCCESS;
export const FETCH_MICROSERVICES_FAIL = 'FETCH_MICROSERVICES_FAIL';
export type FETCH_MICROSERVICES_FAIL = typeof FETCH_MICROSERVICES_FAIL;

// Microservice details
export const FETCH_MICROSERVICE_DETAILS_START = 'FETCH_MICROSERVICE_DETAILS_START';
export type FETCH_MICROSERVICE_DETAILS_START = typeof FETCH_MICROSERVICE_DETAILS_START;
export const FETCH_MICROSERVICE_DETAILS_SUCCESS = 'FETCH_MICROSERVICE_DETAILS_SUCCESS';
export type FETCH_MICROSERVICE_DETAILS_SUCCESS = typeof FETCH_MICROSERVICE_DETAILS_SUCCESS;
export const FETCH_MICROSERVICE_DETAILS_FAIL = 'FETCH_MICROSERVICE_FAIL';
export type FETCH_MICROSERVICE_DETAILS_FAIL = typeof FETCH_MICROSERVICE_DETAILS_FAIL;

//Create microservice
export const CREATE_MICROSERVICE_START = 'CREATE_MICROSERVICE_START';
export type CREATE_MICROSERVICE_START = typeof CREATE_MICROSERVICE_START;
export const CREATE_MICROSERVICE_SUCCESS = 'CREATE_MICROSERVICE_SUCCESS';
export type CREATE_MICROSERVICE_SUCCESS = typeof CREATE_MICROSERVICE_SUCCESS;
export const CREATE_MICROSERVICE_FAIL = 'CREATE_MICROSERVICE_FAIL';
export type CREATE_MICROSERVICE_FAIL = typeof CREATE_MICROSERVICE_FAIL;

//Update microservice
export const UPDATE_MICROSERVICE_START = 'UPDATE_MICROSERVICE_START';
export type UPDATE_MICROSERVICE_START = typeof UPDATE_MICROSERVICE_START;
export const UPDATE_MICROSERVICE_SUCCESS = 'UPDATE_MICROSERVICE_SUCCESS';
export type UPDATE_MICROSERVICE_SUCCESS = typeof UPDATE_MICROSERVICE_SUCCESS;
export const UPDATE_MICROSERVICE_FAIL = 'UPDATE_MICROSERVICE_FAIL';
export type UPDATE_MICROSERVICE_FAIL = typeof UPDATE_MICROSERVICE_FAIL;

//Delete microservice
export const DELETE_MICROSERVICE_START = 'DELETE_MICROSERVICE_START';
export type DELETE_MICROSERVICE_START = typeof DELETE_MICROSERVICE_START;
export const DELETE_MICROSERVICE_SUCCESS = 'DELETE_MICROSERVICE_SUCCESS';
export type DELETE_MICROSERVICE_SUCCESS = typeof DELETE_MICROSERVICE_SUCCESS;
export const DELETE_MICROSERVICE_FAIL = 'DELETE_MICROSERVICE_FAIL';
export type DELETE_MICROSERVICE_FAIL = typeof DELETE_MICROSERVICE_FAIL;

//Pipelines
export const FETCH_PIPELINES_START = 'FETCH_PIPELINES_START';
export type FETCH_PIPELINES_START = typeof FETCH_PIPELINES_START;
export const FETCH_PIPELINES_SUCCESS = 'FETCH_PIPELINES_SUCCESS';
export type FETCH_PIPELINES_SUCCESS = typeof FETCH_PIPELINES_SUCCESS;
export const FETCH_PIPELINES_FAIL = 'FETCH_PIPELINES_FAIL';
export type FETCH_PIPELINES_FAIL = typeof FETCH_PIPELINES_FAIL;

//Create pipeline
export const CREATE_PIPELINE_START = 'CREATE_PIPELINE_START';
export type CREATE_PIPELINE_START = typeof CREATE_PIPELINE_START;
export const CREATE_PIPELINE_SUCCESS = 'CREATE_PIPELINE_SUCCESS';
export type CREATE_PIPELINE_SUCCESS = typeof CREATE_PIPELINE_SUCCESS;
export const CREATE_PIPELINE_FAIL = 'CREATE_PIPELINE_FAIL';
export type CREATE_PIPELINE_FAIL = typeof CREATE_PIPELINE_FAIL;

// Delete pipeline
export const DELETE_PIPELINE_START = 'DELETE_PIPELINE_START';
export type DELETE_PIPELINE_START = typeof DELETE_PIPELINE_START;
export const DELETE_PIPELINE_SUCCESS = 'DELETE_PIPELINE_SUCCESS';
export type DELETE_PIPELINE_SUCCESS = typeof DELETE_PIPELINE_SUCCESS;
export const DELETE_PIPELINE_FAIL = 'DELETE_PIPELINE_FAIL';
export type DELETE_PIPELINE_FAIL = typeof DELETE_PIPELINE_FAIL;

//Builds
export const FETCH_BUILDS_START = 'FETCH_BUILDS_START';
export type FETCH_BUILDS_START = typeof FETCH_BUILDS_START;
export const FETCH_BUILDS_SUCCESS = 'FETCH_BUILDS_SUCCESS';
export type FETCH_BUILDS_SUCCESS = typeof FETCH_BUILDS_SUCCESS;
export const FETCH_BUILDS_FAIL = 'FETCH_BUILDS_FAIL';
export type FETCH_BUILDS_FAIL = typeof FETCH_BUILDS_FAIL;
export const CLEAR_BUILDS = 'CLEAR_BUILDS';
export type CLEAR_BUILDS = typeof CLEAR_BUILDS;

//Stages
export const FETCH_STAGES_START = 'FETCH_STAGES_START';
export type FETCH_STAGES_START = typeof FETCH_STAGES_START;
export const FETCH_STAGES_SUCCESS = 'FETCH_STAGES_SUCCESS';
export type FETCH_STAGES_SUCCESS = typeof FETCH_STAGES_SUCCESS;
export const FETCH_STAGES_FAIL = 'FETCH_STAGES_FAIL';
export type FETCH_STAGES_FAIL = typeof FETCH_STAGES_FAIL;

//Stage details
export const FETCH_STAGE_DETAILS_START = 'FETCH_STAGE_DETAILS_START';
export type FETCH_STAGE_DETAILS_START = typeof FETCH_STAGE_DETAILS_START;
export const FETCH_STAGE_DETAILS_SUCCESS = 'FETCH_STAGE_DETAILS_SUCCESS';
export type FETCH_STAGE_DETAILS_SUCCESS = typeof FETCH_STAGE_DETAILS_SUCCESS;
export const FETCH_STAGE_DETAILS_FAIL = 'FETCH_STAGE_DETAILS_FAIL';
export type FETCH_STAGE_DETAILS_FAIL = typeof FETCH_STAGE_DETAILS_FAIL;

// Timeline
export const FETCH_TIMELINE_START = 'FETCH_TIMELINE_START';
export type FETCH_TIMELINE_START = typeof FETCH_TIMELINE_START;
export const FETCH_TIMELINE_SUCCESS = 'FETCH_TIMELINE_SUCCESS';
export type FETCH_TIMELINE_SUCCESS = typeof FETCH_TIMELINE_SUCCESS;
export const FETCH_TIMELINE_FAIL = 'FETCH_TIMELINE_FAIL';
export type FETCH_TIMELINE_FAIL = typeof FETCH_TIMELINE_FAIL;

//Create stage
export const CREATE_STAGE_START = 'CREATE_STAGE_START';
export type CREATE_STAGE_START = typeof CREATE_STAGE_START;
export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS';
export type CREATE_STAGE_SUCCESS = typeof CREATE_STAGE_SUCCESS;
export const CREATE_STAGE_FAIL = 'CREATE_STAGE_FAIL';
export type CREATE_STAGE_FAIL = typeof CREATE_STAGE_FAIL;

// Update stage
export const UPDATE_STAGE_STATUS_SUCCESS = 'UPDATE_STAGE_STATUS_SUCCESS';
export type UPDATE_STAGE_STATUS_SUCCESS = typeof UPDATE_STAGE_STATUS_SUCCESS;

//Delete stage
export const DELETE_STAGE_START = 'DELETE_STAGE_START';
export type DELETE_STAGE_START = typeof DELETE_STAGE_START;
export const DELETE_STAGE_SUCCESS = 'DELETE_STAGE_SUCCESS';
export type DELETE_STAGE_SUCCESS = typeof DELETE_STAGE_SUCCESS;
export const DELETE_STAGE_FAIL = 'DELETE_STAGE_FAIL';
export type DELETE_STAGE_FAIL = typeof DELETE_STAGE_FAIL;

//Undeploy stage
export const UNDEPLOY_STAGE_START = 'UNDEPLOY_STAGE_START';
export type UNDEPLOY_STAGE_START = typeof UNDEPLOY_STAGE_START;
export const UNDEPLOY_STAGE_SUCCESS = 'UNDEPLOY_STAGE_SUCCESS';
export type UNDEPLOY_STAGE_SUCCESS = typeof UNDEPLOY_STAGE_SUCCESS;
export const UNDEPLOY_STAGE_FAIL = 'UNDEPLOY_STAGE_FAIL';
export type UNDEPLOY_STAGE_FAIL = typeof UNDEPLOY_STAGE_FAIL;

// Login
export const LOGIN_START = 'LOGIN_START';
export type LOGIN_START = typeof LOGIN_START;
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;
export const LOGIN_FAIL = 'LOGIN_FAIL';
export type LOGIN_FAIL = typeof LOGIN_FAIL;
export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export type SET_LOGGED_IN = typeof SET_LOGGED_IN;

export const CURRENT_USER = 'CURRENT_USER';
export type CURRENT_USER = typeof CURRENT_USER;
//
export const CHANGE_PAGE_TITLE = 'CHANGE_PAGE_TITLE';
export type CHANGE_PAGE_TITLE = typeof CHANGE_PAGE_TITLE;

//Projects
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export type FETCH_PROJECTS_START = typeof FETCH_PROJECTS_START;
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export type FETCH_PROJECTS_SUCCESS = typeof FETCH_PROJECTS_SUCCESS;
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';
export type FETCH_PROJECTS_FAIL = typeof FETCH_PROJECTS_FAIL;

export const CURRENT_PROJECT_SELECTED = 'CURRENT_PROJECT_SELECTED';
export type CURRENT_PROJECT_SELECTED = typeof CURRENT_PROJECT_SELECTED;

export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export type CREATE_PROJECT_START = typeof CREATE_PROJECT_START;
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export type CREATE_PROJECT_SUCCESS = typeof CREATE_PROJECT_SUCCESS;
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';
export type CREATE_PROJECT_FAIL = typeof CREATE_PROJECT_FAIL;

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export type UPDATE_PROJECT_START = typeof UPDATE_PROJECT_START;
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export type UPDATE_PROJECT_SUCCESS = typeof UPDATE_PROJECT_SUCCESS;
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export type UPDATE_PROJECT_FAIL = typeof UPDATE_PROJECT_FAIL;

//Delete project
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export type DELETE_PROJECT_START = typeof DELETE_PROJECT_START;
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export type DELETE_PROJECT_SUCCESS = typeof DELETE_PROJECT_SUCCESS;
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export type DELETE_PROJECT_FAIL = typeof DELETE_PROJECT_FAIL;

//Error handling
export const CLEAR_ERROR = 'CLEAR_ERROR';
export type CLEAR_ERROR = typeof CLEAR_ERROR;

// GCP Accounts
export const FETCH_GCP_ACCOUNTS_START = "FETCH_GCP_ACCOUNTS_START";
export type FETCH_GCP_ACCOUNTS_START = typeof FETCH_GCP_ACCOUNTS_START;
export const FETCH_GCP_ACCOUNTS_SUCCESS = "FETCH_GCP_ACCOUNTS_SUCCESS";
export type FETCH_GCP_ACCOUNTS_SUCCESS = typeof FETCH_GCP_ACCOUNTS_SUCCESS;
export const FETCH_GCP_ACCOUNTS_FAIL = "FETCH_GCP_ACCOUNTS_FAIL";
export type FETCH_GCP_ACCOUNTS_FAIL = typeof FETCH_GCP_ACCOUNTS_FAIL;

// Connect GCP Account
export const CONNECT_GCP_ACCOUNT_START = 'CONNECT_GCP_ACCOUNT_START';
export type CONNECT_GCP_ACCOUNT_START = typeof CONNECT_GCP_ACCOUNT_START;
export const CONNECT_GCP_ACCOUNT_SUCCESS = 'CONNECT_GCP_ACCOUNT_SUCCESS';
export type CONNECT_GCP_ACCOUNT_SUCCESS = typeof CONNECT_GCP_ACCOUNT_SUCCESS;
export const CONNECT_GCP_ACCOUNT_FAIL = 'CONNECT_GCP_ACCOUNT_FAIL';
export type CONNECT_GCP_ACCOUNT_FAIL = typeof CONNECT_GCP_ACCOUNT_FAIL;
export const CONNECT_GCP_ACCOUNT_CLEAR = 'CONNECT_GCP_ACCOUNT_CLEAR';
export type CONNECT_GCP_ACCOUNT_CLEAR = typeof CONNECT_GCP_ACCOUNT_CLEAR;

// Delete GCP Account
export const DELETE_GCP_ACCOUNT_START = 'DELETE_GCP_ACCOUNT_START';
export type DELETE_GCP_ACCOUNT_START = typeof DELETE_GCP_ACCOUNT_START;
export const DELETE_GCP_ACCOUNT_SUCCESS = 'DELETE_GCP_ACCOUNT_SUCCESS';
export type DELETE_GCP_ACCOUNT_SUCCESS = typeof DELETE_GCP_ACCOUNT_SUCCESS;
export const DELETE_GCP_ACCOUNT_FAIL = 'DELETE_GCP_ACCOUNT_FAIL';
export type DELETE_GCP_ACCOUNT_FAIL = typeof DELETE_GCP_ACCOUNT_FAIL;

// Update GCP Account
export const UPDATE_GCP_ACCOUNT_START = 'UPDATE_GCP_ACCOUNT_START';
export type UPDATE_GCP_ACCOUNT_START = typeof UPDATE_GCP_ACCOUNT_START;
export const UPDATE_GCP_ACCOUNT_SUCCESS = 'UPDATE_GCP_ACCOUNT_SUCCESS';
export type UPDATE_GCP_ACCOUNT_SUCCESS = typeof UPDATE_GCP_ACCOUNT_SUCCESS;
export const UPDATE_GCP_ACCOUNT_FAIL = 'UPDATE_GCP_ACCOUNT_FAIL';
export type UPDATE_GCP_ACCOUNT_FAIL = typeof UPDATE_GCP_ACCOUNT_FAIL;

// Azure Accounts
export const FETCH_AZURE_ACCOUNTS_START = "FETCH_AZURE_ACCOUNTS_START";
export type FETCH_AZURE_ACCOUNTS_START = typeof FETCH_AZURE_ACCOUNTS_START;
export const FETCH_AZURE_ACCOUNTS_SUCCESS = "FETCH_AZURE_ACCOUNTS_SUCCESS";
export type FETCH_AZURE_ACCOUNTS_SUCCESS = typeof FETCH_AZURE_ACCOUNTS_SUCCESS;
export const FETCH_AZURE_ACCOUNTS_FAIL = "FETCH_AZURE_ACCOUNTS_FAIL";
export type FETCH_AZURE_ACCOUNTS_FAIL = typeof FETCH_AZURE_ACCOUNTS_FAIL;

// Connect Azure Account
export const CONNECT_AZURE_ACCOUNT_START = 'CONNECT_AZURE_ACCOUNT_START';
export type CONNECT_AZURE_ACCOUNT_START = typeof CONNECT_AZURE_ACCOUNT_START;
export const CONNECT_AZURE_ACCOUNT_SUCCESS = 'CONNECT_AZURE_ACCOUNT_SUCCESS';
export type CONNECT_AZURE_ACCOUNT_SUCCESS = typeof CONNECT_AZURE_ACCOUNT_SUCCESS;
export const CONNECT_AZURE_ACCOUNT_FAIL = 'CONNECT_AZURE_ACCOUNT_FAIL';
export type CONNECT_AZURE_ACCOUNT_FAIL = typeof CONNECT_AZURE_ACCOUNT_FAIL;
export const CONNECT_AZURE_ACCOUNT_CLEAR = 'CONNECT_AZURE_ACCOUNT_CLEAR';
export type CONNECT_AZURE_ACCOUNT_CLEAR = typeof CONNECT_AZURE_ACCOUNT_CLEAR;

// Delete Azure Account
export const DELETE_AZURE_ACCOUNT_START = 'DELETE_AZURE_ACCOUNT_START';
export type DELETE_AZURE_ACCOUNT_START = typeof DELETE_AZURE_ACCOUNT_START;
export const DELETE_AZURE_ACCOUNT_SUCCESS = 'DELETE_AZURE_ACCOUNT_SUCCESS';
export type DELETE_AZURE_ACCOUNT_SUCCESS = typeof DELETE_AZURE_ACCOUNT_SUCCESS;
export const DELETE_AZURE_ACCOUNT_FAIL = 'DELETE_AZURE_ACCOUNT_FAIL';
export type DELETE_AZURE_ACCOUNT_FAIL = typeof DELETE_AZURE_ACCOUNT_FAIL;

// Update Azure Account
export const UPDATE_AZURE_ACCOUNT_START = 'UPDATE_AZURE_ACCOUNT_START';
export type UPDATE_AZURE_ACCOUNT_START = typeof UPDATE_AZURE_ACCOUNT_START;
export const UPDATE_AZURE_ACCOUNT_SUCCESS = 'UPDATE_AZURE_ACCOUNT_SUCCESS';
export type UPDATE_AZURE_ACCOUNT_SUCCESS = typeof UPDATE_AZURE_ACCOUNT_SUCCESS;
export const UPDATE_AZURE_ACCOUNT_FAIL = 'UPDATE_AZURE_ACCOUNT_FAIL';
export type UPDATE_AZURE_ACCOUNT_FAIL = typeof UPDATE_AZURE_ACCOUNT_FAIL;

//AWS Accounts 
export const FETCH_AWS_ACCOUNTS_START = 'FETCH_AWS_ACCOUNTS_START';
export type FETCH_AWS_ACCOUNTS_START = typeof FETCH_AWS_ACCOUNTS_START;
export const FETCH_AWS_ACCOUNTS_SUCCESS = 'FETCH_AWS_ACCOUNTS_SUCCESS';
export type FETCH_AWS_ACCOUNTS_SUCCESS = typeof FETCH_AWS_ACCOUNTS_SUCCESS;
export const FETCH_AWS_ACCOUNTS_FAIL = 'FETCH_AWS_ACCOUNTS_FAIL';
export type FETCH_AWS_ACCOUNTS_FAIL = typeof FETCH_AWS_ACCOUNTS_FAIL;

// Connect AWS Account
export const CONNECT_AWS_ACCOUNT_START = 'CONNECT_AWS_ACCOUNT_START';
export type CONNECT_AWS_ACCOUNT_START = typeof CONNECT_AWS_ACCOUNT_START;
export const CONNECT_AWS_ACCOUNT_SUCCESS = 'CONNECT_AWS_ACCOUNT_SUCCESS';
export type CONNECT_AWS_ACCOUNT_SUCCESS = typeof CONNECT_AWS_ACCOUNT_SUCCESS;
export const CONNECT_AWS_ACCOUNT_FAIL = 'CONNECT_AWS_ACCOUNT_FAIL';
export type CONNECT_AWS_ACCOUNT_FAIL = typeof CONNECT_AWS_ACCOUNT_FAIL;
export const CONNECT_AWS_ACCOUNT_CLEAR = 'CONNECT_AWS_ACCOUNT_CLEAR';
export type CONNECT_AWS_ACCOUNT_CLEAR = typeof CONNECT_AWS_ACCOUNT_CLEAR;

// Delete AWS Account
export const DELETE_AWS_ACCOUNT_START = 'DELETE_AWS_ACCOUNT_START';
export type DELETE_AWS_ACCOUNT_START = typeof DELETE_AWS_ACCOUNT_START;
export const DELETE_AWS_ACCOUNT_SUCCESS = 'DELETE_AWS_ACCOUNT_SUCCESS';
export type DELETE_AWS_ACCOUNT_SUCCESS = typeof DELETE_AWS_ACCOUNT_SUCCESS;
export const DELETE_AWS_ACCOUNT_FAIL = 'DELETE_AWS_ACCOUNT_FAIL';
export type DELETE_AWS_ACCOUNT_FAIL = typeof DELETE_AWS_ACCOUNT_FAIL;

// Update AWS Account
export const UPDATE_AWS_ACCOUNT_START = 'UPDATE_AWS_ACCOUNT_START';
export type UPDATE_AWS_ACCOUNT_START = typeof UPDATE_AWS_ACCOUNT_START;
export const UPDATE_AWS_ACCOUNT_SUCCESS = 'UPDATE_AWS_ACCOUNT_SUCCESS';
export type UPDATE_AWS_ACCOUNT_SUCCESS = typeof UPDATE_AWS_ACCOUNT_SUCCESS;
export const UPDATE_AWS_ACCOUNT_FAIL = 'UPDATE_AWS_ACCOUNT_FAIL';
export type UPDATE_AWS_ACCOUNT_FAIL = typeof UPDATE_AWS_ACCOUNT_FAIL;

//Git Accounts 
export const FETCH_GIT_ACCOUNTS_START = 'FETCH_GIT_ACCOUNTS_START';
export type FETCH_GIT_ACCOUNTS_START = typeof FETCH_GIT_ACCOUNTS_START;
export const FETCH_GIT_ACCOUNTS_SUCCESS = 'FETCH_GIT_ACCOUNTS_SUCCESS';
export type FETCH_GIT_ACCOUNTS_SUCCESS = typeof FETCH_GIT_ACCOUNTS_SUCCESS;
export const FETCH_GIT_ACCOUNTS_FAIL = 'FETCH_GIT_ACCOUNTS_FAIL';
export type FETCH_GIT_ACCOUNTS_FAIL = typeof FETCH_GIT_ACCOUNTS_FAIL;

// Connect Git Account
export const CONNECT_GIT_ACCOUNT_START = 'CONNECT_GIT_ACCOUNT_START';
export type CONNECT_GIT_ACCOUNT_START = typeof CONNECT_GIT_ACCOUNT_START;
export const CONNECT_GIT_ACCOUNT_SUCCESS = 'CONNECT_GIT_ACCOUNT_SUCCESS';
export type CONNECT_GIT_ACCOUNT_SUCCESS = typeof CONNECT_GIT_ACCOUNT_SUCCESS;
export const CONNECT_GIT_ACCOUNT_FAIL = 'CONNECT_GIT_ACCOUNT_FAIL';
export type CONNECT_GIT_ACCOUNT_FAIL = typeof CONNECT_GIT_ACCOUNT_FAIL;
export const CONNECT_GIT_ACCOUNT_CLEAR = 'CONNECT_GIT_ACCOUNT_CLEAR';
export type CONNECT_GIT_ACCOUNT_CLEAR = typeof CONNECT_GIT_ACCOUNT_CLEAR;

//Git Repos 
export const FETCH_GIT_REPOS_START = 'FETCH_GIT_REPOS_START';
export type FETCH_GIT_REPOS_START = typeof FETCH_GIT_REPOS_START;
export const FETCH_GIT_REPOS_SUCCESS = 'FETCH_GIT_REPOS_SUCCESS';
export type FETCH_GIT_REPOS_SUCCESS = typeof FETCH_GIT_REPOS_SUCCESS;
export const FETCH_GIT_REPOS_FAIL = 'FETCH_GIT_REPOS_FAIL';
export type FETCH_GIT_REPOS_FAIL = typeof FETCH_GIT_REPOS_FAIL;

//Delete Git Account 
export const DELETE_GIT_ACCOUNT_START = 'DELETE_GIT_ACCOUNT_START';
export type DELETE_GIT_ACCOUNT_START = typeof DELETE_GIT_ACCOUNT_START;
export const DELETE_GIT_ACCOUNT_SUCCESS = 'DELETE_GIT_ACCOUNT_SUCCESS';
export type DELETE_GIT_ACCOUNT_SUCCESS = typeof DELETE_GIT_ACCOUNT_SUCCESS;
export const DELETE_GIT_ACCOUNT_FAIL = 'DELETE_GIT_ACCOUNT_FAIL';
export type DELETE_GIT_ACCOUNT_FAIL = typeof DELETE_GIT_ACCOUNT_FAIL;

//ECR Repos 
export const FETCH_ECR_REPOS_START = 'FETCH_ECR_REPOS_START';
export type FETCH_ECR_REPOS_START = typeof FETCH_ECR_REPOS_START;
export const FETCH_ECR_REPOS_SUCCESS = 'FETCH_ECR_REPOS_SUCCESS';
export type FETCH_ECR_REPOS_SUCCESS = typeof FETCH_ECR_REPOS_SUCCESS;
export const FETCH_ECR_REPOS_FAIL = 'FETCH_ERC_REPOS_FAIL';
export type FETCH_ECR_REPOS_FAIL = typeof FETCH_ECR_REPOS_FAIL;

// Fetch users on project
export const FETCH_PROJECT_USERS_START = 'FETCH_PROJECT_USERS_START';
export type FETCH_PROJECT_USERS_START = typeof FETCH_PROJECT_USERS_START;
export const FETCH_PROJECT_USERS_SUCCESS = 'FETCH_PROJECT_USERS_SUCCESS';
export type FETCH_PROJECT_USERS_SUCCESS = typeof FETCH_PROJECT_USERS_SUCCESS;
export const FETCH_PROJECT_USERS_FAIL = 'FETCH_PROJECT_USERS_FAIL';
export type FETCH_PROJECT_USERS_FAIL = typeof FETCH_PROJECT_USERS_FAIL;

// Remove user from project
export const REMOVE_USER_FROM_PROJECT_START = 'REMOVE_USER_FROM_PROJECT_START';
export type REMOVE_USER_FROM_PROJECT_START = typeof REMOVE_USER_FROM_PROJECT_START;
export const REMOVE_USER_FROM_PROJECT_SUCCESS = 'REMOVE_USER_FROM_PROJECT_SUCCESS';
export type REMOVE_USER_FROM_PROJECT_SUCCESS = typeof REMOVE_USER_FROM_PROJECT_SUCCESS;
export const REMOVE_USER_FROM_PROJECT_FAIL = 'REMOVE_USER_FROM_PROJECT_FAIL';
export type REMOVE_USER_FROM_PROJECT_FAIL = typeof REMOVE_USER_FROM_PROJECT_FAIL;

// Update user permissions on project
export const UPDATE_USER_PERMISSIONS_START = 'UPDATE_USER_PERMISSIONS_START';
export type UPDATE_USER_PERMISSIONS_START = typeof UPDATE_USER_PERMISSIONS_START;
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export type UPDATE_USER_PERMISSIONS_SUCCESS = typeof UPDATE_USER_PERMISSIONS_SUCCESS;
export const UPDATE_USER_PERMISSIONS_FAIL = 'UPDATE_USER_PERMISSIONS_FAIL';
export type UPDATE_USER_PERMISSIONS_FAIL = typeof UPDATE_USER_PERMISSIONS_FAIL;
// Fetch user notifications
export const FETCH_USER_NOTIFICATIONS_START = 'FETCH_USER_NOTIFICATIONS_START';
export type FETCH_USER_NOTIFICATIONS_START = typeof FETCH_USER_NOTIFICATIONS_START;
export const FETCH_USER_NOTIFICATIONS_SUCCESS = 'FETCH_USER_NOTIFICATIONS_SUCCESS';
export type FETCH_USER_NOTIFICATIONS_SUCCESS = typeof FETCH_USER_NOTIFICATIONS_SUCCESS;
export const FETCH_USER_NOTIFICATIONS_FAIL = 'FETCH_USER_NOTIFICATIONS_FAIL';
export type FETCH_USER_NOTIFICATIONS_FAIL = typeof FETCH_USER_NOTIFICATIONS_FAIL;

// Mark notification as read
export const MARK_NOTIFICATION_AS_READ_START = 'MARK_NOTIFICATION_AS_READ_START';
export type MARK_NOTIFICATION_AS_READ_START = typeof MARK_NOTIFICATION_AS_READ_START;
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export type MARK_NOTIFICATION_AS_READ_SUCCESS = typeof MARK_NOTIFICATION_AS_READ_SUCCESS;
export const MARK_NOTIFICATION_AS_READ_FAIL = 'MARK_NOTIFICATION_AS_READ_FAIL';
export type MARK_NOTIFICATION_AS_READ_FAIL = typeof MARK_NOTIFICATION_AS_READ_FAIL;

// Mark all notifications as read
export const MARK_NOTIFICATIONS_AS_READ_START = 'MARK_NOTIFICATIONS_AS_READ_START';
export type MARK_NOTIFICATIONS_AS_READ_START = typeof MARK_NOTIFICATIONS_AS_READ_START;
export const MARK_NOTIFICATIONS_AS_READ_SUCCESS = 'MARK_NOTIFICATIONS_AS_READ_SUCCESS';
export type MARK_NOTIFICATIONS_AS_READ_SUCCESS = typeof MARK_NOTIFICATIONS_AS_READ_SUCCESS;
export const MARK_NOTIFICATIONS_AS_READ_FAIL = 'MARK_NOTIFICATIONS_AS_READ_FAIL';
export type MARK_NOTIFICATIONS_AS_READ_FAIL = typeof MARK_NOTIFICATIONS_AS_READ_FAIL;

// Fetch Kuberneteses 
export const FETCH_KUBERNETESES_START = 'FETCH_KUBERNETESES_START';
export type FETCH_KUBERNETESES_START = typeof FETCH_KUBERNETESES_START;
export const FETCH_KUBERNETESES_SUCCESS = 'FETCH_KUBERNETESES_SUCCESS';
export type FETCH_KUBERNETESES_SUCCESS = typeof FETCH_KUBERNETESES_SUCCESS;
export const FETCH_KUBERNETESES_FAIL = 'FETCH_KUBERNETESES_FAIL';
export type FETCH_KUBERNETESES_FAIL = typeof FETCH_KUBERNETESES_FAIL;

// Add Kubernetes
export const ADD_KUBERNETES_START = 'ADD_KUBERNETES_START';
export type ADD_KUBERNETES_START = typeof ADD_KUBERNETES_START;
export const ADD_KUBERNETES_SUCCESS = 'ADD_KUBERNETES_SUCCESS';
export type ADD_KUBERNETES_SUCCESS = typeof ADD_KUBERNETES_SUCCESS;
export const ADD_KUBERNETES_FAIL = 'ADD_KUBERNETES_FAIL';
export type ADD_KUBERNETES_FAIL = typeof ADD_KUBERNETES_FAIL;
export const ADD_KUBERNETES_CLEAR = 'ADD_KUBERNETES_CLEAR';
export type ADD_KUBERNETES_CLEAR = typeof ADD_KUBERNETES_CLEAR;

// Delete Kubernetes
export const DELETE_KUBERNETES_START = 'DELETE_KUBERNETESES_START';
export type DELETE_KUBERNETES_START = typeof DELETE_KUBERNETES_START;
export const DELETE_KUBERNETES_SUCCESS = 'DELETE_KUBERNETES_SUCCESS';
export type DELETE_KUBERNETES_SUCCESS = typeof DELETE_KUBERNETES_SUCCESS;
export const DELETE_KUBERNETES_FAIL = 'DELETE_KUBERNETES_FAIL';
export type DELETE_KUBERNETES_FAIL = typeof DELETE_KUBERNETES_FAIL;

// Update Kubernetes
export const UPDATE_KUBERNETES_START = 'UPDATE_KUBERNETES_START';
export type UPDATE_KUBERNETES_START = typeof UPDATE_KUBERNETES_START;
export const UPDATE_KUBERNETES_SUCCESS = 'UPDATE_KUBERNETES_SUCCESS';
export type UPDATE_KUBERNETES_SUCCESS = typeof UPDATE_KUBERNETES_SUCCESS;
export const UPDATE_KUBERNETES_FAIL = 'UPDATE_KUBERNETES_FAIL';
export type UPDATE_KUBERNETES_FAIL = typeof UPDATE_KUBERNETES_FAIL;


// Fetch Docker Registries 
export const FETCH_DOCKER_REGISTRIES_START = 'FETCH_DOCKER_REGISTRIES_START';
export type FETCH_DOCKER_REGISTRIES_START = typeof FETCH_DOCKER_REGISTRIES_START;
export const FETCH_DOCKER_REGISTRIES_SUCCESS = 'FETCH_DOCKER_REGISTRIES_SUCCESS';
export type FETCH_DOCKER_REGISTRIES_SUCCESS = typeof FETCH_DOCKER_REGISTRIES_SUCCESS;
export const FETCH_DOCKER_REGISTRIES_FAIL = 'FETCH_DOCKER_REGISTRIES_FAIL';
export type FETCH_DOCKER_REGISTRIES_FAIL = typeof FETCH_DOCKER_REGISTRIES_FAIL;


// Delete Docker Registry
export const DELETE_DOCKER_REGISTRY_START = 'DELETE_DOCKER_REGISTRY_START';
export type DELETE_DOCKER_REGISTRY_START = typeof DELETE_DOCKER_REGISTRY_START;
export const DELETE_DOCKER_REGISTRY_SUCCESS = 'DELETE_DOCKER_REGISTRY_SUCCESS';
export type DELETE_DOCKER_REGISTRY_SUCCESS = typeof DELETE_DOCKER_REGISTRY_SUCCESS;
export const DELETE_DOCKER_REGISTRY_FAIL = 'DELETE_DOCKER_REGISTRY_FAIL';
export type DELETE_DOCKER_REGISTRY_FAIL = typeof DELETE_DOCKER_REGISTRY_FAIL;