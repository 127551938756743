import * as React from 'react';
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { useSelector } from 'react-redux';

import codeartLogo from "../../static/codeart-logo.png";

// Import SVG icons
import overviewIcon from "../../static/overviewIcon.svg";
import infraIcon from "../../static/infra-icon.svg";
import pipelinesIcon from "../../static/pipelines-icon.svg";
import cloudToolsIcon from "../../static/cloud-tools-icon.svg";
import settingsIcon from "../../static/settingsIcon.svg";
import kubernetesIcon from "../../static/kubernetesIcon.svg";

// Import components
import MenuProject from "../MenuProject/MenuProject";
import MenuGroup from "../MenuGroup/MenuGroup";
import { GlobalState } from '../../reducers';
import CustomScrollbars from '../CustomScrollbars/CustomScrollbars';
import { renderMenu } from "../../utils/render-menu";

const MenuBar = (props: RouteComponentProps) => {
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);

  const [bigLinkActive, setBigLinkActive] = React.useState(String);
  React.useEffect(() => {
    const currentPath = window.location.pathname;
    setBigLinkActive(currentPath)
  })

  return (
    renderMenu(props.location.pathname) ?
      <CustomScrollbars maxHeight={"100%"} customClass="menubar" resetClass="reset--top">
        <div className="menubar">
          <NavLink to="/overview">
            <MenuProject name={currentProject.name} logo={codeartLogo} />
          </NavLink>

          {/* <MenuUsers users={users} numberOfUsers={8} /> */}

          <ul className="menu">
            <li className="menu__item" >
              <NavLink className={`menu__link ${bigLinkActive === "/overview" ? 'menu__link--active' : ''}`} to="/overview" exact>
                <img src={overviewIcon} alt="overview"/>
                <span className="menu__title menu__title--arrow">Overview</span>
              </NavLink>
            </li>
            <MenuGroup
              title="Infrastructure Builder"
              icon={infraIcon}
              items={[
                { id: "components", title: "Components", href: "/components" },
                { id: "environments", title: "Environments", href: "/environments" }
              ]}
            />

            <li className="menu__item" >
              <NavLink
                className={`menu__link ${bigLinkActive.includes("pipelines") ? 'menu__link--active' : ''}`}
                to="/pipelines"
                exact
              >
                <img src={pipelinesIcon} alt="pipelines"/>
                <span className="menu__title menu__title--arrow">Pipelines</span>
              </NavLink>
            </li>

            <MenuGroup
              title="Kubernetes"
              icon={kubernetesIcon}
              items={[
                { id: "clusters", title: "Clusters", href: "/kubernetes/clusters" },
                { id: "services", title: "Services", href: "/kubernetes/services" },
                { id: "docker-registries", title: "Docker Registries", href: "/kubernetes/docker-registries" }
              ]}
            />

            <MenuGroup
              title="Cloud Waste Manager"
              icon={cloudToolsIcon}
              items={[
                { id: "cost-explorer", title: "Cost Explorer", href: "/tools/cost-explorer" },
                { id: "schedules", title: "Saving Schedules", href: "/tools/schedules" },
                { id: "advisor", title: "Waste Advisor", href: "/tools/advisor" }
              ]}
            />

            <li className="menu__item" >
              <NavLink
                className={`menu__link ${bigLinkActive.includes("settings") ? 'menu__link--active' : ''}`}
                to="/settings?tab=accounts"
                exact
              >
                <img src={settingsIcon} alt="settings"/>
                <span className="menu__title menu__title--arrow">Settings</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </CustomScrollbars> : null
  );
}


export default withRouter(MenuBar);
