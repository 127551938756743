const awsRegions =
    [
        {
            "id": "UsEast2",
            "value": "us-east-2",
            "name": "US East (Ohio)",
        },
        {
            "id": 'UsEast1',
            "value": "us-east-1",
            "name": "US East (N. Virginia)"
        },
        {
            "id": "UsWest1",
            "value": "us-west-1",
            "name": "US West (N. California)"
        },
        {
            "id": "UsWest2",
            "value": "us-west-2",
            "name": "US West (Oregon)"
        },
        {
            "id": "ApEast1",
            "value": "ap-east-1",
            "name": "Asia Pacific (Hong Kong)"
        },
        {
            "id": "ApSouth1",
            "value": "ap-south-1",
            "name": "Asia Pacific (Mumbai)"
        },
        {
            "id": "ApNortheast3",
            "value": "ap-northeast-3",
            "name": "Asia Pacific (Osaka-Local)"
        },
        {
            "id": "ApNortheast2",
            "value": "ap-northeast-2",
            "name": "Asia Pacific (Seoul)"
        },
        {
            "id": "ApSoutheast1",
            "value": "ap-southeast-1",
            "name": "Asia Pacific (Singapore)"
        },
        {
            "id": "ApSoutheast2",
            "value": "ap-southeast-2",
            "name": "Asia Pacific (Sydney)"
        },
        {
            "id": "ApNortheast1",
            "value": "ap-northeast-1",
            "name": "Asia Pacific (Tokyo)"
        },
        {
            "id": "CaCentral1",
            "value": "ca-central-1",
            "name": "Canada (Central)"
        },
        {
            "id": "CnNorth1",
            "value": "cn-north-1",
            "name": "China (Beijing)"
        },
        {
            "id": "CnNorthwest1",
            "value": "cn-northwest-1",
            "name": "China (Ningxia)"
        },
        {
            "id": "EuCentral1",
            "value": "eu-central-1",
            "name": "EU (Frankfurt)"
        },
        {
            "id": "EuWest1",
            "value": "eu-west-1",
            "name": "EU (Ireland)"
        },
        {
            "id": "EuWest2",
            "value": "eu-west-2",
            "name": "EU (London)"
        },
        {
            "id": "EuWest3",
            "value": "eu-west-3",
            "name": "EU (Paris)"
        },
        {
            "id": "EuNorth1",
            "value": "eu-north-1",
            "name": "EU (Stockholm)"
        },
        {
            "id": "MeSouth1",
            "value": "me-south-1",
            "name": "Middle East (Bahrain)"
        },
        {
            "id": "SaEast1",
            "value": "sa-east-1",
            "name": "South America (São Paulo)"
        },
        {
            "id": "UsGovEast1",
            "value": "us-gov-east-1",
            "name": "AWS GovCloud (US-East)"
        },
        {
            "id": "UsGovWest1",
            "value": "us-gov-west-1",
            "name": "AWS GovCloud (US-West)"
        }
    ]




export default awsRegions;