import * as constants from "../../constants";
import { getProjectAPI, getUserManagementAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, User } from "../../types/index";

export interface FetchProjectUsersStart {
    type: constants.FETCH_PROJECT_USERS_START;
}

export interface FetchProjectUsersSuccess {
    type: constants.FETCH_PROJECT_USERS_SUCCESS;
    users: User[];
}

export interface FetchProjectUsersFail {
    type: constants.FETCH_PROJECT_USERS_FAIL;
    error: ErrorInterface;
}

export type FetchProjectUsers =
    | FetchProjectUsersStart
    | FetchProjectUsersSuccess
    | FetchProjectUsersFail;


export function fetchProjectUsersStart(): FetchProjectUsers {
    return {
        type: constants.FETCH_PROJECT_USERS_START
    };
}

export function fetchProjectUsersSuccess(
    users: User[] = []
): FetchProjectUsers {
    return {
        type: constants.FETCH_PROJECT_USERS_SUCCESS,
        users
    };
}

export function fetchProjectUsersFail(error: ErrorInterface): FetchProjectUsers {
    return {
        type: constants.FETCH_PROJECT_USERS_FAIL,
        error
    };
}

export function fetchProjectUsers(
    projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchProjectUsers> {
    return async (dispatch: ThunkDispatch<{}, {}, FetchProjectUsers>) => {
        dispatch(fetchProjectUsersStart());
        try {
            const { data } = await getProjectAPI().getUsers(projectId);
            const users: any = (await getUserManagementAPI().getMultipleUsers(projectId, { userIds: data.users.map(user => (user.id)) })).data;

            dispatch(fetchProjectUsersSuccess(
                data.users.map((user: any) => {
                    const currentUser = users[user.id];
                    return {
                        userId: user.id,
                        firstName: currentUser.firstName || "",
                        lastName: currentUser.lastName || "",
                        permission: user.permission,
                        email: currentUser.email,
                        profileImage: "../../static/scientist.svg",
                        status: currentUser.status
                    }
                })
            ));
        } catch (e) {
            dispatch(fetchProjectUsersFail(e.response.data));
        }
    };
}
