import React, { useState, useEffect } from "react";
import { useModal } from 'react-modal-hook';
import ListView from "../../components/ListView/ListView";
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ListItemProps } from "../../components/ListItem/ListItem";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../reducers";
import { deleteAzureAccount } from "../../actions";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";
import CloudAccountModal from "../../components/CloudAccount/CloudAccountModal";
import useAzureAccountList from "../../hooks/AzureAccount";

const AzureAccounts = (props: { searchText?: string }) => {
    const { azureAccounts } = useAzureAccountList();
    const [azureAccountList, setAzureAccountList] = useState<ListItemProps[]>([]);
    const [azureAccoutntIds, setAzureAccountIds] = useState({tenantId: "", subscriptionId: ""});
    const [selectedAzureProject, setSelectedAzureProject] = useState({ tenantId: "", subscriptionId: "", name: "" });
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const dispatch = useDispatch();
    const [filterAzureAccounts, setFilterAzureAccounts] = useState("");

    // Check if request to server is still processing - to be able to display real data or skeleton placeholder
    const isProcessing = useSelector((state: GlobalState) => state.project.isProcessing);

    const [showAzureModal, hideAzureModal] = useModal(() => (<CloudAccountModal
        azureAccount={azureAccounts.find(acc => acc.tenantId === azureAccoutntIds.tenantId && acc.subscriptionId === azureAccoutntIds.subscriptionId)}
        onClose={handleHideAzureModal}
    />), [azureAccounts, azureAccoutntIds]);

    const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
        <ModalDanger
            title={`Are you sure you want to delete "${selectedAzureProject.name}" account?`}
            description="Do you really want to delete this Microsoft Azure account? This action cannot be undone."
            action="Delete"
            onCancel={hideConfirmationModal}
            onConfirm={handleDeleteAzureAccountConfirmation}
        />), [selectedAzureProject]);

    function handleHideAzureModal() {
        setAzureAccountIds({subscriptionId: "", tenantId: ""})
        hideAzureModal();
    }

    function handleDeleteAzureAccount(tenantId_subscriptionId: string, title?: string) {
        const [tenantId, subscriptionId] = tenantId_subscriptionId.split("_");
        setSelectedAzureProject({ tenantId, subscriptionId, name: title || subscriptionId })
        showConfirmationModal();
    }

    function handleUpdateAzureAccount(tenantId_subscriptionId: string) {
        const [tenantId, subscriptionId] = tenantId_subscriptionId.split("_");
        setAzureAccountIds({tenantId, subscriptionId})
        showAzureModal();
    }

    async function handleDeleteAzureAccountConfirmation() {
        dispatch(deleteAzureAccount(currentProject.id, selectedAzureProject.tenantId, selectedAzureProject.subscriptionId));
        setSelectedAzureProject({ tenantId: "", subscriptionId: "", name: "" });
        hideConfirmationModal();
    }

    useEffect(() => {
        setAzureAccountList(azureAccounts.map((acc: any) => ({
            itemId: `${acc.tenantId}_${acc.subscriptionId}`,
            title: acc.subscriptionName,
            description: `TENANT_ID: ${acc.tenantId} | SUBSCRIPTION_ID: ${acc.subscriptionId} | APP_ID: ${acc.applicationId}`,
        }))
            .filter(item => {
                if (filterAzureAccounts !== "") {
                    return item.title
                        .toLocaleLowerCase()
                        .includes(
                            filterAzureAccounts.toLocaleLowerCase()
                        );
                } else {
                    return true;
                }
            }));
    }, [azureAccounts, filterAzureAccounts]);

    useEffect(() => {
        setFilterAzureAccounts(props.searchText!);
    }, [props.searchText]);

    // Dropdown menu action items
    const itemActions = [
        {
            title: "Edit",
            icon: <UpdateIcon />,
            onClick: handleUpdateAzureAccount
        },
        {
            title: "Remove",
            icon: <RemoveIcon />,
            onClick: handleDeleteAzureAccount
        }
    ]

    return (
        <React.Fragment>
            {!isProcessing ?
                azureAccountList && azureAccountList.length > 0 &&
                <>
                    <h5 className="pl--10">Microsoft Azure Accounts</h5>
                    <ListView
                        class="list--settings pr--20"
                        listType="icons"
                        itemActions={itemActions}
                        items={azureAccountList}
                    />
                </>
                : [...Array(3)].map((_value, index) => (<ListItemSkeleton key={index} />))
            }
        </React.Fragment>
    )
}

export default AzureAccounts;