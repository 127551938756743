import * as constants from "../../constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";
import { getKubeAPI } from "../../api";
import { ListKubernetesWithDetailsResponseKuberneteses } from "@microtica/ms-kube-sdk";


export interface FetchKubernetesesStart {
    type: constants.FETCH_KUBERNETESES_START;
}

export interface FetchKubernetesesSuccess {
    type: constants.FETCH_KUBERNETESES_SUCCESS;
    kuberneteses: ListKubernetesWithDetailsResponseKuberneteses[];
}

export interface FetchKubernetesesFail {
    type: constants.FETCH_KUBERNETESES_FAIL;
    error: ErrorInterface;
}

export type FetchKuberneteses =
    | FetchKubernetesesStart
    | FetchKubernetesesSuccess
    | FetchKubernetesesFail;

export function fetchKubernetesesStart(): FetchKuberneteses {
    return {
        type: constants.FETCH_KUBERNETESES_START
    };
}

export function fetchKubernetesesSuccess(
    kuberneteses: ListKubernetesWithDetailsResponseKuberneteses[] = []
): FetchKubernetesesSuccess {
    return {
        type: constants.FETCH_KUBERNETESES_SUCCESS,
        kuberneteses
    };
}

export function fetchKubernetesesFail(error: ErrorInterface): FetchKubernetesesFail {
    return {
        type: constants.FETCH_KUBERNETESES_FAIL,
        error
    };
}

export function fetchKuberneteses(
    projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchKuberneteses> {
    return async (dispatch: ThunkDispatch<{}, {}, FetchKuberneteses>) => {
        dispatch(fetchKubernetesesStart());
        try {
            const res = await getKubeAPI().listKubernetesWithDetails(projectId)
            dispatch(fetchKubernetesesSuccess(res.data.kuberneteses));
        } catch (e) {
            dispatch(fetchKubernetesesFail(e.response.data));
        }
    };
}

