import * as constants from "../../constants";
import { getKubeAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types";

export interface DeleteMicroserviceStart {
    type: constants.DELETE_MICROSERVICE_START;
    microserviceId: string;
}
export interface DeleteMicroserviceSuccess {
    type: constants.DELETE_MICROSERVICE_SUCCESS;
    microserviceId: string;
}
export interface DeleteMicroserviceFail {
    type: constants.DELETE_MICROSERVICE_FAIL;
    error: ErrorInterface;
}

export type DeleteMicroservice =
    | DeleteMicroserviceStart
    | DeleteMicroserviceSuccess
    | DeleteMicroserviceFail

export function deleteMicroserviceStart(microserviceId: string): DeleteMicroserviceStart {
    return {
        type: constants.DELETE_MICROSERVICE_START,
        microserviceId
    };
}

export function deleteMicroserviceFail(error: ErrorInterface = errorEmptyObject): DeleteMicroserviceFail {
    return {
        type: constants.DELETE_MICROSERVICE_FAIL,
        error
    };
}

export function deleteMicroserviceSuccess(
    microserviceId: string,
): DeleteMicroserviceSuccess {
    return {
        type: constants.DELETE_MICROSERVICE_SUCCESS,
        microserviceId
    };
}


export function deleteMicroservice(
    name: string,
    projectId: string,
    id: string
): ThunkAction<Promise<void>, {}, {}, DeleteMicroservice> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteMicroservice>) => {
        dispatch(deleteMicroserviceStart(name));
        try {
            await getKubeAPI().deleteMicroservice(name, projectId);
            dispatch(deleteMicroserviceSuccess(id));
        } catch (error) {
            dispatch(deleteMicroserviceFail(error.response.data));
        }
    };
}
