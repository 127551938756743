import * as React from "react";

export interface Props extends React.DOMAttributes<HTMLDivElement> {
  children: any;
  class: string;
}

function Card(props: Props) {
  return (
    <div className={props.class} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

export default Card;