import React, { useEffect, useState } from "react";
import { RouteComponentProps, Link } from 'react-router-dom';

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import { ReactComponent as InviteUser } from "../../static/invite-user.svg";

import { getUserManagementAPI } from "../../api";

interface VerifyAccountProps extends RouteComponentProps<{
    email: string;
    code: string;
}> { }

const VerifyAccount = (props: VerifyAccountProps) => {
    const [completed, setCompleted] = useState(false);
    const [message, setMessage] = useState("Verifying email...");

    useEffect(() => {
        const { email, code } = props.match.params;
        const fetch = async () => {
            if (email && code) {
                try {
                    await getUserManagementAPI().activateUser({ username: email, code });
                    setCompleted(true);
                    setMessage("Your email has been verified!");
                } catch (error) {
                    setMessage("We were not able to verify your email!");
                    setCompleted(false);
                }
            }
        }
        fetch();
    }, []);

    return (
        <div className="container--full welcome">
            <div className="container--md container--padding d-flex flex-column align-items-center">
                <img className="microtica-text-logo mb--30" src={microticaTextLogo} alt="microtica"/>
                <InviteUser className="mb--30" />
                <h1 className="mb--15"><strong>{message}</strong></h1>
                <a className="txt--lg txt--white">
                    <strong>{props.match.params.email}</strong>
                </a>
                <br></br>
                {
                    completed ?
                        <Link
                            className="btn btn--xl btn--green"
                            to="/login"
                        >
                            Go to Login
                        </Link> : null
                }

            </div>
        </div>
    );
};

export default VerifyAccount;