import React, { useEffect, useState } from "react";
import { FormikErrors, Field } from "formik";

import Checkbox from "../../components/Checkbox/Checkbox";
import Dropdown, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import {cronExpressionTimeZoneLessThanZero, cronExpressionTimeZoneMoreThanZero, calculateHourWithoutTimeZone} from "../../utils/cron"

interface Cron {
    startExpression: string;
    stopExpression: string;
}

interface CronProps {
    name: string;
    title: string;
    cronExpression?: Cron;
    timeZone: string;
    errors?: FormikErrors<{
        [key: string]: string | { cronExpression: Cron };
    }>;
    onChange?: (cronExpression: Cron | undefined) => void;
    setFieldValue?: (field: string, value: any) => void;
    setFieldTouched?: (field: string, isTouched?: boolean) => void;
}

const Cron = (props: CronProps) => {
    const weekDays = ["Monday", "Friday", "Tuesday", "Saturday", "Wednesday", "Sunday", "Thursday"];
    const hours = Array.apply(0, Array(24)).map((_, index) => `${index}`.padStart(2, "0"));

    const [selectedStartHour, setSelectedStartHour] = useState("08");
    const [selectedStopHour, setSelectedStopHour] = useState("18");
    const [selectedDays, setSelectedDays] = useState<string[]>(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
    const [selectedStartItem, setSelectedStartItem] = useState<DropdownItem>({ id: "08", name: `08:00 ${props.timeZone}` });
    const [selectedStopItem, setSelectedStopItem] = useState<DropdownItem>({ id: "18", name: `18:00 ${props.timeZone}` });

    useEffect(() => {
        if (props.cronExpression) {
            const [, startHour, , , daysSegment] = props.cronExpression.startExpression.split(" ");
            const [, stopHour] = props.cronExpression.stopExpression.split(" ");
            const startHourLocalZone = calculateHourWithoutTimeZone(startHour, props.timeZone, daysSegment.split(",")).hour;
            const stopHourLocalZone = calculateHourWithoutTimeZone(stopHour, props.timeZone, daysSegment.split(",")).hour;  
            const days = calculateHourWithoutTimeZone(startHour, props.timeZone, daysSegment.split(",")).days;
            
            setSelectedStartHour(startHourLocalZone);
            setSelectedStartItem({ id: startHourLocalZone, name: `${startHourLocalZone}:00 ${props.timeZone}` });
            setSelectedStopHour(stopHourLocalZone);
            setSelectedStopItem({ id: stopHourLocalZone, name: `${stopHourLocalZone}:00 ${props.timeZone}` });
            setSelectedDays(days);
        }
    }, [props.cronExpression]);

    useEffect(() => {
        const convertedCron = convertToCron();

        props.setFieldValue && props.setFieldValue(props.name, convertedCron);
        if (convertedCron) {
            props.setFieldTouched && props.setFieldTouched(props.name, true);
        }

        props.onChange && props.onChange(convertedCron);

    }, [selectedStartHour, selectedStopHour, selectedDays]);

    function convertToCron() {
        if (selectedStartHour && selectedStopHour && selectedDays && selectedDays.length) {
            const zoneOffset = getZoneOffset();
            let startExpression;
            let stopExpression;

            if(zoneOffset > 0){
                startExpression = cronExpressionTimeZoneMoreThanZero(zoneOffset, parseInt(selectedStartHour), selectedDays);
                stopExpression = cronExpressionTimeZoneMoreThanZero(zoneOffset, parseInt(selectedStopHour), selectedDays);
            }else if(zoneOffset < 0){
                startExpression = cronExpressionTimeZoneLessThanZero(zoneOffset, parseInt(selectedStartHour), selectedDays);
                stopExpression = cronExpressionTimeZoneLessThanZero(zoneOffset, parseInt(selectedStopHour), selectedDays);
            }else {
                startExpression = `cron(0 ${parseInt(selectedStartHour)} ? * ${selectedDays.join(",")} *)`
                stopExpression = `cron(0 ${parseInt(selectedStopHour)} ? * ${selectedDays.join(",")} *)`
            }

            return {
                startExpression: startExpression,
                stopExpression: stopExpression
            };
        } else {
            return undefined;
        }
    }

    function getZoneOffset() {
        return parseInt(props.timeZone.split("UTC")[1] || "0");
    }

    return (
        <Field
            name={props.name}
            render={() => (
                <div className="cron">
                    <h6 className="cron__title">
                        <strong>{props.title}</strong>
                    </h6>
                    <div className="row">
                        {
                            weekDays.map(day => (
                                <div className="col-6" key={day}>
                                    <Checkbox
                                        name={day}
                                        checked={selectedDays.includes(day)}
                                        onChange={(checked) => {
                                            if (checked && !selectedDays.includes(day)) {
                                                setSelectedDays([...selectedDays, day]);
                                            } else {
                                                setSelectedDays(selectedDays.filter(d => d !== day))
                                            }
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>

                    <div className="page-content__dropdown-container pl--0 pr--0">
                        <Dropdown
                            label="Wake up time:"
                            items={
                                hours.map(h => ({ id: h, name: `${h}:00 ${props.timeZone}` }))
                            }
                            selectedItem={selectedStartItem}
                            onSelectItem={(item) => {
                                setSelectedStartItem(item);
                                setSelectedStartHour(item.id);
                            }}
                        />
                    </div>
                    
                    <div className="page-content__dropdown-container pl--0 pr--0">
                        <Dropdown
                            label="Sleep time:"
                            items={
                                hours.map(h => ({ id: h, name: `${h}:00 ${props.timeZone}` }))
                            }
                            selectedItem={selectedStopItem}
                            onSelectItem={(item) => {
                                setSelectedStopItem(item);
                                setSelectedStopHour(item.id);
                            }}
                        />
                    </div>
                    {
                        props.errors && props.errors[props.name] ?
                            <div className="page-content__error">
                                {
                                    (props.errors[props.name] as any).startExpression ||
                                    (props.errors[props.name] as any).stopExpression
                                }
                            </div> :
                            null
                    }
                </div>
            )}
        />
    );
};

export default Cron;