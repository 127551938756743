import React, { useState, useEffect } from "react";

interface FadeInProps {
  show?: boolean;
  type: string;
  children: React.ReactNode
  itemIndex: number;
  modal?: boolean;
}

const Animation = (props: FadeInProps) => {
  const {show, children, type, modal} = props;

  // Handle rendering of components
  const [render, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true);
  }, [show, children])

  // Handle unmounting when animation ends
  const onAnimationEnd = () => {
    if (!show) setRender(false)
  }

  return (
    render ? (
      <div
        className={`width--full ${show ? `${type}-in` : `${type}-out`} ${modal ? 'animation-modal' : ''}`}
        style={{
          animationDelay: `${ 100 * props.itemIndex}ms`,
          position: "relative",
          zIndex: 10
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    ) : null
  )
}

export default Animation;