import * as React from "react";

// Import components
import DropdownMenuContainer from "../DropdownMenuContainer/DropdownMenuContainer";
import MenuUsers from "../MenuUsers/MenuUsers";

import { ReactComponent as EditIcon } from "../../static/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import Avatar from "../Avatar/Avatar";

export interface ProjectBoxProps {
  itemId: string,
  title: string,
  description: string,
  logo: string,
  components: string,
  stages: string,
  hasFooter?: boolean,
  users: { image: string; name: string }[],
  classModifier?: string, // For example sm, md, lg
  onSelectItem?: (itemId: string) => void;
  onEditItem?: () => void;
  onRemoveItem?: () => void;
}

const ProjectBox = (props: ProjectBoxProps) => {
  return (
    <div className={`d-flex flex-column justify-content-between box box--projects ${props.classModifier ? `box--projects--${props.classModifier}` : ""}`}
      onClick={() => props.onSelectItem && props.onSelectItem(props.itemId)}>
      <div className="box__content">
        <div className="box__avatar">
          <Avatar
              key={props.itemId}
              name={props.title}
              size="90"
            />
        </div>

        <div className="d-flex flex-column justify-content-around align-items-center">
          <h4 className="box__title" >
            <strong>{props.title}</strong>
          </h4>
          <p className="box__text">{props.description}</p>
        </div>

        {/* Dropdown Menu */}
        <div className="box__menu"><DropdownMenuContainer actions={[
          {
            itemId: "1",
            title: "Edit",
            icon: <EditIcon />,
            onClick: () => props.onEditItem && props.onEditItem()
          },
          {
            itemId: "2",
            title: "Remove",
            icon: <RemoveIcon />,
            onClick: () => props.onRemoveItem && props.onRemoveItem()
          }
        ]}
        /></div>
      </div>

      {/* Render footer component if condition is met */}
      {props.hasFooter ? (
        <div className="box__footer d-flex justify-content-between align-items-center">
          <p>{props.stages} stages</p>
          <p>{props.components} components</p>
          <MenuUsers users={props.users} />
        </div>
      ) : null}

    </div>
  )
}

export default ProjectBox;