import React, { useState, useEffect, Fragment } from 'react';

import DropdownContainer, {
    DropdownItem
} from "../DropdownContainer/DropdownContainer";
import Card from '../Card/Card';
import CardHeader from '../CardHeader/CardHeader';

// DropdownMenuContainer icons
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";
import { Field, FormikErrors } from 'formik';
import { getContinuousIntegrationAPI } from '../../api';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../reducers';

export interface PipelineChangeEvent {
    pipelineId: string;
    artifactStep: string,
}

export interface SelectPipelineProps {
    value?: PipelineChangeEvent;
    onChange: (data: PipelineChangeEvent) => void;
    errors?: FormikErrors<{
        pipelineId: string | undefined;
        artifactStep: string | undefined;
    }>
    setFieldValue?: {
        (
            field: "pipelineId" | "artifactStep",
            value: any,
            shouldValidate?: boolean | undefined
        ): void;
        (field: string, value: any): void;
    }
}

const SelectPipeline = (props: SelectPipelineProps) => {

    const [pipelineId, setPipelineId] = useState<DropdownItem>();
    const [loading, setLoading] = useState<boolean>(false);
    const [pipelineList, setPipelineList] = useState<DropdownItem[]>([]);
    const [pipelineStep, setPipelineStep] = useState<DropdownItem>();
    const [pipelineStepList, setPipelineStepList] = useState<DropdownItem[]>([]);
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);

    useEffect(() => {

        if (pipelineList.length > 0 && props.value) {
            setPipelineStep({ id: props.value!.artifactStep, name: props.value!.artifactStep });
            const p = pipelineList.find(p => p.id == props.value!.pipelineId);
            setPipelineId({ id: p && p.id || "", name: p && p.name || "" })
        }
    }, [props.value, pipelineList])

    useEffect(() => {
        const fetch = async () => {
            const { data: { pipelines } } = await getContinuousIntegrationAPI().listPipelines(currentProject.id);
            setPipelineList(pipelines.map(p => ({ id: p.id, name: p.name })))
        }
        fetch();
    }, [])

    useEffect(() => {
        const fetch = async () => {
            if (pipelineId && pipelineId.id !== "") {
                const { data: { steps } } = await getContinuousIntegrationAPI().getPipelineSteps(currentProject.id, pipelineId.id);
                setLoading(false);
                setPipelineStepList(steps.map(s => ({ id: s, name: s })))
            }
        }
        fetch();
    }, [pipelineId])



    function handlePipelineIdSelect(item: DropdownItem) {
        props.onChange({ pipelineId: item.id, artifactStep: "" })
        setPipelineStepList([])
        setLoading(true);
        setPipelineId(item);
    }

    function handlePipelineStepSelect(item: DropdownItem) {
        setPipelineStep(item);
        props.onChange({ pipelineId: props.value!.pipelineId, artifactStep: item.id })
    }

    return (
        <Fragment>
            <div className="col-12 col-xl-6">
                <Card class="card dark compact">
                    <div className="card_header_container">
                        <CardHeader title="Deployment artifact" text="The artifact generated in a specific pipeline step" />
                    </div>
                    <div className="page-content__dropdown-container mb--30">
                        <Field
                            name="pipeline"
                            label="Pipeline"
                        >
                            {() => (
                                <div>
                                    <DropdownContainer
                                        selectedItem={pipelineId}
                                        items={pipelineList}
                                        label="Pipeline"
                                        placeholder="Select a pipeline"
                                        onSelectItem={(item) => {
                                            props.setFieldValue && props.setFieldValue("pipelineId", item.id);
                                            handlePipelineIdSelect(item);
                                        }}
                                    />
                                    {props.errors && props.errors.pipelineId ?
                                        <div className="page-content__error">{props.errors.pipelineId}</div>
                                        : null}
                                    {pipelineId && (!loading && pipelineStepList.length === 0) ?
                                        <p className="txt--red"> <br /><InfoIcon style={{ width: "15px", height: "15px" }} />
                                            &nbsp;This pipeline does not have any artifact step.
                                        </p>
                                        : null}
                                </div>
                            )}
                        </Field>
                    </div>

                    {
                        pipelineStepList.length === 0 ? null :
                            <div className="page-content__dropdown-container mb--30">
                                <Field
                                    name="pipelineStep"
                                    label="Pipeline Step"
                                    validateOnChange
                                >
                                    {() => (
                                        <div>
                                            <DropdownContainer
                                                selectedItem={pipelineStep!}
                                                items={pipelineStepList}
                                                label="Pipeline Step"
                                                placeholder="Select a Pipeline step"
                                                onSelectItem={(item) => {
                                                    props.setFieldValue && props.setFieldValue("artifactStep", item.id);
                                                    handlePipelineStepSelect(item);
                                                }}
                                            />
                                            {props.errors && props.errors.artifactStep ?
                                                <div className="page-content__error">{props.errors.artifactStep}</div>
                                                : null}
                                        </div>
                                    )}
                                </Field>
                            </div>
                    }
                </Card>
            </div>
        </Fragment>
    );
}

export default SelectPipeline;