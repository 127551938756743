
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";
import { toast } from "react-toastify";

export default function useGitAccountList(gitAccountId?: string) {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const {
        gitAccounts,
        isFetching: isFetchingAccounts,
        error: accountsError,
        gitAccountConnected
    } = useSelector((state: GlobalState) => state.gitAccount);
    const {
        gitRepositories,
        isFetching: isFetchingRepos,
        error: reposError
    } = useSelector((state: GlobalState) => state.gitAccount);

    // Get a list of Git accounts from the backend
    useEffect(() => {
        if (gitAccounts.length === 0) {
            dispatch(actions.fetchGitAccounts(currentProject.id));
        }
        if (gitAccountId) {
            dispatch(actions.fetchGitRepos(currentProject.id, gitAccountId));
        }
    }, [dispatch, gitAccountId]);

    useEffect(() => {
        if(gitAccountConnected) {
            toast.success("You have successfully connected your account.");
            dispatch(actions.connectGitAccountClear());
            dispatch(actions.fetchGitAccounts(currentProject.id));
        }
    }, [gitAccountConnected])

    return {
        gitAccounts,
        gitRepositories,
        isFetchingAccounts,
        isFetchingRepos,
        accountsError,
        reposError,
        gitAccountConnected
    };
}