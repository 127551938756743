import * as React from "react";

import TimelineItem from "../TimelineItem/TimelineItem"
import { TimelineItemsProps } from "../TimelineItem/TimelineItem";
import TimelineItemSkeleton from "../TimelineItem/TimelineItemSkeleton";

import Animation from "../Animations/Animations";

import { status } from "../../utils/deployment-status";

export interface TimelineProps {
  items: TimelineItemsProps[]
  isLoaded: boolean;
  onClick?: (itemId: string, type: string, stageId:string, pipelineId:string) => void;
}

const Timeline = (props: TimelineProps) => {

  function handleItemClick(itemId: string, type: string, stageName: string, pipelineId: string) {
    props.onClick && props.onClick(itemId, type, stageName, pipelineId);
  }

  return (
    <div className="timeline">
      <div className="timeline__container">
        {props.isLoaded ?
          props.items && props.items.length > 0 ?
            props.items.map((item: TimelineItemsProps, index: number) => (
              <Animation show={props.isLoaded} type="fade" itemIndex={index} key={`${item.itemId}-${index}`}>
                <TimelineItem
                  projectId={item.projectId}
                  itemId={item.itemId}
                  name={item.name}
                  type={item.type}
                  status={status(item.status)}
                  timestamp={item.timestamp}
                  onClick={() => handleItemClick(item.itemId, item.type, item.stageName!, item.pipelineId!)}
                />
              </Animation>
            ))
            :
            <Animation show={true} type="fade" itemIndex={1}>
              <h5 className="p--20 txt--grey txt--center width--full">No data available.</h5>
            </Animation>
          :
          [...Array(3)].map((_value, index) => (<TimelineItemSkeleton key={index}/>))
        }

      </div>
    </div>
  )
}

export default Timeline;