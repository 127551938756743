import React, { ChangeEvent } from "react";
import { Field } from "formik";
import { EcrProps } from "../../types/DockerRegistry";
import awsRegions from "../../utils/aws-regions";
import DropdownContainer, { DropdownItem } from "../DropdownContainer/DropdownContainer";
import { InputField } from "../InputField/InputField";

const awsRegionList = awsRegions.map(region => ({ id: region.value, name: region.name })) as DropdownItem[]

const ECR = ({ setFieldValue, awsRegion }: EcrProps) => (
    <>
        <div className="page-content__dropdown-container">
            <Field
                name="aws_account_id"
                placeholder="AWS Account Id"
                hasError={true}
                label="AWS Account ID"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("aws_account_id", e.target.value)}
            />
        </div>

        <div className="page-content__dropdown-container">
            <Field
                name="aws_access_key_id"
                placeholder="Access Key ID"
                hasError={true}
                type="password"
                label="Access Key Id"
                autoComplete="new-password"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("aws_access_key_id", e.target.value)}
            />
        </div>

        <div className="page-content__dropdown-container">
            <Field
                name="aws_secret_access_key"
                placeholder="Secret Access Key"
                hasError={true}
                type="password"
                label="Secret Access Key"
                autoComplete="new-password"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("aws_secret_access_key", e.target.value)}
            />
        </div>
        <div className="page-content__dropdown-container">
            <Field
                name="aws_region"
            >
                {() => (
                    <div>
                        <DropdownContainer
                            selectedItem={awsRegion}
                            items={awsRegionList}
                            label="AWS Region"
                            placeholder="Select an AWS region"
                            onSelectItem={item => setFieldValue("aws_region", item)}
                        />
                    </div>
                )}
            </Field>
        </div>
    </>
);

export { ECR };