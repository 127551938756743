import { StageDeploymentResponseResources, StageDeploymentResponseResponse } from "@microtica/ms-elasticsearch-sdk";
import { GetStageResponseInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";

export const getComponentPrevVersion = (
  resourceName: string,
  lastDeploymentId: string,
  deploymentHistory: StageDeploymentResponseResponse[],
  deploymentStatus: string,
  deploymentIndex: number,
  infrastructureAsCodeTool?: GetStageResponseInfrastructureAsCodeToolEnum
) => {
  let prevVersion: { name: string; link?: string } = {
    name: "n/a",
    link: undefined,
  };

  if (infrastructureAsCodeTool && infrastructureAsCodeTool === GetStageResponseInfrastructureAsCodeToolEnum.Terraform) {
    const isLastStatusFailed = deploymentHistory[deploymentIndex + 1] ?
      deploymentHistory[deploymentIndex + 1].status.endsWith("_FAILED") : false;

    // different handling of prev version if the deployment has status UPDATE_ROLLBACK, 
    // or if the deployment is a Queued update_rollback (it has status DEPLOY_QUEUED and his previous deployment has status FAILED)
    // get the component versions from the failed deployment instead of the last deployment (lastDeploymentId - which is a successful deployment)
    if (deploymentStatus.includes("UPDATE_ROLLBACK") || (deploymentStatus === "DEPLOY_QUEUED" && isLastStatusFailed)) {
      const deployment = deploymentHistory.slice(deploymentIndex + 1).find(d => d.status.endsWith("_FAILED"));
      if (deployment && deployment.resources) {
        const r = deployment.resources.find((r) => r.name === resourceName);

        prevVersion = {
          name: r && r.status !== "CREATE_FAILED" ? r.component.metadata.commit.type === "branch" ? r.component.version.substring(0, 7) : r.component.version : "n/a",
          link:
            r && r.status !== "CREATE_FAILED" && r.component.version && r.component.version !== "n/a"
              ? r.component.version.includes("-")
                ? `/pipelines/${r.component.pipelineId}/builds/${r.component.version}`
                : `${r.component.metadata.repository}/${r.component.metadata.repository.startsWith("https://github.com") ? "commit" : "commits"}/${r.component.metadata.commit.version}`
              : undefined,
        };
      }
      return prevVersion;
    }
  }
  const deployment = deploymentHistory.find(
    (d) => d.deploymentId === lastDeploymentId
  );

  if (deployment && deployment.resources) {
    const r = deployment.resources.find((r) => r.name === resourceName);
    prevVersion = {
      name: r ? r.component.metadata.commit.type === "branch" ? r.component.version.substring(0, 7) : r.component.version : "n/a",
      link:
        r && r.component.version && r.component.version !== "n/a"
          ? r.component.version.includes("-")
            ? `/pipelines/${r.component.pipelineId}/builds/${r.component.version}`
            : `${r.component.metadata.repository}/${r.component.metadata.repository.startsWith("https://github.com") ? "commit" : "commits"}/${r.component.metadata.commit.version}`
          : undefined,
    };
  }
  return prevVersion;
};

export const getComponentNextVersion = (resource: StageDeploymentResponseResources) => {
  return {
    name:
      resource.component.metadata.commit.type === "branch"
        ? resource.componentVersion.substring(0, 7)
        : resource.componentVersion,
    link:
      resource.component.version &&
        resource.component.version !== "n/a"
        ? resource.component.version.includes("-")
          ? `/pipelines/${resource.component.pipelineId}/builds/${resource.component.version}`
          : `${resource.component.metadata.repository}/${resource.component.metadata.repository.startsWith(
            "https://github.com"
          )
            ? "commit"
            : "commits"
          }/${resource.component.metadata.commit.version}`
        : undefined,
  }
}

export const checkVersions = (previousVersion: string, currentVersion: string) => {
  return previousVersion !== "n/a" && currentVersion !== "n/a" && previousVersion !== currentVersion
};