import { FetchComponents, FetchComponentBuilds, CreateComponent, DeleteComponent, FetchComponentDetails, UpdateComponent } from '../actions';
import { ComponentState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';

export const initialState: ComponentState = {
    components: [],
    componentBuilds: {},
    isProcessing: false,
    componentCreated: '',
    componentUpdated: '',
    error: errorEmptyObject
}
export default function reducer(state: ComponentState = initialState, action: FetchComponents
    | FetchComponentBuilds | CreateComponent | DeleteComponent | UpdateComponent | FetchComponentDetails): ComponentState {
    switch (action.type) {

        case constants.FETCH_COMPONENTS_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_COMPONENTS_SUCCESS: return { ...state, components: action.components, isProcessing: false, error: errorEmptyObject };
        case constants.FETCH_COMPONENTS_FAIL: return { ...state, error: action.error };

        case constants.FETCH_COMPONENT_BUILDS_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_COMPONENT_BUILDS_SUCCESS: return { ...state, componentBuilds: action.componentBuilds, isProcessing: false, error: errorEmptyObject };
        case constants.FETCH_COMPONENT_BUILDS_FAIL: return { ...state, error: action.error };

        case constants.CREATE_COMPONENT_START: return { ...state, isProcessing: true, componentCreated: '', error: errorEmptyObject };
        case constants.CREATE_COMPONENT_SUCCESS: return { ...state, isProcessing: false, componentCreated: action.componentId, error: errorEmptyObject };
        case constants.CREATE_COMPONENT_FAIL: return { ...state, isProcessing: false, error: action.error, componentCreated: '' };

        // Delete component
        case constants.DELETE_COMPONENT_START:
            return {
                ...state,
                isProcessing: true,
                error: errorEmptyObject
            };
        case constants.DELETE_COMPONENT_SUCCESS:
            return {
                ...state,
                isProcessing: false,
                error: errorEmptyObject,
                components: state.components.filter(component => component.id !== action.componentId)
            };
        case constants.DELETE_COMPONENT_FAIL:
            return {
                ...state,
                isProcessing: false,
                error: action.error
            };

        // Update component
        case constants.UPDATE_COMPONENT_SUCCESS:
            return {
                ...state,
                componentUpdated: action.componentId,
                error: errorEmptyObject,
                isProcessing: false
            };

        // Fetch component
        case constants.FETCH_COMPONENT_DETAILS_START:
            return {
                ...state,
                isProcessing: true,
                error: errorEmptyObject
            }
        case constants.FETCH_COMPONENT_DETAILS_SUCCESS:
            return {
                ...state,
                isProcessing: true,
                error: errorEmptyObject,
                component: action.component,
                pipeline: action.pipeline
            }

        default: {
            return state;
        }
    }
}
