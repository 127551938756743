const gcpRegions =
    [
        {
            "id": "AsiaEast1",
            "name": "APAC (Changhua County, Taiwan)",
            "value": "asia-east1"
        },
        {
            "id": "AsiaEast2",
            "name": "APAC (Hong Kong)",
            "value": "asia-east2"
        },
        {
            "id": "AsiaNortheast1",
            "name": "APAC (Tokyo, Japan)",
            "value": "asia-northeast1"
        },
        {
            "id": "AsiaNortheast2",
            "name": "APAC (Osaka, Japan)",
            "value": "asia-northeast2"
        },
        {
            "id": "AsiaNortheast3",
            "name": "APAC (Seoul, South Korea)",
            "value": "asia-northeast3"
        },
        {
            "id": "AsiaSouth1",
            "name": "APAC (Mumbai, India)",
            "value": "asia-south1"
        },
        {
            "id": "AsiaSoutheast1",
            "name": "APAC (Jurong West, Singapore)",
            "value": "asia-southeast1"
        },
        {
            "id": "AsiaSoutheast2",
            "name": "APAC (Jakarta, Indonesia)",
            "value": "asia-southeast2"
        },
        {
            "id": "AustraliaSoutheast1",
            "name": "APAC (Sydney, Australia)",
            "value": "australia-southeast1"
        },
        {
            "id": "EuropeCentral2",
            "name": "Europe (Warsaw, Poland)",
            "value": "europe-central2"
        },
        {
            "id": "EuropeNorth1",
            "name": "Europe (Hamina, Finland)",
            "value": "europe-north1"
        },
        {
            "id": "EuropeWest1",
            "name": "Europe (St. Ghislain, Belgium)",
            "value": "europe-west1"
        },
        {
            "id": "EuropeWest2",
            "name": "Europe (London, England)",
            "value": "europe-west2"
        },
        {
            "id": "EuropeWest3",
            "name": "Europe (Frankfurt, Germany)",
            "value": "europe-west3"
        },
        {
            "id": "EuropeWest4",
            "name": "Europe (Eemshaven, Netherlands)",
            "value": "europe-west4"
        },
        {
            "id": "EuropeWest6",
            "name": "Europe (Zurich, Switzerland)",
            "value": "europe-west6"
        },
        {
            "id": "NorthamericaNortheast1",
            "name": "North America (Montréal, Québec)",
            "value": "northamerica-northeast1"
        },
        {
            "id": "UsCentral1",
            "name": "North America (Council Bluffs, Iowa)",
            "value": "us-central1"
        },
        {
            "id": "UsEast1",
            "name": "North America (Moncks Corner, South Carolina)",
            "value": "us-east1"
        },
        {
            "id": "UsEast4",
            "name": "North America (Ashburn,  Virginia)",
            "value": "us-east4"
        },
        {
            "id": "UsWest1",
            "name": "North America (The Dalles, Oregon)",
            "value": "us-west1"
        },
        {
            "id": "UsWest2",
            "name": "North America (Los Angeles, California)",
            "value": "us-west2"
        },
        {
            "id": "UsWest3",
            "name": "North America (Salt Lake City, Utah)",
            "value": "us-west3"
        },
        {
            "id": "UsWest4",
            "name": "North America (Las Vegas, Nevada)",
            "value": "us-west4"
        },
        {
            "id": "SouthamericaEast1",
            "name": "South America (São Paulo, Brazil)",
            "value": "southamerica-east1"
        }
    ]




export default gcpRegions;