import { FetchMicroservices, DeleteMicroservice, FetchMicroserviceDetails } from '../actions';
import { MicroserviceState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';

export const initialState: MicroserviceState = {
    microservices: [],
    isProcessing: false,
    error: errorEmptyObject,
    microserviceCreated: '',
}

export default function reducer(state: MicroserviceState = initialState, action: FetchMicroservices | DeleteMicroservice | FetchMicroserviceDetails): MicroserviceState {
    switch (action.type) {
        // Fetch microservices
        case constants.FETCH_MICROSERVICES_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_MICROSERVICES_SUCCESS: return { ...state, microservices: action.microservices, isProcessing: false, error: errorEmptyObject };
        case constants.FETCH_MICROSERVICES_FAIL: return { ...state, error: action.error, };

        // Create microservice
        // case constants.CREATE_MICROSERVICE_START: return { ...state, isProcessing: true, microserviceCreated: '', error: errorEmptyObject };
        // case constants.CREATE_MICROSERVICE_SUCCESS: return { ...state, isProcessing: false, microserviceCreated: action.microserviceId, error: errorEmptyObject };
        // case constants.CREATE_MICROSERVICE_FAIL: return { ...state, isProcessing: false, error: action.error, microserviceCreated: '' };

        // Delete microservice
        case constants.DELETE_MICROSERVICE_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.DELETE_MICROSERVICE_SUCCESS: return { ...state, isProcessing: false, error: errorEmptyObject, microservices: state.microservices.filter(microservice => microservice.id !== action.microserviceId) };
        case constants.DELETE_MICROSERVICE_FAIL: return { ...state, isProcessing: false, error: action.error };


        // Fetch microserviec
        case constants.FETCH_MICROSERVICE_DETAILS_START:
            return {
                ...state,
                isProcessing: true,
                error: errorEmptyObject
            }
        case constants.FETCH_MICROSERVICE_DETAILS_SUCCESS:
            return {
                ...state,
                isProcessing: true,
                error: errorEmptyObject,
                microservice: action.microservice,
                pipeline: action.pipeline
            }
        default: {
            return state;
        }
    }
}
