import React, { useState, useEffect, Fragment, useReducer } from 'react';
import { Field, FormikErrors } from 'formik';

import Button from '../Button/Button';
import DropdownContainer, {
  DropdownItem
} from "../DropdownContainer/DropdownContainer";
import Card from '../Card/Card';
import CardHeader from '../CardHeader/CardHeader';
import { handleConnectBitbucket, handleConnectGitHub, handleConnectGitLab } from "../../utils/connect-git";
import useGitAccountList from '../../hooks/GitAccount';
import { InputField } from '../InputField/InputField';
import DropdownMenuContainer, { DropdownMenuItemProps } from '../DropdownMenuContainer/DropdownMenuContainer';
import { toast } from 'react-toastify';

export interface GitSourceChangeEvent {
  workdir?: string;
  gitAccountId?: string,
  gitRepositoryUrl?: string;
}

export interface GitSourceProps {
  value?: GitSourceChangeEvent;
  onChange?: (data: GitSourceChangeEvent) => void;
  errors?: FormikErrors<{
    gitAccount: string | undefined;
    gitRepo: string | undefined;
  }>
  setFieldValue?: {
    (
      field: "gitAccount" | "gitRepo",
      value: any,
      shouldValidate?: boolean | undefined
    ): void;
    (field: string, value: any): void;
  }
}

function reducer(state: GitSourceChangeEvent, action: { type: string } & GitSourceChangeEvent) {
  switch (action.type) {
    case "UPDATE_WORKDIR":
      return {
        ...state,
        workdir: action.workdir
      };
    case "UPDATE_GIT_ACCOUNT":
      return {
        ...state,
        gitAccountId: action.gitAccountId
      };
    case "UPDATE_GIT_REPO":
      return {
        ...state,
        gitRepositoryUrl: action.gitRepositoryUrl
      };
    default:
      return state;
  }
};

const GitSource = (props: GitSourceProps) => {
  const defaultWorkdir = props.value ? props.value.workdir : "/";

  // Hooks
  const [gitAccount, setGitAccount] = useState<DropdownItem>();
  const [gitRepo, setGitRepo] = useState<DropdownItem>();
  const { gitAccounts, gitRepositories, accountsError } = useGitAccountList(gitAccount && gitAccount.id);
  const [gitAccountList, setGitAccountList] = useState<DropdownItem[]>([]);
  const [gitRepositoryList, setGitRepositoryList] = useState<DropdownItem[]>([]);

  const [state, dispatch] = useReducer(reducer, {
    workdir: defaultWorkdir,
    gitAccountId: props.value && props.value.gitAccountId,
    gitRepositoryUrl: props.value && props.value.gitRepositoryUrl
  });

  useEffect(() => {
    // Fire onChange event handler with current state
    props.onChange && props.onChange(state);
  }, [state]);

  useEffect(() => {
    if (gitAccounts.length && state.gitAccountId) {
      const account = gitAccounts.find(acc => acc.gitAccountId === state.gitAccountId);
      if (account) {
        setGitAccount({ id: account.gitAccountId, name: account.accountName });
      }
    }
    setGitAccountList(gitAccounts.map(acc => ({ id: acc.gitAccountId, name: acc.accountName })));
  }, [gitAccounts]);

  useEffect(() => {
    if (gitRepositories.length && state.gitRepositoryUrl) {
      const repo = gitRepositories.find(repo => repo.url === state.gitRepositoryUrl);
      if (repo) {
        setGitRepo({ id: repo.id, name: repo.fullName });
      }
    }
    setGitRepositoryList(gitRepositories.map(repo => ({ id: repo.id, name: repo.fullName })));
  }, [gitRepositories]);

  function handleGitAccountSelect(item: DropdownItem) {
    setGitAccount(item);
    setGitRepo({ id: "", name: "" })
    dispatch({ type: "UPDATE_GIT_ACCOUNT", gitAccountId: item.id });
    dispatch({ type: "UPDATE_GIT_REPO", gitRepositoryUrl: "" });
  }
  useEffect(() => {
    toast.error(accountsError.message);
    setGitRepositoryList([]);
}, [accountsError]);

  function handleGitRepoSelect(item: DropdownItem) {
    setGitRepo(item);
    const repo = gitRepositories.find(repo => repo.id === item.id);
    dispatch({ type: "UPDATE_GIT_REPO", gitRepositoryUrl: repo ? repo.url : "" });
  }

  const actions: DropdownMenuItemProps[] = [
    {
      itemId: "1",
      title: "Connect Bitbucket Account",
      onClick: () => handleConnectBitbucket()
    },
    {
      itemId: "2",
      title: "Connect GitHub Account",
      onClick: () => handleConnectGitHub()
    },
    {
      itemId: "3",
      title: "Connect GitLab Account",
      onClick: () => handleConnectGitLab()
    }
  ];

  const emptyAccounts = (
    <div className="col-12 col-xl-6">
      <Card class="card dark compact">
        <CardHeader title="Source" text="Choose a Git repository from which the source code will be pulled and passed through the pipeline" />
        <div className="card__content">
          <Button
            className="btn btn--xl btn--lightBlue mb--20"
            children="Connect Bitbucket Account"
            onClick={handleConnectBitbucket}
          />

          <Button
            className="btn btn--xl btn--git"
            children="Connect GitHub Account"
            onClick={handleConnectGitHub}
          />
        </div>
      </Card>
    </div>
  );

  const existingAccounts = (
    <div className="col-12 col-xl-6">
      <Card class="card dark compact">
        <div className="card_header_container">
          <CardHeader title="Source" text="Choose a Git repository from which the source code will be pulled and passed through the pipeline" />
          <DropdownMenuContainer
            actions={actions}
            type="account"
          />
        </div>

        <div className="page-content__dropdown-container mb--30">
          <Field
            name="gitAccount"
            label="Git Account"
            render={() => (
              <div>
                <DropdownContainer
                  selectedItem={gitAccount}
                  items={gitAccountList}
                  label="Git Account"
                  placeholder="Select a Git account"
                  onSelectItem={(item) => {
                    props.setFieldValue && props.setFieldValue("gitAccount", item.id);
                    handleGitAccountSelect(item);
                  }}
                />
                {props.errors && props.errors.gitAccount ?
                  <div className="page-content__error">{props.errors.gitAccount}</div>
                  : null}
              </div>
            )}
          />
        </div>

        <div className="page-content__dropdown-container mb--30">
          <Field
            name="gitRepo"
            label="Git Account"
            validateOnChange
            render={() => (
              <div>
                <DropdownContainer
                  selectedItem={gitRepo!}
                  items={gitRepositoryList}
                  placeholder="Select a Git repository"
                  onSelectItem={(item) => {
                    props.setFieldValue && props.setFieldValue("gitRepo", item.id);
                    handleGitRepoSelect(item);
                  }}
                  label="Git Repository"
                />
                {props.errors && props.errors.gitRepo ?
                  <div className="page-content__error">{props.errors.gitRepo}</div>
                  : null}
              </div>
            )}
          />
        </div>

        <div className="page-content__dropdown-container">
          <Field
            name="workdir"
            placeholder="Path to the repo workdir"
            type="text"
            label="Pipeline specs dir"
            info="Microtica will expect to find microtica.yaml and schema.json files under this directory"
            hasError={true}
            component={InputField}
            value={state.workdir!}
            onChange={(event: any) => {
              props.setFieldValue && props.setFieldValue("workdir", event.target.value);
              dispatch({ type: "UPDATE_WORKDIR", workdir: event.target.value });
            }}
          />
        </div>
      </Card>
    </div>
  );

  return (
    <Fragment>
      {
        gitAccounts.length ? existingAccounts : emptyAccounts
      }
    </Fragment>
  );
}

export default GitSource;