import * as React from 'react';
import { ErrorInterface } from "../../types/index";
import { ReactComponent as DangerIcon } from "../../static/danger-icon.svg";

function ErrorMessage(props: ErrorInterface) {

    return (
        props.message ?
            <div className="inline-message error-message">
                <DangerIcon className="message-icon" />
                {props.message}
            </div> :
            null
    );
}

export default ErrorMessage;