import * as React from 'react';
import { ErrorInterface } from "../../types/index";
import { ReactComponent as DangerIcon } from "../../static/info-icon-white.svg";

function InfoMessage(props: ErrorInterface) {

    return (
        props.message ?
            <div className="inline-message info-message">
                <DangerIcon className="message-icon" />
                {props.message}
            </div> :
            null
    );
}

export default InfoMessage;