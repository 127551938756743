
import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { dockerPushStep, gitCloneStep, deployStep, cfnComponentStep, deployEnvironmentStep, triggerPipelineStep, terraformBuildStep, triggerReflectTestStep } from "../../utils/microtica-steps";
import ClipboardJS from "clipboard";
import Animation from "../Animations/Animations";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import AceEditor from "react-ace";

export interface MicroticaStepModal {
    stepName: string;
    onClose: any;
}

export default function (props: MicroticaStepModal) {
    const clipboard = new ClipboardJS('.snippet-copy');
    const [textCopied, setTextCopied] = useState<boolean>(false);
    const [step, setStep] = useState<{ title: string, description: string | React.ReactElement, snippet: string }>({ title: "", description: "", snippet: "" });

    clipboard.on('success', function (e) {
        setTextCopied(true)
        e.clearSelection();
    });
    useEffect(() => {
        switch (props.stepName) {
            case "docker-push": {
                setStep(dockerPushStep);
                break;
            }
            case "git-clone": {
                setStep(gitCloneStep);
                break;
            }
            case "deploy-kunernetes-service": {
                setStep(deployStep);
                break;
            }
            case "cfn-component": {
                setStep(cfnComponentStep);
                break;
            }
            case "deploy-environment": {
                setStep(deployEnvironmentStep);
                break;
            }
            case "trigger-pipeline": {
                setStep(triggerPipelineStep);
                break;
            }
            case "terraform-build": {
                setStep(terraformBuildStep);
                break;
            }
            case "trigger-reflect-test": {
                setStep(triggerReflectTestStep);
                break;
            }
        }
    }, []);
    return (
        <>
            <div className="page-overlay" />

            <Animation show={true} type="modal" modal itemIndex={1}>
                <div className="modal modal--configure">
                    {/* HEADER */}
                    <div className="modal__header">
                        <h3 className="modal__title">{step.title}</h3>
                        <span>
                            <p className="modal__text m--0">
                                maintained by&nbsp;
                                <a href="https://microtica.com" target="_blank" rel="noreferrer" style={{ color: "#0075be", fontSize: "inherit", fontWeight: 600 }}>
                                    Microtica
                                </a>
                            </p>
                        </span>
                    </div>

                    <div className="modal__close" onClick={props.onClose}>X</div>

                    <CustomScrollbars minHeight="300px" maxHeight="calc(100vh - 230px)" resetClass="reset--top">
                        {/* CONTENT */}
                        <div className="modal__content ml--15 mr--15">
                            <h6>
                                {step.description}
                            </h6>
                            <br /> <br />
                            <h6>
                                <a style={{ fontSize: "19px", color: "rgb(0, 117, 190)" }} href={`https://microtica.com/docs/pipeline-steps/${props.stepName}`} target="_blank" rel="noreferrer">Step documentation</a>
                            </h6>
                            <br /> <br />
                            <h5>
                                YAML Definition
                            </h5>
                            <span
                                className="snippet-copy-text"
                                style={!textCopied ? { display: "none" } : {}}
                            >Copied to clipboard</span>
                            <Button className="btn btn--sm snippet-copy"
                                data-clipboard-text={step.snippet}
                                onPointerOut={() => setTextCopied(false)}
                            >
                                Copy
                            </Button>

                            <AceEditor
                                showPrintMargin={false}
                                className="row yaml-editor"
                                focus
                                style={{ marginLeft: "-1px", marginTop: "20px", borderTop: "3px solid #16242c" }}
                                showGutter={true}
                                readOnly={true}
                                width="100%"
                                height={"40vh"}
                                mode={"yaml"}
                                theme="solarized_dark"
                                value={step.snippet}
                                fontSize={15}
                                highlightActiveLine={false}
                                setOptions={{
                                    showLineNumbers: true
                                }}
                            />
                        </div>
                    </CustomScrollbars>
                </div>
            </Animation>
        </>
    )
}