import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import { InputField } from "../../components/InputField/InputField";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import { ReactComponent as MicroticaLogoBig } from "../../static/microtica-big-logo.svg";
import { setPasswordSchema } from "../../utils/validation";
import { getUserManagementAPI } from "../../api";
import * as actions from "../../actions";
import { GlobalState } from "../../reducers";
import { trackUserRegister } from "../../tracking/authentication";

interface SetPasswordProps extends RouteComponentProps<{
  email: string;
  token: string;
}> { }

const SetPassword = (props: SetPasswordProps) => {
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state: GlobalState) => state.user);

  useEffect(() => {
    if (isLoggedIn) {
      props.history.push("/projects");
    }
  }, [isLoggedIn]);

  async function handleRegister(newPassword: string, firstName: string, lastName: string) {

    try {
      const { data } = await getUserManagementAPI().setUserPassword({
        newPassword,
        username: props.match.params.email,
        sessionToken: props.match.params.token
      });
      dispatch(actions.login(data));
      trackUserRegister(props.match.params.email, "email");

      if (firstName || lastName) {
        await getUserManagementAPI().updateUser({ firstName, lastName })
        const profile = JSON.parse(localStorage.getItem("profile")!);
        profile.firstName = firstName;
        profile.lastName = lastName;
        localStorage.setItem("profile", JSON.stringify(profile))
      }
    } catch (err) {
      toast.error(err.response.message || "Something went wrong with resetting your password!")
    }
  }

  return (
    <div className="container--full welcome">
      <div className="container--md container--padding d-flex flex-column align-items-center">
        <div className="mb--20 txt--center">
          <img
            className="microtica-text-logo mb--20"
            src={microticaTextLogo}
            alt="microtica"
          />
          <p className="txt--md txt--white font--roboto">
            Set your new password below to be able to access your account.
          </p>
        </div>

        <div className="box box--padding-lg mb--40">
          <h2 className="mb--35 txt--center font--roboto">
            <strong>Welcome!</strong>
          </h2>

          <Formik
            initialValues={{ username: props.match.params.email, firstName: "", lastName: "", newPassword: "" }}
            onSubmit={(values: { newPassword: string, firstName: string, lastName: string }) => {
              handleRegister(values.newPassword, values.firstName, values.lastName);
            }}
            validationSchema={setPasswordSchema}
            render={() => (
              <Form>
                <Field
                  name="username"
                  disabled={true}
                  hasError={true}
                  placeholder="Email"
                  type="text"
                  component={InputField}
                />
                <Field
                  name="firstName"
                  type="text"
                  icon="username"
                  hasError={true}
                  placeholder="First Name"
                  component={InputField}
                />
                <Field
                  name="lastName"
                  type="text"
                  icon="username"
                  hasError={true}
                  placeholder="Last Name"
                  component={InputField}
                />
                <Field
                  name="newPassword"
                  hasError={true}
                  placeholder="New Password"
                  type="password"
                  autoComplete="new-password"
                  component={InputField}
                />
                <Field
                  name="passwordConfirm"
                  hasError={true}
                  placeholder="Confirm Password"
                  type="password"
                  autoComplete="new-password"
                  component={InputField}
                />
                <Button
                  type="submit"
                  className="btn btn--xl btn--blue"
                  children="Complete register"
                />
              </Form>
            )}
          />
        </div>
      </div>
      <MicroticaLogoBig className="microtica-big-logo" />
      <Footer />
    </div>
  );
};

export default SetPassword;
