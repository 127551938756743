import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, Link } from 'react-router-dom';
import { GetStagesItem, GetComponentResponse } from '@microtica/ms-engine-sdk';
import { useModal } from 'react-modal-hook';
import MarkdownViewer from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Skeleton from "react-loading-skeleton";
import { Formik, Form, Field } from 'formik';
import { PipelineBuildDetails } from '@microtica/ms-ap-sdk';

import { GlobalState } from "../../reducers";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import ModalAction from "../../components/ModalAction/ModalAction";
import Button from "../../components/Button/Button";
import RadioButton from "../../components/RadioButton/RadioButton";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import StageStatus from "../../components/StageStatus/StageStatus";
import * as actions from "../../actions";

// Import image
import { ReactComponent as EditIcon } from "../../static/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as CtaImage } from "../../static/cta-image.svg";
import { getContinuousIntegrationAPI, getEngineAPI, getProjectAPI } from '../../api';
import { Dictionary } from '../../components/ModalConfigure/ConfigurationItem';
import ResourceConfigurationModal from '../../components/ModalConfigure/ResourceConfigurationModal';
import ModalDanger from '../../components/ModalDanger/ModalDanger';
import { toast } from 'react-toastify';
import { Project } from '@microtica/ms-project-sdk';
import DropdownContainer, { DropdownItem } from '../../components/DropdownContainer/DropdownContainer';
import ListView from '../../components/ListView/ListView';

import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";
import { ListItemProps } from '../../components/ListItem/ListItem';

const tableHeaders = [
  "Key",
  "Type",
  "Description"
];

interface ComponentDetailsProps extends RouteComponentProps<{ componentId: string }> {
  componentId: string,
}

const ComponentDetails = (props: ComponentDetailsProps) => {
  // Hooks
  const dispatch = useDispatch();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [name, setName] = useState("Component Details");
  const [isPublic, setIsPublic] = useState(false);
  const [pipelineName, setPipelineName] = useState<string>();
  const [componentId] = useState(props.match.params.componentId);
  const [branchFilter, setBranchFilter] = useState("n/a");
  const [gitRepository, setGitRepository] = useState({ repository: "n/a", link: "" });
  const [schema, setSchema] = useState({ inputs: [["", "", ""]], outputs: [["", "", ""]] });
  const [stages, setStages] = useState<GetStagesItem[]>([]);
  const [selectedStage, setSelectedStage] = useState<string | undefined>();
  const [hasActiveBuild, setHasActiveBuild] = useState(false);
  const [hasBuilds, setHasBuilds] = useState(false);
  const [build, setBuild] = useState<PipelineBuildDetails>();
  const [component, setComponent] = useState<GetComponentResponse>()
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<DropdownItem>();
  const [sharedWithProjects, setSharedWithProjects] = useState<ListItemProps[]>([]);
  const [canShare, setCanShare] = useState(false);

  // Mark content as loaded when fetching data is completed
  const [isLoaded, setIsLoaded] = useState(false);

  const stagesList = (<Formik
    initialValues={{ stageId: "" }}
    onSubmit={values => {
      handleAddToStage(values["stageId"]);
      hideAddToStageModal();
    }}
    render={({ values, setFieldValue }) => (
      <Form>
        <div className="modal__blackbox">
          {
            stages.map(stage => (
              <Field
                name="permission"
                render={() => (
                  <RadioButton
                    key={stage.id}
                    onChange={() => setFieldValue("stageId", stage.id)}
                    checked={stage.id === values["stageId"]}
                    label={stage.name}
                    subLabel={stage.description}
                    value={stage.name}
                  />
                )}>
              </Field>
            ))
          }
        </div>
        <br></br>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            type="submit"
            className="btn btn--lg btn--lightGreen"
            disabled={!values["stageId"]}
          >
            Add to Environment
          </Button>
        </div>
      </Form>
    )}>
  </Formik>);

  const noStages = (<React.Fragment>
    <div className="modal__blackbox">
      <p className="box__text">
        Environment is a place where components are combined and deployed in the Cloud, such as development and production.
        Combining multiple components will allow you to create custom cloud infrastructure for your applications.
        <br></br>
        <a href="https://microtica.atlassian.net/servicedesk/customer/portal/1/topic/a5cc9d92-3dc4-436a-98ad-89f0d5f370d0/article/92635200"
          target="_blank"
          rel="noreferrer">
          Read more about Environments
        </a>
      </p>
    </div>
    <br></br>
    <div className="d-flex justify-content-center align-items-center">
      <Link className="btn btn--md btn--green" to="/environments/create">Create Environment</Link>
    </div>
  </React.Fragment>
  )

  const [showAddToStageModal, hideAddToStageModal] = useModal(() => (
    <ModalAction class="modal modal--action modal--add-stage"
      name="Add Component To Environment"
      description="Select an Environment to which you want to add the Component"
      onClose={hideAddToStageModal}>
      {stages.length ? stagesList : noStages}
    </ModalAction>
  ), [stages]);

  const [showShareModal, hideShareModal] = useModal(() => (
    <ModalAction class="modal modal--action modal--add-stage"
      name="Share Component"
      description="Projects and teams can use this component without managing the complexity of the underlying infrastructure."
      onClose={hideShareModal}>
      <div className="page-content__dropdown-container mb--30 pl--0">
        <DropdownContainer
          selectedItem={selectedProject}
          items={
            projects.map(project => ({
              id: project.id,
              name: project.name,
              subTitle: project.description
            }))
          }
          label="Share with Project"
          placeholder="Choose a project to share the component with"
          onSelectItem={setSelectedProject}
        />
        <br />
        <div className="d-flex justify-content-center align-items-center">
          <Button
            type="submit"
            className="btn btn--md btn--orange"
            disabled={!selectedProject || selectedProject.id === ""}
            onClick={async () => {
              try {
                await getEngineAPI().shareComponent(componentId, currentProject.id, { granteeId: selectedProject!.id });
              } catch (error) {
                toast.error(error.response.data.message);
                throw error;
              }
              setSelectedProject({ id: "", name: "" });
              setProjects(projects.filter(p => p.id !== selectedProject!.id));
              setSharedWithProjects([
                ...sharedWithProjects,
                {
                  itemId: selectedProject!.id,
                  title: selectedProject!.name,
                  description: selectedProject!.subTitle as string
                }
              ])
            }}
          >
            Share
          </Button>
          <div className="page-content__info mt--0 ml--10" style={{ marginTop: "0" }}>
            <InfoIcon />
            <small style={{ color: "#a5b8ca" }}>
              With sharing you give READ permissions to this component
            </small>
          </div>
        </div>
      </div>
      <div>
        Shared with
      </div>
      <ListView
        class="list--height"
        listType="icons"
        placeholderText="This component is not shared with any project yet"
        items={sharedWithProjects}
        itemActions={[{
          title: "Revoke access",
          onClick: async (itemId) => {
            try {
              await getEngineAPI().unshareComponent(componentId, currentProject.id, { granteeId: itemId });
            } catch (error) {
              toast.error(error.response.data.message);
              throw error;
            }
            const proj = sharedWithProjects.find(p => p.itemId === itemId);
            setProjects([
              ...projects,
              {
                id: proj!.itemId,
                name: proj!.title,
                description: proj!.description
              }
            ]);
            setSharedWithProjects(
              sharedWithProjects.filter(item => item.itemId !== itemId)
            );
          },
          icon: <></>
        }]}
      />
    </ModalAction >
  ), [projects, selectedProject, sharedWithProjects]);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${name}" component?`}
      description="Do you really want to delete the component? This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleDeleteConfirmation}
    />
  ), [name]);

  const [showConfigModal, hideConfigModal] = useModal(() => (
    <ResourceConfigurationModal
      onSuccess={() => {
        toast.success("Successfully added component to environment");
        hideConfigModal();
      }}
      onClose={hideConfigModal}
      stageId={selectedStage!}
      componentId={componentId}
    />
  ), [selectedStage]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const [{ data: component }, { data: { projects } }] = await Promise.all([
          getEngineAPI().getComponent(componentId, currentProject.id),
          getProjectAPI().listProjects()
        ]);

        setCanShare(!!component.accessList.find(al => al.assigneeType === "parent" && al.assigneeId === currentProject.id));

        setComponent(component);

        const sharedWithProjectIds = component.accessList
          .filter(al => al.assigneeType === "shared")
          .map(al => al.assigneeId);

        setSharedWithProjects(sharedWithProjectIds.map(pId => {
          const project = projects.find(p => p.id === pId);
          return {
            itemId: project!.id,
            title: project!.name,
            description: project!.description
          }
        }));

        setProjects(projects.filter(p => !sharedWithProjectIds.includes(p.id) && p.id !== currentProject.id));

        if (component.schema) {
          const inputProps = component.schema.properties.inputs.properties as Dictionary<{
            type: string;
            description: string;
            default: string;
          }>;
          const requiredInputs = component.schema.properties.inputs.required as string[] || [];
          const outputProps = component.schema.properties.outputs.properties as Dictionary<{
            type: string;
            description: string;
            default: string;
          }> || {};

          setSchema({
            inputs: Object.keys(inputProps).map(key => ([
              requiredInputs.indexOf(key) !== -1 ? `*${key}` : key,
              inputProps[key].type,
              inputProps[key].description
            ])),
            outputs: Object.keys(outputProps).map(key => ([
              key,
              outputProps[key].type,
              outputProps[key].description
            ]))
          });
          setHasBuilds(true);
        }

        setName(component.name);
        setIsPublic(component.isPublic);

        const { data: pipeline } = await getContinuousIntegrationAPI().getPipelineById(currentProject.id, component.pipelineId);

        if (pipeline) {
          setPipelineName(pipeline.name);
          setBranchFilter(pipeline.branchFilter);
          setGitRepository({
            repository: pipeline.repositoryUrl.replace(/(https:\/\/bitbucket.org\/)|(https:\/\/github.com\/)|(https:\/\/gitlab.com\/)/, ""),
            link: pipeline.repositoryUrl
          });

          if (pipeline.latestBuild) {
            setBuild(pipeline.latestBuild);
            setHasActiveBuild((pipeline.latestBuild as any).status.includes("RUNNING") ? true : false);
          }
        }
      } catch (error) {
        toast.error(error.response.data.message);
        throw error;
      } finally {
        // Set content as loaded
        setIsLoaded(true);
      }
    }
    fetch();
  }, []);

  function handleAddToStage(stageId: string) {
    setSelectedStage(stageId);
    showConfigModal();
  }

  async function handleChooseStage() {
    const { data: response } = await getEngineAPI().getStages(currentProject.id);
    setStages(response.stages);
    showAddToStageModal();
  }

  function handleDeleteConfirmation() {
    dispatch(actions.deleteComponent(currentProject.id, componentId));
    hideConfirmationModal();
    props.history.push("/components");
  }

  // Header Items
  const headerMenu = (
    <DropdownMenuContainer
      key="headerMenu"
      actions={[
        {
          itemId: "1",
          title: "Edit",
          icon: <EditIcon />,
          onClick: () => props.history.push(`/components/${componentId}/edit`)
        },
        {
          itemId: "2",
          title: "Remove",
          icon: <RemoveIcon />,
          onClick: () => showConfirmationModal()
        }
      ]}
    />
  );
  const addToStageButton = (
    <>
      {
        canShare ?
          <Button
            key="addToStageBtn"
            className="btn btn--md btn--orange m--0"
            disabled={!hasBuilds || !canShare}
            onClick={() => showShareModal()}
          >
            Share {sharedWithProjects.length ? `(${sharedWithProjects.length})` : ''}
          </Button> : null
      }
      <Button
        key="addToStageBtn"
        className="btn btn--md btn--lightBlue m--0"
        disabled={!hasBuilds}
        onClick={handleChooseStage}
      >
        Add to Environment
      </Button>

    </>
  );

  const headerItems = [
    isPublic || !canShare ? null : headerMenu,
    addToStageButton
  ];

  const renderers = {
    code: ({ language, value }: { language: any, value: any }) => {
      return <SyntaxHighlighter style={dracula} language={language} children={value} />
    }
  }

  return (
    <>
      <main className="content d-flex flex-column justify-content-start">
        <PageHeader
          title={name}
          items={headerItems}
        />
        <div className="content__body ptb--30">
          <div className="details__status">
            <StageStatus
              items={[
                {
                  id: 1,
                  title: "Pipeline Name",
                  text: isLoaded ? pipelineName! : <Skeleton width="70px" />,
                },
                {
                  id: 2,
                  title: "Artifacts Step",
                  text: isLoaded ? component!.artifactStep : <Skeleton width="70px" />,
                },
                {
                  id: 3,
                  title: "Repository",
                  text: isLoaded ? <a target="_blank" rel="noreferrer" style={{ color: "#4a90e2" }} href={gitRepository.link}>{gitRepository.repository}</a> : <Skeleton width="120px" />,
                },
                {
                  id: 4,
                  title: "Branch Filter",
                  text: isLoaded ? branchFilter : <Skeleton width="70px" />
                }
              ]}
            />
          </div>
          {
            !hasBuilds ?
              <div className="details__overlay">
                <div className="details__cta">
                  {/* Image */}
                  <CtaImage />
                  {
                    hasActiveBuild ? <>
                      <h4><strong>Component is being built...</strong></h4>
                      <p>
                        There is already build in progress for this component. Component details will show up here once build is successfully completed.
                      </p>
                      {build ? <Link
                        className="btn btn--lg btn--lightGreen m--auto"
                        to={`/pipelines/${build.pipelineId}/builds/${build.id}`}>
                        View build execution
                      </Link> : null}
                    </> : <>
                      <h4><strong>README and Schema<br></br>are not available for this component</strong></h4>
                      <p>
                        There should be at least one successful build for this component so that README and Schema can be renderend on this page.
                        <br></br>
                        Trigger a new build to get full visibility of this component.
                      </p>
                    </>
                  }
                </div>
              </div> :
              <Card class="card dark card--details">
                <div className="details">
                  <div className="row no-gutters">
                    {/* Light side */}
                    <div className="col-7">
                      {
                        component && component.readme ?
                          <MarkdownViewer
                            source={component.readme}
                            renderers={renderers}
                            className="markdown-viewer"
                          /> :
                          <div className="details__overlay">
                            <div className="details__cta">
                              {/* Image */}
                              <CtaImage />
                              {
                                <>
                                  <h4><strong>README.md<br></br>is not available for this component</strong></h4>
                                  <p>
                                    Add README.md file in your repository to see the component documentation here.
                                  </p>
                                </>
                              }
                            </div>
                          </div>
                      }

                    </div>

                    {/* Dark side */}
                    <div className="col-5 details--dark">
                      <div className="details__row">
                        <div className="details__item">
                          <h5 className="details__title"><strong>Configuration</strong></h5>
                          <p className="details__text">
                            Parameters defined in <i>schema.json</i> file will appear below as specification for component input and output parameters.
                            Required parameters has to be provided when deploying this component.
                          </p>
                        </div>

                        <div className="details__item">
                          <h5 className="details__title"><strong>Input parameters</strong></h5>
                          <Table rows={schema.inputs} headers={tableHeaders} isLoaded={isLoaded} />
                        </div>

                        <div className="details__item">
                          <h5 className="details__title"><strong>Output parameters</strong></h5>
                          <Table rows={schema.outputs} headers={tableHeaders} isLoaded={isLoaded} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
          }
        </div>
      </main>
    </>
  )
};

export default ComponentDetails;