import React, { useState, useEffect } from "react";

// Import Components
import MenuItem from "../MenuItem/MenuItem";
import { MenuItemProps } from "../MenuItem/MenuItem";

export interface MenuGroupProps {
  title: string;
  items: MenuItemProps[];
  icon: string;
}

const MenuGroup = (props: MenuGroupProps) => {
  const [active, setActive] = useState(true);
  const [bigLinkActive, setBigLinkActive] = useState(false);

  const clickHandler = () => {
    setActive(!active);
  };

  let classes = 'menu__item d-flex justify-content-between align-items-center';
  if (active) {
    classes = classes + ' active';
  }

  // Check if the menu group has one of its links that are currently active and highlight the menu group
  useEffect(() => {
    const currentPath = window.location.pathname;
    const checkMenuForPath = props.items.some((link: any) => currentPath.includes(link.href))

    if (checkMenuForPath) {
      setBigLinkActive(true);
    } else {
      setBigLinkActive(false);
    }
  })

  return (
    <li className={`menu__group ${active ? 'menu__group--active' : ''}`}>
      <div className={classes} onClick={() => clickHandler()}>
        <div className={`menu__link menu__arrow ${bigLinkActive ? 'menu__link--active' : ''}`}>
          <img src={props.icon} alt={props.title}/>
          <span className="menu__title">{props.title}</span>
        </div>
      </div>
      <ul className="submenu">
        {props.items.map((item) => (
          <MenuItem key={item.href} id={item.id} title={item.title} href={item.href} />
        ))}
      </ul>
    </li>
  );
};

export default MenuGroup;
