import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";
import awsRegions from "../utils/aws-regions";

export default function useAwsAccountList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    // Why this does not return error and isFetching???
    const { awsAccounts, isFetching, error, isFinished } = useSelector((state: GlobalState) => state.awsAccount);

    // Get a list of AWS accounts from the backend
    useEffect(() => {
        dispatch(actions.fetchAWSAccounts(currentProject.id));
    }, [dispatch]);

    return {
        awsAccounts,
        awsRegions,
        isFetching,
        isFinished,
        error
    };
}