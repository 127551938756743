import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Project } from "@microtica/ms-project-sdk";
import { UserNotificationsNotifications, UserNotificationsNotificationsEntityTypeEnum } from "@microtica/ms-notification-sdk";

// Import icons
import { ReactComponent as ArrowIcon } from "../../static/arrow icons.svg";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import { GlobalState } from "../../reducers";
import Notification from "../Notification/Notification";
import { markNotificationAsRead } from "../../actions";
import { markNotificationsAsRead } from "../../actions/notifications/mark-notifications-as-read";
import useOnClickOutside from "../../hooks/clickOutside";
import Animation from "../Animations/Animations";

import { setCurrentProject } from "../../actions";

interface NotificationsMenuProps extends RouteComponentProps {
  menuOpen: boolean;
  notifications: UserNotificationsNotifications[];
  projects: Project[];
  filter: string;
  setFilter: (filter: string) => void;
  onClose: () => void;
}

const NotificationsMenu = (props: NotificationsMenuProps) => {
  const ref = React.useRef(null);
  const dispatch = useDispatch();
  const { projects, currentProject } = useSelector((state: GlobalState) => state.project);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  useEffect(() => {
    if (props.menuOpen) {
      setTimeout(() => {
        setFirstTimeLoad(false);
      }, 2000);
    }
  }, [props.menuOpen]);

  // Display notifications
  const handleNotifications = (action: string) => {
    return props.notifications.length > 0 ? props.notifications.map((notification, index) => {
      const p = projects.find(project => project.id === notification.projectId)
      if (p === undefined) {
        return;
      }
      let displayNotifications: boolean = false;

      if (action === "show") {
        displayNotifications = true;
      } else {
        displayNotifications = false;
      }

      // If notifications are loaded for the first time, include animation
      if (firstTimeLoad) {
        return <Animation show={displayNotifications} type="fade" key={index} itemIndex={index} >
          <Notification
            key={index}
            notification={notification}
            projectName={p!.name}
            onClick={() => handleReadNotification(notification)}
          />
        </Animation>
      } else {
        return <Notification
          key={index}
          notification={notification}
          projectName={p!.name}
          onClick={() => handleReadNotification(notification)}
        />
      }

    })
      : <h5 className="mt--30 txt--center txt--nm txt--grey">No unread notifications.</h5>
  }

  const handleReadNotification = (notification: UserNotificationsNotifications) => {
    const project = projects.find(p => p.id === notification.projectId);
    dispatch(setCurrentProject(project!));
    dispatch(markNotificationAsRead(currentProject.id, notification.id));
    if (notification.entityType === UserNotificationsNotificationsEntityTypeEnum.PIPELINE) {
      props.history.push(`/pipelines/${notification.itemId}/builds/${notification.version}`);
    } else if (notification.entityType === UserNotificationsNotificationsEntityTypeEnum.STAGE) {
      props.history.push(`/environments/${notification.itemId}/details`);
    }
    props.onClose();
  }
  useOnClickOutside(ref, () => props.onClose());

  return (
    <React.Fragment>
      {props.menuOpen ? <div className="page-overlay"></div> : null}

      <div ref={ref} className={`notifications__menu ${props.menuOpen ? 'notifications__menu--open' : ''}`}>
        <div className="notifications__header">
          <div className="circle circle--md circle--grey clickable" onClick={props.onClose}><ArrowIcon /></div>
          <h5 className="txt--nm ml--10">
            <strong>Notifications</strong>
          </h5>
        </div>

        <div className="notifications__tabs">

          <div className="notifications__filter">
            <span
              className={`notifications__item ${props.filter === "all" ? 'active' : ''}`}
              onClick={() => props.setFilter("all")}>All
            </span>
            <span
              className={`notifications__item ${props.filter === "unread" ? 'active' : ''}`}
              onClick={() => { props.setFilter("unread") }}>Unread
            </span>
          </div>

          <p className="m--0" onClick={() => dispatch(markNotificationsAsRead(currentProject.id))}>Mark all as read</p>
        </div>

        <CustomScrollbars maxHeight="90vh" resetClass="reset--top">
          <div className="notifications__body">
            {
              // !isProcessing ?
              props.menuOpen ? handleNotifications("show") : handleNotifications("hide")
              // : [...Array(3)].map((item, index) => (<NotificationSkeleton key={index} />))
            }
          </div>
        </CustomScrollbars>
      </div>
    </React.Fragment>
  )
}

export default NotificationsMenu;