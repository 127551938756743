import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";

export interface DeleteGcpAccountStart {
    type: constants.DELETE_GCP_ACCOUNT_START;
}
export interface DeleteGcpAccountSuccess {
    type: constants.DELETE_GCP_ACCOUNT_SUCCESS;
    gcpProjectId: string;
}
export interface DeleteGcpAccountError {
    type: constants.DELETE_GCP_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export type DeleteGcpAccount =
    | DeleteGcpAccountStart
    | DeleteGcpAccountSuccess
    | DeleteGcpAccountError

export function deleteGcpAccountStart(): DeleteGcpAccount {
    return {
        type: constants.DELETE_GCP_ACCOUNT_START
    };
}
export function deleteGcpAccountError(error: ErrorInterface): DeleteGcpAccount {
    return {
        type: constants.DELETE_GCP_ACCOUNT_FAIL,
        error
    };
}

export function deleteGcpAccountSuccess(
    gcpProjectId: string
): DeleteGcpAccount {
    return {
        type: constants.DELETE_GCP_ACCOUNT_SUCCESS,
        gcpProjectId
    };
}

export function deleteGcpAccount(
    projectId: string,
    gcpProjectId: string
): ThunkAction<Promise<void>, {}, {}, DeleteGcpAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteGcpAccount>) => {
        dispatch(deleteGcpAccountStart());
        try {
            await getAwsAPI().deleteGcpAccount(gcpProjectId, projectId);
            dispatch(deleteGcpAccountSuccess(gcpProjectId));
        } catch (error) {
            dispatch(deleteGcpAccountError(error.response.data));
        }
    };
}
