import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  StageDeploymentResponseLogs,
  StageDeploymentResponseResources,
  StageDeploymentResponseResponse,
  StageDeploymentResponseLogsErrorMessages,
} from "@microtica/ms-elasticsearch-sdk";

// Import components
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import PageHeader from "../../components/PageHeader/PageHeader";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import CollapsibleCard from "../../components/CollapsibleCard/CollapsibleCard";
import Commit from "../../components/Commit/Commit";

import Badge from "../../components/Badge/Badge";
import CardPlaceholders from "../../components/Card/CardPlaceholders";

import {
  DeploymentHistoryBody,
  PreviousToNext,
} from "./DeploymentHistoryCardBody";

import { GlobalState } from "../../reducers";
import { getElasticSearchAPI } from "../../api";
import { getEngineAPI } from "../../api";
import { statusColor } from "../../utils/status-color";
import { status } from "../../utils/deployment-status";
import {
  getComponentPrevVersion,
  checkVersions,
  getComponentNextVersion,
} from "../../utils/deployment-history";

import DeploymentHistoryScreen from "../../static/deployment-history.png";
import { ReactComponent as LogsIcon } from "../../static/logs-icon.svg";
import WarningIcon from "@material-ui/icons/Warning";
import { GetStageResponseInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";

interface DeploymentHistoryProps extends RouteComponentProps<{ stageId: string }, StaticContext, {
  redirectedAfterQuickDeploy?: boolean;
}> {
  stageId: string;
}

const DeploymentInsights = (props: DeploymentHistoryProps) => {
  const redirectedAfterQuickDeploy = props.location.state ? props.location.state.redirectedAfterQuickDeploy! : false;
  // Check if content is loaded
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusIsNotQueued, setStatusIsNotQueued] = useState(false);
  const [deploymentHistory, setDeploymentHistory] = useState<StageDeploymentResponseResponse[]>([]);
  const projectId = useSelector((stage: GlobalState) => stage.project.currentProject.id);
  const stageId = props.match.params.stageId;
  const [currentDeploymentId, setCurrentDeploymentId] = useState("")
  const [infrastructureAsCodeTool, setInfrastructureAsCodeTool] = useState<GetStageResponseInfrastructureAsCodeToolEnum | undefined>();
  const [currentDeploymentStatus, setCurrentDeploymentStatus] = useState("");
  const [lastDeploymentStatus, setLastDeploymentStatus] = useState("");
  const [disabledDeploymentHistory, setDisabledDeploymentHistory] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!checkIfStatusIsChanged(currentDeploymentStatus, lastDeploymentStatus)) {
        fetch();
      } else {
        clearInterval(timer);
      }
    }, 10000);

    return () => clearInterval(timer);
  }, [currentDeploymentStatus, statusIsNotQueued]);

  function checkIfStatusIsChanged(currentDeploymentStatus: string, lastDeploymentStatus: string) {
    return (
      ((lastDeploymentStatus.endsWith("IN_PROGRESS") ||
        lastDeploymentStatus === "DEPLOY_QUEUED") &&
        currentDeploymentStatus.endsWith("COMPLETE")) ||
      currentDeploymentStatus === "FAILED" ||
      (!redirectedAfterQuickDeploy &&
        lastDeploymentStatus === "" &&
        currentDeploymentStatus !== "" &&
        currentDeploymentStatus !== "DEPLOY_QUEUED" &&
        !currentDeploymentStatus.endsWith("IN_PROGRESS"))
    );
  }

  async function fetch() {
    try {
      const [{ data: { response } }, { data: stage }] = await Promise.all([
        getElasticSearchAPI().getStageDeploymentHistory(
          projectId,
          stageId
        ),
        getEngineAPI().getStage(
          stageId,
          projectId
        )
      ]);

      if (!response.length) {
        setDeploymentHistory([]);
        setCurrentDeploymentStatus(" "); //the status needs to be updated so the page won't continue sending calls to es
      } else {
        setCurrentDeploymentId(stage.currentDeploymentId || "")
        setInfrastructureAsCodeTool(stage.infrastructureAsCodeTool)
        setLastDeploymentStatus(currentDeploymentStatus);
        setCurrentDeploymentStatus(response[0].status);
        setDeploymentHistory(response as StageDeploymentResponseResponse[]);

        if (statusIsNotQueued && response[0].status === "DEPLOY_QUEUED") {
          setStatusIsNotQueued(false);
        }
        if (!statusIsNotQueued && response[0].status !== "DEPLOY_QUEUED") {
          setStatusIsNotQueued(true);
        }
      }
    } catch (e) {
      setDisabledDeploymentHistory(e.response.data.code === 402);
    }

    setIsLoaded(true);
  }

  function getCardHeader(data: StageDeploymentResponseResponse, index: number) {
    return [
      <span className="first-item">
        <div>
          <b>{status(data.status)}</b>
        </div>
        <div>{moment(data.timestamp).fromNow()}</div>
      </span>,
      <div id={data.deploymentId}>{data.deploymentId}</div>,
      <PreviousToNext
        previous={{
          name: data.lastDeploymentId || "n/a",
          link: data.lastDeploymentId ? `#${data.lastDeploymentId}` : undefined,
        }}
        next={{
          name: data.currentDeploymentId || "n/a",
          link: data.currentDeploymentId
            ? `#${data.currentDeploymentId}`
            : undefined,
        }}
      />,
      index === 0 && statusIsNotQueued && (
        <div>
          <Button
            className="btn btn--sm btn--green"
            onClick={() =>
              props.history.push(`/environments/${data.id}/details`)
            }
          >
            <LogsIcon width="16" height="16" />
            &nbsp;&nbsp;View Logs
          </Button>
        </div>
      ),
    ];
  }

  function getCardTableProps(
    resources: StageDeploymentResponseResources[],
    lastDeploymentId: string,
    deploymentStatus: string,
    deploymentIndex: number,
    logs?: StageDeploymentResponseLogs
  ) {
    return {
      tableModifier: "deployment-insights",
      placeholderText:
        currentDeploymentStatus === "DEPLOY_QUEUED"
          ? "The deployment is being queued. The resources will appear shortly."
          : "This deployment does not contain any cloud resources.",
      isLoaded: true,
      headers: ["Resources:left", "", "", "", ""],
      rows: resources.map((resource) => {
        const errorMessages: StageDeploymentResponseLogsErrorMessages[] = logs
          ? logs.events[resource.name]
            ? logs.events[resource.name].errorMessages
            : []
          : [];

        return [
          <div>
            <div>{resource.name}</div>
            <div className="txt--grey mt--5">{resource.component.name}</div>
          </div>,
          <div>
            <Badge name={status(resource.status)} status={resource.status} />
          </div>,
          <div className="versions-wrapper">
            {checkVersions(
              getComponentPrevVersion(
                resource.name,
                lastDeploymentId!,
                deploymentHistory,
                deploymentStatus,
                deploymentIndex,
                infrastructureAsCodeTool
              ).name,
              getComponentNextVersion(resource).name,
            ) && <label> * </label>}
            <PreviousToNext
              previous={getComponentPrevVersion(
                resource.name,
                lastDeploymentId!,
                deploymentHistory,
                deploymentStatus,
                deploymentIndex,
                infrastructureAsCodeTool
              )}
              next={getComponentNextVersion(resource)}
              target={resource.component.version.includes("-") ? "" : "_blank"}
            />
          </div>,
          <Commit
            name={resource.component.metadata.commit.name}
            message={resource.component.metadata.commit.message}
            version={resource.component.metadata.commit.version}
            href={
              resource.component.version.includes("-")
                ? `/pipelines/${resource.component.pipelineId}/builds/${resource.component.version}`
                : `${resource.component.metadata.repository}/${resource.component.metadata.repository.startsWith(
                  "https://github.com"
                )
                  ? "commit"
                  : "commits"
                }/${resource.component.metadata.commit.version}`
            }
            target={resource.component.version.includes("-") ? "" : "_blank"}
            userName={resource.component.metadata.commit.user.name}
            userAvatar={resource.component.metadata.commit.user.avatar}
            type={resource.component.metadata.commit.type}
          />,
          <div className="txt--left">
            {errorMessages.length > 0
              ? errorMessages.map((error, index) => (
                <div className="mb--5" key={index}>
                  <WarningIcon className="danger-icon" viewBox="0 -4 24 24" />
                  {`${error.logicalResourceId} (${error.resourceType}): ${error.statusReason}`}
                </div>
              ))
              : ""}
          </div>,
        ];
      }),
    };
  }

  const EmptyBox = () => (
    <div
      className="page-centered page-centered--project"
      style={{ paddingTop: "5%" }}
    >
      <img src={DeploymentHistoryScreen} alt="deployment history" />
      <div className="page-centered__title">Deployment Insights</div>
      <p className="txt--sm">
        Get an insight of each environment deployment, which components were
        deployed with which exact version.
        <br />
        Understand who did the changes and drill down to the exact commit.
      </p>
      {disabledDeploymentHistory ? (
        <Link className="btn btn--xl" to="/settings?tab=billing">
          Upgrade Plan
        </Link>
      ) : (
        <Link
          className="btn btn--xl btn--green"
          to={`/environments/${stageId}`}
        >
          Create new Deployment
        </Link>
      )}
    </div>
  );

  const showDeployments = (
    <CustomScrollbars maxHeight="calc(100vh - 140px)" resetClass="reset--top">
      <Card class="card card--stages dark">
        {deploymentHistory &&
          deploymentHistory.map((deployment, index) => {
            return (
              <CollapsibleCard
                key={deployment.deploymentId}
                header={getCardHeader(deployment, index)}
                body={
                  <DeploymentHistoryBody
                    branches={
                      deployment.resources
                        ? deployment.resources.map((r) => {
                          return r.component.metadata.commit.name;
                        })
                        : undefined
                    }
                    committers={
                      deployment.resources
                        ? deployment.resources.map((r) => {
                          return {
                            name: r.component.metadata.commit.user.name,
                            avatar: r.component.metadata.commit.user.avatar,
                          };
                        })
                        : undefined
                    }
                  />
                }
                tableProps={getCardTableProps(
                  deployment.resources ? deployment.resources : [],
                  deployment.lastDeploymentId!,
                  deployment.status,
                  index,
                  deployment.logs
                )}
                status={
                  statusColor(deployment.status) === "success" &&
                    deployment.deploymentId === currentDeploymentId
                    ? "success"
                    : statusColor(deployment.status) === "warning"
                      ? "warning"
                      : statusColor(deployment.status) === "fail"
                        ? "fail"
                        : ""
                }
                opened={
                  index === 0
                    ? true
                    : window.location.href.split("#")[1] ===
                    deployment.deploymentId
                }
                error={deployment.error ? true : false}
                errorMessage={deployment.error}
              />
            );
          })}
      </Card>
    </CustomScrollbars>
  );

  const Placeholders = () => (
    <Card class="card card--stages dark">
      <div className="skeleton skeleton__header">
        <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
        <p className="skeleton skeleton--animation skeleton--placeholder"></p>
      </div>
      <CardPlaceholders />
    </Card>
  );

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader title="Deployment Insights" />
      <div className="content__body">
        <React.Fragment>
          {isLoaded ? (
            deploymentHistory && deploymentHistory.length > 0 ? (
              showDeployments
            ) : (
              <EmptyBox />
            )
          ) : (
            <Placeholders />
          )}
        </React.Fragment>
      </div>
    </main>
  );
};

export default DeploymentInsights;
