import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, Link } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import MarkdownViewer from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Skeleton from "react-loading-skeleton";
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import * as actions from "../../actions";
import { GlobalState } from "../../reducers";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import ModalAction from "../../components/ModalAction/ModalAction";
import Button from "../../components/Button/Button";
import RadioButton from "../../components/RadioButton/RadioButton";
import DropdownMenuContainer from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import StageStatus from "../../components/StageStatus/StageStatus";

import MicroserviceConfigurationModal from "../../components/ModalConfigure/MicroserviceConfigurationModal";

// Import image
import { ReactComponent as EditIcon } from "../../static/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as CtaImage } from "../../static/cta-image.svg";
import { getKubeAPI } from '../../api';
import { Dictionary } from '../../components/ModalConfigure/ConfigurationItem';
import ModalDanger from '../../components/ModalDanger/ModalDanger';
import { ListKubernetesResponseKubernetesesTypeEnum } from '@microtica/ms-kube-sdk';

const tableHeaders = [
  "Key",
  "Type",
  "Description"
];

interface MicroserviceDetailsProps extends RouteComponentProps<{ microserviceName: string }> {
  microserviceName: string,
}

interface Cluster {
  id: string,
  name: string,
  endpoint: string,
  type: ListKubernetesResponseKubernetesesTypeEnum
}

const MicroserviceDetails = (props: MicroserviceDetailsProps) => {
  // Hooks
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [microserviceId, setMicroserviceId] = useState("");
  const [name, setName] = useState(props.match.params.microserviceName);
  const [microserviceRegistry, setMicroserviceRegistry] = useState<string>("");
  const [imageRepository, setImageRepository] = useState("n/a");
  const [schema, setSchema] = useState({ inputs: [["", "", ""]], outputs: [["", "", ""]] });
  const [dockerRegistry, setDockerRegistry] = useState("n/a");
  const [readme, setReadme] = useState<string>();
  const [displayHeaderActions, setDisplayHeaderActions] = useState<boolean>(false);

  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<Cluster>({ id: "", name: "", endpoint: "", type: ListKubernetesResponseKubernetesesTypeEnum.Personal });
  const [hasBuilds, setHasBuilds] = useState(false);
  const dispatch = useDispatch();

  // Mark content as loaded when fetching data is completed
  const [isLoaded, setIsLoaded] = useState(false);

  const clustersList = (<Formik
    initialValues={{ selection: "" }}
    onSubmit={values => {
      handleAddToStage();
      hideAddToStageModal();
    }}
    render={({ values, setFieldValue }) => (
      <Form>
        <div className="modal__blackbox">
          {
            clusters.map((cluster, index) => (
              <Field
                key={index}
                name="permission"
                render={() => (
                  <RadioButton
                    key={index}
                    onChange={() => {
                      setFieldValue("selection", `${cluster.id}-${cluster.name}`);
                      handleStageSelection(cluster);
                    }}
                    checked={`${cluster.id}-${cluster.name}` === values["selection"]}
                    label={cluster.name}
                    subLabel={cluster.endpoint}
                    value={cluster.name}
                  />
                )}>
              </Field>
            ))
          }
        </div>
        <br></br>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            type="submit"
            className="btn btn--lg btn--lightGreen"
            disabled={!values["selection"]}
          >
            Add to Cluster
          </Button>
        </div>
      </Form>
    )}>
  </Formik>);

  const noClusters = (<React.Fragment>
    <div className="modal__blackbox">
      <p className="box__text">
        In order to bring your service to life, you need to deploy it inside a Kubernetes Cluster.
        Microtica offers a Kuberneters Cluster Component which you can add and deploy to one of your environments.
      </p>
    </div>
    <br></br>
    <div className="d-flex justify-content-center align-items-center">
      <Link className="btn btn--md btn--lightBlue" to="/kubernetes/clusters">Connect Kubernetes</Link>
    </div>
  </React.Fragment>
  );

  const [showAddToStageModal, hideAddToStageModal] = useModal(() => (
    <ModalAction class="modal modal--action modal--add-stage"
      name="Add To Cluster"
      description="Select a Cluster to which you want to add the service"
      onClose={hideAddToStageModal}>
      {clusters.length ? clustersList : noClusters}
    </ModalAction>
  ), [clusters]);

  const [showMsConfigModal, hideMsConfigModal] = useModal(() => (
    <MicroserviceConfigurationModal
      namespace={undefined}
      microserviceName={name}
      clusterId={selectedCluster.id}
      microserviceRegistry={microserviceRegistry}
      onClose={hideMsConfigModal}
      onSuccess={() => {
        hideMsConfigModal();
        toast.success(`Service ${name} has been deployed in ${selectedCluster.name} cluster`);
        props.history.push(`/kubernetes/clusters/${selectedCluster.id}`);
      }}
    />
  ), [selectedCluster]);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${name}" service?`}
      description="Do you really want to delete the service. This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleDeleteConfirmation}
    />
  ), [handleDeleteConfirmation]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: microservice } = await getKubeAPI().getMicroservice(props.match.params.microserviceName, currentProject.id);
        if (microservice.schema) {
          setHasBuilds(true);
          const inputProps = microservice.schema.properties.inputs.properties as Dictionary<{
            type: string;
            description: string;
            default: string;
          }>;
          const requiredInputs = microservice.schema.properties.inputs.required as string[] || [];
          const outputProps = microservice.schema.properties.outputs.properties as Dictionary<{
            type: string;
            description: string;
            default: string;
          }>;

          setSchema({
            inputs: Object.keys(inputProps).map(key => ([
              requiredInputs.indexOf(key) !== -1 ? `*${key}` : key,
              inputProps[key].type,
              inputProps[key].description
            ])),
            outputs: Object.keys(outputProps).map(key => ([
              key,
              outputProps[key].type,
              outputProps[key].description
            ]))
          });
        }
        setMicroserviceId(microservice.id);
        setName(microservice.name);
        setMicroserviceRegistry(microservice.registry);
        setDockerRegistry(microservice.registry)
        setImageRepository(microservice.imageRepository);
        setReadme(microservice.readme);
        setDisplayHeaderActions(!microservice.isPublic);
      }
      catch (e) {
        toast.error(e.response.data.message)
      };
      setIsLoaded(true);
    }
    fetch()
  }, []);

  function handleStageSelection(cluster: Cluster) {
    setSelectedCluster(cluster);
  }

  function handleAddToStage() {
    showMsConfigModal();
  }

  async function handleChooseStage() {
    try {
      const { data: response } = await getKubeAPI().listKubernetes(currentProject.id);
      setClusters(response.kuberneteses.map(item => ({
        id: item.id,
        name: item.name,
        endpoint: item.endpoint,
        type: item.type
      })));
      showAddToStageModal();
    } catch (err) {
      toast.error(err.response.data.message)
    }
  }

  function handleMicroserviceUpdate() {
    props.history.push(`/kubernetes/services/${name}/edit`);
  }

  function handleMicroserviceRemove(microserviceName: string) {
    showConfirmationModal();
  }

  function handleDeleteConfirmation() {
    dispatch(actions.deleteMicroservice(name, currentProject.id, microserviceId));
    hideConfirmationModal();
    props.history.push(`/kubernetes/services`);
  }

  // Header Items
  const headerMenu = (
    <DropdownMenuContainer
      key="dropdown"
      actions={[
        {
          itemId: "1",
          title: "Edit",
          icon: <EditIcon />,
          onClick: handleMicroserviceUpdate
        },
        {
          itemId: "2",
          title: "Remove",
          icon: <RemoveIcon />,
          onClick: handleMicroserviceRemove
        }
      ]}
    />
  );
  const addToClusterButton = (
    <Button
      key="button"
      className="btn btn--md btn--lightBlue m--0"
      onClick={handleChooseStage}
    >
      Add to Cluster
    </Button>
  );

  const headerItems = [displayHeaderActions ? headerMenu : null, addToClusterButton];

  const renderers = {
    code: ({ language, value }: { language: any, value: any }) => {
      return <SyntaxHighlighter style={dracula} language={language} children={value} />
    }
  }

  return (
    <>
      <main className="content d-flex flex-column justify-content-start">
        <PageHeader
          title={name}
          items={headerItems}
        />
        <div className="content__body ptb--30">
          <div className="details__status details__status--microservice">
            <StageStatus
              items={[
                {
                  id: 1,
                  title: "Docker Registry",
                  text: isLoaded ? dockerRegistry : <Skeleton width="70px" />,
                },
                {
                  id: 2,
                  title: "Image repository",
                  text: isLoaded ? imageRepository : <Skeleton width="70px" />,
                }
              ]}
            />
          </div>

          {
            !hasBuilds ?
              <div className="details__overlay">

                <div className="details__cta">
                  {/* Image */}
                  <CtaImage />

                  <h4><strong>README and Schema<br></br>are not available for this service</strong></h4>
                  <p>
                    There should be at least one successful build for this service so that README and Schema can be renderend on this page.
                    <br></br>

                    Trigger a new build to get full visibility of this service.
                  </p>
                </div>
              </div>
              :
              <Card class="card dark card--details">
                <div className="details">
                  <div className="row no-gutters">
                    {/* Light side */}
                    <div className="col-7">
                      {
                        readme ?
                          <MarkdownViewer
                            source={readme}
                            renderers={renderers}
                            className="markdown-viewer"
                          /> :
                          <div className="details__overlay">
                            <div className="details__cta">
                              {/* Image */}
                              <CtaImage />
                              {
                                <>
                                  <h4><strong>README.md<br></br>is not available for this service</strong></h4>
                                  <p>
                                    Add README.md file in your repository to see the service documentation here.
                                  </p>
                                </>
                              }
                            </div>
                          </div>
                      }

                    </div>

                    {/* Dark side */}
                    <div className="col-5 details--dark">
                      <div className="details__row">
                        <div className="details__item">
                          <h5 className="details__title"><strong>Configuration</strong></h5>
                          <p className="details__text">
                            Parameters defined in <i>schema.json</i> file will appear below as specification for service input and output parameters.
                            Required parameters has to be provided when deploying this service.
                          </p>
                        </div>

                        <div className="details__item">
                          <h5 className="details__title"><strong>Input parameters</strong></h5>
                          <Table rows={schema.inputs} headers={tableHeaders} isLoaded={isLoaded} />
                        </div>

                        <div className="details__item">
                          <h5 className="details__title"><strong>Output parameters</strong></h5>
                          <Table rows={schema.outputs} headers={tableHeaders} isLoaded={isLoaded} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
          }
        </div>
      </main>
    </>
  )
};

export default MicroserviceDetails;