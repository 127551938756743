import * as yup from "yup";
import Ajv, { DependenciesParams } from "ajv";
import { MicroserviceConfigurationItem, ComponentSchemaPropertiesInputs } from "@microtica/ms-engine-sdk";
import { Dictionary } from "../components/ModalConfigure/ConfigurationItem";
import { GetAwsAccountResponse } from "@microtica/ms-aws-sdk";
import cloudTools from "./infrastructure-as-code-tools";
import cloudProviders from "./cloud-providers";

const ajv = new Ajv({
  allErrors: true,
  format: "full",
  useDefaults: false,
  jsonPointers: true
});

interface Length {
  min: number;
  max: number;
}

export const isLengthValid = (field: string, rules: Length) => {
  if (field.length < rules.min || field.length > rules.max) {
    return false;
  } else return true;
};



const firstName = yup
  .string()

const lastName = yup
  .string()

const username = yup
  .string()
  .email("Please enter a valid email")
  .required("Please enter an email address");

const redeemCode = yup
  .string()
  .required("Please enter a promo code");

const email = yup
  .string()
  .email("Please enter a valid email")
  .required("Please enter an email address");

const permission = yup
  .string()
  .oneOf(["owner", "admin", "write", "read"])
  .required("Please assign a permission");

const password = yup
  .string()
  .required("Please enter a password")
  .matches(new RegExp("^(?=.*[a-z])"), "Password must contain at least 1 lowercase letter")
  .matches(new RegExp("^(?=.*[A-Z])"), "Password must contain at least 1 uppercase letter")
  .matches(new RegExp("^(?=.*[0-9])"), "Password must contain at least 1 number")
  .min(8, "Password must be at least 8 characters")

const projectName = yup
  .string()
  .max(20, "Project name can't exceed 20 characters")
  .required("Please enter project name")

const scheduleName = yup
  .string()
  .required("Please enter schedule name")

const projectDescription = yup
  .string()
  .required("Please enter project description")

const componentName = yup
  .string()
  .min(3, "Component name must be at least 3 characters")
  .max(30, "Component name can't exceed 30 characters")
  .required("Please enter component name")

const emailReportName = yup
  .string()
  .min(3, "Report name must be at least 3 characters")
  .required("Please enter report name")

const pipelineName = yup
  .string()
  .min(3, "Pipeline name must be at least 3 characters")
  .max(30, "Pipeline name can't exceed 30 characters")
  .required("Please enter pipeline name")

const componentDescription = yup
  .string()
  .required("Please enter component description")

const componentType = yup
  .string()
  .required("Please select component type")
  .oneOf(["kubernetes", "database", "storage", "virtual-machine", "lambda", "vpc", "other"])

const branch = yup
  .string()
  .required("Please enter branch filter")

const gitAccount = yup
  .string()
  .required("Please select git account")

const gitRepo = yup
  .string()
  .required("Please select git repository")

const awsAccount = yup
  .string()
  .required("Please select AWS account")

const pipelineId = yup
  .string()
  .required("Please select Pipeline")

const artifactStep = yup
  .string()
  .required("Please select Pipeline step")

const awsRegion = yup
  .string()
  .required("Please select AWS region")


const awsAccountName = yup
  .string()
  .required("Please enter AWS account name")
  .max(30, "Aws account name can't exceed 30 characters")


const awsRoleArn = yup
  .string()
  .required("Please enter AWS role ARN")
  .matches(
    new RegExp("^arn:aws:iam::(.*):role/?[a-zA-Z_0-9+=,.@\\-_/]+$"),
    "AWS role ARN should be in format: arn:aws:iam::<ACCOUNT ID>:role/<ROLE NAME>"
  );

const awsExternalId = yup
  .string()
  .required("Please enter AWS external id")

const awsECRRepo = yup
  .string()
  .required("Please select AWS ECR repository")

const gcpProjectId = yup
  .string()
  .required("Please enter Google Cloud Platform project id")
  .max(30, "GoogleClound project id can't exceed 30 characters");
const gcpTargetServiceAccount = yup
  .string()
  .required("Please enter Google Cloud Platform service account");
const gcpProjectName = yup
  .string()
  .required("Please enter Google Cloud Platform project name");

const azureTenantId = yup
  .string()
  .required("Please enter Microsoft Azure Tenant ID")
  .max(36, "Microsoft Azure Tenant ID can't exceed 36 characters");
const azureApplicationId = yup
  .string()
  .required("Please enter Microsoft Azure App ID")
  .max(36, "Microsoft Azure App ID can't exceed 36 characters");
const azureSubscriptionId = yup
  .string()
  .required("Please enter Microsoft Azure Subscription ID")
  .max(64, "Microsoft Azure Subscription ID can't exceed 64 characters");
const azureSubscriptionName = yup
  .string()
  .required("Please enter Microsoft Azure Subscription ID")
  .max(64, "Microsoft Azure Subscription Name can't exceed 64 characters");
const azureClientSecret = yup
  .string()
  .required("Please enter Microsoft Azure Client Secret");

const microserviceName = yup
  .string()
  .min(2, "Microservice name must be at least 2 characters")
  .max(30, "Microservice name can't exceed 30 characters")
  .required("Please enter microservice name")
  .matches(new RegExp("^[a-z0-9\\-\\.\\~]*$"),
    "Microservice name can contain lowercase alphanumeric characters and the following special characters( - . ~ )")

const microserviceDescription = yup
  .string()
  .max(255, "Microservice description can't exceed 255 characters")
  .required("Please enter microservice description")

const stageName = yup
  .string()
  .min(3, "Environment name must be at least 3 characters")
  .max(20, "Environment name can't exceed 20 characters")
  .required("Please enter environment name")
  .matches(new RegExp("^[a-zA-Z0-9]*$"),
    "Environment name must contain only alphanumeric characters")

const stageDescription = yup
  .string()
  .min(1, "Environment description must have at least 1 character")
  .max(255, "Environment description can't exceed 255 characters")
  .required("Please enter environment description")

const cloudProvider = yup
  .string()
  .required("Please select cloud provider")
  .oneOf(cloudProviders.map(tool => tool.id))

const cloudTool = yup
  .string()
  .required("Please select Infrastructure as Code tool")
  .oneOf(cloudTools.map(tool => tool.id))


const slackUrl = yup
  .string()
  .url("URL is not valid");

const minScaledPods = yup
  .number()
  .required("Required field")

const maxScaledPods = yup
  .number()
  .required("Required field")

const apiToken = yup
  .string()
  .required("Required field")

const endpoint = yup
  .string()
  .required("Required field")

const name = yup
  .string()
  .required("Required field")
  .matches(RegExp("^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$"), "Registry name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character")

const docker_username = yup
  .string()
  .trim()
  .required("Required field")

const docker_password = yup
  .string()
  .trim()
  .required("Required field")

const aws_region = yup
  .string()
  .required("Required field")

const aws_account_id = yup
  .string()
  .trim()
  .required("Required field")

const aws_secret_access_key = yup
  .string()
  .trim()
  .required("Required field")

const aws_access_key_id = yup
  .string()
  .trim()
  .required("Required field")

const gitlab_hierarchy_type = yup
  .object({
    id: yup.string(),
    name: yup.string().oneOf(["groups", "projects"])
  })
  .required("Required field")

const gitlab_id = yup
  .string()
  .trim()
  .required("Required field")

const registry_url = yup
  .string()
  .trim()
  .required("Required field")

const awsAccountList = yup
  .array<GetAwsAccountResponse>()
  .required("Required field")

const awsRegionList = yup
  .array<{ id: string; value: string; name: string }>()
  .required("Required field")

const emails = yup
  .array<string>().of(yup.string().email("Please enter a valid emails"))
  .required("Required field")

export const imageRepositoryValidation = yup
  .string()
  .required("Required field")

export const loginSchema = yup.object({
  username
});

export const resetPasswordEmailSchema = yup.object({
  username
});

export const resetPasswordSchema = yup.object({
  newPassword: password,
  passwordConfirm: yup.string()
    .oneOf([yup.ref('newPassword')], "Passwords don't match")
    .required('Password confirm is required')
});

export const inviteUserSchema = yup.object({
  email,
  permission
});

export const registerSchema = yup.object({
  username,
  password
});

export const redeemCodeSchema = yup.object({
  redeemCode
});

export const setPasswordSchema = yup.object({
  firstName,
  lastName,
  newPassword: password,
  passwordConfirm: yup.string()
    .oneOf([yup.ref('newPassword')], "Passwords don't match")
    .required('Password confirm is required')
});

export const projectSchema = yup.object({
  name: projectName,
  description: projectDescription
})

export const componentSchema = yup.object({
  name: componentName,
  description: componentDescription,
  type: componentType,
  pipelineId,
  artifactStep,
  cloudTool,
  cloudProvider,
})

export const gitSchema = yup.object({
  branch,
  gitAccount,
  gitRepo
})

export const createPipelineSchema = yup.object({
  name: pipelineName,
  branch,
  gitAccount,
  gitRepo
})

export const pipelineSchema = yup.object({
  pipelineId,
  artifactStep
})

export const createAwsSchema = yup.object({
  awsAccountName,
  awsRoleArn,
  awsExternalId
})

export const createGcpSchema = yup.object({
  targetServiceAccount: gcpTargetServiceAccount,
  projectName: gcpProjectName
})

export const createAzureSchema = yup.object({
  tenantId: azureTenantId,
  subscriptionId: azureSubscriptionId,
  applicationId: azureApplicationId,
  subscriptionName: azureSubscriptionName,
  clientSecret: azureClientSecret
})

export const connectKubernetesSchema = yup.object({
  name,
  apiToken,
  endpoint,
  associateNamespace: yup.boolean(),
  associatedNamespace: yup
    .string()
    .when("associateNamespace", {
      is: true,
      then: yup
        .string()
        .matches(/^[a-z0-9][a-z0-9-]*[a-z0-9]$/, "Namespace can only contain lowercase alphanumeric characters or '-' and should start and end with an alphanumeric character")
        .min(5, "Namespace must be at least 5 characters")
        .max(63, "Namespace must not be longer than 63 characters")
        .required("Namespace is required")
    })
})

export const addDockerHubRegistrySchema = yup.object({
  name,
  docker_username,
  docker_password
})

export const addEcrRegistrySchema = yup.object({
  name,
  aws_account_id,
  aws_region: yup
    .object({
      id: yup.string().required(),
      name: yup.string().required()
    }).required(),
  aws_access_key_id,
  aws_secret_access_key
})

export const addGitlabContainerRegistrySchema = yup.object({
  name,
  docker_username,
  docker_password,
  gitlab_hierarchy_type,
  gitlab_id,
  registry_url
})


export const awsSchema = yup.object({
  awsAccount,
  awsRegion
})

export const awsSchemaExtended = yup.object({
  awsAccount,
  awsRegion,
  awsECRRepo
})

export const gitAWSSchema = gitSchema;

export const scheduleSchema = yup.object({
  name: yup
    .string()
    .required("Please enter schedule name"),
  cronExpression: yup
    .object({
      startExpression: yup.string().required("Please select at least one day and time range"),
      stopExpression: yup.string().required("Please select at least one day and time range")
    }),
  selectedByTag: yup.boolean(),
  tagKey: yup
    .string()
    .when("selectedByTag", {
      is: true,
      then: yup.string().required("Please select enter tag key"),
    }),
  tagValue: yup
    .string()
    .when("selectedByTag", {
      is: true,
      then: yup.string().required("Please select enter tag value"),
    }),
}).concat(awsSchema);

export const microserviceSchema = yup.object({
  name: microserviceName,
  description: microserviceDescription,
  registry: yup.object({
    id: yup.string().required("Required field")
  }),
  imageRepository: imageRepositoryValidation,
});

export const createStageGcpAccountSchema = yup.object().shape({
  gcpProject: yup.string().when(["gcpRegion", "gcpZone"], {
    is: (gcpRegion: string, gcpZone: string) => !!gcpRegion || !!gcpZone,
    then: yup.string().required("All fields in the Google Cloud Platform Account section should be either empty or populated")
  }),
  gcpRegion: yup.string().when(["gcpProject", "gcpZone"], {
    is: (gcpProject: string, gcpZone: string) => !!gcpProject || !!gcpZone,
    then: yup.string().required("All fields in the Google Cloud Platform Account section should be either empty or populated")
  }),
  gcpZone: yup.string().when(["gcpRegion", "gcpProject"], {
    is: (gcpRegion: string, gcpProject: string) => !!gcpRegion || !!gcpProject,
    then: yup.string().required("All fields in the Google Cloud Platform Account section should be either empty or populated")
  }),
}, [["gcpRegion", "gcpZone"], ["gcpProject", "gcpZone"], ["gcpRegion", "gcpProject"]]);

export const createStageAzureAccountSchema = yup.object().shape({
  azureTenant: yup.string().when("azureSubscription", {
    is: (azureSubscription: string) => !!azureSubscription,
    then: yup.string().required("All fields in the Microsoft Azure Account section should be either empty or populated")
  }),
  azureSubscription: yup.string().when("azureTenant", {
    is: (azureTenant: string) => !!azureTenant,
    then: yup.string().required("All fields in the Microsoft Azure Account section should be either empty or populated")
  }),
}, [["azureSubscription", "azureTenant"]]);

export const createStageSchema = yup.object({
  name: stageName,
  description: stageDescription,
  cloudProvider,
  cloudTool,
}).concat(createStageGcpAccountSchema).concat(createStageAzureAccountSchema);

export const createStageWithSavingScheduleSchema = yup.object({
  name: stageName,
  description: stageDescription,
  awsAccount,
  awsRegion,
  scheduleName: scheduleName,
  cloudProvider,
  cloudTool
})

export const updateStageAwsAccountSchema = yup.object({
  awsAccount: yup
    .string()
    .when("cloudProvider", {
      is: "Aws",
      then: yup.string().required("Please select AWS account"),
    }),
  awsRegion: yup
    .string()
    .when("cloudProvider", {
      is: "Aws",
      then: yup.string().required("Please select AWS region"),
    }),
});

export const updateStageGcpAccountSchema = yup.object({
  gcpProject: yup
    .string()
    .when("cloudProvider", {
      is: "Google",
      then: yup.string().required("Please select Google Cloud Platform project"),
    }),
  gcpRegion: yup
    .string()
    .when("cloudProvider", {
      is: "Google",
      then: yup.string().required("Please select Google Cloud Platform region"),
    }),
  gcpZone: yup
    .string()
    .when("cloudProvider", {
      is: "Google",
      then: yup.string().required("Please select Google Cloud Platform zone"),
    }),
});

export const updateStageAzureAccountSchema = yup.object({
  azureTenant: yup
    .string()
    .when("cloudProvider", {
      is: "Azurerm",
      then: yup.string().required("Please select Microsoft Azure Tenant"),
    }),
  azureSubscription: yup
    .string()
    .when("cloudProvider", {
      is: "Azurerm",
      then: yup.string().required("Please select Microsoft Azure Subscription"),
    })
})

export const updateStageSchema = yup.object({
  name: stageName,
  description: stageDescription,
  cloudProvider,
  cloudTool,
}).concat(updateStageAwsAccountSchema).concat(updateStageGcpAccountSchema).concat(updateStageAzureAccountSchema)

export const updateStageSchemaWithSavingScheduleSchema = yup.object({
  name: stageName,
  description: stageDescription,
  awsAccount,
  awsRegion,
  scheduleName: scheduleName,
  cloudProvider,
  cloudTool
})

export const slackSchema = yup.object({
  slackUrl
})
export const scallingPods = yup.object({
  minScaledPods,
  maxScaledPods
})

export const createEmailReportSchema = yup.object({
  name: emailReportName,
  awsAccountList,
  awsRegionList,
  emails,
})

// Stages
export const resourceName = yup
  .string()
  .required("Please enter resource name")
  .min(3, "Resource name must be at least 3 characters")
  .max(30, "Resource name can't exceed 30 characters")
  .matches(RegExp("^[A-Za-z0-9]+$"), "Resource name can contain only alphanumeric characters")

// Validates object against schema 
export const validateSchema = (schema: ComponentSchemaPropertiesInputs, validData: Dictionary<MicroserviceConfigurationItem> | any):
  Dictionary<string> => {
  const validator = ajv.compile(schema);
  validator(validData);

  const errorsDict: Dictionary<string> = {};

  if (validator.errors) {
    validator.errors.forEach(error => {
      const key = (error.keyword === "required")
        ? (error.params as DependenciesParams).missingProperty
        : error.dataPath.replace("/", "");
      if (error.message) {
        errorsDict[key] = error.message;
      }
    });
  }
  return errorsDict;
}




// schema.json used for microtica.yaml(pipeline spec) validation  
export const schemaDotJSONSchema = {
  properties: {
    type: { // required
      type: "string"
    },
    properties: { // required
      type: "object",
      additionalProperties: false,
      properties: {
        inputs: { // optional
          type: "object",
          properties: {
            type: { // required
              type: "string"
            },
            properties: { // required
              additionalProperties: {
                type: "object",
              }
            },
            required: { // optional
              type: "array",
              items: {
                type: "string"
              }
            }
          },
          required: ["properties", "type"]
        },
        parameters: { // optional
          type: "object",
          additionalProperties: false,
          properties: {
            type: { // required
              type: "string"
            },
            properties: { // required
              type: "object",
              properties: {
                stageId: { // required
                  type: "object"
                },
                resourceId: { // // required
                  type: "object"
                } // can have other properties
              },
              required: ["stageId", "resourceId"]
            }
          },
          required: ["type", "properties"]
        },
        outputs: { // optional
          type: "object",
          properties: {
            type: { // required
              type: "string"
            },
            properties: { // required
              type: "object",
              additionalProperties: {
                type: "object"
              }
            }
          },
          required: [
            "type",
            "properties"
          ]
        }
      }
    }
  },
  required: [
    "properties",
    "type"
  ]
};

// The entire structure of the Microtica.yaml file WITHOUT steps. No stages.
export const microticaYamlBodyWithoutStages = {
  additionalProperties: false,
  properties: {
    runtime: { // optional
      additionalProperties: false,
      minProperties: 1,
      type: "object",
      properties: {
        computeType: { // optional
          type: "string",
          enum: ["SMALL", "MEDIUM", "LARGE", "2XLARGE"]
        },
        packages: { // optional
          additionalProperties: {
            oneOf: [
              { type: "string" },
              { type: "number" }
            ]
          }
        }
      }
    },
    steps: { // required
      type: "object"
    }
  },
  required: [
    "steps"
  ]
};


export const DockerPushStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "docker-push"
    },
    target: { // optional
      type: "string"
    },
    registry: { // optional
      type: "string"
    },
    image_name: { // required
      type: "string"
    },
    dockerfile: { // optional
      type: "string"
    },
    build_context: { // optional
      type: "string"
    },
    tag: { // optional
      type: "string"
    },
    build_arguments: { // optional
      type: "string"
    },
    scan: { // optional
      type: "boolean"
    }
  },
  required: [
    "type",
    "image_name"
  ]
};

export const GitCloneStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "git-clone"
    },
    target: { // optional
      type: "string"
    },
    clone_dir: { // optional
      type: "string"
    }
  },
  required: [
    "type"
  ]
};

export const DeployKubernetesStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "deploy"
    },
    target: { // required
      type: "string",
      const: "kubernetes"
    },
    cluster: { // required
      type: "string"
    },
    service: { // required
      type: "string"
    },
    tag: { // optional
      type: "string"
    },
    branch_filter: { // optional
      type: "string"
    },
  },
  required: [
    "type",
    "target",
    "cluster",
    "service"
  ]
};

export const DeployEnvironmentStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "deploy"
    },
    target: { // required
      type: "string",
      const: "environment"
    },
    env_id: { // required
      type: "string"
    },
    branch_filter: { // optional
      type: "string"
    },
    partial: { // optional
      type: "object",
      properties: {
        resource_version_overrides: {
          type: "object",
          additionalProperties: {
            type: "string"
          }
        }
      },
      required: [
        "resource_version_overrides"
      ]
    }
  },
  required: [
    "type",
    "target",
    "env_id"
  ]
};

export const CfnComponentStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "cfn-component"
    },
    target: { // optional
      type: "string"
    },
    cfn_template: { // required
      type: "string"
    },
    schema: { // optional
      type: "string"
    }
  },
  required: [
    "type",
    "cfn_template"
  ]
};

export const TriggerPipelineStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "trigger-pipeline"
    },
    target: { // optional
      type: "string"
    },
    pipeline: { // required
      type: "string"
    },
    branch_filter: { // optional
      type: "string"
    },
    ref: { // optional
      type: "string"
    }
  },
  required: [
    "type",
    "pipeline"
  ]
};

export const TriggerReflectTestStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "trigger-reflect-test"
    },
    api_key: { // optional
      type: "string"
    },
    tag_slug: { // required
      type: "string"
    },
    parallelism: {
      type: "number"
    },
    overrides: {
      type: "object",
      properties: {
        hostnames: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              original: {
                type: "string"
              },
              replacement: {
                type: "string"
              }
            },
            required: [
              "original",
              "replacement"
            ]
          }
        },
        parameters: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              key: {
                type: "string"
              },
              value: {
                type: "string"
              }
            },
            required: [
              "key",
              "value"
            ]
          }
        },
        cookies: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              name: {
                type: "string"
              },
              value: {
                type: "string"
              },
              domain: {
                type: "string"
              },
              expires: {
                type: "number"
              },
              httpOnly: {
                type: "boolean"
              },
              maxAge: {
                type: "number"
              },
              path: {
                type: "string"
              },
              secure: {
                type: "boolean"
              }
            },
            required: [
              "name",
              "value",
              "domain",
              "expires",
              "httpOnly",
              "maxAge",
              "path",
              "secure"
            ]
          }
        },
        headers: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              name: {
                type: "string"
              },
              value: {
                type: "string"
              }
            },
            required: [
              "name",
              "value"
            ]
          }
        },
        localStorage: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              key: {
                type: "string"
              },
              value: {
                type: "string"
              }
            },
            required: [
              "key",
              "value"
            ]
          }
        },
        sessionStorage: {
          type: "array",
          minItems: 1,
          items: {
            type: "object",
            properties: {
              key: {
                type: "string"
              },
              value: {
                type: "string"
              }
            },
            required: [
              "key",
              "value"
            ]
          }
        },
      }
    },
    variables: {
      type: "object",
      additionalProperties: true
    },
    emailFailures: {
      type: "boolean"
    },
    gitHub: {
      type: "object",
      properties: {
        owner: {
          type: "string"
        },
        repo: {
          type: "string"
        },
        sha: {
          type: "string"
        }
      },
      required: [
        "owner",
        "repo",
        "sha"
      ]
    }
  },
  required: [
    "type",
    "api_key",
    "tag_slug"
  ]
};


export const GeneralStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    image: { // required
      type: "string"
    },
    commands: { // optional
      type: "array",
      minItems: 1, // should have at least 1 command
      items: {
        type: "string",
      }
    },
    artifacts: { // optional
      type: "object",
      minProperties: 1, // should have at least 1 artifact
      additionalProperties: false,
      properties: {
        json: { // optional
          type: "string"
        },
        files: { // optional
          type: "object",
          minProperties: 1, // should have at least 1 file artifact
          additionalProperties: {
            type: "string"
          }
        },
        reports: { // optional
          minProperties: 1, // should have at least 1 file artifact
          additionalProperties: {
            type: "string"
          }
        }
      }
    },
    parameters: { // optional
      type: "object",
      minProperties: 1, // should have at least 1 param
      additionalProperties: {
        type: "string"
      }
    },
    runtime: { // optional
      type: "object",
      additionalProperties: false,
      // no minProperties because we have a required param
      properties: {
        computeType: { // required
          type: "string",
          enum: ["SMALL", "MEDIUM", "LARGE", "2XLARGE"]
        },
        packages: { // optional
          type: "object",
          minProperties: 1, // should have at least 1 package
          additionalProperties: {
            oneOf: [
              { type: "string" },
              { type: "number" }
            ]
          }
        }
      },
      required: [
        "computeType"
      ]
    }
  },
  required: [
    "image"
  ]
};

export const TerraformBuildStep = {
  additionalProperties: false,
  properties: {
    title: { // optional
      type: "string"
    },
    type: { // required
      type: "string",
      const: "terraform-build"
    }
  },
  required: [
    "type"
  ]
};

