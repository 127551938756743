import * as constants from "../../constants";
import { UserState } from "../../types";

export interface ChangePageTitle {
    type: constants.CHANGE_PAGE_TITLE;
    title: string;
}


export interface CurrentUser {
    type: constants.CURRENT_USER;
    user: any;
}

export type PageTitle = ChangePageTitle;

export function changePageTitle(title: string): PageTitle {
    return {
        type: constants.CHANGE_PAGE_TITLE,
        title
    };
}
export function setCurrentUser(user: Partial<UserState>): CurrentUser {
    return {
        type: constants.CURRENT_USER,
        user
    };
}