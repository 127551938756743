import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";
import { UpdateGcpAccountRequest } from "@microtica/ms-aws-sdk";

export interface UpdateGcpAccountStart {
    type: constants.UPDATE_GCP_ACCOUNT_START;
}
export interface UpdateGcpAccountSuccess {
    type: constants.UPDATE_GCP_ACCOUNT_SUCCESS;
    gcpProjectId: string;
    object: UpdateGcpAccountRequest;
}
export interface UpdateGcpAccountError {
    type: constants.UPDATE_GCP_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export type UpdateGcpAccount =
    | UpdateGcpAccountStart
    | UpdateGcpAccountSuccess
    | UpdateGcpAccountError;

export function updateGcpAccountStart(): UpdateGcpAccount {
    return {
        type: constants.UPDATE_GCP_ACCOUNT_START
    };
}
export function updateGcpAccountError(error: ErrorInterface): UpdateGcpAccount {
    return {
        type: constants.UPDATE_GCP_ACCOUNT_FAIL,
        error
    };
}
export function updateGcpAccountSuccess(
    gcpProjectId: string,
    object: UpdateGcpAccountRequest
): UpdateGcpAccount {
    return {
        type: constants.UPDATE_GCP_ACCOUNT_SUCCESS,
        gcpProjectId,
        object
    };
}

export function updateGcpAccount(
    gcpProjectId: string,
    projectId: string,
    object: UpdateGcpAccountRequest
): ThunkAction<Promise<void>, {}, {}, UpdateGcpAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateGcpAccount>) => {
        dispatch(updateGcpAccountStart());
        try {
            await getAwsAPI().updateGcpAccount(projectId, object);
            dispatch(updateGcpAccountSuccess(gcpProjectId, object));
        } catch (error) {
            dispatch(updateGcpAccountError(error.response.data));
        }
    };
}
