import * as constants from "../../constants";
import { GetMicroservicesResponseMicroservices } from "@microtica/ms-kube-sdk";
import { getKubeAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";

export interface FetchMicroservicesStart {
    type: constants.FETCH_MICROSERVICES_START;
}
export interface FetchMicroservicesSuccess {
    type: constants.FETCH_MICROSERVICES_SUCCESS;
    microservices: GetMicroservicesResponseMicroservices[];
}
export interface FetchMicroservicesError {
    type: constants.FETCH_MICROSERVICES_FAIL;
    error: ErrorInterface;
}

export type FetchMicroservices =
    | FetchMicroservicesStart
    | FetchMicroservicesSuccess
    | FetchMicroservicesError

export function fetchMicroservicesStart(): FetchMicroservices {
    return {
        type: constants.FETCH_MICROSERVICES_START
    };
}
export function fetchMicroservicesError(error: ErrorInterface): FetchMicroservices {
    return {
        type: constants.FETCH_MICROSERVICES_FAIL,
        error
    };
}
export function fetchMicroservicesSuccess(
    microservices: GetMicroservicesResponseMicroservices[] = []
): FetchMicroservices {
    return {
        type: constants.FETCH_MICROSERVICES_SUCCESS,
        microservices
    };
}

export function fetchMicroservices(
    projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchMicroservices> {
    return async (dispatch: ThunkDispatch<{}, {}, FetchMicroservices>) => {
        dispatch(fetchMicroservicesStart());
        try {
            const res = await getKubeAPI().getMicroservices(projectId);
            dispatch(fetchMicroservicesSuccess(res.data.microservices));
        } catch (error) {
            dispatch(fetchMicroservicesError(error.response.data));
        }
    };
}