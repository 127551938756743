import React, { ChangeEvent } from "react";
import { Field } from "formik";
import { DockerHubProps } from "../../types/DockerRegistry";
import { InputField } from "../InputField/InputField";

const DockerHub = ({ setFieldValue }: DockerHubProps) => (
    <>
        <div className="page-content__dropdown-container">
            <Field
                name="docker_username"
                placeholder="Docker Username"
                hasError={true}
                label="Docker Username"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("docker_username", e.target.value)}
            />
        </div>
        <div className="page-content__dropdown-container">
            <Field
                name="docker_password"
                placeholder="Docker password/token"
                hasError={true}
                type="password"
                label="Docker Password/Token"
                autoComplete="new-password"
                component={InputField}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("docker_password", e.target.value)}
            />
        </div>
    </>
);

export { DockerHub };