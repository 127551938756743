import { FetchPipelines } from '../actions';
import { PipelineState, errorEmptyObject } from '../types/index';
import * as constants from '../constants';
import { DeletePipeline } from '../actions/pipelines/delete-pipeline';

export const initialState: PipelineState = {
    pipelines: [],
    isFetching: false,
    error: errorEmptyObject
}

export default function reducer(state: PipelineState = initialState, action: FetchPipelines | DeletePipeline): PipelineState {
    switch (action.type) {
        case constants.FETCH_PIPELINES_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_PIPELINES_SUCCESS: return { ...state, pipelines: action.pipelines, isFetching: false, error: errorEmptyObject };
        case constants.FETCH_PIPELINES_FAIL: return { ...state, isFetching: false, error: action.error };

        case constants.DELETE_PIPELINE_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.DELETE_PIPELINE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                pipelines: state.pipelines.filter(pipeline => pipeline.id != action.pipelineId)
            };

        case constants.DELETE_PIPELINE_FAIL: return { ...state, error: action.error };
        default: {
            return state;
        }
    }
}
