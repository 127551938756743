import * as constants from "../../../constants";
import {
    AddAzureAccountRequest,
     GetAzureAccountResponse
} from "@microtica/ms-aws-sdk";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";

export interface ConnectAzureAccountStart {
    type: constants.CONNECT_AZURE_ACCOUNT_START;
}
export interface ConnectAzureAccountSuccess {
    type: constants.CONNECT_AZURE_ACCOUNT_SUCCESS;
    azureAccount: GetAzureAccountResponse;
}
export interface ConnectAzureAccountError {
    type: constants.CONNECT_AZURE_ACCOUNT_FAIL;
    error: ErrorInterface;
}
export interface ConnectAzureAccountClear {
    type: constants.CONNECT_AZURE_ACCOUNT_CLEAR;
}

export type ConnectAzureAccount =
    | ConnectAzureAccountStart
    | ConnectAzureAccountSuccess
    | ConnectAzureAccountError
    | ConnectAzureAccountClear

export function connectAzureAccountStart(): ConnectAzureAccount {
    return {
        type: constants.CONNECT_AZURE_ACCOUNT_START
    };
}
export function connectAzureAccountError(error: ErrorInterface): ConnectAzureAccount {
    return {
        type: constants.CONNECT_AZURE_ACCOUNT_FAIL,
        error
    };
}
export function connectAzureAccountClear(): ConnectAzureAccount {
    return {
        type: constants.CONNECT_AZURE_ACCOUNT_CLEAR
    };
}

export function connectAzureAccountSuccess(
    azureAccount: GetAzureAccountResponse
): ConnectAzureAccount {
    return {
        type: constants.CONNECT_AZURE_ACCOUNT_SUCCESS,
        azureAccount
    };
}

export function connectAzureAccount(
    projectId: string,
    object: AddAzureAccountRequest
): ThunkAction<Promise<void>, {}, {}, ConnectAzureAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, ConnectAzureAccount>) => {
        dispatch(connectAzureAccountStart());
        try {
            await getAwsAPI().addAzureAccount(projectId, object);
            dispatch(connectAzureAccountSuccess(object));
        } catch (error) {
            dispatch(connectAzureAccountError(error.response.data));
        }
    };
}