import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, RouteComponentProps, useLocation } from "react-router-dom";

import ComponentsList from "./pages/ComponentsList/ComponentsList";
import EnvironmentList from "./pages/StagesList/StagesList";
import StageDetails from "./pages/StageDetails/StageDetails";
import PipelinesList from "./pages/Pipelines/PipelinesList";
import PipelineDetails from "./pages/Pipelines/PipelineDetails";
import Register from "./pages/Account/Register";
import Login from "./pages/Account/Login";
import MicroservicesList from "./pages/MicroservicesList/MicroservicesList";
import BuildList from "./pages/Pipelines/BuildsList";
import MenuBar from "./components/MenuBar/MenuBar";
import BuildDetails from "./pages/BuildDetails/BuildDetails";
import ComponentDetails from "./pages/ComponentDetails/ComponentDetails";
import MicroserviceDetails from "./pages/MicroserviceDetails/MicroserviceDetails";
import CreateMicroservice from "./pages/CreateMicroservice/CreateMicroservice";
import CreatePipeline from "./pages/Pipelines/CreatePipeline";
import { GlobalState } from "./reducers";
import Sidebar from "./components/Sidebar/Sidebar";
import Overview from "./pages/Overview/Overview";
import Projects from "./pages/Projects/Projects";
import CreateComponent from "./pages/CreateComponent/CreateComponent";
import CreateStage from "./pages/CreateStage/CreateStage";
// import ReplicateStage from "./pages/ReplicateStage/ReplicateStage";
import StageCluster from "./pages/StageCluster/StageCluster";
import Settings from "./pages/Settings/Settings";
import DeploymentInsights from "./pages/DeploymentHistory/DeploymentHistory";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeploymentDetails from "./pages/DeploymentDetails/DeploymentDetails";
import { StaticContext } from "react-router";
import VerifyAccount from "./pages/Account/VerifyAccount";
import MyAccount from "./pages/Account/MyAccount";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import DeploymentPlan from "./pages/DeploymentPlan/DeploymentPlan";
import SetPassword from "./pages/Account/SetPassword";
import MicroserviceLogsAndEvents from "./pages/MicroserviceDetails/MicroserviceLogsAndEvents"
import ResetPasswordConfirmation from "./pages/Account/ResetPasswordConfirmation";
import ResetPassword from "./pages/Account/ResetPassword";
import CostExplorer from "./pages/CostOptimization/CostExplorer";
import CostOptimizationSchedules from "./pages/CostOptimization/Schedules";
import CostOptimizationCreateSchedule from "./pages/CostOptimization/CreateSchedule";
import Reports from "./pages/CostOptimization/Reports";
import Kubernetes from "./pages/Kubernetes/Kubernetes";
import DockerRegistriesList from "./pages/DockerRegistriesList/DockerRegistriesList";
import AdvisorStatistics from "./pages/Advisor/Statistics";

import RegisterAppSumo from "./pages/Account/RegisterAppSumo";
import AddDockerRegistry from "./pages/AddDockerRegistry/AddDockerRegistry";
import RegisterAWSMarketplace from "./pages/Account/RegisterAWSMarketplace";
import { trackEvent } from "./tracking";

const App = () => {
  toast.configure({
    position: "top-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
  const { isLoggedIn } = useSelector((state: GlobalState) => state.user);
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      trackEvent("pageview", { page: `${location.pathname}${location.search}` });
    }
  }, [location]);

  const routes: {
    path: string;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> |
    { (props: RouteComponentProps<any, StaticContext, any>): React.Component };
  }[] = [
      { path: "/", component: Overview },
      { path: "/page-not-found", component: PageNotFound },
      { path: "/projects", component: Projects },
      { path: "/overview", component: Overview },

      // Account
      { path: "/account", component: MyAccount },
      { path: "/account/set-password/:email/:token", component: SetPassword },

      // Components
      { path: "/components/create", component: CreateComponent },
      { path: "/components/:componentId", component: ComponentDetails },
      {
        path: "/components/:componentId/edit",
        component: (props: RouteComponentProps<any, StaticContext, any>) => <CreateComponent mode="edit" {...props} />
      },
      { path: "/components", component: ComponentsList },

      // Pipelines
      { path: "/pipelines", component: PipelinesList },
      { path: "/pipelines/create", component: CreatePipeline },
      { path: "/pipelines/:pipelineId/edit", component: CreatePipeline },
      { path: "/pipelines/:pipelineId/builds/:buildId", component: BuildDetails },
      { path: "/pipelines/:pipelineId/builds", component: BuildList },
      { path: "/pipelines/:pipelineId/details", component: PipelineDetails },

      // Stages
      { path: "/environments", component: EnvironmentList },
      { path: "/environments/create", component: CreateStage },
      { path: "/environments/:stageId/edit", component: CreateStage },
      { path: "/environments/:stageId/plan", component: DeploymentPlan },
      { path: "/environments/:stageId/details", component: DeploymentDetails },
      { path: "/environments/:stageId/replicate", component: CreateStage },
      { path: "/environments/:stageId", component: StageDetails },
      { path: "/environments/:stageId/deployment-insights", component: DeploymentInsights },

      // Kubernetes
      { path: "/kubernetes/clusters", component: Kubernetes },
      { path: "/kubernetes/clusters/:clusterId", component: StageCluster },
      { path: "/kubernetes/clusters/:clusterId/namespaces/:namespace/:microserviceName/details", component: MicroserviceLogsAndEvents },

      // Kubernetes Services
      { path: "/kubernetes/services/create", component: CreateMicroservice },
      { path: "/kubernetes/services", component: MicroservicesList },
      { path: "/kubernetes/services/:microserviceName", component: MicroserviceDetails },
      {
        path: "/kubernetes/services/:microserviceName/edit",
        component: (props: RouteComponentProps<any, StaticContext, any>) => <CreateMicroservice mode="edit" {...props} />
      },
      // Docker Registries
      { path: "/kubernetes/docker-registries", component: DockerRegistriesList },
      { path: "/kubernetes/docker-registries/add", component: AddDockerRegistry },
      {
        path: "/kubernetes/docker-registries/:registryName/edit",
        component: (props: RouteComponentProps<any, StaticContext, any>) => <AddDockerRegistry mode="edit" {...props} />
      },

      // Cost Optimization
      { path: "/tools/cost-explorer", component: CostExplorer },
      { path: "/tools/cost-explorer/reports/create", component: Reports },
      { path: "/tools/cost-explorer/reports/edit", component: Reports },
      { path: "/tools/schedules", component: CostOptimizationSchedules },
      { path: "/tools/schedules/create", component: CostOptimizationCreateSchedule },
      { path: "/tools/schedules/:scheduleId/edit", component: CostOptimizationCreateSchedule },
      { path: "/tools/advisor", component: AdvisorStatistics },

      // Settings / Account management
      { path: "/settings", component: Settings },

      // Deployment History
      { path: "/depolyment-history", component: DeploymentInsights }
    ];

  const notLoggedInRoutes = (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/register" exact component={Register} />
      <Route path="/appsumo" exact component={RegisterAppSumo} />
      <Route path="/register/aws" exact component={RegisterAWSMarketplace} />
      <Route path="/account/verify/:email/:code" exact component={VerifyAccount} />
      <Route path="/account/set-password/:email/:token" exact component={SetPassword} />
      <Route path="/account/reset/:email/:code" exact component={ResetPassword} />
      <Route path="/account/reset-confirmation/:email" exact component={ResetPasswordConfirmation} />
      <Route path="*" component={Login} />
    </Switch>
  );

  const loggedInRoutes = (
    <div className="d-flex justify-content-start">
      <Switch>
        {
          routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              render={(props: RouteComponentProps) => (
                <route.component {...props} />
              )}
            />
          ))
        }
        <Redirect to="/projects" />
      </Switch>
    </div>
  );

  return (
    <>
      {
        isLoggedIn ?
          <>
            <aside className="aside d-flex">
              <Sidebar />
              <MenuBar />
            </aside>
            {loggedInRoutes}
          </>
          : notLoggedInRoutes
      }
    </>
  );
};

export default App;