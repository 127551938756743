import React, { useState, useEffect, ChangeEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateReportRequestGranularityEnum,
  GetReportsResponseReports,
  UpdateReportRequestNewGranularityEnum,
} from "@microtica/ms-cloud-cost-optimizer-sdk";
import { getCloudCostOptimizerAPI } from "../../api";
import { fetchProjectUsers } from "../../actions";
import { GlobalState } from "../../reducers";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContent from "../../components/PageContent/PageContent";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import Button from "../../components/Button/Button";
import RadioButton from "../../components/RadioButton/RadioButton";
import { InputField } from "../../components/InputField/InputField";
import InputText from "../../components/InputText/InputText";

//import Types and utils
import { createEmailReportSchema } from "../../utils/validation";
import { User } from "../../types";

import useAwsAccountList from "../../hooks/AwsAccount";
import { useModal } from "react-modal-hook";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#16242c", light: "#16242c", contrastText: "#16242c" },
  },
});

const useStyles = makeStyles(() => ({
  custom: {
    backgroundColor: "#16242c",
    color: "#abbed0",
    borderRadius: "5px",
  },
  autocomplete: {
    color: "#abbed0",
    border: "0px !important",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#16242c",
      },
      "&:hover fieldset": {
        borderColor: "#16242c",
      },
    },
  },
}));

interface AwsAccountList{
  id: string;
  iamRole: string;
  projectId: string;
  accountName: string | null;
}

interface CreateEmailReportProps
  extends RouteComponentProps<
  { stageId: string },
  StaticContext,
  {
    report: GetReportsResponseReports;
  }
  > { }

const Reports = (props: CreateEmailReportProps) => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const { projectUsers } = useSelector((state: GlobalState) => state.project);
  const isProcessing = useSelector((state: GlobalState) => state.project.isProcessing);
  const { awsAccounts, awsRegions, isFetching, error } = useAwsAccountList();

  const [projectUsersEmail, setProjectUsersEmail] = useState<string[]>([]);
  const [granularity, setGranularity] = useState(CreateReportRequestGranularityEnum.WEEKLY);
  const [name, setName] = useState(currentProject.name);
  const [emails, setEmails] = useState<string[]>([]);
  const [awsAccountList, setAwsAccountList] = useState<AwsAccountList[]>(
    awsAccounts.filter(
      (account) =>
        props.location.state.report &&
        props.location.state.report.awsAccountIds.find(
          (awsId) => awsId === account.id
        )
    )
  );
  const [awsRegionList, setAwsRegionList] = useState<
    { id: string; value: string; name: string }[]
  >(
    awsRegions.filter(
      (region) =>
        props.location.state.report &&
        props.location.state.report.regions.find((r) => r === region.value)
    )
  );
  const [costAllocationTag, setCostAllocationTag] = useState("");

  const [isUpdate] = useState(
    props.match.path.split("/").reverse()[0] === "edit"
  );
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(true);
  const [pageTitle] = useState(isUpdate ? "Update Report" : "Create Report");


  const [showDeleteModal, hideDeleteModal] = useModal(
    () => (
      <ModalDanger
        title={`Are you sure you want to delete "${name}" report?`}
        description="Do you really want to delete the report? This action cannot be undone."
        action="Delete"
        onCancel={hideDeleteModal}
        onConfirm={handleDeleteReport}
      />
    ),
    [name]
  );

  useEffect(() => {
    if (isUpdate && props.location.state.report) {
      setGranularity(
        props.location.state.report.granularity === "MONTHLY"
          ? CreateReportRequestGranularityEnum.MONTHLY
          : CreateReportRequestGranularityEnum.WEEKLY
      );
      setName(props.location.state.report.reportName);
      setCostAllocationTag(
        props.location.state.report.costAllocationTag
          ? props.location.state.report.costAllocationTag
          : ""
      );
      setEmails(props.location.state.report.emails);
    }
  }, []);

  useEffect(() => {
    if (isUpdate && props.location.state.report) {
      const awsAccountListMap = awsAccounts.filter((account) =>
        props.location.state.report.awsAccountIds.find(
          (awsId) => awsId === account.id
        )
      );
      const awsRegionListMap = awsRegions.filter((region) =>
        props.location.state.report.regions.find((r) => r === region.value)
      );

      setAwsAccountList(awsAccountListMap);
      setAwsRegionList(awsRegionListMap);
    }
  }, [isFetching]);

  useEffect(() => {
    dispatch(fetchProjectUsers(currentProject.id));
  }, [dispatch]);

  useEffect(() => {
    setProjectUsersEmail(projectUsers.map((user: User) => user.email!));
  }, [projectUsers]);

  async function handleSubmit() {
    setIsBusy(true);
    try {
      const awsAccountIds = awsAccountList!.map((awsAccount) => awsAccount.id);
      const regions = awsRegionList!.map((region) => region.value);

      if (isUpdate) {
        await getCloudCostOptimizerAPI().updateReport(
          props.location.state.report.id,
          currentProject.id,
          {
            newName: name,
            newGranularity:
              granularity === "MONTHLY"
                ? UpdateReportRequestNewGranularityEnum.MONTHLY
                : UpdateReportRequestNewGranularityEnum.WEEKLY,
            newCostAllocationTag: costAllocationTag,
          }
        );

        await getCloudCostOptimizerAPI().handleReportEntitiesState(
          props.location.state.report.id,
          currentProject.id,
          {
            emails,
            awsAccountIds,
            regions
          }
        )

        props.history.push("/tools/cost-explorer");
      } else {
        await getCloudCostOptimizerAPI().createReport(currentProject.id, {
          name,
          awsAccountIds: awsAccountIds,
          emails: emails!,
          granularity,
          regions,
          costAllocationTag,
        });
      }

      props.history.push("/tools/cost-explorer");
    } catch (err) {
      toast.error(err.response.data.message || "Something went wrong!");
    }
    setIsBusy(false);
  }

  async function handleDeleteReport() {
    setIsUpdating(false);
    setIsBusy(true);
    hideDeleteModal();
    try {
      await getCloudCostOptimizerAPI().deleteReport(
        props.location.state.report.id,
        currentProject.id
      );
      props.history.push("/tools/cost-explorer");
    } catch (e) {
      toast.error(e.response.data.message || "Something went wrong!");
    }
  }

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader titlePosition="center" title={pageTitle} />
      <div className="content__body">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name,
            awsAccountList,
            awsRegionList,
            emails,
            costAllocationTag,
          }}
          onSubmit={async (values) => {
            handleSubmit();
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={createEmailReportSchema}
          render={({ errors, setFieldValue }) => (
            <Form>
              <PageContent>
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-6 mb--10">
                    <Card class="card dark compact">
                      <div className="card_header_container">
                        <CardHeader
                          title="General"
                          text="Configure cost and Waste Advisor reports that will be sent directly to your email address on weekly or montly basis"
                        />
                      </div>
                      <div className="page-content__dropdown-container">
                        <Field
                          name="name"
                          type="text"
                          label="Report name"
                          placeholder="Enter report name here"
                          hasError={true}
                          component={InputField}
                          value={name}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setName(e.target.value)
                          }
                        />
                      </div>
                      <ThemeProvider theme={theme}>
                        <div className="page-content__dropdown-container mb--30 material-ui-input">
                          <Field
                            name="awsAccounts"
                            label="AWS Account"
                            value={awsAccountList}
                            hasError={true}
                            render={() => (
                              <div>
                                <label className="input__label">
                                  AWS accounts
                                  <span className="tooltip" data-tooltip="The AWS accounts that should be included in the report">
                                    <InfoIcon />
                                  </span>
                                </label>
                                <Autocomplete
                                  id="multiple-limit-tags"
                                  value={awsAccountList}
                                  color="primary"
                                  className={classes.autocomplete}
                                  multiple
                                  limitTags={4}
                                  options={awsAccounts as AwsAccountList[]}
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  onChange={(_event, value) =>
                                    setAwsAccountList(value)
                                  }
                                  getOptionLabel={(option) =>
                                    option.accountName || option.id
                                  }
                                  filterSelectedOptions
                                  disableCloseOnSelect
                                  loading={isProcessing || isFetching}
                                  renderInput={(params) => (
                                    <TextField
                                      color="primary"
                                      className={classes.custom}

                                      {...params}
                                      variant="outlined"
                                      placeholder="Select AWS accounts"
                                    />
                                  )}
                                />
                                {errors.awsAccountList ? (
                                  <div className="page-content__error">
                                    {errors.awsAccountList}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          />
                        </div>
                        <div className="page-content__dropdown-container mb--30 material-ui-input">
                          <Field
                            name="awsAccounts"
                            label="AWS Regions"
                            hasError={true}
                            render={() => (
                              <div>
                                <label className="input__label">
                                  AWS regions
                                  <span className="tooltip" data-tooltip="The AWS regions that should be included in the report">
                                    <InfoIcon />
                                  </span>
                                </label>
                                <Autocomplete
                                  id="awsRegions"
                                  value={awsRegionList}
                                  color="primary"
                                  className={classes.autocomplete}
                                  multiple
                                  limitTags={4}
                                  options={awsRegions}
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  onChange={(_event, value) =>
                                    setAwsRegionList(value)
                                  }
                                  getOptionLabel={(option) => option.name}
                                  filterSelectedOptions
                                  disableCloseOnSelect
                                  loading={isProcessing || isFetching}
                                  renderInput={(params) => (
                                    <TextField
                                      color="primary"
                                      className={classes.custom}
                                      {...params}
                                      variant="outlined"
                                      placeholder="Select AWS regions"
                                    />
                                  )}
                                />
                                {errors.awsRegionList ? (
                                  <div className="page-content__error">
                                    {errors.awsRegionList}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          />
                        </div>
                        <div className="page-content__dropdown-container mb--30 material-ui-input">
                          <Field
                            name="emails"
                            label="Emails"
                            render={() => (
                              <div>
                                <label className="input__label">
                                  Notify your team
                                  <span className="tooltip" data-tooltip="Add the emails of the teammates that should receive this email report">
                                    <InfoIcon />
                                  </span>
                                </label>
                                <Autocomplete
                                  id="multiple-tags"
                                  value={emails}
                                  color="primary"
                                  className={classes.autocomplete}
                                  multiple
                                  limitTags={2}
                                  options={projectUsersEmail}
                                  getOptionSelected={(option, value) =>
                                    option === value
                                  }
                                  onChange={(_event, value) => (
                                    setEmails(
                                      value.map((v) => v.split(" ").join(""))
                                    ),
                                    setFieldValue("emails", value)
                                  )}
                                  getOptionLabel={(option) => option}
                                  filterSelectedOptions
                                  disableCloseOnSelect
                                  freeSolo
                                  loading={isProcessing}
                                  renderInput={(params) => (
                                    <TextField
                                      color="primary"
                                      className={classes.custom}
                                      {...params}
                                      variant="outlined"
                                      placeholder="Enter Emails"
                                    />
                                  )}
                                />
                                {errors.emails ? (
                                  <div className="page-content__error">
                                    {errors.emails}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          />
                        </div>
                        <div className="page-content__dropdown-container mb--30">
                          <Field
                            name="costAllocationTag"
                            type="text"
                            label="Cost Allocation Tag"
                            info="If specified, the report will contain the cost and Waste Advisor reports for resources that have assigned this tag key. E.g. department, environment etc."
                            optional={true}
                            placeholder="Enter Cost Allocation Tag here"
                            hasError={true}
                            component={InputText}
                            value={costAllocationTag}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setCostAllocationTag(e.target.value)
                            }
                          />
                        </div>
                      </ThemeProvider>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <RadioButton
                          key="weekly"
                          onChange={() =>
                            setGranularity(
                              CreateReportRequestGranularityEnum.WEEKLY
                            )
                          }
                          checked={granularity === "WEEKLY"}
                          label={"Weekly"}
                          subLabel={"Receive cost and Waste Advisor reports every week"}
                          value={granularity}
                        />
                        <RadioButton
                          key="monthly"
                          onChange={() =>
                            setGranularity(
                              CreateReportRequestGranularityEnum.MONTHLY
                            )
                          }
                          checked={granularity === "MONTHLY"}
                          label={"Monthly"}
                          subLabel={"Receive cost and Waste Advisor reports every month"}
                          value={granularity}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
              </PageContent>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  type="submit"
                  className="btn btn--md btn--lightBlue btn--create"
                  disabled={!isUpdating && isBusy}
                  isBusy={isUpdating && isBusy}
                  busyText={isUpdate ? "Updating..." : "Creating..."}
                >
                  {isUpdate ? "Update Report" : "Create Report"}
                </Button>

                {isUpdate && (
                  <Button
                    className="btn btn--md btn--danger btn--create ml--50"
                    isBusy={!isUpdating && isBusy}
                    disabled={isUpdating && isBusy}
                    busyText="Deleting..."
                    onClick={() => showDeleteModal()}
                  >
                    Delete Report
                  </Button>
                )}
              </div>
            </Form>
          )}
        />
      </div>
    </main>
  );
};

export default Reports;
