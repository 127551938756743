import * as constants from "../../../constants";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";

export interface DeleteAzureAccountStart {
    type: constants.DELETE_AZURE_ACCOUNT_START;
}
export interface DeleteAzureAccountSuccess {
    type: constants.DELETE_AZURE_ACCOUNT_SUCCESS;
    tenantId: string;
    subscriptionId: string;
}
export interface DeleteAzureAccountError {
    type: constants.DELETE_AZURE_ACCOUNT_FAIL;
    error: ErrorInterface;
}

export type DeleteAzureAccount =
    | DeleteAzureAccountStart
    | DeleteAzureAccountSuccess
    | DeleteAzureAccountError

export function deleteAzureAccountStart(): DeleteAzureAccount {
    return {
        type: constants.DELETE_AZURE_ACCOUNT_START
    };
}
export function deleteAzureAccountError(error: ErrorInterface): DeleteAzureAccount {
    return {
        type: constants.DELETE_AZURE_ACCOUNT_FAIL,
        error
    };
}

export function deleteAzureAccountSuccess(
    tenantId: string,
    subscriptionId: string
): DeleteAzureAccount {
    return {
        type: constants.DELETE_AZURE_ACCOUNT_SUCCESS,
        tenantId,
        subscriptionId
    };
}

export function deleteAzureAccount(
    projectId: string,
    tenantId: string,
    subscriptionId: string
): ThunkAction<Promise<void>, {}, {}, DeleteAzureAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteAzureAccount>) => {
        dispatch(deleteAzureAccountStart());
        try {
            await getAwsAPI().deleteAzureAccount(tenantId, subscriptionId, projectId);
            dispatch(deleteAzureAccountSuccess(tenantId, subscriptionId));
        } catch (error) {
            dispatch(deleteAzureAccountError(error.response.data));
        }
    };
}
