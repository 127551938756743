import React, { useEffect, useState } from "react";

import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    placeholder: string;
    type: string;
    value: string;
    label?: string;
    optional?: boolean;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void
    inputSize?: "short" | "medium" | "long";
    icon?: string;
    info?: string;
}

const InputText: React.StatelessComponent<InputProps> = props => {
    const [showToggle, setShowToggle] = useState(props.type === "password");
    const [passwordVisible, setPasswordVisible] = useState(false);

    const {optional, ...inputProps} = props;

    function togglePasswordVisibility() {
        setPasswordVisible(!passwordVisible);
    }
    useEffect(() => {
        setShowToggle(props.type === "password");
    }, [props])
    
    return (
        <div className="input" style={props.disabled ? { opacity: 0.5 } : {}} tabIndex={-1}>
            {
                <label className="input__label">
                    {props.label}
                    {props.info ? <span className="tooltip" data-tooltip={props.info}><InfoIcon /></span> : null}
                </label>
            }
            {
                optional ?
                    <span style={{ float: "right", color: "#a5b8ca" }}>(optional)</span> :
                    null
            }
            <input
                className={`input__field input__field--${props.icon}`}
                required
                {...inputProps}
                type={passwordVisible ? "text" : props.type}
                style={{
                    paddingRight: showToggle ? "65px" : ""
                }}
            />
            {
                showToggle ?
                    <div className={`input__toggle ${props.label ? "input__toggle__label" : ""}`}
                        onClick={togglePasswordVisibility}
                    >
                        {passwordVisible ? "Hide" : "Show"}
                    </div> :
                    null
            }
        </div>
    );
};

export default InputText;

