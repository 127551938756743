import * as constants from "../../constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  LoginUserBasicResponse,
  LoginUserResponse,
  LoginUserChallengeResponse,
  LoginUserBasicResponseProfile
} from "@microtica/ms-usermanagement-sdk";
import { ErrorInterface } from "../../types/index";
import { trackUserLogin } from "../../tracking/authentication";

export interface LoginStart {
  type: constants.LOGIN_START;
}
export interface LoginSuccess {
  type: constants.LOGIN_SUCCESS;
  data: LoginUserBasicResponse;
}
export interface LoginError {
  type: constants.LOGIN_FAIL;
  error: ErrorInterface;
}
export interface SetLoggedIn {
  type: constants.SET_LOGGED_IN
}

export interface Logout {
  type: constants.LOGOUT;
}

export type Login = LoginStart | LoginSuccess | LoginError | Logout | SetLoggedIn;

function isChallenge(
  parameter: LoginUserResponse
): parameter is LoginUserChallengeResponse {
  return (parameter as any).challengeName;
}

export function loginStart(): Login {
  return {
    type: constants.LOGIN_START
  };
}
export function loginError(error: ErrorInterface): Login {
  return {
    type: constants.LOGIN_FAIL,
    error
  };
}
export function loginSuccess(data: any): Login {
  return {
    type: constants.LOGIN_SUCCESS,
    data
  };
}

export function logout(): Login {
  localStorage.removeItem('idToken');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('profile');

  return { type: constants.LOGOUT };
}

export function setIsLoggedIn(): Login {
  return {
    type: constants.SET_LOGGED_IN
  }
}

export function login(
  data: LoginUserResponse
): ThunkAction<Promise<void>, {}, {}, Login> {
  return async (dispatch: ThunkDispatch<{}, {}, Login>) => {
    if (!isChallenge(data)) {
      const { idToken, profile } = data;
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("profile", JSON.stringify(profile));
      const expirationDate = new Date(
        new Date().getTime() + data.expiresIn * 1000
      );
      localStorage.setItem("expirationDate", expirationDate.toString());
      localStorage.setItem("refreshToken", data.refreshToken);
      trackUserLogin(
        data.profile.email,
        (data.profile as LoginUserBasicResponseProfile & { status?: string }).status ? "github" : "email"
      );
      dispatch(loginSuccess(data));
    }
  };
}


export const authCheckState = () => {
  return (dispatch: ThunkDispatch<{}, {}, Login>) => {
    const token = localStorage.getItem('idToken');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate')!);
      const profile = localStorage.getItem('profile');
      if (expirationDate > new Date()) {
        const data = { token, profile };
        dispatch(loginSuccess(data));
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
      } else {
        dispatch(logout());
      }
    }
  }
}

export const checkAuthTimeout = (expirationTime: number) => {
  return (dispatch: ThunkDispatch<{}, {}, Login>) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  }
}