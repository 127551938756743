import React, { useState, useEffect } from "react";
import { useModal } from 'react-modal-hook';
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from '../../reducers';
import { toast } from "react-toastify";


// Import components
import ListView from "../../components/ListView/ListView";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import ModalAction from "../../components/ModalAction/ModalAction";
import SearchBar from "../../components/SearchBar/SearchBar";
import Button from "../../components/Button/Button";
import { ListItemProps } from "../../components//ListItem/ListItem";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import GitAuth from "../../components/GitAccount/GitAuth";

// Import icons
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as GithubLogo } from "../../static/github-logo.svg";
import { ReactComponent as BitbucketLogo } from "../../static/bitbucket-logo.svg";
import { ReactComponent as GitLabLogo } from "../../static/gitlab-logo.svg";

//import hook
import useGitAccountList from "../../hooks/GitAccount";

import { GitAccount } from "@microtica/ms-ap-sdk";
import * as actions from "../../actions";
import { handleConnectBitbucket, handleConnectGitHub, handleConnectGitLab } from "../../utils/connect-git";
import { RouteComponentProps } from "react-router";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";
import Animation from "../../components/Animations/Animations";

const GitAccounts = (props: RouteComponentProps) => {

  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search);
  const code = params.get("code") || "";
  const type = params.get("type");

  // Dropdown menu action items
  const itemActions = [
    {
      title: "Remove",
      icon: <RemoveIcon />,
      onClick: handleGitAccountRemove
    }
  ]

  const [gitAccountsList, setGitAccountsList] = useState<ListItemProps[]>([]);
  const { gitAccounts, accountsError } = useGitAccountList();
  const [selectedGitAccount, setSelectedGitAccount] = useState<GitAccount>();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [filterGitAccounts, setFilterGitAccounts] = useState("");

  // Check if request to server is still processing - to be able to display real data or skeleton placeholder
  const isProcessing = useSelector((state: GlobalState) => state.project.isProcessing);

  useEffect(() => {
    setGitAccountsList(gitAccounts
      .map((acc: any) => ({
        itemId: acc.gitAccountId,
        title: acc.accountName,
        description: acc.type
      }))
      .filter(item => {
        if (filterGitAccounts !== "") {
          return item.title
            .toLocaleLowerCase()
            .includes(
              filterGitAccounts.toLocaleLowerCase()
            );
        } else {
          return true;
        }
      }));
  }, [gitAccounts, filterGitAccounts]);

  useEffect(() => {
    if (!params.get("tab")) {
      props.history.replace(`/settings?tab=git`);
    }
    if (accountsError.code) {
      toast.error(accountsError.message);
    }
  }, [accountsError])

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedGitAccount!.accountName}" account?`}
      description="Do you really want to delete this Git Account. This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleRemoveConfirmation}
    />
  ), [selectedGitAccount]);

  async function handleGitAccountRemove(gitAccountId: string) {
    setSelectedGitAccount(gitAccounts.find(acc => acc.gitAccountId === gitAccountId));
    showConfirmationModal();
  }

  async function handleRemoveConfirmation() {
    dispatch(actions.deleteGitAccount(currentProject.id, selectedGitAccount!.gitAccountId));
    hideConfirmationModal();
  }

  // Git accounts modal (Github / Bitbucket / GitLab)
  const [showGitModal, hideGitModal] = useModal(() => (
    <ModalAction name="Select Account Type" class="modal modal--git" onClose={hideGitModal}>
      <div className="row no-gutters">

        <div className="col-12 col-xl-4">
          <div className="d-flex flex-column align-items-center separator">
            <GithubLogo />
            <h2>
              <strong>Github</strong>
            </h2>
            <Button className="btn btn--lg btn--lightBlue" onClick={handleConnectGitHub}>Connect Github Account</Button>
          </div>
        </div>

        <div className="col-12 col-xl-4">
          <div className="d-flex flex-column align-items-center separator">
            <BitbucketLogo />
            <h2>
              <strong>Bitbucket</strong>
            </h2>
            <Button className="btn btn--lg btn--lightBlue" onClick={handleConnectBitbucket}>Connect Bitbucket Account</Button>
          </div>
        </div>

        <div className="col-12 col-xl-4">
          <div className="d-flex flex-column align-items-center">
            <GitLabLogo />
            <h2>
              <strong>GitLab</strong>
            </h2>
            <Button className="btn btn--lg btn--lightBlue" onClick={handleConnectGitLab}>Connect GitLab Account</Button>
          </div>
        </div>

      </div>
    </ModalAction>
  ))

  return (
    <React.Fragment>
      {code && (type == "bitbucket" || type == "github" || type == "gitlab") ?
        <GitAuth code={code} type={type} />
        : null
      }
      <div className="searchbar-wrapper d-flex align-items-center mt--5 mb--5 pr--20">
        <SearchBar
          placeholder="Search accounts..."
          value={filterGitAccounts}
          onChange={e => setFilterGitAccounts(e.target.value)}
          modifierClass="pr--20"
        />
        <Button
          className="btn btn--lg btn--lightBlue"
          onClick={() => showGitModal()}>Connect Account</Button>
      </div>

      <CustomScrollbars maxHeight="100%">
        {!isProcessing ?
          gitAccountsList && gitAccountsList.length > 0 ?
            <ListView
              class="list--height list--settings pr--20"
              listType="icons"
              itemActions={itemActions}
              items={gitAccountsList}
            />
            : <Animation show={true} type="fade" itemIndex={1}>
              <h5 className="p--20 txt--grey txt--center width--full">No Git accounts available.</h5>
            </Animation>
          :
          [...Array(3)].map((_value, index) => (<ListItemSkeleton key={index} />))
        }
      </CustomScrollbars>

    </React.Fragment >
  )
}

export default GitAccounts;