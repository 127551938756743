import * as React from "react";
export interface Props {
  users: { image: string; name: string }[];
  numberOfUsers?: number;
}

function MenuUsers(props: Props) {
  return (
    <div className="menu__users d-flex justify-content-start align-items-center">
      <div className="users">
        {props.users.map(user => (
          <div className="circle circle--user circle--md circle--grey" key={user.image}>
            <img
              className="circle__img circle--md"
              src={user.image}
              alt={user.name}
            />
          </div>
        ))}
      </div>
      
      {props.numberOfUsers ? 
        <div className="menu__online d-flex justify-content-center align-items-center">
          {props.numberOfUsers ? (
            <div className="circle circle--md circle--grey">
              <span className="circle__number">{props.numberOfUsers}</span>
            </div>
          ) : null}

          <div className="circle circle--add">
            <span>+</span>
          </div>
        </div> : null
      }
    </div>
  );
}

export default MenuUsers;
