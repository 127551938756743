import React, { useState, useEffect, ReactElement } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { useModal } from "react-modal-hook";
import { toast } from "react-toastify";
import { Component, ComponentCloudProviderEnum, ComponentInfrastructureAsCodeToolEnum, GetComponentsResponseAccessList } from "@microtica/ms-engine-sdk";
import { useDispatch, useSelector } from "react-redux";

import CardsView from "../../components/CardsView/CardsView";
import SearchBar from "../../components/SearchBar/SearchBar";
import Card from "../../components/Card/Card";
import TabMenuContainer, { Tab } from "../../components/TabMenuContainer/TabMenuContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import Button from "../../components/Button/Button";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";

import componentCategories from "../../utils/component-categories";
import * as actions from "../../actions";
import { GlobalState } from "../../reducers";
import useComponentList from "../../hooks/ComponentList";
import { getEngineAPI } from "../../api";

import { ReactComponent as RemoveIcon } from "../../static/delete-icon.svg";
import { ReactComponent as ComponentIcon } from "../../static/component.svg";
import { ReactComponent as GroupIcon } from "../../static/group.svg";
import MicroticaLogo from "../../static/microtica-color-logo.png";
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import ComponentScreen from "../../static/component-details.png";
import { ReactComponent as CloudformationLogo } from "../../static/cloudformation.svg";
import { ReactComponent as TerraformLogo } from "../../static/terraform.svg";
import { ReactComponent as AWSLogo } from "../../static/aws-logo.svg";
import { ReactComponent as AzureLogo } from "../../static/azure-logo.svg";
import { ReactComponent as GCPLogo } from "../../static/google-cloud-logo.svg";
import cloudTools from "../../utils/infrastructure-as-code-tools";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import { trackComponentCreateInit } from "../../tracking/component";


export interface ComponentsListProps extends RouteComponentProps { }

const ComponentsList = (props: ComponentsListProps) => {
  // Hooks
  const dispatch = useDispatch();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const projects = useSelector((state: GlobalState) => state.project.projects);
  const { components } = useSelector((state: GlobalState) => state.component);
  const [publicOnly, setPublicOnly] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<Component>();
  const { filter, setFilter, filteredItems, isProcessing, error } = useComponentList(publicOnly);
  const [selectedTab, setSelectedTab] = useState<Tab>(componentCategories[1]);
  const [cloudTool, setCloudTool] = useState<DropdownItem>({ id: "All", name: "All", value: "all" });

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedComponent!.name}" component?`}
      description="Do you really want to delete the component? This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleDeleteConfirmation}
    />
  ), [selectedComponent]);


  useEffect(() => {
    if (error.message && error.message !== "") {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    setPublicOnly(selectedTab.id === "2");
  }, [selectedTab]);

  useEffect(() => {
    setSelectedTab(
      !isProcessing && !components.filter(c => Boolean(c.isPublic) === false).length ?
        componentCategories[1] :
        componentCategories[0]
    );
  }, [components]);

  function handleComponentDetails(componentId: string) {
    props.history.push(`/components/${componentId}`)
  }

  async function handleDeleteConfirmation() {
    dispatch(actions.deleteComponent(currentProject.id, selectedComponent!.id));
    hideConfirmationModal();
  }

  function handleComponentUpdate(componentId: string) {
    props.history.push(`/components/${componentId}/edit`);
  }

  function handleComponentRemove(componentId: string) {
    const selectedComponent = components.find(c => c.id === componentId);
    setSelectedComponent(selectedComponent);
    showConfirmationModal();
  }

  const handleComponentCreate = async () => {
    try {
      await getEngineAPI().createComponent(currentProject.id);
    } catch (error) {
      trackComponentCreateInit();
      props.history.push("components/create");
    }
  }

  function mapToIcon(isPublic: boolean) {
    return isPublic ?
      <div className="card-icon">
        <img src={MicroticaLogo} alt="microtica logo" width="30" height="30" />
      </div> :
      <ComponentIcon className="card-icon" width="55" height="55" />
  }

  function mapFooter(infrastructureAsCodeTool: ComponentInfrastructureAsCodeToolEnum, cloudProvider: ComponentCloudProviderEnum, isPublic: boolean, accessList: GetComponentsResponseAccessList[],itemId: string) {
    const toolIcon = infrastructureAsCodeTool === ComponentInfrastructureAsCodeToolEnum.Cloudformation ?
      <CloudformationLogo width="35px" height="35px" className="circle circle--placeholder p--5 mr--5" /> : <TerraformLogo width="35px" height="35px" className="circle circle--placeholder p--5 mr--5" />;

    const providerIcon = cloudProvider === ComponentCloudProviderEnum.Aws ? <AWSLogo width="35px" height="35px" className="circle circle--placeholder p--5" /> :
      cloudProvider === ComponentCloudProviderEnum.Azurerm ? <AzureLogo width="35px" height="35px" className="circle circle--placeholder p--5" />
        : <GCPLogo width="35px" height="35px" className="circle circle--placeholder p--5" />;

    const shared = accessList.find(al => al.assigneeType === "shared" && al.assigneeId === currentProject.id);
    const parentComponent = accessList.find(al => al.assigneeType === "parent");
    return (<div className="components-footer d-flex justify-content-between">
      <div className="d-flex align-items-center">
        {isPublic ?
          <>
            <img src={MicroticaLogo} alt="microtica logo" width="30" height="30" />
            <div className="ml--10 txt--white">
              <p className="mb--0">Provided by</p>
              <p className="fw--bold txt--white mb--0">Microtica</p>
            </div>
          </>
          : shared && parentComponent ?
            <>
              <GroupIcon width="30" height="30" />
              <div className="ml--10 txt--white">
                <p className="mb--0">Provided by project</p>
                <p className="fw--bold txt--white mb--0">{projects.find(p => p.id === parentComponent.assigneeId)!.name}</p>
              </div>
            </> :
            <p className="mb--0">Provided by this project </p>}
      </div>
      <div className="d-flex" style={{ height: "35px" }}>
        {toolIcon}
        {providerIcon}
      </div>
    </div>) as ReactElement;
  }

  // Header item(s)
  const createComponentButton = <Button key="createComponent" className="btn btn--md btn--link btn--blue m--0" onClick={handleComponentCreate}>Create Component</Button>

  const itemActions = [{
    title: "Edit",
    icon: <UpdateIcon />,
    onClick: handleComponentUpdate
  }, {
    title: "Delete",
    type: "separator",
    icon: <RemoveIcon />,
    onClick: handleComponentRemove
  }];

  const EmptyBox = () => (
    <div className="page-centered page-centered--project">
      <img src={ComponentScreen} alt="component screen" />
      <div className="page-centered__title">Infrastructure Components</div>
      <p className="txt--sm">
        Build your custom cloud infrastructure in a standardized way. Once the component is created, it can be reused in multiple environments.
        <br />
        You can combine components in environments to build complex solutions and deploy them without having to worry about the underlining automation.
        <br />
        <br />
        To bring the component to life, add the component in an <a href="/environments">environment</a> and initiate deployment.&nbsp;
        <br />
        <br />
        <a href="https://microtica.com/docs/components"
          target="_blank"
          rel="noreferrer">
          Read more about Custom Components
        </a>
      </p>
      <Link className="btn btn--xl btn--green" to="components/create">Create Component</Link>
    </div >
  );

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader
        title="Components"
        items={[createComponentButton]}
      />
      <div className="content__body">
        <Card class="card card--vertical dark">
          <div className="card__header-wrapper card__header-wrapper--lists">
            <TabMenuContainer
              key={selectedTab.id}
              class="tab-menu--big"
              tabs={componentCategories}
              value={selectedTab}
              onClick={setSelectedTab}
            />
            {/* setPublicOnly(tab.id === "2") */}
          </div>
          {
            !isProcessing && selectedTab.id === "1" && !components.filter(c => Boolean(c.isPublic) === false).length ?
              <EmptyBox /> :
              <>
                <div className="searchbar-wrapper list-components d-flex flex-row align-items-center">
                  <SearchBar
                    placeholder="Search components by name, type, description, IaC tool or cloud provider"
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                  <div className="txt--white list-components">
                    <DropdownContainer
                      items={[{ id: "All", name: "All", value: "all" }, ...cloudTools]}
                      selectedItem={cloudTool}
                      onSelectItem={(item) => {
                        setCloudTool(item);
                      }}
                    />
                  </div>
                </div>
                <CustomScrollbars maxHeight={"100%"} minHeight="160px" resetClass="reset--top" customClass="components">
                  <CardsView
                    isLoaded={isProcessing}
                    items={cloudTool.id === "All" ?
                      filteredItems.map(item => ({
                        ...item,
                        footer: mapFooter(item.infrastructureAsCodeTool, item.cloudProvider, item.isPublic, item.accessList,item.itemId),
                        icon: mapToIcon(item.isPublic)
                      })) :
                      filteredItems.filter(item => item.infrastructureAsCodeTool === cloudTool.value).map(item => ({
                        ...item,
                        footer: mapFooter(item.infrastructureAsCodeTool, item.cloudProvider, item.isPublic, item.accessList,item.itemId),
                        icon: mapToIcon(item.isPublic)
                      }))
                    }
                    itemActions={publicOnly ? [] : itemActions}
                    onClick={handleComponentDetails}
                  />
                </CustomScrollbars>
              </>
          }
        </Card>
      </div>

    </main>
  )
};

export default ComponentsList;
