
import * as React from "react";

import Button from "../Button/Button";
import { ReactComponent as WarningIcon } from "../../static/warning-icon.svg";
import Animation from "../Animations/Animations";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";

export interface ModalInfoProps {
    action: string;
    title: string;
    description: string;
    onCancel: () => void;
    onConfirm: () => void;
}

function ModalInfo(props: ModalInfoProps) {

    return (
        <React.Fragment>
            <div className="page-overlay"></div>

            <Animation show={true} type="modal" modal itemIndex={1}>
                <div className="modal modal--action modal--delete">
                    <CustomScrollbars maxHeight="calc(100vh - 200px)">
                        <div className="modal--padding">
                            <div className="d-flex flex-column justify-content-between align-items-center">
                                <div className="circle circle--xl circle--white mb--40"><WarningIcon /></div>

                                <h3 className="modal__title">{props.title}</h3>
                                <p className="modal__text">{props.description}</p>

                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <Button
                                    className="btn btn--lg btn--dark"
                                    children="Cancel"
                                    onClick={props.onCancel}
                                />
                                <Button
                                    className="btn btn--lg btn--blue"
                                    children={props.action}
                                    onClick={props.onConfirm}
                                />
                            </div>
                        </div>
                    </CustomScrollbars>
                </div>
            </Animation>
        </React.Fragment>
    )
}

export default ModalInfo;