import { errorEmptyObject, GCPAccountState } from '../types/index';
import * as constants from '../constants';
import { FetchGcpAccounts, ConnectGcpAccount, DeleteGcpAccount, UpdateGcpAccount } from '../actions';

export const initialState: GCPAccountState = {
    gcpAccounts: [],
    isFetching: false,
    isFinished: false,
    error: errorEmptyObject,
    gcpAccountConnected: false
}
export default function reducer(state: GCPAccountState = initialState, action: FetchGcpAccounts  | ConnectGcpAccount | DeleteGcpAccount | UpdateGcpAccount): GCPAccountState {
    switch (action.type) {
        case constants.FETCH_GCP_ACCOUNTS_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.FETCH_GCP_ACCOUNTS_SUCCESS: return { ...state, gcpAccounts: action.gcpAccounts, isFetching: false, isFinished: true, error: errorEmptyObject };
        case constants.FETCH_GCP_ACCOUNTS_FAIL: return { ...state, isFetching: false, isFinished: true, error: action.error };

        case constants.CONNECT_GCP_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject, gcpAccountConnected: false };
        case constants.CONNECT_GCP_ACCOUNT_SUCCESS: {
            return {
                ...state,
                gcpAccounts: [
                    ...state.gcpAccounts,
                    action.gcpAccount
                ],
                isFetching: false,
                error: errorEmptyObject,
                gcpAccountConnected: true
            };
        }
        case constants.CONNECT_GCP_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false, gcpAccountConnected: false };
        case constants.CONNECT_GCP_ACCOUNT_CLEAR: return { ...state, error: errorEmptyObject, isFetching: false, gcpAccountConnected: false };

        case constants.UPDATE_GCP_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.UPDATE_GCP_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };
        case constants.UPDATE_GCP_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: errorEmptyObject,
                gcpAccounts: state.gcpAccounts.map(acc => {
                    if (acc.gcpProjectId !== action.gcpProjectId) {
                        return acc;
                    } else {
                        return {
                            ...acc,
                            targetServiceAccount: action.object.targetServiceAccount || acc.targetServiceAccount,
                            projectName: action.object.projectName || acc.projectName
                        };
                    }
                })
            };

        case constants.DELETE_GCP_ACCOUNT_START: return { ...state, isFetching: true, error: errorEmptyObject };
        case constants.DELETE_GCP_ACCOUNT_SUCCESS: return {
            ...state,
            gcpAccounts: state.gcpAccounts.filter(acc => acc.gcpProjectId !== action.gcpProjectId),
            isFetching: false,
            error: errorEmptyObject
        };
        case constants.DELETE_GCP_ACCOUNT_FAIL: return { ...state, error: action.error, isFetching: false };

        default: {
            return state;
        }
    }
}
