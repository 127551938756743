import React from "react";
import { UserNotificationsNotifications } from "@microtica/ms-notification-sdk";
import moment from "moment";
import { ReactComponent as ClockIcon } from "../../static/clock-icon.svg";
import Avatar from "../Avatar/Avatar";

export interface NotificationProps {
    onClick: (notificationId: string) => void;
    notification: UserNotificationsNotifications;
    projectName: string;
}

const Notification = (props: NotificationProps) => {

    return (
        <div
            className={`notifications__card ${props.notification.isRead ? '' : 'notifications__card--unread'}`}
            onClick={() => props.onClick(props.notification.id)}
        >
            <div className="notifications__image circle circle--lg circle--success">
                <Avatar
                    key={props.notification.id}
                    name={props.notification.author}
                    size="40" />
            </div>

            <div className="notifications__card-content">
                <h6>
                    <strong>{props.notification.author}</strong>
                </h6>

                <p className="notifications__text">{props.notification.message}</p>

                <div className="notifications__footer">
                    <p className="m--0 txt--uppercase">{props.projectName}</p>

                    <p className="d-flex align-items-center m--0"><ClockIcon /> {moment(props.notification.timestamp, "x").fromNow()}</p>
                </div>
            </div>
        </div>
    )
}

export default Notification;
