import * as constants from "../../../constants";
import {RepositoriesResponseRepositories} from "@microtica/ms-ap-sdk";
import { getContinuousIntegrationAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../../types";

export interface FetchGitReposStart {
  type: constants.FETCH_GIT_REPOS_START;
}

export interface FetchGitReposSuccess {
  type: constants.FETCH_GIT_REPOS_SUCCESS;
  gitRepositories: RepositoriesResponseRepositories[];
}

export interface FetchGitReposFail {
  type: constants.FETCH_GIT_REPOS_FAIL;
  error: ErrorInterface;
}

export type FetchGitRepos =
  | FetchGitReposStart
  | FetchGitReposSuccess
  | FetchGitReposFail;


export function fetchGitReposStart(): FetchGitRepos {
  return {
    type: constants.FETCH_GIT_REPOS_START
  };
}

export function fetchGitReposSuccess(
    gitRepositories: RepositoriesResponseRepositories[] = []
): FetchGitReposSuccess {
  return {
    type: constants.FETCH_GIT_REPOS_SUCCESS,
    gitRepositories
  };
}

export function fetchGitReposFail(error: ErrorInterface = errorEmptyObject): FetchGitReposFail {
  return {
    type: constants.FETCH_GIT_REPOS_FAIL,
    error: error
  };
}

export function fetchGitRepos(
  projectId: string, gitAccountId: string
): ThunkAction<Promise<void>, {}, {}, FetchGitRepos> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchGitRepos>) => {
    dispatch(fetchGitReposStart());
    try {
      const res = await getContinuousIntegrationAPI().listGitAccountRepositories(projectId, gitAccountId);
      dispatch(fetchGitReposSuccess(res.data.repositories));
    } catch (error) {
      dispatch(fetchGitReposFail(error.response.data));
    }
  };
}
