import React, { ReactElement } from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";

interface MetricsWidgetProps {
    title: React.ReactElement | string;
    badgeColor: "success" | "warning" | "fail";
    badgeTitle: string;
    value: React.ReactElement | string;
    valueTitle: React.ReactElement | string;
    footerTitle: React.ReactElement | string;
    footerTitleClassName: "success" | "fail";
    footerValue: React.ReactElement | string;
    data?: object[]
    dataKey?: string;
    placeholder?: ReactElement;
}

const MetricsWidget = (props: MetricsWidgetProps) => {
    const placeholder = (
        <div className="widget widget-metrics">
            <div className="d-flex widget-header">
                <div className="widget-title">{props.title}</div>
                <span className={`align-self-start badge badge--${props.badgeColor}`}>{props.badgeTitle}</span>
            </div>
            <div className="placeholder">
                {props.placeholder}
            </div>
        </div>
    );

    return (
        props.placeholder ? placeholder :
            <div className="widget widget-metrics">
                <div className="d-flex widget-header">
                    <div className="widget-title">{props.title}</div>
                    <span className={`align-self-start badge badge--${props.badgeColor}`}>{props.badgeTitle}</span>
                </div>
                <div className="widget-content txt--center">
                    {
                        props.data ?
                            <div className="row">
                                <div className="col-6">
                                    <ResponsiveContainer>
                                        <LineChart width={300} height={100} data={props.data}>
                                            <Line type="monotone" dataKey={props.dataKey || ""} stroke="#2b7dc5" strokeWidth={2} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="col-6">
                                    <h2>{props.value}</h2>
                                    <h6>{props.valueTitle}</h6>
                                </div>
                            </div> :
                            <div>
                                <h2>{props.value}</h2>
                                <h6>{props.valueTitle}</h6>
                            </div>
                    }
                </div>
                <div className="d-flex widget-footer">
                    <span className="footer-title">{props.footerTitle}</span>
                    <span className={`metric-trend trend--${props.footerTitleClassName}`}>{props.footerValue}</span>
                </div>
            </div>
    )
};
export default MetricsWidget;