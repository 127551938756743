import * as constants from "../../constants";
import { getProjectAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types";
import { toast } from "react-toastify";
import { trackRemoveProject } from "../../tracking/user-settings";

export interface DeleteProjectStart {
    type: constants.DELETE_PROJECT_START;
    projectId: string;
}
export interface DeleteProjectSuccess {
    type: constants.DELETE_PROJECT_SUCCESS;
    projectId: string;
}
export interface DeleteProjectFail {
    type: constants.DELETE_PROJECT_FAIL;
    error: ErrorInterface;
}

export type DeleteProject =
    | DeleteProjectStart
    | DeleteProjectSuccess
    | DeleteProjectFail

export function deleteProjectStart(projectId: string): DeleteProjectStart {
    return {
        type: constants.DELETE_PROJECT_START,
        projectId
    };
}

export function deleteProjectFail(error: ErrorInterface = errorEmptyObject): DeleteProjectFail {
    return {
        type: constants.DELETE_PROJECT_FAIL,
        error
    };
}

export function deleteProjectSuccess(
    projectId: string
): DeleteProjectSuccess {
    return {
        type: constants.DELETE_PROJECT_SUCCESS,
        projectId
    };
}

export function deleteProject(
    projectId: string,
    projectName: string
): ThunkAction<Promise<void>, {}, {}, DeleteProject> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteProject>) => {
        dispatch(deleteProjectStart(projectId));
        try {
            await getProjectAPI().deleteProject(projectId, window.location.origin);
            toast.success("Successfully deleted project")
            trackRemoveProject(projectName, projectId)
            dispatch(deleteProjectSuccess(projectId));
        } catch (error) {
            if (error.response.data.deployedStages) {
                // temporary implementation
                const stages = error.response.data.deployedStages.map((stage: any) => (stage.name));
                toast.error(error.response.data.message + ". Deployed stages: " + stages.join(","));
            }
            dispatch(deleteProjectFail(error.response.data));
        }
    };
}
