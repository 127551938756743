import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";

export default function usePipelineList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { pipelines, isFetching, error } = useSelector((state: GlobalState) => state.pipeline);
    const [polling, setPolling] = useState(false);
    const [initialFetching, setInitialFetching] = useState(true);

    // Get a list of stages from the backend
    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(actions.fetchPipelines(currentProject.id));
            setPolling(true);
            setInitialFetching(false);
        }, 10000);
        dispatch(actions.fetchPipelines(currentProject.id));
        return () => { clearInterval(timer) };
    }, [dispatch]);

    return {
        pipelines,
        isFetching: initialFetching ? isFetching : false,
        error,
        polling,
        disabledPipelineManager: error.code === 402
    }
}

