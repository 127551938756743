import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../reducers";
import { fetchUserNotifications } from "../actions/notifications/notifications-list";

export default function useNotifications(initialFilter: string = "all") {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(initialFilter);
    const { notifications, isProcessing, error } = useSelector((state: GlobalState) => state.notification);

    // Get a list of notificaitons from the backend
    useEffect(() => {
        setInterval(() => {
            dispatch(fetchUserNotifications());
        }, 45000)
        dispatch(fetchUserNotifications());
    }, [dispatch]);

    const filteredItems = notifications
        .filter(notification => {
            if (filter === "unread") {
                return notification.isRead === false
            }
            return true;
        });

    const unreadedNotifications = notifications
        .filter(notification => {
            return notification.isRead === false
        }).length;

    return {
        notifications: filteredItems,
        unreadedNotifications,
        filter,
        setFilter,
        isProcessing,
        error
    };
}