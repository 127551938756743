export function cronExpressionTimeZoneMoreThanZero(zoneOffset: number, hour: number, selectedDays: string[]){
    if((hour - zoneOffset) < 0 ){
        return `cron(0 ${24 + (hour - zoneOffset)} ? * ${getDaysBefore(selectedDays).join(",")} *)`;
    }else{
        return `cron(0 ${hour - zoneOffset} ? * ${selectedDays.join(",")} *)`;
    }
}

// it generates Cron expression when Timezone is less then zero
export function cronExpressionTimeZoneLessThanZero(zoneOffset: number, hour: number, selectedDays: string[]){
    if((hour - zoneOffset) > 23 ){
        return `cron(0 ${0 + Math.abs(24 - (hour - zoneOffset))} ? * ${getDaysAfter(selectedDays).join(",")} *)`;
    }else{
        return `cron(0 ${hour - zoneOffset} ? * ${selectedDays.join(",")} *)`
    }
}

function getDaysBefore(weekDays: string[]){
    let days:string[] = [];
    weekDays.forEach(day => 
    {
        const dayBefore = (() => 
        {
            switch(day) {
            case "Monday":
                return "Sunday";
            case "Tuesday":
                return "Monday";
            case "Wednesday":
                return "Tuesday";
            case "Thursday":
                return "Wednesday";
            case "Friday":
                return "Thursday";
            case "Saturday":
                return "Friday";
            case "Sunday":
                return "Saturday";
        }})
        days.push(String(dayBefore()));
    })
    return days;
}

function getDaysAfter(weekDays: string[]){
    let days:string[] = [];
    weekDays.forEach(day => 
    {
        const dayBefore = (() => 
        {
            switch(day) {
            case "Monday":
                return "Tuesday";
            case "Tuesday":
                return "Wednesday";
            case "Wednesday":
                return "Thursday";
            case "Thursday":
                return "Friday";
            case "Friday":
                return "Saturday";
            case "Saturday":
                return "Sunday";
            case "Sunday":
                return "Monday";
        }})
        days.push(String(dayBefore()));
    })
    return days;
}

export function calculateHourWithoutTimeZone(hour: string, timeZone: string, days: string[]){
    const timeZoneOffset = parseInt(timeZone.split("UTC")[1] || "0")

    if((parseInt(hour) + timeZoneOffset) < 0 ){ 
        return {hour: String(24 + ((parseInt(hour) + timeZoneOffset))), days: getDaysBefore(days)}
    }else if((parseInt(hour) + timeZoneOffset) > 23 ){
        return {hour: String(Math.abs(24 - (parseInt(hour) + timeZoneOffset))), days: getDaysAfter(days)}
    }else{
        return {hour: String((parseInt(hour) + timeZoneOffset)), days: days}
    }
}