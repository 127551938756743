import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import { SignUpUserRequest } from "@microtica/ms-usermanagement-sdk";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import appSumoLogo from "../../static/appsumo-logo.png";
import { ReactComponent as MicroticaLogoBig } from "../../static/microtica-big-logo.svg";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { registerSchema } from "../../utils/validation";
import { InputField } from "../../components/InputField/InputField";
import { getProjectAPI, getUserManagementAPI } from "../../api";
import { toast } from "react-toastify";
import { ReactComponent as NewMail } from "../../static/mail-image.svg";


interface RegisterProps extends RouteComponentProps {
}

const RegisterAppSumo = (props: RegisterProps) => {

  const [step, setStep] = useState(1);
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get("email") || "";

  // Text to be displayed as activation email
  const [activationEmail, setActivationEmail] = useState("");

  async function handleRegister(
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    appSumoCode: string) {
    const object: SignUpUserRequest = {
      username,
      password,
      firstName,
      lastName
    }

    try {
      await getProjectAPI().applyPromoCode(appSumoCode, username);
      await getUserManagementAPI().signUpUser(object);
      setActivationEmail(username);
      // Update text that will be displayed for activation email
      setStep(2);
    } catch (err) {
      await getProjectAPI().releasePromoCode(username);
      toast.error(err.response.data.message);
    }
  }

  const step1 = (
    <div className="container--md container--padding d-flex flex-column align-items-center">
      <div className="mb--20 txt--center logos-container">
        <img className="microtica-text-logo mb--20" src={microticaTextLogo} alt="microtica" />
        <span className="mb--20 pr--10" style={{ fontSize: "30px", color: "white" }}>&</span>
        <img className="appSumo-text-logo mb--20" src={appSumoLogo} alt="sumo" />
      </div>

      <div className="box box--padding-lg mb--40">
        <h5 className="mb--35 txt--center font--roboto">
          <strong>Hey Sumo-lings! <br /><br />Please share your name, email, password and AppSumo Code below to create your account and get 1 year access to Microtica's Pro plan.</strong>
        </h5>
        <Formik
          initialValues={{ username: email, password: "", passwordConfirm: "", firstName: "", lastName: "", appSumoCode: "" }}
          onSubmit={(values) => {
            handleRegister(values.username, values.password, values.firstName, values.lastName, values.appSumoCode);
          }}
          validationSchema={registerSchema}
          render={() => (
            <>
              <Form>
                <Field
                  name="firstName"
                  type="text"
                  icon="name"
                  hasError={true}
                  required
                  placeholder="First name"
                  component={InputField}
                />
                <Field
                  name="lastName"
                  type="text"
                  icon="name"
                  hasError={true}
                  required
                  placeholder="Last name"
                  component={InputField}
                />
                <Field
                  name="username"
                  type="text"
                  icon="email"
                  hasError={true}
                  required
                  placeholder="Email"
                  component={InputField}
                />
                <Field
                  name="password"
                  type="password"
                  hasError={true}
                  required
                  placeholder="Password"
                  component={InputField}
                />
                <Field
                  name="appSumoCode"
                  type="text"
                  hasError={true}
                  required
                  placeholder="AppSumo Code"
                  component={InputField}
                />
                <Button
                  type="submit"
                  className="btn btn--xl btn--blue"
                  children="Sign Up"
                />
              </Form>
            </>
          )}
        />
      </div>
    </div>
  )

  const step2 = (
    <div className="container--md container--padding d-flex flex-column align-items-center">
      <img className="microtica-text-logo mb--30" src={microticaTextLogo} alt="microtica" />
      <NewMail className="mb--30" />
      <h1 className="mb--15"><strong>You've Got Mail!</strong></h1>
      <p className="txt--md txt--white">We sent a confirmation link to:</p>
      <a className="txt--lg txt--white">
        <strong>{activationEmail}</strong>
      </a>
    </div>
  );

  return (
    <div className="container--full welcome">
      {step === 1 ? step1 : step2}
      <MicroticaLogoBig className="microtica-big-logo" />
      <Footer />
    </div>
  );
};

export default RegisterAppSumo;
