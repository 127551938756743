import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
  LoginUserRequest,
  LoginUserResponse,
  LoginUserChallengeResponse,
  ForgotUserPasswordRequest,
} from "@microtica/ms-usermanagement-sdk";
import * as actions from "../../actions";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import { InputField } from "../../components/InputField/InputField";

import { GlobalState } from "../../reducers";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import { ReactComponent as MicroticaLogoBig } from "../../static/microtica-big-logo.svg";
import { loginSchema, resetPasswordEmailSchema } from "../../utils/validation";
import { getProjectAPI, getUserManagementAPI } from "../../api";
import { trackUserResetPassword } from "../../tracking/authentication";

import { ReactComponent as GithubLogo } from "../../static/github-logo.svg";

import { handleGitHubSignIn } from "../../utils/oidc";
import { store } from "../..";
import { setCurrentProject } from "../../actions";
import { Project } from "@microtica/ms-project-sdk";

interface LoginProps
  extends RouteComponentProps<{
    code: string;
  }> { }

const Login = (props: LoginProps) => {
  const dispatch = useDispatch();
  const { isLoggedIn, isLoading } = useSelector(
    (state: GlobalState) => state.user
  );
  const [currentPage, setCurrentPage] = useState("login");

  const params = new URLSearchParams(props.location.search);
  const code = params.get("code");
  const error = params.get("error_description");

  useEffect(() => {
    if (isLoggedIn) {
      props.history.push("/projects");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      toast.error(error.split(";")[0]);
    } else if (code) {
      loginWithGitHub();
    }
  }, [code, error]);

  async function loginWithGitHub() {
    try {
      const { data } = (await getUserManagementAPI().loginUserWithGitHub({
        code: code!,
      })) as { data: LoginUserResponse };

      dispatch(actions.login(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleLogin(username: string, password: string) {
    const project: Project = JSON.parse(
      localStorage.getItem("project") || "{}"
    );
    const pathname = window.location.pathname;

    if (Object.keys(project).length !== 0) {
      store.dispatch(setCurrentProject(project));
    }

    try {
      const { data } = (await getUserManagementAPI().loginUser({
        username,
        password,
      })) as { data: LoginUserResponse | LoginUserChallengeResponse };
      const { done, challengeName, sessionToken } = data as {
        done: boolean;
        challengeName?: string;
        sessionToken: string;
      };
      if (!done && challengeName === "NEW_PASSWORD_REQUIRED") {
        props.history.push(`/account/set-password/${username}/${sessionToken}`);
      } else {
        dispatch(actions.login(data));
      }

      done && props.history.push("/projects");
    } catch (err) {

      toast.error(err.response.data.message);
    }

    if (pathname !== "/" && pathname !== "/login" && pathname !== "/projects") {
      if (Object.keys(project).length === 0) {
        try {
          const { data: { projects } } = await getProjectAPI().listProjects();
          store.dispatch(setCurrentProject(projects[0]));
        } catch (err) {
          toast.error(err.response.data.message);
        }
      }
      props.history.push(pathname);
    }
  }

  async function handleResetPassword(username: string) {
    try {
      await getUserManagementAPI().forgotUserPassword({ username });
      trackUserResetPassword(username);
      props.history.push(`/account/reset-confirmation/${username}`);
    } catch (err) {
      toast.error(err.response.data.message.replace("_", " "));
    }
  }

  const loginPage = (
    <>
      <div className="container--md container--padding d-flex flex-column align-items-center">
        <div className="mb--20 txt--center">
          <img
            className="microtica-text-logo mb--20"
            src={microticaTextLogo}
            alt="microtica logo"
          />
        </div>

        <div className="box box--padding-lg mb--40">
          <React.Fragment>
            <h5 className="mb--35 txt--center font--roboto">
              <strong>Enter your details below to access your account</strong>
            </h5>

            <Formik
              initialValues={{ username: "", password: "" }}
              onSubmit={async (values: LoginUserRequest) => {
                await handleLogin(values.username, values.password);
              }}
              validationSchema={loginSchema}
              render={({ values }) => (
                <Form
                  onKeyPress={async (event) =>
                    event.key === "Enter" &&
                    (await handleLogin(values.username, values.password))
                  }
                >
                  <Field
                    name="username"
                    type="text"
                    hasError={true}
                    placeholder="Username"
                    required
                    component={InputField}
                  />
                  <Field
                    name="password"
                    type="password"
                    hasError={true}
                    required
                    placeholder="Password"
                    component={InputField}
                  />

                  <div className="user-remember">
                    <div style={{ display: "none" }}>
                      <Checkbox name="Remember me" />
                    </div>
                    <a className="m--0" onClick={() => setCurrentPage("reset")}>
                      Forgot your password?
                    </a>
                  </div>

                  <Button
                    type="submit"
                    className="btn btn--xl btn--blue"
                    disabled={isLoading}
                  >
                    Log In
                  </Button>
                </Form>
              )}
            />
            <br />
            <h6 className="mb--20 txt--center font--roboto">
              <strong>- or you can sign in using -</strong>
            </h6>
            <Button
              className="btn btn--xl btn--git-hub"
              onClick={handleGitHubSignIn}
            >
              <GithubLogo width="25px" height="25px" /> &nbsp; GitHub
            </Button>
          </React.Fragment>
        </div>

        <p className="txt--md txt--white font--roboto">
          Don't have an account?{" "}
          <Link to="/register" className="txt--sm txt--highlight font--roboto">
            Sign up
          </Link>
        </p>
      </div>
      <MicroticaLogoBig className="microtica-big-logo" />
      <Footer />
    </>
  );

  const resetPasswordPage = (
    <>
      <div className="container--md container--padding d-flex flex-column align-items-center">
        <div className="mb--20 txt--center">
          <img
            className="microtica-text-logo mb--20"
            src={microticaTextLogo}
            alt="microtica logo"
          />
        </div>

        <div className="box box--padding-lg mb--40">
          <React.Fragment>
            <h1 className="mb--20 txt--center font--roboto">
              <strong>Forgot your password?</strong>
            </h1>

            <p className="txt--center txt--md txt--white mb--30">
              We'll help you reset it and get back on track.
            </p>

            <Formik
              initialValues={{ username: "" }}
              onSubmit={async (values: ForgotUserPasswordRequest) => {
                await handleResetPassword(values.username);
              }}
              validationSchema={resetPasswordEmailSchema}
              render={() => (
                <Form>
                  <Field
                    name="username"
                    type="email"
                    icon="email"
                    required
                    placeholder="Email"
                    hasError={true}
                    component={InputField}
                    className="mb--10"
                  />
                  <Button type="submit" className="btn btn--xl btn--lightBlue">
                    Reset Password
                  </Button>
                </Form>
              )}
            />
          </React.Fragment>
        </div>
        <p
          className="back m--0 txt--sm txt--white font--roboto clickable"
          onClick={() => setCurrentPage("login")}
        >
          Back to Login
        </p>
      </div>
      <MicroticaLogoBig className="microtica-big-logo" />
      <Footer />
    </>
  );

  return (
    <div className="container--full welcome">
      {currentPage === "login" ? loginPage : resetPasswordPage}
    </div>
  );
};

export default Login;
