
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getElasticSearchAPI } from '../api';
import { GlobalState } from "../reducers";

export default function useEnvironmentsMetrics() {

    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [environmentsMetrics, setEnvironmentsMetrics] = useState<{ deploymentTime : number}>();
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const fetch = async () => {
            const { data: { response: environmentsMetrics } } = await getElasticSearchAPI().getDeploymentTime(currentProject.id);
            setEnvironmentsMetrics({
                deploymentTime: Math.ceil(environmentsMetrics.deploymentTime / 60000) });
            setIsLoaded(true);
        }
        fetch();
    }, [])

    return {
        isLoaded,
        environmentsMetrics
    }
}

