import * as constants from "../../constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

export interface UpdateStageStatusSuccess {
    type: constants.UPDATE_STAGE_STATUS_SUCCESS;
    stageId: string;
    status: string;
}

export type UpdateStageStatus =
    | UpdateStageStatusSuccess;

export function updateStageStatusSuccess(
    stageId: string,
    status: string
): UpdateStageStatusSuccess {
    return {
        type: constants.UPDATE_STAGE_STATUS_SUCCESS,
        stageId,
        status
    };
}

export function updateStageStatus(
    stageId: string,
    status: string
): ThunkAction<Promise<void>, {}, {}, UpdateStageStatus> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateStageStatus>) => {
        dispatch(updateStageStatusSuccess(stageId, status));
    };
}

