import React, { useState, useEffect } from "react";
import { useModal } from 'react-modal-hook';

// Import components
import ListView from "../../components/ListView/ListView";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import SearchBar from "../../components/SearchBar/SearchBar";
import Button from "../../components/Button/Button";
import ModalInput from "../../components/ModalInput/ModalInput";

// Import icons
import { ReactComponent as UpdateIcon } from "../../static/pen-icon.svg";
import { ReactComponent as EmailIcon } from "../../static/email-icon.svg";
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as InviteUser } from "../../static/invite-user.svg";
import profileImage from "../../static/scientist.svg";

import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../reducers";
import { AddUserRequest, UpdateUserPermissionsRequest, Permission } from "@microtica/ms-project-sdk";
import { ListItemProps } from "../../components/ListItem/ListItem";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import { updateUserPermissionsOnProject, removeUserFromProject, fetchProjectUsers } from "../../actions";
import { User } from "../../types";
import { getProjectAPI, getUserManagementAPI } from "../../api";
import { toast } from "react-toastify";
import { Form, Formik, Field } from "formik";
import { inviteUserSchema } from "../../utils/validation";
import { InputField } from "../../components/InputField/InputField";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";
import Animation from "../../components/Animations/Animations";

import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import { trackUserAdded, trackUserInviteResend } from "../../tracking/user-settings";

// Radio buttons static data
const permissions = [
  {
    id: "owner",
    name: "Owner",
    subTitle: "User can do EVERYTHING in the system."
  },
  {
    id: "admin",
    name: "Admin",
    subTitle: "User can create components, services, environments, saving schedule, trigger builds and deployments and manage users. User cannot update/delete project."
  },
  {
    id: "write",
    name: "Write",
    subTitle: "User can create components, services, environments, saving schedule, trigger builds and deployments. User cannot manage users in the project."
  },
  {
    id: "read",
    name: "Read",
    subTitle: "User can view everything in the system but will not be allowed to modify anything."
  }
];

const Management = () => {
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("");
  const [selectedPermission, setSelectedPermission] = useState<DropdownItem>(permissions[2]);
  const [user, setUser] = useState<User>();
  const [projectUsersList, setProjectUsersList] = useState<ListItemProps[]>([]);
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const { projectUsers } = useSelector((state: GlobalState) => state.project);
  const [filterAccounts, setFilterAccounts] = useState("");
  const [isBusy, setIsBusy] = useState<boolean>(false);
  // Check if request to server is still processing - to be able to display real data or skeleton placeholder
  const isProcessing = useSelector((state: GlobalState) => state.project.isProcessing);

  const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete ${user!.firstName} ${user!.lastName}?`}
      description="Do you really want to delete the aws account. This action cannot be undone."
      action="Delete"
      onCancel={hideConfirmationModal}
      onConfirm={handleRemoveUserFromProjectConfirmation}
    />), [user]);

  const [showUserModal, hideUserModal] = useModal(() => (
    <ModalInput
      name=""
      open
      class="modal modal--account"
      onClose={() => {
        hideUserModal();
        setUser(undefined);
        setIsBusy(false);
        setUserEmail("");
        setSelectedPermission(permissions[2]);
      }}
    >
      <Formik
        initialValues={{ email: userEmail, permission: selectedPermission.id }}
        onSubmit={values => handleAddOrUpdateUserInProject(values.email, values.permission)}
        validationSchema={inviteUserSchema}
        render={({ values, setFieldValue, errors }) => (
          <Form className="invite-user">
            <InviteUser className="d-block mb--20 m--auto" />
            <h3>
              {user ? <strong>Update User Permissions </strong> : <strong>Invite User by Email</strong>}
            </h3>
            {/* {user ? <p></p> : <p className="txt--white modal__text">Enter the details below to send project invitation</p>} */}
            <br />

            <Field
              name="email"
              label="Email"
              placeholder="User email"
              autoFocus
              hasError={true}
              disabled={user !== undefined}
              type="text"
              icon="email"
              component={InputField}
            />

            <div className="page-content__dropdown-container pl--0 pr--0">
              <Field
                name="permission"
                validateOnChange
                hasError={true}
                render={() => (
                  <div>
                    <DropdownContainer
                      label="Permission"
                      selectedItem={selectedPermission}
                      items={permissions}
                      placeholder="Select user permission"
                      onSelectItem={(item) => {
                        setFieldValue("permission", item.id);
                      }}
                    />
                    {errors && errors.permission ?
                      <div className="page-content__error">{errors.permission}</div>
                      : null}
                  </div>
                )}
              />
            </div>
            <Button
              className="btn btn--xl btn--lightBlue"
              type="submit"
              isBusy={isBusy}
              busyText={user ? "Updating.." : "Sending.."}
            >
              {user ? "Update permissions" : "Send invitation"}
            </Button>
          </Form>
        )} />
    </ModalInput>
  ), [userEmail, selectedPermission, isBusy]);

  useEffect(() => {
    setProjectUsersList(projectUsers.map((user: User) => ({
      itemId: user.userId,
      title: user.email!,
      description: user.permission,
      email: `${user.firstName} ${user.lastName}`,
      profileImage: profileImage,
      footer: user.status === "FORCE_CHANGE_PASSWORD" ? <div className="badge badge--queued">PENDING</div> : undefined
    }))
      .filter(item => {
        if (filterAccounts !== "") {
          return (item.title
            .toLocaleLowerCase()
            .includes(filterAccounts.toLocaleLowerCase())) ||
            (item.title!
              .toLocaleLowerCase()
              .includes(filterAccounts.toLocaleLowerCase()));
        } else {
          return true;
        }
      }));
  }, [projectUsers, filterAccounts])

  useEffect(() => {
    dispatch(fetchProjectUsers(currentProject.id));
  }, [dispatch])

  async function handleAddOrUpdateUserInProject(userEmail: string, permission: string) {
    setIsBusy(true);
    if (user) {
      const updatePermissions: UpdateUserPermissionsRequest = {
        permission: getPermission(permission)
      };
      dispatch(updateUserPermissionsOnProject(currentProject.id, user!.userId, updatePermissions));
    } else {
      const user: AddUserRequest = {
        email: userEmail,
        permission: getPermission(permission)
      }
      try {
        await getProjectAPI().addUser(currentProject.id, window.location.origin, user);
        toast.success("Successfully added user");
        trackUserAdded(userEmail)
        dispatch(fetchProjectUsers(currentProject.id))
      } catch (err) {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong!");
      }
    }
    setSelectedPermission(permissions[2]);
    setUserEmail("");
    setUser(undefined);
    setIsBusy(false);
    hideUserModal();
  }

  function getPermission(permission: string): Permission {
    if (permission === "read") {
      return Permission.Read;
    } else if (permission === "write") {
      return Permission.Write;
    }
    else if (permission === "admin") {
      return Permission.Admin
    } else {
      return Permission.Owner;
    }
  }

  function handleRemoveUserFromProject(userId: string) {
    setUser(projectUsers.find(user => user.userId === userId))
    showConfirmationModal();
  }

  function handleRemoveUserFromProjectConfirmation() {
    dispatch(removeUserFromProject(currentProject!.id, user!.userId, user!.email!));
    hideConfirmationModal();
  }

  function handleUpdateUserPermissionsOnPoject(userId: string) {
    const selectedUser = projectUsers.find(user => user.userId === userId);
    const selectedPermission = permissions.find(permission => permission.id === selectedUser!.permission);
    setUser(selectedUser);
    setUserEmail(selectedUser!.email || "");
    setSelectedPermission(selectedPermission!);
    showUserModal();
  }

  async function handleResendInvitation(userId: string) {
    const selectedUser = projectUsers.find(user => user.userId === userId);
    if (selectedUser!.status === "FORCE_CHANGE_PASSWORD") {
      await getUserManagementAPI().resendUserInvitation(
        currentProject.id,
        {
          username: selectedUser!.email!
        }
      );
      trackUserInviteResend(user!.email!);
      toast.success(`Email invitation sent to ${selectedUser!.email}`);
    } else {
      toast.warn("The user has already accepted the invitation");
    }
  }

  // Dropdown menu action items
  const itemActions = [
    {
      title: "Edit",
      icon: <UpdateIcon />,
      onClick: handleUpdateUserPermissionsOnPoject
    },
    {
      title: "Resend Invitation",
      icon: <EmailIcon />,
      onClick: handleResendInvitation
    },
    {
      title: "Remove",
      icon: <RemoveIcon />,
      onClick: handleRemoveUserFromProject
    }
  ]

  return (
    <React.Fragment>
      <div className="searchbar-wrapper d-flex align-items-center mt--5 mb--5 pr--20">
        <SearchBar
          placeholder="Search accounts..."
          value={filterAccounts}
          onChange={e => setFilterAccounts(e.target.value)}
          modifierClass="pr--20"
        />
        <Button
          className="btn btn--lg btn--lightBlue"
          onClick={showUserModal}>Add New User</Button>
      </div>

      <CustomScrollbars maxHeight="100%">
        {!isProcessing ?
          projectUsersList && projectUsersList.length > 0 ?
            <ListView
              class="list--height list--settings pr--20"
              listType="management"
              itemActions={itemActions}
              items={projectUsersList}
            />
            : <Animation show={true} type="fade" itemIndex={1}>
              <h5 className="p--20 txt--grey txt--center width--full">No users available.</h5>
            </Animation>
          :
          [...Array(3)].map((_value, index) => (<ListItemSkeleton type="management" key={index} />))
        }
      </CustomScrollbars>
    </React.Fragment>
  )
}

export default Management;