import React, { useState, useEffect } from "react";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import { GetPlanResponse, GetStageDetailsResponse } from "@microtica/ms-engine-sdk";
import { useSelector } from "react-redux";

// Import components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/CardHeader/CardHeader";
import PageHeader from "../../components/PageHeader/PageHeader";
import Button from "../../components/Button/Button";
import StageStatus from "../../components/StageStatus/StageStatus";
import Animation from "../../components/Animations/Animations";

// Import icons in the circles
import { ReactComponent as CreateIcon } from "../../static/stage-add.svg";
import { ReactComponent as UpdateIcon } from "../../static/stage-modify.svg";
import { ReactComponent as ErrorIcon } from "../../static/error-icon.svg";

import { GlobalState } from "../../reducers";
import { getContinuousIntegrationAPI, getEngineAPI } from "../../api";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import { toast } from "react-toastify";

import Skeleton from "react-loading-skeleton";
import DeploymentPlanPlaceholders from "./DeploymentPlanPlaceholders";
import { useModal } from "react-modal-hook";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import BuildProcessWaiting from "../../components/BuildProcessWaiting/BuildProcessWaiting";
import { PipelineBuildDetailsMetadata } from "@microtica/ms-ap-sdk";
import Commit from "../../components/Commit/Commit";

import cloudProviders from "../../utils/cloud-providers";
import cloudTools from "../../utils/infrastructure-as-code-tools";
import { trackCreatePlanFailed, trackCreatePlanInit, trackCreatePlanSucceeded } from "../../tracking/deployment";
interface DeploymentPlanProps extends RouteComponentProps<{ stageId: string }> {
  stageId: string
}

const DeploymentPlan = (props: DeploymentPlanProps) => {
  const { currentProject } = useSelector((state: GlobalState) => state.project);
  const [plan, setPlan] = useState<GetPlanResponse>();
  const [stage, setStage] = useState<GetStageDetailsResponse>();
  const [planId, setPlanId] = useState("");
  const stageId = props.match.params.stageId;
  const [commitInfoMap, setCommitInfoMap] = useState(new Map<string, PipelineBuildDetailsMetadata>());
  const [isLoaded, setIsLoaded] = useState(false);
  const [showConfirmationModal, hideConfirmationModal] = useModal(() => {
    return <ModalDanger
      title={`This deployment is about to remove resources in the cloud.`}
      description="Do you really want to proceed with the deployment? This action cannot be undone."
      action="Yes"
      onCancel={hideConfirmationModal}
      onConfirm={handleConfirmDeployment}
    />
  }, [handleConfirmDeployment]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const [{ data: plan }, { data: stage }] = await Promise.all([
          getEngineAPI().createPlan(stageId, currentProject.id),
          getEngineAPI().getStageDetails(stageId, currentProject.id)
        ]);
        setPlanId(plan.planId);
        setStage(stage);
        setIsLoaded(true);
        trackCreatePlanSucceeded(stageId);
      } catch (error) {
        trackCreatePlanFailed(stageId);
        toast.error(error.response.data.message);
        props.history.goBack();
      }
    }

    fetch();
  }, [stageId]);

  async function fetchPlan(planId: string) {
    try {
      const { data: plan } = await getEngineAPI().getPlan(stageId, planId, currentProject.id);
      if (!!plan.changes.length) {
        setPlan(plan);
      }

    } catch (error) {
      toast.error(error.response.data.message);
      props.history.push(`/environments/${stageId}`)
    }
  }

 

  useEffect(() => {
    const fetchCommitInfo = async () => {
      if(plan && plan.changes.length > 0){
        try {
          const [{ data: { components } }, { data: { pipelines } }] = await Promise.all([
            getEngineAPI().getComponents(currentProject.id),
            getContinuousIntegrationAPI().listPipelines(currentProject.id)
          ])
    
          const commitMap = new Map<string, PipelineBuildDetailsMetadata>();
    
          plan.changes.forEach(change => {
            const component = components.find(comp => change.resource.component!.id === comp.id)
            component && commitMap.set(component.id, pipelines.find(pipeline => component.pipelineId === pipeline.id)!.latestBuild!.metadata)
          })
    
          setCommitInfoMap(commitMap)
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    }

    fetchCommitInfo()
  }, [plan])

  useEffect(() => {
    const timer = setInterval(async () => {
      if (!plan && planId) {
        fetchPlan(planId);
      }
      else {
        clearInterval(timer);
      }
    }, 5000);

    if (!plan && planId) {
      fetchPlan(planId);
    }
    return () => clearInterval(timer);

  }, [planId, plan]);

  function getName(array: { id: string, name: string, value: string }[], key: string) {
    return array.find(el => el.value === key)!.name
  }

  async function handleConfirmDeployment() {
    await getEngineAPI().deployStagePlan(stageId, planId!, currentProject.id);
    trackDeploymentPlan(stageId, planId!);
    props.history.push(`/environments/${stageId}/details`)
  }

  const isActionCreate = (action: string) => action === "Add" || action === "create";
  const isActionDelete = (action: string) => action === "Remove" || action === "delete";
  const isActionNoOp = (action: string) => action === "no-op"

  return (
    <main className="content d-flex flex-column justify-content-start">
      {!plan ? <BuildProcessWaiting text="Your plan is being created and it may take a few minutes. Please wait..." /> : null}

      <PageHeader title={stage ? stage.name : "Deployment Plan"} />

      <div className="content__body">
        <Card class="card dark card--replicate-main pb--10">
          <div className="card__header-wrapper card__header-wrapper--replicate card--underline d-flex justify-content-between align-items-center pl--15">
            <div className="d-flex justify-content-start align-items-center" style={{ width: "100%" }}>
              <div className="card__header__item">
                <h5><strong>Plan ID</strong></h5>
                <p className="m--0">
                  {plan ? planId : <Skeleton width="100px" />}
                </p>
              </div>

              <div className="card__header__item">
                <h5><strong>Cloud Provider</strong></h5>
                <p className="m--0">
                  {stage ? getName(cloudProviders, stage.cloudProvider) : <Skeleton width="100px" />}
                </p>
              </div>

              <div className="card__header__item">
                <h5><strong>IaC Tool</strong></h5>
                <p className="m--0">
                  {stage ? getName(cloudTools, stage.infrastructureAsCodeTool) : <Skeleton width="100px" />}
                </p>
              </div>

              <div className="card__header__item">
                <h5><strong>Last Deployed</strong></h5>
                <p className="m--0">{plan ? stage && moment(stage.lastDeployed).fromNow() || "n/a" : <Skeleton width="100px" />}</p>
              </div>
            </div>
            <Button
              className="btn btn--md btn--blue"
              onClick={() => {
                const willRemoveResources = plan!.changes.filter(change => change.action === "Remove").length !== 0;
                if (willRemoveResources) {
                  showConfirmationModal();
                } else {
                  handleConfirmDeployment();
                }
              }}
              disabled={!plan || plan.changes.length === 0}
              children={"Deploy"}
            />
          </div>
          {
            plan ?
              <CustomScrollbars maxHeight={"calc(100vh - 400px)"} resetClass="reset--top">
                <div className="stages-container">
                  {plan!.changes.map((stage, index: number) => (
                    <Animation show={isLoaded} type="fade" itemIndex={index} key={stage.resource.name}>
                      <Card class="card--replicate card--stages">
                        <Card class="card light">
                          <div className="card__header-wrapper">
                            <CardHeader title={stage.resource.name} />
                          </div>
                          <div className="stages plan">
                            <StageStatus
                              class="plan"
                              items={[
                                {
                                  id: 1,
                                  title: "Action",
                                  text: stage.action === "no-op" ? "No changes" : stage.action, 
                                  status: isActionCreate(stage.action) ? "success" : isActionDelete(stage.action) ? "fail" : isActionNoOp(stage.action) ? "placeholder" : "warning",
                                  icon: isActionCreate(stage.action) ? <CreateIcon /> : isActionDelete(stage.action) ? <ErrorIcon /> : <UpdateIcon width="14" stroke="#fff" />
                                },
                                {
                                  id: 2,
                                  title: isActionDelete(stage.action) ? "" : "Component",
                                  text: isActionDelete(stage.action) ? "" : stage.resource.component!.name
                                },
                                {
                                  id: 3,
                                  title: isActionDelete(stage.action) ? "" : "Version",
                                  text: isActionDelete(stage.action) ? "" : stage.resource.component!.version.substring(0, 10)
                                },
                                {
                                  id: 4,
                                  title: "Commit",
                                  text: commitInfoMap.get(stage.resource.component!.id) ?
                                    <Commit
                                      name={commitInfoMap.get(stage.resource.component!.id)!.commit.name}
                                      message={commitInfoMap.get(stage.resource.component!.id)!.commit.message}
                                      userName={commitInfoMap.get(stage.resource.component!.id)!.commit.user.name}
                                      userAvatar={commitInfoMap.get(stage.resource.component!.id)!.commit.user.avatar}
                                      version={commitInfoMap.get(stage.resource.component!.id)!.commit.version}
                                      href={`${commitInfoMap.get(stage.resource.component!.id)!.repository}/${commitInfoMap.get(stage.resource.component!.id)!.repository.startsWith("https://bitbucket.org") ? "commits" : "commit"}/${commitInfoMap.get(stage.resource.component!.id)!.commit.version}`}
                                      target="_blank"
                                    /> :
                                    <div className="pull--left">
                                      <div className="mb--5"><Skeleton width="100px" /></div>
                                      <div><Skeleton width="150px" /></div>
                                    </div>,
                                }
                              ]}
                            />
                          </div>
                        </Card>
                      </Card>
                    </Animation>
                  ))}
                </div>
              </CustomScrollbars>
              : <DeploymentPlanPlaceholders />
          }
        </Card>
      </div>
    </main>
  )
}

export default DeploymentPlan;