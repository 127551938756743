import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../reducers";
import { fetchDockerRegistries } from "../actions";
import dockerhub from "../static/dockerhub.svg"
import gitlab from "../static/gitlab-logo.svg"
import ecr from "../static/ecr.svg";
import { DockerRegistryType } from "@microtica/ms-ap-sdk";
import { DockerProviderType } from "../utils/docker-providers";

export default function useDockerRegistriesList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { dockerRegistries, isProcessing, error } = useSelector((state: GlobalState) => state.dockerRegistry);

    useEffect(() => {
        dispatch(fetchDockerRegistries(currentProject.id));
    }, [dispatch]);

    const dockerRegistriesList = dockerRegistries
        .sort((a, b) => {
            if (a.isDefault) return -1;
            return 0;
        })
        .map((registry: any) => ({
            itemId: registry.registryName,
            title: registry.registryName,
            description: registry.isDefault ? "DEFAULT" : "",
            email: mapRegistryTypes(registry.type),
            profileImage: mapIcon(registry.type)
        }));

    return {
        dockerRegistriesList,
        isProcessing,
        error
    }

    function mapRegistryTypes(type: string) {
        switch (type) {
            case DockerRegistryType.Ecr:
                return DockerProviderType.ECR
            case DockerRegistryType.Dockerhub:
                return DockerProviderType.DOCKER_HUB
            case DockerRegistryType.GitlabContainerRegistry:
                return DockerProviderType.GITLAB_CONTAINER_REGISTRY    
            default:
                return type
        }
    }

    function mapIcon(type: string) {
        switch (type) {
            case DockerRegistryType.Ecr: {
                return ecr
            }
            case DockerRegistryType.Dockerhub:
                return dockerhub
            case DockerRegistryType.GitlabContainerRegistry:
                return gitlab
            default:
                return type
        }
    }
}
