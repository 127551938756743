import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";

import { GlobalState } from '../../reducers';
import { connectGitAccount } from '../../actions/accounts/git/connect-git-account';

export interface GitAuthProps {
    code: string;
    type: "bitbucket" | "github" | "gitlab";
}

const GitAuth = (props: GitAuthProps) => {
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { error } = useSelector((state: GlobalState) => state.gitAccount);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = async () => {
            toast.info(`Connecting with ${props.type.toUpperCase()} account. Please wait...`);

            dispatch(connectGitAccount(currentProject.id, { code: props.code, type: props.type }))
        };
        fetch();
    }, []);

    useEffect(() => {
        if (error.code === 409) {
            toast.error(`${props.type.toUpperCase()} account you are trying to connect already exists.`);
        } else if (error.message) {
            toast.error("Error while connecting your account.");
        }
    }, [error])

    return (
        <div></div>
    )
}
export default GitAuth;