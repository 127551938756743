import * as React from 'react';

export interface FooterProps {
}

const Footer: React.StatelessComponent<FooterProps> = p => {
  return (
    <footer className="footer d-flex justify-content-center">
      <div>
        <p className="footer__copyright">&copy; Microtica {new Date().getFullYear()}. All rights reserved</p>
      </div>

      <div>
        <a href="https://microtica.com/privacy-policy/" target="_blank" className="footer__menu__item" rel="noreferrer">Privacy Policy</a>
        <a href="https://microtica.com/terms-of-service/" target="_blank" className="footer__menu__item" rel="noreferrer">Terms of Service</a>
        <a href="https://microtica.com/cookie-policy/" target="_blank" className="footer__menu__item" rel="noreferrer">Cookie Policy</a>
      </div>
    </footer>

  );
};

export default Footer;
