import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from "react-toastify";
import Card from '../../components/Card/Card';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Table from '../../components/Table/Table';
import ListView from '../../components/ListView/ListView';
import CardHeader from '../../components/CardHeader/CardHeader';
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import TimelineContainer from "../../components/TimelineContainer/TimelineContainer";
import { ReactComponent as PreviewIcon } from "../../static/preview-icon.svg";
import { ReactComponent as ArrowIcon } from "../../static/angle-right-solid.svg";
import TabMenuContainer from '../../components/TabMenuContainer/TabMenuContainer';
import timelineCategories from "../../utils/timeline-categories";
import Button from '../../components/Button/Button';
import PageHeader from '../../components/PageHeader/PageHeader';

import useTimeline from "../../hooks/Timeline";
import { getKubeAPI } from '../../api';
import { GlobalState } from '../../reducers';
import CustomScrollbars from '../../components/CustomScrollbars/CustomScrollbars';
import useStageList from '../../hooks/StageList';
import moment from 'moment';
import Animation from '../../components/Animations/Animations';
import Badge from '../../components/Badge/Badge';
import { trackKubeConnectInit } from '../../tracking/kubernetes';


interface OverviewProps extends RouteComponentProps { }

const headers = [
  "Service Name:left",
  "Namespace",
  "Pods",
  "Version",
  "Last Deployed",
  "Status"
];

const Overview = (props: OverviewProps) => {
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const [clusters, setClusters] = useState<DropdownItem[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<DropdownItem>();
  const [clusterServices, setClusterServices] = useState<(string | number | JSX.Element)[][]>([]);
  const [deploymentsPercentage, setDeploymentsPercentage] = useState("n/a");
  const [hiddenServicesCount, setHiddenServicesCount] = useState(0);
  const [podsPercentage, setPodsPercentage] = useState("n/a");
  const { events, setType } = useTimeline();

  const { stages } = useStageList();

  const [isLoaded, setIsLoaded] = useState(false);
  const [clusterRedirectUrl, setClusterRedirectUrl] = useState("");

  useEffect(() => {
    //fetch kubernetes clusters
    const fetch = async () => {
      try {
        const { data: response } = await getKubeAPI().listKubernetes(currentProject.id);
        setClusters(response.kuberneteses.map(item => ({
          id: item.id,
          name: item.name,
          subTitle: item.endpoint,
          type: item.type
        })));
        const selectedCluster = response.kuberneteses[0] && {
          id: response.kuberneteses[0].id,
          name: response.kuberneteses[0].name,
          subTitle: response.kuberneteses[0].endpoint,
          type: response.kuberneteses[0].type
        }
        setSelectedCluster(selectedCluster);
        await handleClusterSelect(selectedCluster);
      } catch (err) {
        toast.error(err.response.data)
      }
      setIsLoaded(true);
    }
    fetch();
  }, []);

  async function handleClusterSelect(item: DropdownItem) {
    if (!item) { return; }
    setClusterRedirectUrl(`/kubernetes/clusters/${item.id}`);

    try {
      const { data: response } = await getKubeAPI().getKubernetesStatus(
        item.id,
        currentProject.id
      );

      const { podStatus, microservicesStatus } = response;
      const { totalPods } = podStatus;
      const runningPods = podStatus.runningPods + podStatus.succeededPods;

      let totalDeployments = 0;
      let runningDeployments = 0;
      let failedDeployments = 0;

      for (let [, value] of Object.entries(microservicesStatus)) {
        totalDeployments++;
        if (!value.isHealthy) {
          failedDeployments++
        } else {
          runningDeployments++;
        }
      }

      // Filter unique microservices' names
      const microserviceNames = microservicesStatus.map(service => service.name).filter((value, index, self) => self.indexOf(value) === index);
      // Display only part of them
      const clusterServices = microserviceNames.slice(0, 4).map(serviceName => {
        const ms = microservicesStatus.find(service => service.name === serviceName);
        return [
          ms!.name,
          ms!.namespace,
          <span className={`${ms!.podStatus.failedPods > 0 ? "txt--red" : ""}`}>{ms!.podStatus.runningPods}/{ms!.podStatus.totalPods}</span>,
          <Link to={`/pipelines/${ms!.imageRepository.split("/")[1]}/builds/${ms!.version}`} style={{ color: "rgb(74, 144, 226)" }} >{ms!.version}</Link>,
          ms!.lastDeployed ? moment(ms!.lastDeployed).fromNow() : "n/a",
          <Badge name={ms!.isHealthy ? "HEALTHY" : "FAILED"} status={ms!.isHealthy ? "SUCCESS" : "FAILED"} />
        ];
      });
      setDeploymentsPercentage((runningDeployments / totalDeployments * 100).toFixed(0));
      setPodsPercentage((runningPods / totalPods * 100).toFixed(0));
      setSelectedCluster(item);
      setClusterServices(clusterServices);
      setHiddenServicesCount(microserviceNames.length - clusterServices.length);
    } catch (error) {
      setSelectedCluster(undefined);
    }
  }

  function isStatusInProgress(status: string) {
    return status !== "REVIEW_IN_PROGRESS" && status.endsWith("IN_PROGRESS");
  }

  function isStatusFailed(status: string) {
    return status.endsWith("FAILED");
  }

  function isStatusCompleted(status: string) {
    return status.endsWith("COMPLETE") && status !== "DELETE_COMPLETE";
  }

  function mapStatusToColor(status: string) {
    if (isStatusInProgress(status)) {
      return "warning";
    } else if (isStatusCompleted(status)) {
      return "success";
    } else if (isStatusFailed(status)) {
      return "fail";
    } else {
      return "placeholder";
    }
  }

  function handleStageClick(stageId: string) {
    props.history.push(`/environments/${stageId}`);
  }

  function handleTimelineItemClick(itemId: string, type: string, stageName: string, pipelineId: string) {
    if (type === "build" && pipelineId) {
      props.history.push(`/pipelines/${pipelineId}/builds/${itemId}`)
    }
    if (type === "deployment" && stageName) {
      const stage = stages.find(s => s.name === stageName);
      if (stage) {
        props.history.push(`/environments/${stage.id}`);
      } else {
        toast.error(`Environment ${stageName} is no longer available`)
      }
    }
  }

  // Calculate timeline max height
  const [timelineHeight, setTimelineHeight] = useState(700);
  useEffect(() => {
    calculateDistanceFromBottom();
    // Calculate the distance on resize. Maybe use debounce function ?
    window.addEventListener("resize", () => {
      // Call the function only above 760px window height
      if (window.innerHeight > 760) {
        calculateDistanceFromBottom();
      } else {
        return;
      }
    });
  }, [])

  // Calculate distance from bottom of page to bottom card to align the timeline element.
  const calculateDistanceFromBottom = () => {
    const bottomCardElement = document.querySelector(".card--bottom") as HTMLElement || { offsetTop: 0 };
    const distanceBottomToCard = window.innerHeight > 1080 ? (window.innerHeight - bottomCardElement.offsetTop) + 140 : window.innerHeight - bottomCardElement.offsetTop;

    setTimelineHeight(distanceBottomToCard);
  }
  return (
    <main className="content d-flex flex-column justify-content-start overview">
      <PageHeader title="Overview" />
      <div className="content__body">
        <div className="row">
          <div className="col-12 col-xl-8">
            <Card class="card dark mb--20">
              <div className="card__header-wrapper pb--10">
                <CardHeader title="KUBERNETES SUMMARY" />
                {clusters.length > 0 ?
                  <div className="d-flex align-items-center" style={{ width: "200px" }}>
                    < DropdownContainer
                      selectedItem={selectedCluster}
                      items={clusters}
                      onSelectItem={handleClusterSelect}
                    />
                    <Link to={clusterRedirectUrl}
                      className={`btn ${clusterRedirectUrl === "" ? "disabled" : null} btn--cluster`}>
                      <PreviewIcon width="16px" height="16px" />
                    </Link>
                  </div>
                  : null
                }

              </div>
              <>
                {
                  clusters.length > 0 || !isLoaded ?
                    <>
                      <div className="pl--10 pr--10">
                        {
                          selectedCluster || !isLoaded ?
                            <>
                              <ProgressBar isLoaded={!isLoaded} name="DEPLOYMENTS" percentage={`${deploymentsPercentage}%`} />
                              <ProgressBar isLoaded={!isLoaded} name="PODS" percentage={`${podsPercentage}%`} />
                            </> :
                            <Animation show={true} type="fade" itemIndex={1}>
                              <h5 className="p--20 txt--grey txt--center width--full">No data available.</h5>
                            </Animation>
                        }
                      </div>
                      <Table
                        headers={headers}
                        rows={clusterServices}
                        isLoaded={isLoaded}
                      />
                      {
                        isLoaded && hiddenServicesCount > 0 ?
                          <div style={{ textAlign: "center" }}>
                            <a style={{ display: "inline-flex" }} href={selectedCluster ? `/kubernetes/clusters/${selectedCluster!.id}` : ""}>
                              View All Microservices <ArrowIcon width="18px" height="18px" style={{ paddingTop: "2px" }} />
                            </a>
                          </div> : null
                      }

                    </>
                    : <>
                      {
                        isLoaded ?
                          <div style={{ textAlign: "center" }}>
                            <h4 className="mt--30">No clusters connected yet!</h4>
                            <p className="mt--10">
                              Connect your existing Kubernetes cluster running on any cloud provider or on-premise.
                              <br />
                              Automate deployment and monitor services from integrated Kubernetes Dashboard
                            </p>
                            <Button
                              className="btn btn--md btn--link btn--blue mt--10"
                              onClick={() => { trackKubeConnectInit(); props.history.push("/kubernetes/clusters") }} style={{ margin: "auto" }}>
                              Connect Kubernetes
                            </Button>
                          </div>

                          : null}
                    </>
                }
              </>

            </Card>

            <Card class="card dark card--bottom">
              <div className="card__header-wrapper pb--10">
                <CardHeader title="ENVIRONMENTS" />
              </div>
              <>
                {
                  stages.length > 0 || !isLoaded ?
                    < CustomScrollbars maxHeight="340px" customClass="stages" >
                      <ListView
                        items={stages.map(stage => ({
                          itemId: stage.id,
                          title: stage.name,
                          description: stage.description,
                          status: mapStatusToColor(stage.status)
                        }))}
                        onClick={itemId => handleStageClick(itemId)}
                        listType="status"
                        isLoaded={!isLoaded}
                      />
                    </CustomScrollbars> :
                    <>
                      {
                        isLoaded ?
                          <div style={{ textAlign: "center" }}>
                            <h4 className="mt--30">No environments created yet!</h4>
                            <p className="mt--10">
                              Build a complete cloud infrastructure combining multiple components.
                              <br />
                              Choose from our catalogue of ready-to-use components for Kubernetes, VPC, MySQL etc. or build your custom ones
                            </p>
                            <Button
                              className="btn btn--md btn--link btn--blue mt--10"
                              onClick={() => props.history.push("/environments/create")} style={{ margin: "auto" }}>
                              Create Environment
                            </Button>
                          </div>
                          : null
                      }
                    </>
                }
              </>
            </Card>
          </div>
          <div className="col-12 col-xl-4">
            <Card class="card dark card--vertical">
              <div className="card__header-wrapper pb--10">
                <CardHeader title="TIMELINE" />
                {/* <DropdownContainer selectedItem={{ id: "1", name: "All" }}
                  items={[
                    { id: "1", name: "All" },
                    { id: "2", name: "In progress" },
                    { id: "3", name: "Success" },
                    { id: "4", name: "Failed" }
                  ]}
                  onSelectItem={item => setFilter(item.name)}
                  disabled={true}
                /> */}
              </div>

              <TabMenuContainer
                tabs={timelineCategories}
                onClick={tab => setType(tab.label)}
              />

              <CustomScrollbars maxHeight={`${timelineHeight}px`}>
                {
                  events.length > 0 ?

                    <TimelineContainer
                      items={events}
                      onClick={handleTimelineItemClick}
                      isLoaded={isLoaded}
                    /> :
                    <>
                      {
                        isLoaded ?
                          <div style={{ textAlign: "center" }}>
                            <h4 className="mt--30">No pipelines created yet!</h4>
                            <p className="mt--10">
                              Setup how your source code should be built, tested and deployed in the cloud with zero maintenance.
                              <br />
                              Build powerful pipelines using our built-in steps or create custom
                            </p>
                            <Button
                              className="btn btn--md btn--link btn--blue mt--10"
                              onClick={() => props.history.push("/pipelines/create")} style={{ margin: "auto" }}>
                              Create Pipeline
                            </Button>
                          </div>

                          : null}
                    </>
                }
              </CustomScrollbars>

            </Card>
          </div>
        </div>
      </div>
    </main >
  );
};

export default Overview;
