import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';

interface PolarChartProps {
  title: string;
  metaNumber: number;
  pieValues: number[]
}
const PIE_LABELS = ["RUNNING", "FAILED", "PENDING"];
const PIE_COLORS = ["#007dc5", "#f84737", "#f5a71e"];

const PolarChart = (props: PolarChartProps) => {
  // State for pie charts labels and colors (?)

  const chartData = {
    labels: PIE_LABELS,
    datasets: [{
      data: props.pieValues,
      backgroundColor: PIE_COLORS,
      borderWidth: 0,
      datalabels: {
        listeners: {
          click: (context: any) => alert(`${context.chart.data.labels[context.dataIndex]} has been clicked.`)
        }
      }
    }]
  }

  let chartReference = {} as any;
  let segmentsDataValue: any;

  useEffect(() => {
    // Save chart's segments data and use it to set the outer radius of the segments
    segmentsDataValue = chartReference.chartInstance.data.datasets[0];

    // Sort the charts data highest > lowest
    const sortedValues = props.pieValues;
    sortedValues.sort((a, b) => (a > b) ? -1 : 1);

    // Sort colors and labels arrays in the same order as values array - matching values with colors and labels (?)

    setSegmentsOuterRadius(segmentsDataValue);
  }, [props.pieValues])

  // Wait for data to be loaded 
  async function setSegmentsOuterRadius(segmentsDataValue: any) {
    const segment = await segmentsDataValue;

    // Re-render the pie chart
    // chartReference.chartInstance.update();

    // Target the data and outer radius for each segment of each pie chart
    const segmentOuterRadius = segment._meta[props.metaNumber];

    try {
      segmentOuterRadius.data[0]._model.outerRadius = 70;
      segmentOuterRadius.data[1]._model.outerRadius = 70;
    } catch (error) {
      
    }
  }

  return (
    <Pie
      ref={reference => chartReference = reference}
      data={chartData}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: props.title,
          fontColor: "#fff",
          fontSize: 18,
          fontStyle: "normal"
        },
        tooltips: {
          enabled: false
        },
        rotation: Math.PI * 0.5,
        plugins: {
          datalabels: {
            color: "#fff",
            anchor: "end",
            align: "start",
            offset: 10,
            font: {
              size: 12,
              family: "Roboto",
              weight: "bold"
            },
            clamp: true,
            display: "auto",
            formatter: (value: any, context: any) => {
              if (props.pieValues[context.dataIndex] > 10) {
                return `${context.chart.data.labels[context.dataIndex]}\n ${props.pieValues[context.dataIndex]}%`
              } else if (props.pieValues[context.dataIndex] > 0 && props.pieValues[context.dataIndex] <= 10) {
                return `${props.pieValues[context.dataIndex]}%`
              } else {
                return ""
              }
            }
          }
        }
      }}
    />
  )
}

export default PolarChart;