import React, { useState, useEffect } from "react";
import ModalInput from "../../components/ModalInput/ModalInput";
import { useDispatch, useSelector } from "react-redux";

// Import Icons
import Button from "../../components/Button/Button";
import { createProject } from "../../actions";
import { Project } from "@microtica/ms-project-sdk";
import { updateProject } from "../../actions/projects/update-project";
import { GlobalState } from "../../reducers";
import { toast } from "react-toastify";
import { CLEAR_ERROR } from "../../constants";
import { Formik, Form, Field } from "formik";
import { InputField } from "../../components/InputField/InputField";
import { InputAreaField } from "../../components/InputTextarea/InputAreaField";
import { projectSchema } from "../../utils/validation";

export interface CreateProjectModalProps {
  onClose: () => void;
  project?: Project;
}

export default function (props: CreateProjectModalProps) {
  const dispatch = useDispatch();
  const { error, isProcessing } = useSelector((state: GlobalState) => state.project);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    if (error.message) toast.error(error.message);
    return () => {
      if (error.message) dispatch({ type: CLEAR_ERROR });
    };
  }, [error]);


  return (
    <ModalInput
      class="modal modal--projects"
      name={props.project ? "Update Project" : "Create New Project"}
      open
      onClose={props.onClose} >
      <Formik
        initialValues={props.project ?
          { name: props.project.name, description: props.project.description } :
          { name: "", description: "" }}
        onSubmit={values => {
          setIsBusy(true);
          const project = {
            name: values.name,
            description: values.description,
            paymentPlanId: "STARTER"
          }
          if (props.project) {
            dispatch(updateProject(props.project.id, project));
          } else {
            dispatch(createProject(project));
          }
          setIsBusy(false);
          props.onClose();
        }}
        validationSchema={projectSchema}
        render={() => (
          <Form>
            <div className="d-flex justify-content-center align-items-center flex-wrap mb--70">
              {/* <label
              className="box box--empty box--create flex-column"
              id="fileUpload">
              <img className="mb--10" src={imageFrame} />
              <p className="m--0">Upload Logo</p>
              <input
                className="d-none"
                type="file"
                name="fileUpload"
                id="fileUpload"
                size={1}
              />
            </label> */}
              <div className="box__inputs">
                <Field
                  name="name"
                  placeholder="Name"
                  type="text"
                  component={InputField}
                />
                <Field
                  name="description"
                  placeholder="Description"
                  className="input__field input--textarea"
                  type="text"
                  rows={6}
                  component={InputAreaField}
                />
              </div>
              {
                !props.project &&
                <div>
                  <br />
                  <br />
                  You will be subscribed to the <a href="https://microtica.com/pricing" target="_blank" style={{ fontSize: 16 }}>Starter Plan</a> with 14 day free trial.
                </div>
              }
            </div>
            <Button
              type="submit"
              className="btn btn--xl btn--blue"
              disabled={isProcessing}
              isBusy={isBusy}
              busyText={props.project ? "Saving.." : "Creating.."}
              children={props.project ? "Save changes" : "Create Project"}
            />
          </Form>
        )}
      />
    </ModalInput>
  );
}
