import * as constants from "../../../constants";
import {
    AddGcpAccountRequest
} from "@microtica/ms-aws-sdk";
import { getAwsAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, GcpAccount } from "../../../types/index";

export interface ConnectGcpAccountStart {
    type: constants.CONNECT_GCP_ACCOUNT_START;
}
export interface ConnectGcpAccountSuccess {
    type: constants.CONNECT_GCP_ACCOUNT_SUCCESS;
    gcpAccount: GcpAccount;
}
export interface ConnectGcpAccountError {
    type: constants.CONNECT_GCP_ACCOUNT_FAIL;
    error: ErrorInterface;
}
export interface ConnectGcpAccountClear {
    type: constants.CONNECT_GCP_ACCOUNT_CLEAR;
}

export type ConnectGcpAccount =
    | ConnectGcpAccountStart
    | ConnectGcpAccountSuccess
    | ConnectGcpAccountError
    | ConnectGcpAccountClear

export function connectGcpAccountStart(): ConnectGcpAccount {
    return {
        type: constants.CONNECT_GCP_ACCOUNT_START
    };
}
export function connectGcpAccountError(error: ErrorInterface): ConnectGcpAccount {
    return {
        type: constants.CONNECT_GCP_ACCOUNT_FAIL,
        error
    };
}
export function connectGcpAccountClear(): ConnectGcpAccount {
    return {
        type: constants.CONNECT_GCP_ACCOUNT_CLEAR
    };
}

export function connectGcpAccountSuccess(
    gcpAccount: GcpAccount
): ConnectGcpAccount {
    return {
        type: constants.CONNECT_GCP_ACCOUNT_SUCCESS,
        gcpAccount
    };
}

export function connectGcpAccount(
    projectId: string,
    object: AddGcpAccountRequest
): ThunkAction<Promise<void>, {}, {}, ConnectGcpAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, ConnectGcpAccount>) => {
        dispatch(connectGcpAccountStart());
        try {
            await getAwsAPI().addGcpAccount(projectId, object);
            dispatch(connectGcpAccountSuccess({
                ...object,
                microticaServiceAccount: "NOT_IMPORTANT"
            }));
        } catch (error) {
            dispatch(connectGcpAccountError(error.response.data));
        }
    };
}