import React, { useState, useEffect } from "react";
import { useModal } from 'react-modal-hook';
import SearchBar from "../../components/SearchBar/SearchBar";
import Button from "../../components/Button/Button";
import useAwsAccountList from "../../hooks/AwsAccount";
import Animation from "../../components/Animations/Animations";
import AwsAccounts from "./AwsAccounts";
import GcpAccounts from "./GcpAccounts";
import useGcpAccountList from "../../hooks/GcpAccount";
import CloudAccountModal from "../../components/CloudAccount/CloudAccountModal";
import AzureAccounts from "./AzureAccounts";
import useAzureAccountList from "../../hooks/AzureAccount";
import { trackCloudAccountInit } from "../../tracking/user-settings";

const CloudAccounts = () => {
    const { awsAccounts } = useAwsAccountList();
    const { gcpAccounts } = useGcpAccountList();
    const { azureAccounts } = useAzureAccountList();
    const [cloudAccountList, setCloudAccountList] = useState<any[]>([]);
    const [filteredCloudAccounts, setFilteredCloudAccounts] = useState("");

    useEffect(() => {
        setCloudAccountList([...awsAccounts, ...gcpAccounts, ...azureAccounts]
            .map((item: any) => {
                return {
                    itemId: item.id || item.gcpProjectId || `${item.tenantId}_${item.subscriptionId}`,
                    title: item.accountName || item.projectName || item.subscriptionName,
                    description: item.iamRole || item.targetServiceAccount || item.subscriptionId
                }
            })
            .filter(item => {
                if (filteredCloudAccounts !== "") {
                    return item.title
                        .toLocaleLowerCase()
                        .includes(
                            filteredCloudAccounts.toLocaleLowerCase()
                        );
                } else {
                    return true;
                }
            })
        )
    }, [awsAccounts, gcpAccounts, azureAccounts, filteredCloudAccounts])

    const [showConnectAccountModal, hideConnectAccountModal] = useModal(() => (<CloudAccountModal
        onClose={hideConnectAccountModal}
    />), []);

    return (
        <React.Fragment>
            <div className="searchbar-wrapper d-flex align-items-center mt--5 mb--5 pr--20">
                <SearchBar
                    placeholder="Search accounts..."
                    value={filteredCloudAccounts}
                    onChange={e => setFilteredCloudAccounts(e.target.value)}
                    modifierClass="pr--20"
                />
                <Button
                    className="btn btn--lg btn--lightBlue"
                    onClick={() => {
                        trackCloudAccountInit();
                        showConnectAccountModal();
                    }}>Connect Cloud Account</Button>
            </div>

            <AwsAccounts searchText={filteredCloudAccounts} />
            <GcpAccounts searchText={filteredCloudAccounts} />
            <AzureAccounts searchText={filteredCloudAccounts} />
            {
                // Display message if there are no cloud accounts
                (!cloudAccountList || !cloudAccountList.length) &&
                <Animation show={true} type="fade" itemIndex={1}>
                    <h5 className="p--20 txt--grey txt--center width--full">No cloud accounts available. Add a new account?</h5>
                </Animation>
            }
        </React.Fragment>
    )
}

export default CloudAccounts;