import React, { useEffect, useState } from "react";
import { MicroserviceConfigurationItem } from "@microtica/ms-engine-sdk";

import { Dictionary } from "./ConfigurationItem";
import DropdownContainer, { DropdownItem } from "../../components/DropdownContainer/DropdownContainer";
import TextOrDropdown from "../../components/TextOrDropdown/TextOrDropdown";
import InputText from "../InputText/InputText";
import { jaroWrinkerAlgorithm } from "../../utils/jaro-winker-algorithm";

interface Property {
    key: string;
    value: string;
    type: "string" | "select" | string;
    required: boolean;
    description?: string;
    enum: string[];
    sensitive: boolean;
    reference: boolean;
}

const ResourceConfigurationItem = (props: {
    prop: Property;
    resourceOuputs: Dictionary<string[]>;
    onChange?: (prop: MicroserviceConfigurationItem) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    validate?: (property?: string) => void;
    tabIndex?: number;
}) => {
    const { prop, resourceOuputs } = props;

    const [value, setValue] = useState(prop.value);
    const [reference, setReference] = useState(prop.reference);
    const [selectedResource, setSelectedResource] = useState<DropdownItem>();
    const [resources, setResources] = useState<DropdownItem[]>([]);

    useEffect(() => {
        if (prop.reference) {
            const [resource, property] = prop.value.split(".", 2);
            handleResourceSelect({ id: `${resource}.${property}`, name: `${resource}.${property}`, subTitle: resource });
        } else {
            setValue(props.prop.value);
        }
    }, []);

    useEffect(() => {
        if (resourceOuputs) {
            const allResources: DropdownItem[] = [];
            for (const resourceName in resourceOuputs) {
                const outputs = resourceOuputs[resourceName] || [];
                allResources.push(...outputs.map(output => ({
                    id: `${resourceName}.${output}`,
                    name: `${resourceName}.${output}`,
                    subTitle: resourceName,
                    suggestionScore: jaroWrinkerAlgorithm(prop.key, output)
                })));
            }
            setResources(allResources.sort((a, b) => b.suggestionScore! - a.suggestionScore!));
        }
    }, [resourceOuputs]);

    useEffect(() => {
        if (props.onChange && reference) {
            let value = selectedResource && selectedResource.name || "";
            props.onChange({
                key: prop.key,
                value,
                reference: true,
                sensitive: prop.sensitive
            });
            props.validate && props.validate(prop.key);
        }
    }, [reference, selectedResource]);

    useEffect(() => {
        if (props.onChange && !reference) {
            props.onChange({
                key: prop.key,
                value,
                reference: false,
                sensitive: prop.sensitive
            });
            props.validate && props.validate(prop.key)
        }
    }, [reference, value]);


    function handleOnChange(value: string) {
        setValue(value);
        setReference(false);
    }

    function handleResourceSelect(item: DropdownItem) {
        setSelectedResource(item);
        setReference(item.id !== "");
    }

    function mapPropToComponent() {
        if (prop.type === "select") {
            return (
                <DropdownContainer
                    label={prop.key}
                    optional={!prop.required}
                    info={props.prop.description}
                    items={prop.enum.map(item => ({ id: item, name: item }))}
                    selectedItem={{ id: value.toString(), name: value.toString() }}
                    onSelectItem={(item) => setValue(item.name)}
                    onBlur={() => props.validate && props.validate(prop.key)}
                    tabIndex={props.tabIndex}
                    onFocus={props.onFocus}
                />
            );
        } else {
            if (prop.sensitive) {
                return (
                    <InputText
                        type="password"
                        label={prop.key}
                        optional={!prop.required}
                        info={props.prop.description}
                        placeholder="enter value"
                        value={value.toString()}
                        onChange={(e) => setValue(e.target.value)}
                        onBlur={() => props.validate && props.validate(prop.key)}
                        onFocus={props.onFocus}
                    />
                );
            } else {
                if (!resources || !resources.length) {
                    return (
                        <InputText
                            type="text"
                            label={prop.key}
                            optional={!prop.required}
                            info={props.prop.description}
                            placeholder="enter value"
                            value={value.toString()}
                            onChange={(e) => setValue(e.target.value)}
                            onBlur={() => props.validate && props.validate(prop.key)}
                            onFocus={props.onFocus}
                        />
                    );
                } else {
                    return (
                        <TextOrDropdown
                            //placeholder="Choose from existing resource or enter plain text"
                            label={prop.key}
                            optional={!prop.required}
                            info={props.prop.description}
                            icon={reference ? "link" : prop.sensitive ? "password" : ""}
                            items={resources}
                            selectedItem={selectedResource}
                            onSelectItem={handleResourceSelect}
                            value={value}
                            onChange={handleOnChange}
                            tabIndex={props.tabIndex}
                            onFocus={props.onFocus}
                            onBlur={() => props.validate && props.validate(prop.key)}
                        />
                    );
                }
            }
        }
    };

    return (
        <>
            {mapPropToComponent()}
        </>
    );
}

export default ResourceConfigurationItem;