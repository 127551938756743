import * as constants from "../../constants";
import { getKubeAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types";

export interface DeleteKubernetesStart {
    type: constants.DELETE_KUBERNETES_START;
    kubernetesId: string;
}
export interface DeleteKubernetesSuccess {
    type: constants.DELETE_KUBERNETES_SUCCESS;
    kubernetesId: string;
}
export interface DeleteKubernetesFail {
    type: constants.DELETE_KUBERNETES_FAIL;
    error: ErrorInterface;
}

export type DeleteKubernetes =
    | DeleteKubernetesStart
    | DeleteKubernetesSuccess
    | DeleteKubernetesFail

export function deleteKubernetesStart(kubernetesId: string): DeleteKubernetesStart {
    return {
        type: constants.DELETE_KUBERNETES_START,
        kubernetesId
    };
}

export function deleteKubernetesFail(error: ErrorInterface = errorEmptyObject): DeleteKubernetesFail {
    return {
        type: constants.DELETE_KUBERNETES_FAIL,
        error
    };
}

export function deleteKubernetesSuccess(
    kubernetesId: string,
): DeleteKubernetesSuccess {
    return {
        type: constants.DELETE_KUBERNETES_SUCCESS,
        kubernetesId
    };
}

export function deleteKubernetes(
    projectId: string,
    kubernetesId: string
): ThunkAction<Promise<void>, {}, {}, DeleteKubernetes> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteKubernetes>) => {
        dispatch(deleteKubernetesStart(kubernetesId));
        try {
            await getKubeAPI().deleteKubernetes(kubernetesId, projectId);
            // TO-DO: delete kube mixpanel events
            dispatch(deleteKubernetesSuccess(kubernetesId));
        } catch (error) {
            dispatch(deleteKubernetesFail(error.response.data));
        }
    };
}
