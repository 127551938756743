import * as constants from "../../constants";
import { Project } from "@microtica/ms-project-sdk";

export interface CurrentProjectSelected {
  type: constants.CURRENT_PROJECT_SELECTED;
  project: Project;
}

export function setCurrentProject(project: Project): CurrentProjectSelected {
  localStorage.setItem("project", JSON.stringify(project));
  return {
    type: constants.CURRENT_PROJECT_SELECTED,
    project
  };
}

