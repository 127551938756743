import { calculateHourWithoutTimeZone } from "./cron"

export function calculateUtilization(startScheduleExpression: string, stopScheduleExpression: string, timeZone: string) {
    const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    let [, startHour, , , utilizedDays] = startScheduleExpression.split(" ");
    let [, stopHour] = stopScheduleExpression.split(" ");
  
    utilizedDays = calculateHourWithoutTimeZone(startHour,timeZone,utilizedDays.split(",")).days.join(",");
    startHour = calculateHourWithoutTimeZone(startHour,timeZone,utilizedDays.split(",")).hour;
    stopHour = calculateHourWithoutTimeZone(stopHour,timeZone,utilizedDays.split(",")).hour; 
    
    const utilizedDaysCount = utilizedDays.split(",").length;
    const utilizedHours = (24 - ((parseInt(stopHour) - parseInt(startHour))));
    const totalUtilizedHours = ((utilizedHours * utilizedDaysCount) + (24 * (7 - utilizedDaysCount))) * 4;
    const estimatedSavingPersentage = Math.round((totalUtilizedHours / 730) * 100);

    const dailyUtilizedHours = weekDays.map(day => {
        if (utilizedDays.includes(day)) {
            return utilizedHours;
        } else {
            return 24;
        }
    });

    return {
        totalUtilizedHours,
        estimatedSavingPersentage,
        dailyUtilizedHours
    }
}

export function calculateAggregatedUtilization(schedules: { startScheduleExpression: string, stopScheduleExpression: string, timeZone: string }[]) {

    return schedules.reduce((totalSaving, rule) => {
        const utilization = calculateUtilization(rule.startScheduleExpression, rule.stopScheduleExpression, rule.timeZone);

        return {
            totalUtilizedHours: totalSaving.totalUtilizedHours + (utilization.totalUtilizedHours / schedules.length),
            estimatedSavingPersentage: parseInt((totalSaving.estimatedSavingPersentage + (utilization.estimatedSavingPersentage / schedules.length)).toFixed()),
            dailyUtilizedHours: utilization.dailyUtilizedHours.map((item, index) => {
                return item + (totalSaving.dailyUtilizedHours[index] || 0)
            })
        };
    }, {
        totalUtilizedHours: 0,
        estimatedSavingPersentage: 0,
        dailyUtilizedHours: [] as number[]
    });
}