import React, { useState, useEffect } from "react";
import microticaLogo from "../../static/microtica-logo.png";

interface BuildProcessWaitingProps {
  text: string;
  progress?: number | string;
}

const BuildProcessWaiting = (props: BuildProcessWaitingProps) => {
  const [buildProgress, setbuildProgress] = useState(0);

  useEffect(() => {
    const buildInterval = setInterval(() => {
      if (buildProgress < 100) {
        setbuildProgress(buildProgress + 1);
      } else {
        setbuildProgress(100);
      }
    }, 100)

    return () => { clearInterval(buildInterval) }

  }, [buildProgress])

  return (
    <>
      <div className="page-overlay page-overlay--build"></div>

      <div className="build-process">
        <svg width="130px" height="130px" viewBox="0 0 42 42">
          <defs>
            <linearGradient id="progressGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#2c9392" />
              <stop offset="55%" stopColor="#6ab65c" />
              <stop offset="100%" stopColor="#2c9392" />
            </linearGradient>
          </defs>

          <circle className="donut__hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
          <circle className="donut__ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#101b21" strokeWidth="3"></circle>

          <circle className="donut__segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="url('#progressGradient')" strokeWidth={`${buildProgress === 0 ? '0px' : '3px'}`} stroke-dasharray="70 100" strokeDashoffset="0" strokeLinecap="round"></circle>

        </svg>

        <img src={microticaLogo} alt="microtica Logo"/>

        <p className="txt--white">
          <strong>{props.text}</strong>
        </p>
      </div>
    </>
  )
}

export default BuildProcessWaiting;