
import React, { useState, useEffect } from "react";
import ModalInput from "../../components/ModalInput/ModalInput";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";

// Import AWS & Git logo
import { ReactComponent as KubernetesLogo } from "../../static/kubernetesIcon.svg"
import { addKubernetes, updateKubernetes } from "../../actions";
import { Formik, Form, Field } from "formik";
import { connectKubernetesSchema } from "../../utils/validation";
import { InputField } from "../InputField/InputField";
import { toast } from "react-toastify";
import { CreateKubernetesRequestTypeEnum, ListKubernetesWithDetailsResponseKuberneteses } from "@microtica/ms-kube-sdk";
import useKubernetesesList from "../../hooks/Kubernetes";
import Checkbox from "../Checkbox/Checkbox";

export interface KubernetesModalProps {
    kubernetes?: ListKubernetesWithDetailsResponseKuberneteses;
    onClose: () => void;
}

export default function KubernetesModal(props: KubernetesModalProps) {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [cancelModalFlag, setCanceModalFlag] = useState(false);
    const { kuberneteses, error } = useKubernetesesList();
    const apiTokenValue: string = "(sensitive)";
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [associateNamespace, setAssociateNamespace] = useState<boolean>(false);

    const addKubernetesCluster = (values: { name: string, apiToken: string, endpoint: string, associatedNamespace?: string }) => {
        setIsBusy(true);
        const kubernetes = {
            name: values.name,
            apiToken: values.apiToken,
            endpoint: values.endpoint,
            ...(associateNamespace && { associatedNamespace: values.associatedNamespace })
        };
        if (props.kubernetes) {
            if (values.apiToken.includes(apiTokenValue)) {
                delete kubernetes.apiToken;
            }
            dispatch(updateKubernetes(props.kubernetes.id, currentProject.id, kubernetes))
        } else {
            dispatch(addKubernetes(currentProject.id, {
                ...kubernetes,
                type: CreateKubernetesRequestTypeEnum.Personal
            }));
        }

        setCanceModalFlag(true);
    };

    useEffect(() => {
        if (cancelModalFlag) {
            props.onClose && props.onClose();
        }
    }, [kuberneteses])

    useEffect(() => {
        if (error.message && cancelModalFlag) {
            setIsBusy(false);
            toast.error(error.message)
        }
    }, [error])

    return (
        <ModalInput
            class="modal modal--account"
            name={`${props.kubernetes ? "Update" : "Connect Existing"} Kubernetes`}
            open
            logo={< KubernetesLogo height="80" width="80" />}
            onClose={props.onClose} >
            <Formik
                initialValues={props.kubernetes ? {
                    name: props.kubernetes.name,
                    apiToken: apiTokenValue,
                    endpoint: props.kubernetes.endpoint
                } : {
                    name: "",
                    apiToken: "",
                    endpoint: "",
                    associateNamespace: false,
                    associatedNamespace: ""
                }}
                onSubmit={values => addKubernetesCluster(values)}
                validationSchema={connectKubernetesSchema}
                render={({ setFieldValue }) => (
                    <Form>
                        <div className="box__inputs">
                            <Field
                                name="name"
                                autoComplete="new-password"
                                placeholder="Kubernetes Name"
                                hasError={true}
                                label="Name"
                                type="text"
                                disabled={props.kubernetes}
                                component={InputField}
                            />
                            <Field
                                name="apiToken"
                                placeholder="API token"
                                hasError={true}
                                type="password"
                                label="API token"
                                autoComplete="new-password"
                                component={InputField}
                            />
                            <Field
                                name="endpoint"
                                placeholder="Kubernetes Endpoint"
                                hasError={true}
                                label="Endpoint"
                                component={InputField}
                            />
                            {!props.kubernetes &&
                                <>
                                    <Checkbox
                                        key="associateNamespace"
                                        name="Associate this cluster to a specific namespace"
                                        checked={associateNamespace}
                                        onChange={checked => {
                                            setFieldValue("associateNamespace", checked);
                                            setAssociateNamespace(checked);
                                        }}
                                        description="This Kubernetes cluster will only have access to the specified namespace"
                                    />
                                    {
                                        associateNamespace &&
                                        <Field
                                            name="associatedNamespace"
                                            placeholder="e.g. development"
                                            type="text"
                                            hasError={true}
                                            label="Associated namespace"
                                            component={InputField}
                                        />
                                    }
                                </>
                            }
                        </div>
                        <Button
                            className="btn btn--xl btn--blue"
                            type="submit"
                            isBusy={isBusy}
                            busyText={props.kubernetes ? "Updating.." : "Connecting.."}
                        >
                            {props.kubernetes ? "Update" : "Connect"} Kubernetes
                        </Button>
                    </Form>
                )}
            />

        </ModalInput >
    )
}