import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import { SignUpUserRequest } from "@microtica/ms-usermanagement-sdk";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import awsLogo from "../../static/aws-marketplace-logo.png";
import { ReactComponent as MicroticaLogoBig } from "../../static/microtica-big-logo.svg";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { registerSchema } from "../../utils/validation";
import { InputField } from "../../components/InputField/InputField";
import { getProjectAPI, getUserManagementAPI } from "../../api";
import { toast } from "react-toastify";
import { ReactComponent as NewMail } from "../../static/mail-image.svg";

interface RegisterProps extends RouteComponentProps {
}

const RegisterAWSMarketplace = (props: RegisterProps) => {

  const [step, setStep] = useState(1);
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search);
  const [firstName, setFirstName] = useState("");
  const email = queryParams.get("email") || "";


  // Text to be displayed as activation email
  const [activationEmail, setActivationEmail] = useState("");

  async function handleRegister(
    username: string,
    password: string,
    firstName: string,
    lastName: string
  ) {
    const object: SignUpUserRequest = {
      username,
      password,
      firstName,
      lastName
    }

    try {
      const params = new URLSearchParams(props.location.search);
      const code = params.get("code") || "";

      await getProjectAPI().applyPromoCode(code, username);
      await getUserManagementAPI().signUpUser(object);
      setFirstName(firstName);
      setActivationEmail(username);
      setStep(2);
    } catch (err) {
      await getProjectAPI().releasePromoCode(username);
      toast.error(err.response.data.message);
    }
  }

  const step1 = (
    <div className="container--md container--padding d-flex flex-column align-items-center">
      <div className="mb--20 txt--center logos-container">
        <img className="microtica-text-logo mb--20" src={microticaTextLogo} />
        <span className="mb--20 pr--10" style={{ fontSize: "30px", color: "#007dc5" }}>&</span>
        <img className="appSumo-text-logo mb--20" style={{ width: "220px" }} src={awsLogo} />
      </div>

      <div className="box box--padding-lg mb--40">
        <h5 className="mb--35 txt--center font--roboto">
          Enter your details below to create your account
        </h5>
        <Formik
          initialValues={{
            username: email,
            password: "",
            passwordConfirm: "",
            firstName: "",
            lastName: ""
          }}
          onSubmit={(values) => {
            handleRegister(values.username, values.password, values.firstName, values.lastName);
          }}
          validationSchema={registerSchema}
          render={() => (
            <>
              <Form>
                <Field
                  name="firstName"
                  type="text"
                  icon="name"
                  hasError={true}
                  required
                  placeholder="First name"
                  component={InputField}
                />
                <Field
                  name="lastName"
                  type="text"
                  icon="name"
                  hasError={true}
                  required
                  placeholder="Last name"
                  component={InputField}
                />
                <Field
                  name="username"
                  type="text"
                  icon="email"
                  hasError={true}
                  required
                  placeholder="Email"
                  component={InputField}
                />
                <Field
                  name="password"
                  type="password"
                  hasError={true}
                  required
                  placeholder="Password"
                  component={InputField}
                />
                <Button
                  type="submit"
                  className="btn btn--xl btn--blue"
                  children="Sign Up"
                />
              </Form>
            </>
          )}
        />
      </div>
    </div>
  )

  const step2 = (
    <div className="container--md container--padding d-flex flex-column align-items-center">
      <img className="microtica-text-logo mb--30" src={microticaTextLogo} />
      <NewMail className="mb--30" />
      <h2 className="mb--15">
        <strong>
          Email Verification
        </strong>
      </h2>
      <p className="txt--md txt--white txt--center">
        Hi {firstName} 👋 , thank you for choosing Microtica!
        <br />
        <br />
        You should receive a verification link on <span id="email">{activationEmail}</span>.
        <br />
        Follow the link in the email to complete the registration.
      </p>
    </div>
  );

  return (
    <div className="container--full welcome">
      {step === 1 ? step1 : step2}
      <MicroticaLogoBig className="microtica-big-logo" />
      <Footer />
    </div>
  );
};

export default RegisterAWSMarketplace;
