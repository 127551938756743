import React, { useState } from "react";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Button from "../../components/Button/Button";
import InputText from "../../components/InputText/InputText";

import testImage from "../../static/my-account-profile-test.jpg";

const MyAccount = () => {
  // Change displayed screen
  const [accountScreen, setAccountScreen] = useState("profile");

  // Profile screen
  const profile = (
    <div className="box__content">
      <div className="d-flex justify-content-start align-items-start">
        <span className="box__profile-picture">
          <img src={testImage} alt="test"/>
        </span>

        {/* Input fields */}
        <div className="width--full mb--50">
          <InputText placeholder="Name"    type="text" value="" icon="username" />
          <InputText placeholder="Surname" type="text" value="" icon="username" />
          <InputText placeholder="Email"   type="text" value="" icon="email"    />
        </div>
      </div>
      
      <Button className="btn btn--xl width--full btn--lightBlue">Update Profile</Button>
    </div>
  );

  // Password screen
  const password = (
    <div className="box__content">
      {/* Input fields */}
      <div className="mb--50">
        <InputText placeholder="Old Password" type="password" value="" icon="password" />
        <InputText placeholder="New Password" type="password" value="" icon="password" />
        <InputText placeholder="Repeat New Password" type="password" value="" icon="confirm" />
      </div>

      <Button className="btn btn--xl width--full btn--lightBlue">Update Password</Button>
    </div>
  );

  return (
    <main className="content content--projects d-flex flex-column justify-content-start">
      <PageHeader title="My Account" />

      <div className="content__body">
        <div className="box box--account">
          <div className="d-flex justify-content-start align-items-center">
            {/* Profile Tab */}
            <div 
              className={`box__tab ${accountScreen === "profile" ? "box__tab--selected" : null}`} 
              onClick={() => setAccountScreen("profile")}
            >
              <h5><strong>Profile</strong></h5>
              <p className="m--0">Review or edit your basic profile info</p>
            </div>

            {/* Password Tab */}
            <div 
              className={`box__tab ${accountScreen === "password" ? "box__tab--selected" : null}`} 
              onClick={() => setAccountScreen("password")}
            >
              <h5><strong>Password</strong></h5>
              <p className="m--0">Review or edit your security credentials</p>
            </div>
            
          </div>

          {/* Display content */}
          { accountScreen === "profile" ? profile : password }
        
        </div>
      </div>


    </main>
  )
}

export default MyAccount;