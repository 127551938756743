import React from "react";

const StagesListPlaceholders = () => {
  return (
    <React.Fragment>
      {[...Array(2)].map((_value, index) => (
        <div className="skeleton skeleton__card pl--15 pr--15 pt--5 pb--5" key={index}>
          <div className="card__header-wrapper pl--10 pr--10 pb--5 pt--5">
            <div className="skeleton skeleton__header">
              <h6 className="skeleton skeleton--animation skeleton--placeholder mb--5 mt--5"></h6>
              <p className="skeleton skeleton--animation skeleton--placeholder mb--5 mt--5"></p>
            </div>

            <div className="skeleton skeleton__button skeleton--animation skeleton--placeholder"></div>
          </div>
          <div className="d-flex">
            {[...Array(4)].map((_value, index) => (
              <div className="skeleton skeleton__content skeleton--item" key={index}>
                <div className="skeleton skeleton__circle skeleton--animation skeleton--placeholder"></div>
                <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
                <p className="skeleton skeleton--animation skeleton--placeholder"></p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default StagesListPlaceholders;