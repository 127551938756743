import * as React from 'react';
import Avatar from '../Avatar/Avatar';

export interface Props {
  name: string;
  logo: string
}

function MenuProject(props: Props) {

  return (
    <div className="menubar__header d-flex justify-content-between align-items-center">
      <div className="m--0 d-flex justify-content-center align-items-center">
        <Avatar
          name={props.name}
          size="40"
        />
        <h5 className="ml--10"><strong>{props.name}</strong></h5>
      </div>
      <div className="menubar__trigger">
        <span></span>
        <span></span>
      </div>
    </div>
  );
}


export default MenuProject;
