import React from 'react';

import { Tooltip } from '@material-ui/core';

interface PropsBranches extends React.DOMAttributes<HTMLDivElement> {
    branches: string[]
}

interface PropsFeatures extends React.DOMAttributes<HTMLDivElement> {
    features: string[]
}

interface PropsCommitters extends React.DOMAttributes<HTMLDivElement> {
    committers: { avatar: string, name: string }[]
}

interface PreviousToNextProps {
    previous: { name: string, link?: string };
    next: { name: string, link?: string };
    target?: string
}

interface PropsBody extends React.DOMAttributes<HTMLDivElement> {
    branches?: string[];
    committers?: { avatar: string, name: string }[];
    features?: string[]
}

export function PreviousToNext({ previous, next, target }: PreviousToNextProps) {
    return (
        <div>
            &nbsp;
            <a
                className={previous.link ? "txt--highlight" : ""}
                target={target}
                href={previous.link}
                onClick={e => e.stopPropagation()}
            >
                {previous.name}
            </a>
            <span className="ml--10 mr--10 txt--grey">   -{">"}   </span>
          &nbsp;
            <a
                className={next.link ? "txt--highlight" : ""}
                target={target}
                href={next.link}
                onClick={e => e.stopPropagation()}
            >
                {next.name}
            </a>
        </div>
    )
}

export function DeploymentHistoryBranches({ branches }: PropsBranches) {

    const uniqueBranches = new Set<string>();
    branches.filter(branch => {
        uniqueBranches.add(branch);
    })
    return (
        <div>
            <h5 className="fw--semibold pb--10">Branches</h5>
            <div>
            {
                Array.from(uniqueBranches).join(", ")
            }
            </div>
        </div>
    );
}

export function DeploymentHistoryFeatures({ features }: PropsFeatures) {
    return (
        <div>
            <h5 className="fw--semibold pb--10">Features</h5>
            <div>
                {features.map((feature, index) =>
                    <>
                        <a
                            className="txt--highlight"
                            target="_blank"
                            rel="noreferrer"
                            href={`"https://github.com/microtica-components/component-aws-postgres`}
                            onClick={e => e.stopPropagation()}>
                            {feature}
                        </a>
                        {
                            index < features.length - 1 &&
                            <span>, </span>
                        }
                    </>)}
            </div>
        </div>
    )
}

export function DeploymentHistoryCommitters({ committers }: PropsCommitters) {
    const filteredCommitters: { avatar: string, name: string }[] = [];
    committers.filter(committer => {
        if (!filteredCommitters.find(f => f.name === committer.name ? true : false)) {
            filteredCommitters.push(committer)
        }
    })

    return (
        <div>
            <h5 className="fw--semibold pb--10">Committers</h5>
            {
                filteredCommitters.map((committer, index) => index < 2 &&
                    <div className="pipeline-commit-cell__profile " key={index}>
                        <img src={committer.avatar} alt={committer.name} style={{ borderRadius: "50%", marginBottom: "-3px", marginRight: "3px" }} width="15px" />
                        {committer.name}
                    </div>)}
            <Tooltip
                interactive
                title={
                    filteredCommitters
                        .map((committer, index) => {
                            return (
                                index > 1 &&
                                <div style={{ fontSize: "15px", padding: "10px" }} key={index}>
                                    <img src={committer.avatar} alt={committer.name} style={{ borderRadius: "50%", marginBottom: "-3px", marginRight: "3px" }} width="15px" />
                                    {committer.name}
                                </div>
                            )
                        })
                }>
                {filteredCommitters.length > 2 ?
                    <div className="pipeline-report-cell pointer txt--grey pt--10">
                        {filteredCommitters.length - 2} more committers
                    </div> : <div />
                }
            </Tooltip>
        </div>
    )
}

export function DeploymentHistoryBody({ committers, features, branches }: PropsBody) {
    return (
        branches || features || committers ?
            <div className="row p--15 txt--white">
                {branches &&
                    <div className="col-md-2 col-sm-4">
                        <DeploymentHistoryBranches branches={branches} />
                    </div>
                }
                {features &&
                    <div className="col-md-2 com-sm-4">
                        <DeploymentHistoryFeatures features={features} />
                    </div>
                }
                {committers &&
                    <div className="col-auto">
                        <DeploymentHistoryCommitters committers={committers} />
                    </div>
                }
            </div> : <div />
    )
}