import { trackEvent } from "./index";

export const trackQuickDeploy = (
    envId: string
) => {
    trackEvent("env_deployment_init", {
        envId
    });
};

export const trackCreatePlanInit = (
    envId: string,
) => {
    trackEvent("env_plan_init", {
        envId
    });
};

export const trackCreatePlanSucceeded = (
    envId: string,
) => {
    trackEvent("env_plan_succeeded", {
        envId
    });
};

export const trackCreatePlanFailed = (
    envId: string,
) => {
    trackEvent("env_plan_failed", {
        envId
    });
};

export const trackCancelDeployment = (
    envId: string,
) => {
    trackEvent("env_deployment_canceled", {
        envId
    });
};

export const trackUndeployInit = (
    envId: string,
) => {
    trackEvent("env_undeploy_init", {
        envId
    });
};