import React from "react";

interface ConditionalWrapperProps {
    condition: boolean,
    wrapper: Function,
    children: React.ReactChild | React.ReactChild[]
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) => condition ? wrapper(children) : children;

export default ConditionalWrapper;