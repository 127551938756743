import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps } from 'react-router-dom';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/mode-yaml";
import { useModal } from 'react-modal-hook';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GlobalState } from "../../reducers";
import { getContinuousIntegrationAPI } from '../../api';
import Card from "../../components/Card/Card";
import TabMenuContainer from "../../components/TabMenuContainer/TabMenuContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import ListView from "../../components/ListView/ListView";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import MicroticaStepModal from "../../components/MicroticaStepModal/MicroticaStepModal";
import Button from "../../components/Button/Button";
import { starterYaml } from "../../utils/microtica-steps";
import { ReactComponent as DangerIcon } from "../../static/danger-icon.svg";
import { validateMicroticaYaml } from "../../utils/validate-microtica-yaml";

interface PipelineSpecProps extends RouteComponentProps<{ pipelineId: string }> {
    pipelineId: string,
}

const PipelineSpec = (props: PipelineSpecProps) => {
    const pipelineId = props.match.params.pipelineId;
    const [pipelineSpec, setPipelineSpec] = useState<string>("");
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [editorWidth, setEditorWidth] = useState<number>(0);
    const refParent = useRef<any>();
    const refTabMenu = useRef<any>();
    const refEditor = useRef<any>();
    const [filter, setFilter] = useState<string>("");
    const [numberOfErrors, setNumberOfErrors] = useState<number>(0);
    const [isYamlScreenSelected, setIsYamlScreenSelected] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [errorText, setErrorText] = useState<string>();
    const [validating, setValidating] = useState<boolean>(false);
    const [sourceCode, setSourceCode] = useState<boolean>(true);
    const [timer, setTimer] = useState<any>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const items = [
        {
            itemId: "git-clone",
            title: "Git Clone",
            description: "Clone a Git repository into pipeline runtime environment"
        },
        {
            itemId: "docker-push",
            title: "Docker Push",
            description: "Build and push image to a remote Docker registry"
        },
        {
            itemId: "deploy-kunernetes-service",
            title: "Deploy on Kubernetes",
            description: "Deploy service to a Kubernetes cluster"
        },
        {
            itemId: "cfn-component",
            title: "CloudFormation Component",
            description: "Build Microtica component using your existing CloudFormation templates"
        },
        {
            itemId: "deploy-environment",
            title: "Deploy Environment",
            description: "Trigger new deployment on specific environment"
        },
        {
            itemId: "trigger-pipeline",
            title: "Trigger Pipeline",
            description: "Initiate execution of a pipeline from another pipeline"
        },
        {
            itemId: "terraform-build",
            title: "Terraform Build",
            description: "Build terraform component"
        },
        {
            itemId: "trigger-reflect-test",
            title: "Trigger Reflect.run Test",
            description: "Initiate execution of a specific Reflect.run test"
        }
    ];
    const [microticaSteps, setMicroticaSteps] = useState<any>(items);
    const [stepName, setStepName] = useState<any>("");

    const [showMicroticaStepModal, hideMicroticaStepModal] = useModal(() => (
        <MicroticaStepModal
            stepName={stepName}
            onClose={hideMicroticaStepModal}
        />
    ), [stepName]);

    const handleStepClick = (stepName: string) => {
        setStepName(stepName);
        showMicroticaStepModal();
    }

    function handleTextChange(text: string) {
        let errors: string[] = [];
        setPipelineSpec(text);
        setValidating(true);
        if (timer) {
            clearTimeout(timer); //cancel the previous timer.
            setTimer(null);
        }
        setTimer(setTimeout(
            () => {
                errors = validateMicroticaYaml(text);
                setErrors(errors);
                setNumberOfErrors(errors.length);
                setValidating(false);
            },
            2000
        ));
    }


    async function handleSaveClick() {
        setIsBusy(true);
        try {
            await getContinuousIntegrationAPI().updatePipelineSpec(currentProject.id, pipelineId, { microticaYaml: pipelineSpec });
            toast.success("You have succesfully updated your pipeline specification");
        } catch (error) {
            toast.error("Something went wrong while updating your pipeline specification");
        }
        setIsBusy(false);
    }

    //useEffect hooks
    useEffect(() => {
        if (refParent.current && refTabMenu.current) {
            setEditorWidth(refParent.current.offsetWidth - refTabMenu.current.offsetWidth);
        }
    }, [refParent.current, refTabMenu.current]);

    useEffect(() => {
        if (refParent.current) {
            window.addEventListener('resize', () => setEditorWidth(refParent.current.offsetWidth - refTabMenu.current.offsetWidth));
        }
        // return window.removeEventListener('resize', () => setEditorWidth(refParent.current.offsetWidth - refTabMenu.current.offsetWidth));
    }, []);

    useEffect(() => {
        let text = "";
        if (!isYamlScreenSelected) {
            errors.map((e, i) => {
                if (i != 0) {
                    text += "\n \n"
                } else text += "\n"
                text += `  ${e}`;
            })
            setErrorText(text);
        }
    }, [isYamlScreenSelected])

    useEffect(() => {
        setMicroticaSteps(items.filter(item => {
            if (filter) {
                return item.description
                    .toLocaleLowerCase()
                    .includes(
                        filter.toLocaleLowerCase()
                    );
            } else {
                return true;
            }
        }));
    }, [filter]);

    useEffect(() => {
        const fetch = async () => {
            setIsFetching(true);
            const { data: spec } = await getContinuousIntegrationAPI().getPipelineSpec(currentProject.id, pipelineId);
            setSourceCode(spec.sourceCode);
            if (spec.microticaYaml != "") {
                setPipelineSpec(spec.microticaYaml);
            } else {
                setPipelineSpec(starterYaml);
            }
            setIsFetching(false);
        }
        fetch();
    }, []);

    return (
        <React.Fragment>
            <Card class="card dark card--replicate-main pt--0 pr--0">
                <div ref={refParent} className="d-flex justify-content-between align-items-start pl--10">
                    <AceEditor
                        showPrintMargin={false}
                        className="row yaml-editor"
                        focus
                        showGutter={isYamlScreenSelected}
                        readOnly={!isYamlScreenSelected || sourceCode}
                        width={`${editorWidth}px`}
                        height={"69vh"}
                        mode={isYamlScreenSelected ? "yaml" : ""}
                        theme="solarized_dark"
                        value={isYamlScreenSelected ? isFetching ? " Loading .." : pipelineSpec : errorText}
                        onChange={text => handleTextChange(text)}
                        highlightActiveLine={!sourceCode}
                        fontSize={15}
                        setOptions={{
                            showLineNumbers: isYamlScreenSelected,
                            tabSize: 2
                        }}
                        ref={refEditor}
                    />
                    <div className="row yaml-editor-tabs" style={{ width: editorWidth }} >
                        <div className="col-1"></div>
                        <div className={`col-3 txt--center ${isYamlScreenSelected ? "yaml-editor-activeTab" : ""}`}
                            onClick={() => setIsYamlScreenSelected(true)}
                        >
                            <h6>YAML</h6>
                        </div>
                        <div className={`col-3 txt--center ${!isYamlScreenSelected ? "yaml-editor-activeTab" : ""}`}

                            onClick={() => setIsYamlScreenSelected(false)}
                        > {
                                validating ?
                                    <h6>Validating ...</h6> :
                                    <div>
                                        <DangerIcon className="yaml-editor-icon"></DangerIcon>
                                        <h6>{numberOfErrors} Errors</h6>
                                    </div>
                            }
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4 txt--right">
                            <h6>{isFetching ? "" : sourceCode ? "READ-ONLY" : "EDITABLE"}</h6>
                        </div>
                    </div>

                    <div ref={refTabMenu} className="deployment-menu">
                        <TabMenuContainer
                            class="tab-menu--deployment"
                            tabs={[{ id: "1", label: "Microtica steps" }]}
                        />
                        <SearchBar
                            placeholder="Search steps"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <CustomScrollbars maxHeight={"100%"} customClass="deployment-menu" resetClass="reset--top">
                            <div className="list__container">
                                <ListView
                                    listType="stage"
                                    isLoaded={false}
                                    items={microticaSteps}
                                    onClick={handleStepClick}
                                />
                            </div>
                        </CustomScrollbars>

                    </div>
                </div>
                <div className="row mt--10" style={{ width: editorWidth }}>
                    <div className="col-10"></div>
                    <div className="col-2 txt--right" style={{ left: "25px" }}>
                        <Button
                            className="btn btn--md btn--blue pull--right"
                            disabled={numberOfErrors > 0 || validating || sourceCode}
                            isBusy={isBusy}
                            busyText="Saving.."
                            onClick={handleSaveClick}
                        > Save </Button>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
};
export default PipelineSpec;