import React, { useState, useEffect } from "react";
import CardItem, { CardItemProps } from "../CardItem/CardItem";
import CardItemSkeleton from "../../components/CardItem/CardItemSkeleton";
import Animation from "../Animations/Animations";

export interface CardsViewProps {
  items: CardItemProps[];
  isLoaded?: boolean;
  itemActions?: CardItemAction[];
  onClick?: (itemId: string) => void;
}

interface CardItemAction {
  title: string;
  icon: React.ReactElement;
  onClick: (itemId: string) => void;
}

const CardsView = (props: CardsViewProps) => {
  const [hasItems, setHasItems] = useState(false);

  function handleItemClick(itemId: string) {
    props.onClick && props.onClick(itemId);
  }

  useEffect(() => {
    if (props.items) {
      setHasItems(true);
    } else {
      setHasItems(false);
    }
  })

  return (
    <div className="cards-dashboard d-flex flex-wrap">

      {props.isLoaded ?
        // Render 3 items by default as placeholders
        [...Array(3)].map((_value, index) => (
          <CardItemSkeleton key={index} />
        )) :
        (props.items && props.items.length) ?
          props.items.map((item: CardItemProps, index: number) => (
            <CardItem
              key={item.itemId}
              itemId={item.itemId}
              title={item.title}
              description={item.description}
              type={item.type}
              icon={item.icon}
              footer={item.footer}
              itemActions={(props.itemActions || []).map(action => ({
                itemId: item.itemId,
                ...action
              }))}
              onClick={() => handleItemClick(item.itemId)}
            />
          ))
          : <Animation show={hasItems} type="fade" itemIndex={1}>
              <h5 className="p--20 txt--grey txt--center width--full">No data available</h5>
          </Animation>
      }
    </div>
  );
}

export default CardsView;
