import { trackEvent } from "./index";

type CloudAccountType = "aws" | "gcp" | "azure";

export const trackCreateProject = (
    projectName: string,
    projectId: string
) => {
    trackEvent("project_created", { projectId, projectName });
};

export const trackRemoveProject = (
    projectName: string,
    projectId: string
) => {
    trackEvent("project_removed", { projectId, projectName });
};

export const trackCloudAccountInit = () => {
    trackEvent("cloud_account_init", {});
};

export const trackSpecCloudAccountInit = (
    type: "aws"
) => {
    trackEvent(`${type}_account_init`, {});
};

export const trackGitAccountInit = (
    type: "github" | "bitbucket" | "gitlab"
) => {
    trackEvent(`${type}_account_init`, {});
};

export const trackUserAdded = (
    userEmail: string
) => {
    trackEvent("user_added", {
        userEmail
    });
};

export const trackUserRemoved = (
    userEmail: string
) => {
    trackEvent("user_removed", {
        userEmail
    });
};

export const trackUserInviteResend = (
    userEmail: string
) => {
    trackEvent("user_invite_resend", {
        userEmail
    });
};

export const trackSlackAdded = () => {
    trackEvent("slack_added", {});
};

export const trackSlackRemoved = () => {
    trackEvent("slack_removed", {});
};

export const trackNotificationsSubscribed = () => {
    trackEvent("notifications_subscribed", {});
};

export const trackNotificationsUnsubscribed = () => {
    trackEvent("notifications_unsubscribed", {});
};

export const trackBillingPilot = () => {
    trackEvent("billing_pilot_init", {});
};