import React from "react";

const CardItemSkeleton = () => {
  const skeletonListContent = () => {
    return (
      <div className="card-item">
        <div className="d-flex justify-content-between" style={{ height: "55px" }}>
          <div className="d-flex align-items-center">
            <span className="skeleton skeleton__image skeleton--animation skeleton--placeholder card-icon" style={{ height: "55px" }}></span>
            <div className="d-flex flex-column ml--80">
              <p className="card-text skeleton skeleton--animation skeleton--placeholder position-inherit"></p>
              <p className="mb--0 card-text skeleton skeleton--animation skeleton--placeholder position-inherit"></p>
            </div>
          </div>
        </div>
        <div className="mt--25 mb--25">
          <p className="mb--0 txt--justify card-text skeleton skeleton--animation skeleton--placeholder box"></p>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <p className="mb--0 skeleton skeleton--animation skeleton--placeholder position-inherit"></p>
          </div>
          <div className="d-flex">
            <span className="skeleton skeleton__card-item skeleton__image skeleton--animation skeleton--placeholder position-inherit"></span>
            <span className="skeleton skeleton__card-item skeleton__image skeleton--animation skeleton--placeholder position-inherit ml--10"></span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="skeleton__lists col-4">
      {skeletonListContent()}
    </div>
  )
}

export default CardItemSkeleton;