import React from "react";
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    LineChart,
    Line
} from "recharts";

interface LineChartWidgetProps {
    title: React.ReactElement | string;
    subtitle: React.ReactElement | string;
    value?: React.ReactElement | string;
    valueTitle?: React.ReactElement | string;
    valueSubtitle?: React.ReactElement | string;
    valueSubtitleClassName?: "success" | "fail";
    data: object[];
    xAxisDataKey: string;
    yAxisDataKeys: string[];
    size?: "large" | "small";
}

const colors = [
    "#ED532F",
    "#E68D14",
    "#6D9C29",
    "#C07316",
    "#F5AE2E",
    "#B1593A",
    "#F5F2ED",
    "#08F9FA",
    "#0779D9",
    "#246CC1"
];

const LineChartWidget = (props: LineChartWidgetProps) => {
    const height = props.size == "small" ? 139 : 272;

    function calculateColor(text: any) {
        var sum = 0;
        for (const index in text) {
            sum += text.charCodeAt(index);
        }
        return colors[sum % colors.length];
    }

    return (
        <div className="widget widget-chart">
            <div className="widget-header">
                <div className="widget-title">{props.title}</div>
                <div className="widget-subtitle">{props.subtitle}</div>
            </div>
            <div className="widget-content txt--center">
                {
                    props.value ?
                        <div className="content-header">
                            <div className="header-title">{props.valueTitle}</div>
                            <h2 className="header-value">{props.value}</h2>
                            <div className={`header-subtitle subtitle--${props.valueSubtitleClassName}`}>
                                {props.valueSubtitle}
                            </div>
                        </div>
                        : null
                }
                <div style={{ width: '100%', height: height }}>
                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={300}
                            data={props.data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={props.xAxisDataKey} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {
                                props.yAxisDataKeys.map(key => (
                                    <Line 
                                      key={key}
                                      type="monotone"
                                      dataKey={key}
                                      stroke={calculateColor(key)}
                                      strokeWidth="2" 
                                    />
                                ))
                            }
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
};
export default LineChartWidget;