import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pipeline } from "@microtica/ms-ap-sdk";

import * as actions from "../actions";
import { GlobalState } from "../reducers";

import { PipelineStatusCell, CommitCell, ReportsCell, PipelineTimeCell, PipelineExecutionCell } from "../pages/Pipelines/PipelineListCells";

export default function useBuildList(pipeline: Pipeline, initialFilter: string = "") {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { builds: items, isFetching, error, offset, limit } = useSelector((state: GlobalState) => state.build);
    const [filter, setFilter] = useState(initialFilter);

    useEffect(() => {
        dispatch(actions.fetchBuilds(currentProject.id, pipeline.id, limit, offset));
        // Clear builds list on destroy
        return () => { dispatch(actions.clearBuilds()) }
    }, [dispatch]);

    const builds = items.filter(item => {
        if (filter) {
            return item.id
                .toLocaleLowerCase()
                .includes(
                    filter.toLocaleLowerCase()
                );
        } else {
            return true;
        }
    }).map(build => ([
        <PipelineStatusCell pipeline={{ ...pipeline, latestBuild: build }} />,
        <PipelineExecutionCell pipeline={{ ...pipeline, latestBuild: build }} />,
        <CommitCell pipeline={{ ...pipeline, latestBuild: build }} />,
        <ReportsCell pipeline={{ ...pipeline, latestBuild: build }} />,
        <PipelineTimeCell pipeline={{ ...pipeline, latestBuild: build }} />
    ]));

    return {
        filter,
        setFilter,
        builds,
        isFetching,
        error,
        limit,
        offset
    }
}

