import { trackGitAccountInit } from "../tracking/user-settings";

type WindowConfig = Window & { env: { GITHUB_CLIENT_ID: string; GITLAB_CLIENT_ID: string; BITBUCKET_CLIENT_ID: string; GITLAB_REDIRECT_URL: string; } }

export function handleConnectBitbucket() {
    trackGitAccountInit("bitbucket")
    const bitBucketClientId = (window as unknown as WindowConfig).env.BITBUCKET_CLIENT_ID;
    window.open(`https://bitbucket.org/site/oauth2/authorize?client_id=${bitBucketClientId}&response_type=code&state=redirect:portal`, "_self");
}

export function handleConnectGitHub() {
    trackGitAccountInit("github")
    const gitHubClientId = (window as unknown as WindowConfig).env.GITHUB_CLIENT_ID;
    window.open(`https://github.com/login/oauth/authorize?client_id=${gitHubClientId}&scope=repo&state=redirect:portal`, "_self");
}

export function handleConnectGitLab() {
    trackGitAccountInit("gitlab")
    const gitLabClientId = (window as unknown as WindowConfig).env.GITLAB_CLIENT_ID;
    const gitLabRedirectUrl = (window as unknown as WindowConfig).env.GITLAB_REDIRECT_URL;
    window.open(`https://gitlab.com/oauth/authorize?client_id=${gitLabClientId}&redirect_uri=${gitLabRedirectUrl}&response_type=code&scope=read_repository+api+read_user&state=redirect:portal`, "_self");
}