import React, { useState } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Project } from "@microtica/ms-project-sdk";
import { useModal } from "react-modal-hook";
import { logout } from "../../actions/user/login";
import useOnClickOutside from '../../hooks/clickOutside';

import Avatar from "../Avatar/Avatar";
import projectsIcon from "../../static/projects-icon.svg";
import { GlobalState } from "../../reducers";
import * as actions from "../../actions";
import { useEffect } from "react";

import microticaLogo from "../../static/microtica-logo.png";

import profileImage from "../../static/scientist.svg";
import CreateProjectModal from "../../pages/Projects/CreateProjectModal";
import NotificationsMenu from "../NotificationsMenu/NotificationsMenu";

// Avatar menu icons
import { ReactComponent as HelpIcon } from "../../static/help-icon.svg";
import { ReactComponent as CookieIcon } from "../../static/cookie-bite-solid.svg";
import { ReactComponent as TermsIcon } from "../../static/replicate-icon.svg";
import { ReactComponent as PasswordIcon } from "../../static/password-icon.svg";
import { ReactComponent as LogoutIcon } from "../../static/logout-icon.svg";
import useNotifications from "../../hooks/Notifications";
import NotificationBadge from "../NotificationBadge/NotificationBadge";
import { fetchUserNotifications, fetchProjects } from "../../actions";

const Sidebar = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state: GlobalState) => state.project);
  const { unreadedNotifications, notifications, filter, setFilter } = useNotifications();
  const { profile } = useSelector((state: GlobalState) => state.user);
  const ref = React.useRef(null);

  useEffect(() => {
    if (!projects.length) {
      dispatch(fetchProjects());
    }
  }, [dispatch]);

  const [showModal, hideModal] = useModal(() => (
    <CreateProjectModal
      onClose={hideModal}
    />
  ));

  function handleProjectClick(project: Project) {
    dispatch(actions.setCurrentProject(project));
    if (props.location.pathname.includes("/overview")) {
      window.location.reload();
    }
    else {
      props.history.push("/overview");
    }
  }

  // Display dropdown when clicked on avatar icon
  const [avatarDropdown, setAvatarDropdown] = useState(false)

  const handleAvatarDropdown = () => {
    if (avatarDropdown) {
      setAvatarDropdown(false);
    } else {
      setAvatarDropdown(true);
    }
  }

  // Logout the user
  const userLogout = () => {
    logout();
    window.location.reload();
  }

  // Open / Close notifications sidemenu
  const [notificationsMenu, setNotificationsMenu] = useState(false);

  function setNotifications() {
    dispatch(fetchUserNotifications())
    setNotificationsMenu(true);
  }

  useOnClickOutside(ref, () => setAvatarDropdown(false));

  return (
    <React.Fragment>
      <NotificationsMenu
        history={props.history}
        location={props.location}
        match={props.match}
        projects={projects}
        notifications={notifications}
        filter={filter}
        setFilter={setFilter}
        onClose={() => setNotificationsMenu(false)}
        menuOpen={notificationsMenu}
      />

      <div className="sidebar d-flex flex-column justify-content-between" ref={ref}>
        <div className="sidebar__top">
          <a className="d-block mb--0" href="https://www.microtica.com">
            <img
              className="sidebar__logo d-block"
              src={microticaLogo}
              alt="Microtica"
            />
          </a>
          {projects.slice(0, 4).map(project => (
            <Avatar
              key={project.id}
              name={project.name}
              size="40"
              onClick={() => handleProjectClick(project)}
            />
          ))}

          <div className="avatar avatar--project circle d-flex align-items-center justify-content-center" onClick={showModal}>
            <span><strong>+</strong></span>
          </div>
        </div>

        <div className="sidebar__bottom">
          <div className="sidebar__icons">
            <NavLink to="/projects" exact>
              <img src={projectsIcon} alt="projects"/>
            </NavLink>

            <NotificationBadge
              show={unreadedNotifications > 0}
              numberOfNotifications={unreadedNotifications}
              onClick={() => setNotifications()}
            />
          </div>

          <div style={{ position: "relative" }}>
            <Avatar size="40" src={profileImage} onClick={handleAvatarDropdown} />

            {avatarDropdown ?
              <div className={`avatar__menu ${avatarDropdown ? 'avatar__menu--open' : ''}`}>
                <div className="avatar__header">
                  <h6 className="avatar__title">{
                    profile.firstName ? profile.firstName : ""}  {profile.lastName ? profile.lastName : ""
                    }</h6>
                  <p className="avatar__text m--0">{profile.email}</p>
                </div>

                <ul className="avatar__list">
                  {/* <Link to="/account">
                    <li className="avatar__item">
                      <UserIcon /><strong>My Account</strong>
                    </li>
                  </Link> */}

                  <a href="https://microtica.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">
                    <li className="avatar__item">
                      <HelpIcon /><strong>Help</strong>
                    </li>
                  </a>

                  <a href="https://microtica.com/privacy-policy/" target="_blank" rel="noreferrer">
                    <li className="avatar__item">
                      <PasswordIcon /><strong>Privacy Policy</strong>
                    </li>
                  </a>

                  <a href="https://microtica.com/terms-of-service/" target="_blank" rel="noreferrer">
                    <li className="avatar__item">
                      <TermsIcon /><strong>Terms of Service</strong>
                    </li>
                  </a>

                  <a href="https://microtica.com/cookie-policy/" target="_blank" rel="noreferrer">
                    <li className="avatar__item">
                      <CookieIcon /><strong>Cookie Policy</strong>
                    </li>
                  </a>

                  <li className="avatar__item" onClick={userLogout}><LogoutIcon /><strong>Log Out</strong></li>
                </ul>

              </div>
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Sidebar);
