import * as React from 'react';
import { statusColor } from "../../utils/status-color";

export interface Props {
  name: string;
  status: string;
}


function Badge(props: Props) {
  return (
    <div className={`badge badge--${statusColor(props.status)}`}>{props.name}</div>
  );
}


export default Badge;