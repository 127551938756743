import React from "react";
import { FieldProps } from "formik";
import { ReactComponent as InfoIcon } from "../../static/info-icon.svg";

interface InputAreaFieldProps {
  icon?: string;
  info?: string;
  hasError?: boolean;
  label?: string;
}

export const InputAreaField: React.SFC<FieldProps<any> & InputAreaFieldProps> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];
  const {hasError, ...textAreaProps} = props;

  return (
      <div className={`input ${props.info ? 'd-flex align-items-center' : ''} ${hasError ? 'input--login' : ''}`}>
        {props.label ? <label className="input__label">{props.label}</label> : null }
        <textarea
        {...field}
        {...textAreaProps}
        className={`input__field input--textarea ${errorMsg ? 'input-textarea--error' : ''}`}
        style={{ border: errorMsg ? "1px solid #f84737" : "" }}
        />
        {props.info ? 
          <span className="tooltip" data-tooltip={props.info}>
            <InfoIcon /> 
          </span>
        : null}
        {/* {errorMsg ? <ExclamationIcon /> : null} */}
        {errorMsg ? <span className="input__field--error-msg">{errorMsg}</span> : null}
      </div>
  );
};
