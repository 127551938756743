import * as React from "react"
import moment from "moment";
import { statusColor } from "../../utils/status-color";

export interface TimelineItemsProps {
  projectId: string;
  itemId: string;
  name: string;
  type: string;
  status: string;
  timestamp: string;
  branch?: string;
  stageName?: string;
  pipelineId?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function TimelineItem(props: TimelineItemsProps) {
  return (
    <div className="timeline__item d-flex" style={{ cursor: "pointer" }} onClick={props.onClick} >
      <div className={`circle circle--xs circle--${statusColor(props.status)} timeline__circle`}></div>
      <div className="timeline__info">
        <h5>{props.name}</h5>
        <p>{props.type} {props.status} - {moment(props.timestamp, "x").fromNow()}</p>
      </div>
    </div >
  )
}

export default TimelineItem;