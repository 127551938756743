import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { useModal } from "react-modal-hook";
import Skeleton from "react-loading-skeleton";
import { Pipeline } from "@microtica/ms-ap-sdk";

import { GlobalState } from "../../reducers";
import { getContinuousIntegrationAPI, getProjectAPI } from '../../api';
import * as actions from "../../actions";
import usePipelineList from "../../hooks/PipelineList";
import usePipelineMetrics from "../../hooks/PipelineMetrics";

import SearchBar from "../../components/SearchBar/SearchBar";
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import DropdownMenuContainer, { DropdownMenuItemProps } from "../../components/DropdownMenuContainer/DropdownMenuContainer";
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import Button from '../../components/Button/Button';
import StageStatus from "../../components/StageStatus/StageStatus";

import { PipelineStatusCell, CommitCell, PipelineCell, PipelineTimeCell, ReportsCell } from "./PipelineListCells";
import CardPlaceholders from "../../components/Card/CardPlaceholders";

import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as EditIcon } from "../../static/pen-icon.svg";
import { ReactComponent as ClockIcon } from "../../static/clock-icon.svg";
import { ReactComponent as ErrorIcon } from "../../static/error-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../static/check-icon.svg";
import { ReactComponent as StartIcon } from "../../static/start-icon.svg";
import PipelineScreen from "../../static/pipeline-manager.png"

const tableHeaders = [
  "Pipeline:left",
  "Status",
  "Commit:left",
  "Reports",
  "",
  "",
  ""
];

const PipelinesList = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { isFetching, error, pipelines, polling, disabledPipelineManager } = usePipelineList();
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [filter, setFilter] = useState<any>();
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const { isLoaded, pipelineMetrics } = usePipelineMetrics();

  const [buildLimitation, setBuildLimitation] = useState<number>();
  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <ModalDanger
      title={`Are you sure you want to delete "${selectedPipeline!.name}" pipeline?`}
      description="Do you really want to delete the pipeline. This action cannot be undone."
      action="Delete"
      onCancel={hideDeleteModal}
      onConfirm={handleDeleteConfirmation}
    />
  ), [selectedPipeline]);

  function selectPipeline(itemId: string) {
    const selectedPipeline = pipelines.find(p => p.id === itemId);
    setSelectedPipeline(selectedPipeline);
  }

  function handleDeleteConfirmation() {
    dispatch(actions.deletePipeline(currentProject.id, selectedPipeline!.id));
    hideDeleteModal();
  }

  function handlePipelineDelete(itemId: string) {
    selectPipeline(itemId);
    showDeleteModal();
  }

  function handlePipelineEdit(itemId: string) {
    props.history.push(`/pipelines/${itemId}/edit`);
  }

  function getItemActions(itemId: string) {

    return [
      {
        itemId: itemId,
        title: "Edit",
        icon: <EditIcon />,
        onClick: () => handlePipelineEdit(itemId)
      },
      {
        itemId: itemId,
        title: "Delete",
        icon: <RemoveIcon />,
        onClick: () => handlePipelineDelete(itemId)
      }
    ]
  }

  async function handlePipelineTrigger(pipelineId: string, ref: string) {
    try {
      const { data: build } = await getContinuousIntegrationAPI().triggerPipeline(currentProject.id, pipelineId, { ref });
      props.history.push(`/pipelines/${pipelineId}/builds/${build.buildId}`);
    } catch (err: any) {
      toast.error(err.response.data.message)
    }
  }


  useEffect(() => {
    const fetch = async () => {
      const { data: { buildMinutes } } = await getProjectAPI().getProjectBuildLimitations(currentProject.id);
      setBuildLimitation(buildMinutes)
    }
    fetch();
  }, []);


  useEffect(() => {
    if (error.code && !error.message) {
      toast.configure({
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      toast.info(`Something went wrong! Error: ${error.code}`)
    }
    if (error.code !== 402 && error.message && error.message !== "") toast.error(error.message)
  }, [error]);

  useEffect(() => {
    setFilteredItems(pipelines.filter(item => {
      if (filter) {
        return item.name
          .toLocaleLowerCase()
          .includes(
            filter.toLocaleLowerCase()
          );
      } else {
        return true;
      }
    }).sort((a, b) => {
      if (!a.latestBuild && !b.latestBuild) {
        return 0;
      }
      if (!a.latestBuild) {
        return 1;
      }
      if (!b.latestBuild) {
        return -1;
      }
      if (!a.latestBuild.stopDate) {
        return -1;
      }
      if (!b.latestBuild.stopDate) {
        return 1;
      }
      if (a.latestBuild.stopDate > b.latestBuild.stopDate) {
        return -1;
      }
      if (a.latestBuild.stopDate < b.latestBuild.stopDate) {
        return 1;
      }
      return 0;
    })
      .map((pipeline: Pipeline) => ([

        <PipelineCell pipeline={pipeline} />,

        <PipelineStatusCell pipeline={pipeline} />,

        <CommitCell pipeline={pipeline} />,

        <ReportsCell pipeline={pipeline} />,

        <PipelineTimeCell pipeline={pipeline} />,
        <React.Fragment key={pipeline.id}>
          <DropdownMenuContainer
            key={pipeline.id}
            actions={[]}
            title="Run from branch:"
            icon={<RemoveIcon />}
            onClick={async () => {
              const { data: { branches } } = await getContinuousIntegrationAPI().listGitAccountRepositoryBranches(
                currentProject.id,
                pipeline.gitAccountId!,
                pipeline.repositoryUrl!
              );
              return branches.map(branch => ({
                itemId: branch,
                title: branch,
                onClick: () => handlePipelineTrigger(pipeline.id, `refs/heads/${branch}`)
              })) as DropdownMenuItemProps[];
            }}
            type="play"
          />
          <p>Run</p>
        </React.Fragment>,
        <DropdownMenuContainer
          actions={getItemActions(pipeline.id)}
          onClick={async () => getItemActions(pipeline.id)}
        />
      ])));
  }, [pipelines, filter]);

  const EmptyBox = () => (
    <div className="page-centered page-centered--project" style={{ paddingTop: "5%" }}>
      <img src={PipelineScreen} alt="pipeline" />
      <div className="page-centered__title">Pipeline Manager</div>
      <p className="txt--sm">
        Use pipelines to automate the process of building, testing and deploying your infrastructure and apps from source code to production.
        <br />
        Define pipelines in your preferable way using our UI editor or using&nbsp;
        <a
          href="https://microtica.com/docs/pipeline-definition"
          target="_blank"
          rel="noreferrer"
        >
          microtica.yaml
        </a>
        &nbsp;file under your source code.

        <br></br>
        <br></br>
        <a href="https://microtica.com/docs/pipeline-introduction/"
          target="_blank"
          rel="noreferrer">
          Read more about Pipelines
        </a>
      </p>
      {disabledPipelineManager ?
        < Link className="btn btn--xl" to="/settings?tab=billing">
          Upgrade Plan
        </Link> : <Link className="btn btn--xl btn--green" to="pipelines/create">Create Pipeline</Link>
      }
    </div>
  );

  const Placeholders = () => (
    <Card class="card card--stages dark">
      <div className="skeleton skeleton__header">
        <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
        <p className="skeleton skeleton--animation skeleton--placeholder"></p>
      </div>

      <CardPlaceholders />
    </Card>
  );

  const createPipelineButton = <Link key="createPipeline" className="btn btn--md btn--link btn--blue m--0"
    to="pipelines/create">
    <Button
      className="btn btn--md btn--link btn--blue m--0"
      disabled={disabledPipelineManager}
    >
      Create Pipeline
    </Button>
  </Link>;

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader title="Pipelines" items={[createPipelineButton]} />
      <div className="content__body pb--0">
        {
          pipelines.length > 0 || !isFetching ?
            disabledPipelineManager || !pipelines.length ? <EmptyBox />
              :
              <CustomScrollbars maxHeight="calc(100vh - 120px)">
                <div className="details__status">
                  <StageStatus
                    class="stages__info-wrapper-"
                    items={[
                      {
                        id: 1,
                        title: "Successful builds",
                        icon: <CheckmarkIcon />,
                        text: isLoaded ? `${pipelineMetrics!.succesfullExecutions}` : <Skeleton width="70px" />
                      },
                      {
                        id: 2,
                        title: "Failed builds",
                        icon: <ErrorIcon />,
                        text: isLoaded ? `${pipelineMetrics!.failedExecutions}` : <Skeleton width="70px" />
                      },

                      {
                        id: 3,
                        title: "Success rate",
                        icon: <StartIcon />,
                        text: isLoaded ? `${pipelineMetrics!.successRate.toFixed(2)} %`
                          : <Skeleton width="70px" />,
                      },

                      {
                        id: 4,
                        title: "Build minutes spent",
                        icon: <ClockIcon />,
                        text: isLoaded ? `${pipelineMetrics!.buildTime!.toFixed(2)} / ${buildLimitation!} mins` : <Skeleton width="70px" />,
                      },

                    ]}
                  />
                </div>
                <br />
                <Card class="card card--vertical card--pipelines dark">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="searchbar-wrapper">
                      <SearchBar
                        placeholder="Search pipelines"
                        value={filter}
                        onChange={(event) => setFilter(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="table-wrapper-sticky">
                    <Table
                      isLoaded={pipelines.length > 0 || !isFetching || polling}
                      headers={tableHeaders}
                      rows={filteredItems}
                      tableModifier="pipelines"
                    />
                  </div>
                </Card>
              </CustomScrollbars>
            : <Placeholders />
        }
      </div>

    </main>
  )
};
export default PipelinesList;