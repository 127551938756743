import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";
import gcpRegions from "../utils/gcp-regions";
import gcpZones from "../utils/gcp-zones";

export default function useGcpAccountList() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { gcpAccounts, isFetching, error, isFinished } = useSelector((state: GlobalState) => state.gcpAccount);

    // Get a list of GCP accounts from the backend
    useEffect(() => {
        dispatch(actions.fetchGcpAccounts(currentProject.id));
    }, [dispatch]);

    return {
        gcpAccounts,
        gcpRegions,
        gcpZones,
        isFetching,
        isFinished,
        error
    };
}