import React, { useState, useEffect } from "react";

// Import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Card from "../../components/Card/Card";

// Import different tabs
import GitAccounts from "./GitAccounts";
import Management from "./Management";
import Billing from "./Billing";
import CloudAccounts from "./CloudAccounts";
import Notifications from "./Notifications";
import { RouteComponentProps, } from "react-router";

const Settings = (props: RouteComponentProps) => {
  // Set active link and screen
  const params = new URLSearchParams(props.location.search);
  const [selectedScreen, setSelectedScreen] = useState(params.get("tab") || "git");

  // Update the selected screen to display different content (AWS, Git account, Management & Notifications)
  useEffect(() => {
    const headerItems = document.querySelectorAll(".settings-tab");
    headerItems.forEach(item => item.addEventListener("click", e => {
      const screen = item.getAttribute("data-screen") as string;
      setSelectedScreen(screen);
      props.history.replace(`/settings?tab=${screen}`);
    }))
  }, [])

  // Handle underline
  const [underline, setUnderline] = useState({
    left: 0,
    width: 0,
    opacity: 1,
    height: "4px"
  })

  const handleUnderline = (e: any) => {
    const elements = document.querySelectorAll(".settings-tab");
    const currentIndex = parseInt(e.currentTarget.dataset.index);
    const settingsTabs = [] as any;
    let elementWidth: number;

    elements.forEach((element) => {
      let elementProperties = element.getBoundingClientRect();
      elementWidth = elementProperties.width;

      settingsTabs.push({ elementWidth });
    })

    let underlineLeftOfsset = 0;
    elements.forEach((_element, index) => {
      if (currentIndex > index) {
        underlineLeftOfsset += settingsTabs[index].elementWidth;
      }
    })

    let left = currentIndex === 0 ? 0 : underlineLeftOfsset;
    let width = settingsTabs[currentIndex].elementWidth;

    setUnderline({
      left: left,
      width: width,
      opacity: 1,
      height: "4px"
    })
  }

  useEffect(() => {
    // When you are on settings page and you click "Settings on the sidebar"
    if (params.get("tab") && (params.get("tab") !== selectedScreen)) {
      setSelectedScreen(params.get("tab")!);
    }

  }, [props.location.search])

  // Remove tab menu underline when not hovering over it
  const removeUnderline = () => {
    setUnderline({
      ...underline,
      opacity: 0,
      height: "0px"
    })
  }

  return (
    <main className="content d-flex flex-column justify-content-start">
      <PageHeader title="Settings" />

      <div className="content__body pb--0">

        <Card class="card card--settings dark">
          <div className="card__header-wrapper card__header-wrapper--replicate card--underline d-flex justify-content-between align-items-center">

            <div className="row no-gutters width--full pl--15">

              <div
                className={`col-2 settings-tab ${selectedScreen === "accounts" ? 'active' : ''}`}
                data-index="0"
                onMouseEnter={e => handleUnderline(e)}
                onMouseLeave={() => removeUnderline()}
                data-screen="accounts"
              >
                <h5 className="mb--5"><strong>Cloud Accounts</strong></h5>
                <p className="m--0">Connect Cloud accounts</p>
              </div>

              <div className={`col-2 settings-tab ${selectedScreen === "git" ? 'active' : ''}`}
                data-index="1"
                onMouseEnter={e => handleUnderline(e)}
                onMouseLeave={() => removeUnderline()}
                data-screen="git"
              >
                <h5 className="mb--5"><strong>Git Accounts</strong></h5>
                <p className="m--0">Connect and manage Git accounts</p>
              </div>

              <div
                className={`col-3 settings-tab ${selectedScreen === "management" ? 'active' : ''}`}
                data-screen="management"
                data-index="2"
                onMouseEnter={e => handleUnderline(e)}
                onMouseLeave={() => removeUnderline()}
              >
                <h5 className="mb--5"><strong>Access Management</strong></h5>
                <p className="m--0">Invite users and manage permissions</p>
              </div>

        

              <div
                className={`col-3 settings-tab ${selectedScreen === "notifications" ? 'active' : ''}`}
                data-screen="notifications"
                data-index="3"
                onMouseEnter={e => handleUnderline(e)}
                onMouseLeave={() => removeUnderline()}
              >
                <h5 className="mb--5"><strong>Notifications</strong></h5>
                <p className="m--0">Configure notification preferences</p>
              </div>

              <div
                className={`col-2 settings-tab ${selectedScreen === "billing" ? 'active' : ''}`}
                data-screen="billing"
                data-index="4"
                onMouseEnter={e => handleUnderline(e)}
                onMouseLeave={() => removeUnderline()}
              >
                <h5 className="mb--5"><strong>Billing</strong></h5>
                <p className="m--0">Review or edit your billing preferences</p>
              </div>

            </div>


            <div className="underline" style={{
              height: underline.height,
              opacity: underline.opacity,
              left: underline.left,
              width: underline.width,
            }}></div>
          </div>

          {/* DISPLAY CONTENT BASED ON SELECTED TAB */}
          {selectedScreen === "accounts" ? <CloudAccounts /> :
            selectedScreen === "git" ? <GitAccounts {...props} /> :
              selectedScreen === "management" ? <Management /> :
                selectedScreen === "billing" ? <Billing /> : <Notifications />
          }
        </Card>

      </div>

    </main>
  )
}

export default Settings;