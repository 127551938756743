import * as constants from "../../../constants";
import { getContinuousIntegrationAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../../types/index";

// Create microservice
export interface ConnectGitAccountStart {
    type: constants.CONNECT_GIT_ACCOUNT_START;
}
export interface ConnectGitAccountSuccess {
    type: constants.CONNECT_GIT_ACCOUNT_SUCCESS;
    gitAccountId: string;
}
export interface ConnectGitAccountError {
    type: constants.CONNECT_GIT_ACCOUNT_FAIL;
    error: ErrorInterface;
}


export interface ConnectGitAccountClear {
    type: constants.CONNECT_GIT_ACCOUNT_CLEAR;
}
export type ConnectGitAccount =
    | ConnectGitAccountStart
    | ConnectGitAccountSuccess
    | ConnectGitAccountError
    | ConnectGitAccountClear

export function connectGitAccountStart(): ConnectGitAccount {
    return {
        type: constants.CONNECT_GIT_ACCOUNT_START
    };
}

export function connectGitAccountClear(): ConnectGitAccount {
    return {
        type: constants.CONNECT_GIT_ACCOUNT_CLEAR
    };
}


export function connectGitAccountError(error: ErrorInterface): ConnectGitAccount {
    return {
        type: constants.CONNECT_GIT_ACCOUNT_FAIL,
        error
    };
}

export function connectGitAccountSuccess(
    gitAccountId: string
): ConnectGitAccount {
    return {
        type: constants.CONNECT_GIT_ACCOUNT_SUCCESS,
        gitAccountId
    };
}

export function connectGitAccount(
    projectId: string,
    object: { code: string, type: "github" | "bitbucket" | "gitlab" }
): ThunkAction<Promise<void>, {}, {}, ConnectGitAccount> {
    return async (dispatch: ThunkDispatch<{}, {}, ConnectGitAccount>) => {
        dispatch(connectGitAccountStart());
        try {
            const { data: res } = await getContinuousIntegrationAPI().initiateOAuth(projectId, object.code, object.type);
            dispatch(connectGitAccountSuccess(res.gitAccountId));

        } catch (error) {
            dispatch(connectGitAccountError(error.response.data));
        }
    };
}