import { errorEmptyObject, KubernetesState } from '../types/index';
import * as constants from '../constants';
import { FetchKuberneteses, AddKubernetes, DeleteKubernetes, UpdateKubernetes } from '../actions';
import { ListKubernetesWithDetailsResponseKubernetesesTypeEnum } from '@microtica/ms-kube-sdk';


export const initialState: KubernetesState = {
    kuberneteses: [],
    isProcessing: false,
    error: errorEmptyObject
}
export default function reducer(state: KubernetesState = initialState, action: FetchKuberneteses | AddKubernetes | DeleteKubernetes | UpdateKubernetes): KubernetesState {
    switch (action.type) {

        case constants.FETCH_KUBERNETESES_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.FETCH_KUBERNETESES_SUCCESS: return { ...state, kuberneteses: action.kuberneteses, isProcessing: false, error: errorEmptyObject };
        case constants.FETCH_KUBERNETESES_FAIL: return { ...state, error: action.error, isProcessing: false };

        case constants.DELETE_KUBERNETES_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.DELETE_KUBERNETES_SUCCESS: return {
            ...state, isProcessing: false, error: errorEmptyObject,
            kuberneteses: state.kuberneteses.filter(kube => kube.id !== action.kubernetesId)
        };
        case constants.DELETE_KUBERNETES_FAIL: return { ...state, isProcessing: false, error: action.error };

        case constants.ADD_KUBERNETES_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.ADD_KUBERNETES_SUCCESS:
            return {
                ...state,
                kuberneteses: [
                    ...state.kuberneteses,
                    { ...action.data, projectId: action.projectId, id: action.data.name, type: action.data.type as unknown as ListKubernetesWithDetailsResponseKubernetesesTypeEnum }
                ],
                isProcessing: false,
                error: errorEmptyObject,

            };
        case constants.ADD_KUBERNETES_FAIL: return { ...state, error: action.error, isProcessing: false };

        case constants.UPDATE_KUBERNETES_START: return { ...state, isProcessing: true, error: errorEmptyObject };
        case constants.UPDATE_KUBERNETES_SUCCESS:
            const updatedKuberneteses = state.kuberneteses.map(kube => {
                if (kube.id === action.kubernetesId) {
                    return {
                        ...kube,
                        name: action.object.name ? action.object.name : kube.name,
                        endpoint: action.object.endpoint ? action.object.endpoint : kube.endpoint,
                        apiToken: action.object.apiToken ? action.object.apiToken : null
                    }
                }
                return kube;
            });

            return {
                ...state,
                kuberneteses: updatedKuberneteses,
                isProcessing: false,
                error: errorEmptyObject
            };
        case constants.UPDATE_KUBERNETES_FAIL: return { ...state, isProcessing: false, error: action.error };

        default: {
            return state;
        }
    }
}
