import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useModal } from 'react-modal-hook';
import { toast } from "react-toastify";

import { GlobalState } from "../../reducers";

// Import icons for DropdownMenuContainer
import { ReactComponent as RemoveIcon } from "../../static/remove-icon.svg";
import { ReactComponent as EditIcon } from "../../static/pen-icon.svg";
import DockerScreen from "../../static/docker-registries.png";

import PageHeader from '../../components/PageHeader/PageHeader';
import CustomScrollbars from '../../components/CustomScrollbars/CustomScrollbars';
import ListView from "../../components/ListView/ListView";
import Button from '../../components/Button/Button';
import ModalDanger from "../../components/ModalDanger/ModalDanger";
import ListItemSkeleton from "../../components/ListItem/ListItemSkeleton";

import useDockerRegistriesList from '../../hooks/DockerRegistries';
import * as actions from "../../actions";
import { trackRegistryCreateInit } from '../../tracking/kubernetes';

const DockerRegistriesList = (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const [selectedRegistry, setSelectedRegistry] = useState<any>();
    const { dockerRegistriesList: registries, isProcessing, error } = useDockerRegistriesList();

    const addRegistry = <Button
        key="addRegistry"
        className="btn btn--md btn--link btn--blue"
        onClick={() => {
            trackRegistryCreateInit()
            props.history.push(`/kubernetes/docker-registries/add`)
        }}>Add Registry</Button>


    useEffect(() => {
        if (error.message && error.message !== "") {
            toast.error(error.message);
        }
    }, [error]);

    const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
        <ModalDanger
            title={`Are you sure you want to delete "${selectedRegistry!.itemId}"?`}
            description="Do you really want to delete the registry? This action cannot be undone."
            action="Delete"
            onCancel={hideConfirmationModal}
            onConfirm={handleDeleteConfirmation}
        />
    ), [selectedRegistry]);

    function handleDockerRegistryDelete(itemId: string) {
        const dockerRegistry = registries.find(r => r.itemId === itemId);
        setSelectedRegistry(dockerRegistry!);
        showConfirmationModal();
    }

    async function handleDeleteConfirmation() {
        dispatch(actions.deleteDockerRegistry(currentProject.id, selectedRegistry.itemId));
        hideConfirmationModal();
    }

    function handleDockerRegistryEdit(itemId: string) {
        props.history.push(`/kubernetes/docker-registries/${itemId}/edit`);
    }


    const EmptyBox = () => (
        <div className="page-centered page-centered--project" style={{ marginTop: "10%" }}>
            <img src={DockerScreen} alt="docker"/>
            <div className="page-centered__title">Remote Docker Registries</div>
            <p className="txt--sm">
                Store your Docker images on your prefered container registry and retain a full control over access and securty.
                <br />
                You can build and push Docker images using pipelines.
                <br></br>
                <br></br>
                <a href="https://microtica.com/docs/pipeline-artifacts/#docker-artifacts"
                    target="_blank"
                    rel="noreferrer">
                    Read more about Docker Artifacts
                </a>

            </p>
            <Link className="btn btn--xl btn--green" to="docker-registries/add">Add Docker Registry</Link>
        </div>
    );

    const itemActions = [
        {
            title: "Edit",
            icon: <EditIcon />,
            onClick: handleDockerRegistryEdit
        },
        {
            title: "Delete",
            icon: <RemoveIcon />,
            type:"separator",
            onClick: handleDockerRegistryDelete
        }
    ]
    const showRegistries = (
        <CustomScrollbars maxHeight="calc(100vh - 220px)" resetClass="reset--top">
            {
                registries && registries.length > 0 ?
                    <ListView
                        class="list--height list--settings pr--20"
                        listType="management"
                        itemActions={itemActions}
                        items={registries}
                    />
                    : registries && registries.length === 0 ?
                        <EmptyBox />
                        : null
            }
        </CustomScrollbars >
    );

    return (
        <main className="content d-flex flex-column justify-content-start">
            <PageHeader title="Docker Registries" items={[addRegistry]} />
            <div className="content__body">
                <React.Fragment>
                    {!isProcessing ?
                        showRegistries :
                        [...Array(3)].map((_value, index) => (<ListItemSkeleton type="management" key={index}/>))
                    }
                </React.Fragment>
            </div>
        </main>
    )
}

export default DockerRegistriesList;