import * as constants from "../../constants";
import { getContinuousIntegrationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../types";


export interface DeleteDockerRegistryStart {
    type: constants.DELETE_DOCKER_REGISTRY_START;
    registryName: string;
}
export interface DeleteDockerRegistrySuccess {
    type: constants.DELETE_DOCKER_REGISTRY_SUCCESS;
    registryName: string;
}
export interface DeleteDockerRegistryFail {
    type: constants.DELETE_DOCKER_REGISTRY_FAIL;
    error: ErrorInterface;
}

export type DeleteDockerRegistry =
    | DeleteDockerRegistryStart
    | DeleteDockerRegistrySuccess
    | DeleteDockerRegistryFail

export function deleteDockerRegistryStart(registryName: string): DeleteDockerRegistryStart {
    return {
        type: constants.DELETE_DOCKER_REGISTRY_START,
        registryName
    };
}

export function deleteDockerRegistryFail(error: ErrorInterface = errorEmptyObject): DeleteDockerRegistryFail {
    return {
        type: constants.DELETE_DOCKER_REGISTRY_FAIL,
        error
    };
}

export function deleteDockerRegistrySuccess(
    registryName: string,
): DeleteDockerRegistrySuccess {
    return {
        type: constants.DELETE_DOCKER_REGISTRY_SUCCESS,
        registryName
    };
}



export function deleteDockerRegistry(
    projectId: string,
    registryName: string
): ThunkAction<Promise<void>, {}, {}, DeleteDockerRegistry> {
    return async (dispatch: ThunkDispatch<{}, {}, DeleteDockerRegistry>) => {
        dispatch(deleteDockerRegistryStart(registryName));
        try {
            await getContinuousIntegrationAPI().deleteDockerRegistry(projectId, registryName);
            dispatch(deleteDockerRegistrySuccess(registryName));
        } catch (error) {
            dispatch(deleteDockerRegistryFail(error.response.data));
        }
    };
}
