import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import { SignUpUserRequest } from "@microtica/ms-usermanagement-sdk";

// Import images
import microticaTextLogo from "../../static/microtica-text-logo.svg";
import { ReactComponent as MicroticaLogoBig } from "../../static/microtica-big-logo.svg";
import { Link, RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { registerSchema } from "../../utils/validation";
import { InputField } from "../../components/InputField/InputField";
import { getUserManagementAPI } from "../../api";
import { toast } from "react-toastify";
import { ReactComponent as NewMail } from "../../static/mail-image.svg";
import { ReactComponent as MailConfirmed } from "../../static/email-confirmed.svg";
import { handleGitHubSignIn } from "../../utils/oidc";
import { ReactComponent as GithubLogo } from "../../static/github-logo.svg";


interface RegisterProps extends RouteComponentProps {
}

const Register = (props: RegisterProps) => {

  const [step, setStep] = useState(1);
  const queryParams = new URLSearchParams(props.location.search);
  const email = queryParams.get("email") || "";

  // Text to be displayed as activation email
  const [activationEmail, setActivationEmail] = useState("");

  async function handleRegister(
    username: string,
    password: string) {
    const object: SignUpUserRequest = {
      username,
      password
    }

    try {
      await getUserManagementAPI().signUpUser(object);
      // Update text that will be displayed for activation email
      setActivationEmail(username);
      setStep(2);
    } catch (err) {
      toast.error(err.response.data.message)
    }
  }

  const step1 = (
    <div className="container--md container--padding d-flex flex-column align-items-center">
      <div className="mb--20 txt--center">
        <img className="microtica-text-logo mb--20" src={microticaTextLogo} alt="microtica" />
      </div>

      <div className="box box--padding-lg mb--40">
        <h5 className="mb--35 txt--center font--roboto">
          <strong>A new developer self-service experience</strong>
        </h5>
        <h6 style={{ textAlign: "center" }}>
          <strong>
            Microtica 2.0 Beta is here!
          </strong>
          <br />
          <br />
          <strong>
            A new version of our portal is available and it`s a major one. New design, more ready-to-use templates, Git-push deployments, inline configuration, automated rollbacks, built-in security, real-time monitoring.
          </strong>
          <br />
          <br />
          <strong>
            Access the self-service portal now 👇
          </strong>
          <br />
          <br />
          <br />
          <a href="https://app.microtica.com/auth/signup" className="btn btn--xl btn--blue">
            Discover Microtica 2.0
          </a>
        </h6>
        {/* <h5 className="mb--35 txt--center font--roboto">
          <strong>Enter your details below to create your account</strong>
        </h5>
        <Formik
          initialValues={{ username: email, password: "", passwordConfirm: "" }}
          onSubmit={(values) => {
            handleRegister(values.username, values.password);
          }}
          validationSchema={registerSchema}
          render={() => (
            <>
              <Form>
                <Field
                  name="username"
                  type="text"
                  icon="email"
                  hasError={true}
                  required
                  placeholder="Email"
                  component={InputField}
                />
                <Field
                  name="password"
                  type="password"
                  hasError={true}
                  required
                  placeholder="Password"
                  component={InputField}
                />
                <Button
                  type="submit"
                  className="btn btn--xl btn--blue"
                  children="Sign Up"
                />
              </Form>
              <br />
              <h6 className="mb--20 txt--center font--roboto">
                <strong>- or you can sign up using -</strong>
              </h6>
              <Button
                className="btn btn--xl btn--git-hub"
                onClick={handleGitHubSignIn}
              >
                <GithubLogo width="25px" height="25px" /> &nbsp; GitHub
              </Button>
            </>
          )}
        /> */}
      </div>

      <p className="txt--md txt--white font--roboto">
        Already have an account?{" "}
        <Link to="/login" className="txt--sm txt--highlight font--roboto">
          Log In
        </Link>
      </p>
    </div>
  )



  const step2 = (
    <div className="container--md container--padding d-flex flex-column align-items-center">
      <img className="microtica-text-logo mb--30" src={microticaTextLogo} alt="microtica" />
      <NewMail className="mb--30" />
      <h2 className="mb--15">
        <strong>
          Email Verification
        </strong>
      </h2>
      <p className="txt--md txt--white txt--center">
        Hi there 👋 , thank you for choosing Microtica!
        <br />
        <br />
        You should receive a verification link on <span id="email">{activationEmail}</span>.
        <br />
        Follow the link in the email to complete the registration.
      </p>
    </div>
  );

  // Email confirmed => step3 ?
  const step3 = (
    <div className="container--md container--padding d-flex flex-column align-items-center mail-confirmed">
      <img className="microtica-text-logo mb--30" src={microticaTextLogo} alt="microtica" />
      <MailConfirmed className="mb--40" />
      <h1 className="mb--20 txt--xl"><strong>Awesome!</strong></h1>
      <p className="m--0 txt--md txt--white">Your email address has been confirmed!</p>
      <p className="mb--35 txt--md txt--white">Now you can start bringing your code to life.</p>
      <Link className="btn btn--lg btn--green" to="/login">Get Started!</Link>
    </div>
  )

  return (
    <div className="container--full welcome">
      {step === 1 ? step1 : step === 2 ? step2 : step3}
      <MicroticaLogoBig className="microtica-big-logo" />
      <Footer />
    </div>
  );
};

export default Register;
