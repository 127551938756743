import { trackEvent } from "./index";

export const trackPipelineCreateInit = () => {
    trackEvent("pipeline_create_init", {
    });
};

export const trackPipelineBuildInit = () => {
    trackEvent("pipeline_build_init", {
    });
};