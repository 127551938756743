import * as constants from "../../constants";
import { Pipeline } from "@microtica/ms-ap-sdk";
// import { Dispatch } from "react-redux";
import { getContinuousIntegrationAPI } from "../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface } from "../../types/index";


export interface FetchPipelinesStart {
  type: constants.FETCH_PIPELINES_START;
}

export interface FetchPipelinesSuccess {
  type: constants.FETCH_PIPELINES_SUCCESS;
  pipelines: Pipeline[];
}

export interface FetchPipelinesFail {
  type: constants.FETCH_PIPELINES_FAIL;
  error: ErrorInterface;
}

export type FetchPipelines =
  | FetchPipelinesStart
  | FetchPipelinesSuccess
  | FetchPipelinesFail;



export function fetchPipelinesStart(): FetchPipelines {
  return {
    type: constants.FETCH_PIPELINES_START
  };
}

export function fetchPipelinesSuccess(
  pipelines: Pipeline[] = []
): FetchPipelinesSuccess {
  return {
    type: constants.FETCH_PIPELINES_SUCCESS,
    pipelines
  };
}

export function fetchPipelinesFail(error: ErrorInterface): FetchPipelinesFail {
  return {
    type: constants.FETCH_PIPELINES_FAIL,
    error
  };
}

export function fetchPipelines(
  projectId: string
): ThunkAction<Promise<void>, {}, {}, FetchPipelines> {
  return async (dispatch: ThunkDispatch<{}, {}, FetchPipelines>) => {
    // const projectId = "y2g6hjf2miurpg347953";
    dispatch(fetchPipelinesStart());
    try {
      const res = await getContinuousIntegrationAPI().listPipelines(projectId);
      dispatch(fetchPipelinesSuccess(res.data.pipelines));
    } catch (error: any) {
      dispatch(fetchPipelinesFail(error.response.data));
    }
  };
}

