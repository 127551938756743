import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../reducers";
import { fetchTimeline } from "../actions";
import { BuildEventTypeEnum, StageDeploymentEventTypeEnum, StageDeploymentEventEntityTypeEnum } from "@microtica/ms-notification-sdk";


// Type refers to "build" and "deployment" and filter refers to status filtering
export default function useTimeline(initialType: string = "", initialFilter: string = "") {
    const dispatch = useDispatch();
    const { currentProject, events } = useSelector((state: GlobalState) => state.project);
    const [type, setType] = useState(initialType);
    const [filter, setFilter] = useState(initialType);

    // Get a list of timeline events from the backend
    useEffect(() => {
        dispatch(fetchTimeline(currentProject.id, type));
    }, [dispatch, type]);

    // TODO 
    // Event status filtering (DEPLOYMENT and BUILD statuses are different. Should we map somehow,
    // for example: RUNNING -> In progress, UPDATE_IN_PROGRESS -> In Progress?)
    const filteredEvents = events.map(event => {
        if (event.type === BuildEventTypeEnum.Build) {
            return {
                projectId: event.projectId,
                itemId: event.buildId,
                name: event.pipelineName,
                status: event.status,
                type: event.type,
                timestamp: event.timestamp,
                pipelineId: event.pipelineId
            };
        } else if (
            event.type === StageDeploymentEventTypeEnum.Deployment &&
            event.entityType === StageDeploymentEventEntityTypeEnum.STAGE
        ) {
            return {
                projectId: event.projectId,
                itemId: event.deploymentId,
                name: event.stageName,
                status: event.status,
                type: event.type,
                timestamp: event.timestamp,
                stageName: event.stageName
            };
        } else {
            return {
                projectId: event.projectId,
                itemId: Math.random().toString(),
                name: event.microserviceName,
                status: event.status,
                type: event.type,
                timestamp: event.timestamp
            };
        }
    }).filter(event => {
        if (filter !== "All") {
            return event.status
                .toLocaleLowerCase()
                .includes(
                    filter.toLocaleLowerCase()
                );
        } else {
            return true;
        }
    });

    return {
        events: filteredEvents,
        type,
        setType,
        setFilter
    }

}
