import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions";
import { GlobalState } from "../reducers";


export default function useMicroserviceList(initialFilter: string = "") {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
    const { microservices, isProcessing, error } = useSelector((state: GlobalState) => state.microservice);
    const [filter, setFilter] = useState(initialFilter);

    // Get a list of microservices from the backend
    useEffect(() => {
        dispatch(actions.fetchMicroservices(currentProject.id));
    }, [dispatch]);
    const filteredItems = microservices
        .map(item => ({
            itemId: item.name,
            title: item.name,
            description: item.description,
            registry: item.registry || "",
            isPublic: item.isPublic
        }))
        .filter(item => {
            if (filter) {
                return item.title
                    .toLocaleLowerCase()
                    .includes(
                        filter.toLocaleLowerCase()
                    );
            } else {
                return true;
            }
        })
        .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
        });
    return {
        filteredItems,
        filter,
        setFilter,
        isProcessing,
        error
    };
}