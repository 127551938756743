import React, { useState, useEffect, Fragment, ReactElement } from "react";
import { useModal } from "react-modal-hook";
import { ReactComponent as AWSLogo } from "../../static/aws-logo.svg";
import Card from "../Card/Card";
import Button from "../Button/Button";
import CardHeader from "../CardHeader/CardHeader";
import DropdownMenuContainer, {
  DropdownMenuItemProps
} from "../DropdownMenuContainer/DropdownMenuContainer";
import DropdownContainer, {
  DropdownItem
} from "../../components/DropdownContainer/DropdownContainer";
import useAwsAccountList from "../../hooks/AwsAccount";
import { FormikErrors, Field } from "formik";
import CloudAccountModal, { CloudAccountType } from "../CloudAccount/CloudAccountModal";

export interface Aws {
  accountId: string;
  iamRole: string;
}

export interface AwsAccountChangeEvent {
  awsAccountId?: string;
  awsRegion?: string;
  ecrRepository?: string;
}

export interface AwsAccountProps {
  title?: string;
  subtitle?: string | ReactElement;
  value?: AwsAccountChangeEvent;
  disabled?: boolean;
  onChange?: (event: AwsAccountChangeEvent) => void;
  showEcrDropdown: boolean;
  showCardBox?: boolean;
  emptyText?: string | ReactElement;
  cfnLink?: string;
  errors?: FormikErrors<{
    awsAccount: string | undefined;
    awsRegion: string | undefined;
    awsECRRepo?: string | undefined;
  }>;
  setFieldValue?: {
    (
      field: "awsAccount" | "awsRegion",
      value: any,
      shouldValidate?: boolean | undefined
    ): void;
    (field: string, value: any): void;
  };
}

const AwsAccount = (props: AwsAccountProps) => {
  // Hooks
  const [title] = useState(props.title);
  const [info] = useState(props.subtitle);
  const [awsAccount, setAwsAccount] = useState<DropdownItem>();
  const [awsRegion, setAwsRegion] = useState<DropdownItem>();
  const [ecrRepository, setEcrRepository] = useState<DropdownItem>();
  const { awsAccounts, awsRegions } = useAwsAccountList();

  const [awsAccountList, setAwsAccountList] = useState<DropdownItem[]>([]);
  const [awsRegionList, setAwsRegionList] = useState<DropdownItem[]>([]);
  const [ecrRepositoryList] = useState<DropdownItem[]>(
    []
  );
  const [showModal, hideModal] = useModal(() => (
    <CloudAccountModal
      cfnLink={props.cfnLink}
      onClose={hideModal}
      cloudAccountType={CloudAccountType.AWS}
    />
  ));

  useEffect(() => {
    if (awsRegions.length) {
      setAwsRegionList(
        awsRegions.map(region => ({ id: region.id, name: region.name }))
      );
    }
  }, [awsRegions]);

  useEffect(() => {
    if (awsAccounts.length) {
      if (awsAccounts.length !== awsAccountList.length) {
        setAwsAccountList(awsAccounts.map(acc => ({ id: acc.id, name: acc.accountName || acc.id })));
      }
      if (props.value) {
        if (awsAccounts && props.value.awsAccountId && (!awsAccount || awsAccount.id !== props.value.awsAccountId)) {

          const account = awsAccounts.find(acc => acc.id === props.value!.awsAccountId);
          setAwsAccount({
            id: props.value.awsAccountId,
            name: account ? account!.accountName || account!.id : props.value.awsAccountId
          });
        }
        if (awsRegions && props.value.awsRegion && (!awsRegion || awsRegion.id !== props.value.awsRegion)) {

          const region = awsRegions.find(
            region => region.id === props.value!.awsRegion
          );
          setAwsRegion(region);
        }
      }
    }
  }, [props.value, awsAccounts])

  useEffect(() => {
    const isAccountChanged = !!(awsAccount && props.value && awsAccount.id !== props.value.awsAccountId);
    const isRegionChanged = !!(awsRegion && props.value && awsRegion.id !== props.value.awsRegion);
    const isEcrChanged = !!(ecrRepository && props.value && ecrRepository.id !== props.value.ecrRepository);
    if (isAccountChanged || isRegionChanged || isEcrChanged) {
      props.onChange &&
        props.onChange({
          awsAccountId: awsAccount && awsAccount.id,
          awsRegion: awsRegion && awsRegion.id,
          ecrRepository: ecrRepository && ecrRepository.id
        });
    }
  }, [awsAccount, awsRegion, ecrRepository]);

  const actions: DropdownMenuItemProps[] = [
    {
      itemId: "1",
      title: "Connect AWS Account",
      onClick: () => {
        showModal();
      }
    }
  ];

  const emptyAccounts = (
    <Card class={props.showCardBox === false ? "" : "card dark compact"}>
      {
        title ? <CardHeader title={title} text={info} /> : null
      }
      <div className="card__content">
        <div className="connect-account">
          <AWSLogo />
        </div>
        <p className="card__content__text">
          <strong>{props.emptyText || "Connect your first AWS account"}</strong>
        </p>
        <Button
          className="btn btn--xl btn--orange"
          children="Connect AWS Account"
          onClick={() => {
            showModal();
          }}
        />
      </div>
    </Card>
  );

  const existingAccounts = (
    <Card class={props.showCardBox === false ? "" : "card dark compact"}>
      <div className="card_header_container">
        {
          title ?
            <>
              <CardHeader title={title} text={info} />
              <DropdownMenuContainer
                actions={actions}
                type="account"
              />
            </>
            : null
        }
        {/* <CardHeader title={title} /> */}
      </div>
      <div className="page-content__dropdown-container mb--30">
        <Field
          name="awsAccount"
          label="AWS Account"
        >
          {() => (
            <div>
              <DropdownContainer
                selectedItem={awsAccount}
                items={awsAccountList}
                label="AWS Account"
                placeholder="Select an AWS account"
                disabled={props.disabled}
                onSelectItem={item => {
                  props.setFieldValue && props.setFieldValue("awsAccount", item.id);
                  setAwsAccount(item);
                }}
              />
              {props.errors && props.errors.awsAccount ? (
                <div className="page-content__error">{props.errors.awsAccount}</div>
              ) : null}
            </div>
          )}
        </Field>
      </div>
      <div className="page-content__dropdown-container mb--30">
        <Field
          name="awsRegion"
          label="AWS Region"
        >
          {() => (
            <div>
              <DropdownContainer
                selectedItem={awsRegion}
                items={awsRegionList}
                label="AWS Region"
                placeholder="Select an AWS region"
                disabled={props.disabled}
                onSelectItem={item => {
                  props.setFieldValue && props.setFieldValue("awsRegion", item.id);
                  setAwsRegion(item);
                }}
              />
              {props.errors && props.errors.awsRegion ? (
                <div className="page-content__error">{props.errors.awsRegion}</div>
              ) : null}
            </div>
          )}
        </Field>
      </div>
      {props.showEcrDropdown ? (
        <div className="page-content__dropdown-container">
          <Field
            name="awsECRRepo"
            label="AWS ECR Repository"
          >
            {() => (
              <div>
                <DropdownContainer
                  selectedItem={ecrRepository!}
                  items={ecrRepositoryList}
                  placeholder="Select an ECR repository"
                  onSelectItem={item => {
                    props.setFieldValue && props.setFieldValue("awsECRRepo", item.id);
                    setEcrRepository(item)
                  }}
                  label="AWS ECR Repository"
                />
                {props.errors && props.errors.awsECRRepo ? (
                  <div className="page-content__error">{props.errors.awsECRRepo}</div>
                ) : null}
              </div>
            )}
          </Field>
        </div>
      ) : null}
    </Card>
  );

  return (
    <Fragment>{awsAccounts.length ? existingAccounts : emptyAccounts}</Fragment>
  );
};

export default AwsAccount;