

export function statusColor(status: string): string {
    if (status.includes("IN_PROGRESS") ||
        status.includes("RUNNING") ||
        status.includes("PENDING")) {
        return "warning"
    }
    if (status.includes("FAILED") ||
        status.includes("TIMED_OUT")) {
        return "fail"
    }
    if (status.includes("QUEUED")) {
        return "queued"
    }
    if (status === "NO BUILD") {
        return "nobuild"
    }
    if (status.includes("ABORTED")) {
        return "aborted";
    }
    return "success";
}