import { trackEvent, windowWithType } from "./index";

export const trackUserRegister = (username: string, type: "email" | "github") => {
    if (windowWithType.woopra) {
        windowWithType.woopra.identify({
            email: username,
            created: new Date(),
        });
        windowWithType.woopra.push();
    }
    if (windowWithType.gist) {
        windowWithType.gist.identify({
            email: username
        });
    }

    trackEvent("user_register", { email: username, type });
};

export const trackUserLogin = (username: string, type: "email" | "github") => {
    if (windowWithType.woopra) {
        windowWithType.woopra.identify({
            email: username,
        });
        windowWithType.woopra.push();
    }
    if (windowWithType.gist) {
        windowWithType.gist.identify({
            email: username
        });
    }
    trackEvent("user_login", { email: username, type });
};

export const trackUserResetPassword = (username: string) => {
    if (windowWithType.woopra) {
        windowWithType.woopra.identify({
            email: username,
        });
        windowWithType.woopra.push();
    }
    if (windowWithType.gist) {
        windowWithType.gist.identify({
            email: username
        });
    }

    trackEvent("user_password_reset", { email: username });
};
