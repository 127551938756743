import React, { useEffect } from "react";
import { RouteComponentProps } from 'react-router-dom';

import SearchBar from "../../components/SearchBar/SearchBar";
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import useBuildList from "../../hooks/BuildsList";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import { toast } from "react-toastify";
import { Pipeline } from "@microtica/ms-ap-sdk";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { GlobalState } from "../../reducers";

interface BuildsListProps extends RouteComponentProps<{ pipelineId: string }> {
  pipelineId: string;
  pipeline: Pipeline;
}

const headers = [
  "Status",
  "Execution",
  "Commit:left",
  "Reports",
  ""
];

const BuildsList = (props: BuildsListProps) => {
  const dispatch = useDispatch();
  const currentProject = useSelector((state: GlobalState) => state.project.currentProject);
  const { filter, setFilter, isFetching, error, builds, limit, offset } = useBuildList(props.pipeline);

  useEffect(() => {
    if (error.code && !error.message) {
      toast.configure({
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      toast.info(`Something went wrong! Error: ${error.code}`)
    }
  }, [error]);

  const fetchMoreData = async () => {
    // Fetch more data if there is a valid offset and limit
    if (!isFetching && offset) {
      dispatch(actions.fetchBuilds(currentProject.id, props.pipeline.id, limit, offset));
    }
  }

  return (
    <React.Fragment>
      <CustomScrollbars maxHeight="calc(100vh - 220px)" fetchMoreData={fetchMoreData}>
        <Card class="card card--vertical card--pipelines dark">
          <div className="d-flex justify-content-between align-items-center">
            <div className="searchbar-wrapper">
              <SearchBar
                placeholder="Search build executions"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
              />
            </div>
          </div>
          <div className="table-wrapper-sticky">
            <Table
              isLoaded={!isFetching || offset != undefined}   // Dont reload if doing a re-fetch
              headers={headers}
              rows={builds}
            />
          </div>
        </Card>
      </CustomScrollbars>

    </React.Fragment>
  )
};

export default BuildsList;