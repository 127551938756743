import React from "react";
import { Link } from "react-router-dom";

import image404 from "../../static/404-graphic.svg";

const PageNotFound = () => {
  return (
    <main className="content content--projects d-flex flex-column justify-content-start">

      <div className="content__body">
        <div className="page-centered">
          <img src={image404} alt="page not found"/>
          <h1><strong>Oops, page not found!</strong></h1>
          <p>We are very sorry for the inconvenience. It looks like you're trying to access a page that has been deleted or never even existed.</p>
          <Link to="/projects" className="btn btn--xl btn--green">Back to Dashboard</Link>
        </div>
      </div>

    </main>
  )
}

export default PageNotFound;