import * as React from 'react';
import Animation from "../Animations/Animations";
import CustomScrollbars from '../CustomScrollbars/CustomScrollbars';

export interface ModalActionProps {
  name: string;
  description?: string;
  children: any;
  class: string;
  onClose: React.MouseEventHandler
}

function ModalAction(props: ModalActionProps) {
  return (
    <React.Fragment>
      <div className="page-overlay"></div>

      <Animation show={true} type="modal" modal itemIndex={1}>
        <div className={props.class}>
          <div className="modal__close" onClick={props.onClose}>X</div>
          <CustomScrollbars maxHeight="calc(100vh - 200px)">
            <div className="modal-padding">
              <div className="modal__header mb--40">
                <h3 className="modal__title">{props.name}</h3>
                <p className="modal__text">{props.description}</p>
              </div>
              {props.children}
            </div>
          </CustomScrollbars>
        </div>
      </Animation>
    </React.Fragment>
  );
}


export default ModalAction;
