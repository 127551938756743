import React from "react";

const ProjectBoxSkeleton = () => {
  return (
    <div className="skeleton skeleton__box">
      <span className="skeleton skeleton__logo skeleton--animation"></span>
      <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
      <p className="skeleton skeleton--animation skeleton--placeholder"></p>
      <div className="skeleton__footer skeleton--placeholder d-flex justify-content-end align-items-center">
        <span className="skeleton skeleton__circle skeleton--animation"></span>
      </div>
    </div>
  )
}

export default ProjectBoxSkeleton;