import * as constants from "../../../constants";
import { getContinuousIntegrationAPI } from "../../../api";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ErrorInterface, errorEmptyObject } from "../../../types";

export interface DeleteGitAccountStart {
  type: constants.DELETE_GIT_ACCOUNT_START;
}

export interface DeleteGitAccountSuccess {
  type: constants.DELETE_GIT_ACCOUNT_SUCCESS;
  gitAccountId: string;
}

export interface DeleteGitAccountFail {
  type: constants.DELETE_GIT_ACCOUNT_FAIL;
  error: ErrorInterface;
}

export type DeleteGitAccount =
  | DeleteGitAccountStart
  | DeleteGitAccountSuccess
  | DeleteGitAccountFail;


export function deleteGitAccountStart(): DeleteGitAccountStart {
  return {
    type: constants.DELETE_GIT_ACCOUNT_START
  };
}

export function deleteGitAccountSuccess(
  gitAccountId: string
): DeleteGitAccountSuccess {
  return {
    type: constants.DELETE_GIT_ACCOUNT_SUCCESS,
    gitAccountId
  };
}

export function deleteGitAccountFail(error: ErrorInterface = errorEmptyObject): DeleteGitAccountFail {
  return {
    type: constants.DELETE_GIT_ACCOUNT_FAIL,
    error: error
  };
}

export function deleteGitAccount(
  projectId: string,
  gitAccountId: string
): ThunkAction<Promise<void>, {}, {}, DeleteGitAccount> {
  return async (dispatch: ThunkDispatch<{}, {}, DeleteGitAccount>) => {
    dispatch(deleteGitAccountStart());
    try {
      await getContinuousIntegrationAPI().deleteGitAccount(projectId, gitAccountId);
      dispatch(deleteGitAccountSuccess(gitAccountId));
    } catch (error) {
      dispatch(deleteGitAccountFail(error.response.data));
    }
  };
}
