import * as constants from "../../constants";
import { ErrorInterface, errorEmptyObject } from "../../types/index";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { UpdateProjectRequest } from "@microtica/ms-project-sdk";
import { getProjectAPI } from "../../api";

export interface UpdateProjectStart {
    type: constants.UPDATE_PROJECT_START;
}
export interface UpdateProjectSuccess {
    type: constants.UPDATE_PROJECT_SUCCESS;
    projectId: string;
    object: UpdateProjectRequest;
}
export interface UpdateProjectFail {
    type: constants.UPDATE_PROJECT_FAIL;
    error: ErrorInterface;
}

export interface ClearError {
    type: constants.CLEAR_ERROR;
    error: ErrorInterface;
}

export type UpdateProject =
    | UpdateProjectStart
    | UpdateProjectSuccess
    | UpdateProjectFail

export interface ClearError {
    type: constants.CLEAR_ERROR;
    error: ErrorInterface;
}

export function updateProjectStart(): UpdateProjectStart {
    return {
        type: constants.UPDATE_PROJECT_START
    };
}

export function updateProjectFail(error: ErrorInterface): UpdateProjectFail {
    return {
        type: constants.UPDATE_PROJECT_FAIL,
        error
    };
}

export function updateProjectSuccess(
    projectId: string,
    object: UpdateProjectRequest
): UpdateProjectSuccess {
    return {
        type: constants.UPDATE_PROJECT_SUCCESS,
        projectId,
        object
    };
}

export function updateProject(
    projectId: string,
    project: UpdateProjectRequest
): ThunkAction<Promise<void>, {}, {}, UpdateProject> {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateProject>) => {
        dispatch(updateProjectStart());
        try {
            await getProjectAPI().updateProject(projectId, project);
            dispatch(updateProjectSuccess(projectId, project));
        } catch (error) {
            dispatch(updateProjectFail(error.response.data));
        }
    };
}

export function clearError(): ClearError {
    return {
        type: constants.CLEAR_ERROR,
        error: errorEmptyObject
    };
}