import React from "react";

interface ListItemSkeletonProps {
  type?: string;
}

const ListItemSkeleton = (props: ListItemSkeletonProps) => {
  // Render different content based on type of list item
  const skeletonListContent = () => {
    let content;

    switch(true) {
      case props.type === "status":
        content = (
          <React.Fragment>
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>

            <span className="skeleton skeleton__lists--circle skeleton--animation skeleton--placeholder skeleton--circle"></span>
          </React.Fragment>
        )
        break;
      case props.type === "management":
        content = (
          <div className="d-flex align-items-center">
            <span className="skeleton skeleton__image skeleton--animation skeleton--placeholder skeleton--circle"></span>
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>
          </div>
        )
        break;
      default: 
        content = (
          <React.Fragment>
            <h5 className="skeleton skeleton--animation skeleton--placeholder"></h5>
            <p className="skeleton skeleton--animation skeleton--placeholder"></p>
          </React.Fragment>
        )
    }

    return content;
  }

  return (
    <div className={`skeleton skeleton__lists ${props.type === "management" ? 'skeleton__lists--management' : ''}`}>
      {skeletonListContent()}
    </div>
  )
}

export default ListItemSkeleton;